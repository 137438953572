import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setDefaultTab } from "../../../redux/classes/Admin-Client/shiftTabSlice";
const Breadcrumbs = () => {
  const dispatch = useDispatch();
  const crumbsData = useSelector((state) => state.breadcrumb);
  // const activeTab = useSelector((state) => state.tabChange);
  const goBack = () => {
    if (Array.isArray(crumbsData)) {
      crumbsData.forEach((ele) => {
        if (ele?.label === "Sites") {
          dispatch(setDefaultTab("tab5Click"));
        }
      });
    }

    // Navigate back in browser history
    window.history.back();
  };

  const goForward = () => {
    window.history.forward();
  };

  return (
    <ul className="flex gap-4 items-center breadcrumbs-wrap">
      <li>
        <Link onClick={goBack}>
          <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m14.144 16.5-5.5-5.5 5.5-5.5"
              stroke="#5786CC"
              strokeWidth="1.667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </li>
      <li>

        <Link to={crumbsData[0]?.home}>
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.06 14.167h6.667M9.575 2.303 3.924 6.7c-.378.294-.567.441-.703.625-.12.163-.21.347-.265.542-.062.22-.062.46-.062.938v6.03c0 .933 0 1.4.182 1.756.16.314.414.569.728.728.357.182.823.182 1.757.182h9.666c.934 0 1.4 0 1.757-.182.314-.16.569-.414.728-.728.182-.357.182-.823.182-1.757V8.804c0-.478 0-.718-.062-.938a1.669 1.669 0 0 0-.265-.542c-.136-.184-.325-.33-.702-.625l-5.652-4.396c-.293-.227-.44-.341-.601-.385a.833.833 0 0 0-.436 0c-.161.044-.308.158-.6.385z"
              stroke="#667085"
              stroke-width="1.667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Link>
      </li>
      <li>
        <Link onClick={goForward}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M6 12L10 8L6 4" stroke="#D0D5DD" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </Link>
      </li>

      {crumbsData &&
        crumbsData?.map((data, index) => (
          <React.Fragment key={index}>
            <li>
              <Link to={data?.url}>
                <span onClick={() => data?.label == "Sites" && dispatch(setDefaultTab("tab5Click"))} className="text-xs font-semibold text-gray-600">
                  {data?.label}
                </span>
              </Link>
            </li>
            {index !== crumbsData?.length - 1 && (
              <li>
                <span className="text-xs font-semibold text-blue-700">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M6 12L10 8L6 4" stroke="#D0D5DD" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
              </li>
            )}
          </React.Fragment>
        ))}
    </ul>
  );
};
export default Breadcrumbs;
