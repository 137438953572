import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as userActions from "../../../../../../../actions";
import Input from "../../../../../../../common/element/Input";
import Label from "../../../../../../../common/element/Label";
import Tag from "../../../../../../../common/element/Tag";
import Button from "../../../../../../../common/element/Buttons";
import Segment from "../../../../../../../common/element/Segment";
import Select from "../../../../../../../common/element/Select/Select";
import ScrollBar from "../../../../../../../common/element/ScrollBar";
import { Link, useParams } from "react-router-dom";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../../../../common/element/Dialog";
import Validations from "../../../../../../../common/404/Validations";
import { GetWorkerOverviewDetailsById } from "../../../../../../../actions/admin-worker/GetWorkerOverviewDetailsById";
import { redirectSpecWorker } from "../../../../../../../redux/classes/admin-worker/RedirectWorkerSlice";
import { UpdateWorkerProfileSetting } from "../../../../../../../actions/worker-portal/UpdateWorkerProfileSetting";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import ThreeDotLoader from "../../../../../../../common/element/ButtonLoader/ThreeDotLoader";
import preventInvalidChars from "../../../../../../../utils/preventInvalidChars";

const ClientDetails = forwardRef((props, ref) => {
  let getApiData;
  const dispatch = useDispatch();
  const { id } = useParams();
  const dialogRef = useRef(null);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [dialogAddOpen, setdialogAddOpen] = useState(false);
  const [dialogUserUpdate, setDialogUserUpdate] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [urlImagePath, setUrlImagePath] = useState(false)
  const [hasChanges, setHasChanges] = useState(false);
  const [statesOne, setStatesOne] = useState();
  const [countries, setCountries] = useState();
  const [statesTwo, setStatesTwo] = useState();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    occupation: "",
    email: "",
    photo: "",
    phone: "",
    state_id: "",
    country_id: "",
  });
  const [formErrors, setFormErrors] = useState({
    first_name: "",
    last_name: "",
    occupation: "",
    email: "",
    photo: "",
  });
  const [attachemnt, setAttachment] = useState("");
  const [urlImage, setUrlImage] = useState();
  const [documentError, setdocumentError] = useState("");
  const { workerRedirectSpecificData } = useSelector((state) => state)
  getApiData = workerRedirectSpecificData?.individual_id ? workerRedirectSpecificData?.individual_id : "";
  useEffect(() => {
    updateStateFromApi(workerRedirectSpecificData)
  }, [workerRedirectSpecificData])

  const updateStateFromApi = (arrayData) => {
    setFormData({
      ...formData,
      first_name: arrayData?.first_name,
      last_name: arrayData?.last_name,
      occupation: arrayData?.occupation ? arrayData?.occupation : "",
      email: arrayData?.email,
      individual_id: arrayData?.individual_id,
      phone: arrayData?.phone ? arrayData?.phone : "",
      country_id: arrayData?.country_id,
      state_id: arrayData?.state_id,
      // photo: arrayData?.avatar
    });
    setUrlImage(arrayData?.avatar);
    setUrlImagePath(true)
    setAttachment(arrayData?.avatar);
  };

  const onUpdateDialogClose = (e) => {
    setDialogUserUpdate(false)
    props?.userSettingHide()
  }
  const onDropHandler = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileChange(files);
  };

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      inputChangeHandlerFile(file);
    }
  };

  const inputChangeHandlerFile = (file) => {
    // Check the file extension
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/svg+xml" ||
        fileType.startsWith("image/png") ||
        fileType.startsWith("image/jpeg") ||
        fileType.startsWith("image/gif")
      ) {
        // Check the file size (max. 800x400px)
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          if (img.width <= 800 && img.height <= 400) {
            setAttachment(file);
            setUrlImage(URL.createObjectURL(file));
            setdocumentError("");
          } else {
            // Handle size validation error
            setdocumentError(`File dimensions should not exceed 800x400 pixels. Your current file size is ${img?.width}x${img?.height} pixels.`)
          }
        };
      } else {
        // Handle file type validation error
        setdocumentError(
          "Invalid file type. Only SVG, PNG, JPG, or GIF files are allowed."
        );
      }
    }
  };

  const handleInputChange = (e, identifier) => {
    let value;
    if (identifier == "country_id") {
      value = e?.value;
    } else if (identifier == "state_id") {
      value = e?.value;
    } else {
      value = e?.target?.value;
    }
    setFormData({ ...formData, [identifier]: value });
    if (identifier == "first_name" || identifier == "last_name") {
      setFormErrors((prevError) => ({
        ...prevError,
        [identifier]: ""
      }))
    }
    if (identifier == "phone") {
      setFormErrors((prevError) => ({
        ...prevError,
        phone: ""
      }))
    }
  };


  const handleSubmit = (e) => {
    // e.preventDefault()
    const phoneValidation = Validations({
      value: (formData?.phone?.length == "" || formData?.phone?.length == undefined) ? "test" : (formData?.phone?.length >= 7 ? formData?.phone : ""),
      Validations: { required: true },
    });
    if (!phoneValidation.valid) {
      setFormErrors((prevError) => ({
        ...prevError,
        phone: "Phone number must be at least 7 digits if provided",
      }));
    }
    const fnameValidation = Validations({
      value: formData?.first_name,
      Validations: { required: true },
    });
    if (!fnameValidation.valid) {
      setFormErrors((prevError) => ({
        ...prevError,
        first_name: fnameValidation.error_msg
      }))
    }
    const lnameValidation = Validations({
      value: formData?.last_name,
      Validations: { required: true },
    });
    if (!lnameValidation.valid) {
      setFormErrors((prevError) => ({
        ...prevError,
        last_name: lnameValidation.error_msg
      }))
    }
    const emailValidation = Validations({
      value: formData?.email,
      Validations: { required: true },
    });
    if (!emailValidation.valid) {
      setFormErrors((prevError) => ({
        ...prevError,
        email: emailValidation.error_msg
      }))
    }

    if (fnameValidation?.valid === true && lnameValidation?.valid === true &&
      emailValidation?.valid === true && phoneValidation?.valid === true) {
      setButtonDisabled(true)
      const payload = {
        individual_id: workerRedirectSpecificData?.individual_id ? workerRedirectSpecificData?.individual_id : "",
        first_name: formData?.first_name,
        last_name: formData?.last_name,
        occupation: formData?.occupation,
        avatar: attachemnt,
        phone: formData?.phone,
        country_id: formData?.country_id,
        state_id: formData?.state_id,
      };
      dispatch(UpdateWorkerProfileSetting(payload))
        .then((response) => {
          if (response?.success) {
            setDialogUserUpdate(true)
            setButtonDisabled(false)
            dispatch(GetWorkerOverviewDetailsById({ individual_id: workerRedirectSpecificData?.individual_id })).then(
              (response) => {
                if (response?.success) {
                  const arrayData = response?.data;
                  dispatch(redirectSpecWorker(arrayData))
                } else if (response?.status === 401) {
                  handleUnauthorizedAccess();
                }
              }
            );
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {
          setButtonDisabled(false)
        }).finally(() => {
          setButtonDisabled(false)
        })
    }
  };

  const GetAllStatesbyCountryId = useCallback(
    async (formData) => {
      if (formData?.country_id) {
        dispatch(userActions.GetAllStatesbyCountryId(formData?.country_id)).then(
          (response) => {
            if (response.success === true) {
              const arrayData = response.data;
              let state_data = [];
              for (let key in arrayData) {
                state_data.push({
                  label: arrayData[key].state_name,
                  value: arrayData[key].state_id,
                });
              }
              setStatesOne(state_data);
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            } else {
            }
          }
        );
      }

      // eslint-disable-next-line
    },
    [dispatch, formData?.country_id]
  );

  const GetAllCountries = useCallback(async () => {
    dispatch(userActions.GetAllCountries()).then((response) => {
      if (response.success === true) {
        const arrayData = response.data;
        let country_data = [];
        for (let key in arrayData) {
          country_data.push({
            label: arrayData[key].name,
            value: arrayData[key].country_id,
            country_id: arrayData[key].country_id,
          });
        }
        setCountries(country_data);
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      } else {
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetAllStatesbyCountryId(formData);
  }, [formData?.country_id]);

  useEffect(() => {
    GetAllCountries();
  }, []);

  return (
    <React.Fragment>
      <div ref={dialogRef}>
        <div className="h-500 pr-1">
          <ScrollBar>
            <div className="pr-4 mt-5">
              <div className="flex border-b border-gray-200 justify-between flex-col mb-5  pb-5">
                <h5 className="text-base text-gray-900 font-semibold mb-1">
                  Worker Details
                </h5>
                <p className="text-gray-600">
                  Update the Worker's image and details here.
                </p>
              </div>

              <div className="flex flex-col border-b border-gray-200 pb-5 mb-5">
                <div className="flex w-full justify-between gap-5 ">
                  <label className="text-gray-700 font-semibold font-sm">
                    Worker image
                  </label>
               
                  <span className="h-28 ml-6 w-28 bg-gray-100 rounded-full flex justify-center items-center avtar_img_round">
                    {!urlImagePath && (
                      <img
                        className="w-28 h-28 rounded-full"
                        src=""
                        alt=""
                      />
                    )}
                    {(urlImage || urlImagePath) && (
                      <img
                        className="w-28 h-28 rounded-full"
                        src={urlImage || "/assets/img/avatars/thumb-8.jpg"}
                        alt="User Avatar"
                      />
                    )}

                  </span>
                  <div className="flex flex-col items-center justify-center  flex-1 upload-img ml-8">
                    <label
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full border py-4 px-6 rounded-xl  border-gray-200  hover:bg-gray-25 bg-white"
                    >
                      <div
                        className="flex items-center justify-center  flex-1 upload-img ml-8"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={onDropHandler}
                      >
                        <div className="flex flex-col items-center justify-center">
                          <div className="rounded-full border-12 circle m-auto flex align-center justify-center bg-gray-200 border-gray-50 mb-3 border-6 w-10 h-10 items-center">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                                stroke="#475467"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <p className="mb-2  text-gray-600 darks:text-gray-400">
                            <span className="font-semibold text-blue-700">
                              Click to upload
                            </span>
                            <span className="text-xs"> or drag and drop</span>
                          </p>
                          <p className="text-gray-600 darks:text-gray-400 font-inter">
                            SVG, PNG, JPG or GIF (max. 800x400px)
                          </p>
                        </div>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        onChange={(e) =>
                          inputChangeHandlerFile(e?.target?.files[0])
                        }
                      />
                    </label>

                  </div>
                </div>
                {documentError && (
                  <p className="my-2 text-xs text-red-600 mt-2">
                    {documentError}
                  </p>
                )}
              </div>

              <div className="flex items-start mb-5">
                <label className="text-gray-700 font-semibold w-2/12">Full name*</label>
                <div className="flex items-center w-full flex-wrap">
                  <div className="flex gap-3 w-full">
                    <div className="flex-1">
                      <Label className="mb-1">First name</Label>
                      <input
                        className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Enter first name"
                        value={formData?.first_name}
                        onChange={(e) => handleInputChange(e, "first_name")}
                      />
                      {formErrors?.first_name && (
                        <p className="text-xs text-red-600 mb-4 mt-0">
                          {formErrors?.first_name}
                        </p>
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="mb-1">Last name</Label>
                      <input
                        className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Enter last name"
                        value={formData?.last_name}
                        onChange={(e) => handleInputChange(e, "last_name")}
                      />
                      {formErrors?.last_name && (
                        <p className="text-xs text-red-600 mb-4 mt-0">
                          {formErrors?.last_name}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-start mb-5">
                <label className="text-gray-700 font-semibold w-2/12">Occupation</label>
                <div className="flex items-center w-full flex-wrap">
                  <input
                    className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Job title here"
                    value={formData?.occupation}
                    onChange={(e) => handleInputChange(e, "occupation")}
                  />
                </div>
              </div>
              <div className="flex items-start mb-5">
                <label className="text-gray-700 font-semibold w-2/12">
                  Email address*
                </label>
                <div className="flex items-center w-full flex-wrap relative">
                  <span className="absolute left-3 top-2">
                    <img src="/assets/icons-svg/mail-01.svg" alt="" />
                  </span>
                  <input
                    className="input px-3 input-shadow text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline' h-9 w-full pl-10"
                    placeholder="name@emailaddress.com"
                    value={formData?.email}
                  />
                  {formErrors?.email && (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {formErrors?.email}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-start mb-5">
                <label className="text-gray-700 font-semibold w-2/12">
                  Contact number
                </label>
                <div className="flex items-center w-full flex-wrap relative">
                  <span className="absolute left-3 top-2">
                    <img src="/assets/icons-svg/phone.svg" alt="" />
                  </span>
                  <input
                    type="number"
                    className="input px-3 input-shadow text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-9 w-full pl-10"
                    placeholder="name@emailaddress.com"
                    value={formData?.phone}
                    onChange={(e) => {
                      const value = e?.target?.value;
                      if (value.length <= 15) {
                        handleInputChange(e, "phone");
                      }
                    }}
                    min="1000000"  // Min value for 7 digits
                    max="999999999999999"  // Max value for 15 digits
                    onKeyDown={preventInvalidChars}
                  />
                  {formErrors?.phone && (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {formErrors?.phone}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-start">
                <label className="text-gray-700 font-semibold w-2/12">Location</label>
                <div className="flex items-center w-full flex-wrap">
                  <div className="flex gap-3 w-full">
                    <div className="flex-1">
                      <Select
                        isSearchable={false}
                        placeholder="Country"
                        options={countries}
                        className="select-option w-full h-10 flex-1"
                        value={countries?.filter(function (option) {
                          return option.value === formData?.country_id;
                        })}
                        onChange={(e) => handleInputChange(e, "country_id")}
                      />

                    </div>
                    <div className="flex-1">
                      <Select
                        isSearchable={false}
                        placeholder="State"
                        options={statesOne}
                        className="select-option w-full h-10 flex-1"
                        value={statesOne?.filter(function (option) {
                          return option.value === formData?.state_id;
                        })}
                        onChange={(e) => handleInputChange(e, "state_id")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ScrollBar>
        </div>
        <div className="flex p-6 justify-end border-gray-200">
          <BtnLight
            disabled={buttonDisabled}
            onClick={() => { props?.userSettingHide() }}
            // onClick={() => { hasChanges ? openDialogUnSave() : onCloseNotUpdateValue() }}
            className="w-auto px-5 mr-3"
            variant="plain"
          >
            Close
          </BtnLight>
          <BtnPrimary
            disabled={buttonDisabled}
            onClick={() => handleSubmit()}
            className="w-auto px-5">
            {buttonDisabled ? <ThreeDotLoader /> : "Save changes"}
          </BtnPrimary>
        </div>

        {/* <Dialog
          isOpen={unSaveDialogIsOpen}
          onClose={onDialogCloseUnSave}
          onRequestClose={onDialogCloseUnSave}
          bodyOpenClassName="overflow-hidden"
          className="customAlert"
        >
          <div className="px-6 pt-6">
            <span className="w-12 h-12 rounded-full m-auto bg-warning-100 flex items-center justify-center">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 3.5v3.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C8.26 8.5 8.54 8.5 9.1 8.5h6.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437c.109-.214.109-.494.109-1.054V4.5m0 17v-6.4c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437c-.214-.109-.494-.109-1.054-.109H9.1c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C7.5 14.26 7.5 14.54 7.5 15.1v6.4m14-11.675V16.7c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327H8.3c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3.5 19.22 3.5 18.38 3.5 16.7V8.3c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C5.78 3.5 6.62 3.5 8.3 3.5h6.874c.49 0 .734 0 .965.055.204.05.399.13.578.24.201.123.374.296.72.642l3.126 3.126c.346.346.519.519.642.72.11.18.19.374.24.579.055.23.055.474.055.963z"
                  stroke="#000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
              You have unsaved changes
            </h4>
            <p className=" text-gray-600 text-center mt-1">
              Save your information before leaving?
            </p>
          </div>

          <div className="mt-8 flex justify-between pb-6 px-6">
            <BtnLight onClick={() => onDialogExitUnSave()} className="mr-1.5">
              Exit
            </BtnLight>
            <BtnPrimary className="ml-1.5" onClick={() => EditClient()}>
              Save
            </BtnPrimary>
          </div>
        </Dialog> */}
      </div>
      <Dialog
        isOpen={dialogUserUpdate}
        bodyOpenClassName="overflow-hidden"
        onClose={onUpdateDialogClose}
        onRequestClose={onUpdateDialogClose}
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.890625" y="0.0449219" width="48" height="48" rx="24" fill="#D1FADF" />
              <path d="M20.3906 24.0449L23.3906 27.0449L29.3906 21.0449M34.8906 24.0449C34.8906 29.5678 30.4135 34.0449 24.8906 34.0449C19.3678 34.0449 14.8906 29.5678 14.8906 24.0449C14.8906 18.5221 19.3678 14.0449 24.8906 14.0449C30.4135 14.0449 34.8906 18.5221 34.8906 24.0449Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Worker details saved!
          </p>
        </div>
        <div className='mt-8 flex justify-between pb-6 px-6'>
          <BtnLight onClick={onUpdateDialogClose}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
});

function mapStateToProps(state) {
  return {
    loader: userActions.UpdateClientDetails.loader,
  };
}
const mapDispatchToProps = {
  onUpdateClientDetails: userActions.UpdateClientDetails,
  onUpdateOrgStatus: userActions.UpdateOrgStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
