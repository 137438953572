import axios from "axios";
import * as actionType from "../../constant/actionType";
export const GetAllClientsForCompCertificate =
  (apiData) => async (dispatch) => {
    /*** API Call Action ***/

    try {
      let apiPath;
      if (apiData.type == "adminworker") {
        apiPath = "admin/worker";
      } else if (apiData.type == "worker") {
        apiPath = "worker";
      } else {
        apiPath = "admin/provider";
      }
      
      // Payload data
      const payload = {
        last_certificate_gen_date: apiData?.last_certificate_gen_date,
        provider_fa_id: apiData?.provider_fa_id,
      };
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/${apiPath}/GetAllClientsForCompCertificate`,
        params: payload,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };
