import React from "react";

const TermsofUse = () => {
  return (
    <div className="bg-gray-100 text-gray-800 min-h-screen py-8">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-3xl font-bold mb-6 text-center">Terms Of Use</h1>
      </div>
    </div>
  );
};
export default TermsofUse;
