// tabChange.js
import { createSlice } from '@reduxjs/toolkit';

const tabChange = createSlice({
  name: 'tabChange',
  initialState: 'tab1', // default tab
  reducers: {
    setDefaultTab: (state, action) => {
      return action.payload; // set the tab value
    },
  },
});

export const { setDefaultTab } = tabChange.actions;
export default tabChange.reducer;
