import React, { useEffect, useState } from "react";
import Table from "../../../../common/element/Table";
import Button from "../../../../common/element/Buttons";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { GetAllAssignedWorkerToClient } from "../../../../actions/provider-portal/GetAllAssignedWorkerToClient";
const ConnectedWorkers = ({ indivisualData }) => {
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  const [data, SetData] = useState([]);
  const [sort, setSort] = useState("created_date");
  const [order, setOrder] = useState("desc");
  const dispatch = useDispatch();
  const { id } = useParams();
  const { Tr, Th, Td, THead, TBody } = Table;
  // provider_org_id, client_org_id
  const handleSort = (column) => {
    if (column === sort) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setSort(column);
    }
  };

  useEffect(() => {
    let payload = {
      provider_org_id: loginUserData?.organisation_id,
      client_org_id: indivisualData.organisation_id,
      sort: sort,
      order: order,
    };

    dispatch(GetAllAssignedWorkerToClient(payload)).then((response) => {
      if (response.success) {
        SetData(response?.data);
      }
    });
  }, [sort, order]);
  return (
    <div className="pr-3">
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("first_name")}
                >
                  Worker name
                  <svg
                    className={`transform ${
                      sort !== "" && sort === "first_name" && order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th>

              <Th className="w-64">
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("worker_job_title")}
                >
                  Job title
                  <svg
                    className={`transform ${
                      sort !== "" &&
                      sort === "worker_job_title" &&
                      order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th>

              <Th className="w-48">
                <span className="flex items-center gap-1">Contact no.</span>
              </Th>
              {/* <Th className="w-16"></Th> */}
            </Tr>
          </THead>
          <TBody>
            {data?.map((item, index) => {
              return (
                <Tr key={index}>
                  <Td>{item?.first_name + " " + item?.last_name}</Td>
                  <Td>{item?.worker_job_title}</Td>
                  <Td>{item?.phone == null ? "-" : item?.phone}</Td>
                  {/* <Td>
                    <Button>
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.01677 11.2367C1.90328 11.057 1.84654 10.9672 1.81477 10.8286C1.79091 10.7245 1.79091 10.5603 1.81477 10.4562C1.84654 10.3176 1.90328 10.2278 2.01677 10.0481C2.95461 8.56312 5.74617 4.80908 10.0003 4.80908C14.2545 4.80908 17.0461 8.56312 17.9839 10.0481C18.0974 10.2278 18.1541 10.3176 18.1859 10.4562C18.2098 10.5603 18.2098 10.7245 18.1859 10.8286C18.1541 10.9672 18.0974 11.057 17.9839 11.2367C17.0461 12.7217 14.2545 16.4757 10.0003 16.4757C5.74617 16.4757 2.95461 12.7217 2.01677 11.2367Z"
                          stroke="#475467"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.0003 13.1424C11.381 13.1424 12.5003 12.0231 12.5003 10.6424C12.5003 9.2617 11.381 8.14242 10.0003 8.14242C8.61962 8.14242 7.50034 9.2617 7.50034 10.6424C7.50034 12.0231 8.61962 13.1424 10.0003 13.1424Z"
                          stroke="#475467"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </Td> */}
                </Tr>
              );
            })}
          </TBody>
        </Table>
      </div>
    </div>
  );
};

export default ConnectedWorkers;
