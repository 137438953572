import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import Tabs from "../../../../../common/element/Tabs";
import { useParams, useLocation, useNavigate  } from "react-router-dom";
// import Layout from "../../../../adminDashboard/components/layout";
import SiteName from "./SiteName";
import Visitorlog from "./SpecificSiteTab/Visitorlog";
import Inductees from "./SpecificSiteTab/Inductees";
import SiteSettings from "./SpecificSiteTab/SiteSettings";
import AuditLog from "./SpecificSiteTab/AuditLog";
import SiteInduction from "./SpecificSiteTab/SiteInductions";
import * as userActions from '../../../../../actions/index';
import Overview from "./SpecificSiteTab/Overview";
import { GetClientSiteById } from "../../../../../actions/client-portal/AllSiteApi"
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import SiteAccess from "./SpecificSiteTab/SiteAccessLog";
import { decryptId } from "../../../../../common/encryptDecrypt/encryptdecrypt";
const { TabNav, TabList, TabContent } = Tabs

const SpecificSite = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [currentTab, setCurrrentTab] = useState("tab1")
    const { id, siteId } = useParams();
    const handleUnauthorizedAccess = useUnauthorizedHandler()
    const dispatch = useDispatch()
    const permissionDataUpdate = useSelector((state) => state?.permissions?.permissionData);
    const [forceRender, setForceRender] = useState(false);
    const [siteData, setSiteData] = useState()

    let organisation_id;
    const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
    if (storedData !== null) {
        organisation_id = storedData.organisation_id;
    }

    useEffect(() => {
        if (permissionDataUpdate !== null) {
            localStorage.setItem("permission_data", JSON.stringify(permissionDataUpdate));
            setForceRender(prevForceRender => !prevForceRender);
            if (permissionDataUpdate?.sites_view == false) {
                if (location.pathname.startsWith('/client/sites/specific-site/') && siteId) {
                    navigate('/client/dashboard');
                }
            }
        }
    }, [permissionDataUpdate]);

    let permission_data;
    const permissionData = JSON.parse(localStorage?.getItem("permission_data"));

    if (permissionData !== null) {
        permission_data = permissionData;
    }
    const sitePermission = permission_data?.sites_write == true ? false : true
    const handleGetClientSiteById = useCallback(() => {
        const siteOrgId=decryptId(siteId)
        const setId = { site_id: siteOrgId, client_org_id: organisation_id };
        
        dispatch(GetClientSiteById(setId)).then((response) => {
            if (response.success) {
                const arrayData = response.data
                setSiteData(arrayData)
            } else if (response.status === 401) {
                handleUnauthorizedAccess();
            }
        });
    }, [dispatch, siteId]);

    useEffect(() => {
        handleGetClientSiteById();
    }, [handleGetClientSiteById]);


    return (
        // <Layout>
        <React.Fragment>
            <SiteName siteName={siteData?.site_name} />
            <Tabs value={currentTab} variant="pill">
                <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5">
                    <TabList>
                        <TabNav value="tab1" onClick={() => setCurrrentTab("tab1")}>Overview</TabNav>
                        <TabNav value="tab2" onClick={() => setCurrrentTab("tab2")}>Site Inductions</TabNav>
                        <TabNav value="tab3" onClick={() => setCurrrentTab("tab3")}>Inductees</TabNav>
                        <TabNav value="tab4" onClick={() => setCurrrentTab("tab4")}>Site Access Log</TabNav>
                        <TabNav value="tab5" onClick={() => setCurrrentTab("tab5")}>Visitor log</TabNav>
                        <TabNav value="tab6" onClick={() => setCurrrentTab("tab6")}>Site Settings</TabNav>
                        <TabNav value="tab7" onClick={() => setCurrrentTab("tab7")}>Audit Log</TabNav>
                    </TabList>
                </div>
                <div className="">
                    <TabContent value="tab1">
                        <Overview
                            sitePermission={sitePermission}
                            siteData={siteData}
                            setCurrrentTab={setCurrrentTab}
                            handleGetClientSiteById={handleGetClientSiteById} />
                    </TabContent>
                    <TabContent value="tab2">
                        <SiteInduction
                            sitePermission={sitePermission}
                            siteData={siteData} />
                    </TabContent>
                    <TabContent value="tab3">
                        <Inductees 
                        sitePermission={sitePermission}   
                        siteData={siteData} />
                    </TabContent>
                    <TabContent value="tab4">
                        <SiteAccess 
                        sitePermission={sitePermission}
                        siteData={siteData}  />
                    </TabContent>
                    <TabContent value="tab5">
                        <Visitorlog
                            siteData={siteData}
                            siteName={siteData?.site_name}
                            sitePermission={sitePermission} />
                    </TabContent>
                    <TabContent value="tab6">
                        <SiteSettings
                            siteData={siteData}
                            sitePermission={sitePermission}
                            handleGetClientSiteById={handleGetClientSiteById} />
                    </TabContent>
                    <TabContent value="tab7">
                        <AuditLog sitePermission={sitePermission}
                          siteData={siteData}  />
                    </TabContent>


                </div>
            </Tabs>
        </React.Fragment>
        //  </Layout>
    )
}
export default SpecificSite