import React, { useEffect, useRef, useState } from "react";
import Table from "../../../../../common/element/Table";
import Tooltip from "../../../../../common/element/Tooltip";
import Tag from "../../../../../common/element/Tag";
import Button from "../../../../../common/element/Buttons";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import Tabs from "../../../../../common/element/Tabs";
import { notify } from "react-notify-toast";
import { useDispatch, useSelector } from "react-redux";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import Scrollbar from "../../../../../common/element/ScrollBar";
import Badge from "../../../../../common/element/Badge";
import { UpdateProviderDoc } from "../../../../../actions/admin-provider/GetUpdateContractorDoc";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import moment from "moment";
import ClientInput from "../../../../../common/element/Input/clientInput";
import { GetDocumentHistoryById } from "../../../../../actions/admin-provider/GetDocumentHistoryById";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import { DeleteProviderDoc } from "../../../../../actions/admin-provider/DeleteContractorDoc";
import Upload from "../../../../../common/element/Upload";
// import AddDocument from "./AddDocument";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UpdateProviderDocStatus } from "../../../../../actions/admin-provider/clients/UpdateProviderDocStatus";
import { GetProviderApprovalDocbyId } from "../../../../../actions/client-portal/GetProviderApprovalDocbyId";
import { GetSignedUrlByKey } from "../../../../../actions/public/GetSignedUrlByKey";
const { Tr, Th, Td, THead, TBody } = Table;
const { TabNav, TabList, TabContent } = Tabs;
const Documents = (props) => {
  const navigate = useNavigate()
  let trading_name;

  let organisation_id;
  let current_user;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    organisation_id = storedData.organisation_id;
    trading_name = storedData?.trading_name;
    current_user = `${storedData?.userName?.first_name || ''} ${storedData?.userName?.last_name || ''}`;
  }
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const client_org_id = queryParams.get("client_org_id");
  const provider_doc_appr_id = queryParams.get("provider_doc_appr_id");

  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const GettAllFunctions = useSelector((state) => state);
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  });
  const dispatch = useDispatch();
  const [updateData, SetupdateData] = useState([]);

  const [specificData, SetspecificData] = useState([]);
  const [specificDataUpdate, SetspecificDataUpdate] = useState([]);
  const [dialogIsOpen, setIsOpen] = useState(true);
  const [dialogAddDocIsOpen, setDialogAddDocIsOpen] = useState(false);
  // const [checkEdit, SetCheckEdit] = useState(false);
  const [indexData, setIndexData] = useState();
  const [refetchData, setrefetchData] = useState(false);
  const [closePannel, setClosePannel] = useState(false);
  const [deleteDiolog, setDeleteDiolog] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [docTypeName, setDocTypeName] = useState();
  const [updateState, setUpdateState] = useState({ id: "", name: "" });
  const [pdfFile, setPdfFile] = useState("");
  const [checkAllData, setcheckAllData] = useState();
  const [docStatus, SetDocStatus] = useState();
  const [closeAddDocPannel, setCloseAddDocPannel] = useState(false);
  const [statusData, setStatusData] = useState({
    provider_doc_appr_id: "",
    approval_status: "",
    doc_name: "",
    client_trading_name: trading_name,
    provider_trading_name: "contractor trading(static created)",
    message: "",
  });




  const onDialogClose = (e) => {
    setIsOpen(false);
    SetspecificData([]);
    navigate("/client/dashboard")
    // SetCheckEdit(false);
    // dispatch(reSetData())
  };

  const handleTabClick = (event) => {
    if (event.target.textContent === "History") {
      dispatch(
        GetDocumentHistoryById({
          document_id: specificData?.document_id,
          provider_doc_appr_id: specificData?.provider_doc_appr_id,
        })
      );
    }
  };

  // Documentb Edit function
  const handleEditForm = (e, customField) => {
    if (customField == "emptyDoc_file") {
      SetupdateData(e[0]);
    } else {
      SetupdateData(e.target.value);
    }
    SetspecificDataUpdate((prevalue) => {
      if (customField == "customField") {
        const doctype_fields_data = {
          ...prevalue.doctype_fields_data, // If it already exists in prevalue
          [e.target.name]: e.target.value, // Assuming 'name' is the key where you want to store the value
          // If you have different keys, replace 'name' with your desired key
        };
        return {
          ...prevalue,
          doctype_fields_data: doctype_fields_data,
        };
      } else if (customField == "doc_file") {
        return {
          ...prevalue,
          ["doc_file"]: e.target.files[0],
        };
      } else if (customField == "emptyDoc_file") {
        return {
          ...prevalue,
          ["doc_file"]: e[0],
        };
      } else {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      }
    });
    SetspecificData((prevalue) => {
      if (customField == "customField") {
        return {
          ...prevalue,
          customField: {
            ...prevalue.customField,
            [e.target.name]: e.target.value,
          },
        };
      } else if (customField == "doc_file") {
        return {
          ...prevalue,
          ["doc_file"]: e.target.files[0],
        };
      } else if (customField == "emptyDoc_file") {
        return {
          ...prevalue,
          ["doc_file"]: e[0],
        };
      } else {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      }
    });
  };
  // Update data in a state
  useEffect(() => {
    let customColumnName = "";
    if (specificData?.document_id) {
      if (specificDataUpdate?.doctype_fields_data) {
        customColumnName = Object.keys(specificDataUpdate?.doctype_fields_data);
      }

      let columnName;
      if (customColumnName) {
        columnName = {
          ...specificDataUpdate,
          [customColumnName]: customColumnName,
        };
      } else {
        columnName = specificDataUpdate;
      }
      const columnData = Object.keys(columnName).filter(
        (key) =>
          key !== "document_id" &&
          key !== "column_names" &&
          key !== "doctype_fields_data"
      );
      SetspecificDataUpdate((prevData) => ({
        ...prevData,
        column_names: columnData,
      }));
    }
  }, [updateData]);
  // Document updatData function
  let UpdateDocData;
  if (specificDataUpdate?.doctype_fields_data) {
    UpdateDocData = {
      ...specificDataUpdate,
      ["doctype_fields_data"]: JSON.stringify(
        specificDataUpdate.doctype_fields_data
      ),
    };
  } else {
    UpdateDocData = specificDataUpdate;
  }
  const updatData = () => {
    let updatePayload = {
      ...UpdateDocData,
      ...{ document_id: specificData?.document_id },
    };
    dispatch(UpdateProviderDoc(updatePayload))
      .then((response) => {
        if (response.success) {
          // SetCheckEdit(false);
          SetspecificDataUpdate({
            document_id: specificData?.document_id,
            column_names: [""],
          });
          setrefetchData(!refetchData);
          SetDocStatus("pending");
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {
        notify.show(error, "error");
      });
  };
  // Function to toggle the main rows
  // const toggleClick = (index) => {
  //   const newToggleStates = [...toggleState];
  //   newToggleStates[index] = !newToggleStates[index];
  //   setToggleState(newToggleStates);
  //   // Close all child rows when closing a parent row
  //   // setExpandedChildRows({});

  //   setExpandedRows((prevExpandedRows) => {
  //     const newExpandedRows = [...prevExpandedRows];
  //     newExpandedRows[index] = !newExpandedRows[index];
  //     return newExpandedRows;
  //   });
  // };

  const onDeleteDialogClose = () => {
    setDeleteDiolog(false);
  };

  // const openDeleteHandle = (item, worker_doc_approval_id, innerTab) => {
  //   let singleItem = item.contr_doc;
  //   if (innerTab) {
  //     setDeleteData({ worker_doc_approval_id: worker_doc_approval_id });
  //   } else {
  //     if (singleItem.is_swms_doc) {
  //       setDeleteData({
  //         doc_type: "swms",
  //         document_id: singleItem.document_id,
  //         document_swm_type_id:
  //           singleItem.swm_doc_type.document_swm_type_id,
  //         swm_type_id: singleItem?.swm_doc_type?.swm_type?.swm_type_id,
  //         worker_doc_approval_id: "",
  //       });
  //     } else {
  //       setDeleteData({
  //         doc_type: singleItem.document_type.document_type,
  //         document_id: singleItem.document_id,
  //         worker_doc_approval_id: "",
  //       });
  //     }
  //   }

  //   setDeleteDiolog(true);
  // };

  const deleteModuleConfirmation = () => {
    dispatch(DeleteProviderDoc(deleteData)).then((response) => {
      if (response.success) {
        setDeleteDiolog(false);
        props.refetch(true);
      }
    });
  };
  let docName;
  if (specificData?.doc_file) {
    if (specificData?.doc_file.name) {
      docName = specificData?.doc_file.name;
    } else {
      docName = specificData?.doc_file.split("-");
      const partAfterThirdHyphen = docName.slice(4).join("-");
      // Decode the URL component to get the actual data
      docName = decodeURIComponent(partAfterThirdHyphen);
    }
  } else {
    docName = "";
  }

  const fileInputRef = useRef(null);

  const UploadDoc = () => {
    // Trigger click event on file input
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (specificData?.doc_file?.name) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setPdfFile(base64String);
      };
      reader.readAsDataURL(specificData?.doc_file);
    } else {
      if (specificData?.doc_file) {
        dispatch(
          GetSignedUrlByKey({ url: specificData?.doc_file }, "client")
        ).then((response) => {
          if (response.success) {
            setPdfFile(response?.data);
          }
        });
      }
    }
  }, [specificData?.doc_file]);

  useEffect(() => {
    if (closeAddDocPannel) {
      setDialogAddDocIsOpen(false);
      setCloseAddDocPannel(false);
      setrefetchData(!refetchData);
    }
  }, [closeAddDocPannel]);

  const onDialogAddDocClose = () => {
    setDialogAddDocIsOpen(false);
  };

  const OpenAddnewDoc = (e, index, innerIndex) => {
    setIndexData({ index: index, innerIndex: innerIndex });
    setDocTypeName(e);
    setDialogAddDocIsOpen(true);
  };
    
  // const OpenViewDoc = (data) => {
  //   SetDocStatus(data?.DocAppr?.approval_status);
  //   // SetspecificData(data)
  //   SetspecificData({
  //     document_id: data?.DocAppr?.provApprDoc?.document_id,
  //     provider_doc_appr_id: data?.DocAppr?.provider_doc_appr_id,
  //     document_type: data?.DocAppr?.provApprDoc?.document_type_id,
  //     doc_type_name: data?.document_type?.doc_type_name,
  //     doc_name: data?.DocAppr?.provApprDoc?.doc_name,
  //     document_desc: data?.DocAppr?.provApprDoc?.expiry_date_notes,
  //     expiry_date: data?.DocAppr?.provApprDoc?.expiry_date,
  //     customField: data?.DocAppr?.provApprDoc?.doctype_fields_data,
  //     // number_of_workers: data?.doc_insurance?.number_of_workers,
  //     insured_amount: data?.DocAppr?.provApprDoc?.amount_insured,
  //     policy_no: data?.DocAppr?.provApprDoc?.policy_no,
  //     // trading_name: data?.swm_doc_type?.swm_clients_org,
  //     issuing_authority: data?.DocAppr?.provApprDoc?.issuer,
  //     created_date: data?.DocAppr?.provApprDoc?.created_date,
  //     // issue_date: data?.docData?.issue_date,
  //     // is_swms_doc: data?.is_swms_doc,
  //     doc_file: data?.DocAppr?.provApprDoc?.doc_file,
  //   });
  //   setStatusData((prevalue) => {
  //     return {
  //       ...prevalue,
  //       ["provider_doc_appr_id"]: data?.DocAppr?.provider_doc_appr_id,
  //     };
  //   });
  //   setStatusData((prevalue) => {
  //     return {
  //       ...prevalue,
  //       ["doc_name"]: data?.DocAppr?.provApprDoc?.doc_name,
  //     };
  //   });

  //   setIsOpen(true);
  // };

  useEffect(() => {
    let complianceDocIDs = props?.indivisualData?.orgChklists
      ?.flat()
      .flatMap((checklist) =>
        checklist.compDocs.map((doc) => doc.compliance_doc_id)
      );
    // dispatch(
    //   providerGetSubmissionDocsOfProvider({
    //     compDocIds: JSON.stringify(complianceDocIDs),
    //     provider_org_id: id,
    //   })
    // ).then((response) => {
    //   if (response.success) {
    //     SetgetIndividual(response.data);
    //     SetClientOrgData(response?.data?.clientOrg);
    //     SetUpdateStatus(true);
    //   }
    // }, []);
  }, [updateState, refetchData]);

  useEffect(() => {
    if (closePannel) {
      setClosePannel(false);
      onDialogAddDocClose();
    }
    // closePannel={setClosePannel}
  }, [closePannel]);

  const HandleUpdateStatus = (approval_status) => {
    let payload = { ...statusData, ...{ approval_status: approval_status, current_user: current_user, organisation_id: organisation_id, trading_name: trading_name , client_action :"yes"} };
    dispatch(UpdateProviderDocStatus(payload)).then((response) => {
      if (response.success) {
        setrefetchData(!refetchData);
        SetDocStatus(approval_status);
        setStatusData((prevalue) => {
          return {
            ...prevalue,
            ["message"]: "",
          };
        });
      }
    });
  };

  const HandleStatusChanges = (e) => {
    setStatusData((prevalue) => {
      return {
        ...prevalue,
        ["message"]: e.target.value,
      };
    });
  };

  useEffect(() => {
    dispatch(
      GetProviderApprovalDocbyId({
        provider_doc_appr_id: provider_doc_appr_id,
      })
    ).then((response) => {
      if (response.success) {
        let data = response.data
        setcheckAllData(data?.provApprDoc?.document_type)
        SetDocStatus(data?.approval_status);
        // SetspecificData(data)
        SetspecificData({
          document_id: data?.provApprDoc?.document_id,
          provider_doc_appr_id: data?.provider_doc_appr_id,
          document_type: data?.provApprDoc?.document_type_id,
          doc_type_name: data?.provApprDoc?.document_type?.doc_type_name,
          doc_name: data?.provApprDoc?.doc_name,
          document_desc: data?.provApprDoc?.expiry_date_notes,
          expiry_date: data?.provApprDoc?.expiry_date,
          customField: data?.provApprDoc?.doctype_fields_data,
          // number_of_workers: data?.doc_insurance?.number_of_workers,
          insured_amount: data?.provApprDoc?.amount_insured,
          policy_no: data?.provApprDoc?.policy_no,
          // trading_name: data?.swm_doc_type?.swm_clients_org,
          issuing_authority: data?.provApprDoc?.issuer,
          created_date: data?.provApprDoc?.created_date,
          // issue_date: data?.docData?.issue_date,
          // is_swms_doc: data?.is_swms_doc,
          doc_file: data?.provApprDoc?.doc_file,
        });
        setStatusData((prevalue) => {
          return {
            ...prevalue,
            ["provider_doc_appr_id"]: data?.provider_doc_appr_id,
          };
        });
        setStatusData((prevalue) => {
          return {
            ...prevalue,
            ["doc_name"]: data?.provApprDoc?.doc_name,
          };
        });

        setIsOpen(true);
      }
    });
  }, []);



  return (
    <React.Fragment>
      {/* <Dialog
        isOpen={dialogAddDocIsOpen}
        onClose={onDialogAddDocClose}
        onRequestClose={onDialogAddDocClose}
        bodyOpenClassName="overflow-hidden"
      >
        <AddDocument
          docTypeName={docTypeName}
          updateData={setUpdateState}
          indivisualData={props?.indivisualData}
          closePannel={setClosePannel}
          closeAddDocPannel={setCloseAddDocPannel}
        />
      </Dialog> */}

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="documentDialog"
      >
        <div className="flex h-full">
          <div className="left-block-wrap h-full w-1/4">
            <Tabs defaultValue="tab1" variant="pill">
              <TabList
                className="bg-gray-500 rounded-lg p-1"
                onClick={(val) => handleTabClick(val)}
              >
                <TabNav value="tab1">Details</TabNav>
                <TabNav value="tab2">History</TabNav>
              </TabList>
              <div className="h-cal-full ">
                <Scrollbar>
                  <div className="">
                    <TabContent value="tab1">
                      <div className="mb-4">
                        <div className="font-bold text-white text-xl mb-3 mt-3">
                          Status
                        </div>
                        {/* <Tag className="text-blue-700 bg-blue-50">{docStatus}</Tag> */}
                        <Tag
                          className={
                            docStatus == "pending"
                              ? "bg-blue-100 text-blue-700"
                              : docStatus == null
                                ? "bg-gray-100 text-gray-700"
                                : docStatus == "client_approved_action"
                                  ? "bg-success-100 text-success-700 flex"
                                  : docStatus == "approved"
                                    ? "bg-success-100 text-success-700 flex"
                                    : docStatus == "admin_reject"
                                      ? "bg-error-100 text-error-700"
                                      : "bg-error-100 text-error-700"
                          }
                        >
                          {docStatus == "pending" ? (
                            "Awaiting review"
                          ) : docStatus == null ? (
                            "Pending Upload"
                          ) : docStatus == "client_approved_action" ? (
                            <div className="flex gap-3 items-center w-fit">
                              Approved
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="15"
                                viewBox="0 0 13 15"
                                fill="none"
                              >
                                <path
                                  d="M0.667969 13.6426L0.667969 2.30924M0.667969 8.30924H5.6013C5.97467 8.30924 6.16135 8.30924 6.30396 8.23658C6.4294 8.17267 6.53139 8.07068 6.59531 7.94524C6.66797 7.80263 6.66797 7.61595 6.66797 7.24258V2.70924C6.66797 2.33588 6.66797 2.14919 6.59531 2.00658C6.53139 1.88114 6.4294 1.77916 6.30396 1.71524C6.16135 1.64258 5.97467 1.64258 5.6013 1.64258H1.73464C1.36127 1.64258 1.17458 1.64258 1.03198 1.71524C0.906534 1.77916 0.804547 1.88114 0.740631 2.00658C0.667969 2.14919 0.667969 2.33588 0.667969 2.70924V8.30924ZM6.66797 2.97591H10.9346C11.308 2.97591 11.4947 2.97591 11.6373 3.04857C11.7627 3.11249 11.8647 3.21448 11.9286 3.33992C12.0013 3.48253 12.0013 3.66921 12.0013 4.04258V8.57591C12.0013 8.94928 12.0013 9.13596 11.9286 9.27857C11.8647 9.40401 11.7627 9.506 11.6373 9.56992C11.4947 9.64258 11.308 9.64258 10.9346 9.64258H7.73464C7.36127 9.64258 7.17458 9.64258 7.03197 9.56992C6.90653 9.506 6.80455 9.40401 6.74063 9.27857C6.66797 9.13596 6.66797 8.94928 6.66797 8.57591V2.97591Z"
                                  stroke="#039855"
                                  stroke-width="1.33333"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>{" "}
                            </div>
                          ) : docStatus == "approved" ? (
                            "Approved"
                          ) : docStatus == "admin_reject" ? (
                            "Reject"
                          ) : (
                            "Action Required"
                          )}{" "}
                        </Tag>

                        <hr className="border-t border-gray-400 my-5" />
                        <div className="font-bold text-white text-xl mb-1">
                          <span></span>

                          {/* {specificData?.doc_name} */}
                          <div className="mb-4">
                            <p className="text-white font-semibold">
                              {specificData?.doc_name}
                            </p>
                          </div>
                          {/* <Button
                            onClick={() => SetCheckEdit(true)}
                            className="ml-auto"
                          >
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                                stroke="#fff"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Button> */}
                        </div>
                        {specificData && (
                          <label className="text-gray-100">
                            {moment(specificData?.created_date).format(
                              "DD/MM/YYYY"
                            )}{" "}
                            at{" "}
                            {moment(specificData?.created_date).format("HH:mm")}
                          </label>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="text-gray-100 text-12 font-medium">
                          Document type
                        </label>
                        <p className="text-white font-semibold">
                          {specificData?.doc_type_name}
                        </p>
                      </div>

                      {checkAllData?.req_issuer && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Issuing authority
                          </label>
                          <p className="text-white font-semibold">
                            {specificData?.issuing_authority}
                          </p>
                        </div>
                      )}
                      {/* {clientOrgData?.length && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Clients
                          </label>
                          <p className="text-white font-semibold">
                            {clientOrgData
                              ?.map((innerData) => innerData?.trading_name)
                              .join(", ")}
                          </p>
                        </div>
                      )} */}

                      {checkAllData?.req_policy_no && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Policy number
                          </label>
                          <p className="text-white font-semibold">
                            {specificData?.policy_no}
                          </p>
                        </div>
                      )}
                      {checkAllData?.req_amount_insured && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Amount insured
                          </label>
                          <p className="text-white font-semibold">
                            ${specificData?.insured_amount}
                          </p>
                        </div>
                      )}
                      {checkAllData?.allow_expiry && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Expiry date
                          </label>
                          <p className="text-white font-semibold">
                            {moment(specificData?.expiry_date).format(
                              "DD/MM/YYYY"
                            )}
                          </p>
                        </div>
                      )}
                      {checkAllData?.req_expiry_date_notes && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Additional info
                          </label>
                          <p className="text-white font-semibold">
                            {specificData?.document_desc}
                          </p>
                        </div>
                      )}
                      {specificData?.customField &&
                        Object.keys(specificData?.customField)?.map(
                          (item, index) => {
                            return (
                              <div className="mb-4" key={index}>
                                <label className="text-gray-100 text-12 font-medium">
                                  {item}
                                </label>
                                <p className="text-white font-semibold">
                                  {specificData?.customField[item]}
                                </p>
                              </div>
                            );
                          }
                        )}
                      {/* <hr className="border-t border-gray-400 my-5" /> */}
                      {/* <div className="font-bold text-white text-xl mb-4 ">
                        Validation
                      </div>
                      <div className="mb-4">
                        <label className="text-gray-100 text-12 font-medium mb-2 block">
                          Message
                        </label>
                        <textarea
                          id=""
                          name=""
                          className="w-full"
                          placeholder="Enter a message..."
                        ></textarea>
                        <p className="text-gray-100 text-12">
                          Provide a message about the document, if necessary.
                        </p>
                      </div> */}

                      <hr className="border-t border-gray-400 my-5" />
                      <div className="font-bold text-white text-xl mb-4 ">
                        Validation
                      </div>
                      <div className="mb-4">
                        <label className="text-gray-100 text-12 font-medium mb-2 block">
                          Message
                        </label>
                        <textarea
                          id=""
                          name=""
                          value={statusData?.message}
                          className="w-full"
                          placeholder="Enter a message..."
                          onChange={HandleStatusChanges}
                        ></textarea>
                        <p className="text-gray-100 text-12">
                          Provide a message about the document, if necessary.
                        </p>
                      </div>
                      {(docStatus == "pending" || docStatus == "client_approval_req") && (
                        <div className="flex justify-end gap-2">
                          <Button
                            className="text-error-700 font-semibold bg-white h-10 w-20 rounded-lg"
                            onClick={() => HandleUpdateStatus("client_reject")}
                          >
                            Reject
                          </Button>
                          <Button
                            className="text-blue-700 font-semibold bg-white h-10 w-50 rounded-lg p-2"
                            onClick={() =>
                              HandleUpdateStatus("client_approved_action")
                            }
                          >
                            Approve with action
                          </Button>
                          <Button
                            className="text-white font-semibold bg-blue-700 h-10 w-20 rounded-lg"
                            onClick={() => HandleUpdateStatus("approved")}
                          >
                            Approve
                          </Button>
                        </div>
                      )}
                    </TabContent>
                    <TabContent value="tab2">
                      {GettAllFunctions?.DocumentHistoryData?.data?.data?.map(
                        (item, index) =>
                          item.desc_html.map((innerItem, index) => {
                            let documetnName;
                            if (item?.new_value.doc_file) {
                              documetnName =
                                item?.new_value.doc_file.split("-");
                              const partAfterThirdHyphen = documetnName
                                .slice(4)
                                .join("-");
                              // Decode the URL component to get the actual data
                              documetnName =
                                decodeURIComponent(partAfterThirdHyphen);
                            } else {
                              documetnName = "";
                            }
                            let parser = new DOMParser();
                            let doc = parser.parseFromString(
                              innerItem,
                              "text/html"
                            );
                            return (
                              <React.Fragment>
                                {item?.column_names?.includes("doc_file") ? (
                                  <div className="mb-4  pb-4">
                                    <label className="text-gray-100">
                                      {moment(item?.updated_date).format(
                                        "DD MMMM YYYY [at] HH:mm"
                                      )}
                                    </label>
                                    <p className="font-semibold text-white mt-2">
                                      {doc.body.textContent}
                                    </p>
                                    <div className=" p-4 rounded-lg bg-white font-medium mt-2 flex">
                                      <span className="w-9 h-9 rounded-full bg-blue-50 border-4 border-blue-100 flex justify-center items-center">
                                        <svg
                                          width="16"
                                          height="17"
                                          viewBox="0 0 16 17"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M10.8 14.692H4.62c-.403 0-.605 0-.698-.08a.333.333 0 0 1-.116-.28c.01-.122.152-.264.438-.55l5.668-5.669c.264-.264.396-.396.549-.445a.667.667 0 0 1 .412 0c.152.05.284.181.548.445L14 10.693v.8m-3.2 3.2c1.12 0 1.68 0 2.108-.219a2 2 0 0 0 .874-.874c.218-.428.218-.987.218-2.108m-3.2 3.2H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 13.172 2 12.613 2 11.492v-5.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v5.6M7 6.36a1.333 1.333 0 1 1-2.667 0A1.333 1.333 0 0 1 7 6.36z"
                                            stroke="#5786CC"
                                            strokeWidth="1.333"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </span>
                                      <div className="ml-3">
                                        <span className="font-medium text-gray-700 text-xs">
                                          {documetnName}
                                        </span>
                                        <p className="text-gray-600 text-xs">
                                          {(
                                            item?.new_value?.file_size / 1024
                                          ).toFixed(2)}{" "}
                                          KB
                                        </p>
                                      </div>
                                      <Button className="ml-auto">
                                        <a
                                          href={item?.new_value.doc_file}
                                          download
                                        >
                                          <svg
                                            width="20"
                                            height="21"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M17.5 8.458v-5m0 0h-5m5 0-6.667 6.667m-2.5-5H6.5c-1.4 0-2.1 0-2.635.272A2.5 2.5 0 0 0 2.772 6.49C2.5 7.024 2.5 7.725 2.5 9.125v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h5.333c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.093-1.093c.272-.535.272-1.235.272-2.635v-1.833"
                                              stroke="#475467"
                                              strokeWidth="1.667"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </a>
                                      </Button>
                                    </div>
                                  </div>
                                ) : item?.column_names?.includes("message") ? (
                                  <div className="mb-4  pb-4">
                                    <label className="text-gray-100">
                                      {moment(item?.updated_date).format(
                                        "DD MMMM YYYY [at] HH:mm"
                                      )}
                                    </label>
                                    <p className="font-semibold text-white mt-2">
                                      {doc.body.textContent}
                                    </p>
                                    {item.new_value.message && (
                                      <div className=" p-4 rounded-lg bg-white font-medium mt-2 flex">
                                        {/* <span className="w-9 h-9 rounded-full bg-blue-50 border-4 border-blue-100 flex justify-center items-center">
                                      </span> */}
                                        <div className="ml-3">
                                          <span className="font-medium text-gray-700 text-xs">
                                            {item.new_value.message}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    key={index}
                                    className="mb-4 border-b border-gray-500 pb-4"
                                  >
                                    <label className="text-gray-100">
                                      {moment(item?.updated_date).format(
                                        "DD MMMM YYYY [at] HH:mm"
                                      )}
                                    </label>
                                    <p className="font-semibold text-white mt-2">
                                      {doc.body.textContent}
                                    </p>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })
                      )}
                    </TabContent>
                  </div>
                </Scrollbar>
              </div>
            </Tabs>
          </div>
          <div className="right-block-wrap h-full w-4/5">
            <div className="doc-view pdf_view_scroll">
              {specificData && specificData?.doc_file && pdfFile && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={pdfFile}
                    plugins={[defaultLayoutPluginInstance]}
                    theme="dark"
                  />
                </Worker>
              )}
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        isOpen={deleteDiolog}
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            Are you sure you want to delete document?
          </p>
        </div>

        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight className="mr-1.5" onClick={() => setDeleteDiolog(false)}>
            Cancel
          </BtnLight>
          <BtnPrimary
            className="ml-1.5"
            onClick={() => deleteModuleConfirmation()}
          >
            Delete
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default Documents;
