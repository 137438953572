import React, { useState, useEffect, useCallback } from "react";
import Heading from "../../../../../../common/element/Heading";
import AvatarImg from "../../../../../../assets/img/avatars/thumb-8.jpg";
import Icon from "../../../../../../assets/img/img03.jpg";
import { useSelector } from "react-redux";

const WorkerName = (props) => {
  const [WorkerDetails, setWorkerDetails] = useState(null);

  useEffect(() => {
    if (props?.workerSpecficData) {
      setWorkerDetails(props?.workerSpecficData);
    }
  }, [props?.workerSpecficData]);
  if (WorkerDetails?.trading_name) {
    let items = [
      { label: "View All Worker" },
      {
        label: `${WorkerDetails?.first_name || ""} ${
          WorkerDetails?.last_name || ""
        }`,
      }, // Concatenate names with a space in between
    ];

    localStorage.setItem("Breadcrums", JSON.stringify(items)); // Store the breadcrumb items in localStorage
  }
  const {ProviderWorkerSpecificData}=useSelector((state)=>state)
  return (
    <React.Fragment>
      <div className="flex gap-4 flex-row justify-between">
        <div className="flex items-center mb-5">
          <div className="h-16 w-16 rounded-full overflow-hidden mr-5 avtar_img_round">
            {/* <img src={logo === undefined ? Icon : ( logo ? logo : AvatarImg)} alt="" /> */}
            <img
              src={
                ProviderWorkerSpecificData?.avatar === undefined
                  ? Icon
                  : ProviderWorkerSpecificData?.avatar
                  ? ProviderWorkerSpecificData?.avatar
                  : AvatarImg
              }
              alt=""
            />
          </div>
          <div className="flex flex-col">
            <Heading className="mb-2">
              {/* {props?.clientData?.trading_name} */}
              {`${WorkerDetails?.worker_individual?.first_name || ""} ${
                WorkerDetails?.worker_individual?.last_name || ""
              }`}
              
            </Heading>
            {/* <div className="flex gap-6 items-center">
              <span
                className={
                       
                  WorkerDetails?.is_org_active
                    ? "badge bg-green-50 text-green-700 py-0.5 px-2 rounded-full font-medium"
                    : "badge bg-red-50 text-red-600 py-0.5 px-2 rounded-full font-medium"
                }
              >
                <span
                  className={
                 
                    WorkerDetails?.is_org_active
                      ? "badge-dot badge-inner mr-1 bg-green-700"
                      : "badge-dot badge-inner mr-1 bg-red-700"
                  }
                ></span>
                {WorkerDetails?.is_org_active
                  ?
                  "Complaince"
                  : "Non-Complaince"}{" "}
              </span>
              <span className="text-gray-900  font-medium">
                <span className="text-gray-400 mr-1 font-semibold">ABN:</span>
              
                {WorkerDetails?.abn_nzbn?WorkerDetails?.abn_nzbn:"00-000-000-000"}
              </span>
            </div> */}
          </div>
        </div>
        {/* <div className="flex gap-3">
          <BtnLight
            onClick={() => openDialog()}
            className="w-24 text-xs text-gray-700 font-semibold flex items-center justify-center"
          >
            Email <img src={EmailIcon} alt="" className="ml-2" />
          </BtnLight>
          <BtnLight
            className="w-24 text-xs text-gray-700 font-semibold flex  items-center justify-center"
            onClick={() => SendClientData()}
          >
            <img src={EditIcon} alt="" className="mr-2" />
            Notes
          </BtnLight>
          <BtnLight
            onClick={() => userSettingShow()}
            className="w-10 flex text-xs text-gray-700 font-semibold justify-center items-center"
          >
            <img src={SettingIcon} alt="" />
          </BtnLight>
        </div> */}
      </div>
      {/* <Dialog
        toOpen={openDialog}
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b  pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={EmailIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Send an email{" "}
            </h5>
            <p className="text-gray-600 ">
              Compose and send an email directly to user
            </p>
          </div>
        </div>
        <div className="flex">
          <div className=" bg-gray-100 rounded-bl-xl">
            <div className="overflow-y-auto w-48   h-595">
              <Scrollbars className="border-r border-gray-200">
                <p className="text-gray-900 font-semibold px-6 pt-6 mb-3">
                  Templates
                </p>
                <ul className="templates-list pb-2">
                  {templateData &&
                    templateData.map((item) => (
                      <li
                        key={item?.email_id}
                        className="border-gray-200 border-b"
                      >
                        <button
                          onClick={(e) => handleTemplate(e, item)}
                          className="threeDotLine w-full"
                        >
                          <Tooltip title={item?.template_name} placement="top">
                            {item?.template_name}
                          </Tooltip>
                        </button>
                      </li>
                    ))}
                </ul>
              </Scrollbars>
            </div>
          </div>
          <div className="pt-6 w-full">
            <div className="overflow-y-auto h-500 px-2">
              <Scrollbars>
                <div className="flex justify-center flex-col w-full mb-4 px-5">
                  <label className="text-xs text-medium text-gray-700 mb-1.5">
                    Recipient*{" "}
                  </label>
                  <div className="flex">
                    <Select
                      type="text"
                      placeholder=""
                      className="w-full h-auto select-options"
                      isClearable={true}
                      isSearchable={true}
                      options={
                        updatedOptions
                          ? updatedOptions
                          : selectedOptionRecipient
                      }
                      value={selectedOptionRecipient}
                      onChange={(e) => handleSelectChange(e, "recipient")}
                      handleSearchChange={(e) =>
                        handleSearchChange(e, "recipient")
                      }
                      isMulti={true}
                    />
                    <div className="flex">
                      <button
                        className={
                          showCC
                            ? "!hidden"
                            : " text-xs text-gray-600 font-semibold ml-3"
                        }
                        onClick={onCcClick}
                      >
                        CC
                      </button>
                      <button
                        className={
                          showBcc
                            ? "!hidden"
                            : "text-xs text-gray-600 font-semibold ml-3"
                        }
                        onClick={onBccClick}
                      >
                        BCC
                      </button>
                    </div>
                  </div>
                  {emailToError?.recipientError && (
                    <div className="text-red-500 text-sm mt-1">
                      {emailToError?.recipientError}
                    </div>
                  )}
                </div>
                <div
                  className={
                    !showCC
                      ? "!hidden"
                      : "flex justify-center flex-col w-full mb-4 px-5"
                  }
                >
                  <label className="text-xs text-medium text-gray-700 mb-1.5">
                    CC{" "}
                  </label>
                  <div className="flex">
                    <Select
                      type="text"
                      placeholder=""
                      className="w-full h-auto select-options"
                      isClearable={true}
                      isSearchable={true}
                      options={
                        updatedOptions ? updatedOptions : selectedOptionCC
                      }
                      value={selectedOptionCC}
                      onChange={(e) => handleSelectChange(e, "cc")}
                      handleSearchChange={(e) => handleSearchChange(e, "cc")}
                      isMulti={true}
                    />
                    <svg
                      onClick={onCcClick}
                      className="cursor-pointer my-1 py-1"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m18 6.5-12 12m0-12 12 12"
                        stroke="#667085"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  {emailToError?.ccError && (
                    <div className="text-red-500 text-sm mt-1">
                      {emailToError?.ccError}
                    </div>
                  )}
                </div>
                <div
                  className={
                    !showBcc
                      ? "!hidden"
                      : "flex justify-center flex-col w-full mb-4 px-5"
                  }
                >
                  <label className="text-xs text-medium text-gray-700 mb-1.5">
                    BCC{" "}
                  </label>
                  <div className="flex">
                    <Select
                      type="text"
                      placeholder=""
                      className="w-full h-auto select-options"
                      isClearable={true}
                      isSearchable={true}
                      options={
                        updatedOptions ? updatedOptions : selectedOptionBCC
                      }
                      value={selectedOptionBCC}
                      onChange={(e) => handleSelectChange(e, "bcc")}
                      handleSearchChange={(e) => handleSearchChange(e, "bcc")}
                      isMulti
                    />
                    <svg
                      onClick={onBccClick}
                      className="cursor-pointer my-1 py-1"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m18 6.5-12 12m0-12 12 12"
                        stroke="#667085"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  {emailToError?.bccError && (
                    <div className="text-red-500 text-sm mt-1">
                      {emailToError?.bccError}
                    </div>
                  )}
                </div>
                <div className="flex justify-center flex-col w-full mb-4 px-5">
                  <label className="text-xs text-medium text-gray-700 mb-1.5">
                    Subject*{" "}
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    value={templateSub}
                    className="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    onChange={(e) => {
                      setTemplateSub(e.target.value);
                      setSubjectError("");
                    }}
                  />
                  {subjectError && (
                    <div className="text-red-500 text-sm mt-1">
                      {subjectError}
                    </div>
                  )}
                </div>
                <div className="flex justify-center flex-col w-full mb-4 px-5">
                  <label className="text-xs text-medium text-gray-700 mb-1.5">
                    Message*{" "}
                  </label>
                  <RichTextEditor
                    value={templateMessage}
                    onChange={(value) => setTemplateMessage(value)}
                  />
                </div>
                <div className="flex justify-center flex-col w-full mb-5 px-5">
                  <label className="text-xs font-medium block mb-1 mt-3">
                    Attachment
                  </label>
                  <Upload
                    draggable
                    className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                    onChange={handleFileChange}
                    onFileRemove={handleFileRemove}
                    multiple={true}
                    maxFileSize={25 * 1024 * 1024}
                  >
                    <span className="text-blue-700 font-semibold">
                      Click to upload
                    </span>{" "}
                    <span className="font-medium text-gray-600">
                      or drag and drop
                    </span>
                    <p className="text-gray-600">(max.25mb)</p>
                  </Upload>

                  {emailToError?.docUploadError && (
                    <div className="text-red-500 text-sm mt-1">
                      {emailToError?.docUploadError}
                    </div>
                  )}
                  {uploadedFiles.files &&
                    uploadedFiles.files.map((item, index) =>
                      item?.email_temp_attachment_id ? (
                        <div
                          key={index}
                          className="upload-file flex items-center justify-between mt-2"
                        >
                          <div className="upload-file-left flex items-center">
                            <div className="upload-file-thumbnail">
                              <span className="w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3">
                                <img src={PdfFile} alt="" />
                              </span>
                            </div>
                            <a
                              href={item?.file}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="upload-file-name font-medium text-xs text-gray-700">
                                {item?.fileName}
                              </span>
                            </a>
                          </div>
                          <span
                            className="upload-file-remove w-5"
                            onClick={() => {
                              handleFileRemove(item);
                            }}
                          >
                            <span className="">
                              <img src={trashIcon} alt="" />
                            </span>
                          </span>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                </div>
              </Scrollbars>
            </div>
            <div className="flex p-6 border-t justify-end border-gray-200">
              <BtnLight
                className="mr-1.5"
                variant="plain"
                onClick={onDialogClose}
                disabled={buttonDisabled}
              >
                Cancel
              </BtnLight>
              <BtnPrimary
                disabled={buttonDisabled}
                className="w-full px-5 mr-3"
                onClick={submitEmail}
              >
                {buttonDisabled ? <ThreeDotLoader /> : "Send email"}
              </BtnPrimary>
            </div>
          </div>
        </div>
      </Dialog> */}
      {/* <Dialog
        toOpen={userSettingShow}
        isOpen={userSetting}
        onClose={userSettingHide}
        onRequestClose={userSettingHide}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b  pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={SettingIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Settings
            </h5>
          </div>
        </div>
        <ClientSettings />
      </Dialog> */}
      {/* <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                fill="#D1FADF"
              />
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M32.083 18.8721L21.083 29.8721L16.083 24.8721"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Email sent successfully
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => onAddDialogClose()}>Close</BtnLight>
        </div>
      </Dialog> */}
    </React.Fragment>
  );
};
export default WorkerName;
