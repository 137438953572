import React, { useEffect, useState } from 'react';
import Heading from '../../../../../common/element/Heading';
import { setBreadcrumbs } from '../../../../../redux/classes/breadcrumbsSlice';
import Card from "../../../../../common/element/Card";
import { useParams } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useUnauthorizedHandler from '../../../../../common/snippet/useUnauthorizedHandler';
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="#5786CC"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const Reports = () => {
    const [openAccordions, setOpenAccordions] = useState({
        accordion1: true, // Open by default
        accordion2: true, // Open by default
        accordion3: true, // Open by default
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, siteId } = useParams();
    const handleUnauthorizedAccess = useUnauthorizedHandler();
    const { ClientSpecificProfileData } = useSelector((state) => state);

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                { label: "Reports", url: "/admin/worker/reports", home: "/admin/worker/dashboard" },
            ])
        );
    }, [dispatch]);

    const toggleAccordion = (accordion) => {
        setOpenAccordions((prevState) => ({
            ...prevState,
            [accordion]: !prevState[accordion],
        }));
    };

    const handleNavigate = (value) => {
        navigate(`${value}`);
    };

    return (
        <React.Fragment>
            <div className="flex justify-between mb-5 border-b border-gray-200 pb-5">
                <Heading>Reports</Heading>
            </div>

            {/* Favourites Accordion */}
            <Accordion open={openAccordions.accordion1} icon={<Icon id={1} open={openAccordions.accordion1} />}>
                <AccordionHeader onClick={() => toggleAccordion('accordion1')} className="border-0 text-base">
                    Favourites
                </AccordionHeader>
                <AccordionBody>
                    <div className="flex gap-5">
                        <Card
                            clickable
                            className="w-1/3 flex justify-between rounded-xl card-shadows border border-gray-200 p-5 hover:shadow-lg transition duration-150 ease-in-out"
                            onClick={(e) => console.log('Card Clickable', e)}
                        >
                            <div>
                                <h5 className="font-semibold text-gray-800 text-base">Report AK</h5>
                                <p className="mt-2 text-xs">
                                    Description lorem ipsum dolor sit amet consectetur. Mauris vulputate.
                                </p>
                            </div>
                            <span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_15736_74961)">
                                        <path d="M9.87232 2.41075C10.0431 2.0001 10.6248 2.0001 10.7956 2.41075L12.8617 7.37822C12.9337 7.55134 13.0965 7.66963 13.2834 7.68461L18.6462 8.11454C19.0896 8.15009 19.2693 8.70334 18.9316 8.99267L14.8457 12.4927C14.7033 12.6147 14.6411 12.806 14.6846 12.9884L15.9329 18.2216C16.0361 18.6542 15.5655 18.9962 15.1859 18.7643L10.5946 15.96C10.4346 15.8622 10.2334 15.8622 10.0734 15.96L5.48204 18.7643C5.10249 18.9962 4.63187 18.6542 4.73506 18.2216L5.98337 12.9884C6.02687 12.806 5.96469 12.6147 5.82229 12.4927L1.7364 8.99267C1.39863 8.70334 1.57839 8.15009 2.02172 8.11454L7.38453 7.68461C7.57142 7.66963 7.73423 7.55134 7.80623 7.37822L9.87232 2.41075Z" fill="#FDB022" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_15736_74961">
                                            <rect width="20" height="20" fill="white" transform="translate(0.333984 0.800781)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </Card>

                        <Card
                            clickable
                            className="w-1/3 flex justify-between rounded-xl card-shadows border border-gray-200 p-5 hover:shadow-lg transition duration-150 ease-in-out"
                            onClick={(e) => console.log('Card Clickable', e)}
                        >
                            <div>
                                <h5 className="font-semibold text-gray-800 text-base">Custom report AM</h5>
                                <p className="mt-2 text-xs">
                                    Description lorem ipsum dolor sit amet consectetur. Mauris vulputate.
                                </p>
                            </div>
                            <span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_15736_74961)">
                                        <path d="M9.87232 2.41075C10.0431 2.0001 10.6248 2.0001 10.7956 2.41075L12.8617 7.37822C12.9337 7.55134 13.0965 7.66963 13.2834 7.68461L18.6462 8.11454C19.0896 8.15009 19.2693 8.70334 18.9316 8.99267L14.8457 12.4927C14.7033 12.6147 14.6411 12.806 14.6846 12.9884L15.9329 18.2216C16.0361 18.6542 15.5655 18.9962 15.1859 18.7643L10.5946 15.96C10.4346 15.8622 10.2334 15.8622 10.0734 15.96L5.48204 18.7643C5.10249 18.9962 4.63187 18.6542 4.73506 18.2216L5.98337 12.9884C6.02687 12.806 5.96469 12.6147 5.82229 12.4927L1.7364 8.99267C1.39863 8.70334 1.57839 8.15009 2.02172 8.11454L7.38453 7.68461C7.57142 7.66963 7.73423 7.55134 7.80623 7.37822L9.87232 2.41075Z" fill="#FDB022" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_15736_74961">
                                            <rect width="20" height="20" fill="white" transform="translate(0.333984 0.800781)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </Card>
                    </div>
                </AccordionBody>
            </Accordion>

            {/* Custom Reports Accordion */}
            <Accordion open={openAccordions.accordion2} icon={<Icon id={2} open={openAccordions.accordion2} />}>
                <AccordionHeader onClick={() => toggleAccordion('accordion2')} className="border-0 text-base">
                    Custom reports
                </AccordionHeader>
                <AccordionBody>
                    <div className="flex gap-5">
                        <Card
                            clickable
                            className="w-1/3 flex justify-between rounded-xl card-shadows border border-gray-200 p-5 hover:shadow-lg transition duration-150 ease-in-out"
                            onClick={(e) => console.log('Card Clickable', e)}
                        >
                            <div>
                                <h5 className="font-semibold text-gray-800 text-base">Custom report AJ</h5>
                                <p className="mt-2 text-xs">
                                    Description lorem ipsum dolor sit amet consectetur. Mauris vulputate.
                                </p>
                            </div>
                            <span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_15736_74961)">
                                        <path d="M9.87232 2.41075C10.0431 2.0001 10.6248 2.0001 10.7956 2.41075L12.8617 7.37822C12.9337 7.55134 13.0965 7.66963 13.2834 7.68461L18.6462 8.11454C19.0896 8.15009 19.2693 8.70334 18.9316 8.99267L14.8457 12.4927C14.7033 12.6147 14.6411 12.806 14.6846 12.9884L15.9329 18.2216C16.0361 18.6542 15.5655 18.9962 15.1859 18.7643L10.5946 15.96C10.4346 15.8622 10.2334 15.8622 10.0734 15.96L5.48204 18.7643C5.10249 18.9962 4.63187 18.6542 4.73506 18.2216L5.98337 12.9884C6.02687 12.806 5.96469 12.6147 5.82229 12.4927L1.7364 8.99267C1.39863 8.70334 1.57839 8.15009 2.02172 8.11454L7.38453 7.68461C7.57142 7.66963 7.73423 7.55134 7.80623 7.37822L9.87232 2.41075Z" fill="#EAECF0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_15736_74961">
                                            <rect width="20" height="20" fill="white" transform="translate(0.333984 0.800781)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </Card>
                    </div>
                </AccordionBody>
            </Accordion>

            {/* Standard Reports Accordion */}
            <Accordion open={openAccordions.accordion3} icon={<Icon id={3} open={openAccordions.accordion3} />}>
                <AccordionHeader onClick={() => toggleAccordion('accordion3')} className="border-0 text-base">
                    Standard reports
                </AccordionHeader>
                <AccordionBody>
                    <div className="flex gap-5 mb-5">
                        <Card
                            clickable
                            className="w-1/3 flex justify-between rounded-xl card-shadows border border-gray-200 p-5 hover:shadow-lg transition duration-150 ease-in-out"
                        >
                            <div className='cursor-pointer' 
                            onClick={() => handleNavigate("workers-overview")}
                            >
                                <h5 className="font-semibold text-gray-800 text-base">Workers Overview</h5>
                                <p className="mt-2 text-xs">
                                    Description lorem ipsum dolor sit amet consectetur. Mauris vulputate.
                                </p>
                            </div>
                            <span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_15736_74961)">
                                        <path d="M9.87232 2.41075C10.0431 2.0001 10.6248 2.0001 10.7956 2.41075L12.8617 7.37822C12.9337 7.55134 13.0965 7.66963 13.2834 7.68461L18.6462 8.11454C19.0896 8.15009 19.2693 8.70334 18.9316 8.99267L14.8457 12.4927C14.7033 12.6147 14.6411 12.806 14.6846 12.9884L15.9329 18.2216C16.0361 18.6542 15.5655 18.9962 15.1859 18.7643L10.5946 15.96C10.4346 15.8622 10.2334 15.8622 10.0734 15.96L5.48204 18.7643C5.10249 18.9962 4.63187 18.6542 4.73506 18.2216L5.98337 12.9884C6.02687 12.806 5.96469 12.6147 5.82229 12.4927L1.7364 8.99267C1.39863 8.70334 1.57839 8.15009 2.02172 8.11454L7.38453 7.68461C7.57142 7.66963 7.73423 7.55134 7.80623 7.37822L9.87232 2.41075Z" fill="#EAECF0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_15736_74961">
                                            <rect width="20" height="20" fill="white" transform="translate(0.333984 0.800781)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </Card>
                        <Card
                            clickable
                            className="w-1/3 flex justify-between rounded-xl card-shadows border border-gray-200 p-5 hover:shadow-lg transition duration-150 ease-in-out"
                            onClick={(e) => console.log('Card Clickable', e)}
                        >
                            <div className='cursor-pointer'
                            onClick={() => handleNavigate("compliance-checklist")}
                            >
                                <h5 className="font-semibold text-gray-800 text-base">Workers Compliance Checklist</h5>
                                <p className="mt-2 text-xs">
                                    Description lorem ipsum dolor sit amet consectetur. Mauris vulputate.
                                </p>
                            </div>
                            <span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_15736_74961)">
                                        <path d="M9.87232 2.41075C10.0431 2.0001 10.6248 2.0001 10.7956 2.41075L12.8617 7.37822C12.9337 7.55134 13.0965 7.66963 13.2834 7.68461L18.6462 8.11454C19.0896 8.15009 19.2693 8.70334 18.9316 8.99267L14.8457 12.4927C14.7033 12.6147 14.6411 12.806 14.6846 12.9884L15.9329 18.2216C16.0361 18.6542 15.5655 18.9962 15.1859 18.7643L10.5946 15.96C10.4346 15.8622 10.2334 15.8622 10.0734 15.96L5.48204 18.7643C5.10249 18.9962 4.63187 18.6542 4.73506 18.2216L5.98337 12.9884C6.02687 12.806 5.96469 12.6147 5.82229 12.4927L1.7364 8.99267C1.39863 8.70334 1.57839 8.15009 2.02172 8.11454L7.38453 7.68461C7.57142 7.66963 7.73423 7.55134 7.80623 7.37822L9.87232 2.41075Z" fill="#EAECF0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_15736_74961">
                                            <rect width="20" height="20" fill="white" transform="translate(0.333984 0.800781)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </Card>
                        <Card
                            clickable
                            className="w-1/3 flex justify-between rounded-xl card-shadows border border-gray-200 p-5 hover:shadow-lg transition duration-150 ease-in-out"
                            onClick={(e) => console.log('Card Clickable', e)}
                        >
                            <div className='cursor-pointer'
                             onClick={() => handleNavigate("workers-inductions")}
                            >
                                <h5 className="font-semibold text-gray-800 text-base">Workers Inductions</h5>
                                <p className="mt-2 text-xs">
                                    Description lorem ipsum dolor sit amet consectetur. Mauris vulputate.
                                </p>
                            </div>
                            <span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_15736_74961)">
                                        <path d="M9.87232 2.41075C10.0431 2.0001 10.6248 2.0001 10.7956 2.41075L12.8617 7.37822C12.9337 7.55134 13.0965 7.66963 13.2834 7.68461L18.6462 8.11454C19.0896 8.15009 19.2693 8.70334 18.9316 8.99267L14.8457 12.4927C14.7033 12.6147 14.6411 12.806 14.6846 12.9884L15.9329 18.2216C16.0361 18.6542 15.5655 18.9962 15.1859 18.7643L10.5946 15.96C10.4346 15.8622 10.2334 15.8622 10.0734 15.96L5.48204 18.7643C5.10249 18.9962 4.63187 18.6542 4.73506 18.2216L5.98337 12.9884C6.02687 12.806 5.96469 12.6147 5.82229 12.4927L1.7364 8.99267C1.39863 8.70334 1.57839 8.15009 2.02172 8.11454L7.38453 7.68461C7.57142 7.66963 7.73423 7.55134 7.80623 7.37822L9.87232 2.41075Z" fill="#EAECF0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_15736_74961">
                                            <rect width="20" height="20" fill="white" transform="translate(0.333984 0.800781)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </Card>

                    </div>
                
                </AccordionBody>
            </Accordion>
        </React.Fragment>
    );
};

export default Reports;
