import axios from "axios";
import * as actionType from "../../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/AdminContractor/GetAdminSpecificContractorSlice";
export const GetIndStatusOfSpecificWorkerByClient = (apiData,workerPortal) => async (dispatch) => {
    /*** API Call Action ***/
    try {
    //   dispatch(fetchData())
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url:`${actionType.environment_url}/api/worker/GetIndStatusOfWorkerByClient`,
        params: apiData
      });
      return response.data
    //   dispatch(fetchDataSuccess(response.data))
    } catch (error) {
        return error.message
    //   dispatch(fetchDataError(error.message))  
    }
  };