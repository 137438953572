import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
// import { notify } from "react-notify-toast";
import * as userActions from "../../../../../../../../actions";
import Input from "../../../../../../../../common/element/Input";
import Label from "../../../../../../../../common/element/Label";
import Tag from "../../../../../../../../common/element/Tag";
import Button from "../../../../../../../../common/element/Buttons";
import Segment from "../../../../../../../../common/element/Segment";
import Select from "../../../../../../../../common/element/Select/Select";
import ScrollBar from "../../../../../../../../common/element/ScrollBar";
import { Link, useParams } from "react-router-dom";
import BtnLight from "../../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../../../../../common/element/Dialog";
import Validations from "../../../../../../../../common/404/Validations";
import useUnauthorizedHandler from "../../../../../../../../common/snippet/useUnauthorizedHandler";
import { GetWorkerOverviewDetailsById } from "../../../../../../../../actions/admin-worker/GetWorkerOverviewDetailsById";

const ClientDetails = forwardRef((props, ref) => {
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  const dispatch = useDispatch();
  const { id } = useParams();
  const dialogRef = useRef(null);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [swapActive, setActive] = useState(true);
  const [StateOptions1, setStateOptions1] = useState();
  const [StateOptions2, setStateOptions2] = useState();

  const [countryOptions, setCountryOptions] = useState();

  const [businessType, setBusinessType] = useState("");
  const [userInactive, setUserInactive] = useState(false);
  const [unSaveDialogIsOpen, unSaveSetIsOpen] = useState(false);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [abnNumber, setAbnNumber] = useState("");
  const [acn, setAcn] = useState("");
  const [website, setWebsite] = useState("");
  const [logo, setLogo] = useState("");
  const [urlImage, setUrlImage] = useState("");
  const [businessAddres1, setBusinessAddres1] = useState("");
  const [businessAddres2, setBusinessAddres2] = useState("");
  const [businessAddresCity, setBusinessAddresCity] = useState("");
  const [businessAddresPostCode, setBusinessAddresPostCode] = useState("");
  const [businessAddresCountry, setBusinessAddresCountry] = useState("");
  const [businessAddresState, setBusinessAddresState] = useState("");
  const [mailingAddres1, setMailingAddres1] = useState("");
  const [mailingAddres2, setMailingAddres2] = useState("");
  const [mailingAddresCity, setMailingAddresCity] = useState("");
  const [mailingAddresPostCode, setMailingAddresPostCode] = useState("");
  const [mailingAddresCountry, setMailingAddresCountry] = useState("");
  const [mailingAddresState, setMailingAddresState] = useState("");
  const [functionId, setfunctionId] = useState("");
  const [mailingCountryID, setMailingCountryID] = useState("");
  const [businessCountryID, setBusinessCountryID] = useState("");
  const [mailingStateID, setMailingStateID] = useState("");
  const [businessStateID, setBusinessStateID] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [sameAddress, setSameAddress] = useState();
  const [businessId, setBusinessId] = useState("");
  const [mailingId, setMailingId] = useState("");
  const [callApi, setcallApi] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [clientExist, setClientExist] = useState(false);
  const [abn_nzbn, setabn_nzbn] = useState("");

  const [formErrors, setFormErrors] = useState({
    acn: "",
    businessAddres1: "",
    businessAddresCity: "",
    businessAddresPostCode: "",
    businessAddresState: "",
    businessAddresCountry: "",
    mailingAddres1: "",
    mailingAddresCity: "",
    mailingAddresPostCode: "",
    mailingAddresState: "",
    mailingAddresCountry: "",
    existClient: "",
  });

  const functionAssignmentId = useSelector(
    (state) => state.functionAssignmentId.functionAssignmentId
  );

  const clickHandlerInactive = () => {
    const payload = {
      is_org_active: !swapActive,
      organisation_id: organizationId,
      function_assignment_id: functionAssignmentId,
    };
    props.onUpdateClientStatus(payload).then((response) => {
      if (response.success === true) {
        GetSpecificClientDetailsById();
        userInactiveClose();
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      } else {
      }
    });
  };

  const userInactiveOpen = () => {
    setUserInactive(true);
  };
  const userInactiveClose = () => {
    setUserInactive(false);
  };
  const openDialogUnSave = () => {
    if (hasChanges) {
      unSaveSetIsOpen(true);
    }
  };
  const onDialogCloseUnSave = () => {
    unSaveSetIsOpen(false);
  };
  const onDialogExitUnSave = () => {
    unSaveSetIsOpen(false);
    props.userSettingHide();
  };

  const onDialogClose = (e) => {
    setIsOpen(false);
  };

  const GetAlreadyExistingOrgCheck = useCallback(
    async (businessName, newAbn, organizationId) => {
      dispatch(
        userActions.GetAlreadyExistingOrgCheck(
          businessName,
          newAbn,
          organizationId
        )
      ).then((response) => {
        if (response.success === true) {
          const arrayData = response.data;
          setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            existClient: "",
          }));
          if (
            arrayData &&
            arrayData?.abn_nzbn == abnNumber &&
            arrayData?.trading_name == businessName
          ) {
            // setClientExist(true)
            setFormErrors((prevFormErrors) => ({
              ...prevFormErrors,
              existClient:
                "The Name and ABN/NZBN you have provided are already registered on our system. In order to register as a new organization, please choose a different name or ABN/NZBN",
            }));
          } else {
            // setClientExist(false)
          }
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        } else {
        }
      });
      // eslint-disable-next-line
    },
    [dispatch]
  );

  const GetSpecificClientDetailsById = useCallback(async () => {
    dispatch(
      GetWorkerOverviewDetailsById({
        individual_id: storedData?.userName?.individual_id,
      })
    ).then((response) => {
      if (response.success === true) {
        const arrayData = response.data;
        setOrganizationId(arrayData?.organisation_id);
        setBusinessType(arrayData?.org_type);
        setBusinessName(arrayData?.trading_name);
        setAcn(arrayData?.acn);
        setAbnNumber(arrayData?.abn_nzbn);
        setActive(arrayData?.is_org_active);
        setfunctionId(arrayData?.function_id);
        setUrlImage(arrayData?.logo);
        setLogo(arrayData?.logo);
        setWebsite(arrayData?.website_url);
        //   setActive(arrayData?.is_org_active)

        setcallApi(true);
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      } else {
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    GetSpecificClientDetailsById();
  }, []);

  const GetAllCountries = useCallback(async () => {
    dispatch(userActions.GetAllCountries()).then((response) => {
      if (response.success === true) {
        const arrayData = response.data;
        let country_data = [];
        for (let key in arrayData) {
          country_data.push({
            label: arrayData[key].name,
            value: arrayData[key].country_id,
          });
        }
        setCountryOptions(country_data);
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      } else {
      }
    });
  }, [dispatch]);

  const GetAllStatesbyCountryId = useCallback(
    async (businessCountryID, mailingCountryID) => {
      if (businessCountryID) {
        dispatch(userActions.GetAllStatesbyCountryId(businessCountryID)).then(
          (response) => {
            if (response.success === true) {
              const arrayData = response.data;
              let state_data = [];
              for (let key in arrayData) {
                state_data.push({
                  label: arrayData[key].state_name,
                  value: arrayData[key].state_id,
                });
              }
              setStateOptions1(state_data);
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            } else {
            }
          }
        );
      }
      if (mailingCountryID) {
        dispatch(userActions.GetAllStatesbyCountryId(mailingCountryID)).then(
          (response) => {
            if (response.success === true) {
              const arrayData = response.data;
              let state_data = [];
              for (let key in arrayData) {
                state_data.push({
                  label: arrayData[key].state_name,
                  value: arrayData[key].state_id,
                });
              }
              setStateOptions2(state_data);
            } else {
            }
          }
        );
      }

      // eslint-disable-next-line
    },
    [dispatch]
  );

  useEffect(() => {
    if (callApi === true) {
      GetAllCountries();
    }
  }, [businessCountryID, mailingCountryID]);

  useEffect(() => {
    GetAllStatesbyCountryId(businessCountryID, mailingCountryID);
  }, [
    GetAllCountries,
    GetAllStatesbyCountryId,
    mailingAddresCountry,
    mailingStateID,
    businessAddresCountry,
    businessStateID,
  ]);

  const inputchangehandler = (e, identifier) => {
    if (identifier === "abn") {
      setAbnNumber(e);
    }
    if (identifier === "acn") {
      setAcn(e);
    }
    if (identifier === "website") {
      let newValue = e;
      if (
        newValue.includes("www.") &&
        !newValue.startsWith("www.") &&
        !newValue.startsWith("http")
      ) {
        newValue = newValue.replace("www.", "");
      }

      if (
        !newValue.startsWith("www") &&
        !newValue.startsWith("www.") &&
        !newValue.startsWith("http")
      ) {
        newValue = `www.${newValue}`;
      }
      setWebsite(newValue);
    }
    if (identifier === "businessAddress1") {
      setBusinessAddres1(e);
    }
    if (identifier === "businessAddress2") {
      setBusinessAddres2(e);
    }
    if (identifier === "businessParentCity") {
      setBusinessAddresCity(e);
    }
    if (identifier === "businessParentPostCode") {
      setBusinessAddresPostCode(e);
    }
    if (identifier === "businessCity") {
      setBusinessAddresCity(e);
    }
    //  if(identifier === 'businessState'){
    //      setBusinessAddresState(e)
    //  }
    if (identifier === "mailingAddres1") {
      setMailingAddres1(e);
    }
    if (identifier === "mailingAddres2") {
      setMailingAddres2(e);
    }
    if (identifier === "mailingPostCode") {
      setMailingAddresPostCode(e);
    }
    if (identifier === "mailingCity") {
      setMailingAddresCity(e);
    }
    setHasChanges(true);
  };

  const handleABN = () => {
    let newValue = abnNumber.replace(/\D/g, ""); // Remove non-digit characters
    const length = newValue.length;
    if (length <= 11) {
      newValue = `${newValue.slice(0, 2)} ${newValue.slice(
        2,
        5
      )} ${newValue.slice(5, 8)} ${newValue.slice(8, 11)}`;
    }
    const trimmedData = abnNumber.replace(/\s/g, "");
    setAbnNumber(newValue);
    setabn_nzbn(trimmedData);
    GetAlreadyExistingOrgCheck(businessName, newValue, organizationId);
  };

  const onBusinessType = useCallback(
    (val) => {
      setHasChanges(true);
      setBusinessType(val);
    },
    [setBusinessType]
  );

  const handleCountryChange = (selectedOption, identifier) => {
    if (identifier === "businessAddress") {
      setBusinessCountryID(selectedOption.value);
      setBusinessAddresCountry(selectedOption);
      setBusinessStateID("");
    }
    if (identifier === "mailingAddress") {
      setMailingCountryID(selectedOption.value);
      setMailingAddresCountry(selectedOption);
      setMailingStateID("");
    }
    setHasChanges(true);
  };

  const handleStateChange = (selectedOption, identifier) => {
    if (identifier === "businessAddress") {
      setBusinessStateID(selectedOption.value);
      setBusinessAddresState(selectedOption);
    }
    if (identifier === "mailingAddress") {
      setMailingStateID(selectedOption.value);
      setMailingAddresState(selectedOption);
    }
    setHasChanges(true);
  };

  const onDropHandler = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileChange(files);
  };

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      inputChangeHandlerFile(file);
    }
  };

  const inputChangeHandlerFile = (file) => {
    // Check the file extension
    const fileType = file.type;
    if (
      fileType === "image/svg+xml" ||
      fileType.startsWith("image/png") ||
      fileType.startsWith("image/jpeg") ||
      fileType.startsWith("image/gif")
    ) {
      // Check the file size (max. 800x400px)
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        if (img.width <= 800 && img.height <= 400) {
          setLogo(file);
          setUrlImage(URL.createObjectURL(file));
          setHasChanges(true);
        } else {
          // Handle size validation error
        }
      };
    } else {
      // Handle file type validation error
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dialogRef.current && !dialogRef.current.contains(e.target)) {
        openDialogUnSave();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hasChanges]);

  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  const EditClient = (e) => {
    if (!formErrors.existClient) {
      if (!sameAddress) {
        const abnValidation = Validations({
          value: abnNumber,
          Validations: { required: true, ValidateABN: true },
        });

        const add1BusinessValidation = Validations({
          value: businessAddres1,
          Validations: { required: true },
        });
        const cityBusinessValidation = Validations({
          value: businessAddresCity,
          Validations: { required: true },
        });
        const postCodeBusinessValidation = Validations({
          value: businessAddresPostCode,
          Validations: { required: true },
        });
        const stateBusinessValidation = Validations({
          value: businessStateID,
          Validations: { required: true },
        });

        const add1MailingValidation = Validations({
          value: mailingAddres1,
          Validations: { required: true },
        });
        const cityMailingValidation = Validations({
          value: mailingAddresCity,
          Validations: { required: true },
        });
        const postCodeMailingValidation = Validations({
          value: mailingAddresPostCode,
          Validations: { required: true },
        });
        const countryMailingValidation = Validations({
          value: mailingCountryID,
          Validations: { required: true },
        });
        const stateMailingValidation = Validations({
          value: mailingStateID,
          Validations: { required: true },
        });

        if (!add1BusinessValidation.valid) {
          setFormError("businessAddres1", add1BusinessValidation.error_msg);
        }
        if (!cityBusinessValidation.valid) {
          setFormError("businessAddresCity", cityBusinessValidation.error_msg);
        }
        if (!postCodeBusinessValidation.valid) {
          setFormError(
            "businessAddresPostCode",
            postCodeBusinessValidation.error_msg
          );
        }
        if (!stateBusinessValidation.valid) {
          setFormError(
            "businessAddresCountry",
            stateBusinessValidation.error_msg
          );
        }

        if (!add1MailingValidation.valid) {
          setFormError("mailingAddres1", add1MailingValidation.error_msg);
        }
        if (!cityMailingValidation.valid) {
          setFormError("mailingAddresCity", cityMailingValidation.error_msg);
        }
        if (!postCodeMailingValidation.valid) {
          setFormError(
            "mailingAddresPostCode",
            postCodeMailingValidation.error_msg
          );
        }
        if (!countryMailingValidation.valid) {
          setFormError(
            "mailingAddresState",
            countryMailingValidation.error_msg
          );
        }
        if (!stateMailingValidation.valid) {
          setFormError(
            "mailingAddresCountry",
            stateMailingValidation.error_msg
          );
        }
        if (!abnValidation.valid) {
          setFormError("abn", abnValidation.error_msg);
        }

        if (
          abnValidation.valid === true &&
          add1BusinessValidation.valid === true &&
          cityBusinessValidation.valid === true &&
          postCodeBusinessValidation.valid === true &&
          stateBusinessValidation.valid === true &&
          add1MailingValidation.valid === true &&
          cityMailingValidation.valid === true &&
          postCodeMailingValidation.valid === true &&
          countryMailingValidation.valid === true &&
          stateMailingValidation.valid === true
        ) {
          const payload = {
            organisation_id: organizationId,
            org_type:
              businessType === "pty"
                ? "pty_traders"
                : businessType === "sole"
                ? "sole_traders"
                : "other", //Assuming businessType is one of "pty", "sole", or "other"
            trading_name: businessName,
            abn_nzbn: abnNumber,
            acn: acn,
            website_url: website,
            logo: logo, // Add the logo URL or base64 data here
            client_address_data: JSON.stringify([
              {
                address_type: "business",
                address_one: businessAddres1,
                address_two: businessAddres2,
                suburb: businessAddresCity,
                zipcode: businessAddresPostCode,
                state_id: businessStateID,
                country_id: businessCountryID,
                address_id: businessId ? businessId : "",
                state_name: businessAddresState.label,
                country_name: businessAddresCountry.label,
              },
              {
                address_type: "mailing",
                address_one: mailingAddres1,
                address_two: mailingAddres2,
                suburb: mailingAddresCity,
                zipcode: mailingAddresPostCode,
                state_id: mailingStateID,
                country_id: mailingCountryID,
                address_id: mailingId ? mailingId : "",
                state_name: mailingAddresState.label,
                country_name: mailingAddresCountry.label,
              },
            ]),
          };
          props.onUpdateClientDetails(payload).then((response) => {
            if (response.success === true) {
              props.userSettingHide();
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            } else {
            }
          });
        }
      } else if (sameAddress) {
        const abnValidation = Validations({
          value: abnNumber,
          Validations: { required: true, ValidateABN: true },
        });
        const add1BusinessValidation = Validations({
          value: businessAddres1,
          Validations: { required: true },
        });
        const cityBusinessValidation = Validations({
          value: businessAddresCity,
          Validations: { required: true },
        });
        const postCodeBusinessValidation = Validations({
          value: businessAddresPostCode,
          Validations: { required: true },
        });
        const stateBusinessValidation = Validations({
          value: businessStateID,
          Validations: { required: true },
        });

        if (!add1BusinessValidation.valid) {
          setFormError("businessAddres1", add1BusinessValidation.error_msg);
        }
        if (!cityBusinessValidation.valid) {
          setFormError("businessAddresCity", cityBusinessValidation.error_msg);
        }
        if (!postCodeBusinessValidation.valid) {
          setFormError(
            "businessAddresPostCode",
            postCodeBusinessValidation.error_msg
          );
        }
        if (!stateBusinessValidation.valid) {
          setFormError(
            "businessAddresCountry",
            stateBusinessValidation.error_msg
          );
        }

        if (!abnValidation.valid) {
          setFormError("abn", abnValidation.error_msg);
        }

        if (
          abnValidation.valid === true &&
          add1BusinessValidation.valid === true &&
          cityBusinessValidation.valid === true &&
          postCodeBusinessValidation.valid === true &&
          stateBusinessValidation.valid === true
        ) {
          const payload = {
            organisation_id: organizationId,
            org_type:
              businessType === "pty"
                ? "pty_traders"
                : businessType === "sole"
                ? "sole_traders"
                : "other", //Assuming businessType is one of "pty", "sole", or "other"
            trading_name: businessName,
            abn_nzbn: abnNumber,
            acn: acn,
            website_url: website,
            logo: logo, // Add the logo URL or base64 data here
            client_address_data: JSON.stringify([
              {
                address_type: "business",
                address_one: businessAddres1,
                address_two: businessAddres2,
                suburb: businessAddresCity,
                zipcode: businessAddresPostCode,
                state_id: businessStateID,
                country_id: businessCountryID,
                address_id: businessId ? businessId : "",
                state_name: businessAddresState.label,
                country_name: businessAddresCountry.label,
              },
              {
                address_type: "mailing",
                address_one: businessAddres1,
                address_two: businessAddres2,
                suburb: businessAddresCity,
                zipcode: businessAddresPostCode,
                state_id: businessStateID,
                country_id: businessCountryID,
                address_id: mailingId ? mailingId : "",
                //  address_id: businessId ? businessId : '',
                state_name: businessAddresState.label,
                country_name: businessAddresCountry.label,
              },
            ]),
          };

          props.onUpdateClientDetails(payload).then((response) => {
            if (response.success === true) {
              props.userSettingHide();
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            } else {
            }
          });
        }
      }
    }
  };

  useImperativeHandle(ref, () => ({
    EditClient,
  }));

  return (
    <React.Fragment>
      <div ref={dialogRef}>
        <div className="h-500 pr-1">
          <ScrollBar>
            <div className="pr-4 mt-5">
              <div className="flex justify-between flex-col mb-5 border-b border-gray-200 pb-5">
                <h5 className="text-base text-gray-900 font-semibold mb-1">
                  Worker Details
                </h5>
                <p className="text-gray-600">
                  Update the Worker’s logo and details here.
                </p>
              </div>
              <div className="flex items-center border-b border-gray-200 pb-5 mb-5">
                <Label className="text-gray-700 font-semibold w-52 font-sm">
                  Status
                </Label>
                {swapActive === true && (
                  <div>
                    <Tag
                      prefix
                      prefixClass="bg-success-500"
                      className="text-success-700 bg-success-50 gap-1"
                    >
                      Active
                    </Tag>
                    <Button
                      onClick={userInactiveOpen}
                      className="text-gray-600 text-xs font-semibold ml-4 hover:text-gray-900 focus:text-gray-600"
                    >
                      Make inactive
                    </Button>
                  </div>
                )}
                {swapActive === false && (
                  <div>
                    <Tag className="text-error-700 bg-error-50 gap-1">
                      Inactive
                    </Tag>
                    <Button
                      onClick={clickHandlerInactive}
                      className="text-gray-600 text-xs font-semibold ml-4 hover:text-gray-900 focus:text-gray-600"
                    >
                      Make active
                    </Button>
                  </div>
                )}
              </div>

              <div className="flex justify-between items-start border-b border-gray-200 pb-5 mb-5">
                <label className="text-gray-700 font-semibold w-52 font-sm">
                  Your photo
                </label>
                <div className="flex items-center justify-center  flex-1 upload-img ml-8">
                  <div className="flex flex-row">
                    <span className="h-28 w-28 bg-gray-100 rounded-full flex justify-center items-center mr-5 avtar_img_round">
                      {urlImage && (
                        <img
                          className="w-28 h-28 rounded-full"
                          src={urlImage}
                          alt=""
                        />
                      )}
                    </span>

                    <div className="flex items-center justify-center  flex-1 upload-img ml-8">
                      <label
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full border py-4 px-6 rounded-xl  border-gray-200  hover:bg-gray-25 bg-white"
                      >
                        <div
                          className="flex items-center justify-center  flex-1 upload-img ml-8"
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={onDropHandler}
                        >
                          <div className="flex flex-col items-center justify-center">
                            <div className="rounded-full border-12 circle m-auto flex align-center justify-center bg-gray-200 border-gray-50 mb-3 border-6 w-10 h-10 items-center">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                                  stroke="#475467"
                                  strokeWidth="1.667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <p className="mb-2  text-gray-600 darks:text-gray-400">
                              <span className="font-semibold text-blue-700">
                                Click to upload
                              </span>
                              <span className="text-xs"> or drag and drop</span>
                            </p>
                            <p className="text-gray-600 darks:text-gray-400 font-inter">
                              SVG, PNG, JPG or GIF (max. 800x400px)
                            </p>
                          </div>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                          onChange={(e) =>
                            inputChangeHandlerFile(e.target.files[0])
                          }
                        />
                      </label>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="flex items-start mb-5">
                <label className="text-gray-700 font-semibold w-52">
                  Full name
                </label>
                <div className="flex items-center flex-1 flex-wrap">
                  <div className="flex gap-3 w-full">
                    <div className="flex-1">
                      <label>First name</label>
                      <Input
                        className="h-9 w-full"
                        placeholder="Enter first name"
                      />
                    </div>
                    <div className="flex-1">
                      <label>Last name</label>
                      <Input
                        className="h-9 w-full"
                        placeholder="Enter second name"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-start mb-5">
                <label className="text-gray-700 font-semibold w-52">
                  Job title
                </label>
                <div className="flex items-center flex-1 flex-wrap">
                  <Input className="h-9 w-full" placeholder="Job title here" />
                </div>
              </div>
              <div className="flex items-start mb-5">
                <label className="text-gray-700 font-semibold w-52">
                  Email address
                </label>
                <div className="flex items-center flex-1 flex-wrap">
                  <span className="absolute left-3 top-2">
                    <img src="/assets/icons-svg/mail-01.svg" alt="" />
                  </span>
                  <Input
                    className="h-9 w-full pl-10"
                    placeholder="name@emailaddress.com"
                  />
                </div>
              </div>
              <div className="flex items-start mb-5">
                <label className="text-gray-700 font-semibold w-52">
                  Contact number
                </label>
                <div className="flex items-center flex-1 flex-wrap">
                  <span className="absolute left-3 top-2">
                    <img src="/assets/icons-svg/phone.svg" alt="" />
                  </span>
                  <Input
                    className="h-9 w-full pl-10"
                    placeholder="name@emailaddress.com"
                  />
                </div>
              </div>
              {formErrors.existClient && (
                <p className="text-xs text-red-600 mb-4 mt-0 pt-8">
                  {formErrors.existClient}
                </p>
              )}
              <div className="flex items-start mb-5">
                <Label className="font-semibold w-52 font-sm">Location*</Label>
                <div className="flex items-center flex-1 flex-wrap">
                  <Input
                    type="text"
                    name="address"
                    placeholder="Street Address*"
                    className="w-full h-9 mb-2"
                    value={businessAddres1}
                    inputchangehandler={(e) =>
                      inputchangehandler(e, "businessAddress1")
                    }
                  />
                  {formErrors.businessAddres1 && (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {formErrors.businessAddres1}
                    </p>
                  )}
                  <div className="flex w-full gap-2">
                    <div className="flex-1">
                      <Input
                        type="text"
                        name="Apartment"
                        placeholder="Apartment, suite, etc (optional)"
                        className="mb-2 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                        value={businessAddres2}
                        inputchangehandler={(e) =>
                          inputchangehandler(e, "businessAddress2")
                        }
                      />
                    </div>
                    <div className="flex-1">
                      <Input
                        type="text"
                        name="Suburb"
                        placeholder="Suburb*"
                        className="mb-2 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                        value={businessAddresCity}
                        inputchangehandler={(e) =>
                          inputchangehandler(e, "businessParentCity")
                        }
                      />
                      {formErrors.businessAddresCity && (
                        <p className="text-xs text-red-600 mb-4 mt-0">
                          {formErrors.businessAddresCity}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex w-full gap-2">
                    <div className="flex-1">
                      <Select
                        isSearchable={false}
                        placeholder="Country"
                        options={countryOptions}
                        className="select-option mb-2 h-10 text-sm border-gray-300 rounded-lg  appearance-none w-full  text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                        value={countryOptions?.filter(function (option) {
                          return option.value === businessCountryID;
                        })}
                        onChange={(e) =>
                          handleCountryChange(e, "businessAddress")
                        }
                      />
                    </div>
                    <div className="flex-1">
                      <Input
                        type="text"
                        name="Postcode"
                        placeholder="Postcode*"
                        className="mb-2 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                        value={businessAddresPostCode}
                        inputchangehandler={(e) =>
                          inputchangehandler(e, "businessParentPostCode")
                        }
                        maxLength={12}
                      />
                      {formErrors.businessAddresPostCode && (
                        <p className="text-xs text-red-600 mb-4 mt-0">
                          {formErrors.businessAddresPostCode}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex w-full h-9">
                    <Select
                      isSearchable={false}
                      placeholder="State*"
                      options={StateOptions1}
                      className="select-option flex-1"
                      value={StateOptions1?.filter(function (option) {
                        return option.value === businessStateID;
                      })}
                      onChange={(e) => handleStateChange(e, "businessAddress")}
                    />
                    {formErrors.businessAddresState && (
                      <p className="text-xs text-red-600 mb-4 mt-0">
                        {formErrors.businessAddresState}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ScrollBar>
        </div>
        {/* <div className="flex p-6 justify-end border-gray-200">
          <BtnLight
            onClick={() => openDialogUnSave()}
            className="w-auto px-5 mr-3"
            variant="plain"
          >
            Close
          </BtnLight>
          <BtnPrimary onClick={() => EditClient()} className="w-auto px-5">
            Save changes
          </BtnPrimary>
        </div> */}
        <Dialog
          isOpen={userInactive}
          onClose={userInactiveClose}
          onRequestClose={userInactiveClose}
          bodyOpenClassName="overflow-hidden"
          className="customAlert"
        >
          <div className="px-6 pt-6">
            <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                  stroke="#000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
              Are you sure you want to <br />
              make this Client inactive?
            </h4>
            <p className=" text-gray-600 text-center mt-1">
              This client will no longer have access to the app.
            </p>
          </div>

          <div className="mt-8 flex justify-between pb-6 px-6">
            <BtnLight onClick={() => userInactiveClose()} className="mr-1.5">
              Cancel
            </BtnLight>
            <BtnPrimary onClick={clickHandlerInactive} className="ml-1.5">
              Confirm
            </BtnPrimary>
          </div>
        </Dialog>
        <Dialog
          isOpen={unSaveDialogIsOpen}
          onClose={onDialogCloseUnSave}
          onRequestClose={onDialogCloseUnSave}
          bodyOpenClassName="overflow-hidden"
          className="customAlert"
        >
          <div className="px-6 pt-6">
            <span className="w-12 h-12 rounded-full m-auto bg-warning-100 flex items-center justify-center">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 3.5v3.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C8.26 8.5 8.54 8.5 9.1 8.5h6.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437c.109-.214.109-.494.109-1.054V4.5m0 17v-6.4c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437c-.214-.109-.494-.109-1.054-.109H9.1c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C7.5 14.26 7.5 14.54 7.5 15.1v6.4m14-11.675V16.7c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327H8.3c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3.5 19.22 3.5 18.38 3.5 16.7V8.3c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C5.78 3.5 6.62 3.5 8.3 3.5h6.874c.49 0 .734 0 .965.055.204.05.399.13.578.24.201.123.374.296.72.642l3.126 3.126c.346.346.519.519.642.72.11.18.19.374.24.579.055.23.055.474.055.963z"
                  stroke="#000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
              You have unsaved changes
            </h4>
            <p className=" text-gray-600 text-center mt-1">
              Save your information before leaving?
            </p>
          </div>

          <div className="mt-8 flex justify-between pb-6 px-6">
            <BtnLight onClick={() => onDialogExitUnSave()} className="mr-1.5">
              Exit
            </BtnLight>
            <BtnPrimary className="ml-1.5" onClick={() => EditClient()}>
              Save
            </BtnPrimary>
          </div>
        </Dialog>
      </div>
    </React.Fragment>
  );
});

function mapStateToProps(state) {
  return {
    loader: userActions.UpdateClientDetails.loader,
  };
}
const mapDispatchToProps = {
  onUpdateClientDetails: userActions.UpdateClientDetails,
  onUpdateClientStatus: userActions.UpdateOrgStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
