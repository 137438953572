
import * as actionType from "../constant/actionType";

const initialState = {
  functionAssignmentId: '', 
};

const functionAssignmentUuidReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_FUNCTION_ASSIGNMENT_ID:
      return {
        ...state,
        functionAssignmentId: action.payload,
      };
    default:
      return state;
  }
};

export default functionAssignmentUuidReducer;