import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "../../../../../../../common/snippet/Debouncing";
import SiteInductionList from "./SiteInductionList";
import Dialog from "../../../../../../../common/element/Dialog/Dialog";
// import AddInductionDialog from "../../../Dialog/SpecificSite/SiteInductions/AddInduction";
import AddInductionDialog from "./SpecificSite/SiteInductions/AddInduction";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import Badge from "../../../../../../../common/element/Badge";
import { useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
// import * as userActions from "../../../../../../../actions/index";
import { notify } from "react-notify-toast";
import ClientInput from "../../../../../../../common/element/Input/clientInput";

import {
  AddSiteInductionModuleData,
  ResetSiteInductionModuleData,
  DeleteSiteInductionModuleDataByIndex,
} from "../../../../../../../redux/classes/Admin-Client/SiteInductionModuleSlice";
import SiteInductionDetailForm, {
  RestSiteInductionDetailForm,
} from "../../../../../../../redux/classes/Admin-Client/SiteInductionDetailForm";
import { EditSiteInduction } from "../../../../../../../redux/classes/Admin-Client/EditSiteInductionSlice";
import { ResetEditSiteInduction } from "../../../../../../../redux/classes/Admin-Client/EditSiteInductionSlice";
import { closeDocumentfuncSite } from "../../../../../../../redux/classes/Admin-Client/closeDocumentSite";
import { DeletedocumentDataSite } from "../../../../../../../redux/classes/Admin-Client/deleteDocumentSite";
import { refetchAllInductionsDataSite } from "../../../../../../../redux/classes/Admin-Client/refetchAllInductionsSite";
import { UpdateInductionStatusSite } from "../../../../../../../redux/classes/Admin-Client/updatInductionStatusSite";
import { siteInductionDetailForm } from "../../../../../../../redux/classes/Admin-Client/SiteInductionDetailForm";
// import { AddSiteInductionModuleData } from "../../../../../../../redux/classes/Admin-Client/SiteInductionModuleSlice";

import { GetClientUserPermByIdForClient } from "../../../../../../../actions/client-portal/AllUsersApi";
import {
  CreateSiteInductionForClient,
  GetAllInductionsOfSpecificSiteForClient,
  UpdateSiteInductionStatusForClient,
  GetSiteInductionAndModulesbyIdForClient,
  DeleteSiteInductionModuleById,
} from "../../../../../../../actions/client-portal/AllSiteApi";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../../../common/pagination";
import { setBreadcrumbs } from "../../../../../../../redux/classes/breadcrumbsSlice";
import { decryptId } from "../../../../../../../common/encryptDecrypt/encryptdecrypt";
const SiteInduction = (props) => {
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const siteOrgId=decryptId(siteId)

  const handleUnauthorizedAccess = useUnauthorizedHandler();
  // get all redux state
  const GettAllState = useSelector((state) => state);
  
  // get induction detail form from redux
  const siteInductionDetailData = GettAllState?.siteInductionDetailData;
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [apiCalls, setApiCalls] = useState(true)
  const [BtnDisable, setBtnDisable] = useState(false);
  const [validateFrom, setvalidateFrom] = useState(false);
  const [veiwInduction, setveiwInduction] = useState(false);
  const [deleteModuleData, setdeleteModuleData] = useState({});
  const [finalSaveData, setfinalSaveData] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [deleteDiolog, setdeleteDiolog] = useState(false);
  const [activeBtn, setActiveBtn] = useState("");
  const [exitCheck, setExitCheck] = useState(false);
  const [upDateState, setupDateState] = useState(false);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [dialogUnsaveOpen, setUnsaveOpen] = useState(false);
  const [countData, setCountData] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [AllData, setAllData] = useState([]);
  const [siteInductionId, setSiteInductionId] = useState(null);

  // let function_assignment_id;
  let function_assignment_id;
  let user_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));

  if (storedData !== null) {
    user_id = storedData?.userName?.user_id;
    function_assignment_id = storedData.function_assignment_id;
  }

  const [updateData, SetupdateData] = useState(false);
  const [formSatus, SetformSatus] = useState("inductionDetails");
  const [site_induction_id, setSite_induction_id] = useState("");
  // api call for permissions
  // useEffect(() => {
  //   const apiData = {
  //     user_id: user_id,
  //     function_assignment_id: function_assignment_id,
  //   };
  //   dispatch(GetClientUserPermByIdForClient(apiData)).then((response) => {
  //     if (response?.success === true) {
  //       // const arrayData = response?.data;
  //       localStorage.setItem("permission_data", JSON.stringify(response?.data));
  //     } else if (response?.status === 401) {
  //       handleUnauthorizedAccess();
  //     }
  //   });
  // }, []);

  // site induction id from create site api

  // useEffect(() => {
  //    dispatch(setBreadcrumbs([{label: 'View All Clients', url: '/admin/client/view-all-clients'}, {label: props?.clientData?.trading_name, url: `/admin/client/view-all-clients/specific-client/${id}`}, {label: 'Company Induction', url: `/admin/client/view-all-clients/specific-client/${id}`}]));
  //  }, [props?.clientData]);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Sites", url: "/client/sites", home: "/client/dashboard" },
        { label: props?.siteData?.site_name ? props?.siteData?.site_name : "", url: `/client/sites/specific-site/${encodeURIComponent(siteId)}`, },
        { label: "Site Inductions", url: `/client/sites/specific-site/${encodeURIComponent(siteId)}`, },
      ])
    );
  }, [props?.siteData]);

  const openDialog = () => {
    dispatch(ResetEditSiteInduction());
    dispatch(ResetSiteInductionModuleData()); // Removed unnecessary parameter
    // dispatch(RestSiteInductionDetailForm());

    setIsOpen(true);
    setBtnDisable(false);
    dispatch(RestSiteInductionDetailForm());

    setveiwInduction(false);
  };

  useEffect(() => {
    dispatch(EditSiteInduction(false));
    // dispatch(RestSiteInductionDetailForm());
  }, []);

  useEffect(() => {

    if (
      GettAllState?.editSiteInduction !== null &&
      GettAllState?.editSiteInduction
    ) {
      SetformSatus("module");
      dispatch(EditSiteInduction(true));
      setIsOpen(true);
      setBtnDisable(false);
      //  dispatch(ResetEditSiteInduction())
    }
  }, [GettAllState, GettAllState.editSiteInduction]);

  // const getInductionDetailsData = useSelector(
  //   (state) => state.siteInductionDetails
  // );

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      // Dispatch your action when the page is about to be unloaded (hard refresh)
      dispatch(EditSiteInduction(false));
      dispatch(ResetEditSiteInduction());
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dispatch]);

  // FilTER DATA SHOW
  const [getFilterData, SetFilterData] = useState({
    site_id: siteOrgId,
    page: 1,
    limit: 10,
    sort: "created_date",
    order: "desc",
    search: "",
  });


  // All ReduxFunction
  const fetchData = useCallback(() => {
    dispatch(GetAllInductionsOfSpecificSiteForClient(getFilterData))
      .then(
        (response) => {
          if (response?.success) {
            setAllData(response?.data);
            setTotalPages(Math.ceil(response?.data?.count / getFilterData?.limit));
            dispatch(refetchAllInductionsDataSite(false));
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        }).catch((error) => {
        console.log("error", error);
      })
  }, [dispatch, getFilterData]);

  useEffect(() => {
    fetchData();
  }, [updateData, dialogIsOpen, getFilterData,
    GettAllState?.refetchAllInductionsSite]);

  const handleSort = (column) => {
    if (column === getFilterData.sort) {
      SetFilterData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getFilterData.order === "asc" ? "desc" : "asc",
        };
      });
    } else {
      SetFilterData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
      SetFilterData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getFilterData.order === "asc" ? "desc" : "asc",
        };
      });
    }
  };
  const handlePageChange = ({ selected }) => {
    SetFilterData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(Math.ceil(AllData?.count / getFilterData?.limit));
  };

  // Search Data
  const handleSearch = (e) => {
    SetFilterData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value.trim(),
      };
    });
  };

  const updatePageLimit = (newLimit) => {
    if (apiCalls == true) {
      setApiCalls(false)
    }
    else {
      SetFilterData(prevState => ({
        ...prevState,
        limit: newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10,
      }));
    }
  };

  const updatePage = (newPage) => {
    if (apiCalls == true) {
      setApiCalls(false)
    }
    else{
      SetFilterData(prevState => ({
        ...prevState,
        page: newPage?.page ? newPage?.page : newPage ? newPage : 1,
      }));
    }

  };
  const inputchangehandler = useCallback(debounce(handleSearch), []);
  const getPageNumber = () => {
    setTotalPages(Math.ceil(AllData?.count / getFilterData?.limit));
  };
  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);

  const onDialogClose = (e) => {
    if (formSatus === "module") {
      setUnsaveOpen(true);
      setvalidateFrom(false);

      setIsOpen(true);
      setBtnDisable(false);
    } else {
      dispatch(ResetSiteInductionModuleData());
      setvalidateFrom(false);
      SetformSatus("inductionDetails");
      // dispatch(RestInductuionDetailForm());
      setIsOpen(false);
    }
  };

  const openAddDialog = () => {

    if (isActive) {
      setExitCheck(true);
    }
    dispatch(UpdateInductionStatusSite(true));
    setveiwInduction(false);
    if (formSatus == "inductionDetails") {
      if (siteInductionDetailData?.site_ind_name !== "") {
        if (!isActive) {
          // if (!GettAllState?.editSiteInduction) {

          const { site_ind_doctypes, site_ind_status, site_induction_id, ...siteInductionDetailsPayload } = siteInductionDetailData;
          const newpayload = { ...siteInductionDetailsPayload, site_name: props?.siteData?.site_name }

          dispatch(CreateSiteInductionForClient(newpayload, ipAddress))
            .then((response) => {

              if (response?.success) {
                setSite_induction_id(response?.data?.site_induction_id);
                const updatedInductionData = {
                  ...siteInductionDetailData,
                  site_induction_id: response?.data?.site_induction_id,
                };

                SetformSatus("module");
                setSiteInductionId(response?.data?.site_induction_id);
                dispatch(SiteInductionDetailForm(updatedInductionData));
              } else if (response?.status === 401) {
                handleUnauthorizedAccess();
              }
            })
            .catch((error) => {
            });
        }
        //  else {
        //   setfinalSaveData(true);
        // }
      } else {
        setvalidateFrom(true);
      }
    } else {
      setBtnDisable(true);
      setfinalSaveData(true);
      setupDateState(!upDateState);
    }
  };

  const closePannelDisable = () => {
    setBtnDisable(false);
  };

  const closePannel = (disableBTN) => {
    if (disableBTN == "disableBTN") {
      setBtnDisable(false);
    } else {
      setAddOpen(true);
    }
  };

  const onAddDialogClose = (e) => {
    setBtnDisable(false);
    SetformSatus("inductionDetails");
    dispatch(ResetSiteInductionModuleData());
    // dispatch(RestSiteInductionDetailForm());
    setIsOpen(false);
    setAddOpen(false);
    setfinalSaveData(false);
    SetupdateData(!updateData);
    dispatch(ResetEditSiteInduction());
    // props?.getOnDialogClose(onAddDialogClose);
  };

  // To save & close the the dialog box for edit & create
  const onSaveDialog = (e) => {
    setExitCheck(true);
    setfinalSaveData(true);
    setUnsaveOpen(false);
    if (dialogAddOpen) {
      SetformSatus("inductionDetails");
      setTimeout(() => {
        SetupdateData(!updateData);
        dispatch(ResetSiteInductionModuleData());
        // dispatch(RestSiteInductionDetailForm());
        setIsOpen(false);
        setAddOpen(false);
        setfinalSaveData(false);
        // setUnsaveOpen(false);
        dispatch(ResetEditSiteInduction());
      }, 500);
    }
  };
  // change the code to exit without saving anything and also reset all the module
  const openUnsaveDialog = () => {
    if (formSatus === "module") {
      setUnsaveOpen(true);
    } else {
      setIsOpen(false);
    }
  };
  const onUnsaveDialogClose = (e) => {
    setUnsaveOpen(false);
    setExitCheck(false);
  };
  const closeFullForm = () => {
    setExitCheck(false);
    setUnsaveOpen(false);
    SetformSatus("inductionDetails");
    setTimeout(() => {
      SetupdateData(!updateData);
      dispatch(ResetSiteInductionModuleData());
      setIsOpen(false);
      setAddOpen(false);
      setfinalSaveData(false);
      setUnsaveOpen(false);
      dispatch(ResetEditSiteInduction());
    }, 500);
  };

  const getSpecificSiteModule = (site_induction_id) => {
    dispatch(ResetEditSiteInduction());
    dispatch(ResetSiteInductionModuleData());

    dispatch(GetSiteInductionAndModulesbyIdForClient(site_induction_id))
      .then((response) => {

        if (response?.success) {
          const data = response?.data;
          if (data?.site_ind_modules?.length) {
            data?.site_ind_modules?.forEach((item) => {
              dispatch(AddSiteInductionModuleData(item?.module_data));
            });
          }
          dispatch(
            SiteInductionDetailForm({
              site_id: data?.site_id,
              site_ind_name: data?.site_ind_name,
              site_ind_desc: data?.site_ind_desc,
              site_induction_id: data?.site_induction_id,
              site_ind_status: data?.site_ind_status,
              // comp_doc_id_data: response?.data?.site_ind_comp_docs.map(
              //   (docs) => docs.compliance_doc.compliance_doc_name
              // ),
              // induction changes pending
              // site_ind_doctypes: response?.data?.site_ind_doctypes,
              site_ind_doctypes: data?.site_ind_doctypes?.length
                ? data?.site_ind_doctypes.map(docs => docs?.document_type?.doc_type_name)
                : [],
              master_setting_id_data: data?.site_ind_industries?.length
                ? data?.site_ind_industries.map(industry => industry?.master_setting?.meta_value_one)
                : [],
            })
          );


        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {
        console.error('An error occurred while fetching site induction data:', error);
      });

    setIsOpen(true);
  };


  const onAddDialogView = () => {
    getSpecificSiteModule(
      site_induction_id == ""
        ? GettAllState.siteInductionDetailData?.site_induction_id
        : site_induction_id
    );
    setveiwInduction(true);
    SetformSatus("inductionDetails");
    setIsOpen(false);
    setAddOpen(false);
    setfinalSaveData(false);
    // setveiwInduction(true);
    // SetupdateData(!updateData);
  };

  useEffect(() => {
    if (isActive) {
      ActivateInduction(activeBtn);
    }
  }, [dialogAddOpen]);

  const ActivateInduction = (btn) => {
    setBtnDisable(true);
    dispatch(UpdateInductionStatusSite(true));
    setActiveBtn(btn);
    setisActive(true);
    let status;

    if (siteInductionDetailData?.site_ind_status == "active") {
      status = "draft";
    } else {
      status = "active";
    }
    if (btn == "archiveBtn") {
      status = "archived";
    }
    if (!dialogAddOpen) {
      openAddDialog();
    } else {
      dispatch(
        UpdateSiteInductionStatusForClient({
          site_ind_status: status,
          site_induction_id: siteInductionDetailData?.site_induction_id
            ? siteInductionDetailData?.site_induction_id
            : siteInductionId,
        })
      )
        .then((response) => {
          if (response?.success) {
            // setfinalSaveData(true);
            SetformSatus("inductionDetails");
            setfinalSaveData(false);
            setisActive(false);
            // setIsOpen(false);
            // setAddOpen(false);
            SetupdateData(!updateData);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {
        });
    }
  };

  // Delete Module Dialog box
  const onDeleteDialogClose = () => {
    setdeleteDiolog(false);
  };
  const openDeletePopup = (ModuleData, indexData) => {
    setdeleteDiolog(true);
    setdeleteModuleData({ indexData: indexData, ModuleData: ModuleData });
  };

  const deleteModuleConfirmation = () => {
    let indexData = deleteModuleData.indexData;
    if (deleteModuleData.ModuleData.module_id) {
      dispatch(DeleteSiteInductionModuleById(deleteModuleData.ModuleData)).then(
        (response) => {
          if (response?.success) {
            dispatch(DeleteSiteInductionModuleDataByIndex({ indexData }));
            setdeleteDiolog(false);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        }
      );
    } else {
      dispatch(DeleteSiteInductionModuleDataByIndex({ indexData }));
      setdeleteDiolog(false);
    }
  };

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5 px-2">
        <div className="w-80 h-10">
          <div className="input-wrapper">
            <div className="input-suffix-start left-3.5">
              <img
                src="/assets/icons-svg/search-lg.svg"
                alt=""
                className="h-5"
              />
            </div>
            <ClientInput
              type="text"
              className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Search"
              inputchangehandler={inputchangehandler}
              name={"search"}
            />
          </div>
        </div>
        <div className="flex gap-2">

          <BtnPrimary
            disabled={props?.sitePermission}
            onClick={() => openDialog()}
            className="text-xs h-9 flex items-center justify-center px-3 gap-1"
          >
            Add Induction
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.712 4.167v11.666M4.878 10h11.667"
                stroke="#fff"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </BtnPrimary>

        </div>
      </div>
      <SiteInductionList
        fetchData={fetchData}
        sitePermission={props?.sitePermission}
        siteData={props?.siteData}
        veiwInduction={veiwInduction}
        data={AllData}
        handleSort={handleSort}
        getFilterData={getFilterData}
      />
      <div className="flex justify-between mt-3">
        <Pagination
          totalCount={AllData?.count}
          pageNumber={getFilterData?.page}
          displayPage={AllData?.rows?.length}
          // UpdatePageLimit={SetFilterData}
          UpdatePageLimit={updatePageLimit}
          UpdatePage={updatePage}
        />


        {AllData?.count > 0 && <ReactPaginate
          className="flex justify-content-end p-2 align-items-center pagination-wrap"
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousLinkClassName={"prev-link"}
          nextLinkClassName={"page-link"}
          breakLinkClassName={"prev-link"}
          previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
          nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
          pageLinkClassName={"px-4 rounded-md"}
          activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
          disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
          renderOnZeroPageCount={null}
          forcePage={getFilterData?.page - 1}
        />}
      </div>

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="lg-dialog"
      >
        <div className="flex border-b pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m12.895 21.586-.1-.15c-.695-1.042-1.043-1.563-1.501-1.94a4 4 0 0 0-1.378-.737c-.568-.173-1.194-.173-2.447-.173H6.095c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874c-.218-.428-.218-.988-.218-2.108v-8.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.427-.218.987-.218 2.108-.218h.4c2.24 0 3.36 0 4.215.436A4 4 0 0 1 12.46 5.77c.436.856.436 1.976.436 4.216m0 11.6v-11.6m0 11.6.1-.15c.694-1.042 1.042-1.563 1.5-1.94a4.001 4.001 0 0 1 1.378-.737c.568-.173 1.194-.173 2.447-.173h1.375c1.12 0 1.68 0 2.107-.218a2 2 0 0 0 .875-.874c.218-.428.218-.988.218-2.108v-8.6c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.875-.874c-.427-.218-.987-.218-2.107-.218h-.4c-2.24 0-3.36 0-4.216.436A4 4 0 0 0 13.33 5.77c-.436.856-.436 1.976-.436 4.216"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              {formSatus === "inductionDetails"
                ? "Add an Induction Module"
                : siteInductionDetailData?.site_ind_name}{" "}
            </h5>
            {/* <h5 className="mb-1 text-gray-900 text-base font-semibold">Add an Induction Module</h5> */}
            {/* <p className="text-gray-600">Craft a unique induction module to onboard your team</p> */}
            {formSatus === "inductionDetails" ? (
              <p className="text-gray-600">
                Craft a unique induction module to onboard your team
              </p>
            ) : (
              <Badge
                className="py-0.5 px-2 rounded-lg"
                content={
                  siteInductionDetailData?.site_ind_status !== undefined
                    ? siteInductionDetailData?.site_ind_status
                    : "Draft"
                }
                innerClass="bg-blue-50 text-blue-800 "
              />
            )}
          </div>
        </div>
        <AddInductionDialog
          siteData={props?.siteData}
          siteInductionDetailData={siteInductionDetailData}
          siteInductionId={siteInductionId}
          validateFrom={validateFrom}
          formSatus={formSatus}
          saveData={finalSaveData}
          closePannel={closePannel}
          closePannelDisable={closePannelDisable}
          upDateState={upDateState}
          exitCheck={exitCheck}
          openDeletePopup={openDeletePopup}
        />
        <div className="flex dailog-footer mt-0 justify-end">
          <BtnLight
            className="mr-1.5 w-auto px-4"
            variant="plain"
            disabled={BtnDisable}
            onClick={openUnsaveDialog}
          >
            Cancel
          </BtnLight>

          {formSatus !== "inductionDetails" &&
            siteInductionDetailData?.site_ind_status === "active" && (
              <BtnPrimary
                disabled={BtnDisable}
                onClick={() => ActivateInduction("archiveBtn")}
                className="ml-1.5 w-auto px-4 flex gap-2 items-center"
              >
                Archive induction
              </BtnPrimary>
            )}
          {formSatus !== "inductionDetails" && (
            <BtnPrimary
              onClick={() => ActivateInduction()}
              disabled={
                BtnDisable
                  ? BtnDisable
                  : GettAllState?.SiteInductionModuleData.length
                    ? !GettAllState?.SiteInductionModuleData?.every((module) =>
                      module?.module_ques_data?.every(
                        (item) =>
                          item?.ques_ans_data.every(
                            (ans) => ans.answer !== ""
                          ) &&
                          item?.ques_ans_data.some(
                            (ans) => ans.is_correct_answer
                          )
                      )
                    )
                    : true
                // (item) => item?.module_ques_data && item?.module_ques_data[0]?.question === ""
              }
              className="ml-1.5 w-auto px-4 flex gap-2 items-center"
            >
              {siteInductionDetailData?.site_ind_status === "active"
                ? "Set induction to Draft"
                : "Activate induction"}
            </BtnPrimary>
          )}

          <BtnPrimary
            type="submit"
            disabled={BtnDisable}
            onClick={() => openAddDialog()}
            className="ml-1.5 w-auto px-4 flex gap-2 items-center"
          >
            {formSatus === "inductionDetails" ? "Next" : "Save"}
            {formSatus === "inductionDetails" ? (
              <img src="/assets/icons-svg/arrow-right.svg" alt="" />
            ) : (
              ""
            )}
          </BtnPrimary>

          {/* <BtnPrimary onClick={() => openAddDialog()} className="px-4 flex gap-2 items-center justify-center">
         Next <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.167 10.487h11.666m0 0L10 4.654m5.833 5.833L10 16.321" stroke="#fff" strokeWidth="1.667" strokeLinecap="round" strokeLinejoin="round"/>
         </svg></BtnPrimary > */}
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m12.895 21.586-.1-.15c-.695-1.042-1.043-1.563-1.501-1.94a4 4 0 0 0-1.378-.737c-.568-.173-1.194-.173-2.447-.173H6.095c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874c-.218-.428-.218-.988-.218-2.108v-8.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.427-.218.987-.218 2.108-.218h.4c2.24 0 3.36 0 4.215.436A4 4 0 0 1 12.46 5.77c.436.856.436 1.976.436 4.216m0 11.6v-11.6m0 11.6.1-.15c.694-1.042 1.042-1.563 1.5-1.94a4.001 4.001 0 0 1 1.378-.737c.568-.173 1.194-.173 2.447-.173h1.375c1.12 0 1.68 0 2.107-.218a2 2 0 0 0 .875-.874c.218-.428.218-.988.218-2.108v-8.6c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.875-.874c-.427-.218-.987-.218-2.107-.218h-.4c-2.24 0-3.36 0-4.216.436A4 4 0 0 0 13.33 5.77c-.436.856-.436 1.976-.436 4.216"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Induction added successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 flex-col gap-3">
          <BtnPrimary
            onClick={onAddDialogView}
            className="w-auto px-4 flex gap-2 items-center justify-center"
          >
            View Induction
          </BtnPrimary>
          <BtnLight onClick={onAddDialogClose}>Close</BtnLight>
        </div>
      </Dialog>

      <Dialog
        isOpen={dialogUnsaveOpen}
        onClose={onUnsaveDialogClose}
        onRequestClose={onUnsaveDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-warning-100 flex items-center justify-center">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 3.5v3.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C8.26 8.5 8.54 8.5 9.1 8.5h6.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437c.109-.214.109-.494.109-1.054V4.5m0 17v-6.4c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437c-.214-.109-.494-.109-1.054-.109H9.1c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C7.5 14.26 7.5 14.54 7.5 15.1v6.4m14-11.675V16.7c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327H8.3c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3.5 19.22 3.5 18.38 3.5 16.7V8.3c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C5.78 3.5 6.62 3.5 8.3 3.5h6.874c.49 0 .734 0 .965.055.204.05.399.13.578.24.201.123.374.296.72.642l3.126 3.126c.346.346.519.519.642.72.11.18.19.374.24.579.055.23.055.474.055.963z"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-0.5">
            You have unsaved updates
          </p>
          <p className=" text-gray-600 text-center">Save before leaving?</p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 gap-3">
          <BtnLight className="flex-1" onClick={() => closeFullForm()}>
            Exit
          </BtnLight>
          <BtnPrimary
            className="flex-1 flex items-center justify-center"
            onClick={onSaveDialog}
          >
            Save
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={deleteDiolog}
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            Are you sure you want to delete module?
          </p>
          <p className="text-gray-600 text-center">
            This action cannot be undone
          </p>
        </div>

        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight className="mr-1.5" onClick={() => setdeleteDiolog(false)}>
            Cancel
          </BtnLight>
          <BtnPrimary
            className="ml-1.5"
            onClick={() => deleteModuleConfirmation()}
          >
            Delete
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default SiteInduction;
