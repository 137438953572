import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { notify } from "react-notify-toast";
import Upload from "../../../../../common/element/Upload";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import * as userActions from "../../../../../actions/index";
import Validations from "../../../../../common/404/Validations";
import trashIcon from '../../../../../assets/icons-svg/trash.svg';
import Select from "../../../../../common/element/Select";
import PdfFile from '../../../../../assets/icons-svg/upload-pdf.svg';
import { TailSpin } from "react-loader-spinner";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { GetClientContactsForSiteList } from "../../../../../actions/client-portal/AllSiteApi";
import { decryptId } from "../../../../../common/encryptDecrypt/encryptdecrypt";
import preventInvalidChars from "../../../../../utils/preventInvalidChars";
const DuplicateSite = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const orgId=decryptId(id)

  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [search, setSearch] = useState("")
  const [documentError, setdocumentError] = useState("");
  const [optionsValue, setOptionsValue] = useState([])
  const [searchOptions, setSearchOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [formData, setFormData] = useState({
    function_assignment_id: "",
    site_name: "",
    site_address: "",
    site_address_other: "",
    site_suburb: "",
    site_state: '',
    site_zipcode: "",
    site_country: '',
    induction_valid_days: "",
    siteContact_Individual_Ids: [],
  });
  const [formErrors, setFormErrors] = useState({
    nameError: "",
    add1Error: "",
    cityError: "",
    pinCodeError: "",
    stateError: "",
    countryError: "",
    site_Contacts_Ids_Error: ""
  });
  // const [isSaving, setIsSaving] = useState(false);
  const [allStates, setAllStates] = useState();
  const [allCountries, setAllCountries] = useState();
  const [siteState, setSiteState] = useState();
  const [siteCountry, setSiteCountry] = useState();
  const [attachment, setAttachment] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [newAttachment, setNewAttachment] = useState();
  const [filesUploaded, setFilesUploaded] = useState(false);


  let organisation_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    organisation_id = storedData.organisation_id;
  }

  // const fetchClientContacts = useCallback(() => {
  useEffect(() => {
    if (searchOptions.length == 0 || optionsValue.length == 0) {
      const emailForApi = { organisation_id: orgId };
      dispatch(GetClientContactsForSiteList(emailForApi)).then((response) => {
        if (response?.success) {
          const filterData = response?.data?.map((i) => ({
            label: `${i.first_name} ${i.last_name}`,
            value: i.individual_id,
            individual_org_id: i.individual_org_id,
          }));
          setSearchOptions(filterData);
          setOptionsValue(filterData)
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      });
    }
  }, [formData?.siteContact_Individual_Ids])

  const handleSearchChange = (newInputValue) => {
    setSearch(newInputValue);

  };
  const updatedOptions = searchOptions.find((option) => option.value === search)
    ? searchOptions
    : [...searchOptions];

  const handleSelectChange = (selectedOption) => {
    let filterData = Array.isArray(selectedOption)
      ? selectedOption.map((item) => item?.value)
      : [];
    setSelectedOption(selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      siteContact_Individual_Ids: filterData
    }))
    setFormError("site_Contacts_Ids_Error", "");

    // update options for site Contacts
    const notSelectedData = optionsValue.filter(option => {
      return !selectedOption.some(selected => selected.value === option.value);
    });
    setSearchOptions(notSelectedData)
  };

  const downloadFile = async (url) => {
    try {
      const response = await fetch(url);
      if (response?.ok) {
        const contentType = response?.headers.get("content-type");

        let blobType = "application/octet-stream"; // Default to binary data

        if (contentType) {
          if (contentType.includes("application/pdf")) {
            blobType = "application/pdf";
          } else if (contentType.includes("image/jpeg")) {
            blobType = "image/jpeg";
          }
        }

        const blob = await response?.blob();

        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsArrayBuffer(blob);
        });
      } else {
        throw new Error(
          `Failed to download file. Status code: ${response?.status}`
        );
      }
    } catch (error) {
      console.error('Error fetching file:', error);
      return null;
    }
  };

  useEffect(() => {
    handleDownloadAndReupload();
  }, [fileUrl]);

  const handleDownloadAndReupload = async () => {
    if (fileUrl && Array.isArray(fileUrl)) {
      const files = [];
      for (let i = 0; i < fileUrl.length; i++) {
        const binaryData = await downloadFile(fileUrl[i]);
        if (binaryData) {
          const url = new URL(fileUrl[i]);
          const parts = url.pathname.split("-");
          let fileName;

          if (parts.length >= 3) {
            fileName = parts[parts.length - 1];
          }

          const blob = new Blob([binaryData], {
            type: fileName.endsWith(".pdf") ? "application/pdf" : "image/jpeg",
          });
          const file = new File([blob], fileName, {
            lastModified: Date.now(),
          });

          files.push(file);
        }
      }

      setAttachment(files);
    }
  };

  // Merge first name and end name and set in label
  function addLabelToObjects(dataArray) {
    return dataArray.map(data => {
      const { first_name, last_name } = data;
      return {
        ...data,
        label: `${first_name} ${last_name}`
      };
    });
  }
  const GetClientSiteById = useCallback(async () => {
    dispatch(userActions.GetClientSiteById(props?.siteId, orgId))
      .then((response) => {
        if (response?.success === true) {
          const arrayData = response?.data;
          // Modify site_name to add "Duplicate of"
          const duplicatedSiteName = `Duplicate of ${arrayData.site_name}`;

          const storeIndiviualId = []
          if (arrayData?.siteContacts) {
            const extractedData = arrayData?.siteContacts.map(contact => contact.siteContIndi).filter(Boolean);
            storeIndiviualId.push(extractedData);
          }
          const updatedArray = addLabelToObjects(storeIndiviualId[0]);
          let alreadyStoredData = [];
          for (let key in updatedArray) {
            alreadyStoredData.push({
              label: updatedArray[key].label,
              value: updatedArray[key].individual_id,
            });
          }
          setSelectedOption(alreadyStoredData);
          let FilterSiteContactId = []
          if (alreadyStoredData?.length > 0) {

            FilterSiteContactId = alreadyStoredData?.map(item => item?.value)
          }
          setFormData({
            ...formData,
            function_assignment_id: arrayData?.function_assignment_id,
            site_name: duplicatedSiteName,
            site_address: arrayData?.site_address,
            site_address_other: arrayData?.site_address_other,
            site_suburb: arrayData?.site_suburb,
            site_zipcode: arrayData?.site_zipcode,
            induction_valid_days: arrayData?.induction_valid_days,
            site_country: arrayData?.site_country?.name,
            site_state: arrayData?.site_state?.state_name,
            siteContact_Individual_Ids: FilterSiteContactId ? FilterSiteContactId : [],
          });
          // setSelectedOption(updatedArray);
          setFileUrl(arrayData?.evacuation_diagram)
          setSiteCountry(arrayData?.country_id);
          setSiteState(arrayData?.state_id);
          if (arrayData?.evacuation_diagram && arrayData?.evacuation_diagram?.length > 0) {
            setFilesUploaded(true)
          }
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        } else {
        }
      }).catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, [dispatch, props?.siteId]);

  // fetch site_contacts data and removed selected options
  useEffect(() => {
    const filteredSearchOptions = searchOptions?.filter((item) => {
      const conditionMet = formData?.siteContact_Individual_Ids?.some((i) => {
        let qwe = i?.value == item?.value;
        return qwe
      });
      return (!conditionMet) && item;
    });
    setSearchOptions(filteredSearchOptions);
  }, [formData?.siteContact_Individual_Ids])

  useEffect(() => {
    GetClientSiteById();
    //  downloadFile(url)
  }, [props?.siteId]);

  const handleInputChange = (e, identifier) => {
    const value = e.target.value;
    setFormData({ ...formData, [identifier]: value });
  };


  const GetAllStatesbyCountryId = useCallback(async () => {

    if (siteCountry) {
      dispatch(userActions.GetAllStatesbyCountryId(siteCountry)).then(
        (response) => {
          if (response?.success === true) {

            const arrayData = response?.data;
            let state_data = [];
            for (let key in arrayData) {
              state_data.push({
                label: arrayData[key].state_name,
                value: arrayData[key].state_id,
              });
            }
            setAllStates(state_data);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess()
          } else {
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [dispatch, siteCountry]);

  const GetAllCountries = useCallback(async () => {
    dispatch(userActions.GetAllCountries()).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data;
        let country_data = [];
        for (let key in arrayData) {
          country_data.push({
            label: arrayData[key].name,
            value: arrayData[key].country_id,
            // country_id: arrayData[key].country_id,
          });
        }
        setAllCountries(country_data);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
      } else {
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetAllCountries();
    GetAllStatesbyCountryId();
  }, [siteCountry]);

  const handleFileChange = (files) => {
    let totalSize = 0;
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf"
    ];

    // Filter and validate files
    let fileTypeError = false;
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          setdocumentError(
            "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
          );
          fileTypeError = true
          return false;
        }

        // Check individual file size (2MB limit)
        if (fileSize > 25 * 1024 * 1024) {
          setdocumentError("File size exceeds the 25MB limit.");
          totalSize += fileSize;
          return false;
        }
        else {
          totalSize += fileSize;
          return true;
        }

      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
      return;
    }

    // Update state if everything is valid
    if (validatedFiles?.length > 0) {
      setNewAttachment({ files: validatedFiles });
      setFilesUploaded(true)
      if (fileTypeError) {
        setdocumentError(
          "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
        );
        return false;
      }
      else {
        if (totalSize > 25 * 1024 * 1024) {
          setdocumentError("Total file size exceeds the maximum limit of 25MB.");
          return;
        }
        else {
          setdocumentError(""); // Clear any existing errors
        }
      }
    } else {
      setNewAttachment({ files: [] }); // Clear files if no valid files
    }
  };

  const handleFileChangeAdditional = (files) => {
    let totalSize = 0;
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf"
    ];

    // Filter and validate files
    let fileTypeError = false;
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          setdocumentError(
            "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
          );
          fileTypeError = true
          return false;
        }

        // Check individual file size (2MB limit)
        if (fileSize > 25 * 1024 * 1024) {
          setdocumentError("File size exceeds the 25MB limit.");
          totalSize += fileSize;
          return false;
        }
        else {
          totalSize += fileSize;
          return true;
        }

      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
      return;
    }

    // Update state if everything is valid
    if (validatedFiles?.length > 0) {
      setNewAttachment({ files: validatedFiles });
      setFilesUploaded(true)
      if (fileTypeError) {
        setdocumentError(
          "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
        );
        return false;
      }
      else {
        if (totalSize > 25 * 1024 * 1024) {
          setdocumentError("Total file size exceeds the maximum limit of 25MB.");
          return;
        }
        else {
          setdocumentError(""); // Clear any existing errors
        }
      }
    } else {
      setNewAttachment({ files: [] }); // Clear files if no valid files
    }

  };

  const handleFileRemove = (files) => {
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf"
    ];

    let totalSize = 0;
    let fileTypeError = false;

    // Revalidate remaining files after one is removed
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          fileTypeError = true;
          return false;
        }

        totalSize += fileSize;
        return true;
      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
    } else if (fileTypeError) {
      setdocumentError(
        "Invalid file type detected. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
      );
    } else {
      setdocumentError(""); // Clear any existing errors if valid
    }

    // Update state with the validated files
    setNewAttachment({ files: validatedFiles });

    // Handle edge case when all files are removed
    if (validatedFiles?.length === 0) {
      setFilesUploaded(false);
    }


    if (files.length === 0) {
      setFilesUploaded(false)
    }
  };
  const handleRemoveFile = (e) => {
    let fileArray = attachment.filter((item) => item?.name !== e.name)
    setAttachment(fileArray)
    if (fileArray.length === 0) {
      setFilesUploaded(false)
    }
  }



  const calculateTotalAttachmentSize = () => {
    let totalSize = 0;
    attachment?.files?.forEach((item) => {
      if (item?.size) {
        totalSize += item?.size;
      }
    });
    return totalSize;
  };

  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };
  const handleAddSite = (e) => {
    // e.preventDefault()
    const nameValidation = Validations({ value: formData?.site_name, Validations: { required: true } })
    const add1Validation = Validations({ value: formData?.site_address, Validations: { required: true } })
    const cityValidation = Validations({ value: formData?.site_suburb, Validations: { required: true } })
    const pinCodeValidation = Validations({ value: formData?.site_zipcode, Validations: { required: true } })
    const stateValidation = Validations({ value: siteState, Validations: { required: true } })
    const countryValidation = Validations({ value: siteCountry, Validations: { required: true } })
    const siteIdsValidation = Validations({ value: formData?.siteContact_Individual_Ids?.length >= 1 ? true : "", Validations: { required: true } });

    if (!nameValidation.valid) { setFormError("nameError", nameValidation.error_msg) }
    if (!add1Validation.valid) { setFormError("add1Error", add1Validation.error_msg) }
    if (!cityValidation.valid) { setFormError("cityError", cityValidation.error_msg) }
    if (!pinCodeValidation.valid) { setFormError("pinCodeError", pinCodeValidation.error_msg) }
    if (!stateValidation.valid) { setFormError("stateError", stateValidation.error_msg) }
    if (!countryValidation.valid) { setFormError("countryError", countryValidation.error_msg) }
    if (!siteIdsValidation.valid) { setFormError("site_Contacts_Ids_Error", siteIdsValidation.error_msg); }


    const totalSize = calculateTotalAttachmentSize();
    if (totalSize > 25 * 1024 * 1024) {
      return;
    }

    const combinedAttachments = [...(attachment || []), ...(newAttachment || [])];
    if (nameValidation.valid === true &&
      add1Validation.valid === true &&
      documentError == "" &&
      cityValidation.valid === true &&
      pinCodeValidation.valid === true &&
      stateValidation.valid === true &&
      siteIdsValidation.valid === true &&
      countryValidation.valid === true) {
      props?.setIsSaving(true);
      const payload = {
        function_assignment_id: formData?.function_assignment_id,
        country_id: siteCountry,
        state_id: siteState,
        site_name: formData?.site_name,
        site_address: formData?.site_address,
        site_address_other: formData?.site_address_other,
        site_suburb: formData?.site_suburb,
        site_zipcode: formData?.site_zipcode,
        induction_valid_days: formData?.induction_valid_days,
        evacuation_diagram: combinedAttachments,
        siteContactIndividualIds: JSON.stringify(formData?.siteContact_Individual_Ids ? formData?.siteContact_Individual_Ids : [])
      };


      props?.onCreateSite(payload)
        .then((response) => {
          if (response?.success === true) {
            props?.onSiteDialogClose()
            props?.GetAllSitesOfClient()
            props?.setIsSaving(false);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess()
            props?.setIsSaving(false);
          } else {
            props?.setIsSaving(false);
          }
        })
        .catch((error) => {
          props?.setIsSaving(false);
        });
    }
  }



  return (
    <React.Fragment>
      <form className="px-6">
        {!props?.isSaving && <div>
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/5">Site name*</label>
            <div className="w-4/5 pl-2">
              <input
                type="text"
                placeholder="Duplicate of [Site Name]"
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={formData?.site_name}
                onChange={(e) => handleInputChange(e, "site_name")}
              />
              {formErrors.nameError && (!formData?.site_name ? <p className='text-xs text-red-600 mt-1'>{formErrors.nameError}</p> : '')}
            </div>
          </div>
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/5">
              Site Address*
            </label>
            <div className="w-4/5 pl-2">
              <div class="mb-5">
                <input
                  type="text"
                  placeholder="Street Address*"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData?.site_address}
                  onChange={(e) => handleInputChange(e, "site_address")}
                // readOnly
                />
                {formErrors.add1Error && (!formData?.site_address ? <p className='text-xs text-red-600 mt-1'>{formErrors.add1Error}</p> : '')}
              </div>
              <div className="flex">
                <div className="flex-1 pr-1 mb-5 ">
                  <input
                    type="text"
                    placeholder="Apartment, suite, etc "
                    className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    value={formData?.site_address_other}
                    onChange={(e) => handleInputChange(e, "site_address_other")}
                  />
                </div>
                <div className="flex-1 pl-1 mb-5 ">
                  <input
                    type="text"
                    placeholder="Suburb*"
                    className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    value={formData?.site_suburb}
                    onChange={(e) => handleInputChange(e, "site_suburb")}
                  />
                  {formErrors.cityError && (!formData?.site_suburb ? <p className='text-xs text-red-600  mt-1'>{formErrors.cityError}</p> : '')}
                </div>
              </div>
              <select
                value={siteCountry}
                onChange={(e) => setSiteCountry(e.target.value)}
                // disabled={true}
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="">Country</option>
                {allCountries &&
                  allCountries.map((country) => (
                    <option key={country.value} value={country.value}>
                      {country.label}
                    </option>
                  ))}
              </select>
              {formErrors.countryError && (!siteCountry ? <p className='text-xs text-red-600 mt-1'>{formErrors.countryError}</p> : '')}

              <div className="flex mt-5">
                <div className="flex-1 pr-1">
                  <select
                    value={siteState}
                    onChange={(e) => setSiteState(e.target.value)}
                    // disabled={true}
                    className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option>State*</option>
                    {allStates &&
                      allStates.map((state) => (
                        <option key={state.value} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                  </select>
                  {formErrors.stateError && (!siteState ? <p className='text-xs text-red-600 mt-1'>{formErrors.stateError}</p> : '')}
                </div>
                <div className="flex-1 pl-1">
                  <input
                    type="number"
                    placeholder="Postcode*"
                    value={formData?.site_zipcode}
                    onKeyDown={preventInvalidChars}
                    onChange={(e) => {
                      if (e?.target?.value?.length <= 10) {
                        handleInputChange(e, "site_zipcode");
                      }
                    }}
                    className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {formErrors.pinCodeError && (!formData?.site_zipcode ? <p className='text-xs text-red-600 mt-1'>{formErrors.pinCodeError}</p> : '')}
                </div>
              </div>

            </div>
          </div>
          <div className="flex mb-5 border-t mt-5 pt-5">
            <label className="font-medium text-gray-700 w-1/5">Valid days*</label>
            <div className="w-4/5 pl-2">
              <input
                type="number"
                placeholder="000"
                value={formData?.induction_valid_days}
                onKeyDown={preventInvalidChars}
                onChange={(e) => handleInputChange(e, "induction_valid_days")}
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
          <div className="flex mb-5 border-t mt-5 pt-5">
            <label className="font-medium text-gray-700 w-1/5">
              Evacuation Diagram
            </label>

            <div className="w-4/5 pl-2 hideIcons">
              {filesUploaded ? (
                // Show the second Upload component when files are uploaded
                <Upload
                  className="text-blue-700 flex font-semibold text-xs items-center justify-end"
                  onChange={handleFileChangeAdditional}
                  onFileRemove={handleFileRemove}
                  maxFileSize={25 * 1024 * 1024}
                >
                  Upload additional
                  <span className="pl-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 4.167v11.666M4.167 10h11.666"
                        stroke="#5786CC"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Upload>
              ) : (
                // Show the first Upload component when no files are uploaded
                <Upload
                  draggable
                  className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                  onChange={handleFileChange}
                  onFileRemove={handleFileRemove}
                  multiple={true}
                  maxFileSize={25 * 1024 * 1024}
                ><span className="text-blue-700 font-semibold">
                    Click to upload
                  </span>{" "}
                  <p className="text-gray-600">
                    PDF, JPG, JPEG, PNG, GIF (MAX. 25MB)
                  </p></Upload>
              )}
              {documentError && <p className='text-xs text-red-600 mt-1'>{documentError}</p>}

              <div className="upload-file-list">
                {attachment &&
                  attachment.map((item, index) => (
                    (item ?

                      <div key={index} className="upload-file flex items-center justify-between mt-2">
                        <div className="upload-file-left flex items-center">
                          <div className="upload-file-thumbnail">
                            <span className="w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3">
                              <img src={PdfFile} alt="" />
                            </span>
                          </div>
                          <a href={item} target="_blank" rel="noopener noreferrer">
                            <span className="upload-file-name font-medium text-xs text-gray-700">
                              {item?.name}
                            </span>
                          </a>
                        </div>
                        <span className="upload-file-remove w-5"
                          onClick={() => handleRemoveFile(item)}
                        >
                          <span className=""><img src={trashIcon} alt="" /></span>
                        </span>
                      </div> : '')
                  ))
                }
              </div>
            </div>
          </div>
        </div>}

        {/* Site's Contact */}
        <div className="flex mb-5 border-t mt-5 pt-5">
          <label className="font-medium text-gray-700 w-1/5">
            Site’s contacts*
          </label>
          <div className="w-4/5 pl-2">
            <Select
              type="text"
              placeholder="Select"
              className="w-full h-full select-options"
              isClearable={true}
              isSearchable={true}
              options={updatedOptions ? updatedOptions : searchOptions}
              value={selectedOption}
              onChange={handleSelectChange}
              isMulti={true}
              handleSearchChange={handleSearchChange}
            />
            {formErrors.site_Contacts_Ids_Error && (
              <p className="text-xs text-red-600 mt-1">
                {formErrors.site_Contacts_Ids_Error}
              </p>
            )}

          </div>
        </div>
        {props?.isSaving && (
          <div>
            <div className="blur">
              <div className="flex">
                <label className="font-medium text-gray-700 w-1/5">
                  Site Address*
                </label>
                <div className="w-4/5 pl-2">
                  <input type="text" placeholder="Street Address*" className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" />
                  <input type="text" placeholder="Street Address*" className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" />
                </div>
              </div>

            </div>
            <div className="flex justify-center mb-5 mt-5 pt-5">
              <TailSpin color="blue" radius={"8px"} /></div>


            <div className="blur">
              <div className="flex mb-5 border-t mt-5 pt-5">
                <label className="font-medium text-gray-700 w-1/5">
                  Evacuation Diagram
                </label>
                <div className="w-4/5 pl-2 hideIcons">
                  <Upload className="text-blue-700 flex font-semibold text-xs items-center justify-end" >
                    Upload additional
                    <span className="pl-2"> </span>
                  </Upload>
                </div>
              </div>
            </div>

          </div>
        )}

      </form>
      <div className="flex dailog-footer ">
        {!props?.isSaving && <BtnLight className="mr-1.5" onClick={(e) => props?.onSiteDialogClose(e)}>Cancel</BtnLight>}
        <BtnPrimary className="ml-1.5" type='submit' onClick={() => handleAddSite()}
          disabled={props?.isSaving}>
          {props?.isSaving ? "Saving..." : "Duplicate Site"}
        </BtnPrimary>
      </div>
    </React.Fragment>
  );
};



const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  onCreateSite: userActions.CreateSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateSite);