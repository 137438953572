import React, { useState, useEffect, useCallback } from "react";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import SmallButton from "../../../../../common/element/Buttons/btnPrimary";
import Table from "../../../../../common/element/Table";
import { useDispatch } from "react-redux";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Button from "../../../../../common/element/Buttons";
import Switcher from "../../../../../common/element/Switcher";
import Tooltip from "../../../../../common/element/Tooltip";
import Dialog from "../../../../../common/element/Dialog";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { ConserveUserPersonalNotifPermission, UpdateConserveUsersPersonalPermissions } from "../../../../../actions/Admin";

const Notification = () => {
    const dispatch = useDispatch();
    const handleUnauthorizedAccess = useUnauthorizedHandler();
    const { Tr, Th, Td, THead, TBody } = Table;
    const [dialogAddOpen, setDialogAddOpen] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [changedNotifications, setChangedNotifications] = useState([]); // Track changed notifications
    const loginData = JSON.parse(localStorage.getItem("LoginUserData"));

    let user_id;
    const user_type = "worker";
    if (loginData != null) {
        user_id = loginData?.userName?.user_id;
        // user_id = "4be23a66-99c2-4f8c-a905-31376b624bae"
    }

    const onAddDialogClose = () => {
        setDialogAddOpen(false);
        setChangedNotifications([])
    };

    const WorkerPersonalNotifypermissions = useCallback(async () => {
        try {
            const response = await dispatch(ConserveUserPersonalNotifPermission({ user_id: user_id, user_type: user_type }));

            if (response?.success) {
                setNotificationList(response?.data);
            } else if (response?.status === 401) {
                handleUnauthorizedAccess();
            }
        } catch (error) {
            console.error('Error fetching global permissions:', error);
        }
    }, [dispatch]);

    useEffect(() => {
        WorkerPersonalNotifypermissions();
    }, [WorkerPersonalNotifypermissions]);

    const handleAllOnOff = (item) => {
        // Define the corresponding access property for each notification type
        const accessProperty = {
            'notify_task': 'worker_task_access',
            'notify_bell': 'worker_bell_access',
            'notify_email': 'worker_email_access',
        };
        // Filter notifications with access enabled
        const enabledNotifications = notificationList.filter(notification => notification[accessProperty[item]]);

        // Determine if all enabled notifications have the 'item' property set to true
        const allEnabled = enabledNotifications.every(notification => notification[item]);

        // Update only notifications with access enabled
        const updatedNotifications = notificationList.map(notification => {
            const hasAccess = notification[accessProperty[item]];
            return {
                ...notification,
                [item]: hasAccess ? !allEnabled : notification[item], // Only toggle if access is true
            };
        });
        setNotificationList(updatedNotifications);
        // Track changed notifications for the save button (remove duplicates and add updated items)
        const updatedItems = updatedNotifications.filter((notification, index) =>
            notificationList[index][item] !== notification[item]
        );
        setChangedNotifications(prev => {
            const uniqueNotifications = prev.filter(
                prevItem => !updatedItems.some(
                    updatedItem => updatedItem?.notifi_global_setting_id === prevItem?.notifi_global_setting_id
                )
            );

            return [...uniqueNotifications, ...updatedItems];
        });
    };


    const onSwitcherToggle = (val, notifi_global_setting_id, type) => {
        let updatedNotificationObject = null;

        // Update the notification that matches the ID
        const updatedNotifications = notificationList?.map(notification => {
            if (notification.notifi_global_setting_id === notifi_global_setting_id) {
                updatedNotificationObject = {
                    ...notification,
                    [type]: !notification[type],
                };
                return updatedNotificationObject;
            }
            return notification;
        });

        // Update the notification list state
        setNotificationList(updatedNotifications);

        // Check if the object already exists in changedNotifications before adding it
        setChangedNotifications(prev => {
            const exists = prev.some(
                notification => notification.notifi_global_setting_id === notifi_global_setting_id
            );

            if (exists) {
                // If it exists, update the value in the previous list
                return prev.map(notification =>
                    notification.notifi_global_setting_id === notifi_global_setting_id
                        ? updatedNotificationObject
                        : notification
                );
            } else {
                // If it doesn't exist, add it to the list
                return [...prev, updatedNotificationObject];
            }
        });
    };


    const submitNotifications = () => {
        if (changedNotifications?.length > 0) {
            const payload = changedNotifications?.map(notification => ({
                notifi_global_setting_id: notification?.notifi_global_setting_id,
                notifi_personal_setting_id: notification?.notifi_personal_setting_id,
                user_id: user_id,
                user_type: user_type,
                notify_bell: notification?.notify_bell,
                notify_email: notification?.notify_email,
                notify_task: notification?.notify_task,
            }));

            dispatch(UpdateConserveUsersPersonalPermissions(payload))
                .then((response) => {
                    if (response?.success) {
                        setChangedNotifications([])
                        setDialogAddOpen(true);
                    } else {
                        console.error("Failed to update notification");
                    }
                })
                .catch((error) => {
                    console.error("Error while updating notification:", error);
                });
        }
    };

    return (
        <React.Fragment>
            <div className="flex justify-between mb-5 border-b border-gray-200 pb-5">
                <div>
                    <h5 className="text-base text-gray-900 font-semibold mb-1">Notifications</h5>
                    <p className="text-gray-600">Customize your notification preferences to stay informed and in control of your alerts and updates.</p>
                </div>
                <div className="flex justify-end items-center">
                    <BtnLight className="w-auto px-4">Cancel</BtnLight>
                    <SmallButton className="w-auto px-4 ml-3" onClick={() => submitNotifications()}>Save</SmallButton>
                </div>
            </div>

            <Table className="notification-table">
                <THead>
                    <Tr>
                        <Th>Notify me when...</Th>
                        <Th>
                            Task <br />
                            <Button
                                className="text-blue-700 text-xs font-semibold hover:text-blue-900 focus:text-blue-700"
                                onClick={() => notificationList.length > 0 && handleAllOnOff('notify_task')}
                            >
                                {notificationList?.every(notification => notification?.notify_task)
                                    ? "Turn off all tasks"
                                    : "Turn on all tasks"}
                            </Button>
                        </Th>
                        <Th>
                            Bell <br />
                            <Button
                                className="text-blue-700 text-xs font-semibold hover:text-blue-900 focus:text-blue-700"
                                onClick={() => notificationList.length > 0 && handleAllOnOff('notify_bell')}
                            >
                                {notificationList?.every(notification => notification?.notify_bell)
                                    ? "Turn off all bells"
                                    : "Turn on all bells"}
                            </Button>
                        </Th>
                        <Th>
                            Email <br />
                            <Button
                                className="text-blue-700 text-xs font-semibold hover:text-blue-900 focus:text-blue-700"
                                onClick={() => notificationList.length > 0 && handleAllOnOff('notify_email')}
                            >
                                {notificationList?.every(notification => notification?.notify_email)
                                    ? "Turn off all emails"
                                    : "Turn on all emails"}
                            </Button>
                        </Th>
                    </Tr>
                </THead>
                <TBody>
                    {notificationList?.map((notification, idx) => (
                        <Tr key={idx} >
                            <Td>
                                <span className="block font-semibold text-gray-700">
                                    {notification?.permission_name}
                                </span>
                                <span className="text-gray-600 text-xs">
                                    {notification?.message_template || "Description not available"}
                                </span>
                            </Td>
                            <Td>
                                {notification?.worker_task_access ? (
                                    <Switcher
                                        checked={notification?.notify_task}
                                        onChange={(val) => onSwitcherToggle(val, notification?.notifi_global_setting_id, 'notify_task')}
                                    />
                                ) :
                                    (<Tooltip title="Disabled">
                                        <Switcher
                                            className="personal-notify"
                                            disabled={true}
                                            checked={false}
                                        />
                                    </Tooltip>)}
                                <span className="text-xs text-gray-700 font-medium ml-2">Task</span>
                            </Td>

                            <Td>
                                {notification?.worker_bell_access ? (
                                    <Switcher
                                        checked={notification?.notify_bell}
                                        onChange={(val) => onSwitcherToggle(val, notification?.notifi_global_setting_id, 'notify_bell')}
                                    />
                                ) :
                                    (<Tooltip title="Disabled">
                                        <Switcher
                                            className="personal-notify"
                                            disabled={true}
                                            checked={false}
                                        />
                                    </Tooltip>)}
                                <span className="text-xs text-gray-700 font-medium ml-2">Bell</span>
                            </Td>
                            <Td>
                                {notification?.worker_email_access ? (
                                    <Switcher
                                        checked={notification?.notify_email}
                                        onChange={(val) => onSwitcherToggle(val, notification?.notifi_global_setting_id, 'notify_email')}
                                    />
                                ) :
                                    (<Tooltip title="Disabled">
                                        <Switcher
                                            className="personal-notify"
                                            disabled={true}
                                            checked={false}
                                        />
                                    </Tooltip>)}
                                <span className="text-xs text-gray-700 font-medium ml-2">Email</span>
                            </Td>
                        </Tr>
                    ))}
                </TBody>
            </Table>

            <Dialog
                isOpen={dialogAddOpen}
                onClose={onAddDialogClose}
                onRequestClose={onAddDialogClose}
                bodyOpenClassName="overflow-hidden"
                className="customAlert"
            >
                <div className="px-6 pt-6">
                    <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="49"
                            height="49"
                            viewBox="0 0 49 49"
                            fill="none"
                        >
                            <rect
                                x="0.583008"
                                y="1.37207"
                                width="47"
                                height="47"
                                rx="23.5"
                                fill="#D1FADF"
                            />
                            <rect
                                x="0.583008"
                                y="1.37207"
                                width="47"
                                height="47"
                                rx="23.5"
                                stroke="#ECFDF3"
                            />
                            <path
                                d="M32.083 18.8721L21.083 29.8721L16.083 24.8721"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </span>
                    <p className="text-base text-gray-900 font-semibold text-center mt-4">
                        Personal notifications updated successfully
                    </p>
                </div>
                <div className="mt-8 flex justify-between pb-6 px-6">
                    <BtnPrimary onClick={() => onAddDialogClose()}>Close</BtnPrimary>
                </div>
            </Dialog>
        </React.Fragment >
    );
};

export default Notification;

