import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/AdminContractor/GetAdminSpecificContractorSlice";
export const GetAllIndividualListForWorkerInvites = (search,provider_org_id) => async (dispatch) => {
    /*** API Call Action ***/
    try {
    //   dispatch(fetchData())
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/worker/GetAllIndividualListForWorkerInvites`,
        params: {search:search,provider_org_id:provider_org_id}
      });
      return response.data
    //   dispatch(fetchDataSuccess(response.data))
    } catch (error) {
        return error.message
    //   dispatch(fetchDataError(error.message))  
    }
  };