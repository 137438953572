
// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const ProviderRegisterFinalData = createSlice({
  name:"ProviderRegisterFinalData",
  initialState:[],
  reducers:{
    AddProviderRegisterFinalData(state,action){
      return (action.payload)
    },
    ResetProviderRegisterFinalData(){
      return ""
    },
  }
}) 
export const {AddProviderRegisterFinalData,ResetProviderRegisterFinalData}=ProviderRegisterFinalData.actions;
export default ProviderRegisterFinalData.reducer;