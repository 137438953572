import React, { useEffect, useState } from "react";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Table from "../../../../../../common/element/Table";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { updateRoutefunc } from "../../../../../../redux/classes/common/updateRoute";
import ImageUploader from "../../../../../../common/element/ImageUploder";
import CryptoJS from "crypto-js";
import { encryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";

const ContractorsList = ({
  handleSort,
  getAllinvitedData,
  providerAllData,
}) => {
  const [dialogChoose, setChoose] = useState(false);
  const [data, SetData] = useState([]);
  const [specificData, SetSpecificData] = useState();
  const dispatch = useDispatch();
  let encryptedId;
  const openChoose = (items) => {
    setChoose(true);
    SetSpecificData(items);
    encryptedId = encryptId(items?.organisation_id);
  };

  const onChooseClose = (e) => {
    setChoose(false);
  };
  // Update Route for View Provider Profile
  const UpdateRoute = () => {
    dispatch(updateRoutefunc(true));
  };
  const { Tr, Th, Td, THead, TBody } = Table;
  const GettAllFunctions = useSelector((state) => state);
  const defaultImageUrl =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVYhkJiYFb8n7qEEchgHlIoZzgalsuUrfkXQ&s";

  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("trading_name")}
                >
                  Provider name
                  <svg
                    className={`transform ${
                      getAllinvitedData.sort !== "" &&
                      getAllinvitedData.sort === "trading_name" &&
                      getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("abn_nzbn")}
                >
                  ABN/NZBN
                  <svg
                    className={`transform ${
                      getAllinvitedData.sort !== "" &&
                      getAllinvitedData.sort === "abn_nzbn" &&
                      getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-96">Email</Th>
              <Th className="w-44">Primary contact</Th>
            </Tr>
          </THead>
          <TBody>
            {providerAllData?.map((item, index) => {
              return (
                <Tr
                  className={"cursor-pointer"}
                  onClick={() => openChoose(item)}
                  key={index}
                >
                  <Td>{item?.trading_name ? item?.trading_name : "-"} </Td>
                  <Td>{item?.abn_nzbn ? item?.abn_nzbn : "-"}</Td>
                  <Td>{item?.email ? item?.email : "-"}</Td>
                  <Td>{item?.phone ? item?.phone : "-"}</Td>
                </Tr>
              );
            })}
          </TBody>
        </Table>
      </div>
      <Dialog
        isOpen={dialogChoose}
        onClose={onChooseClose}
        onRequestClose={onChooseClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_16588_103756)">
                <path
                  d="M19.2751 21.3745C20.0803 21.3745 20.7308 20.7225 20.7308 19.9191V16.0467C20.7308 15.2434 20.0803 14.5914 19.2751 14.5914H13.4651C12.6599 14.5914 12.0094 15.2434 12.0094 16.0467V19.9191C12.0094 20.7225 12.6599 21.3745 13.4651 21.3745H19.2751ZM19.2751 20.0128H13.4651C13.4134 20.0128 13.3714 19.9707 13.3714 19.9191V16.0467C13.3714 15.9951 13.4134 15.9531 13.4651 15.9531H19.2751C19.3268 15.9531 19.3688 15.9951 19.3688 16.0467V19.9191C19.3688 19.9707 19.3268 20.0128 19.2751 20.0128Z"
                  fill="#344054"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M19.3687 16.0458C19.3687 16.5251 18.9797 16.9141 18.5004 16.9141H14.2397C13.7604 16.9141 13.3714 16.5251 13.3714 16.0458C13.3714 15.6699 13.0663 15.3648 12.6904 15.3648C12.3145 15.3648 12.0094 15.6699 12.0094 16.0458C12.0094 17.2769 13.0086 18.2761 14.2397 18.2761H18.5004C19.7315 18.2761 20.7307 17.2769 20.7307 16.0458C20.7307 15.6699 20.4256 15.3648 20.0497 15.3648C19.6738 15.3648 19.3687 15.6699 19.3687 16.0458Z"
                  fill="#344054"
                  stroke="#344054"
                  stroke-width="0.2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.9219 15.389C18.2425 15.389 18.5029 15.1287 18.5029 14.808V14.0333C18.5029 13.285 17.897 12.6777 17.1472 12.6777H15.5979C14.848 12.6777 14.2422 13.285 14.2422 14.0333V14.808C14.2422 15.1287 14.5025 15.389 14.8232 15.389H17.9219ZM17.3409 14.3727H15.4042V14.0333C15.4042 13.9265 15.491 13.8397 15.5979 13.8397H17.1472C17.2541 13.8397 17.3409 13.9265 17.3409 14.0333V14.3727Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M10.8864 14.5664C9.00961 14.598 5.44132 15.2346 3.66681 19.0573C3.45485 19.5142 3.49124 20.237 4.83672 20.276C6.18219 20.315 8.71244 20.2923 10.2724 20.276"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="11.6802"
                  cy="8.40674"
                  r="3.75986"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                />
              </g>
              <defs>
                <clipPath id="clip0_16588_103756">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.109375 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Viewing Provider
            </h5>
          </div>
        </div>
        <div className="px-6">
          <div className="flex w-full">
            <div className="border h-72 border-gray-300 rounded-lg w-56 flex items-center text-center justify-center">
              <span className="text-xs text-gray-400 font-semibold">
                <ImageUploader
                  uploadImage={false} // Set to false to disable image upload
                  defaultImage={
                    specificData?.logo == "" ||
                    specificData?.logo == "null" ||
                    specificData?.logo == undefined
                      ? defaultImageUrl
                      : specificData?.logo
                  }
                />
              </span>
            </div>
            <div className="flex w-4/6 pl-6">
              <div className="w-3/6">
                <div className="flex flex-col mb-5">
                  <label className="text-xs font-medium text-gray-700">
                    Provider name
                  </label>
                  <div className="text-gray-900 font-semibold">
                    {specificData?.trading_name
                      ? specificData?.trading_name
                      : "Not available"}
                  </div>
                </div>
                <div className="flex flex-col mb-5">
                  <label className="text-xs font-medium text-gray-700">
                    Email address
                  </label>
                  <div className="text-gray-900 font-semibold">
                    {specificData?.email
                      ? specificData?.email
                      : "Not available"}
                  </div>
                </div>
                <div className="flex flex-col mb-5">
                  <label className="text-xs font-medium text-gray-700">
                    Website
                  </label>
                  {specificData?.website_url == "" ||
                  specificData?.website_url == "null" ||
                  specificData?.website_url == undefined ? (
                    <div className="text-gray-900 font-semibold">-</div>
                  ) : (
                    <Link
                      to={specificData?.website_url || ""}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-xs text-blue-700 flex gap-1"
                    >
                      {specificData?.website_url == "" ||
                      specificData?.website_url == "null" ||
                      specificData?.website_url == undefined
                        ? ""
                        : specificData?.website_url}
                      <svg
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7"
                          stroke="#5786cc"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Link>
                  )}
                </div>
                <div className="flex flex-col mb-5">
                  <label className="text-xs font-medium text-gray-700">
                    Address
                  </label>
                  <div className="text-gray-900 font-semibold">
                    {specificData?.address_one
                      ? specificData?.address_one
                      : "Not available"}
                  </div>
                </div>
              </div>
              <div className="w-3/6">
                <div className="flex flex-col mb-5">
                  <label className="text-xs font-medium text-gray-700">
                    ABN/NZBN
                  </label>
                  <div className="text-gray-900 font-semibold">
                    {specificData?.abn_nzbn
                      ? specificData?.abn_nzbn
                      : "Not available"}
                  </div>
                </div>
                <div className="flex flex-col mb-5">
                  <label className="text-xs font-medium text-gray-700">
                    Contact number
                  </label>
                  <div className="text-gray-900 font-semibold">
                    {specificData?.phone
                      ? specificData?.phone
                      : "Not available"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight
            onClick={() => onChooseClose()}
            className="mr-1.5"
            variant="plain"
          >
            Close
          </BtnLight>
          <BtnPrimary
            onClick={() => {
              UpdateRoute();
            }}
            className="ml-1.5"
          >
            <Link
              to={`/admin/provider/view-all-provider/specific-provider/${encodeURIComponent(
                encryptedId
              )}`}
            >
              View Provider’s full profile
            </Link>
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default ContractorsList;
