import axios from "axios";
import * as actionType from "../../../constant/actionType";

export const SaveTrainingCompIndModule = (APIData) => {
  return (dispatch) => {
    return axios({
      method: "post",
      data: APIData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/worker/SaveTrainingCompIndModule`,
    })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  };
};
