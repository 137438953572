import axios from "axios";
import * as actionType from "../../../constant/actionType";
export const GetAllAssignedChecklistOfProvider = (ApiData) => async (dispatch) => {
    try {
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/provider/GetAllAssignedChecklistOfProvider`,
        params: ApiData,
      });
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error fetching provider docs:', error);
      return { success: false, error: error.message || 'Unknown error' };
    }
  };
  
  export const UpdateProviderChecklistAssign = (ApiData) => async (dispatch) => {
    try {
      const response = await axios({
        method: 'put',
        data: ApiData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/provider/UpdateProviderChecklistAssign`,
      });
      return response.data
    } catch (error) {
      return console.log(error.message);
    }
  }