import React, { useState, useEffect, useCallback } from "react";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { notify } from "react-notify-toast";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import DuplicateSite from "../../Dialog/DuplicateSite";
import Tag from "../../../../../../common/element/Tag";
import * as userActions from "../../../../../../actions/index";
import ThreeDotLoader from "../../../../../../common/element/ButtonLoader/ThreeDotLoader";
import { DeleteSite } from "../../../../../../actions/Admin-Client/AllSiteApi";
import Table from "../../../../../../common/element/Table";
import { encryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";
const { Tr, Th, Td, THead, TBody } = Table;

const SitesList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [IsOpen, setOpen] = useState(false);
  const [openDataExistDialog, setDataExistDailog] = useState(false);
  const [siteId, setSiteId] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [specificSiteData, setSiteData] = useState();
  const [isSaving, setIsSaving] = useState(false);

  // const functionAssignmentId = useSelector(
  //     (state) => state.functionAssignmentId.functionAssignmentId
  //  )
  // const clientName =
  // useEffect(() => {
  //     setAllSiteData(props?.allSiteData)
  // }, [props?.allSiteData])
  const onCloseExistDailog = (e) => {
    setDataExistDailog(false)
    onDialogClose()
  }
  const openDialog = (siteId) => {
    setIsOpen(true);
    setSiteId(siteId)
  };

  const onDialogClose = (e) => {
    if (!buttonDisabled) {
      setIsOpen(false);
    }
  };

  const openSiteDialog = (siteData) => {
    setSiteData(siteData?.siteId);
    setOpen(true);
  };

  const onSiteDialogClose = (e) => {
    if (isSaving == false) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  // Delete Site
  const handleDeleteSite = async () => {
    setButtonDisabled(true);
    try {
      const response = await dispatch(DeleteSite(siteId?.siteId,siteId?.siteName, ipAddress));
      if (response?.success) {
        onDialogClose()
        setButtonDisabled(false)
        setSiteId(null)
        if (response?.message !== "Delete site inductions first to remove site!") {
          props?.GetAllSitesOfClient();
        }

        // apply 
      }
      // Change this later
      if (response?.message == "Delete site inductions first to remove site!") {
        setDataExistDailog(true)
        setButtonDisabled(false)
        setSiteId(null)
      } else {
        console.error("Failed to delete site:", response.message);
      }
    } catch (error) {
      console.error("Error deleting site:", error);
    } finally {
      setButtonDisabled(false)
      setSiteId(null)
    }
  };


  //     const GetAllSitesOfClient = useCallback(async () => {
  //         dispatch(userActions.GetAllSitesOfClient(functionAssignmentId)).then((response) => {
  //            if (response?.success === true) {
  //                const arrayData = response?.data.rows
  //                let siteData = [];
  //              for (let key in arrayData) {
  //               siteData?.push({
  //                  siteName: arrayData[key].site_name,
  //                  siteId: arrayData[key].site_id,
  //                  siteState: arrayData[key].state_name,
  //                  stateId: arrayData[key].state_id,
  //                  siteAddress: arrayData[key].site_address,
  //                  siteStatus: arrayData[key].is_site_active,
  //               })
  //             }
  //             setAllSiteData(siteData)
  //           } else if (response?.status === 401) { }
  //            else { }
  //        });
  //      // eslint-disable-next-line
  //   }, [dispatch]);

  //   useEffect(() => {
  //     GetAllSitesOfClient();
  //   }, [])

  const handleAddSite = (e) => {
    // e.preventDefault()
    const payload = {
      //   site_id: ,
    };


    props?.onRemoveClientSiteById(payload).then((response) => {
      if (response?.success === true) {
        props?.onDialogClose();
      } else if (response?.status === 401) {
        handleUnauthorizedAccess();
      } else {
      }
    });
    //   }
  };

  const handleRowClick = (item) => {
    if (item?.siteId) {
      const encryptedId = encryptId(item?.siteId);
      // Navigate to the specific site page
      navigate(`/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}/specific-site/${encodeURIComponent(encryptedId)}`);
    }
  };
  return (
    <React.Fragment>
      <div className="flex flex-col">
        <div className="overflow-hidden border rounded-lg border-gray-200">
          <Table>
            <THead>
              <Tr>
                <Th className="w-1/4">
                  <span
                    className="flex items-center"
                    onClick={() => props?.handleSort("site_name")}
                  >
                    Site name
                    <svg
                      className={`transform ${props?.filtersort === "site_name" &&
                        props?.filterorder === "asc"
                        ? "rotate-180"
                        : ""
                        }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        stroke-width="1.333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </Th>
                <Th className="w-2/5 min-w-72">
                  <span className="inline-flex items-center">Address</span>
                </Th>

                <Th className="w-44">State</Th>
                <Th className="w-44">
                  <span className="flex items-center">Status</span>
                </Th>
                <Th className="w-36"></Th>
              </Tr>
            </THead>
            <TBody>
              {props?.allSiteData &&
                props?.allSiteData.map((item, index) => (
                  <Tr
                    key={index}
                    style={{ cursor: 'pointer' }} // Show that the row is clickable
                    onClick={() => handleRowClick(item)} // Navigate on row click
                  >
                    <Td>
                      <span className="whitespace-nowrap">
                        {item?.siteName ? item?.siteName : "-"}
                      </span>
                    </Td>
                    <Td>
                      <span className="whitespace-nowrap">
                        {item?.siteAddress ? item?.siteAddress : "-"}
                      </span>
                    </Td>
                    <Td>
                      <span className="whitespace-nowrap">
                        {item?.siteState ? item?.siteState : "-"}
                      </span>
                    </Td>
                    <Td>
                      <span className={`badge py-0.5 px-2 rounded-lg ${item?.siteStatus ? "bg-success-50 text-success-700" : "bg-red-50 text-red-700"}`}>
                        {item?.siteStatus ? "Active" : "Inactive"}
                      </span>
                    </Td>

                    <Td>
                      <div className="flex">
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click from triggering
                            openSiteDialog(item);
                          }}
                          className="p-2"
                        >
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.167 13.218c-.777 0-1.165 0-1.471-.127a1.666 1.666 0 0 1-.902-.902c-.127-.306-.127-.694-.127-1.47V5.05c0-.933 0-1.4.181-1.756.16-.314.415-.569.729-.729.356-.181.823-.181 1.756-.181H10c.777 0 1.165 0 1.471.127.409.169.733.493.902.902.127.306.127.694.127 1.47m-2.333 14.167h5.5c.933 0 1.4 0 1.756-.181.314-.16.569-.415.729-.729.181-.356.181-.823.181-1.756v-5.5c0-.934 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182h-5.5c-.934 0-1.4 0-1.757.182-.314.16-.569.415-.728.728-.182.357-.182.823-.182 1.757v5.5c0 .933 0 1.4.182 1.756.16.314.414.569.728.729.357.181.823.181 1.757.181z"
                              stroke="#475467"
                              strokeWidth="1.667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click from triggering
                            openDialog(item);
                          }}
                          className="p-2"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                              stroke="#475467"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </Td>
                  </Tr>
                ))}
            </TBody>
          </Table>
        </div>
      </div>

      {/* dialog to delete site confirmation */}
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            Are you sure you want to delete this Site?
          </p>
          <p className="text-gray-600 text-center">
            Deleting a site will remove all associated data. Please confirm that
            you want to proceed with this action as it cannot be undone.
          </p>
        </div>

        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight disabled={buttonDisabled} className="mr-1.5" onClick={onDialogClose}>
            Cancel
          </BtnLight>
          <BtnPrimary disabled={buttonDisabled} className="ml-1.5" onClick={handleDeleteSite}>
            {buttonDisabled ? <ThreeDotLoader /> : "Delete"}
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={IsOpen}
        onClose={onSiteDialogClose}
        onRequestClose={onSiteDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.126 14.833a4.002 4.002 0 0 0 7.748 0M11.018 3.597 4.235 8.872c-.453.353-.68.529-.843.75a2 2 0 0 0-.318.65C3 10.536 3 10.823 3 11.398v7.235c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874c.428.218.988.218 2.108.218h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874c.218-.428.218-.988.218-2.108v-7.235c0-.575 0-.862-.074-1.126a2.001 2.001 0 0 0-.318-.65c-.163-.221-.39-.397-.843-.75l-6.783-5.275c-.351-.273-.527-.41-.72-.463a1 1 0 0 0-.523 0c-.194.053-.37.19-.721.463z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Duplicate Site
            </h5>
            <p className="text-gray-600 ">
              Creating duplicate site under the Client's profile to manage
              Inductions, Inductees, Visitors Log and more.{" "}
            </p>
          </div>
        </div>
        <DuplicateSite
          siteId={specificSiteData}
          onSiteDialogClose={onSiteDialogClose}
          GetAllSitesOfClient={props?.GetAllSitesOfClient}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
        />
      </Dialog>


      <Dialog
        isOpen={openDataExistDialog}
        onClose={onCloseExistDailog}
        onRequestClose={onCloseExistDailog}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" width="48" height="48" rx="24" fill="#FEE4E2" />
              <path
                d="M21.5 21L27.5 27M27.5 21L21.5 27M20.3 33H28.7C30.3802 33 31.2202 33 31.862 32.673C32.4265 32.3854 32.8854 31.9265 33.173 31.362C33.5 30.7202 33.5 29.8802 33.5 28.2V19.8C33.5 18.1198 33.5 17.2798 33.173 16.638C32.8854 16.0735 32.4265 15.6146 31.862 15.327C31.2202 15 30.3802 15 28.7 15H20.3C18.6198 15 17.7798 15 17.138 15.327C16.5735 15.6146 16.1146 16.0735 15.827 16.638C15.5 17.2798 15.5 18.1198 15.5 19.8V28.2C15.5 29.8802 15.5 30.7202 15.827 31.362C16.1146 31.9265 16.5735 32.3854 17.138 32.673C17.7798 33 18.6198 33 20.3 33Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Delete site inductions first to remove site!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onCloseExistDailog}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default SitesList;
