import axios from "axios";
import * as actionType from "../../constant/actionType";

export const GetAllClientArticles = () => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.helpScout_url}/collections/${actionType.client_collectionId}/articles`,
      auth: {
        username: `${actionType.helpScout_api_key}`,
        password: `${actionType.helpScout_password}`
      }
    });
    return response.data
  } catch (error) {
    console.error("Error fetching client articles:", error);
  }
};

export const GetSearchClientArticles = (searchQuery) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.helpScout_url}/search/articles?query=${encodeURIComponent(searchQuery)}&collectionId=${actionType.client_collectionId}`,
      auth: {
        username: `${actionType.helpScout_api_key}`,
        password: `${actionType.helpScout_password}`
      }
    });
    return response.data
  } catch (error) {
    console.error("Error fetching client articles:", error);
  }

}