import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../../../../../common/element/Table";
import Button from "../../../../../common/element/Buttons";
import Dialog from "../../../../../common/element/Dialog";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Label from "../../../../../common/element/Label";
import Input from "../../../../../common/element/Input";
import Scrollbar from "../../../../../common/element/ScrollBar";
import ThreeDotBouncing from "../../../../../common/element/PageLoader";
import Checkbox from "../../../../../common/element/Checkbox/Checkbox";
import * as userActions from "../../../../../actions/index";
import { notify } from "react-notify-toast";
import Validations from "../../../../../common/404/Validations";
import settingIcon from "../../../../../assets/icons-svg/setting.svg";
import uploadIcon from "../../../../../assets/icons-svg/upload.svg";
import moment from "moment";
import imagePlus from "../../../../../assets/icons-svg/image-plus.svg";
import AvatarEditor from "react-avatar-image-cropper";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import ClientInput from "../../../../../common/element/Input/clientInput";
import ThreeDotLoader from "../../../../../common/element/ButtonLoader/ThreeDotLoader";
const { Tr, Th, Td, THead, TBody } = Table;

const UserList = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  let role_assignment_id;
  if (loginData) {
    role_assignment_id = loginData?.data?.role_assignment_id;
  }
  let permission_info;
  if (loginData.data != null) {
    permission_info = JSON.parse(localStorage.getItem("permissionData"));
  }
  const MAX_WIDTH = 800;
  const MAX_HEIGHT = 400;
  let payloadData;
  //  props?.permissionRole
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [dialogUserUpdate, setDialogUserUpdate] = useState(false);
  const [removeInviteOpen, setRemoveInviteIsOpen] = useState();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogIsOpenReInvite, setIsOpenReInvite] = useState(false);
  const [notificationFullName, setNotificationFullName] = useState("")
  const [dialogDeleteOpen, setDeleteOpen] = useState(false)
  const [editDialogIsOpen, setEditIsOpen] = useState(false);
  const [userData, setUserData] = useState(props?.userData);
  const [firstname, setFirstname] = useState();
  // const [isViewTypeAvailable, setIsViewTypeAvailable] = useState(false);
  // const [getApiObjectData, setGetApiObjectData] = useState({})
  const [removeUserMsg, setRemoveUserMsg] = useState({ message: "" })
  const [individualId, setIndividualId] = useState();
  const [lastname, setLastname] = useState();
  const [jobTitle, setJobTitle] = useState();
  const [email, setEmail] = useState();
  const [photo, setPhoto] = useState();
  const [urlImage, setUrlImage] = useState();
  const [function_id, setfunction_id] = useState();
  // const [role, setRole] = useState();
  const [userRole, setUserRole] = useState();
  const [selectedRole, setSelectedRole] = useState(null);
  const [roleAssignment, setRoleAssignment] = useState();
  const [role_id, setRoleId] = useState();
  const [userId, setUserId] = useState();
  const [emailError, setEmailError] = useState("");
  const [firstnameError, setFirstNameError] = useState("");
  const [lastnameError, setLastNameError] = useState("");
  const [reInviteCall, setReinviteCall] = useState(false);
  const [inviteId, setInviteId] = useState("");
  const [errors, setErrors] = useState({ removeUserMsgError: "", });
  const [reinviteData, setReInviteData] = useState({
    invite_id: "",
    individual_id: "",
    fullName: ""
  });
  const [dialogReInviteOpen, setReInviteIsOpen] = useState(false);

  const allRoles = useSelector((state) => state.allRole.allRole);
  const AllFunctions = useSelector((state) => state.allFunctions);

  useEffect(() => {
    if (AllFunctions?.allFunction) {
      let functionType = AllFunctions?.allFunction?.filter(
        (item) => item?.function_name === "conserve-team"
      );
      setfunction_id(functionType[0]?.function_id);
    }
  }, []);

  // Function to open the remove user confirmation dialog
  const openDialog = (individual_id) => {
    GetUserDetailsById(individual_id);
    setErrors(prevErrors => ({ ...prevErrors, removeUserMsgError: "" }));
    setRemoveUserMsg({ message: "" });
    setIsOpen(true);
    
  };

  const onUpdateDialogClose = () => {
    onEditDialogOk();
    onEditDialogClose()
    setDialogUserUpdate(false)
  }
  // Function to close the remove user confirmation dialog
  const onDialogClose = () => {
    if (!buttonDisabled) {
      setIsOpen(false);
      // setErrors(prevErrors => ({ ...prevErrors, removeUserMsgError: "" }));
      setRemoveUserMsg({ message: "" });
    }
  };
  // Remove User 
  const onDeleteDialogClose = () => {
    if (dialogIsOpen == true) {
      setDeleteOpen(false)
      onDialogOk();
      onDialogClose()
    }
    if (removeInviteOpen == true) {
      onRemoveInvitationDialogClose();
      setDeleteOpen(false)
    }
  }
  //Function call when user removed successful
  const openDeleteDialogUser = () => {
    setDeleteOpen(true)
  }
  // Function to handle the user confirmation to remove the user
  const onDialogOk = () => {
    setIsOpen(false);
  };

  // Function to open the edit user details dialog
  const openEditDialog = (individual_id) => {
    GetUserDetailsById(individual_id);
    setEditIsOpen(true);
  };

  // Function to close the edit user details dialog
  const onEditDialogClose = () => {
    if (!buttonDisabled) {
      setEditIsOpen(false);
      setFirstname("");
      setLastname("");
      setJobTitle("");
      setEmail("");
      setIndividualId("");
      setSelectedRole("");
      setRoleAssignment("");
      setRoleId("");
      setUserId("");
      setUrlImage("");
      setPhoto("");
    }
  };

  // Function to handle the user confirmation to save the edited user details
  const onEditDialogOk = () => {
    if (buttonDisabled == false) {
      setEditIsOpen(false);
    }
  };
  // Function to handle input changes
  const inputchangehandler = (e, identifier) => {
    if (identifier === "firstName") {
      setFirstname(e);
    }
    if (identifier === "lastName") {
      setLastname(e);
    }
    if (identifier === "email") {
      setEmail(e);
      setEmailError("");
    }
    if (identifier === "jobTitle") {
      setJobTitle(e);
    }

  };
  // Function for set remove user message
  const inputChangeHandler = (e) => {
    setRemoveUserMsg({ message: e });
    // Clear the error if there's any
    // setErrors(prevErrors => ({ ...prevErrors, removeUserMsgError: "" }));
  };


  useEffect(() => {
    setUserData(props?.userData);
    // if (props?.getApiObjectData) {
    //   setGetApiObjectData(props?.getApiObjectData);
    //   setIsViewTypeAvailable(true)
    // }
  }, [props?.userData, props?.getApiObjectData]);

  // Function to get user details by individual_id
  const GetUserDetailsById = useCallback(
    async (individual_id) => {
      dispatch(userActions.GetUserDetailsById(individual_id))
        .then(
          (response) => {
            if (response?.success === true) {
              closeInvitation();
              setButtonDisabled(false)
              const arrayData = response?.data;
              setFirstname(arrayData?.first_name);
              setLastname(arrayData?.last_name);
              setJobTitle(arrayData?.user_data?.role_assign?.admin_job_title);
              setEmail(arrayData?.email);
              setIndividualId(arrayData?.individual_id);
              setSelectedRole(arrayData?.user_data?.role_assign?.role?.role_name);
              setRoleAssignment(
                arrayData?.user_data?.role_assign?.role_assignment_id
              );
              setRoleId(arrayData?.user_data?.role_assign?.role?.role_id);
              setUserId(arrayData?.user_data?.user_id);
              setUrlImage(arrayData?.avatar);
              setPhoto(arrayData?.avatar);
              if (!arrayData.user_data) {
                setReinviteCall(true);
              }
            } else if (response?.status === 401) {
              handleUnauthorizedAccess()
            } else {
            }
          }
        ).catch((error) => {
          console.error('Error :', error)
        }).finally(() => {
          setButtonDisabled(false);
        });
    },
    [dispatch]
  );

  const handlecheckbox = (option) => {
    if (selectedRole === option) {
      setSelectedRole(null);
      setUserRole("");
    } else {
      setSelectedRole(option);
      const filteredData = allRoles.filter((item) => item?.role_name === option);
      setUserRole(filteredData);
    }
  };

  const ResetPasswordHandler = (e) => {
    e.preventDefault();
    const payload = {
      email: email,
      user_id: userId,
      first_name: firstname,
      last_name: lastname,
    };
    setButtonDisabled(true)
    props?.onUserResetPasswordEmail(payload).then((response) => {
      if (response?.success === true) {
        onDialogOk();
        setTimeout(() => {
          setButtonDisabled(false);
        }, 500)
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
      }
    }).catch((error) => {
      console.error('Error:', error);
    })
      .finally(() => {
        setTimeout(() => {
          setButtonDisabled(false);
        }, 500)
      });
  };

  const RemoveUserHandler = (e) => {
    e.preventDefault();
    setButtonDisabled(true)
    const payload = {
      user_id: userId,
      individual_id: individualId,
      role_assignment_id: roleAssignment,
      message: removeUserMsg.message ? removeUserMsg.message : "",
    };
    props?.onRemoveUser(payload)
      .then((response) => {
        if (response?.success === true) {
          props?.GetAllConserveTeamAndInvites();
          setButtonDisabled(false);
          openDeleteDialogUser()
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        }
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        setButtonDisabled(false);
      });

  };

  const onDropHandler = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileChange(files);
  };

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      inputChangeHandlerFile(file);
    }
  };

  const inputChangeHandlerFile = (file) => {
    // Check the file extension
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/svg+xml" ||
        fileType.startsWith("image/png") ||
        fileType.startsWith("image/jpeg") ||
        fileType.startsWith("image/gif")
      ) {
        // Check the file size (max. 800x400px)
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          if (img.width <= 800 && img.height <= 400) {
            setPhoto(file);
            setUrlImage(URL.createObjectURL(file));
          } else {
            // Handle size validation error
            notify.show(
              "Image dimensions must be within 800x400 pixels.",
              "error"
            );
          }
        };
      } else {
        // Handle file type validation error
        notify.show(
          "Invalid file type. Only SVG, PNG, JPG, or GIF files are allowed.",
          "error"
        );
      }
    }
  };

  const submithandler = (e) => {
    e.preventDefault();

    //Validate Email
    const emailValidation = Validations({
      value: email,
      Validations: { required: true, email: true },
    });
    if (!emailValidation.valid) {
      setEmailError(emailValidation.error_msg);
    }
    const firstnameValidation = Validations({
      value: firstname,
      Validations: { required: true },
    });
    const lastnameValidation = Validations({
      value: lastname,
      Validations: { required: true },
    });
    if (!firstnameValidation.valid) {
      setFirstNameError(firstnameValidation.error_msg);
    }
    if (!lastnameValidation.valid) {
      setLastNameError(lastnameValidation.error_msg);
    }
    let roleId = userRole ? userRole[0]?.role_id : role_id;

    if (
      emailValidation.valid === true &&
      firstnameValidation.valid === true &&
      lastnameValidation.valid === true
    ) {
      setButtonDisabled(true)
      const payload = {
        first_name: firstname,
        last_name: lastname,
        email: email,
        individual_id: individualId,
        role_assignment_id: roleAssignment,
        role_id: roleId,
        avatar: photo,
        admin_job_title: jobTitle,
      };
      props?.onUpdateUser(payload)
        .then((response) => {
          if (response?.success === true) {
            setButtonDisabled(false)
            setDialogUserUpdate(true)
            props?.GetAllConserveTeamAndInvites();
            setEmailError("");
            setFirstNameError("");
            setLastNameError("");
            setPhoto("");


          } else if (response?.status === 401) {
            handleUnauthorizedAccess()

          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setButtonDisabled(false);
        });
    }
  };

  const clearReInviteData = () => {
    setReInviteData({
      invite_id: "",
      individual_id: "",
      fullName: "",
    });
  };

  // Function to open the "Re-Invite Invitation" dialog
  const openDialogReInvite = () => {
    setIsOpenReInvite(true);
  };
  // Function to close the "Re-Invite Invitation" confirmation dialog
  const onDialogCloseReInvite = () => {
    setIsOpenReInvite(false);
  };

  // to reInvite user
  const ReInviteUser = async () => {
    await GetUserDetailsById(reinviteData?.individual_id);
    setButtonDisabled(true)
  };

  useEffect(() => {
    if (reInviteCall) {
      if (email) {
        ResendInvitation();
      }
    }
  }, [reInviteCall]);

  // Function to open the "Delete User" dialog
  const openDeleteDialog = (e) => {
    // setInviteId(e.invite_id)
    setReInviteData({
      ...reinviteData,
      invite_id: e.invite_id,
      individual_id: e.individual_id,
      fullName: `${e?.first_name} ${e?.last_name}`,
    });
    setReInviteIsOpen(true);
  };
  // Function to close the "Delete User" confirmation dialog
  const closeInvitation = () => {
    if (buttonDisabled == false) {
      setReInviteIsOpen(false);
    }
  };

  const ResendInvitation = () => {
    if (email && reinviteData.invite_id) {
      const payload = {
        invite_id: reinviteData.invite_id,
        email: email,
        fullName: reinviteData?.fullName
      };
      props?.onReinviteAdminUser(payload).then((response) => {
        if (response?.success === true) {
          openDialogReInvite();
          // setInviteId('')
          clearReInviteData();
          setReinviteCall(false);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        } else {
          // setInviteId('')
          clearReInviteData();
          setReinviteCall(false);
        }
      });
    }
  };

  // Function to open the remove user confirmation dialog
  const openRemoveInvitationDialog = (e) => {
    setInviteId(e.invite_id);
    setIndividualId(e.individual_id);
    // set full name for notification
    setNotificationFullName(e?.first_name + " " + e?.last_name);
    setRemoveInviteIsOpen(true);
  };

  // Function to close the remove user confirmation dialog
  const onRemoveInvitationDialogClose = () => {
    if (!buttonDisabled) {
      setRemoveInviteIsOpen(false);
    }
  };

  // to remove user ivite
  const RemoveInvitation = async (e) => {
    setButtonDisabled(true)
    const payload = {
      invite_id: inviteId,
      individual_id: individualId,
      fullName: notificationFullName,
    };
    props?.onRemoveAdminInviteUser(payload)
      .then((response) => {
        if (response?.success === true) {
          setButtonDisabled(false)
          setInviteId("");
          setIndividualId("");
          openDeleteDialogUser()
          setReinviteCall(false);
          setTimeout(() => {
            props?.GetAllConserveTeamAndInvites();
          }, 100);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
          setButtonDisabled(false)
        } else {
          setReinviteCall(false);
          setButtonDisabled(false)
        }
      })
      .catch((error) => {
        setButtonDisabled(false)
      });
  };

  return (
    <React.Fragment>
      <div className=" border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className="w-72">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("first_name")}
                >
                  User
                  <svg
                    className={`transform ${props?.filtersort === "first_name" &&
                      props?.filterorder === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-56">Access</Th>
              {userData && userData?.[0]?.invite_status && (
                <Th className="w-48">Status</Th>
              )}

              <Th className="w-48">
                {" "}
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("last_login")}
                >
                  Last active
                  <svg
                    className={`transform ${props?.filtersort === "last_login" &&
                      props?.filterorder === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-64">Invited by</Th>
              <Th className="w-20"></Th>
            </Tr>
          </THead>
          <TBody>
            {props?.pageLoader ==false && userData && userData?.map((item) => {
              return (
                <>
                  {/*
        support team
        client service team
        super admin
        */}
                  <Tr>
                    <Td>{`${item?.first_name} ${item?.last_name}`}</Td>
                    <Td>{item?.role_name ? item?.role_name : "-"}</Td>
                    {item?.invite_status && (
                      <Td>
                        <span
                          className={
                            item?.invite_status === "Active"
                              ? "badge bg-success-50 text-success-700 py-0.5 px-2 rounded-lg font-medium"
                              : " badge bg-gray-100 text-gray-700 py-0.5 px-2 rounded-lg font-medium"
                          }
                        >
                          {item?.invite_status ? item?.invite_status : "-"}
                        </span>
                      </Td>
                    )
                    }

                    <Td>
                      {(() => {
                        if (item?.invite_status === "Active" || item?.last_login == null || item?.last_login) {
                          return item?.last_login ? item?.last_login : "Not login yet";
                        } else {
                          if (item?.invite_status === "Invited") {
                            return "In-process";
                          }
                          else {
                            return "Not login yet";
                          }
                        }
                      })()}
                    </Td>
                    <Td>{item?.invited_by ? item?.invited_by : "-"}</Td>
                    {permission_info ? (
                      <Td>
                        {item?.invite_status === "Active" || item?.edit_user ? (
                          <span className="flex gap-3">
                            {/* Super Admin: Show edit and delete options for all users */}
                            {permission_info?.role_name === "super admin" && (
                              <>
                                <Button onClick={() => openDialog(item?.individual_id)}>
                                  {/* this is for when user active and i want to remove form conserve */}
                                  <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.333 5.693v-.667c0-.933 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182H9.333c-.933 0-1.4 0-1.756.182-.314.16-.569.415-.729.728-.181.357-.181.824-.181 1.757v.667m1.666 4.583v4.167m3.334-4.167v4.167M2.5 5.693h15m-1.667 0v9.333c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 0 1-1.093 1.093c-.534.272-1.235.272-2.635.272H8.167c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.093-1.093c-.272-.535-.272-1.235-.272-2.635V5.693"
                                      stroke="#667085"
                                      strokeWidth="1.667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </Button>
                                <Button onClick={() => openEditDialog(item?.individual_id)}>
                                  {/* this is edit user details option  */}
                                  <svg
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                                      stroke="#667085"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </Button>
                              </>
                            )}
                            {/* Client Service Team: Show edit and delete options only for Support Team users */}
                            {permission_info?.role_name === "client service team" &&
                              item?.role_name === "Customer Support" && (
                                <>
                                  <Button onClick={() => openEditDialog(item?.individual_id)}>

                                    {/* this is edit user details option  */}
                                    <svg
                                      width="21"
                                      height="20"
                                      viewBox="0 0 21 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                                        stroke="#667085"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </Button>
                                  <Button onClick={() => openRemoveInvitationDialog(item)}>
                                    {/* this is for when user active and i want to remove form conserve */}
                                    <svg
                                      width="20"
                                      height="21"
                                      viewBox="0 0 20 21"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M13.333 5.693v-.667c0-.933 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182H9.333c-.933 0-1.4 0-1.756.182-.314.16-.569.415-.729.728-.181.357-.181.824-.181 1.757v.667m1.666 4.583v4.167m3.334-4.167v4.167M2.5 5.693h15m-1.667 0v9.333c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 0 1-1.093 1.093c-.534.272-1.235.272-2.635.272H8.167c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.093-1.093c-.272-.535-.272-1.235-.272-2.635V5.693"
                                        stroke="#667085"
                                        strokeWidth="1.667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </Button>
                                </>
                              )}
                          </span>
                        ) : (
                          <span className="flex gap-3">
                            {/* Super Admin: Can re-invite users and delete invited users */}
                            {(permission_info?.role_name === "super admin" || permission_info?.role_name === "client service team") && (
                              <>
                                <Button onClick={() => openRemoveInvitationDialog(item)}>
                                  {/* This is for delete re-invite users  super admin  and  client service team */}
                                  <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.333 5.693v-.667c0-.933 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182H9.333c-.933 0-1.4 0-1.756.182-.314.16-.569.415-.729.728-.181.357-.181.824-.181 1.757v.667m1.666 4.583v4.167m3.334-4.167v4.167M2.5 5.693h15m-1.667 0v9.333c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 0 1-1.093 1.093c-.534.272-1.235.272-2.635.272H8.167c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.093-1.093c-.272-.535-.272-1.235-.272-2.635V5.693"
                                      stroke="#667085"
                                      strokeWidth="1.667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </Button>
                                <Button onClick={() => openDeleteDialog(item)}>
                                  {/* This is for re-invite users only super admin and client service team */}
                                  <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.236.816.428L9.2 12.144c.046.018.068.026.088.04.018.012.036.03.052.047.014.02.022.042.04.088l.152.39Z"
                                      stroke="#667085"
                                      strokeWidth="1.667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </Button>
                              </>
                            )}
                          </span>
                        )}
                      </Td>
                    ) : (
                      <Td>-</Td>
                    )}


                  </Tr>
                </>

              );
            })}
          </TBody>
        </Table>
        
      </div>
      {/* --------- Delete User Confirmation --------  */}
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        width="568px"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            You are about to remove this user.
          </p>
          <p className="text-gray-600 text-center mb-5">
            This action cannot be undone. The user will lose all access to the app.
          </p>
          <Label className="text-center block mb-0.5">Provide the user with an explanation for the removal of their account (optional)</Label>
          <div className="w-440 m-auto table">
            <Input
              className="h-9 w-full"
              placeholder="Type here"
              value={removeUserMsg.message ? removeUserMsg.message : ""}
              name="message"
              inputchangehandler={(e) => inputChangeHandler(e, "message")} // Assuming onChange is the correct event handler
            />

            {/* {errors.removeUserMsgError && (
              <p className="text-xs text-red-600 mt-2">{errors.removeUserMsgError}</p>
            )} */}
          </div>
        </div>
        <div className="pt-8 mt-8 flex justify-between pb-6 px-6  gap-3 border-t border-gray-200">
          <BtnLight
            className="customAlert"
            variant="plain"
            onClick={onDialogClose}
            disabled={buttonDisabled}
          >
            Cancel
          </BtnLight>
          <Button
            disabled={buttonDisabled}
            className="whiteBtnShadow bg-error-600 text-white btn border border-gray-100 block w-full rounded-lg h-10 0 text-sm font-semibold hover:bg-error-700 focus:bg-error-700  duration-100 secondary-btn customAlert" variant="solid"
            onClick={RemoveUserHandler}>
            {buttonDisabled ? <ThreeDotLoader /> : 'Remove user'}
          </Button>
        </div>
      </Dialog>
      {/* Edit user */}
      <Dialog
        isOpen={editDialogIsOpen}
        onClose={onEditDialogClose}
        onRequestClose={onEditDialogClose}
        width={900}
      >
        <div className="flex border-b mb-2 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={settingIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Settings
            </h5>
          </div>
        </div>
        <div className="h-595">
          <Scrollbar>
            <div className="px-8 pt-3">
              <div className="flex mb-5">
                <Label className="w-52 font-medium">Full name*</Label>
                <div className="flex items-center flex-1 gap-2">
                  <Input
                    placeholder="First name"
                    className="h-9 flex-1"
                    value={firstname}
                    inputchangehandler={(e) =>
                      inputchangehandler(e, "firstName")
                    }
                  />
                  {firstnameError && (
                    <div className="text-red-500 text-sm mt-1">
                      {firstnameError}
                    </div>
                  )}
                  <Input
                    placeholder="Last name"
                    className="h-9 flex-1"
                    value={lastname}
                    inputchangehandler={(e) =>
                      inputchangehandler(e, "lastName")
                    }
                  />
                  {lastnameError && (
                    <div className="text-red-500 text-sm mt-1">
                      {lastnameError}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex mb-5">
                <Label className="w-52 font-medium">Job title</Label>
                <Input
                  placeholder="Job title"
                  className="h-9 flex-1"
                  value={jobTitle}
                  inputchangehandler={(e) => inputchangehandler(e, "jobTitle")}
                />
              </div>

              <div className="flex mb-5">
                <Label className="w-52 font-medium">Email address</Label>
                <Input
                  placeholder="[email address here]"
                  className="h-9 flex-1"
                  value={email}
                  inputchangehandler={(e) => inputchangehandler(e, "email")}
                  readOnly
                />
                {emailError && (
                  <div className="text-red-500 text-sm mt-1">{emailError}</div>
                )}
              </div>
              <div className="flex mb-5">
                <Label className="w-52 font-medium">Password</Label>
                <div className="flex-1">
                  <Button
                    disabled={buttonDisabled}
                    className="text-xs hoverSvg flex items-center gap-2 font-semibold text-blue-700 hover:text-blue-900 focus:text-blue-700"
                    onClick={ResetPasswordHandler}
                  >
                    Send reset email
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m17.916 15.5-5.536-5m-4.762 0-5.535 5m-.417-9.167 6.804 4.763c.551.386.827.579 1.126.653.265.066.542.066.806 0 .3-.074.576-.267 1.127-.653l6.804-4.763M5.666 17.167h8.667c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 0 0 1.092-1.092c.273-.535.273-1.235.273-2.635V7.833c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.092c-.535-.273-1.235-.273-2.635-.273H5.666c-1.4 0-2.1 0-2.635.273a2.5 2.5 0 0 0-1.093 1.092c-.272.535-.272 1.235-.272 2.635v5.334c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.092c.535.273 1.235.273 2.635.273z"
                        stroke="#5786CC"
                        strokeWidth="1.667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                </div>
              </div>
              <div className="flex mb-5">
                <Label className="w-52 font-medium">Photo</Label>
                <div className="flex-1">
                  <div className="flex items-center flex-1">
                    <span className="w-28 h-28 bg-gray-100 rounded-full flex justify-center items-center avtar_img_round">
                      {urlImage != "null" ? (
                        <img className="w-28 h-28 rounded-full" src={urlImage} />
                      ) : (
                        <img className="w-16 h-16" src={imagePlus} alt="" />
                      )}
                    </span>
                    <div className="flex items-center justify-center  flex-1 upload-img ml-8">
                      <div className="flex items-center justify-center  flex-1 upload-img ml-8">
                        <label
                          htmlFor="dropzone-file"
                          className="flex flex-col items-center justify-center w-full border py-4 px-6 rounded-xl  border-gray-200  hover:bg-gray-25 bg-white"
                        >
                          <div
                            className="flex items-center justify-center  flex-1 upload-img ml-8"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={onDropHandler}
                          >
                            <div className="flex flex-col items-center justify-center">
                              <div className="rounded-full border-12 circle m-auto flex align-center justify-center bg-gray-200 border-gray-50 mb-3 border-6 w-10 h-10">
                                <img src={uploadIcon} alt="" className="w-4" />
                              </div>
                              <p className="mb-1  text-gray-600">
                                <span className="font-semibold text-blue-700">
                                  Click to upload
                                </span>
                                <span className="text-xs">
                                  {" "}
                                  or drag and drop
                                </span>
                              </p>
                              <p className=" text-gray-600  font-inter text-sm font-normal">
                                SVG, PNG, JPG or GIF (max. 800x400px)
                              </p>
                            </div>
                          </div>
                          <input
                            id="dropzone-file"
                            type="file"
                            accept=".svg, .png, .jpg, .jpeg, .gif, image/svg+xml, image/png, image/jpeg, image/gif"
                            className="hidden"
                            onChange={(e) => inputChangeHandlerFile(e.target.files[0])}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-5 mb-5" />
              <h4 className="text-base text-gray-900 font-semibold mb-5">
                User access*
              </h4>
              <div className="flex item-start mb-6">
                <Checkbox
                  disabled={props?.permissionRole !== "super admin"}
                  onChange={(e) => handlecheckbox("super admin")}
                  checked={selectedRole === "super admin"}
                  value="super admin"
                />
                <div className="flex flex-col">
                  <span className="font-medium text-gray-700">Super Admin</span>
                  <span className="text-xs text-gray-600">
                    Description for super admin In aliquet suscipit gravida molestie in fermentum vitae quis. Leo donec mattis congue.
                  </span>
                </div>
              </div>
              <div className="flex item-start mb-6">
                <Checkbox
                  disabled={props?.permissionRole !== "super admin"}
                  onChange={() => handlecheckbox("client service team")}
                  checked={selectedRole === "client service team"}
                  value="client service team"
                />
                <div className="flex flex-col">
                  <span className="font-medium text-gray-700">Client Services Team</span>
                  <span className="text-xs text-gray-600">
                    Description for admin In aliquet suscipit gravida molestie in fermentum vitae quis. Leo donec mattis congue.
                  </span>
                </div>
              </div>
              <div className="flex item-start mb-6">
                <Checkbox
                  disabled={props?.permissionRole !== "super admin"}
                  onChange={() => handlecheckbox("support team")}
                  checked={selectedRole === "support team"}
                  value="support team"
                />
                <div className="flex flex-col">
                  <span className="font-medium text-gray-700">Support Team</span>
                  <span className="text-xs text-gray-600">
                    Description for admin In aliquet suscipit gravida molestie in fermentum vitae quis. Leo donec mattis congue.
                  </span>
                </div>
              </div>
            </div>
          </Scrollbar>
        </div>
        <div className="flex dailog-footer justify-end">
          <BtnLight
            className="mr-2 w-auto px-4"
            variant="plain"
            disabled={buttonDisabled}
            onClick={onEditDialogClose}
          >
            Close
          </BtnLight>
          <BtnPrimary
            className="w-auto px-4"
            variant="solid"
            type="submit"
            disabled={buttonDisabled}
            onClick={submithandler}
          >
            {buttonDisabled ? <ThreeDotLoader /> : "Save changes"}
          </BtnPrimary>
        </div>
      </Dialog>
      {/*--------- Invitaion Sent Confirmation -------- */}
      <Dialog
        isOpen={dialogIsOpenReInvite}
        onClose={onDialogCloseReInvite}
        onRequestClose={onDialogCloseReInvite}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#101828"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </h4>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={() => onDialogCloseReInvite()}>Close</BtnPrimary>
        </div>
      </Dialog>

      {/* --------- Delete Invite Confirmation --------  */}
      <Dialog
        isOpen={removeInviteOpen}
        onClose={onRemoveInvitationDialogClose}
        onRequestClose={onRemoveInvitationDialogClose}
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            Are you sure you want to remove this Invitation?
          </p>
          <p className="text-gray-600 text-center">
            This action cannot be undone. The user will lose all access to the
            app.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6  gap-3">
          <BtnLight
            className="customAlert"
            variant="plain"
            disabled={buttonDisabled}
            onClick={onRemoveInvitationDialogClose}
          >
            Cancel
          </BtnLight>
          <BtnPrimary disabled={buttonDisabled}
            variant="solid" onClick={RemoveInvitation}>
            {buttonDisabled ? <ThreeDotLoader /> : "Confirm"}
          </BtnPrimary>
        </div>
      </Dialog>

      {/* User Removed Successfully */}

      <Dialog
        isOpen={dialogDeleteOpen}
        bodyOpenClassName="overflow-hidden"
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}

        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.8945 7.58691H22.8945M16.8945 21.5869V20.3869C16.8945 18.7068 16.8945 17.8667 16.5676 17.2249C16.2799 16.6605 15.821 16.2015 15.2565 15.9139C14.6148 15.5869 13.7747 15.5869 12.0945 15.5869H7.69453C6.01437 15.5869 5.17429 15.5869 4.53256 15.9139C3.96807 16.2015 3.50913 16.6605 3.22151 17.2249C2.89453 17.8667 2.89453 18.7068 2.89453 20.3869V21.5869M13.3945 8.08691C13.3945 10.0199 11.8275 11.5869 9.89453 11.5869C7.96153 11.5869 6.39453 10.0199 6.39453 8.08691C6.39453 6.15392 7.96153 4.58691 9.89453 4.58691C11.8275 4.58691 13.3945 6.15392 13.3945 8.08691Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            {dialogIsOpen == true ? "User removal successful!" : "User invitaion removal successful!"}
          </p>
        </div>
        <div className='mt-8 flex justify-between pb-6 px-6'>
          <BtnPrimary onClick={onDeleteDialogClose}>Close</BtnPrimary>
        </div>
      </Dialog>
      {/*--------- Remove Invitaion -------- */}
      <Dialog
        isOpen={dialogReInviteOpen}
        onClose={closeInvitation}
        onRequestClose={closeInvitation}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1.35156" y="0.5" width="47" height="47" rx="23.5" fill="#D2EBFF" />
              <rect x="1.35156" y="0.5" width="47" height="47" rx="23.5" stroke="#ECFDF3" />
              <path d="M23.3511 25.5001L33.8511 15.0001M23.4787 25.8281L26.1068 32.5861C26.3383 33.1815 26.4541 33.4791 26.6209 33.566C26.7655 33.6414 26.9377 33.6415 27.0824 33.5663C27.2493 33.4796 27.3654 33.1821 27.5976 32.587L34.188 15.6992C34.3976 15.1621 34.5024 14.8935 34.4451 14.7219C34.3953 14.5728 34.2783 14.4558 34.1293 14.406C33.9577 14.3487 33.6891 14.4535 33.1519 14.6632L16.2641 21.2535C15.6691 21.4857 15.3715 21.6018 15.2848 21.7687C15.2097 21.9134 15.2098 22.0857 15.2851 22.2303C15.372 22.3971 15.6697 22.5128 16.265 22.7444L23.023 25.3725C23.1439 25.4195 23.2043 25.443 23.2552 25.4793C23.3003 25.5114 23.3397 25.5509 23.3719 25.596C23.4082 25.6468 23.4317 25.7073 23.4787 25.8281Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
            Are you sure you want to re-invite this user?
          </h4>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight className="mr-1.5" disabled={buttonDisabled} onClick={() => closeInvitation()}>
            Cancel
          </BtnLight>
          <BtnPrimary
            onClick={() => ReInviteUser()}
            disabled={buttonDisabled}
            className="ml-1.5"
          >
            {buttonDisabled ? <ThreeDotLoader /> : "Yes"}
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogUserUpdate}
        bodyOpenClassName="overflow-hidden"
        onClose={onUpdateDialogClose}
        onRequestClose={onUpdateDialogClose}

        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            User details updated successfully!
          </p>
        </div>
        <div className='mt-8 flex justify-between pb-6 px-6'>
          <BtnPrimary onClick={onUpdateDialogClose}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment >
  );
};

function mapStateToProps(state) {
  return {
    loader: userActions.UserResetPasswordEmail.loader,
  };
}
const mapDispatchToProps = {
  onUserResetPasswordEmail: userActions.UserResetPasswordEmail,
  onUpdateUser: userActions.UpdateUser,
  onRemoveUser: userActions.RemoveUser,
  onReinviteAdminUser: userActions.ReinviteAdminUser,
  onRemoveAdminInviteUser: userActions.RemoveAdminInviteUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
