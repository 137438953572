import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    Title,
    Plugin,
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const PieChart = () => {

    const data = {
        labels: [
            // `Provider ${clientData?.activeCount || 0}`,
            // `Worker ${clientData?.inactiveCount || 0}`,
            `[Provider Type] ${500 || 0}`,
            `[Provider Type] ${300 || 0}`,
            `[Provider Type] ${200 || 0}`,
            `[Provider Type] ${100 || 0}`,
        ],
        datasets: [{
            label: 'Status',
            data: [
                // Number(clientData?.activeCount || 0),
                // Number(clientData?.inactiveCount || 0)
                Number(500 || 0),
                Number(300 || 0),
                Number(200 || 0),
                Number(100 || 0),
            ],
            backgroundColor: [
                '#D2EBFF',
                '#A6D3FA',
                '#5AB8ED',
                '#0E4E9A',

               
            ],
            hoverOffset: 4,
            borderWidth: 0
        }]
    };

    const options = {
        responsive: true,

        plugins: {
            legend: {
                // display: true,
                position: 'right',

                labels: {
                    boxWidth: 10,
                    padding: 20,
                    borderWidth: 1,
                    usePointStyle: true, // Add this line to make legend circles
                },

            }
        },
        cutout: '60%',  // To make it a doughnut chart

    };


    // Custom plugin to draw text in the center
    const centerTextPlugin = {
        id: 'centerText',
        beforeDraw: (chart) => {
            const { ctx, data, chartArea: { width, height } } = chart;
            ctx.save();
            // Convert data to numbers and calculate the total
            const total = data.datasets[0].data.reduce((acc, value) => acc + Number(value), 0);

            // Text settings for the total number
            ctx.font = 'bold 20px Arial';
            ctx.fillStyle = '#1D2939';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            // Draw the total number
            ctx.fillText(`${total}`, width / 2, height / 2 - 10);

            // Text settings for "Total Checklists"
            ctx.font = '13px Arial'; // Regular font with 10px size
            ctx.fillText('Total Providers', width / 2, height / 2 + 10);

            ctx.restore();
        }
    };

    return (


        <div className="card-body mt-3.5 flex" style={{ width: '100%', height: '400px' }} >
            <Doughnut data={data} options={options} plugins={[centerTextPlugin]} />

        </div>
    );
};

export default PieChart;
