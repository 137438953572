import React, { useState, useCallback, useEffect } from "react";
import Select from "../../../../../../../common/element/Select";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import { GetAllWorkersOfProviderByClient } from "../../../../../../../actions/client-portal/AllSiteApi";
import WorkersList from "./WorkerList";
import { debounce } from "../../../../../../../common/snippet/Debouncing";
import { Pagination } from "../../../../../../../common/pagination";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
const Workers = (props) => {

  const searchOptions = [
    {
      value: "Steph De La Vega steph.delavega@speckledigital.com",
      label: "Steph De La Vega steph.delavega@speckledigital.com",
    },
  ];
  let client_org_id;
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (loginData) {
    client_org_id = loginData?.organisation_id
  } 
  const dispatch = useDispatch();
  const [filterpage, setfilterpage] = useState(1);
  const [filterlimit, setfilterlimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [filterorder, setFilterOrder] = useState("desc");
  const [countData, setCountData] = useState(0);
  const [filtersort, setFiltersort] = useState("first_name");
  const [searchTerm, setsearchTerm] = useState("");
  const [workerData, setWorkerData] = useState(null)
  const [allData, setAllData] = useState({
    page: filterpage,
    limit: filterlimit,
    sort: filtersort,
    order: filterorder,
    search: searchTerm,
    provider_org_id: props?.organisation_id ? props?.organisation_id : "",
    client_org_id: client_org_id
  })

  const OnGetAllWorkersOfProvider = useCallback(async () => {
    if ((allData?.provider_org_id || props?.organisation_id)) {
      try {
        const response = await dispatch(GetAllWorkersOfProviderByClient(allData))
        if (response.success) {
          const arrayData = response.data.rows;
          setWorkerData(arrayData);
          setCountData(response.data.count)
          setTotalPages(Math.ceil(response.data.count / filterlimit));

        } else if (response.status === 401) {
          // handleUnauthorizedAccess();
        } else {
          // Handle other errors
        }
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }

    }
  }, [dispatch, filterpage, filterlimit, filterorder, filtersort, allData?.search,
    searchTerm, props?.organisation_id, allData])

  useEffect(() => {
    OnGetAllWorkersOfProvider()
  }, [filterorder, setAllData, allData])

  const handleSeachChange = (e) => {
    setsearchTerm(e.target.value)
    setAllData((prevalue) => {
      return { ...prevalue, ["search"]: e.target.value.trim() }
    })
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);
  // To handle page change
  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
    OnGetAllWorkersOfProvider();
  };
  // to handle page sorting
  const handleSort = () => {
    setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    setAllData((prevalue) => {
      return { ...prevalue, ["order"]: allData.order == "asc" ? "desc" : "asc" }
    })
  };

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5">
        <div className="flex gap-2">


          <div className="flex">
            <div className="w-80 h-10">
              <div className="input-wrapper">
                <div className="input-suffix-start left-3.5">
                  <svg className="h-5" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="m17.5 17.658-2.917-2.917m2.084-5a7.083 7.083 0 1 1-14.167 0 7.083 7.083 0 0 1 14.167 0z" stroke="#667085" strokeWidth="1.667" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>

                </div>
                <input
                  type="text"
                  name={"search"}
                  className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Search"
                  onChange={(e) => inputchangeSearch(e)}
                />

              </div>
            </div>

          </div>

          <BtnLight className="w-9 h-9 flex items-center justify-center">
            <svg
              width="21"
              height="20"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.712 8h12m0 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0zm-6 8h12m-12 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                stroke="#101828"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </BtnLight>
        </div>
      </div>
      <WorkersList
        handleSort={handleSort}
        workerData={workerData}
        filterorder={filterorder}
        trading_name={props?.trading_name}
        providerData={props?.organisation_id}
        filtersort={filtersort}
      />
      <div className="flex justify-between mt-3">
        <Pagination totalCount={countData} pageNumber={filterpage} displayPage={workerData?.length} UpdatePageLimit={setfilterlimit} UpdatePage={setfilterpage} />

        {countData > 0 && <ReactPaginate
          className="flex justify-content-end p-2 align-items-center pagination-wrap"
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousLinkClassName={"prev-link"}
          nextLinkClassName={"page-link"}
          breakLinkClassName={"prev-link"}
          previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
          nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
          pageLinkClassName={"px-4 rounded-md"}
          activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
          disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
          renderOnZeroPageCount={null}
          forcePage={filterpage - 1}
        />}

      </div>
    </React.Fragment>
  )
}

export default Workers