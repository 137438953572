import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { useParams } from "react-router-dom";
import AuditLogList from "./AuditLogList";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import { debounce } from "../../../../../../../common/snippet/Debouncing";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import { GetAllClientContacts } from "../../../../../../../actions/client-portal/AllContactsApi";
import { Pagination } from "../../../../../../../common/pagination";
import { setBreadcrumbs } from "../../../../../../../redux/classes/breadcrumbsSlice";
import { decryptId } from "../../../../../../../common/encryptDecrypt/encryptdecrypt";
const AuditLog = (props) => {
  const { id, siteId } = useParams();
  
  const dispatch = useDispatch();
  const { ClientSpecificProfileData } = useSelector((state) => state);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [filterpage, setfilterpage] = useState(1);
  const [filterlimit, setfilterlimit] = useState(10);
  const [countData, setCountData] = useState(0);
  const [searchTerm, setsearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [filtersort, setFiltersort] = useState("first_name");
  const [filterorder, setFilterOrder] = useState("desc");
  const [DocData, setDocData] = useState("");
  let organisation_id = decryptId(id);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Clients",
          url: "/admin/client/view-all-clients",
          home: "/admin/client/dashboard",
        },
        
        {
          label: ClientSpecificProfileData?.trading_name
            ? ClientSpecificProfileData?.trading_name
            : "",
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}`,
        },
        {
          label: "Sites",
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}`,
        },
        {
          label: props?.siteData?.site_name ? props?.siteData?.site_name : "",
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}/specific-site/${encodeURIComponent(siteId)}`,
        },
        {
          label: "Audit Log",
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}/specific-site/${encodeURIComponent(siteId)}`,
        },
      ])
    );
  }, [props?.siteData]);
  const handleSeachChange = (e) => {
    setsearchTerm(e.target.value.trim());
    setfilterpage(1);
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);

  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
    GetAllClientContacts(selected + 1, filterlimit);
  };
  const handleSort = (sortType) => {
    setFiltersort(sortType);
    setFilterOrder(filterorder === "asc" ? "desc" : "asc");
  };

  const OnGetAllClientContacts = useCallback(async () => {
    const payload = {
      sort: filtersort,
      order: filterorder,
      page: filterpage,
      limit: filterlimit,
      search: searchTerm,
      organisation_id: organisation_id,
    };
    try {
      const response = await dispatch(GetAllClientContacts(payload));

      const { success, data, status } = response;

      if (success) {
        const { rows } = data;
        setCountData(response?.data?.count);
        setDocData(rows);
        setTotalPages(Math.ceil(response?.data?.count / filterlimit));
      } else if (status === 401) {
        handleUnauthorizedAccess();
      } else {
        console.error("Failed to fetch compliance checklist:", response);
        // Handle other types of errors or unsuccessful responses
      }
    } catch (error) {
      console.error("Failed to fetch compliance checklist:", error);
      // Handle unexpected errors
    }
  }, [dispatch, filtersort, filterorder, searchTerm, filterlimit, filterpage]);

  useEffect(() => {
    OnGetAllClientContacts();
  }, [filtersort, filterorder, searchTerm, filterlimit, filterpage]);
  return (
    <React.Fragment>
      <div className="flex justify-between mb-5 px-2">
        <div className="w-80 h-10">
          <div className="input-wrapper">
            <div className="input-suffix-start left-3.5">
              <img
                src="/assets/icons-svg/search-lg.svg"
                alt=""
                className="h-5"
              />
            </div>
            <input
              type="text"
              name="hs-table-search"
              className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Search"
              onChange={(e) => inputchangeSearch(e)}
            />
          </div>
        </div>
      </div>
      <AuditLogList
        handleSort={handleSort}
        filtersort={filtersort}
        filterorder={filterorder}
        allListData={DocData}
      />

      {/* <div className="flex justify-between mt-3">
                <Pagination
                    totalCount={countData}
                    pageNumber={filterpage}
                    displayPage={DocData?.length}
                    UpdatePageLimit={setfilterlimit}
                    UpdatePage={setfilterpage}
                />
                {countData > 0 && <ReactPaginate
                    className="flex justify-content-end p-2 align-items-center pagination-wrap"
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    previousLinkClassName={"prev-link"}
                    nextLinkClassName={"page-link"}
                    breakLinkClassName={"prev-link"}
                    previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
                    nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
                    pageLinkClassName={"px-4 rounded-md"}
                    activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
                    disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
                    renderOnZeroPageCount={null}
                    forcePage={filterpage - 1}
                />}
            </div> */}
    </React.Fragment>
  );
};
export default AuditLog;
