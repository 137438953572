import React, { useEffect, useState } from "react";
import SiteList from "./SiteList";
import { GetSiteAccessLog } from "../../../../../../../actions/Admin-Client/GetSiteAccessLog";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { debounce } from "../../../../../../../common/snippet/Debouncing";
import useCallbackRef from "../../../../../../../common/element/hooks/useCallbackRef";
import ClientInput from "../../../../../../../common/element/Input/clientInput";
import { Pagination } from "../../../../../../../common/pagination";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import { setBreadcrumbs } from "../../../../../../../redux/classes/breadcrumbsSlice";
import { decryptId } from "../../../../../../../common/encryptDecrypt/encryptdecrypt";
const SiteAccess = (props) => {
  const { siteId, id } = useParams();
  const siteOrgId = decryptId(siteId);

  const GetFunction = useSelector((state) => state);
  const { ClientSpecificProfileData } = useSelector((state) => state);
  const dispatch = useDispatch();
  // Filter code
  const [totalPages, setTotalPages] = useState(1);
  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    site_id: siteOrgId,
    page: 1,
    limit: 10,
    sort: "created_date",
    order: "desc",
    search: "",
  });
  const [apiCalls, setApiCalls] = useState(false);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Clients",
          url: "/admin/client/view-all-clients",
          home: "/admin/client/dashboard",
        },

        {
          label: ClientSpecificProfileData?.trading_name
            ? ClientSpecificProfileData?.trading_name
            : "",
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}`,
        },
        {
          label: "Sites",
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}`,
        },
        {
          label: props?.siteData?.site_name ? props?.siteData?.site_name : "",
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}/specific-site/${encodeURIComponent(siteId)}`,
        },
        {
          label: "Site Access Log",
          url: `/admin/client/view-all-clients/specific-client/${encodeURIComponent(id)}/specific-site/${encodeURIComponent(siteId)}`,
        },
      ])
    );
  }, [props?.siteData]);
  // All ReduxFunction
  useEffect(() => {
    if (!apiCalls) {
      dispatch(GetSiteAccessLog(getAllinvitedData));
      setApiCalls(true);
    }
    //  Setrefetch(false);
  }, [getAllinvitedData, apiCalls]);

  const handleSort = (column) => {
    setApiCalls(false);
    if (column === getAllinvitedData.sort) {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    } else {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
    }
  };

  const handlePageChange = ({ selected }) => {
    setApiCalls(false);
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(
      Math.ceil(
        GetFunction.SiteAccessLogsData?.data?.data?.count /
          getAllinvitedData.limit
      )
    );
  };

  // Search Data
  const handleSearch = (e) => {
    setApiCalls(false);
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value.trim(),
      };
    });
  };

  const inputchangehandler = useCallbackRef(debounce(handleSearch), []);
  const getPageNumber = () => {
    setTotalPages(
      Math.ceil(
        GetFunction?.SiteAccessLogsData?.data?.data?.count /
          getAllinvitedData.limit
      )
    );
  };
  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5 px-2">
        <div className="w-80 h-10">
          <div className="input-wrapper">
            <div className="input-suffix-start left-3.5">
              <img
                src="/assets/icons-svg/search-lg.svg"
                alt=""
                className="h-5"
              />
            </div>
            <ClientInput
              type="text"
              className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Search"
              inputchangehandler={inputchangehandler}
              name={"search"}
            />
          </div>
        </div>
      </div>
      <SiteList handleSort={handleSort} getAllinvitedData={getAllinvitedData} />
      <div
        className="flex justify-between mt-3"
        style={{
          alignItems: "Right",
          listStyle: "none",
          padding: 0,
        }}
      >
        <Pagination
          totalCount={GetFunction?.SiteAccessLogsData?.data?.data?.count || 0}
          pageNumber={getAllinvitedData.page}
          displayPage={
            GetFunction?.SiteAccessLogsData?.data?.data?.rows?.length || 0
          }
          UpdatePageLimit={SetgetAllinvitedData}
          allfilters={getAllinvitedData}
        />
        {GetFunction?.SiteAccessLogsData?.data?.data?.count > 0 && (
          <ReactPaginate
            className="flex justify-content-end p-2 align-items-center pagination-wrap"
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            // pageLinkClassName={'page-link'}
            previousLinkClassName={"prev-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"prev-link"}
            previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
            nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
            pageLinkClassName={"px-4 rounded-md"}
            activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
            disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default SiteAccess;
