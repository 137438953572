import React, { useCallback, useEffect, useState } from "react";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../common/element/Dialog/Dialog";
import { notify } from "react-notify-toast";
import WorkersList from "./WorkersList";
import { GetAllIndividualListForWorkerInvites } from "../../../../actions/provider-portal/worker/GetAllIndividualListForWorkerInvites";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "../../../../common/snippet/Debouncing";
import { InviteWorker } from "../../../../actions/provider-portal/worker/InviteWorker";
import ClientInput from "../../../../common/element/Input/clientInput";
import { GetProviderWorkersInvite } from "../../../../actions/provider-portal/worker/GetSpecificContractorWorkerInvites";
import ReactPaginate from "react-paginate";
import Segment from "../../../../common/element/Segment";
import Validations from "../../../../common/404/Validations";
import { GetIndividualByEmail } from "../../../../actions/public/GetIndividualByEmail";
import Dropdown from "../../../../common/element/Dropdown";
import { CSVLink } from "react-csv";
import Button from "../../../../common/element/Buttons";
import { inviteWorkerCsvView } from "../../../../actions/provider-portal/worker/inviteContractorWorkerCsvView";
import MultiSelect from "@mui/material/Select";
import Td from "../../../../common/element/Table/Td";
import TBody from "../../../../common/element/Table/TBody";
import Th from "../../../../common/element/Table/Th";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tr from "../../../../common/element/Table/Tr";
import Table from "../../../../common/element/Table";
import Scrollbar from "../../../../common/element/ScrollBar";
import THead from "../../../../common/element/Table/THead";
import Checkbox from "../../../../common/element/Checkbox";
import { reSetData } from "../../../../redux/classes/AdminContractor/inviteContractorWorkerCsvViewSlice";
import { InviteWorkerFromCsvDetails } from "../../../../actions/provider-portal/worker/InviteWorkerFromCsvDetails";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
import { useParams } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../common/pagination";
import { GetAllClientsOfProviderList } from "../../../../actions/provider-portal/GetAllClientsOfContractorList";
import MenuItem from "@mui/material/MenuItem";
import Select from "../../../../common/element/Select";
import ViewAll from "./viewAll";
import { GetAllWorkersOfProvider } from "../../../../actions/provider-portal/worker/GetAllWorkersOfProvider";
import NonCompliantList from "./NonCompliant";
import CompliantList from "./Compliant";
import Heading from "../../../../common/element/Heading";
import ScrollBar from "../../../../common/element/ScrollBar/ScrollBar";
import { decryptId } from "../../../../common/encryptDecrypt/encryptdecrypt";
import PaymentMethod from "../../../../common/payment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { GetInvoicePdfById } from "../../../../actions/provider-portal/GetInvoicePdfById";
import FullPageLoader from "../../../../common/snippet/Loader";
import Loader from "../../../../common/loader";
import ThreeDotLoader from "../../../../common/element/ButtonLoader/ThreeDotLoader";
import FullscreenLoader from "../../../../common/element/ButtonLoader/FullScrrenLoader";

// Load your publishable key
const stripePromise = loadStripe(
  "pk_test_51Q7sXyHzRJP5i60v7RiJ8rCw08dxddw1yA7fmOwWto3PlLpehOuGQlMmWebaGAokylN0ixDimgnzOXByoHohTX4800qimpMTWT"
);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const ContractorWorkers = (props) => {
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  // let user_id;
  let organisation_id;
  let trading_name;
  let current_user;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    // user_id = storedData?.userName?.user_id;
    organisation_id = storedData.organisation_id;
    trading_name = storedData?.trading_name;
    current_user = `${storedData?.userName?.first_name || ""} ${
      storedData?.userName?.last_name || ""
    }`;
  }

  const Allfunction = useSelector((state) => state?.allFunctions?.allFunction);
  const { id } = useParams();
  // const orgId = decryptId(id);

  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [dialogNextCsv, setDialogNextCsv] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dialogInvoice, setDialogInvoice] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [seatCount, setseatCount] = useState(0);
  const [addWorkerData, setAddWorkerData] = useState({
    trading_name: trading_name /* provider trading name */,
    provider_fa_id: loginUserData?.function_assignment_id,
    workersInviteData: [
      {
        individual_id: "",
        email: "",
        first_name: "",
        last_name: "",
      },
    ],
    workerClientsIds: "",
  });
  const [errorValidation, setErrorValidation] = useState({
    email: "",
    fistName: "",
    lastName: "",
    workerClientsIds: "",
    providerNameError: "",
    clientNameError: "",
    // Initialize worker-specific errors
    ...addWorkerData?.workersInviteData?.reduce((acc, _, index) => {
      acc[`firstNameError_${index}`] = "";
      acc[`lastNameError_${index}`] = "";
      acc[`emailError_${index}`] = "";
      acc[`conserveTeamError_${index}`] = "";
      acc[`alreadyWorkerError_${index}`] = "";
      return acc;
    }, {}),
  });

  const hasConserveTeamError = Object.keys(errorValidation).some(
    (key) => key.startsWith("conserveTeamError_") && errorValidation[key]
  );
  const hasAlreadyWorkerError = Object.keys(errorValidation).some(
    (key) => key.startsWith("alreadyWorkerError_") && errorValidation[key]
  );

  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {
          label: "Workers",
          url: "/provider/workers",
          home: "/provider/dashboard",
        },
      ])
    );
  }, []);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [singleSegmentValue, setSingleSegmentValue] = useState("workers");
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [checkExistingWorker, setcheckExistingWorker] = useState(false);
  const [search, setSearch] = useState("");
  const [stepForm, setStepForm] = useState(0);

  const [customOptions, setcustomOptions] = useState([]);
  const [inviteData, setinviteData] = useState([]);
  const [readOnly, setreadOnly] = useState(false);
  const [validateData, setvalidateData] = useState(false);

  const [refetch, setrefetch] = useState(false);

  const [finalCsvData, setfinalCsvData] = useState([]);
  const [checkRequired, SetcheckRequired] = useState("");
  const [searchOptions, setsearchOptions] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dialogOpenCSV, setCSV] = useState(false);
  const [allClient, setAllClient] = useState([]);
  const [updateCsvData, setUpdateCsvData] = useState(false);
  const [selectedClientType, setSelectedClientType] = useState("");
  const [personName, setPersonName] = React.useState([]);
  const [checkError, setCheckError] = React.useState(false);

  const [selectedOption, setSelectedOption] = useState("");

  const [errorvalidation, seterrorvalidation] = useState({
    email: "",
    fistName: "",
    lastName: "",
    workerClientsIds: "",
  });

  const [CsvFileData, SetCsvFileData] = useState({
    provider_type_id: "",
    invite_attach: "",
    invite_message: "",
  });

  const [dialogUnsaveOpen, setUnsaveOpen] = useState(false);
  const openDialog = () => {
    setIsOpen(true);
    setinviteData([]);
    setcheckExistingWorker(false);
  };
  const onDialogClose = (e) => {
    localStorage.setItem("workerInviteData", "");
    setStepForm(0);
    setIsOpen(false);
    setCheckError(false);
    // props.getOnDialogClose(onDialogClose);
    setAddWorkerData({
      trading_name: trading_name /* provider trading name */,
      provider_fa_id: loginUserData?.function_assignment_id,
      workersInviteData: [
        {
          individual_id: "",
          email: "",
          first_name: "",
          last_name: "",
        },
      ],
      workerClientsIds: "",
    });
    setPersonName([]);
  };
  const openAddDialog = (step, checkInvite) => {
    if (checkInvite) {
      let checkValidation = addWorkerData?.workersInviteData?.every(
        (item) => item.first_name && item.last_name && item.email
      );
      setCheckError(true);
      let validationCheck = true;

      const workerClientsIds = Validations({
        value: addWorkerData?.workerClientsIds?.[0],
        Validations: { required: true },
      });

      if (true) {
        if (!workerClientsIds.valid) {
          seterrorvalidation((prevalue) => {
            return {
              ...prevalue,
              ["workerClientsIds"]: workerClientsIds.error_msg,
            };
          });
        }

        if (workerClientsIds.valid) {
          validationCheck = true;
        } else {
          validationCheck = false;
        }
      } else {
        validationCheck = true;
      }

      if (validationCheck && checkValidation) {
        // if (inviteData.length === 0) {
        //   setvalidateData(true);
        // }
        // if (inviteData?.value) {
        //   setvalidateData(false);
        //   setcheckExistingWorker(true);
        // }
        if (!inviteData.is_conserve_team) {
          const updateObject = {
            ...addWorkerData,
            trading_name: trading_name /* provider trading name */,
            provider_org_id: storedData.organisation_id,
            current_user: current_user,
          };
          dispatch(InviteWorker(updateObject))
            .then((response) => {
              if (response.success) {
                localStorage.setItem("workerInviteData", "");
                setAddOpen(true);
                setIsOpen(false);
                setinviteData([]);
                setrefetch(!refetch);
              }
              if (response.status == 409) {
                notify.show(response.message, "error");
              } else if (response.status === 401) {
                handleUnauthorizedAccess();
              }
            })
            .catch((error) => {});
        } else {
          notify.show("This worker is not invited", "error");
        }
      } else {
        const emailValidation = Validations({
          value: inviteData.email,
          Validations: { required: true, email: true },
        });
        if (inviteData.length === 0) {
          setvalidateData(true);
          SetcheckRequired(emailValidation.error_msg);
        } else {
          if (!emailValidation.valid) {
            setvalidateData(true);
            SetcheckRequired(emailValidation.error_msg);
          } else {
            setcheckExistingWorker(true);
          }
        }
      }
    } else {
      if (step == 0) {
        let checkValidation = addWorkerData?.workersInviteData?.every(
          (item) => item.first_name && item.last_name && item.email
        );
        setCheckError(true);
        let validationCheck = true;

        const workerClientsIds = Validations({
          value: addWorkerData?.workerClientsIds?.[0],
          Validations: { required: true },
        });

        if (true) {
          if (!workerClientsIds.valid) {
            seterrorvalidation((prevalue) => {
              return {
                ...prevalue,
                ["workerClientsIds"]: workerClientsIds.error_msg,
              };
            });
          }

          if (workerClientsIds.valid) {
            validationCheck = true;
          } else {
            validationCheck = false;
          }
        } else {
          validationCheck = true;
        }

        if (validationCheck && checkValidation) {
          // if (inviteData.length === 0) {
          //   setvalidateData(true);
          // }
          // if (inviteData?.value) {
          //   setvalidateData(false);
          //   setcheckExistingWorker(true);
          // }
          if (!inviteData.is_conserve_team) {
            let data = { ...addWorkerData, clientData: personName };
            localStorage.setItem("workerInviteData", JSON.stringify(data));
            setStepForm(1);
          } else {
            notify.show("This worker is not invited", "error");
          }
        } else {
          const emailValidation = Validations({
            value: inviteData.email,
            Validations: { required: true, email: true },
          });
          if (inviteData.length === 0) {
            setvalidateData(true);
            SetcheckRequired(emailValidation.error_msg);
          } else {
            if (!emailValidation.valid) {
              setvalidateData(true);
              SetcheckRequired(emailValidation.error_msg);
            } else {
              setcheckExistingWorker(true);
            }
          }
        }
      }
    }
  };

  const onAddDialogClose = (e) => {
    setPersonName([]);
    setAddOpen(false);
    onDialogClose();
    setrefetch(!refetch);
  };
  const openUnsaveDialog = () => {
    setUnsaveOpen(true);
    setIsOpen(false);
    onDialogClose();
  };

  // Check Existing Email
  const HandleChange = (e) => {
    setSearch(e);
    setvalidateData(false);
    setcustomOptions({ value: e, label: e });
    if (e !== "" && e?.length > 3) {
      dispatch(
        GetAllIndividualListForWorkerInvites({
          search: e,
          provider_fa_id: loginUserData?.function_assignment_id,
          provider_org_id: loginUserData?.organisation_id,
        })
      ).then((response) => {
        if (response.success) {
          const FilterData = response?.data?.map((item) => {
            return {
              label: `${item.first_name}` + "-" + item.email,
              email: item.email,
              first_name: item.first_name,
              individual_id: item.individual_id,
              last_name: item.last_name,
              user_id: item.user_id,
              is_conserve_team: item.is_conserve_team,
              worker_id: item.worker_id,
            };
          });
          setsearchOptions(FilterData);
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      });
    } else {
      setsearchOptions([]);
    }
  };
  const inputchangehandler = useCallback(debounce(HandleChange), []);
  const OnSlectHandleChange = (e) => {
    if (e?.value) {
      setinviteData({
        email: e.value,
      });
    } else {
      setinviteData({
        ...e,
        organisation_id: loginUserData?.organisation_id,
        function_assignment_id: loginUserData?.function_assignment_id,
        trading_name: trading_name,
      });
    }
  };

  // const formHandle = (e) => {
  //   // Get Existing Data email
  //   seterrorvalidation((prevalue) => {
  //     return {
  //       ...prevalue,
  //       ["email"]: "",
  //     };
  //   });
  //   setinviteData((prevalue) => {
  //     return {
  //       ...prevalue,
  //       [e.target.name]: e.target.value,
  //     };
  //   });

  //   if (e.target.value !== "" && e.target.value?.length > 3) {
  //     e.target.value &&
  //       e.target.value.includes("@") &&
  //       dispatch(GetIndividualByEmail(e.target.value)).then((response) => {
  //         if (response.success) {
  //           if (response.data !== null) {
  //             if (!response.data.is_conserve_team) {
  //               setinviteData({

  //                 function_assignment_id:
  //                   loginUserData?.function_assignment_id,
  //                 ...response.data,
  //               });
  //               setreadOnly(true);
  //             } else {
  //               notify.show("This worker is not invited", "error");
  //             }
  //           } else {
  //             setinviteData((prevalue) => {
  //               return {
  //                 ...prevalue,
  //                 ["individual_id"]: "",
  //               };
  //             });
  //             setreadOnly(false);
  //             setinviteData((prevalue) => {
  //               return {
  //                 ...prevalue,
  //                 [e.target.name]: e.target.value,
  //               };
  //             });
  //           }
  //         } else if (response.status === 401) {
  //           handleUnauthorizedAccess();
  //         }
  //       });
  //   }
  // };

  // Form data Handle
  const formHandleData = (e, index, name) => {
    seterrorvalidation((prevalue) => {
      return {
        ...prevalue,
        [e.target.name == "last_name" ? "lastName" : "fistName"]: "",
      };
    });

    setAddWorkerData((prevState) => {
      const updatedWorkersInviteData = [...prevState.workersInviteData];
      if (name == "first_name") {
        updatedWorkersInviteData[index] = {
          ...updatedWorkersInviteData[index],
          first_name: e.target.value,
        };
      } else {
        updatedWorkersInviteData[index] = {
          ...updatedWorkersInviteData[index],
          last_name: e.target.value,
        };
      }

      return {
        ...prevState,
        workersInviteData: updatedWorkersInviteData,
      };
    });
    // setinviteData((prevalue) => {
    //   return {
    //     ...prevalue,
    //     [e.target.name]: e.target.value,
    //     function_assignment_id: loginUserData?.function_assignment_id,
    //     individual_id: "",
    //   };
    // });
  };

  // FilTER DATA SHOW
  const [totalPages, setTotalPages] = useState(1);
  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    function_assignment_id: loginUserData?.function_assignment_id,
    page: 1,
    limit: 10,
    sort: "created_date",
    order: "desc",
    search: "",
  });

  // All ReduxFunction
  useEffect(() => {
    if (singleSegmentValue == "workers") {
      let updateFilter = getAllinvitedData;
      delete updateFilter.statusType;
      delete updateFilter.function_assignment_id;
      updateFilter["provider_org_id"] = loginUserData?.organisation_id;
      dispatch(GetAllWorkersOfProvider(updateFilter));
    } else if (
      singleSegmentValue == "compliant" ||
      singleSegmentValue == "nonCompliant"
    ) {
      let updateFilter = getAllinvitedData;
      delete updateFilter.page;
      delete updateFilter.limit;
      delete updateFilter.function_assignment_id;
      updateFilter["statusType"] = "assigned";
      updateFilter["provider_org_id"] = loginUserData?.organisation_id;
      dispatch(GetAllWorkersOfProvider(updateFilter));
    } else {
      let updateFilter = getAllinvitedData;
      delete updateFilter.provider_org_id;
      delete updateFilter.statusType;
      updateFilter["function_assignment_id"] =
        loginUserData?.function_assignment_id;
      dispatch(GetProviderWorkersInvite(getAllinvitedData));
    }
  }, [getAllinvitedData, refetch]);
  const GetFunction = useSelector((state) => state);

  const handleSort = (column) => {
    if (column === getAllinvitedData.sort) {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    } else {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    }
  };
  const handlePageChange = ({ selected }) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(
      Math.ceil(
        GetFunction.SpecificContractorWorkerInvites?.data?.data?.count ||
          0 / getAllinvitedData.limit
      )
    );
  };

  // Search Data
  const handleSearch = (e) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value.trim(),
      };
    });
  };

  const inputchangehandlerSearch = useCallback(debounce(handleSearch), []);
  // page, limit, sort, order, search, function_assignment_id  (client function assignment id)

  const getPageNumber = () => {
    setTotalPages(
      Math.ceil(
        GetFunction.SpecificContractorWorkerInvites?.data?.data?.count /
          getAllinvitedData.limit
      )
    );
  };

  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);

  // Check Tab
  // Update Main Tabs Function

  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setSingleSegmentValue(val[0]);
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: "created_date",
          ["order"]: "desc",
        };
      });
    },
    [setSingleSegmentValue]
  );

  // Import CSV Function
  const Toggle = (
    <Button className="flex gap-1 justify-center items-center text-xs w-auto px-3 h-9 bg-blue-50 border-gray-300 rounded-lg border font-semibold hover:bg-gray-50 hover:border-gray-300 focus:bg-white focus:border-gray-300">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.67 10.417v-4.75c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.093-1.093c-.534-.272-1.235-.272-2.635-.272H7.336c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093c-.272.535-.272 1.235-.272 2.635v8.666c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h3.083m1.25-9.166h-5M8.336 12.5H6.669m6.667-6.667H6.669m5.834 10 2.5 2.5m0 0 2.5-2.5m-2.5 2.5v-5"
          stroke="#344054"
          strokeWidth="1.667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Import CSV
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m6.394 9 6 6 6-6"
          stroke="#000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Button>
  );
  useEffect(() => {
    if (GetFunction.inviteContractorWorkerCsvData?.data?.data?.success) {
      if (updateCsvData) {
        dialogCloseCSV();
        setAddOpen(true);
        dispatch(reSetData());
        setCsvData([]);
      }
    } else {
      if (
        !GetFunction.inviteContractorWorkerCsvData.loading &&
        GetFunction.inviteContractorWorkerCsvData.data?.data !== undefined
      ) {
        setCsvData(GetFunction.inviteContractorWorkerCsvData.data?.data);
        let filterdata =
          GetFunction?.inviteContractorWorkerCsvData?.data?.data.filter(
            (items) => items.is_validated == true
          );
        // Now, create a new filtered array that excludes the 'action' and 'invite_id' properties
        if (filterdata) {
          const filteredData = filterdata.map((item) => {
            // Create a new object with only the desired properties
            const { action, is_validated, invite_id, ...filteredItem } = item;
            return filteredItem;
          });

          setfinalCsvData(filteredData);
        } else {
          setfinalCsvData([]);
        }
      }
    }
  }, [GetFunction]);

  const openCSV = () => {
    setCSV(true);
  };

  const dialogCloseCSV = (e) => {
    setCSV(false);
    dispatch(reSetData());
    setCsvData([]);
  };

  // Upload CSV File
  const handleFileChange = (e) => {
    dispatch(reSetData());
    setCsvData([]);
    const file = e.target.files[0];
    let payload = {
      provider_org_id: loginUserData?.organisation_id,
      function_assignment_id: loginUserData?.function_assignment_id,
      invite_worker_csv: file,
    };
    dispatch(inviteWorkerCsvView(payload));
    openCSV();
    setUpdateCsvData(false);
  };

  // CSV Default Data Array
  const CSVData = [
    ["first_name", "last_name", "email"],
    ["brandon", "Hodges", "brandon.hodges33@conserv.com"],
    ["victor", "Carr", "victor.carr33@conserv.com"],
    ["Lillian", "Hodges", "lillian.pullman@conserv.com"],
    ["Edward", "Hodges", "edward.mills@conserv.com"],
    ["Megan", "Hodges", "megan.mcdonald4555@conserv.com"],
  ];

  // Function to handle checkbox change
  const handlecheckbox = (event, index) => {
    const isChecked = event;
    const item = csvData[index];
    if (isChecked) {
      // Add the checked item to the array
      setfinalCsvData((prevCheckedItems) => [...prevCheckedItems, item]);
    } else {
      // Remove the unchecked item from the array
      setfinalCsvData((prevCheckedItems) =>
        prevCheckedItems.filter(
          (checkedItem) => checkedItem.email !== item.email
        )
      );
    }
  };

  // Submit CSV Data
  const submitCsvData = () => {
    dispatch(
      InviteWorkerFromCsvDetails({
        trading_name: trading_name,
        function_assignment_id: loginUserData?.function_assignment_id,
        worker_invite_data: finalCsvData,
        clientOrgIds: addWorkerData.workerClientsIds,
      })
    )
      .then((response) => {
        if (response.success) {
          setCSV(false);
          dispatch(reSetData());
          setCsvData([]);
          setAddOpen(true);
          onCloseNextCsv();
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {});
    setUpdateCsvData(true);
  };

  const selectionClient = (selectedOption) => {
    setSelectedClientType(selectedOption);
  };

  const addWorker = () => {
    setAddWorkerData((prevState) => ({
      ...prevState,
      workersInviteData: [
        ...prevState.workersInviteData,
        { individual_id: "", email: "", first_name: "", last_name: "" },
      ],
    }));
  };

  const DeleteWorker = (indexToRemove) => {
    setAddWorkerData((prevState) => ({
      ...prevState,
      workersInviteData: prevState.workersInviteData.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  const handleChange = (event) => {
    seterrorvalidation((prevalue) => {
      return {
        ...prevalue,
        ["workerClientsIds"]: "",
      };
    });
    const EventValue = event.target.value;
    const filterdata = EventValue.map((item, index) => {
      return item;
    });
    setPersonName(filterdata.flat());

    setAddWorkerData((prevalue) => {
      return {
        ...prevalue,
        ["workerClientsIds"]: filterdata.flat().map((item) => item.value),
      };
    });
  };

  // const openMultiSelect = () => {

  // };
  useEffect(() => {
    if (dialogIsOpen) {
      dispatch(
        GetAllClientsOfProviderList({
          provider_fa_id: loginUserData?.function_assignment_id,
          isReqSeatCount: true,
          provider_org_id: loginUserData?.organisation_id,
        })
      )
        .then((response) => {
          if (response.success) {
            const arrayData = response?.data;
            setseatCount(arrayData?.seatCount);
            let client_data = [];
            for (let key in arrayData?.clientsRes) {
              client_data.push({
                label: arrayData?.clientsRes[key].trading_name,
                value: arrayData?.clientsRes[key].organisation_id,
                organisation_id: arrayData?.clientsRes[key].organisation_id,
                function_assignment_id:
                  arrayData?.clientsRes[key].function_assignment_id,
              });
            }
            const listData = [];
            if (client_data) {
              client_data.map((item) => {
                if (item.function_assignment_id) {
                  listData.push(item);
                }
              });
            }
            setAllClient(listData);
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {});
    }
  }, [dialogIsOpen]);
  // Custom option for the input value for email/abn for 1st page & email for 2nd page
  const customOption = { value: search, label: search };
  // Check if the inputValue exists in options, if not, add it
  const updatedOptions = searchOptions?.find(
    (option) => option.value === search
  )
    ? searchOptions
    : [...searchOptions, customOption];

  // function for Select tag for email/abn for 1st page & email for 2nd page
  const handleSearchChange = (newInputValue) => {
    setSearch(newInputValue);
    // setFromData((prevVal) => ({
    //   ...prevVal,
    //   search: newInputValue
    // }))

    // setFromError((prevError) => ({
    //   ...prevError,
    //   email: ""
    // }))
    // setRequireField((prevError) => ({
    //   ...prevError,
    //   correctFormat: ""
    // }))
  };

  // Handle the select option change for email/abn for 1st page & email for 2nd page
  // const handleSelectChange = (selectedOption,index) => {
  //   setSelectedOption(selectedOption);
  //   // .workersInviteData
  //   setAddWorkerData((prevalue)=>{
  //     return{
  //       ...prevalue,
  //     }
  //   })

  //   const handleSelectChange = (selectedOption, index) => {
  //     setAddWorkerData((prevValue) => {
  //       // Create a copy of the workersInviteData array
  //       const updatedWorkersInviteData = prevValue.workersInviteData.map((item, i) => {
  //         // Check if the current index matches the specified index
  //         if (i === index) {
  //           // Return a new object with the updated value
  //           return {
  //             ...item,
  //             // Assuming selectedOption contains the updated values (e.g., email, first_name, etc.)
  //             ...selectedOption,
  //           };
  //         }
  //         // Return the unchanged item for other indices
  //         return item;
  //       });

  //       // Return the updated state
  //       return {
  //         ...prevValue,
  //         workersInviteData: updatedWorkersInviteData,
  //       };
  //     });
  //   };

  //   // if (isEmail(selectedOption?.label)) {
  //   //   setFromData((prevVal) => ({ ...prevVal, email: selectedOption?.label }));
  //   // }
  //   // else if (isElevenDigitNumber(selectedOption?.label)) {
  //   //   setFromData((prevVal) => ({ ...prevVal, abn_nzbn: selectedOption?.label }));
  //   // }
  //   // else {
  //   //   // show message
  //   //   //Please Enter the Value in correct format
  //   // }

  //   // // setRequireField((prevError) => ({
  //   // //   ...prevError,
  //   // //   conserveEmailReq: ""
  //   // // }));
  //   // // setFromError((prevError) => ({
  //   // //   ...prevError,
  //   // //   abn_nzbn: '',
  //   // // }))
  //   // GetIndividualByEmail(selectedOption?.label); // Pass selected email to GetIndividualByEmail
  // };

  const handleSelectChange = (selectedOption, index) => {
    // setAddWorkerData((prevValue) => {
    //   // Create a copy of the workersInviteData array
    //   const updatedWorkersInviteData = prevValue.workersInviteData.map(
    //     (item, i) => {
    //       // Check if the current index matches the specified index
    //       if (i === index) {
    //         // Return a new object with the updated value
    //         return {
    //           ...item,
    //           // Assuming selectedOption contains the updated values (e.g., email, first_name, etc.)
    //           ...selectedOption,
    //           ["email"]:
    //             selectedOption == null
    //               ? ""
    //               : selectedOption.value != undefined
    //               ? selectedOption.value
    //               : selectedOption.email,
    //         };
    //       }
    //       // Return the unchanged item for other indices
    //       return item;
    //     }
    //   );

    //   // Return the updated state
    //   return {
    //     ...prevValue,
    //     workersInviteData: updatedWorkersInviteData,
    //   };
    // });
    if (selectedOption?.is_conserve_team) {
      setErrorValidation((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        updatedErrors[`conserveTeamError_${index}`] =
          "Conserve team members cannot be added via this form.";
        return updatedErrors;
      });
    } else {
      setErrorValidation((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        updatedErrors[`conserveTeamError_${index}`] = "";
        return updatedErrors;
      });
    }
    // Already Worker associated with this provider Error
    if (selectedOption?.worker_id) {
      setErrorValidation((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        updatedErrors[`alreadyWorkerError_${index}`] =
          "This worker is already associated with this provider. Please select a different worker.";
        return updatedErrors;
      });
    } else {
      setErrorValidation((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        updatedErrors[`alreadyWorkerError_${index}`] = "";
        return updatedErrors;
      });
    }

    let emailAlreadyExist = false;
    setAddWorkerData((prevValue) => {
      const emailToValidate = selectedOption?.label || selectedOption?.email;
      // Check for duplicate email
      const emailMatch = prevValue?.workersInviteData?.some(
        (ele) => ele?.email === emailToValidate
      );
      if (emailMatch) {
        setErrorValidation((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          updatedErrors[`emailError_${index}`] =
            "This email address has already been entered.";
          // updatedErrors[`conserveTeamError_${index}`] = ""
          emailAlreadyExist = true;
          return updatedErrors;
        });
        // setTimeout(() => {
        //     setErrorValidation((prevErrors) => {
        //         const updatedErrors = { ...prevErrors };
        //         updatedErrors[`emailError_${index}`] = "";
        //         emailAlreadyExist=false;
        //         return updatedErrors;
        //     });
        // }, 4000);
        return prevValue; // Return the previous state to avoid changes
      }

      // Validate email format
      if (emailToValidate && !isEmail(emailToValidate)) {
        setErrorValidation((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          updatedErrors[`emailError_${index}`] =
            "Please insert a valid email address";
          emailAlreadyExist = true;
          return updatedErrors;
        });

        // setTimeout(() => {
        //     setErrorValidation((prevErrors) => {
        //         const updatedErrors = { ...prevErrors };
        //         updatedErrors[`emailError_${index}`] = "";
        //         emailAlreadyExist=false;
        //         return updatedErrors;
        //     });
        // }, 4000);
        return prevValue; // Return the previous state to avoid changes
      }
      //  To check Correct Email or not
      function isEmail(value) {
        if (value !== undefined && value !== "") {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(value);
        }
        return false;
      }
      const updatedWorkersInviteData = prevValue.workersInviteData.map(
        (item, i) => {
          if (i === index) {
            return {
              ...item,
              ...selectedOption,
              email:
                selectedOption == null
                  ? ""
                  : selectedOption.value !== undefined
                  ? selectedOption.value
                  : selectedOption.email,
            };
          }
          return item;
        }
      );

      return {
        ...prevValue,
        workersInviteData: updatedWorkersInviteData,
      };
    });

    // Clear worker-specific errors
    if (
      selectedOption?.email &&
      selectedOption?.first_name &&
      selectedOption?.last_name
    ) {
      setErrorValidation((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        updatedErrors[`firstNameError_${index}`] = "";
        updatedErrors[`lastNameError_${index}`] = "";
        return updatedErrors;
      });
    }

    if (selectedOption?.label && emailAlreadyExist == false) {
      setErrorValidation((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        updatedErrors[`emailError_${index}`] = "";
        return updatedErrors;
      });
    }
  };

  // const handleSelectChange = (selectedOption, identifier, index) => {
  //   if (identifier === "worker") {
  //     if (selectedOption?.is_conserve_team) {
  //       setErrorValidation((prevErrors) => {
  //         const updatedErrors = { ...prevErrors };
  //         updatedErrors[`conserveTeamError_${index}`] =
  //           "Conserve team members cannot be added via this form.";
  //         return updatedErrors;
  //       });
  //     } else {
  //       setErrorValidation((prevErrors) => {
  //         const updatedErrors = { ...prevErrors };
  //         updatedErrors[`conserveTeamError_${index}`] = "";
  //         return updatedErrors;
  //       });
  //     }
  //     // Already Worker associated with this provider Error
  //     if (selectedOption?.worker_id) {
  //       setErrorValidation((prevErrors) => {
  //         const updatedErrors = { ...prevErrors };
  //         updatedErrors[`alreadyWorkerError_${index}`] =
  //           "This worker is already associated with this provider. Please select a different worker.";
  //         return updatedErrors;
  //       });
  //     } else {
  //       setErrorValidation((prevErrors) => {
  //         const updatedErrors = { ...prevErrors };
  //         updatedErrors[`alreadyWorkerError_${index}`] = "";
  //         return updatedErrors;
  //       });
  //     }

  //     let emailAlreadyExist = false;
  //     setAddWorkerData((prevValue) => {
  //       const emailToValidate = selectedOption?.label || selectedOption?.email;
  //       // Check for duplicate email
  //       const emailMatch = prevValue?.workersInviteData?.some(
  //         (ele) => ele?.email === emailToValidate
  //       );
  //       if (emailMatch) {
  //         setErrorValidation((prevErrors) => {
  //           const updatedErrors = { ...prevErrors };
  //           updatedErrors[`emailError_${index}`] =
  //             "This email address has already been entered.";
  //           // updatedErrors[`conserveTeamError_${index}`] = ""
  //           emailAlreadyExist = true;
  //           return updatedErrors;
  //         });
  //         // setTimeout(() => {
  //         //     setErrorValidation((prevErrors) => {
  //         //         const updatedErrors = { ...prevErrors };
  //         //         updatedErrors[`emailError_${index}`] = "";
  //         //         emailAlreadyExist=false;
  //         //         return updatedErrors;
  //         //     });
  //         // }, 4000);
  //         return prevValue; // Return the previous state to avoid changes
  //       }

  //       // Validate email format
  //       if (emailToValidate && !isEmail(emailToValidate)) {
  //         setErrorValidation((prevErrors) => {
  //           const updatedErrors = { ...prevErrors };
  //           updatedErrors[`emailError_${index}`] =
  //             "Please insert a valid email address";
  //           emailAlreadyExist = true;
  //           return updatedErrors;
  //         });

  //         // setTimeout(() => {
  //         //     setErrorValidation((prevErrors) => {
  //         //         const updatedErrors = { ...prevErrors };
  //         //         updatedErrors[`emailError_${index}`] = "";
  //         //         emailAlreadyExist=false;
  //         //         return updatedErrors;
  //         //     });
  //         // }, 4000);
  //         return prevValue; // Return the previous state to avoid changes
  //       }

  //       const updatedWorkersInviteData = prevValue.workersInviteData.map(
  //         (item, i) => {
  //           if (i === index) {
  //             return {
  //               ...item,
  //               ...selectedOption,
  //               email:
  //                 selectedOption == null
  //                   ? ""
  //                   : selectedOption.value !== undefined
  //                   ? selectedOption.value
  //                   : selectedOption.email,
  //             };
  //           }
  //           return item;
  //         }
  //       );

  //       return {
  //         ...prevValue,
  //         workersInviteData: updatedWorkersInviteData,
  //       };
  //     });

  //     // Clear worker-specific errors
  //     if (
  //       selectedOption?.email &&
  //       selectedOption?.first_name &&
  //       selectedOption?.last_name
  //     ) {
  //       setErrorValidation((prevErrors) => {
  //         const updatedErrors = { ...prevErrors };
  //         updatedErrors[`firstNameError_${index}`] = "";
  //         updatedErrors[`lastNameError_${index}`] = "";
  //         return updatedErrors;
  //       });
  //     }

  //     if (selectedOption?.label && emailAlreadyExist == false) {
  //       setErrorValidation((prevErrors) => {
  //         const updatedErrors = { ...prevErrors };
  //         updatedErrors[`emailError_${index}`] = "";
  //         return updatedErrors;
  //       });
  //     }
  //   }
  // };
  // useEffect(() => {
  //   if (dialogChoose || dialogNextCsv) {
  //     fetchProviderList();
  //   }
  // }, [dialogChoose, dialogNextCsv]);

  const onCloseNextCsv = () => {
    setPersonName([]);
    setDialogNextCsv(false);
    setfinalCsvData([]);
    SetCsvFileData({
      provider_type_id: "",
      invite_attach: "",
      invite_message: "",
    });
  };

  const onCloseInvoice = () => {
    setDialogInvoice(false);
    // setPersonName([]);
    // setDialogNextCsv(false);
    // setfinalCsvData([]);
    // SetCsvFileData({
    //   provider_type_id: "",
    //   invite_attach: "",
    //   invite_message: "",
    // });
  };

  // Submit CSV Data
  const submitCsvDataNext = () => {
    // open new pannel
    dialogCloseCSV();
    setDialogNextCsv(true);
  };

  let totalWorker = addWorkerData?.workersInviteData?.length || 0;

  // useEffect(async () => {
  //   // Frontend: After redirection to success_url
  //   const sessionId = new URLSearchParams(window.location.search).get(
  //     "session_id"
  //   );
  //   if (sessionId) {
  //     // Confirm payment and send the invitation
  //     const response = await fetch(
  //       `/check-payment-status?session_id=${sessionId}`
  //     );
  //     const data = await response.json();
  //     if (data.payment_status === "paid") {
  //       // Handle invitation
  //     }
  //   }
  // }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const session_id = params.get("session_id");
    const paymentId = params.get("paymentId");

    if (session_id || paymentId) {
      let inviteData = "";
      if (localStorage.getItem("workerInviteData") != "") {
        inviteData = JSON.parse(localStorage.getItem("workerInviteData"));
      }
      const interval = setInterval(() => {
        if (inviteData && inviteData) {
          setLoader(true);
          const checkout_custom_id = JSON.parse(
            localStorage.getItem("checkout_custom_id")
          );
          dispatch(
            GetInvoicePdfById({
              payment_session_id: session_id || paymentId,
              checkout_custom_id: checkout_custom_id?.checkout_custom_id,
            })
          ).then((response) => {
            if (response?.data?.is_invoice_success) {
              setLoader(false);
              setInvoiceData(response?.data);
              setDialogInvoice(true);
              setIsOpen(true);
              setPersonName(inviteData?.clientData);
              delete inviteData?.clientData;
              setAddWorkerData(inviteData);
              clearInterval(interval);
            }
          })  
          .catch((error) => {
            setLoader(false);
          });
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, []);

  // useEffect(() => {
  //   if (session_id || paymentId) {
  //     const interval = setInterval(() => {
  //       dispatch(
  //         GetProviderRegistPaymentStatus({
  //           checkout_custom_id: checkout_custom_id?.checkout_custom_id,
  //         })
  //       ).then((response) => {
  //         if (response?.data?.is_invoice_success) {
  //           let data = response?.data;
  //           setIsInvoiceSuccess(true);
  //           setSessionData({
  //             payment_checkout_id: data?.payment_checkout_id,
  //             provider_fee_type: data?.provider_fee_type,
  //             payment_gateway: data?.payment_gateway,
  //             account_payment_id:data?.account_payment_id
  //           });
  //           setInvoiceData(data?.xero_invoice_pdf);
  //           clearInterval(interval); // Stop polling when condition is met
  //         }
  //       });
  //     }, 2000); // Call API every second

  //     // Cleanup interval on component unmount
  //     return () => clearInterval(interval);
  //   }
  // }, [dispatch]);

  const downloadInvoice = () => {
    // invoiceData.xero_invoice_pdf
    if (invoiceData && invoiceData.xero_invoice_pdf) {
      const link = document.createElement("a");
      link.href = invoiceData.xero_invoice_pdf;
      link.download = "invoice.pdf"; // Set the file name
      link.target = "_blank"; // Open in new tab for security if on another domain
      link.click();
    } else {
      console.error("No PDF URL found in invoice data");
    }
  };
  if (loader) {
    return <FullscreenLoader />;
  }
  return (
    <React.Fragment>
      <Heading>Workers</Heading>
      <p className="text-gray-600 mb-5">
        Lorem ipsum dolor sit amet consectetur. Amet sagittis sem quam cras nunc
        facilisi non arcu ut. Amet elementum sed vulputate ipsum dapibus.
      </p>
      <div className="flex justify-between mb-5">
        <div className="flex gap-2">
          <Segment
            value={singleSegmentValue}
            onChange={(val) => onSingleSelectionSegmentChange(val)}
          >
            <Segment.Item value="workers" className="h-9 p-2">
              <span>View All</span>
            </Segment.Item>
            <Segment.Item value="compliant" className="h-9 p-2">
              <span>Compliant</span>
            </Segment.Item>
            <Segment.Item value="nonCompliant" className="h-9 p-2">
              <span>Non-compliant</span>
            </Segment.Item>
            
            <Segment.Item value="Invited" className="h-9 p-2">
              <span> Invited Workers</span>
            </Segment.Item>
          </Segment>
          <ClientInput
            type="text"
            className="input  w-72 input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Search"
            inputchangehandler={inputchangehandlerSearch}
            name={"search"}
          />
          {/* <BtnLight className="w-9 h-9 flex items-center justify-center">
            <svg
              width="21"
              height="20"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.712 8h12m0 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0zm-6 8h12m-12 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                stroke="#101828"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </BtnLight> */}
        </div>
        <div className="flex">
          {/* <BtnLight className="ml-4 w-29 flex items-center justify-center text-12 h-9">
            <span className="mr-3">
              <img
                src="/assets/icons-svg/file-download-02.svg"
                alt=""
                className="h-5"
              />
            </span>{" "}
            Import CSV{" "}
          </BtnLight> */}
          <div className="flex gap-2 reset-dropdown">
            <Dropdown renderTitle={Toggle} isOpen={true}>
              <Dropdown.Item eventKey="a">
                <CSVLink
                  className="client_portal_csv"
                  data={CSVData.map((row) =>
                    row.filter((column) => column !== "action")
                  )}
                  filename={"Sample file for worker invite.csv"}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 10V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V10M11.3333 6.66667L8 10M8 10L4.66667 6.66667M8 10V2"
                      stroke="#344054"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Download template</span>
                </CSVLink>
              </Dropdown.Item>
              <Dropdown.Item eventKey="b">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.33317 1.51318V4.26688C9.33317 4.64025 9.33317 4.82693 9.40583 4.96954C9.46975 5.09498 9.57173 5.19697 9.69718 5.26088C9.83978 5.33354 10.0265 5.33354 10.3998 5.33354H13.1535M5.99984 10.0002L7.99984 12.0002M7.99984 12.0002L9.99984 10.0002M7.99984 12.0002L7.99984 8.00016M9.33317 1.3335H5.8665C4.7464 1.3335 4.18635 1.3335 3.75852 1.55148C3.3822 1.74323 3.07624 2.04919 2.88449 2.42552C2.6665 2.85334 2.6665 3.41339 2.6665 4.5335V11.4668C2.6665 12.5869 2.6665 13.147 2.88449 13.5748C3.07624 13.9511 3.3822 14.2571 3.75852 14.4488C4.18635 14.6668 4.7464 14.6668 5.8665 14.6668H10.1332C11.2533 14.6668 11.8133 14.6668 12.2412 14.4488C12.6175 14.2571 12.9234 13.9511 13.1152 13.5748C13.3332 13.147 13.3332 12.5869 13.3332 11.4668V5.3335L9.33317 1.3335Z"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {/* Import file */}
                <span>Import file</span>
                <input
                  className="upload_csv"
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                />
              </Dropdown.Item>
            </Dropdown>
            <BtnPrimary
              onClick={() => openDialog()}
              className="px-2 ml-2 w-32 text-12 h-9 justify-center flex gap-2 items-center"
            >
              Add Worker{" "}
              <span>
                <img src="/assets/icons-svg/plus.svg" alt="" />
              </span>
            </BtnPrimary>
          </div>
        </div>
      </div>
      {singleSegmentValue == "Invited" && (
        <React.Fragment>
          <WorkersList
            handleSort={handleSort}
            getAllinvitedData={getAllinvitedData}
            singleSegmentValue={singleSegmentValue}
            setrefetch={setrefetch}
            refetch={refetch}
          />
          <div className="flex justify-between mt-3">
            <Pagination
              totalCount={
                GetFunction.SpecificContractorWorkerInvites?.data?.data
                  ?.count || 0
              }
              pageNumber={getAllinvitedData.page}
              displayPage={
                GetFunction.SpecificContractorWorkerInvites?.data?.data?.rows
                  ?.length || 0
              }
              UpdatePageLimit={SetgetAllinvitedData}
              allfilters={getAllinvitedData}
            />
            {GetFunction.SpecificContractorWorkerInvites?.data?.data?.count >
              0 && (
              <ReactPaginate
                className="flex justify-content-end p-2 align-items-center pagination-wrap"
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                // pageLinkClassName={'page-link'}
                previousLinkClassName={"prev-link"}
                nextLinkClassName={"page-link"}
                breakLinkClassName={"prev-link"}
                previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
                nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
                pageLinkClassName={"px-4 rounded-md"}
                activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
                disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              />
            )}
          </div>
        </React.Fragment>
      )}
      {singleSegmentValue == "compliant" && (
        <React.Fragment>
          <CompliantList
            handleSort={handleSort}
            getAllinvitedData={getAllinvitedData}
            singleSegmentValue={singleSegmentValue}
            setrefetch={setrefetch}
            refetch={refetch}
          />
        </React.Fragment>
      )}
      {singleSegmentValue == "nonCompliant" && (
        <React.Fragment>
          <NonCompliantList
            handleSort={handleSort}
            getAllinvitedData={getAllinvitedData}
            singleSegmentValue={singleSegmentValue}
            setrefetch={setrefetch}
            refetch={refetch}
          />
        </React.Fragment>
      )}
      {singleSegmentValue == "workers" && (
        <React.Fragment>
          <ViewAll
            handleSort={handleSort}
            getAllinvitedData={getAllinvitedData}
            singleSegmentValue={singleSegmentValue}
            setrefetch={setrefetch}
            refetch={refetch}
          />
          <div className="flex justify-between mt-3">
            <Pagination
              totalCount={
                GetFunction.SpecificContractorWorkerInvites?.data?.data
                  ?.count || 0
              }
              pageNumber={getAllinvitedData.page}
              displayPage={
                GetFunction.SpecificContractorWorkerInvites?.data?.data?.rows
                  ?.length || 0
              }
              UpdatePageLimit={SetgetAllinvitedData}
              allfilters={getAllinvitedData}
            />
            {GetFunction.SpecificContractorWorkerInvites?.data?.data?.count >
              0 && (
              <ReactPaginate
                className="flex justify-content-end p-2 align-items-center pagination-wrap"
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                // pageLinkClassName={'page-link'}
                previousLinkClassName={"prev-link"}
                nextLinkClassName={"page-link"}
                breakLinkClassName={"prev-link"}
                previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
                nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
                pageLinkClassName={"px-4 rounded-md"}
                activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
                disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              />
            )}
          </div>
        </React.Fragment>
      )}

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="addContact-dialog"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/file-check.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Invite a Worker
            </h5>
          </div>
        </div>
        {stepForm == 0 && (
          <div className="px-6">
            <div className="mb-5">
              <label className="font-medium text-gray-700 mb-1 block">
                Client
              </label>
              <div className="w-full hide-seacrh-icon">
                <MultiSelect
                  className="w-full"
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={personName}
                  onChange={handleChange}
                  // onOpen={() => {
                  //   openMultiSelect();
                  // }}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) =>
                    selected.map((item) => item.label).join(", ")
                  } // Display only names
                  MenuProps={MenuProps}
                >
                  {allClient?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      <Checkbox
                        checked={
                          personName.findIndex(
                            (selectedItem) => selectedItem.label === item.label
                          ) > -1
                        }
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  ))}
                </MultiSelect>
                {errorvalidation.workerClientsIds && (
                  <div className="text-red-500 text-sm mt-1 ">
                    {errorvalidation.workerClientsIds}
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center mb-1">
              <label className="font-medium text-gray-700 block ">
                Workers
              </label>
              <span
                className={
                  "badge tag text-warning-700 bg-warning-50 py-0.5 px-2 rounded-full font-medium"
                }
              >
                {totalWorker}/{seatCount} seats
              </span>
            </div>
            {addWorkerData?.workersInviteData?.map((item, index) => {
              return (
                <React.Fragment>
                  <div className="mt-5">
                    <div className="chooseEmail flex gap-2 items-start">
                      {/* <ClientInput
                      name="email"
                      inputchangehandler={inputchangehandler}
                      value={inviteData.email}
                      className="h-9 w-full"
                      placeholder="Enter Contact's first name"
                    /> */}
                      <div className="w-full">
                        <Select
                          className="w-full h-10 select-options"
                          placeholder="Search for a worker"
                          isClearable={true}
                          isSearchable={true}
                          options={
                            updatedOptions ? updatedOptions : selectedOption
                          }
                          value={{
                            label: item?.first_name
                              ? `${item?.first_name}` + "-" + item?.email
                              : item.label
                              ? item.label
                              : "",
                            // label:`${item?.first_name}` + "-" + item?.email,
                            email: item?.email,
                            individual_id: item?.individual_id,
                            is_conserve_team: item?.is_conserve_team,
                          }}
                          // value={{
                          //   label: `${item?.invite_individual?.first_name}` + "-" + item?.invite_individual?.email,
                          //   email: item?.invite_individual?.email,
                          //   individual_id: item?.individual_id,
                          //   is_conserve_team: item?.invite_individual?.is_conserve_team,
                          // }}
                          onChange={(e) => handleSelectChange(e, index)}
                          // onChange={(inputchangehandler) => {
                          //   if (!selectedValue) {
                          //     isSearchClear();
                          //   }
                          //   handleSelectChange(selectedValue);
                          // }}
                          // handleSearchChange={handleSearchChange}
                          handleSearchChange={inputchangehandler}
                          // onBlur={handleBlurForEmail}
                        />
                        {checkError && !item.email && (
                          <div className="text-red-500 text-sm mt-1 ">
                            This field is required!
                            {/* {errorvalidation.email} */}
                          </div>
                        )}
                        <div className="flex flex-row gap-1 mt-1">
                          {/* First priority: alreadyWorkerError */}
                          {errorValidation[`alreadyWorkerError_${index}`] && (
                            <div className="text-red-500 text-sm">
                              {errorValidation[`alreadyWorkerError_${index}`]}
                            </div>
                          )}

                          {/* Second priority: conserveTeamError, only if alreadyWorkerError doesn't exist */}
                          {!errorValidation[`alreadyWorkerError_${index}`] &&
                            errorValidation[`conserveTeamError_${index}`] && (
                              <div className="text-red-500 text-sm">
                                {errorValidation[`conserveTeamError_${index}`]}
                              </div>
                            )}

                          {/* Third priority: emailError, only if the previous two errors don't exist */}
                          {!errorValidation[`alreadyWorkerError_${index}`] &&
                            !errorValidation[`conserveTeamError_${index}`] &&
                            errorValidation[`emailError_${index}`] && (
                              <div className="text-red-500 text-sm">
                                {errorValidation[`emailError_${index}`]}
                              </div>
                            )}
                        </div>
                      </div>

                      {addWorkerData?.workersInviteData.length > 1 && (
                        <Button onClick={() => DeleteWorker(index)}>
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                              stroke="#667085"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </Button>
                      )}
                    </div>
                    {!item?.individual_id && (
                      <div className="flex gap-2">
                        <div className="w-3/6 mt-5">
                          <label className="font-medium text-gray-700 block mb-1">
                            First Name*
                          </label>
                          <ClientInput
                            name="first_name"
                            readOnly={item?.individual_id ? true : false}
                            inputchangehandler={(e) => {
                              formHandleData(e, index, "first_name");
                            }}
                            value={item.first_name}
                            className="h-9 w-full"
                            placeholder="Enter Contact's first name"
                          />
                          {checkError && !item.first_name && (
                            <div className="text-red-500 text-sm mt-1 ">
                              This field is required!
                            </div>
                          )}
                        </div>
                        <div className=" w-3/6 mt-5">
                          <label className="font-medium text-gray-700 block mb-1">
                            Last Name*
                          </label>
                          <ClientInput
                            name="last_name"
                            readOnly={item?.individual_id ? true : false}
                            inputchangehandler={(e) => {
                              formHandleData(e, index, "last_name");
                            }}
                            value={item.last_name}
                            className="h-9 w-full"
                            placeholder="Enter Contact's last name"
                          />
                          {checkError && !item.last_name && (
                            <div className="text-red-500 text-sm mt-1 ">
                              This field is required!
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              );
            })}

            <button
              class="text-blue-700 text-xs font-semibold flex items-center gap-2 mt-5"
              onClick={addWorker}
            >
              <span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 4.167v11.666M4.167 10h11.666"
                    stroke="#5786CC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </span>
              Add another Worker
            </button>
          </div>
        )}

        {stepForm == 1 && (
          <Elements stripe={stripePromise}>
            <PaymentMethod newSeat={totalWorker - seatCount} />
          </Elements>
        )}

        <div className="flex dailog-footer">
          <BtnLight
            className="mr-1.5 px-4"
            variant="plain"
            onClick={openUnsaveDialog}
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            disabled={hasConserveTeamError || hasAlreadyWorkerError}
            onClick={() =>
              openAddDialog(
                stepForm == 1 ? "payment" : 0,
                totalWorker <= seatCount
              )
            }
            className="ml-1.5 px-4 flex gap-2 items-center justify-center"
            type="submit"
          >
            {totalWorker <= seatCount ? "Invite" : "Next"}
          </BtnPrimary>
        </div>
      </Dialog>
      {/* CSV Dilog Box */}
      <Dialog
        isOpen={dialogOpenCSV}
        onClose={dialogCloseCSV}
        onRequestClose={dialogCloseCSV}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.67 10.417v-4.75c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.093-1.093c-.534-.272-1.235-.272-2.635-.272H7.336c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093c-.272.535-.272 1.235-.272 2.635v8.666c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h3.083m1.25-9.166h-5M8.336 12.5H6.669m6.667-6.667H6.669m5.834 10 2.5 2.5m0 0 2.5-2.5m-2.5 2.5v-5"
                stroke="#344054"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              CSV Upload
            </h5>
            <p>Providers</p>
          </div>
        </div>
        <div className="h-96 pr-2">
          <Scrollbar>
            <div className="px-4">
              <div className="overflow-hidden border rounded-lg border-gray-200">
                <Table>
                  <THead>
                    <Tr>
                      {/* {CSVData[0].map((item, index) => {
                        return (
                          <Th className="w-1/3" key={index}>
                            <span className="flex items-center gap-1">
                              {item}
                              {index == 0 && (
                                <svg
                                  width="17"
                                  height="16"
                                  viewBox="0 0 17 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                    stroke="#475467"
                                    strokeWidth="1.333"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                            </span>
                          </Th>
                          
                        );
                      })} */}
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          First Name
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                              stroke="#475467"
                              strokeWidth="1.333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          Last Name
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">Email</span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">Action</span>
                      </Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {csvData?.map((item, index) => {
                      return (
                        <React.Fragment>
                          <Tr key={index}>
                            <Td>{item.first_name}</Td>
                            <Td>{item.last_name}</Td>
                            <Td>{item.email}</Td>
                            <Td>
                              {item.is_validated ? (
                                <Checkbox
                                  defaultChecked={true}
                                  onChange={(event) => {
                                    handlecheckbox(event, index);
                                  }}
                                />
                              ) : (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m18 6.5-12 12m0-12 12 12"
                                    stroke="red"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                            </Td>
                          </Tr>
                        </React.Fragment>
                      );
                    })}
                  </TBody>
                </Table>
              </div>
              {!finalCsvData.length && (
                <div className="text-red-500 text-sm mt-2">
                  Some of the records seem to be incorrect. Please check the
                  uploaded file again.
                </div>
              )}
            </div>
          </Scrollbar>
        </div>

        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => dialogCloseCSV()} className="mr-1.5">
            Cancel
          </BtnLight>
          <BtnPrimary
            disabled={!finalCsvData.length}
            onClick={() => submitCsvDataNext()}
          >
            Next
          </BtnPrimary>
        </div>
      </Dialog>

      {/* Invite Provider import csv */}
      <Dialog
        isOpen={dialogNextCsv}
        onClose={onCloseNextCsv}
        onRequestClose={onCloseNextCsv}
        bodyOpenClassName="overflow-hidden"
        width={720}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_12450_78578)">
                <path
                  d="M19.1658 20.8745C19.9709 20.8745 20.6214 20.2225 20.6214 19.4191V15.5467C20.6214 14.7434 19.9709 14.0914 19.1658 14.0914H13.3557C12.5506 14.0914 11.9 14.7434 11.9 15.5467V19.4191C11.9 20.2225 12.5506 20.8745 13.3557 20.8745H19.1658ZM19.1658 19.5128H13.3557C13.304 19.5128 13.262 19.4707 13.262 19.4191V15.5467C13.262 15.4951 13.304 15.4531 13.3557 15.4531H19.1658C19.2174 15.4531 19.2594 15.4951 19.2594 15.5467V19.4191C19.2594 19.4707 19.2174 19.5128 19.1658 19.5128Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M19.2593 15.5458C19.2593 16.0251 18.8703 16.4141 18.391 16.4141H14.1303C13.651 16.4141 13.262 16.0251 13.262 15.5458C13.262 15.1699 12.9569 14.8648 12.581 14.8648C12.2051 14.8648 11.9 15.1699 11.9 15.5458C11.9 16.7769 12.8992 17.7761 14.1303 17.7761H18.391C19.6221 17.7761 20.6213 16.7769 20.6213 15.5458C20.6213 15.1699 20.3162 14.8648 19.9403 14.8648C19.5644 14.8648 19.2593 15.1699 19.2593 15.5458Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.81 14.889C18.1307 14.889 18.391 14.6287 18.391 14.308V13.5333C18.391 12.785 17.7852 12.1777 17.0354 12.1777H15.486C14.7362 12.1777 14.1304 12.785 14.1304 13.5333V14.308C14.1304 14.6287 14.3907 14.889 14.7114 14.889H17.81ZM17.229 13.8727H15.2924V13.5333C15.2924 13.4265 15.3792 13.3397 15.486 13.3397H17.0354C17.1422 13.3397 17.229 13.4265 17.229 13.5333V13.8727Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M10.78 14.0664C8.90316 14.098 5.33487 14.7346 3.56036 18.5573C3.34841 19.0142 3.38479 19.737 4.73027 19.776C6.07575 19.815 8.606 19.7923 10.166 19.776"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="11.5698"
                  cy="7.90674"
                  r="3.75986"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                />
              </g>
              <defs>
                <clipPath id="clip0_12450_78578">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Providers
            </h5>
            <p>
              Ensure Providers are categorised correctly and send an optional
              invitation message.
            </p>
          </div>
        </div>
        <div className="pl-4">
          <div className="h-24">
            <ScrollBar>
              <div className="pr-6 pl-2">
                <div className="mb-5">
                  <label className="font-medium text-gray-700 mb-1 block">
                    Client
                  </label>
                  <div className="w-full hide-seacrh-icon">
                    <MultiSelect
                      className="w-full"
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={personName}
                      onChange={handleChange}
                      // onOpen={() => {
                      //   openMultiSelect();
                      // }}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) =>
                        selected.map((item) => item.label).join(", ")
                      } // Display only names
                      MenuProps={MenuProps}
                    >
                      {allClient?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          <Checkbox
                            checked={
                              personName.findIndex(
                                (selectedItem) =>
                                  selectedItem.label === item.label
                              ) > -1
                            }
                          />
                          <ListItemText primary={item.label} />
                        </MenuItem>
                      ))}
                    </MultiSelect>
                    {errorvalidation.workerClientsIds && (
                      <div className="text-red-500 text-sm mt-1 ">
                        {errorvalidation.workerClientsIds}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </ScrollBar>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight className="mr-1.5" variant="plain" onClick={onCloseNextCsv}>
            Cancel
          </BtnLight>
          <BtnPrimary onClick={() => submitCsvData()} className="ml-1.5">
            Invite Provider
          </BtnPrimary>
        </div>
      </Dialog>
      {/* Invoice code */}
      <Dialog
        isOpen={dialogInvoice}
        onClose={onCloseInvoice}
        onRequestClose={onCloseInvoice}
        bodyOpenClassName="overflow-hidden"
        width={720}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_12450_78578)">
                <path
                  d="M19.1658 20.8745C19.9709 20.8745 20.6214 20.2225 20.6214 19.4191V15.5467C20.6214 14.7434 19.9709 14.0914 19.1658 14.0914H13.3557C12.5506 14.0914 11.9 14.7434 11.9 15.5467V19.4191C11.9 20.2225 12.5506 20.8745 13.3557 20.8745H19.1658ZM19.1658 19.5128H13.3557C13.304 19.5128 13.262 19.4707 13.262 19.4191V15.5467C13.262 15.4951 13.304 15.4531 13.3557 15.4531H19.1658C19.2174 15.4531 19.2594 15.4951 19.2594 15.5467V19.4191C19.2594 19.4707 19.2174 19.5128 19.1658 19.5128Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M19.2593 15.5458C19.2593 16.0251 18.8703 16.4141 18.391 16.4141H14.1303C13.651 16.4141 13.262 16.0251 13.262 15.5458C13.262 15.1699 12.9569 14.8648 12.581 14.8648C12.2051 14.8648 11.9 15.1699 11.9 15.5458C11.9 16.7769 12.8992 17.7761 14.1303 17.7761H18.391C19.6221 17.7761 20.6213 16.7769 20.6213 15.5458C20.6213 15.1699 20.3162 14.8648 19.9403 14.8648C19.5644 14.8648 19.2593 15.1699 19.2593 15.5458Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.81 14.889C18.1307 14.889 18.391 14.6287 18.391 14.308V13.5333C18.391 12.785 17.7852 12.1777 17.0354 12.1777H15.486C14.7362 12.1777 14.1304 12.785 14.1304 13.5333V14.308C14.1304 14.6287 14.3907 14.889 14.7114 14.889H17.81ZM17.229 13.8727H15.2924V13.5333C15.2924 13.4265 15.3792 13.3397 15.486 13.3397H17.0354C17.1422 13.3397 17.229 13.4265 17.229 13.5333V13.8727Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M10.78 14.0664C8.90316 14.098 5.33487 14.7346 3.56036 18.5573C3.34841 19.0142 3.38479 19.737 4.73027 19.776C6.07575 19.815 8.606 19.7923 10.166 19.776"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="11.5698"
                  cy="7.90674"
                  r="3.75986"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                />
              </g>
              <defs>
                <clipPath id="clip0_12450_78578">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Connect with [Client name]
            </h5>
            <p>
              Ensure a successful connection with the client by completing these
              steps
            </p>
          </div>
        </div>
        <div className="pl-4 pr-4">
          <div className="mb-5 bg-gray-50 rounded-xl border border-gray-200 p-5 rounded-xl ">
            <h3 className="mb-1 text-gray-700 font-bold text-base">
              Payment successful and invites sent to Workers!
            </h3>
            <p className="mb-4 text-gray-700">
              Thank you for your payment. A confirmation of this transaction has
              been sent to your email.
            </p>
            <h3 className="mb-1 text-gray-700 font-bold text-base">
              Invoice number{" "}
            </h3>
            <p className="mb-4 text-gray-700">
              <p>{invoiceData?.xero_invoice_number}</p>
            </p>
            <Button
              className="flex gap-1  text-blue-700 hover:text-blue-900 focus:text-blue-700"
              onClick={downloadInvoice}
            >
              Download invoice{" "}
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.583 13.2866V14.2866C17.583 15.6868 17.583 16.3868 17.3105 16.9216C17.0708 17.392 16.6884 17.7745 16.218 18.0141C15.6832 18.2866 14.9831 18.2866 13.583 18.2866H6.58301C5.18288 18.2866 4.48281 18.2866 3.94803 18.0141C3.47763 17.7745 3.09517 17.392 2.85549 16.9216C2.58301 16.3868 2.58301 15.6868 2.58301 14.2866V13.2866M14.2497 9.11995L10.083 13.2866M10.083 13.2866L5.91634 9.11995M10.083 13.2866V3.28662"
                  stroke="#5786CC"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </div>
        </div>

        <div className="flex dailog-footer ">
          <BtnPrimary
            onClick={() => onCloseInvoice()}
            className="ml-1.5 w-auto	px-4 my-auto	ml-auto	mr-0"
          >
            Okay
          </BtnPrimary>
        </div>
      </Dialog>

      {/* Succesfull Dilog Box */}
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="22"
              height="23"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#000"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onAddDialogClose}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default ContractorWorkers;
