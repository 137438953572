import React, { useEffect, useRef, useState } from "react";
import logomark from "./Logomark.png";
// import pdfbg from "../../../../../assets/icons-svg/"
// import pdfbg from "./head-bg.png";
import pdfbg from "./pdfbg.svg";
import Logo from "./Logo.png";
import logo from "../../../../../assets/logo.svg";
import sign from "./sign.png";
import Button from "../../../../../common/element/Buttons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import ClientInput from "../../../../../common/element/Input/clientInput";
import Tabs from "../../../../../common/element/Tabs";
import { useReactToPrint } from "react-to-print";
import { useDispatch } from "react-redux";
import { GetAllClientsForCompCertificate } from "../../../../../actions/admin-provider/GetAllClientsForCompCertificate";
import Td from "../../../../../common/element/Table/Td";
import Tr from "../../../../../common/element/Table/Tr";
import Table from "../../../../../common/element/Table";
import THead from "../../../../../common/element/Table/THead";
import Th from "../../../../../common/element/Table/Th";
import TBody from "../../../../../common/element/Table/TBody";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import { CreateCertificateGenActivity } from "../../../../../actions/admin-provider/CreateCertificateGenActivity";
import { GetProviderCertificateActivity } from "../../../../../actions/admin-provider/GetProviderCertificateActivity";
import moment from "moment";
import { GeneratedCertificateSendEmail } from "../../../../../actions/admin-provider/GeneratedCertificateSendEmail";
import ScrollBar from "../../../../../common/element/ScrollBar/ScrollBar";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { GetSignedUrlByKey } from "../../../../../actions/public/GetSignedUrlByKey";
const { TabNav, TabList, TabContent, Tag } = Tabs;
const PdfData = ({
  PdfData,
  type,
  userSettingHide,
  accreditationDate,
  provider_fa_id,
}) => {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [startPosition, setStartPosition] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(100);
  const [showPdf, setShowPdf] = useState(false);
  const [certificateNo, setCertificateNo] = useState("");
  const [dragging, setDragging] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [updatePdf, setUpdatePdf] = useState(false);
  const [clients, setClients] = useState([]);
  const [openPdf, SetOpenPdf] = useState(false);
  const [activityData, SetActivityData] = useState([]);
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  });
  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "JPEG", 0, 0);
        pdf.save("download.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF: ", error);
      });
  };
  const handleZoomOut = () => {
    setZoom(zoom + 10);
  };

  const handleZoomIn = () => {
    if (zoom > 1) {
      setZoom(zoom - 10);
    }
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setDragging(false);
    setStartPosition(null);
  };

  const handleMouseMove = (e) => {
    if (dragging && startPosition) {
      const deltaX = e.clientX - startPosition.x;
      const deltaY = e.clientY - startPosition.y;
      setPosition({
        x: position.x + deltaX,
        y: position.y + deltaY,
      });
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const onDialogAddDocClose = () => {
    SetOpenPdf(false);
  };
  // const handleDownload = () => {
  //   const link = document.createElement("a");
  //   link.href = UrlPdf;
  //   link.download = "image.jpg";
  //   link.click();
  // };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // const handlePrint = () => {
  //   window.print();

  //   // const printWindow = window.open("", "", "width=600,height=600");
  //   // printWindow.document.open();
  //   // printWindow.document.write(`<img src="${UrlPdf}" alt="Image" />`);
  //   // printWindow.document.close();
  //   // printWindow.print();
  //   // printWindow.close();
  // };

  const getClientData = (
    date,
    newCertificate,
    provider_certi_activity_id,
    certi_no
  ) => {
    dispatch(
      GetAllClientsForCompCertificate({
        provider_fa_id: PdfData?.provider_fa_id,
        last_certificate_gen_date: date,
        type: type,
      })
    ).then((response) => {
      if (response.status) {
        setClients(response?.data);
        if (newCertificate == "newCertificate") {
          // Capture the div as a high-resolution image
          const input = document.getElementById("divToPrint");

          // Increase scale for higher resolution
          if (document.getElementById("divToPrint") != null) {
            html2canvas(input, { scale: 3 }).then((canvas) => {
              const imgData = canvas.toDataURL("image/png");
              const pdf = new jsPDF("p", "mm", "a4");

              // Calculate scaling factors
              const pdfWidth = pdf.internal.pageSize.getWidth();
              const pdfHeight = pdf.internal.pageSize.getHeight();

              const imgWidth = canvas.width;
              const imgHeight = canvas.height;

              const ratio = Math.min(
                pdfWidth / imgWidth,
                pdfHeight / imgHeight
              );
              const newWidth = imgWidth * ratio;
              const newHeight = imgHeight * ratio;

              // Add the image to the PDF
              pdf.addImage(imgData, "PNG", 0, 0, newWidth, newHeight);

              // Convert PDF to Blob
              const pdfBlob = pdf.output("blob");
              // Create a FormData object and append the file
              const formData = new FormData();
              formData.append("provider_org_id", PdfData?.organisation_id);
              formData.append(
                "provider_certi_activity_id",
                provider_certi_activity_id
              );
              formData.append("certi_no", certi_no);
              formData.append(
                "clientIds",
                JSON.stringify(
                  response?.data.map((item) => item.organisation_id)
                )
              );
              formData.append("certificate", pdfBlob, "certificate.pdf");

              // Dispatch the action to send the file
              dispatch(GeneratedCertificateSendEmail(formData)).then(
                (response) => {
                  if (response.success) {
                    setPdfUrl(response?.data?.certi_url);
                    setUpdatePdf(false);
                  }
                }
              );
            });
          }
        }
      }
    });

    // Activity API
    dispatch(
      GetProviderCertificateActivity({
        provider_org_id: PdfData?.organisation_id,
      })
    ).then((response) => {
      if (response.success) {
        if (response?.data?.length) {
          SetActivityData(response.data);
          if (response?.data[0]?.certi_url) {
            dispatch(
              GetSignedUrlByKey(
                { url: response?.data[0]?.certi_url },
                "admin/provider"
              )
            ).then((response) => {
              if (response.success) {
                setPdfUrl(response?.data);
              }
            });
          }
        }
      }
    });
  };

  useEffect(() => {
    getClientData(
      PdfData?.last_certificate_gen_date
        ? PdfData?.last_certificate_gen_date
        : ""
    );
  }, []);

  const certificateGenActivity = () => {
    setUpdatePdf(true);
    const payload = {
      provider_org_id: PdfData?.organisation_id,
    };
    dispatch(CreateCertificateGenActivity(payload)).then((response) => {
      if (response.success) {
        setCertificateNo(
          PdfData?.trading_name?.slice(0, 3) +
            "00" +
            response?.data?.provider_certi_activity_id
        );
        getClientData(
          response?.data?.last_certificate_gen_date,
          "newCertificate",
          response?.data?.provider_certi_activity_id,
          PdfData?.trading_name?.slice(0, 3) +
            "00" +
            response?.data?.provider_certi_activity_id
        );
      }
    });
  };

  return (
    <React.Fragment>
      {clients?.length >= 1 && (
        <>
          {!openPdf && (
            <React.Fragment>
              <div className="flex justify-between items-center pr-6 my-6 ">
                <h4 className="text-gray-900 text-base font-semibold">
                  Compliance certificate
                </h4>
                <Button
                  className="border text-sm border-gray-300 rounded-lg p-3 font-semibold text-gray-700"
                  onClick={certificateGenActivity}
                >
                  {activityData.length == 0
                    ? "Generate certificate"
                    : "Regenerate certificate"}
                </Button>
              </div>

              {/* Check Certificate is available or not */}
              {activityData.length == 0 && !updatePdf ? (
                <React.Fragment>
                  <span className="h-full w-full my-2">
                    No certificate currently. Click generate certificate to
                    create one.
                  </span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {(!pdfUrl || updatePdf) && (
                    <div className="border inline-flex border-gray-300 rounded-lg p-8 pb-10	relative content ">
                      <div className="pdf-shadow" id="divToPrint">
                        <div
                          style={{
                            background: "rgb(245, 250, 255)",
                            margin: 0,
                            padding: 0,
                            borderCollapse: "collapse",
                            border: "none",
                            width: "200px",
                          }}
                        >
                          <table
                            style={{
                              width: "100%",
                              backgroundColor: "rgb(245, 250, 255)",
                              border: "0px",
                              borderSpacing: "0px",
                              backgroundRepeat: "no-repeat",
                              backgroundImage: `url(${logomark})`,
                              backgroundPosition: "left center",
                              backgroundSize: "contain",
                            }}
                          >
                            <thead
                              style={{
                                backgroundImage: `url(${pdfbg})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center bottom",
                              }}
                            >
                              <tr>
                                <th
                                  style={{ height: "30px", lineHeight: "30px" }}
                                ></th>
                              </tr>
                              <tr>
                                <th style={{ padding: "0px 0px 10px" }}>
                                  <img
                                    className="m-auto w-11 h-2.5"
                                    src={Logo}
                                    alt="Logo"
                                  />
                                </th>
                              </tr>
                              <tr>
                                <th
                                  style={{
                                    fontSize: "11px",
                                    color: "rgb(255, 255, 255)",
                                    textAlign: "center",
                                  }}
                                >
                                  Certificate of Compliance
                                </th>
                              </tr>
                              <tr>
                                <th
                                  style={{
                                    fontSize: "5px",
                                    color: "rgb(255, 255, 255)",
                                    textAlign: "center",
                                    padding: "5px 0px",
                                    fontWeight: 400,
                                  }}
                                >
                                  This is to certify that
                                </th>
                              </tr>
                              <tr>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    color: "rgb(14, 78, 154)",
                                    fontSize: "9px",
                                    textAlign: "center",
                                    padding: "5px 0px",
                                  }}
                                >
                                  <strong>{PdfData?.trading_name}</strong>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    textAlign: "center",
                                    paddingBottom: "8px",
                                  }}
                                >
                                  <span
                                    style={{
                                      paddingRight: "20px",
                                      fontSize: "5px",
                                      color: "rgb(14, 78, 154)",
                                    }}
                                  >
                                    <strong>ABN:{PdfData?.abn_nzbn}</strong>
                                  </span>
                                  <span
                                    style={{
                                      paddingLeft: "20px",
                                      fontSize: "5px",
                                      color: "rgb(14, 78, 154)",
                                    }}
                                  >
                                    <strong>ACN:{PdfData?.acn}</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    textAlign: "center",
                                    padding: "5px 60px",
                                    color: "rgb(29, 41, 57)",
                                    fontSize: "4px",
                                  }}
                                >
                                  is an approved Conserve contractor following
                                  the following client(s)
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    textAlign: "center",
                                    color: "rgb(29, 41, 57)",
                                  }}
                                >
                                  <table
                                    style={{
                                      maxWidth: "700px",
                                      margin: "auto",
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            textAlign: "center",
                                            color: "rgb(29, 41, 57)",
                                            fontSize: "4px",
                                          }}
                                        >
                                          {clients?.map((item, index) => (
                                            <span key={index}>
                                              {item?.trading_name}{" "}
                                              {index == clients.length - 1
                                                ? ""
                                                : ","}
                                            </span>
                                          ))}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "11px 5px" }}>
                                  <table style={{ width: "100%" }}>
                                    <tr>
                                      <td style={{ color: "rgb(29, 41, 57)" }}>
                                        <table>
                                          <tr>
                                            <td style={{ fontSize: "3px" }}>
                                              <strong>Contractor ID:</strong>{" "}
                                              {PdfData?.organisation_id}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ fontSize: "3px" }}>
                                              <strong>
                                                Certificate No:{certificateNo}
                                              </strong>{" "}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ fontSize: "3px" }}>
                                              <strong>Expiry Date:</strong>{" "}
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                      <td style={{ color: "rgb(29, 41, 57)" }}>
                                        <table style={{ width: "100%" }}>
                                          <tr>
                                            <td style={{ textAlign: "right" }}>
                                              <img
                                                className="my-auto ml-auto mr-0 w-8 h-4"
                                                src={sign}
                                                alt="Signature"
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: "right",
                                                fontSize: "3px",
                                              }}
                                            >
                                              <strong>Brian Govindasamy</strong>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                textAlign: "right",
                                                fontSize: "3px",
                                              }}
                                            >
                                              Managing Director
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "0px 5px" }}>
                                  <table style={{ width: "100%" }}>
                                    <tr>
                                      <td
                                        style={{
                                          color: "rgb(29, 41, 57)",
                                          verticalAlign: "top !important",
                                        }}
                                      >
                                        <table>
                                          <tr>
                                            <td style={{ fontSize: "3px" }}>
                                              This certificate confirms that the
                                              provider is approved in Conserve©
                                              and has met the individual
                                              client’s validation criteria, at
                                              the time of assessment.
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                      <td
                                        style={{
                                          color: "rgb(29, 41, 57)",
                                          verticalAlign: "top !important",
                                        }}
                                      >
                                        <table>
                                          <tr>
                                            <td
                                              style={{
                                                fontSize: "3px",
                                                lineHeight: 0,
                                              }}
                                            >
                                              <strong>Conserve</strong>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                fontSize: "3px",
                                                paddingBottom: "4px",
                                              }}
                                            >
                                              ABN: {PdfData?.abn_nzbn}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                fontSize: "3px",
                                                lineHeight: 0,
                                              }}
                                            >
                                              <strong>Postal address</strong>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ fontSize: "3px" }}>
                                              {PdfData?.address_one}
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                      <td
                                        style={{
                                          color: "rgb(29, 41, 57)",
                                          verticalAlign: "top !important",
                                        }}
                                      >
                                        <table style={{ width: "100%" }}>
                                          <tr>
                                            <td
                                              style={{
                                                fontSize: "3px",
                                                lineHeight: 0,
                                              }}
                                            >
                                              <strong>Phone</strong>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ fontSize: "3px" }}>
                                              {PdfData?.phone
                                                ? PdfData?.phone
                                                : "-"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                fontSize: "3px",
                                                lineHeight: 0,
                                              }}
                                            >
                                              <strong>Fax</strong>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ fontSize: "3px" }}>
                                              {PdfData?.org_fax
                                                ? PdfData?.org_fax
                                                : "-"}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                fontSize: "3px",
                                                lineHeight: 0,
                                              }}
                                            >
                                              <strong>Email</strong>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td style={{ fontSize: "3px" }}>
                                              {PdfData?.email
                                                ? PdfData?.email
                                                : "-"}
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {type != "adminworker" && (
                        <div className="flex border-t border-gray-100 pdf-btn-block">
                          <Button
                            className="border-r h-10 flex gap-1 flex-1 text-sm font-semibold text-gray-700 items-center justify-center"
                            onClick={() => SetOpenPdf(true)}
                          >
                            <svg
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.375 7.64258L18.375 2.64258M18.375 2.64258H13.375M18.375 2.64258L11.7083 9.30924M9.20833 4.30924H7.375C5.97487 4.30924 5.2748 4.30924 4.74002 4.58173C4.26962 4.82141 3.88717 5.20386 3.64748 5.67427C3.375 6.20905 3.375 6.90911 3.375 8.30924V13.6426C3.375 15.0427 3.375 15.7428 3.64748 16.2776C3.88717 16.748 4.26962 17.1304 4.74002 17.3701C5.2748 17.6426 5.97487 17.6426 7.375 17.6426H12.7083C14.1085 17.6426 14.8085 17.6426 15.3433 17.3701C15.8137 17.1304 16.1962 16.748 16.4358 16.2776C16.7083 15.7428 16.7083 15.0427 16.7083 13.6426V11.8092"
                                stroke="#344054"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Open
                          </Button>
                        </div>
                      )}
                      {type == "adminworker" && (
                        <div className="flex border-t border-gray-100 pdf-btn-block">
                          <Button
                            className="border-r h-10 flex gap-1 flex-1 text-sm font-semibold text-gray-700 items-center justify-center"
                            onClick={() => SetOpenPdf(true)}
                          >
                            <svg
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.375 7.64258L18.375 2.64258M18.375 2.64258H13.375M18.375 2.64258L11.7083 9.30924M9.20833 4.30924H7.375C5.97487 4.30924 5.2748 4.30924 4.74002 4.58173C4.26962 4.82141 3.88717 5.20386 3.64748 5.67427C3.375 6.20905 3.375 6.90911 3.375 8.30924V13.6426C3.375 15.0427 3.375 15.7428 3.64748 16.2776C3.88717 16.748 4.26962 17.1304 4.74002 17.3701C5.2748 17.6426 5.97487 17.6426 7.375 17.6426H12.7083C14.1085 17.6426 14.8085 17.6426 15.3433 17.3701C15.8137 17.1304 16.1962 16.748 16.4358 16.2776C16.7083 15.7428 16.7083 15.0427 16.7083 13.6426V11.8092"
                                stroke="#344054"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Open
                          </Button>
                          <Button
                            className="border-l h-10 flex gap-1 flex-1 text-sm font-semibold text-gray-700 items-center justify-center"
                            onClick={printDocument}
                          >
                            <svg
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.875 12.6426V13.6426C17.875 15.0427 17.875 15.7428 17.6025 16.2776C17.3628 16.748 16.9804 17.1304 16.51 17.3701C15.9752 17.6426 15.2751 17.6426 13.875 17.6426H6.875C5.47487 17.6426 4.7748 17.6426 4.24002 17.3701C3.76962 17.1304 3.38717 16.748 3.14748 16.2776C2.875 15.7428 2.875 15.0427 2.875 13.6426V12.6426M14.5417 8.47591L10.375 12.6426M10.375 12.6426L6.20833 8.47591M10.375 12.6426V2.64258"
                                stroke="#344054"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Download
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                  {pdfUrl && !updatePdf && (
                    <div className="border border-gray-300 rounded-lg pt-8	relative h-80 w-96">
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <Viewer
                          fileUrl={pdfUrl}
                          // plugins={[defaultLayoutPluginInstance]}
                          theme="light"
                          Viewer
                        />
                      </Worker>
                      {/* <div>
                    <div
                      style={{
                        background: "rgb(245, 250, 255)",
                        margin: 0,
                        padding: 0,
                        borderCollapse: "collapse",
                        border: "none",
                        width: "200px",
                        height:"100%"
                      }}
                    >
                    
                    </div>
                  </div> */}
                      <div className="flex border-t border-gray-100 pdf-btn-block">
                        <Button
                          className="border-r h-10 flex gap-1 flex-1 text-sm font-semibold text-gray-700 items-center justify-center"
                          onClick={() => SetOpenPdf(true)}
                        >
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.375 7.64258L18.375 2.64258M18.375 2.64258H13.375M18.375 2.64258L11.7083 9.30924M9.20833 4.30924H7.375C5.97487 4.30924 5.2748 4.30924 4.74002 4.58173C4.26962 4.82141 3.88717 5.20386 3.64748 5.67427C3.375 6.20905 3.375 6.90911 3.375 8.30924V13.6426C3.375 15.0427 3.375 15.7428 3.64748 16.2776C3.88717 16.748 4.26962 17.1304 4.74002 17.3701C5.2748 17.6426 5.97487 17.6426 7.375 17.6426H12.7083C14.1085 17.6426 14.8085 17.6426 15.3433 17.3701C15.8137 17.1304 16.1962 16.748 16.4358 16.2776C16.7083 15.7428 16.7083 15.0427 16.7083 13.6426V11.8092"
                              stroke="#344054"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          Open
                        </Button>
                      </div>
                    </div>
                  )}
                  <h4 className="text-gray-900 text-base font-semibold mt-6">
                    Certificate activity
                  </h4>
                  <div className="overflow-hidden border rounded-lg border-gray-200 mt-6 h-72">
                    <ScrollBar>
                      <Table>
                        <THead>
                          <Tr>
                            <Th>Date</Th>
                            <Th>Activity</Th>
                            <Th>User</Th>
                          </Tr>
                        </THead>
                        <TBody>
                          {activityData?.map((item, index) => {
                            return (
                              <Tr key={index}>
                                <Td>
                                  {moment(item.created_date).format(
                                    "YYYY-MM-DD"
                                  )}
                                </Td>
                                <Td>Regenerate certificate</Td>
                                <Td>
                                  {item?.certiGenBy.first_name +
                                    " " +
                                    item?.certiGenBy.last_name}
                                </Td>
                              </Tr>
                            );
                          })}
                        </TBody>
                      </Table>
                    </ScrollBar>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {openPdf && (
            <Dialog
              isOpen={true}
              bodyOpenClassName="overflow-hidden"
              className="documentDialog"
              onClose={onDialogAddDocClose}
              onRequestClose={onDialogAddDocClose}
            >
              {pdfUrl && (
                <div
                  className="image-container view_pdf"
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onMouseMove={handleMouseMove}
                  id="divToPrint"
                  ref={componentRef}
                >
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={pdfUrl}
                      plugins={[defaultLayoutPluginInstance]}
                      theme="dark"
                    />
                  </Worker>
                </div>
              )}
              {!pdfUrl && (
                <React.Fragment>
                  <div className="iFrame-Header image_viewer pdf_btn_center	">
                    <div className="text-white text-xs">
                      {/* {moduleData?.[indexData]?.company_ind_file?.name} */}
                    </div>
                    <div className="btn-group">
                      <button
                        onClick={() => handleZoomIn()}
                        className="bg-white"
                      >
                        -
                      </button>
                      <button
                        onClick={() => handleZoomOut()}
                        className="bg-white"
                      >
                        +
                      </button>
                    </div>
                    <div className="flex gap-3">
                      <button onClick={() => printDocument()}>
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 13v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C15.6 18 14.9 18 13.5 18h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 16.1 2.5 15.4 2.5 14v-1m11.667-4.167L10 13m0 0L5.833 8.833M10 13V3"
                            stroke="#fff"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button onClick={() => handlePrint()}>
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 6.333v-1.5c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 3.433 5 3.899 5 4.833v1.5m0 9.166c-.775 0-1.163 0-1.48-.085a2.5 2.5 0 0 1-1.768-1.768c-.085-.318-.085-.705-.085-1.48v-1.833c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.534-.272 1.234-.272 2.635-.272h8.666c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.093 1.093c.272.534.272 1.235.272 2.635v1.833c0 .775 0 1.162-.085 1.48a2.5 2.5 0 0 1-1.768 1.768c-.318.085-.705.085-1.48.085m-2.5-6.25H15m-7.333 9.584h4.666c.934 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.824.182-1.757v-1.333c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 13.433 5 13.899 5 14.833v1.333c0 .933 0 1.4.181 1.757.16.313.415.568.729.728.356.182.823.182 1.756.182z"
                            stroke="#fff"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div
                    className="image-container view_pdf"
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    id="divToPrint"
                    ref={componentRef}
                  >
                    <div
                      className="pdf-shadow"
                      style={{
                        scale: `${zoom}% `,
                        transform: `translate(${position.x}px, ${position.y}px)`,
                      }}
                    >
                      <div
                        border="0"
                        calsan=""
                        style={{
                          background: "#f5faff",
                          margin: "0",
                          padding: "0",
                          borderCollapse: "collapse",
                          border: "none",
                        }}
                      >
                        <table
                          style={{
                            width: "100%",
                            backgroundColor: "#f5faff",
                            border: 0,
                            borderSpacing: 0,
                            backgroundImage: `url(${logomark})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "left center",
                            backgroundSize: "contain",
                          }}
                        >
                          <thead
                            style={{
                              backgroundImage: `url(${pdfbg})`,
                              backgroundSize: "cover",
                              backgroundPosition: "bottom",
                            }}
                          >
                            <tr>
                              <th
                                style={{ height: "60px", lineHeight: "60px" }}
                              ></th>
                            </tr>
                            <tr>
                              <th style={{ padding: "0 0 40px 0" }}></th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  fontSize: "48px",
                                  color: "#fff",
                                  textAlign: "center",
                                }}
                              >
                                Certificate of Compliance
                              </th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  fontSize: "24px",
                                  color: "#fff",
                                  textAlign: "center",
                                  padding: "25px 0",
                                  fontWeight: 400,
                                }}
                              >
                                This is to certify that
                              </th>
                            </tr>
                            <tr>
                              <th
                                style={{ height: "60px", lineHeight: "60px" }}
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  color: "#0e4e9a",
                                  fontSize: "28px",
                                  textAlign: "center",
                                  padding: "30px 0",
                                }}
                              >
                                <strong>{PdfData?.trading_name}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "center" }}>
                                <span
                                  style={{
                                    paddingRight: "20px",
                                    fontSize: "24px",
                                    color: "#0e4e9a",
                                  }}
                                >
                                  <strong>ABN:{PdfData?.abn_nzbn} </strong>
                                </span>
                                <span
                                  style={{
                                    paddingLeft: "20px",
                                    fontSize: "24px",
                                    color: "#0e4e9a",
                                  }}
                                >
                                  <strong>ACN:{PdfData?.acn} </strong>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "center",
                                  padding: "30px 60px",
                                  color: "#1d2939",
                                  fontSize: "24px",
                                }}
                              >
                                is an approved Conserve contractor following the
                                following client(s)
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "center",
                                  color: "#1d2939",
                                }}
                              >
                                <table
                                  style={{ maxWidth: "700px", margin: "auto" }}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          color: "#1d2939",
                                          lineHeight: "30px",
                                          fontSize: "20px",
                                        }}
                                      >
                                        {clients?.map((item, index) => (
                                          <span key={index}>
                                            {item?.trading_name}{" "}
                                            {index == clients.length - 1
                                              ? ""
                                              : ","}
                                          </span>
                                        ))}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            <tr>
                              <td style={{ padding: "0 60px" }}>
                                <table
                                  style={{ width: "100%", marginTop: "60px" }}
                                >
                                  <tr>
                                    <td style={{ color: "#1d2939" }}>
                                      <table>
                                        <tr>
                                          <td>
                                            <strong>Provider ID:</strong>{" "}
                                            {PdfData?.organisation_id}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <strong>Certificate No:</strong>{" "}
                                            {certificateNo}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <strong>Expiry Date:</strong>{" "}
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                    <td style={{ color: "#1d2939" }}>
                                      <table style={{ width: "100%" }}>
                                        <tr>
                                          <td style={{ textAlign: "right" }}>
                                            <img src={sign} alt="Signature" />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ textAlign: "right" }}>
                                            <strong>Brian Govindasamy</strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ textAlign: "right" }}>
                                            Managing Director
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </Dialog>
          )}
        </>
      )}
      <div className="flex pr-6 pt-6 pb-6 justify-end border-gray-200">
        <BtnLight
          onClick={() => userSettingHide()}
          className="w-auto px-5 mr-3"
          variant="plain"
        >
          Close
        </BtnLight>
      </div>

      {clients?.length == 0 && (
        <div className="h-full w-full text-center my-2">No Data Available!</div>
      )}
    </React.Fragment>
  );
};

export default PdfData;
