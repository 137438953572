import React, { useEffect, useRef, useState } from "react";
import Tag from "../../../../../../common/element/Tag";
import Button from "../../../../../../common/element/Buttons";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Scrollbar from "../../../../../../common/element/ScrollBar";
import Tabs from "../../../../../../common/element/Tabs";
import { notify } from "react-notify-toast";
import { useDispatch, useSelector } from "react-redux";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import {
  GetDocumentHistoryById,
  UpdateProviderDocStatus,
  UpdateProviderDoc,
  DeleteProviderDoc,
} from "../../../../../../actions/client-portal/AllSiteApi";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetInvoiceDetailsById } from "../../../../../../actions/Admin-Client/invoices/GetInvoiceDetailsById";
import moment from "moment";
import { GetInvoiceHistoryById } from "../../../../../../actions/Admin-Client/invoices/GetInvoiceHistoryById";
let fileExtension;
const { TabNav, TabList, TabContent } = Tabs;
const ViewInvoice = (props) => {
  // const { specificClientDetails } = useSelector((state) => state);
  const navigate = useNavigate();
  let trading_name;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    trading_name = storedData.trading_name;
  }
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const client_org_id = queryParams.get("client_org_id");

  const handleUnauthorizedAccess = useUnauthorizedHandler();

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  });
  const dispatch = useDispatch();
  const [updateData, SetupdateData] = useState([]);
  const [allHistoryData, SethistoryData] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [specificData, SetspecificData] = useState([]);
  const [specificDataUpdate, SetspecificDataUpdate] = useState([]);

  // const [checkEdit, SetCheckEdit] = useState(false);

  const [refetchData, setrefetchData] = useState(false);
  const [closePannel, setClosePannel] = useState(false);
  const [deleteDiolog, setDeleteDiolog] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [updateState, setUpdateState] = useState({ id: "", name: "" });
  const [pdfFile, setPdfFile] = useState("");

  const [closeAddDocPannel, setCloseAddDocPannel] = useState(false);
  const [statusData, setStatusData] = useState({
    provider_doc_appr_id: "",
    approval_status: "",
    doc_name: "",
    client_trading_name: trading_name,
    provider_trading_name: props?.trading_name ? props?.trading_name : "",
    message: "",
  });

  fileExtension = pdfFile?.split(".").pop().toLowerCase();

  const handleTabClick = (event) => {
    if (event.target.textContent === "History") {
      if (!buttonDisabled) {
        setButtonDisabled(true);
        try {
          const payload = {
            account_xero_invoice_id:
              props?.invoicesData?.account_xero_invoice_id,
          };
          if (props?.invoicesData?.account_xero_invoice_id) {
            dispatch(GetInvoiceHistoryById(payload))
              .then((response) => {
                if (response?.success) {
                  // handle success, set the history data
                  const data = response?.data?.data || [];
                  SethistoryData(data); // Pass the correct data to the state
                } else {
                  // handle the case where success is false
                  console.error("API response was not successful.");
                  setButtonDisabled(false);
                }
              })
              .catch((error) => {
                // handle API call failure
                console.error("API call failed: ", error);
                SethistoryData([]);
                setButtonDisabled(false);
              });
          } else {
            SethistoryData([]);
            setButtonDisabled(false);
          }
        } catch (error) {
          console.error("Unexpected error: ", error);
          setButtonDisabled(false);
        }
      }
    } else {
      setButtonDisabled(false);
    }
  };

  // Documentb Edit function
  // const handleEditForm = (e, customField) => {
  //   if (customField == "emptyDoc_file") {
  //     SetupdateData(e[0]);
  //   } else {
  //     SetupdateData(e.target.value);
  //   }
  //   SetspecificDataUpdate((prevalue) => {
  //     if (customField == "customField") {
  //       const doctype_fields_data = {
  //         ...prevalue.doctype_fields_data, // If it already exists in prevalue
  //         [e.target.name]: e.target.value, // Assuming 'name' is the key where you want to store the value
  //         // If you have different keys, replace 'name' with your desired key
  //       };
  //       return {
  //         ...prevalue,
  //         doctype_fields_data: doctype_fields_data,
  //       };
  //     } else if (customField == "doc_file") {
  //       return {
  //         ...prevalue,
  //         ["doc_file"]: e.target.files[0],
  //       };
  //     } else if (customField == "emptyDoc_file") {
  //       return {
  //         ...prevalue,
  //         ["doc_file"]: e[0],
  //       };
  //     } else {
  //       return {
  //         ...prevalue,
  //         [e.target.name]: e.target.value,
  //       };
  //     }
  //   });
  //   SetspecificData((prevalue) => {
  //     if (customField == "customField") {
  //       return {
  //         ...prevalue,
  //         customField: {
  //           ...prevalue.customField,
  //           [e.target.name]: e.target.value,
  //         },
  //       };
  //     } else if (customField == "doc_file") {
  //       return {
  //         ...prevalue,
  //         ["doc_file"]: e.target.files[0],
  //       };
  //     } else if (customField == "emptyDoc_file") {
  //       return {
  //         ...prevalue,
  //         ["doc_file"]: e[0],
  //       };
  //     } else {
  //       return {
  //         ...prevalue,
  //         [e.target.name]: e.target.value,
  //       };
  //     }
  //   });
  // };
  // Update data in a state
  // useEffect(() => {
  //   let customColumnName = "";
  //   if (specificData?.document_id) {
  //     if (specificDataUpdate?.doctype_fields_data) {
  //       customColumnName = Object.keys(specificDataUpdate?.doctype_fields_data);
  //     }

  //     let columnName;
  //     if (customColumnName) {
  //       columnName = {
  //         ...specificDataUpdate,
  //         [customColumnName]: customColumnName,
  //       };
  //     } else {
  //       columnName = specificDataUpdate;
  //     }
  //     const columnData = Object.keys(columnName).filter(
  //       (key) =>
  //         key !== "document_id" &&
  //         key !== "column_names" &&
  //         key !== "doctype_fields_data"
  //     );
  //     SetspecificDataUpdate((prevData) => ({
  //       ...prevData,
  //       column_names: columnData,
  //     }));
  //   }
  // }, [updateData]);
  // Document updatData function
  let UpdateDocData;
  if (specificDataUpdate?.doctype_fields_data) {
    UpdateDocData = {
      ...specificDataUpdate,
      ["doctype_fields_data"]: JSON.stringify(
        specificDataUpdate.doctype_fields_data
      ),
    };
  } else {
    UpdateDocData = specificDataUpdate;
  }
  // const updatData = () => {
  //   let updatePayload = {
  //     ...UpdateDocData,
  //     ...{ document_id: specificData?.document_id },
  //   };
  //   dispatch(UpdateProviderDoc(updatePayload))
  //     .then((response) => {
  //       if (response?.success) {
  //         // SetCheckEdit(false);
  //         SetspecificDataUpdate({
  //           document_id: specificData?.document_id,
  //           column_names: [""],
  //         });
  //         setrefetchData(!refetchData);
  //         // SetDocStatus("pending");
  //       } else if (response?.status === 401) {
  //         handleUnauthorizedAccess();
  //       }
  //     })
  //     .catch((error) => {
  //       notify.show(error, "error");
  //     });
  // };

  const onDeleteDialogClose = () => {
    setDeleteDiolog(false);
  };

  // const deleteModuleConfirmation = () => {
  //   dispatch(DeleteProviderDoc(deleteData)).then((response) => {
  //     if (response?.success) {
  //       setDeleteDiolog(false);
  //       props.refetch(true);
  //     }
  //   });
  // };
  let docName;
  if (specificData?.doc_file) {
    if (specificData?.doc_file.name) {
      docName = specificData?.doc_file.name;
    } else {
      docName = specificData?.doc_file.split("-");
      const partAfterThirdHyphen = docName.slice(4).join("-");
      docName = decodeURIComponent(partAfterThirdHyphen);
    }
  } else {
    docName = "";
  }

  const fileInputRef = useRef(null);

  // const UploadDoc = () => {
  //   // Trigger click event on file input
  //   fileInputRef.current.click();
  // };

  useEffect(() => {
    if (specificData?.invoiceUrl) {
      if (specificData.invoiceUrl instanceof Blob) {
        // If it's a Blob or File, use FileReader
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setPdfFile(base64String);
        };
        reader.readAsDataURL(specificData.invoiceUrl);
      } else {
        // If it's a URL (string), just set the URL
        setPdfFile(specificData.invoiceUrl);
      }
    } else {
      setPdfFile(null);
    }
  }, [specificData?.invoiceUrl]);

  useEffect(() => {
    if (closeAddDocPannel) {
      // setDialogAddDocIsOpen(false);
      setCloseAddDocPannel(false);
      setrefetchData(!refetchData);
    }
  }, [closeAddDocPannel]);

  const onDialogAddDocClose = () => {
    // setDialogAddDocIsOpen(false);
  };

  // const OpenAddnewDoc = (e, index, innerIndex) => {
  //   setIndexData({ index: index, innerIndex: innerIndex });
  //   setDocTypeName(e);
  //   setDialogAddDocIsOpen(true);
  // };

  useEffect(() => {
    let complianceDocIDs = props?.indivisualData?.orgChklists
      ?.flat()
      .flatMap((checklist) =>
        checklist.compDocs.map((doc) => doc.compliance_doc_id)
      );
  }, [updateState, refetchData]);

  useEffect(() => {
    if (closePannel) {
      setClosePannel(false);
      onDialogAddDocClose();
    }
    // closePannel={setClosePannel}
  }, [closePannel]);

  // const HandleUpdateStatus = (approval_status) => {
  //   let payload = { ...statusData, ...{ approval_status: approval_status } };
  //   setButtonDisabled(true);
  //   dispatch(UpdateProviderDocStatus(payload))
  //     .then((response) => {
  //       if (response?.success) {
  //         props?.OnGetProviderDocsAgainstClient();
  //         setrefetchData(!refetchData);
  //         // SetDocStatus(approval_status);
  //         setButtonDisabled(false);
  //         setStatusData((prevalue) => {
  //           return {
  //             ...prevalue,
  //             ["message"]: "",
  //           };
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //       setButtonDisabled(false);
  //     })
  //     .finally(() => {
  //       setButtonDisabled(false);
  //     });
  // };

  // const HandleStatusChanges = (e) => {
  //   setStatusData((prevalue) => {
  //     return {
  //       ...prevalue,
  //       ["message"]: e.target.value,
  //     };
  //   });
  // };

  useEffect(() => {
    if (props?.invoicesData?.account_xero_invoice_id) {
      dispatch(
        GetInvoiceDetailsById({
          account_xero_invoice_id: props?.invoicesData?.account_xero_invoice_id,
        })
      ).then((response) => {
        if (response.success) {
          SetspecificData(response?.data);
        }
      });
    }
  }, [props?.invoicesData]);
  console.log("specificData::", specificData);
  let status = "";

  if (specificData.xero_invoice_status === "AUTHORISED") {
    const today = new Date();
    const endDate = new Date(specificData.end_date); // Assuming `item.end_date` is in a valid date format like "YYYY-MM-DD".

    // Calculate the difference in days
    const differenceInTime = endDate.getTime() - today.getTime();
    const remainingDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Convert milliseconds to days

    if (remainingDays > 0) {
      status = "Due";
    } else {
      status = "Overdue";
    }
  }
  return (
    <React.Fragment>
      <div className="flex h-full">
        <div className="left-block-wrap h-full w-1/4">
          <Tabs defaultValue="tab1" variant="pill">
            <TabList
              className="bg-gray-500 rounded-lg p-1"
              onClick={(val) => handleTabClick(val)}
            >
              <TabNav value="tab1">Details</TabNav>
              <TabNav value="tab2">History</TabNav>
            </TabList>
            <div className="h-cal-full ">
              <Scrollbar>
                <div className="">
                  <TabContent value="tab1">
                    <div className="mb-4">
                      <div className="font-bold text-white text-xl mb-3 mt-3">
                        {specificData?.xero_invoice_number
                          ? specificData?.xero_invoice_number
                          : "-"}
                      </div>

                      <hr className="border-t border-gray-400 my-5" />
                      {/* <div className="font-bold text-white text-xl mb-1">
                        <span></span>
                        <div className="mb-4">
                          <p className="text-white font-semibold">
                            {specificData?.doc_name}
                          </p>
                        </div>
                      </div> */}
                      <div className="mb-4">
                        <label className="text-gray-100 text-12 font-medium">
                          Recipient
                        </label>
                        <div className="text-white">
                          {"-"}
                        </div>
                      </div>
                    </div>
                    {/* <div className="mb-4">
                      <label className="text-gray-100 text-12 font-medium">
                        Payee
                      </label>
                      <p className="text-white">
                        {specificData?.payee ? specificData?.payee : "-"}
                      </p>
                    </div> */}
                    <div className="mb-4">
                      <label className="text-gray-100 text-12 font-medium">
                        Email address
                      </label>
                      <p className="text-white font-semibold">
                        {specificData?.email ? specificData?.email : "-"}
                      </p>
                    </div>

                    <div className="mb-4">
                      <label className="text-gray-100 text-12 font-medium">
                        Issue date
                      </label>
                      <div className="text-white">
                        {specificData?.created_date ? (
                          <label className="text-gray-100">
                            {moment(specificData?.created_date).format(
                              "DD/MM/YYYY"
                            )}{" "}
                            at{" "}
                            {moment(specificData?.created_date).format("HH:mm")}
                          </label>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="text-gray-100 text-12 font-medium">
                        Due date
                      </label>
                      <div className="text-white">
                        {specificData?.end_date ? (
                          <label className="text-white">
                            {moment(specificData?.end_date).format(
                              "DD/MM/YYYY"
                            )}{" "}
                            at {moment(specificData?.end_date).format("HH:mm")}
                          </label>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="text-gray-100 text-12 font-medium">
                        Status
                      </label>
                      <div>
                        {specificData.xero_invoice_status === "Paid" && (
                          <Tag className="bg-success-50 text-success-700">
                            <span>
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 11 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.39355 0.981445L3.89355 6.48145L1.39355 3.98145"
                                  stroke="#3DB77E"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>{" "}
                            Paid
                          </Tag>
                        )}
                        {status === "Overdue" && (
                          <Tag className="bg-error-50 text-error-700">
                            <span>
                              <svg
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_7446_51239)">
                                  <path
                                    d="M6.39355 4.98145V6.98145M6.39355 8.98145H6.39855M1.39355 5.24282V8.72007C1.39355 8.84237 1.39355 8.90352 1.40737 8.96106C1.41962 9.01208 1.43982 9.06085 1.46723 9.10559C1.49816 9.15605 1.54139 9.19928 1.62787 9.28576L4.08924 11.7471C4.17572 11.8336 4.21895 11.8768 4.26941 11.9078C4.31415 11.9352 4.36292 11.9554 4.41394 11.9676C4.47148 11.9814 4.53263 11.9814 4.65493 11.9814H8.13218C8.25448 11.9814 8.31563 11.9814 8.37317 11.9676C8.42419 11.9554 8.47296 11.9352 8.5177 11.9078C8.56816 11.8768 8.61139 11.8336 8.69787 11.7471L11.1592 9.28576C11.2457 9.19928 11.289 9.15605 11.3199 9.10559C11.3473 9.06085 11.3675 9.01208 11.3797 8.96106C11.3936 8.90352 11.3936 8.84237 11.3936 8.72007V5.24282C11.3936 5.12052 11.3936 5.05937 11.3797 5.00183C11.3675 4.95081 11.3473 4.90204 11.3199 4.8573C11.289 4.80684 11.2457 4.76361 11.1592 4.67713L8.69787 2.21576C8.61139 2.12928 8.56816 2.08605 8.5177 2.05513C8.47296 2.02771 8.42419 2.00751 8.37317 1.99526C8.31563 1.98145 8.25448 1.98145 8.13218 1.98145H4.65493C4.53263 1.98145 4.47148 1.98145 4.41394 1.99526C4.36292 2.00751 4.31415 2.02771 4.26941 2.05513C4.21895 2.08605 4.17572 2.12928 4.08924 2.21576L1.62787 4.67713C1.54139 4.76361 1.49816 4.80684 1.46723 4.8573C1.43982 4.90204 1.41962 4.95081 1.40737 5.00183C1.39355 5.05937 1.39355 5.12052 1.39355 5.24282Z"
                                    stroke="#F04438"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_7446_51239">
                                    <rect
                                      width="12"
                                      height="12"
                                      fill="white"
                                      transform="translate(0.393555 0.981445)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>{" "}
                            Overdue
                          </Tag>
                        )}
                        {(status == "Upcoming" || status == "Due") && (
                          <Tag className="bg-warning-50 text-warning-700">
                            <span>
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_18999_70113)">
                                  <path
                                    d="M11.35 5.75L10.3503 6.75L9.34998 5.75M10.4725 6.5C10.4907 6.33583 10.5 6.169 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C3.51472 1.5 1.5 3.51472 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5C7.41363 10.5 8.67502 9.84817 9.5 8.82867M6 3.5V6L7.5 7"
                                    stroke="#F79009"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_18999_70113">
                                    <rect width="12" height="12" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>{" "}
                            {status}
                          </Tag>
                        )}
                        {specificData.xero_invoice_status === "DRAFT" && (
                          <Tag className="bg-blue-100 text-blue-700">
                            <span>
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_18999_37916)">
                                  <path
                                    d="M1.25 10.7502L4.02463 9.68299C4.2021 9.61473 4.29084 9.5806 4.37386 9.53603C4.4476 9.49645 4.5179 9.45077 4.58402 9.39946C4.65846 9.34169 4.72569 9.27447 4.86014 9.14001L10.5 3.50015C11.0523 2.94787 11.0523 2.05244 10.5 1.50015C9.94773 0.947867 9.0523 0.947866 8.50001 1.50015L2.86014 7.14001C2.72569 7.27446 2.65846 7.34169 2.6007 7.41613C2.54939 7.48225 2.5037 7.55255 2.46412 7.62629C2.41955 7.70931 2.38542 7.79805 2.31717 7.97552L1.25 10.7502ZM1.25 10.7502L2.27906 8.07463C2.3527 7.88317 2.38952 7.78744 2.45267 7.74359C2.50786 7.70527 2.57615 7.69078 2.64215 7.70338C2.71767 7.7178 2.79019 7.79033 2.93524 7.93538L4.06479 9.06493C4.20984 9.20997 4.28236 9.2825 4.29678 9.35802C4.30939 9.42401 4.29489 9.49231 4.25657 9.5475C4.21272 9.61065 4.11699 9.64747 3.92553 9.72111L1.25 10.7502Z"
                                    stroke="#0E4E9A"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_18999_37916">
                                    <rect width="12" height="12" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                            Draft
                          </Tag>
                        )}
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="text-gray-100 text-12 font-medium">
                        Message
                      </label>
                      <div className="text-white">
                        <p className="semi-bold">
                          {specificData?.manual_invoice_note
                            ? specificData?.manual_invoice_note
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </TabContent>
                  <TabContent value="tab2">
                    {allHistoryData && allHistoryData?.length > 0 ? (
                      allHistoryData?.map((item, index) =>
                        item?.desc_html.map((innerItem, index) => {
                          let documetnName;
                          if (item?.new_value.doc_file) {
                            documetnName = item?.new_value.doc_file.split("-");
                            const partAfterThirdHyphen = documetnName
                              .slice(4)
                              .join("-");
                            // Decode the URL component to get the actual data
                            documetnName =
                              decodeURIComponent(partAfterThirdHyphen);
                          } else {
                            documetnName = "";
                          }
                          let parser = new DOMParser();
                          let doc = parser.parseFromString(
                            innerItem,
                            "text/html"
                          );
                          return (
                            <React.Fragment>
                              {item?.column_names?.includes("doc_file") ? (
                                <div className="mb-4  pb-4">
                                  <label className="text-gray-100">
                                    {moment(item?.updated_date).format(
                                      "DD MMMM YYYY [at] HH:mm"
                                    )}
                                  </label>
                                  <p className="font-semibold text-white mt-2">
                                    {doc.body.textContent}
                                  </p>
                                  <div className=" p-4 rounded-lg bg-white font-medium mt-2 flex">
                                    <span className="w-9 h-9 rounded-full bg-blue-50 border-4 border-blue-100 flex justify-center items-center">
                                      <svg
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.8 14.692H4.62c-.403 0-.605 0-.698-.08a.333.333 0 0 1-.116-.28c.01-.122.152-.264.438-.55l5.668-5.669c.264-.264.396-.396.549-.445a.667.667 0 0 1 .412 0c.152.05.284.181.548.445L14 10.693v.8m-3.2 3.2c1.12 0 1.68 0 2.108-.219a2 2 0 0 0 .874-.874c.218-.428.218-.987.218-2.108m-3.2 3.2H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 13.172 2 12.613 2 11.492v-5.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v5.6M7 6.36a1.333 1.333 0 1 1-2.667 0A1.333 1.333 0 0 1 7 6.36z"
                                          stroke="#5786CC"
                                          strokeWidth="1.333"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    <div className="ml-3">
                                      <span className="font-medium text-gray-700 text-xs">
                                        {documetnName}
                                      </span>
                                      <p className="text-gray-600 text-xs">
                                        {(
                                          item?.new_value?.file_size / 1024
                                        ).toFixed(2)}{" "}
                                        KB
                                      </p>
                                    </div>
                                    <Button className="ml-auto">
                                      <a
                                        href={item?.new_value.doc_file}
                                        download
                                      >
                                        <svg
                                          width="20"
                                          height="21"
                                          viewBox="0 0 20 21"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M17.5 8.458v-5m0 0h-5m5 0-6.667 6.667m-2.5-5H6.5c-1.4 0-2.1 0-2.635.272A2.5 2.5 0 0 0 2.772 6.49C2.5 7.024 2.5 7.725 2.5 9.125v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h5.333c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.093-1.093c.272-.535.272-1.235.272-2.635v-1.833"
                                            stroke="#475467"
                                            strokeWidth="1.667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </a>
                                    </Button>
                                  </div>
                                </div>
                              ) : item?.column_names?.includes("message") ? (
                                <div className="mb-4  pb-4">
                                  <label className="text-gray-100">
                                    {moment(item?.updated_date).format(
                                      "DD MMMM YYYY [at] HH:mm"
                                    )}
                                  </label>
                                  <p className="font-semibold text-white mt-2">
                                    {doc.body.textContent}
                                  </p>
                                  {item?.new_value.message && (
                                    <div className=" p-4 rounded-lg bg-white font-medium mt-2 flex">
                                      {/* <span className="w-9 h-9 rounded-full bg-blue-50 border-4 border-blue-100 flex justify-center items-center">
                                      </span> */}
                                      <div className="ml-3">
                                        <span className="font-medium text-gray-700 text-xs">
                                          {item?.new_value.message}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div
                                  key={index}
                                  className="mb-4 border-b border-gray-500 pb-4"
                                >
                                  <label className="text-gray-100">
                                    {moment(item?.updated_date).format(
                                      "DD MMMM YYYY [at] HH:mm"
                                    )}
                                  </label>
                                  <p className="font-semibold text-white mt-2">
                                    {doc.body.textContent}
                                  </p>
                                </div>
                              )}
                            </React.Fragment>
                          );
                        })
                      )
                    ) : (
                      <div className="flex items-center justify-center h-full">
                        <p className="text-white font-medium mt-4">
                          No history available!
                        </p>
                      </div>
                    )}
                  </TabContent>
                </div>
              </Scrollbar>
            </div>
          </Tabs>
        </div>
        {/* <div className="right-block-wrap h-full w-4/5">
          <div className="doc-view pdf_view_scroll">
            {specificData && specificData?.doc_file && pdfFile && (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={pdfFile}
                  plugins={[defaultLayoutPluginInstance]}
                  theme="dark"
                />
              </Worker>
            )}
          </div>
        </div> */}
        <div className="right-block-wrap h-full w-4/5">
          <div className="doc-view pdf_view_scroll">
            {pdfFile && (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={pdfFile}
                  plugins={[defaultLayoutPluginInstance]}
                  theme="dark"
                />
              </Worker>
            )}
          </div>
        </div>
      </div>
      {/* <Dialog
        isOpen={deleteDiolog}
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            Are you sure you want to delete document?
          </p>
        </div>

        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight className="mr-1.5" onClick={() => setDeleteDiolog(false)}>
            Cancel
          </BtnLight>
          <BtnPrimary
            className="ml-1.5"
            onClick={() => deleteModuleConfirmation()}
          >
            Delete
          </BtnPrimary>
        </div>
      </Dialog> */}
    </React.Fragment>
  );
};
export default ViewInvoice;
