import React, { useEffect, useState } from "react";
import Radio from "../../../../../../../../common/element/Radio";
import { UpdateSiteInductionModuleData } from "../../../../../../../../redux/classes/Admin-Client/SiteInductionModuleSlice";
import { useDispatch, useSelector } from "react-redux";
import Upload from "../../../../../../../../common/element/Upload";
import ClientInput from "../../../../../../../../common/element/Input/clientInput";
import Label from "../../../../../../../../common/element/Label";
import { pdfjs } from "react-pdf";
import { UpdateInductionStatusSite } from "../../../../../../../../redux/classes/Admin-Client/updatInductionStatusSite";
import { DeletedocumentDataSite } from "../../../../../../../../redux/classes/Admin-Client/deleteDocumentSite";
import { closeDocumentfuncSite } from "../../../../../../../../redux/classes/Admin-Client/closeDocumentSite";
import { object } from "prop-types";
import { notify } from "react-notify-toast";
import { DeleteCompanyIndModuleFileById } from "../../../../../../../../actions/Admin-Client/AllCompanyInduction";

import { Tooltip } from "@material-ui/core";
import { GetSignedUrlByKey } from "../../../../../../../../actions/public/GetSignedUrlByKey";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PdfFile = (props) => {
  const { SiteInductionModuleData } = useSelector((state) => state);
  const [checkPdf, SetcheckPdf] = useState("");
  const [Pdfdata, Setpdfdata] = useState();
  const [PDFPagError, SetPDFPagError] = useState("");
  const [checkPages, SetcheckPages] = useState(false);
  const [pdfUpdate, SetpdfUpdate] = useState(true);
  const [fileSize, SetfileSize] = useState({
    size: "",
    name: "",
  });
  const [sizeError, SetsizeError] = useState("");
  const [pdfPages, SetPdfPages] = useState({
    startPage: null,
    endPage: null,
    totalPage: null,
  });
  const dispatch = useDispatch();
  function getAllPDfPages(file) {
    if (typeof file == "object") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        try {
          const pdfDocument = await pdfjs.getDocument({ data: arrayBuffer })
            .promise;
          const totalPages = pdfDocument.numPages;
          SetPdfPages((prevvalue) => {
            return {
              ...prevvalue,
              ["totalPage"]: totalPages,
            };
          });
        } catch (error) {
          console.error("Error loading PDF:", error);
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", file, true);
      xhr.responseType = "arraybuffer";

      xhr.onload = async () => {
        if (xhr.status === 200) {
          const arrayBuffer = xhr.response;
          try {
            const pdfDocument = await pdfjs.getDocument({ data: arrayBuffer })
              .promise;
            const totalPages = pdfDocument.numPages;
            SetPdfPages((prevvalue) => {
              return {
                ...prevvalue,
                ["totalPage"]: totalPages,
              };
            });
          } catch (error) {
            console.error("Error loading PDF:", error);
          }
        } else {
          console.error("Failed to load PDF. Status code:", xhr.status);
        }
      };

      xhr.onerror = () => {
        console.error("Network error while trying to load the PDF.");
      };
      xhr.send();
    }
  }
  const handleFileChange = (e) => {
    const regex = /[!@#$%^&*(),.?":{}|<>]/;

    // if (e[e.length - 1].name.slice(0, -4).includes(" ")) {
    // } else {
    // }

    // Use the test method of the regular expression to check if the string contains special characters

    if (
      !regex.test(e[e.length - 1].name.slice(0, -4)) &&
      !e[e.length - 1].name.slice(0, -4).includes(" ")
    ) {
      dispatch(UpdateInductionStatusSite(true));
      if (e[e.length - 1].size <= 25 * 1024 * 1024) {
        SetcheckPdf(e[e.length - 1]);
        const file = e[e.length - 1];
        if (file) {
          getAllPDfPages(file);
        }

        Setpdfdata({
          ...Pdfdata,
          content_info_type: "file",
          site_induction_file: file,
        });
        SetsizeError("");
      } else {
        SetsizeError("File size exceeds the maximum limit of 25MB.");
      }
    } else {
      SetsizeError(
        "Containing special characters and white space is not allowed in PDF names."
      );
    }
  };

  useEffect(() => {
    Setpdfdata(SiteInductionModuleData[props.indexData]);
    if (
      SiteInductionModuleData[props.indexData]?.pdf_page_range?.hasOwnProperty(
        "totalPage"
      )
      // &&
      // SiteInductionModuleData[props.indexData]?.pdf_page_range?.startPage != ""
    ) {
      SetcheckPages(true);
      SetPdfPages({
        startPage:
          SiteInductionModuleData[props.indexData].pdf_page_range.startPage,
        endPage:
          SiteInductionModuleData[props.indexData].pdf_page_range.endPage,
        totalPage:
          SiteInductionModuleData[props.indexData].pdf_page_range.totalPage,
      });
    } else {
      // SetcheckPages(false);
      SetPdfPages({
        startPage: "",
        endPage: "",
        totalPage: "",
      });
    }
    if (
      SiteInductionModuleData[props.indexData]?.site_induction_file != undefined
    ) {
      SetfileSize({
        // size: "",
        name: "",
      });
      SetcheckPdf(
        SiteInductionModuleData[props.indexData]?.site_induction_file
      );
    } else {
      if (SiteInductionModuleData?.[props.indexData]?.content_info) {
        dispatch(
          GetSignedUrlByKey(
            { url: SiteInductionModuleData[props.indexData]?.content_info },
            "admin/client"
          )
        ).then((response) => {
          if (response.success) {
            // setPdfFile(response?.data);
            SetcheckPdf(response?.data);
          }
        });

        const parts =
          SiteInductionModuleData[props.indexData].content_info.split("-");
        const partAfterThirdHyphen = parts.slice(4).join("-");
        // Decode the URL component to get the actual data
        const decodedData = decodeURIComponent(partAfterThirdHyphen);
        SetfileSize({
          // size: 10,
          name: decodedData,
        });
      }

      // let fileName;
      // if (SiteInductionModuleData?.[props.indexData]?.content_info) {
      //   fileName = SiteInductionModuleData?.[props.indexData]?.content_info;
      // }
      // // Get the file extension
      // let fileExtension;
      // // Check if it's a PDF
      // if (fileName) {
      //   fileExtension = fileName?.split(".").pop().toLowerCase();
      // }
      // if (fileExtension === "pdf") {
      //   fetch(SiteInductionModuleData[props.indexData].content_info)
      //     .then((response) => response.blob())
      //     .then((blob) => {
      //       const sizeKB = Math.round(blob.size / 1024);
      //       const parts =
      //         SiteInductionModuleData[props.indexData].content_info.split("-");

      //       const partAfterThirdHyphen = parts.slice(4).join("-");
      //       // Decode the URL component to get the actual data
      //       const decodedData = decodeURIComponent(partAfterThirdHyphen);
      //       let FileName;
      //       if (parts.length >= 3) {
      //         FileName = parts[parts.length - 1];
      //         FileName = decodedData;
      //       }
      //       SetfileSize({
      //         size: sizeKB,
      //         name: FileName,
      //       });
      //     });
      // }
    }
  }, [SiteInductionModuleData[props.indexData]]);

  useEffect(() => {
    if (Pdfdata?.content_info_type) {
      dispatch(
        UpdateSiteInductionModuleData({
          indexData: props.indexData,
          moduleData: Pdfdata,
        })
      );
    }
  }, [Pdfdata]);

  useEffect(() => {
    if (checkPages == true && pdfUpdate) {
      SetpdfUpdate(false);
      Setpdfdata({
        ...Pdfdata,
        pdf_page_range: pdfPages,
      });
    }
  }, [pdfPages]);

  const handleDeletePDF = () => {
    dispatch(closeDocumentfuncSite(true));
    if (
      Pdfdata?.module_id &&
      Pdfdata?.content_info_type == "file" &&
      Pdfdata?.content_info
    ) {
      dispatch(
        DeleteCompanyIndModuleFileById({
          module_id: Pdfdata.module_id,
          content_info: Pdfdata.content_info,
        })
      );
    }
    dispatch(UpdateInductionStatusSite(true));
    SetcheckPdf("");
    Setpdfdata({
      ...Pdfdata,
      content_info_type: "",
      site_induction_file: "",
      content_info: "",
      pdf_page_range: {},
    });
    let UpdateData = {
      ...Pdfdata,
      content_info_type: "",
      site_induction_file: "",
      content_info: "",
      pdf_page_range: {},
    };
    if (Pdfdata) {
      dispatch(
        UpdateSiteInductionModuleData({
          indexData: props.indexData,
          moduleData: UpdateData,
        })
      );
    }
  };
  // PDF Page
  const handleInput = (e) => {
    SetpdfUpdate(true);
    dispatch(UpdateInductionStatusSite(true));
    SetPdfPages((prevvalue) => {
      return {
        ...prevvalue,
        [e.target.name]: e.target.value,
      };
    });
    SetPDFPagError("");
    if (e.target.name == "startPage") {
      if (
        !(
          parseInt(e.target.value) >= 1 &&
          parseInt(e.target.value) <= pdfPages.totalPage
        )
      ) {
        SetPDFPagError("Invalid page numbers. Please enter valid numbers.");
      }
    } else {
      if (
        !(
          parseInt(e.target.value) <= pdfPages.totalPage &&
          parseInt(e.target.value) >= pdfPages.startPage
        )
      ) {
        SetPDFPagError("Invalid page numbers. Please enter valid numbers.");
      }
    }
  };
  useEffect(() => {
    // SetcheckPdf(Pdfdata.module_data ? Pdfdata.module_data.site_induction_file : Pdfdata?.site_induction_file);
    if (Pdfdata?.site_induction_file != undefined) {
      SetcheckPdf(Pdfdata?.site_induction_file);
    } else {
      SetcheckPdf(Pdfdata?.content_info);
    }

    if (
      Pdfdata?.pdf_page_range?.hasOwnProperty("totalPage") &&
      Pdfdata?.pdf_page_range?.startPage != ""
    ) {
      SetcheckPages(true);
      SetPdfPages({
        startPage: Pdfdata.pdf_page_range.startPage,
        endPage: Pdfdata.pdf_page_range.endPage,
        totalPage: Pdfdata.pdf_page_range.totalPage,
      });
    } else {
      SetcheckPages(false);
      SetPdfPages({
        startPage: "",
        endPage: "",
        totalPage: "",
      });
    }
  }, []);

  // useEffect(() => {
  //   if (checkPages == true) {
  //     Setpdfdata({
  //       ...Pdfdata,
  //       pdf_page_range: pdfPages,
  //     });
  //   }
  // }, [pdfPages]);

  // Pages check
  const handlePagesCheck = (e) => {
    if (!e.target.checked) {
      SetPdfPages({
        startPage: null,
        endPage: null,
        totalPage: null,
      });
    } else {
      // getAllPDfPages(Pdfdata.company_ind_file);
      if (
        Pdfdata?.company_ind_file == undefined ||
        Pdfdata?.content_info ||
        Pdfdata?.company_ind_file?.length
      ) {
        getAllPDfPages(
          Pdfdata?.company_ind_file == undefined
            ? Pdfdata?.content_info
            : Pdfdata?.company_ind_file
        );
      }
    }
    SetcheckPages(!checkPages);
  };
  return (
    <React.Fragment>
      <div className="flex w-full gap-5 items-center relative">
        <Tooltip placement={"right-start"} title={"Remove module content"}>
          <button
            onClick={() => handleDeletePDF()}
            className="modue_creation_close_icon absolute right-0 bottom-full"
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m18 6.5-12 12m0-12 12 12"
                stroke="#667085"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </Tooltip>
        <div className="flex items-center justify-between w-2/4">
          {/* {checkPdf?.length == 0 && ( */}
          {(checkPdf == undefined ? true : checkPdf?.length == 0) ? (
            <Upload
              accept={".pdf"}
              onChange={handleFileChange}
              showList={false}
              draggable
              // value={Pdfdata.site_induction_file}
              className="w-full"
            >
              <div className="text-center">
                <div className="flex items-center justify-center flex-col">
                  <span className="flex w-9 h-9 items-center justify-center rounded-full bg-gray-200 outline outline-8 outline-gray-50 outline-offset-0">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                        stroke="#475467"
                        strokeWidth="1.667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                  <span className="pt-4">
                    <span className="text-blue-700 font-semibold mr-1">
                      Click to upload
                    </span>{" "}
                    <span className="text-xs text-gray-600">
                      {" "}
                      or drag and drop ( max. 25mb)
                    </span>
                  </span>
                </div>
              </div>
              {sizeError && (
                <div className="text-red-500 text-sm mt-1">{sizeError}</div>
              )}
            </Upload>
          ) : (
            // {checkPdf?.length != 0 && (
            <React.Fragment>
              <div className="p-4 rounded-xl border border-gray-300 w-full flex items-center justify-between">
                <div className="flex  gap-4 items-center">
                  <span className="w-9 h-9 rounded-full border-4 border-blue-50 bg-blue-200 flex justify-center items-center">
                    <img src="/assets/icons-svg/upload-pdf.svg" alt="" />
                  </span>
                  <div className="flex flex-col items-center">
                    <span className="text-gray-700 text-xs font-medium">
                      {/* {checkPdf?.name} */}
                      {checkPdf?.name ? checkPdf?.name : fileSize.name}
                    </span>
                    {/* <span className="text-gray-600 text-xs">
                  {checkPdf?.size ? checkPdf?.size :props?.size?.size} KB
                    {checkPdf?.size}KB
                  </span> */}
                  </div>
                </div>
                <button onClick={handleDeletePDF}>
                  <svg
                    width="20"
                    height="19"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                      stroke="#344054"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
        {checkPdf?.length != 0 && (
          <div className="flex items-center flex-wrap">
            <Radio
              onClick={(e) => handlePagesCheck(e)}
              className="mr-4 font-medium text-gray-700 flex items-center gap-2"
              checked={checkPages}
              name="simpleRadioExample"
            >
              Pages
            </Radio>
            {checkPages && (
              <React.Fragment>
                <div className="flex flex-col gap-1 mr-3">
                  <Label>To</Label>
                  <ClientInput
                    className={"w-20"}
                    value={pdfPages.startPage}
                    name={"startPage"}
                    inputchangehandler={(e) => {
                      handleInput(e);
                    }}
                    placeholder=""
                    type="number"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <Label>From</Label>
                  <ClientInput
                    className={"w-20"}
                    value={pdfPages.endPage}
                    name={"endPage"}
                    inputchangehandler={(e) => {
                      handleInput(e);
                    }}
                    placeholder=""
                    type="number"
                  />
                </div>

                {PDFPagError && (
                  <div className="text-red-500 text-sm mt-1">{PDFPagError}</div>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default PdfFile;
