// Import necessary libraries
import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';

// Register required components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const LineChart = () => {
  // Define data for chart
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Active',
        data: [40, 50, 60, 65, 70, 80, 85, 90, 92, 93, 95, 100],
        borderColor: '#86E5B2',
        backgroundColor: 'rgba(134, 229, 178, 0.3)',
        pointBackgroundColor: '#3DB77E',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
        pointHoverRadius: 7,
        fill: true,
        lineTension: 0.4,
        borderWidth: 2,
      },
      {
        label: 'Inactive',
        data: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75],
        borderColor: '#98A2B3',
        backgroundColor: 'rgba(152, 162, 179, 0.3)',
        pointBackgroundColor: '#6C757D',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
        pointHoverRadius: 7,
        fill: true,
        lineTension: 0.4,
        borderWidth: 2,
      },
    ],
  };

  // Define chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Disable aspect ratio to allow custom sizing
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw}`,
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: { font: { size: 12 } },
      },
      y: {
        beginAtZero: true,
        ticks: { font: { size: 12 } },
      },
    },
  };

  return (
    <div
      style={{
        width: '100%',
        height: '400px', // Set height of the chart container
        margin: '0 auto',
        paddingTop: '60px',
        position: 'relative',
      }}
    >
      {/* Title on the left side */}
      <div style={{ position: 'absolute', top: '0', left: '0' }}>
        <span className="text-gray-600 font-medium text-xs">Total Clients</span>
        <div className="text-gray-600 font-semibold text-sm">00000</div>
      </div>

      {/* Custom Legend on the right side */}
      <div
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          display: 'flex',
          gap: '30px',
          alignItems: 'center',
        }}
      >
        <div className="flex gap-3">
          <div
            className="mt-2"
            style={{ width: '8px', height: '8px', backgroundColor: '#3DB77E', borderRadius: '50%' }}
          ></div>
          <div>
            <span className="text-gray-600 font-medium text-xs">Active</span>
            <div className="text-gray-600 font-semibold text-sm">00000</div>
          </div>
        </div>
        <div className="flex gap-3">
          <div
            className="mt-2"
            style={{ width: '8px', height: '8px', backgroundColor: '#6C757D', borderRadius: '50%' }}
          ></div>
          <div>
            <span className="text-gray-600 font-medium text-xs">Inactive</span>
            <div className="text-gray-600 font-semibold text-sm">00000</div>
          </div>
        </div>
      </div>

      {/* Chart */}
      <div style={{ width: '100%', height: '100%' }}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default LineChart;
