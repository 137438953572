import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/client-portal/getAllComplianceSlice";
export const GetIndividualData = (getData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      // dispatch(fetchData());
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/client/GetCompanyInductionModuleById`,
        params: {module_id:typeof(getData)=='string'?getData:getData.module_id}
      });
      // dispatch(fetchDataSuccess(response.data));
      return response.data
      
    } catch (error) { 
      return error.message
      // dispatch(fetchDataError(error.message));
    }
  };