import axios from "axios";
import * as actionType from "../../constant/actionType";

export const CreateSiteInduction = (APIData, ipAddress) => async (dispatch) => {
  try {
    const response = await axios({
      method: "post",
      data: APIData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        'X-Client-IP': ipAddress, // Add IP address here
      },
      url: `${actionType.environment_url}/api/admin/client/CreateSiteInduction`,
    });
    return response.data;
  } catch (error) {
    console.error('Error creating site induction:', error);
    return { success: false, message: error.message }; // Return structured error
  }
};




export const DashboardAllClientsCompanyInd = () => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DashboardAllClientsCompanyInd`,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return { success: false, error: error.message };
  }
};
export const DashboardClientCompanyInductions = (apiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      params: { function_assignment_id: apiData },
      url: `${actionType.environment_url}/api/admin/client/DashboardClientCompanyInductions`,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return { success: false, error: error.message };
  }
};

export const DashboardGetAllClientsStatusChart = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DashboardGetAllClientsStatusChart`,
      params: apiData,
    });
    return response.data
    //   dispatch(fetchDataSuccess(response.data));
  } catch (error) {
    return error.message
    //   dispatch(fetchDataError(error.message));
  }
};


export const CreateSiteInductionModule = (ApiData) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in ApiData) {
      if (key === "site_induction_file ") {
        formdata.append("site_induction_file ", ApiData.site_induction_file);
      } else {
        if (key === "module_ques_data" || key === "pdf_page_range") {
          formdata.append(key, JSON.stringify(ApiData[key]));
        } else {
          if (key === "content_info_type") {
            if (ApiData.content_info_type !== "") {
              formdata.append(key, ApiData[key]);
            }
          } else {
            formdata.append(key, ApiData[key]);
          }
        }
      }
    }

    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/CreateSiteInductionModule`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const TwoFactorAuth = (APIData) => async (dispatch) => {
  try {
    // dispatch(fetchData());
    const response = await axios({
      method: "patch",
      data: APIData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/TwoFactorAuth`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};
export const GetAllInductionsOfSpecificSite =
  (getAllData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/GetAllInductionsOfSpecificSite`,
        params: getAllData,
      });
      return response.data;
    } catch (error) { }
  };

export const GetIndividualSiteIndData = (getData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    // dispatch(fetchData());
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/GetSiteInductionModuleById`,
      params: { module_id: getData.module_id },
    });
    // dispatch(fetchDataSuccess(response.data));
    return response.data;
  } catch (error) {
    return error.message;
    // dispatch(fetchDataError(error.message));
  }
};

export const GetSiteInductionAndModulesbyIdForAdmin =
  (getAllData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/GetSiteInductionAndModulesbyId`,
        params: { site_induction_id: getAllData },
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

export const GetSiteInductionModuleById = (getData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/GetSiteInductionModuleById`,
      params: { module_id: getData.module_id },
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const UpdateSiteInductionData = (ApiData, ipAddress) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in ApiData) {
      if (
        key === "SiteIndCompDocIds" ||
        key === "SiteIndIndustryIds" ||
        key === "doctType_id_data" ||
        key === "master_setting_id_data"
      ) {
        // Append array or object data as JSON string
        formdata.append(key, JSON.stringify(ApiData[key]));
      } else {
        // Append other properties directly
        formdata.append(key, ApiData[key]);
      }
    }

    const response = await axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        'X-Client-IP': ipAddress, // Add IP address here
        'Content-Type': 'multipart/form-data', // Ensure correct content type
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateSiteInduction`,
    });

    return response.data;
  } catch (error) {
    console.error('Error updating site induction:', error);
    return { success: false, message: error.message }; // Structured error handling
  }
};


export const UpdateSiteInductionModuleForAdmin = (ApiData) => async (dispatch) => {
  try {
    // dispatch(fetchData());
    let formdata = new FormData();
    for (let key in ApiData) {
      if (key === 'site_induction_file') {
        formdata.append('site_induction_file', ApiData.site_induction_file);
      } else {
        if (key === 'module_ques_data' || key === "pdf_page_range") {
          // Assuming you want to stringify the array as JSON
          formdata.append(key, JSON.stringify(ApiData[key]));
        }
        else {
          // For other properties, append the value directly
          if (key === "content_info_type") {
            if (ApiData.content_info_type !== "" && ApiData.content_info_type !== null) {
              formdata.append(key, ApiData[key]);
            }
          } else {
            formdata.append(key, ApiData[key]);
          }
        }
      }
    }

    const response = await axios({
      method: 'put',
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateSiteInductionModule`,
    });
    // dispatch(fetchDataSuccess(response.data));
    return response.data
  } catch (error) {
    return error.message
    // dispatch(fetchDataError(error.message));
  }
}
export const UpdateSiteInductionStatusForAdmin =
  (ApiData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "put",
        data: ApiData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/UpdateSiteInductionStatus`,
      });
      // dispatch(fetchDataSuccess(response.data));
      return response.data;
    } catch (error) {
      return error.message;
      // dispatch(fetchDataError(error.message));
    }
  };

export const ExportInductionModulePdfRange = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'put',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/ExportInductionModulePdfRange`,
    });
    return response.data
  } catch (error) {
    return error.message
  }
}


export const DeleteSite = (siteId, siteName, ipAddress) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'delete',
      data: { site_id: siteId, site_name: siteName },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        'X-Client-IP': ipAddress, // Adding IP address in the header
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteSite`,
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting site:', error);
    return { success: false, message: error.message }; // Return structured error message
  }
};



export const DeleteSiteInduction = (apiData, ipAddress) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'delete',
      data: apiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        'X-Client-IP': ipAddress, // Add IP address here
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteSiteInduction`,
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting site induction:', error);
    return { success: false, message: error.message }; // Optional: structured error handling
  }
};

export const DeleteInducteeSiteDetails = (apiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: apiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteInducteeSiteDetails`,
    });
    return response.data;
  } catch (error) { }
};
export const DeleteCompanyIndModuleFileById = (data) => async (dispatch) => {
  // Final CSV Data
  try {
    let formdata = new FormData();
    for (let key in data) {
      formdata.append(key, data[key]);
    }
    const response = await axios({
      method: "delete",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteCompanyIndModuleFileById`,
    });
    return response.data;
  } catch (error) { }
};

export const DeleteCompanyIndModuleQuesAnsByid = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: { module_question_id: ApiData },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteCompanyIndModuleQuesAnsByid`,
    });
    return response.data;
  } catch (error) { }
};

export const DeleteCompanyIndModuleAnsByid = (ApiData) => async (dispatch) => {
  // Final CSV Data
  try {
    const response = await axios({
      method: "delete",
      data: { module_answer_id: ApiData },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteCompanyIndModuleAnsByid`,
    });
    return response.data;
  } catch (error) { }
};


export const DeleteSiteInductionModuleById = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteSiteInductionModuleById`,
    });
    return response.data;
  } catch (error) { }
};