import axios from "axios";
import * as actionType from "../../../constant/actionType";
export const GetAllProviderOrgList =
  (apiData) => async (dispatch) => {
    /*** API Call Action ***/

    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/provider/GetAllProviderOrgList`,
        params: apiData,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };


  
