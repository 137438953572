import axios from "axios";
import * as actionType from "../../constant/actionType";

export const GetProviderRegisSaveAndContinueById =
  (apidata) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      // dispatch(fetchData());
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/GetProviderRegisSaveAndContinueById`,
        params: apidata,
      });
      // dispatch(fetchDataSuccess(response.data));
        return response.data
      
      // return response.data
    } catch (error) {
      // dispatch(fetchDataError(error.message));
      return error.message
    }
  };
