import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setPermissionData } from "../../../../redux/classes/client-portal/permissionsSlice";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";
import { GetClientUserPermByIdForClient } from "../../../../actions/client-portal/AllUsersApi";
const Header = (props) => {
  const dispatch = useDispatch();
  const permissionDataUpdate = useSelector((state) => state?.permissions?.permissionData);
  const [forceRender, setForceRender] = useState(false);
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const { headerStart, headerEnd, headerMiddle, className, container } = props;

  // let function_assignment_id;
  // let user_id;
  // const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  // if (storedData !== null) {
  //   user_id = storedData?.userName?.user_id;
  //   function_assignment_id = storedData.function_assignment_id;
  // }

  // useEffect(() => {
  //   const fetchPermissions = async () => {
  //     const apiData = {
  //       user_id: user_id,
  //       function_assignment_id: function_assignment_id
  //     }
  //     dispatch(GetClientUserPermByIdForClient(apiData))
  //       .then(
  //         (response) => {
  //           if (response?.success === true) {
  //             // const arrayData = response.data;  
  //             localStorage.setItem("permission_data", JSON.stringify(response?.data))
  //             dispatch(setPermissionData(response?.data));
  //             setForceRender(prevForceRender => !prevForceRender);
  //           } else if (response.status === 401) {
  //             handleUnauthorizedAccess()
  //           }
  //         }
  //       );
  //   };
  //   fetchPermissions();
  // }, []);

  useEffect(() => {
    if (permissionDataUpdate !== null) {
      localStorage.setItem("permission_data", JSON.stringify(permissionDataUpdate));
      setForceRender(prevForceRender => !prevForceRender);
    }
  }, [permissionDataUpdate]);
  return (
    <header className={classNames("header", className)}>
      <div className="header-wrapper">
        <div className="header-action header-action-start">{headerStart}</div>
        {headerMiddle && (
          <div className="header-action header-action-middle">
            {headerMiddle}
          </div>
        )}
        <div className="header-action header-action-end">{headerEnd}</div>
      </div>
    </header>
  );
};

Header.propTypes = {
  headerStart: PropTypes.node,
  headerEnd: PropTypes.node,
  container: PropTypes.bool,
};

export default Header;
