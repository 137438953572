import React, { useState, useCallback, useRef, useEffect } from "react";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import Checkbox from "../../../../../../../common/element/Checkbox";
import Button from "../../../../../../../common/element/Buttons";
import { connect, useDispatch, useSelector } from "react-redux";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import { GetProviderDocTypeAgainstClientList } from "../../../../../../../actions/client-portal/AllComplinaceDocApi";
import * as userActions from "../../../../../../../actions/index";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { split } from "lodash";

const Dropdown = (props) => {
  let combinedFilterData;
  const dispatch = useDispatch();
  let secFilter;
  // const [selectFilterData, setSelectFilterData] = useState({ filterStatus: [] })
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();
  const [showAll, setShowAll] = useState(false);
  const [AcoOpen, setOpenAco] = useState();
  const [AcoOpen2, setOpenAco2] = useState();
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [allDocType, setAllDocType] = useState([])
  const statusMappings = [
    { back_status: "client_approval_req", front_status: "Action required" },
    { back_status: "client_approved_action", front_status: "Approved" },
    { back_status: ["client_reject", "admin_reject"], front_status: "Rejected" },
    { back_status: "pending", front_status: "Awaiting review" },
    { back_status: null, front_status: "Pending Upload" },
  ]

  // Get All list type Document
  const fetchDocumentTypeList = useCallback(() => {
    if (props?.client_org_id && props?.provider_org_id) {
      dispatch(GetProviderDocTypeAgainstClientList({ provider_org_id: props?.provider_org_id, client_org_id: props?.client_org_id }))
        .then((response) => {
          let filterData = response?.data?.map((item) => {
            return {
              value: item.document_type_id,
              label: item.doc_type_name,
            };
          });
          setAllDocType(filterData);
        });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchDocumentTypeList()
  }, [fetchDocumentTypeList, props?.client_org_id && props?.provider_org_id]);

  // Function to handle opening/closing accordions
  const handleOpen = (value) => {
    if (value === 1) {
      setOpenAco(AcoOpen === value ? 0 : value);
    } else if (value === 2) {
      setOpenAco2(AcoOpen2 === value ? 0 : value);
    }
  };

  const handleShowAllClick = () => {
    setShowAll(true);
  };

  const itemsToShow = showAll ? allDocType : allDocType?.slice(0, 1);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
        setShowAll(false)
        setOpenAco(0)
        setOpenAco2(0)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const clearFilter = () => {
    setSelectedItems([]);
    setSelectedStatus([]);
    setIsPending(false)
  };
  const applyFilter = () => {
    const filteredStatus = selectedStatus?.filter(status => status !== null && status !== undefined);
    props?.setApprovalStatus((prevState) => ({
      ...prevState,
      doc_type_name: selectedItems,
      approval_status: filteredStatus,
      isPendindUpload: isPending == true ? "true" : "false"
    }))
  }

  const handleCheckboxClick = (documentTypeId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(documentTypeId)) {
        return prevSelectedItems.filter((item) => item !== documentTypeId);
      } else {
        return [...prevSelectedItems, documentTypeId];
      }
    });
  };

  const handleCheckboxClick2 = (backStatus) => {
    
    if (backStatus == null) {
      if (isPending == true) {
        setIsPending(false)
      } else {
        setIsPending(true)
      }
    }

    setSelectedStatus((prevSelectedItems) => {
      let newSelectedItems = [...prevSelectedItems];
      if (Array.isArray(backStatus)) {
        // If backStatus is an array, handle it separately
        backStatus.forEach(status => {
          if (newSelectedItems.includes(status)) {
            newSelectedItems = newSelectedItems.filter((selectedItem) => selectedItem !== status);
          } else {
            newSelectedItems.push(status);
          }
        });
      } else {
        if (newSelectedItems.includes(backStatus)) {
          newSelectedItems = newSelectedItems.filter((selectedItem) => selectedItem !== backStatus);
        } else {
          newSelectedItems.push(backStatus);
        }
      }
      return newSelectedItems;
    });
  };
  return (
    <div ref={dropdownRef}>
      <BtnLight onClick={() => setOpen(!open)} className="w-9 h-9 flex items-center justify-center">
        <svg width="21" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.712 8h12m0 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0zm-6 8h12m-12 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </BtnLight>
      {open && (
        <div className="dropdown-content">
          <Accordion open={AcoOpen === 1}>
            <AccordionHeader
              className="reset-accordian-btn"
              onClick={() => handleOpen(1)}
            >
              <div className="flex items-center">
                <span className="text-gray-700 font-semibold block">
                  Document type
                </span>
              </div>
            </AccordionHeader>
            <AccordionBody className="accordion-body">
              {allDocType && allDocType.length > 0 && (
                itemsToShow.map((dataItem, index) => {
                  return (
                    <div className="checkbox-group" key={index}>
                      <Checkbox
                        onClick={() => handleCheckboxClick(dataItem?.value)}
                        checked={selectedItems.includes(dataItem?.value)}
                      >
                        {dataItem?.label ? dataItem.label : "-"}
                      </Checkbox>
                    </div>
                  );
                })
              )}
              {!showAll && itemsToShow && (
                <Button
                  className="ml-2.5 mt-5 text-blue-700 text-xs font-semibold hover:text-blue-900 focus:text-blue-700"
                  onClick={handleShowAllClick}
                >
                  Show all...
                </Button>
              )}
            </AccordionBody>

          </Accordion>
          <Accordion open={AcoOpen2 === 2}>
            <AccordionHeader
              className="reset-accordian-btn"
              onClick={() => handleOpen(2)}
            >
              <div className="flex items-center">
                <span className="text-gray-700 font-semibold block">
                  Status
                </span>
              </div>
            </AccordionHeader>
            <AccordionBody className="accordion-body">
              {statusMappings?.length > 0 && (
                statusMappings?.map((dataItem, index) => (
                  <div className="checkbox-group" key={index}>
                    <Checkbox
                      onClick={() => handleCheckboxClick2(dataItem?.back_status)}
                      checked={Array.isArray(dataItem?.back_status)
                        ? dataItem?.back_status.every(status => selectedStatus.includes(status))
                        : selectedStatus?.includes(dataItem?.back_status)}
                    >
                      {dataItem?.front_status}
                    </Checkbox>
                  </div>
                ))
              )}
            </AccordionBody>

          </Accordion>
          <hr className="border-gray-200 mb-6 mt-4" />
          <div className="flex gap-3 mt-3">
            <BtnLight onClick={clearFilter} >Clear all filter</BtnLight>
            <BtnPrimary onClick={applyFilter}>Apply filter</BtnPrimary>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
