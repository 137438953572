import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ContractorViewAll from "../pages/Provider/ViewAll";
import SpecificContractor from "../pages/Provider/SpecificContractor/SpecificContractor";
import Notes from "../pages/Provider/SpecificContractor/Notes";
import ClientSettings from "../pages/Client/SpecificClient/Settings";
import SpecificSite from "../pages/Client/SpecificSite/index";
import Dashboard from "../pages/Provider/Dashboard";
import Reports from "../pages/Provider/Reports";
import ProvidersOverview from "../pages/Provider/Reports/ProvidersOverview";
import ProvidersComplianceChecklist from "../pages/Provider/Reports/ProvidersComplianceChecklist";
import WorkersUnderProviders from "../pages/Provider/Reports/WorkersUnderProvider";
import ProvidersInvoices from "../pages/Provider/Reports/ProvidersInvoices";
import Invoices from "../pages/Provider/Invoices";
function useLocalStoragePath() {
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("currentPath", location.pathname);
  }, [location.pathname]);

  return location.pathname;
}

export const AdminPortalContractorRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="view-all-provider" element={<ContractorViewAll />} />
        <Route
          path="view-all-provider/specific-provider/:id/*"
          element={<SpecificContractor />}
        />
        <Route
          path="view-all-provider/specific-provider/:id/notes"
          element={<Notes />}
        />
        <Route
          path="view-all-provider/specific-provider/:id/settings"
          element={<ClientSettings />}
        />
        <Route
          path="view-all-provider/specific-provider/:id/specific-site"
          element={<SpecificSite />}
        />
        <Route path="reports" element={< Reports />} />
        <Route path="reports/providers-overview" element={<ProvidersOverview />} />
        
        <Route path="reports/compliance-checklist" element={<ProvidersComplianceChecklist />} />
        <Route path="reports/providers-invoices" element={<ProvidersInvoices />} />
        <Route path="reports/workers-under-providers" element={<WorkersUnderProviders />} />
        <Route path="invoices" element={<Invoices />} />
        
      </Routes>
    </React.Fragment>
  );
};
