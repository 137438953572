import React, { useEffect, useState } from "react";
import Dialog from "../../../../common/element/Dialog/Dialog";
import EditContactForm from "./EditContact";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Table from "../../../../common/element/Table";
import Tooltip from "../../../../common/element/Tooltip";
import { DeleteProviderContact } from "../../../../actions/admin-provider/DeleteContractorContact";
import { useDispatch, useSelector } from "react-redux";
import { GetProviderInternalContactById } from "../../../../actions/admin-provider/GetContractorInternalContactById";
import { deleteContact } from "../../../../redux/classes/provider-portal/GetAllContactsOfContractorPortalSlice";
import { notify } from "react-notify-toast";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";

const ContactsList = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [treashIsOpen, setTrashIsOpen] = useState(false);
  const [indivisualData, setindivisualData] = useState();
  const [index, setindex] = useState();
  const [deleteId, setdeleteId] = useState("");
  const [contactData, setcontactData] = useState(null);
  const { Tr, Th, Td, THead, TBody } = Table;
  const openDialog = (id) => {
    dispatch(GetProviderInternalContactById(id)).then((response) => {
      if (response.success) {
        setindivisualData(response.data);
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      }
    });
    setIsOpen(true);
  };
  const onDialogClose = (e) => {
    setIsOpen(false);
  };
  // Close Edit Form
  const CloseEditForm = () => {
    setIsOpen(false);
  };
  const openAlert = (id, index) => {
    setindex(index);
    setdeleteId(id);
    setTrashIsOpen(true);
  };
  const onAlertClose = (e) => {
    setTrashIsOpen(false);
  };
  const onDialogOk = (e) => {
    dispatch(DeleteProviderContact(deleteId))
      .then((response) => {
        if (response.success) {
          setTrashIsOpen(false);
          dispatch(deleteContact(index));
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {
        notify.show(error, "error");
      });
  };

  let { AllContactsOfContractorPortal } = useSelector((state) => state);
  useEffect(() => {
    setcontactData(AllContactsOfContractorPortal?.data?.data?.rows);
  }, [AllContactsOfContractorPortal]);
  let checkClient = props.getAllinvitedData.type == "clientContact";

  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() =>
                    props.handleSort(
                      props.getAllinvitedData.type == "clientContact"
                        ? "contact_first_name"
                        : "first_name"
                    )
                  }
                >
                  Contact name
                  <svg
                    className={`transform ${props.getAllinvitedData.order != "asc" ? "rotate-180" : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              {checkClient && (
                <Th>
                  <span className="flex items-center gap-1">Client</span>
                </Th>
              )}
              <Th>
                <span className="flex items-center gap-1">Job Title</span>
              </Th>
              <Th className="w-96">
                <span className="flex items-center gap-1">Email address</span>
              </Th>
              <Th className="w-44">
                <span className="flex items-center gap-1">Phone number</span>
              </Th>
              <Th className="w-36"></Th>
            </Tr>
          </THead>
          <TBody>
            {checkClient
              ? contactData?.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td>
                      {item.contact_first_name + item.contact_last_name}
                    </Td>
                    <Td>{item?.contClientContOrg?.trading_name}</Td>
                    <Td>{item?.contact_title}</Td>
                    <Td>{item.contact_email}</Td>
                    <Td>{item.contact_phone}</Td>
                    {/*permission for Read and Write */}
                    <Td>
                      <span className="flex items-center justify-end gap-4">
                        <Tooltip title="Edit" placement="top">
                          <button
                            disabled={props.permission_data.contacts_write == true ? false : true}
                            onClick={() =>
                              openDialog({
                                provider_client_contact_id:
                                  item.provider_client_contact_id,
                                type: props.getAllinvitedData.type,
                              })
                            }
                          >
                            <svg
                              width="19"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                                stroke="#475467"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </Tooltip>
                      </span>
                    </Td>
                  </Tr>
                );
              })
              : contactData?.map((item, index) => {
                if (item.hasOwnProperty("contact_first_name")) {
                  return (
                    <Tr key={index}>
                      <Td>
                        {item.contact_first_name + item.contact_last_name}
                      </Td>
                      <Td>{item?.contClientContOrg?.trading_name}</Td>
                      {/* <Td>{item?.contact_title}</Td> */}
                      <Td>{item.contact_email}</Td>
                      <Td>{item.contact_phone}</Td>
                      {/*permission for Read and Write */}
                      <Td>
                        <span className="flex items-center justify-end gap-4">
                          <Tooltip title="Edit" placement="top">
                            <button
                              disabled={props.permission_data.contacts_write == true ? false : true}
                              onClick={() =>
                                openDialog({
                                  provider_client_contact_id:
                                    item.provider_client_contact_id,
                                  type: props.getAllinvitedData.type,
                                })
                              }
                            >
                              <svg
                                width="19"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                                  stroke="#475467"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                        </span>
                      </Td>
                    </Tr>
                  );
                } else {
                  return (
                    <Tr key={index}>
                      <Td>{item.first_name + item.last_name}</Td>
                      <Td>
                        {item?.job_title === undefined
                          ? item?.individual_org?.job_title
                          : item?.job_title}
                      </Td>
                      <Td>{item.email}</Td>
                      <Td>{item.phone}</Td>
                      {/*permission for Read and Write */}
                      <Td>
                        <span className="flex items-center justify-end gap-4">
                          <Tooltip title="Delete" placement="top">
                            <button
                              disabled={props.permission_data.contacts_write == true ? false : true}
                              onClick={() =>
                                openAlert(
                                  item?.individual_org
                                    ?.individual_org_id === undefined
                                    ? item.individual_org_id
                                    : item?.individual_org
                                      ?.individual_org_id,
                                  index
                                )
                              }
                            >
                              <svg
                                width="19"
                                height="20"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                  stroke="#475467"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                          <Tooltip title="Edit" placement="top">
                            <button
                              disabled={props.permission_data.contacts_write == true ? false : true}
                              onClick={() =>
                                openDialog({
                                  individual_id: item.individual_id,
                                  organisation_id:
                                    item?.individual_org
                                      ?.organisation_id === undefined
                                      ? item?.organisation_id
                                      : item?.individual_org
                                        ?.organisation_id,
                                })
                              }
                            >
                              <svg
                                width="19"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                                  stroke="#475467"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                        </span>
                      </Td>
                    </Tr>
                  );
                }
              })}
          </TBody>
        </Table>
        {/* <Table>
          <THead>
            <Tr>
              <Th>
                <span className="flex items-center gap-1">Type</span>
              </Th>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() =>
                    props.handleSort(
                      props.getAllinvitedData.type == "clientContact"
                        ? "contact_first_name"
                        : "first_name"
                    )
                  }
                >
                  Contact name
                  <svg
                    className={`transform ${
                      props.getAllinvitedData.order != "asc" ? "rotate-180" : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              {checkClient && (
                <Th>
                  <span className="flex items-center gap-1">Client</span>
                </Th>
              )}
              <Th>
                <span className="flex items-center gap-1">Job Title</span>
              </Th>
              <Th className="w-96">
                <span className="flex items-center gap-1">Email address</span>
              </Th>
              <Th className="w-44">
                <span className="flex items-center gap-1">Phone number</span>
              </Th>
              <Th className="w-36"></Th>
            </Tr>
          </THead>
          <TBody>
            {checkClient
              ? contactData?.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td>Client</Td>
                      <Td>
                        {item.contact_first_name + item.contact_last_name}
                      </Td>
                      <Td>{item?.contClientContOrg?.trading_name}</Td>
                      <Td>{item?.contact_title}</Td>
                      <Td>{item.contact_email}</Td>
                      <Td>{item.contact_phone}</Td>
                      <Td>
                        <span className="flex items-center justify-end gap-4">
                          <Tooltip title="Edit" placement="top">
                            <button
                              onClick={() =>
                                openDialog({
                                  provider_client_contact_id:
                                    item.provider_client_contact_id,
                                  type: props.getAllinvitedData.type,
                                })
                              }
                            >
                              <svg
                                width="19"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                                  stroke="#475467"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                        </span>
                      </Td>
                    </Tr>
                  );
                })
              : contactData?.map((item, index) => {
                  if (item.hasOwnProperty("contact_first_name")) return;
                  return item?.clientContact?.[0] ? (
                    <Tr key={index}>
                      <Td>Client</Td>
                      <Td>
                        {item?.clientContact[0]?.contact_first_name +
                          item?.clientContact[0]?.contact_last_name}
                      </Td>
                      <Td>{item?.clientContact[0]?.contact_title}</Td>
                      <Td>{item?.clientContact[0]?.contact_email}</Td>
                      <Td>{item?.clientContact[0]?.contact_phone}</Td>
                      <Td>
                        <span className="flex items-center justify-end gap-4">
                          <Tooltip title="Edit" placement="top">
                            <button
                              onClick={() =>
                                openDialog({
                                  provider_client_contact_id:
                                    item.provider_client_contact_id,
                                  type: props.getAllinvitedData.type,
                                })
                              }
                            >
                              <svg
                                width="19"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                                  stroke="#475467"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                        </span>
                      </Td>
                    </Tr>
                  ) : (
                    <Tr key={index}>
                      <Td>Internal</Td>
                      <Td>{item?.first_name + item?.last_name}</Td>
                      <Td>
                        {item?.job_title === undefined
                          ? item?.individual_org?.job_title
                          : item?.job_title}
                      </Td>
                      <Td>{item?.email}</Td>
                      <Td>{item?.phone}</Td>
                      <Td>
                        <span className="flex items-center justify-end gap-4">
                          <Tooltip title="Delete" placement="top">
                            <button
                              onClick={() =>
                                openAlert(
                                  item?.individual_org?.individual_org_id ===
                                    undefined
                                    ? item?.individual_org_id
                                    : item?.individual_org?.individual_org_id,
                                  index
                                )
                              }
                            >
                              <svg
                                width="19"
                                height="20"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                  stroke="#475467"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                          <Tooltip title="Edit" placement="top">
                            <button
                              onClick={() =>
                                openDialog({
                                  individual_id: item.individual_id,
                                  organisation_id:
                                    item?.individual_org?.organisation_id ===
                                    undefined
                                      ? item?.organisation_id
                                      : item?.individual_org?.organisation_id,
                                })
                              }
                            >
                              <svg
                                width="19"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                                  stroke="#475467"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                        </span>
                      </Td>
                    </Tr>
                  );
                })}
          </TBody>
        </Table> */}
      </div>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="addContact-dialog"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/user-edit.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Edit Contact
            </h5>
            <p className="text-gray-600 ">
              Modify and update the information for this contact
            </p>
          </div>
        </div>
        <EditContactForm
          indivisualData={indivisualData}
          CloseEditForm={CloseEditForm}
          onDialogClose={onDialogClose}
          type={props?.type}
        />
      </Dialog>
      <Dialog
        isOpen={treashIsOpen}
        onClose={onAlertClose}
        onRequestClose={onAlertClose}
        style={{
          marginTop: 250,
        }}
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Are you sure you want to delete this Contact?
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight className="mr-1.5" onClick={onAlertClose}>
            Cancel
          </BtnLight>
          <BtnPrimary className="ml-1.5" onClick={onDialogOk}>
            Confirm
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default ContactsList;
