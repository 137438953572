import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
// import { notify } from "react-notify-toast";
import Heading from "../../../../../../common/element/Heading";
import ThreeDotLoader from "../../../../../../common/element/ButtonLoader/ThreeDotLoader";
import Segment from "../../../../../../common/element/Segment";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import ServicesTypesList from "./ServicesTypesList";
import Input from "../../../../../../common/element/Input";
import Label from "../../../../../../common/element/Label";
import * as userActions from "../../../../../../actions/index";
import moment from "moment";
import ReactPaginate from "react-paginate";
import searchIcon from "../../../../../../assets/icons-svg/search-lg.svg";
import plusIcon from "../../../../../../assets/icons-svg/plus.svg";
import homeIcon from "../../../../../../assets/icons-svg/home-04.svg";
import Validations from "../../../../../../common/404/Validations";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import { debounce } from "../../../../../../common/snippet/Debouncing";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../../common/pagination";

const ServicesTypes = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  let role_assignment_id;
  if (loginData) {
    role_assignment_id = loginData?.data?.role_assignment_id
  }
  let permission_info;
  if (loginData.data != null) {
    permission_info = JSON.parse(localStorage.getItem("permissionData"));
  }
  // State for filtering
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [filtersort, setFiltersort] = useState("master_setting_id");
  const [filterorder, setFilterOrder] = useState("desc");
  const [filterpage, setfilterpage] = useState(1);
  const [filterlimit, setfilterlimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [countData, setCountData] = useState(0);
  const [searchTerm, setsearchTerm] = useState("");
  // State for service type data
  const [serviceData, setServiceData] = useState();
  // State for dialogs
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogAddOpen, setAddOpen] = useState(false);

  const [singleSegmentValue, setSingleSegmentValue] = useState(["view all"]);
  const [singleSegmentCheck, setSingleSegmentCheck] = useState([""]);
  // State for service type form data
  const [serviceName, setServiceName] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [serveType, setServeType] = useState("");
  // State for input validation errors
  const [nameError, setNameError] = useState("");
  const [typeError, setTypeError] = useState("");

  useEffect(() => {
    // Set breadcrumbs
    dispatch(
      setBreadcrumbs([{ label: "Service Types", url: "/admin/services-types", home: "/admin/dashboard" }])
    );
  }, []);

  // Open the dialog for adding a service
  const openDialog = () => {
    setIsOpen(true);
  };
  // Close the dialog and reset input fields
  const onDialogClose = (e) => {
    if (!buttonDisabled) {
      setIsOpen(false);
      setServiceName("");
      setNameError('')
      setTypeError('')
      setSingleSegmentCheck([""]);
    }
  };
  //open dialog box for service added alert
  const openAddDialog = () => {
    setAddOpen(true);
    setIsOpen(false);
  };
  //close dialog box for service added alert
  const onAddDialogClose = (e) => {
    setAddOpen(false);
    GetAllServiceTypes();
  };
  // to select different tab in service type list
  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setSingleSegmentValue(val);
      if (val[0] === "view all") {
        setServiceType("");
        setfilterpage(1);
      } else {
        setServiceType(val[0]);
        setfilterpage(1);
      }
    },
    [setSingleSegmentValue]
  );
  // to select service type in add form
  const onSingleSelectionSegmentCheck = useCallback(
    (val) => {
      setSingleSegmentCheck(val);
      setServeType(val[0]);
    },
    [setSingleSegmentCheck, setServeType]
  );
  // Handle changes in input field
  const inputchangehandler = (e, identifier) => {
    if (identifier === "servieName") {
      setServiceName(e);
    }
  };
  // Handle changes in the search input field
  const handleSeachChange = (e) => {
    setsearchTerm(e.target.value.trim());
    setfilterpage(1);
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);

  const handleSort = (column) => {
    // Handle sorting of the service list
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };

  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
    // GetAllServiceTypes(selected + 1, filterlimit);
  };

  const GetAllServiceTypes = useCallback(async () => {
    dispatch(
      userActions.GetAllServiceTypes(
        serviceType,
        searchTerm,
        filterpage,
        filterlimit,
        filtersort,
        filterorder
      )
    ).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data?.rows;
        setCountData(response?.data?.count)
        const maildata = [];
        for (let key in arrayData) {
          const created = moment(arrayData[key]?.created_date).format(
            "DD/MM/YYYY"
          );
          maildata.push({
            service_id: arrayData[key]?.master_setting_id,
            service_id: arrayData[key]?.master_setting_id,
            first_name:
              (arrayData[key]?.first_name || "").charAt(0).toUpperCase() +
              (arrayData[key]?.first_name || "").slice(1),
            last_name:
              (arrayData[key]?.last_name || "").charAt(0).toUpperCase() +
              (arrayData[key]?.last_name || "").slice(1),
            meta_value_one: arrayData[key]?.meta_value_one,
            meta_key:
              arrayData[key]?.meta_key === "industry_type"
                ? "Industry"
                : arrayData[key]?.meta_key === "high_risk_work_type"
                  ? "High Risk Work"
                  : "",
            created_date: created,
          });
        }
        setServiceData(maildata);
        setTotalPages(Math.ceil(response?.data?.count / filterlimit));
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
      } else {
      }
    });
    // eslint-disable-next-line
  }, [
    dispatch,
    serviceType,
    searchTerm,
    filterpage,
    filterlimit,
    filtersort,
    filterorder,
  ]);

  useEffect(() => {
    // Initialize the service list when the component loads
    GetAllServiceTypes();
  }, [
    GetAllServiceTypes,
    serviceType,
    searchTerm,
    filterpage,
    filterlimit,
    filtersort,
    filterorder,
  ]);


  //call permission api 
  const GetSpecificUserRole = useCallback(async () => {
    if (role_assignment_id) {
      dispatch(userActions.GetSpecificUserRole(role_assignment_id)).then(
        (response) => {
          if (response?.success === true) {
          } else if (response?.status === 401) {
            handleUnauthorizedAccess()
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    GetSpecificUserRole()
  }, [])



  const submitServiceType = (e) => {
    e.preventDefault();
    // Validation
    const nameValidation = Validations({
      value: serviceName,
      Validations: { required: true },
    });
    const typeValidation = Validations({
      value: serveType,
      Validations: { required: true },
    });
    if (!nameValidation.valid) {
      setNameError(nameValidation.error_msg);
    }
    if (!typeValidation.valid) {
      setTypeError(typeValidation.error_msg);
    }

    if (nameValidation.valid === true && typeValidation.valid === true) {
      const payload = {
        meta_key: serveType,
        meta_value_one: serviceName,
      };
      setButtonDisabled(true)

      props?.onCreateServiceType(payload).then((response) => {
        if (response?.success === true) {
          openAddDialog();
          GetAllServiceTypes();
          setServiceName("");
          setNameError('')

          setButtonDisabled(false)

          setTypeError('')
          setSingleSegmentCheck([""]);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
          setButtonDisabled(false)

        } else {
          setButtonDisabled(false)
        }
      }).catch((error) => {
        setButtonDisabled(false)
      });
    }
  };

  return (
    <React.Fragment>
      <div className="flex items-center justify-between mb-7 gap-3">
        <Heading>Service Types</Heading>


        {permission_info && permission_info?.role_name !== "support team" &&
          <BtnPrimary
            onClick={() => openDialog()}
            className="w-36 flex items-center justify-center text-12"
            variant="solid"
          >
            Add record
            <span className="ml-3">
              <img src={plusIcon} alt="" className="h-5" />
            </span>
          </BtnPrimary>
        }
      </div>
      <div className="flex justify-between mb-5 flex-wrap gap-y-2.5">
        <Segment
          className="docType-btn"
          value={singleSegmentValue}
          onChange={(val) => onSingleSelectionSegmentChange(val)}
        >
          <Segment.Item value="view all">View All</Segment.Item>
          <Segment.Item value="industry_type">Industry</Segment.Item>
          <Segment.Item value="high_risk_work_type">
            High-risk work
          </Segment.Item>
        </Segment>
        <div className="flex gap-2">
          <div className="w-80 h-10">
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <img src={searchIcon} alt="" className="h-5" />
              </div>
              <input
                type="text"
                name="hs-table-search"
                className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                onChange={(e) => inputchangeSearch(e)}
              />
            </div>
          </div>

        </div>
      </div>
      <ServicesTypesList
        serviceData={serviceData}
        handleSort={handleSort}
        filtersort={filtersort}
        filterorder={filterorder}
      />
      <div className="flex justify-between mt-3">
        <Pagination totalCount={countData} pageNumber={filterpage} displayPage={serviceData?.length} UpdatePageLimit={setfilterlimit} UpdatePage={setfilterpage} />
        {countData > 0 && <ReactPaginate
          className="flex justify-content-end p-2 align-items-center pagination-wrap"
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousLinkClassName={"prev-link"}
          nextLinkClassName={"page-link"}
          breakLinkClassName={"prev-link"}
          previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
          nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
          pageLinkClassName={"px-4 rounded-md"}
          activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
          disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
          renderOnZeroPageCount={null}
          forcePage={filterpage - 1}
        />}
      </div>

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={homeIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Service
            </h5>
          </div>
        </div>
        <div className="px-6">
          <div className="flex mb-5">
            <Label className="w-1/5">Type*</Label>
            <div className="w-4/5 pl-2 docType-btn">
              <Segment
                value={singleSegmentCheck}
                onChange={(val) => onSingleSelectionSegmentCheck(val)}
              >
                <Segment.Item value="industry_type"><span>  Industry</span></Segment.Item>
                <Segment.Item value="high_risk_work_type">
                  <span> High-risk work</span>
                </Segment.Item>
              </Segment>
              {typeError && (
                <p className="text-xs text-red-600 mt-2">{typeError}</p>
              )}
            </div>
          </div>

          <div className="flex mb-5">
            <Label className="font-medium text-gray-700 w-1/5">
              Service name*
            </Label>
            <div className="w-4/5 pl-2">
              <Input
                placeholder="Enter service name here"
                className="h-10 w-full"
                inputchangehandler={(e) => inputchangehandler(e, "servieName")}
              />
              {nameError && (
                <p className="text-xs text-red-600 mt-2">{nameError}</p>
              )}
            </div>

          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight disabled={buttonDisabled} className="mr-1.5" variant="plain" onClick={onDialogClose}>
            Cancel
          </BtnLight>
          <BtnPrimary disabled={buttonDisabled} onClick={(e) => submitServiceType(e)} className="ml-1.5">
            {buttonDisabled ? <ThreeDotLoader /> : "Add"}
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 16a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 10.565c0-.574 0-.861.074-1.126a2 2 0 0 1 .318-.65c.163-.22.39-.397.843-.75l6.783-5.275c.351-.273.527-.41.72-.462a1 1 0 0 1 .523 0c.194.052.37.189.721.462l6.783 5.275c.453.353.68.53.843.75.145.195.252.416.318.65.074.265.074.552.074 1.126V17.8c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C19.48 21 18.92 21 17.8 21H6.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C3 19.48 3 18.92 3 17.8v-7.235z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Service added
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={() => onAddDialogClose()}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    loader: userActions.CreateServiceType.loader,
  };
}
const mapDispatchToProps = {
  onCreateServiceType: userActions.CreateServiceType,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesTypes);
