import axios from "axios";
import * as actionType from "../../constant/actionType";
export const GetSpecificOrgNotes = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/provider/GetSpecificOrgNotes`,
      params: apiData,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const CreateOrgNote = (form_data) => async (dispatch) => {
  try {
    // dispatch(fetchData());
    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "note_attach") {
        // Check if it's an array of files
        if (Array.isArray(form_data[key].files)) {
          form_data[key].files.forEach((file, i) => {
            formdata.append(`note_attach`, file);
          });
        } else {
          // It's a single file
          formdata.append(`note_attach`, form_data[key].files[0]);
        }
      } else {
        formdata.append(key, form_data[key]);
      }
    }
    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/provider/CreateOrgNote`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const GetAllSpecificEmailTemplates = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/provider/GetAllSpecificEmailTemplates`,
      params: apiData,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const GetAllIndividualForEmail = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/provider/GetAllIndividualForEmail`,
      params: apiData,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const EmailToIndividual = (form_data) => async (dispatch) => {
  try {
    // dispatch(fetchData());

    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "email_doc") {
        if (form_data[key]) {
          form_data[key].files.forEach((file, i) => {
            formdata.append(`email_doc`, file);
          });
        }
      } else if (key === "email_attach_docs") {
        if (form_data[key]) {
          if (form_data[key].length === 1) {
            formdata.append(`email_attach_docs[]`, [form_data[key][0]]);
          } else {
            if (form_data[key].length != 0) {
              form_data[key].forEach((file, i) => {
                formdata.append(`email_attach_docs`, file);
              });
            } else {
              formdata.append(`email_attach_docs`, [[][0]]);
            }
          }
        }
      } else {
        formdata.append(key, form_data[key]);
      }
    }

    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/provider/EmailToIndividual`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const GetProviderDetailsById = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/provider/GetProviderDetailsById`,
      params: apiData,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const UpdateOrgStatus = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "patch",
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/provider/UpdateOrgStatus`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const UpdateProviderOrgDetails = (data) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in data) {
      formdata.append(key, data[key]);
    }
    const response = await axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/provider/UpdateProviderOrgDetails`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const GetHighRiskActivityList =
  (getAllinvitedData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/GetHighRiskActivityList`,
        params: getAllinvitedData,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

export const UpdateProviderOrgOtherDetails = (data) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in data) {
      if (key === "whs_contact_info") {
        const contactData = data[key];
        // Check if individualData is an object
        if (contactData && typeof contactData === "object") {
          // Iterate over individualData properties
          for (let dataKey in contactData) {
            formdata.append(
              `whs_contact_info[${dataKey}]`,
              contactData[dataKey]
            );
          }
        }
      } else if (key === "msa_doc") {
        if (data[key]) {
          data[key].forEach((file, i) => {
            formdata.append(`msa_doc`, file);
          });
        }
      } else if (key === "msa_info") {
        const msaData = data[key];
        if (msaData && typeof msaData === "object") {
          // Iterate over individualData properties
          for (let dataKey in msaData) {
            formdata.append(`msa_info[${dataKey}]`, msaData[dataKey]);
          }
        }
      } else {
        formdata.append(key, data[key]);
      }
    }
    const response = await axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/provider/UpdateProviderOrgOtherDetails`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};
