import React, { useCallback, useEffect, useState } from "react";
import ContactsList from "./ContactsList";
import AddContactForm from "./AddContact";
import Dialog from "../../../../common/element/Dialog/Dialog";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Segment from "../../../../common/element/Segment";
import ClientInput from "../../../../common/element/Input/clientInput";
import { useDispatch, useSelector } from "react-redux";
// import { GetAllContactsOfProvider } from "../../../../actions/admin-provider/GetAllContactsOfContractor";
import { GetAllContactsOfProvider } from "../../../../actions/provider-portal/GetAllContactsOfContractor";
import { debounce } from "../../../../common/snippet/Debouncing";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
import { Pagination } from "../../../../common/pagination";
const Contacts = () => {
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {
          label: "Providers",
          url: "/provider/contacts",
          home: "/provider/dashboard",
        },
      ])
    );
  }, []);
  const [singleSegmentValue, setSingleSegmentValue] = useState(["all"]);
  const [totalPages, setTotalPages] = useState(1);
  const [type, setType] = useState("");
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [forceRender, setForceRender] = useState(false);
  const permissionProviderData = useSelector(
    (state) => state?.permissionsProvider?.permissionData
  );
    
  //Permission
  useEffect(() => {
    if (permissionProviderData !== null) {
      localStorage?.setItem(
        "permission_data",
        JSON?.stringify(permissionProviderData)
      );
      setForceRender((prevForceRender) => !prevForceRender);
    }
  }, [permissionProviderData]);
  let permission_data;
  const permissionData = JSON.parse(localStorage?.getItem("permission_data"));

  if (permissionData !== null) {
    permission_data = permissionData;
  }
  const openDialog = () => {
    setIsOpen(true);
  };
  const onDialogClose = (e) => {
    setIsOpen(false);
  };

  const submitData = () => {
    setIsOpen(false);
    onSingleSelectionSegmentChange();
  };

  // FilTER DATA SHOW
  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    organisation_id: loginUserData?.organisation_id,
    page: 1,
    limit: 10,
    sort: "created_date",
    order: "desc",
    search: "",
  });

  // All ReduxFunction
  useEffect(() => {
    dispatch(GetAllContactsOfProvider(getAllinvitedData, singleSegmentValue));
  }, [getAllinvitedData]);

  const GetFunction = useSelector((state) => state);
  const handleSort = (column) => {
    // if (column === getAllinvitedData.sort) {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
      };
    });
  };

  const handlePageChange = ({ selected }) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(
      Math.ceil(
        (GetFunction.AllContactsOfContractorPortal?.data?.data?.count || 0) /
          getAllinvitedData.limit
      )
    );
  };

  // Search Data
  const handleSearch = (e) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value.trim(),
      };
    });
  };

  const inputchangehandler = useCallback(debounce(handleSearch), []);
  const getPageNumber = () => {
    setTotalPages(
      Math.ceil(
        (GetFunction?.AllContactsOfContractorPortal?.data?.data?.count || 0) /
          getAllinvitedData.limit
      )
    );
  };

  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);

  // Update Main Tabs Function
  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      if (val?.[0] === undefined ? true : val[0] === "all") {
        SetgetAllinvitedData({
          organisation_id: loginUserData?.organisation_id,
          page: 1,
          limit: 10,
          sort: "created_date",
          order: "desc",
          search: "",
        });
      } else {
        let type = val[0] === "Internal" ? "internal" : "clientContact";
        setType(type);
        SetgetAllinvitedData((prevalue) => {
          return {
            ...prevalue,
            type: type,
            search: "",
            page: 1,
            limit: 10,
            sort: "created_date",
            order: "desc",
          };
        });
      }
      setSingleSegmentValue(val?.[0] === undefined ? ["all"] : val);
    },
    [setSingleSegmentValue, singleSegmentValue]
  );

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5 mt-7">
        <div className="flex gap-2 validation-filter">
          <Segment
            value={singleSegmentValue}
            onChange={(val) => onSingleSelectionSegmentChange(val)}
          >
            <Segment.Item value="all" className="h-9">
              <span>View all</span>
            </Segment.Item>
            <Segment.Item value="Internal" className="h-9">
              <span>Internal</span>
            </Segment.Item>
            <Segment.Item value="Client" className="h-9">
              <span>Client</span>
            </Segment.Item>
          </Segment>
          <ClientInput
            type="text"
            className="input  w-64 input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Search"
            inputchangehandler={inputchangehandler}
            name={"search"}
          />
        </div>
        <div className="flex gap-2 reset-dropdown">
          {/*permission for Read and Write */}
          <BtnPrimary
            variant="solid"
            disabled={permission_data.contacts_write == true ? false : true}
            onClick={() => openDialog()}
            className="w-auto h-9 flex items-center justify-center text-12 px-3.5"
            type="button"
          >
            Add Contacts{" "}
            <span className="ml-3">
              <img src="/assets/icons-svg/plus.svg" alt="" className="h-5" />
            </span>
          </BtnPrimary>
        </div>
      </div>
      <ContactsList
        handleSort={handleSort}
        getAllinvitedData={getAllinvitedData}
        type={type}
        permission_data={permission_data}
      />
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="addContact-dialog"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/user-plus-01.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Add a Contact
            </h5>
            <p className="text-gray-600 ">
              Create a new Client into the Conserve system
            </p>
          </div>
        </div>
        <AddContactForm onClick={submitData} onDialogClose={onDialogClose}/>
      </Dialog>
      <div className="flex justify-between mt-3">
        <Pagination
          totalCount={
            GetFunction.AllContactsOfContractorPortal?.data?.data?.count || 0
          }
          pageNumber={getAllinvitedData.page}
          displayPage={
            GetFunction.AllContactsOfContractorPortal?.data?.data?.rows
              ?.length || 0
          }
          UpdatePageLimit={SetgetAllinvitedData}
          allfilters={getAllinvitedData}
        />
        {GetFunction.AllContactsOfContractorPortal?.data?.data?.count > 0 && (
          <ReactPaginate
            className="flex justify-content-end p-2 align-items-center pagination-wrap"
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            // pageLinkClassName={'page-link'}
            previousLinkClassName={"prev-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"prev-link"}
            previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
            nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
            pageLinkClassName={"px-4 rounded-md"}
            activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
            disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Contacts;
