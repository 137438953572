import axios from "axios";
import * as actionType from "../../../constant/actionType";
export const AddProviderDocsAndSubmit =
  (APIData, token) => async (dispatch) => {
    try {
      let formdata = new FormData();
      for (let key in APIData) {
        if (key === "docsData") {
          formdata.append(key, JSON.stringify(APIData[key]));
        } else {
          formdata.append(key, APIData[key]);
        }
      }
      const response = await axios({
        method: "post",
        data: formdata,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/AddProviderDocsAndSubmit`,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  };
