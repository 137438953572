import React, { useEffect } from "react";
import Table from "../../../../../common/element/Table";
import Tooltip from "../../../../../common/element/Tooltip";
import Tag from "../../../../../common/element/Tag";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AddSpecificProviderData } from "../../../../../redux/classes/AdminContractor/SpecificProvider";
import Button from "../../../../../common/element/Buttons";
import CryptoJS from "crypto-js";
import { encryptId } from "../../../../../common/encryptDecrypt/encryptdecrypt";
const { Tr, Th, Td, THead, TBody } = Table;
const ViewAllList = ({ providersData, filterData, handleSort, updateData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSpecificData = (item) => {
    dispatch(AddSpecificProviderData(""));
    updateData(item);
    navigate(
      `specific-provider/${encodeURIComponent(
        encryptId(item?.organisation_id)
      )}`
    );
  };

  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              {/* <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("created_date")}
                >
                  ID
                  <svg
                    className={`transform ${
                      filterData?.sort === "created_date" &&
                      filterData?.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th> */}
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("trading_name")}
                >
                  Provider name
                  <svg
                    className={`transform ${
                      filterData?.sort === "trading_name" &&
                      filterData?.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("provider_type_name")}
                >
                  Provider type
                  <svg
                    className={`transform ${
                      filterData?.sort === "provider_type_name" &&
                      filterData?.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>

              <Th>Services</Th>
              <Th>ABN/NZBN</Th>
              <Th>State</Th>
              <Th className="w-60">
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("phone")}
                >
                  Contact no.
                  <svg
                    className={`transform ${
                      filterData?.sort === "phone" &&
                      filterData?.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th>
                <span
                  className="flex items-center gap-1"
                  // onClick={() => handleSort("date")}
                >
                  Renewal date
                  {/* <svg
                    className={`transform ${
                      filterData?.sort === "date" && filterData?.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg> */}
                </span>
              </Th>
            </Tr>
          </THead>
          <TBody>
            {providersData &&
              providersData?.map((item, index) => {
                // Usage example
                const encryptedId = encryptId(item?.organisation_id);

                return (
                  <Tr
                    key={index}
                    onClick={() => handleSpecificData(item)}
                    className={"cursor-pointer"}
                  >
                    {/* <Td>-</Td> */}
                    <Td>
                      <Button onClick={() => handleSpecificData(item)}>
                        <Link
                          to={`specific-provider/${encodeURIComponent(
                            encryptedId
                          )}`}
                        >
                          {item?.trading_name?item?.trading_name:"-"}
                        </Link>
                      </Button>
                    </Td>
                    <Td>{item?.provider_type_name?item?.provider_type_name:"-"}</Td>
                    <Td>{item?.meta_value_one?item?.meta_value_one:"-"}</Td>
                    <Td>{item?.abn_nzbn?item?.abn_nzbn:"-"}</Td>
                    <Td>{item?.state_name?item?.state_name:"-"}</Td>
                    <Td ><div className="noti-newwrap w-8/12" > {item?.phone?item?.phone:"-"}</div></Td>

                    {/* <Td>
                      <Tag className="text-gray-700 bg-gray-100">-</Tag>
                    </Td> */}

                    <Td>-</Td>
                  </Tr>
                );
              })}

            {/* 
            <Tr>
              <Td>000000</Td>
              <Td>Annette Souter</Td>
              <Td>Telecommunication Services</Td>
              <Td>69-117-449-260</Td>
              <Td>NT</Td>
              <Td> 08 8947 0664</Td>

              <Td>
                <Tag className="text-blue-800 bg-blue-50">Registered</Tag>
              </Td>

              <Td>28/01/2023</Td>
            </Tr>
            <Tr>
              <Td>000000</Td>
              <Td>Provider Name lorem ipsum</Td>
              <Td>Telecommunication Services</Td>
              <Td>69-117-449-260</Td>
              <Td>NT</Td>
              <Td> 08 8947 0664</Td>

              <Td>
                <Tag className="text-success-700 bg-success-50">Active</Tag>
              </Td>

              <Td>28/01/2023</Td>
            </Tr>
            <Tr>
              <Td>000000</Td>
              <Td>Provider Name lorem ipsum</Td>
              <Td>Telecommunication Services</Td>
              <Td>69-117-449-260</Td>
              <Td>NT</Td>
              <Td> 08 8947 0664</Td>

              <Td>
                <Tag className="text-error-700 bg-error-50">Deactivated</Tag>
              </Td>

              <Td>28/01/2023</Td>
            </Tr>
            <Tr>
              <Td>000000</Td>
              <Td>Annette Souter</Td>
              <Td>Telecommunication Services</Td>
              <Td>69-117-449-260</Td>
              <Td>NT</Td>
              <Td> 08 8947 0664</Td>

              <Td>
                <Tag className="text-blue-800 bg-blue-50">Registered</Tag>
              </Td>

              <Td>28/01/2023</Td>
            </Tr>
            <Tr>
              <Td>000000</Td>
              <Td>Provider Name lorem ipsum</Td>
              <Td>Telecommunication Services</Td>
              <Td>69-117-449-260</Td>
              <Td>NT</Td>
              <Td> 08 8947 0664</Td>

              <Td>
                <Tag className="text-success-700 bg-success-50">Active</Tag>
              </Td>

              <Td>28/01/2023</Td>
            </Tr>
            <Tr>
              <Td>000000</Td>
              <Td>Provider Name lorem ipsum</Td>
              <Td>Telecommunication Services</Td>
              <Td>69-117-449-260</Td>
              <Td>NT</Td>
              <Td> 08 8947 0664</Td>

              <Td>
                <Tag className="text-error-700 bg-error-50">Deactivated</Tag>
              </Td>

              <Td>28/01/2023</Td>
            </Tr>
            <Tr>
              <Td>000000</Td>
              <Td>Annette Souter</Td>
              <Td>Telecommunication Services</Td>
              <Td>69-117-449-260</Td>
              <Td>NT</Td>
              <Td> 08 8947 0664</Td>

              <Td>
                <Tag className="text-blue-800 bg-blue-50">Registered</Tag>
              </Td>

              <Td>28/01/2023</Td>
            </Tr>
            <Tr>
              <Td>000000</Td>
              <Td>Provider Name lorem ipsum</Td>
              <Td>Telecommunication Services</Td>
              <Td>69-117-449-260</Td>
              <Td>NT</Td>
              <Td> 08 8947 0664</Td>

              <Td>
                <Tag className="text-success-700 bg-success-50">Active</Tag>
              </Td>

              <Td>28/01/2023</Td>
            </Tr>
            <Tr>
              <Td>000000</Td>
              <Td>Provider Name lorem ipsum</Td>
              <Td>Telecommunication Services</Td>
              <Td>69-117-449-260</Td>
              <Td>NT</Td>
              <Td> 08 8947 0664</Td>

              <Td>
                <Tag className="text-error-700 bg-error-50">Deactivated</Tag>
              </Td>

              <Td>28/01/2023</Td>
            </Tr>
            <Tr>
              <Td>000000</Td>
              <Td>Annette Souter</Td>
              <Td>Telecommunication Services</Td>
              <Td>69-117-449-260</Td>
              <Td>NT</Td>
              <Td> 08 8947 0664</Td>

              <Td>
                <Tag className="text-blue-800 bg-blue-50">Registered</Tag>
              </Td>

              <Td>28/01/2023</Td>
            </Tr>
            <Tr>
              <Td>000000</Td>
              <Td>Provider Name lorem ipsum</Td>
              <Td>Telecommunication Services</Td>
              <Td>69-117-449-260</Td>
              <Td>NT</Td>
              <Td> 08 8947 0664</Td>

              <Td>
                <Tag className="text-success-700 bg-success-50">Active</Tag>
              </Td>

              <Td>28/01/2023</Td>
            </Tr>
            <Tr>
              <Td>000000</Td>
              <Td>Provider Name lorem ipsum</Td>
              <Td>Telecommunication Services</Td>
              <Td>69-117-449-260</Td>
              <Td>NT</Td>
              <Td> 08 8947 0664</Td>

              <Td>
                <Tag className="text-error-700 bg-error-50">Deactivated</Tag>
              </Td>

              <Td>28/01/2023</Td>
            </Tr> */}
          </TBody>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default ViewAllList;
