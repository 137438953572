import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/client-portal/clientCsvSlice";
export const AddProviderContact = (ApiData, ipAddress) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'post',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        'X-Client-IP': ipAddress, // Added IP address header
      },
      url: `${actionType.environment_url}/api/admin/provider/AddProviderContact`,
    });

    return response.data;
  } catch (error) {
    console.error("Error adding provider contact:", error);
    return error.message;
  }
};
