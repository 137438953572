import React, { useState, useEffect } from "react";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../common/element/Select/Select";
import { useDispatch, useSelector } from "react-redux";
// import { GetAllIndividualListForProviderContact } from "../../../../actions/provider-portal/GetAllIndividualListForProviderContact";
import { GetAllIndividualListForProviderContact } from "../../../../actions/provider-portal/GetAllIndividualListForContractorContact";
import ClientInput from "../../../../common/element/Input/clientInput";
import { AddProviderContact } from "../../../../actions/admin-provider/AddContractorContact";
// import { notify } from "react-notify-toast";
import Validations from "../../../../common/404/Validations";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";
import Dialog from "../../../../common/element/Dialog";

const AddContactForm = (props) => {
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  const allFunction = useSelector((state) => state);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [searchOptions, setsearchOptions] = useState([]);
  const [customOptions, setcustomOptions] = useState([]);
  const [contactType, setContactType] = useState("");
  const [dialogAddOpen, setDialogAddOpen] = useState(false);
  const filteredContacts = allFunction.AllContactTypes.filter(
    (contact) => contact.contact_type === "provider contact"
  );
  const dispatch = useDispatch();
  const [readOnly, setreadOnly] = useState(false);
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const [addContactData, SetaddContactData] = useState({
    contact_type_id: filteredContacts[0].contact_type_id,
    organisation_id: loginUserData?.organisation_id,
    first_name: "",
    last_name: "",
    email: "",
    job_title: "",
    phone: "",
    individual_id: "",
  });
  const [errorvalidation, seterrorvalidation] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
  });
  
  useEffect(() => {
    setContactType(filteredContacts?.[0]?.contact_type);
  }, [filteredContacts?.[0]]);

  const formHandle = (e) => {
    if (e.eamil == "email") {
      if (e?.e) {
        setcustomOptions({ value: e.e, label: e.e });
        seterrorvalidation((prevalue) => {
          return {
            ...prevalue,
            ["email"]: "",
          };
        });
      }
      if (e.e !== "" && e.e?.length > 3) {
        dispatch(GetAllIndividualListForProviderContact(e.e)).then(
          (response) => {
            if (response.success) {
              if (response.data.length) {
                const FilterData = response?.data?.map((item) => {
                  seterrorvalidation({
                    email: "",
                    first_name: "",
                    last_name: "",
                  });
                  return {
                    label: item.email,
                    value: item,
                  };
                });
                setsearchOptions(FilterData);
                SetaddContactData((prevalue) => {
                  return {
                    ...prevalue,
                    ...response.data[0],
                  };
                });

                setreadOnly(true);
              } else {
                setreadOnly(false);
                SetaddContactData((prevalue) => {
                  return {
                    ...prevalue,
                    ["individual_id"]: "",
                  };
                });
                SetaddContactData((prevalue) => {
                  return {
                    ...prevalue,
                    ["email"]: e.e,
                  };
                });
                let UpdateData = { first_name: "", last_name: "" };
                SetaddContactData((prevalue) => {
                  return {
                    ...prevalue,
                    ...UpdateData,
                  };
                });
              }
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            }
          }
        );
      }
    } else {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          [e.target.name]: "",
        };
      });
      setreadOnly(false);
      SetaddContactData((prevalue) => {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  // OnSelect Email
  const OnSlectHandleChange = (e) => {
    SetaddContactData((prevalue) => {
      if (typeof e.value == "string") {
        return {
          ...prevalue,
          ["email"]: e.value,
        };
      } else {
        return {
          ...prevalue, // Spread previous state
          ...e.value, // Spread data from response
        };
      }
    });
  };
  const onSubmitData = () => {
    let validationCheck = true;
    const emailValidation = Validations({
      value: addContactData.email,
      Validations: { required: true, email: true },
    });
    const first_name = Validations({
      value: addContactData.first_name,
      Validations: { required: true },
    });
    const last_name = Validations({
      value: addContactData.last_name,
      Validations: { required: true },
    });
    const phone = Validations({
      value: addContactData.phone,
      Validations: {
        required: addContactData.phone == "" ? false : true,
        min_length: 7,
        max_length: 15,
      },
    });

    if (!emailValidation.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["email"]: emailValidation.error_msg,
        };
      });
    }
    if (!first_name.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["first_name"]: first_name.error_msg,
        };
      });
    }
    if (!last_name.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["last_name"]: last_name.error_msg,
        };
      });
    }
    if (!phone.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["phone"]: phone.error_msg,
        };
      });
    }
    if (
      emailValidation.valid &&
      first_name.valid &&
      last_name.valid &&
      phone.valid
    ) {
      validationCheck = true;
    } else {
      validationCheck = false;
    }
    if (addContactData && validationCheck) {
      // delete inviteData.label;
      const newpayload = { ...addContactData, contact_type: contactType };

      dispatch(AddProviderContact(newpayload, ipAddress))
        .then((response) => {
          if (response.success) {
            setDialogAddOpen(true);
          }
          if (response.status == 409) {
            seterrorvalidation((prevalue) => {
              return {
                ...prevalue,
                ["email"]: response?.message,
              };
            });
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {});
    }
  };
  
  const onAddDialogClose = () => {
    setDialogAddOpen(false);
    props.onClick();
    props.CloseEditForm();
  };
  return (
    <React.Fragment>
      <form>
        <div className="mt-6 add-client-form px-6">
          <div className="flex justify-between flex-col">
            <div className="flex">
              <div className="flex-1 pl-1 mb-3">
                <div className="flex-1 pr-1 mb-3">
                  <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                    Email address*
                  </label>
                  <Select
                    isSearchable={true}
                    placeholder="Select email"
                    name="email"
                    options={[...searchOptions, customOptions]}
                    onChange={OnSlectHandleChange}
                    handleSearchChange={(e) =>
                      formHandle({ e, eamil: "email" })
                    }
                    className="selectType"
                  />
                  {errorvalidation.email && (
                    <div className="text-red-500 text-sm mt-1 ">
                      {errorvalidation.email}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="flex-1 pr-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  First name*
                </label>
                <ClientInput
                  type="text"
                  name="first_name"
                  readOnly={readOnly}
                  inputchangehandler={formHandle}
                  value={addContactData.first_name}
                  placeholder="Enter Contact's first name"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errorvalidation.first_name && (
                  <div className="text-red-500 text-sm mt-1 ">
                    {errorvalidation.first_name}
                  </div>
                )}
              </div>
              <div className="flex-1 pl-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Last name*
                </label>
                <ClientInput
                  type="text"
                  readOnly={readOnly}
                  inputchangehandler={formHandle}
                  value={addContactData.last_name}
                  name="last_name"
                  placeholder="Enter Contact's last name"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errorvalidation.last_name && (
                  <div className="text-red-500 text-sm mt-1 ">
                    {errorvalidation.last_name}
                  </div>
                )}
              </div>
            </div>
            <div className="flex">
              <div className="flex-1 pr-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Job title
                </label>
                <ClientInput
                  type="text"
                  name="job_title"
                  inputchangehandler={formHandle}
                  value={addContactData.job_title}
                  placeholder="Enter Contact's job role"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex-1 pl-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Phone number
                </label>
                <ClientInput
                  type="number"
                  name="phone"
                  inputchangehandler={formHandle}
                  value={addContactData.phone}
                  placeholder="Enter Contact's phone number"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errorvalidation.phone && (
                  <div className="text-red-500 text-sm mt-1 ">
                    {errorvalidation.phone}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex dailog-footer ">
          <BtnLight onClick={props?.onDialogClose}>Cancel</BtnLight>
          <BtnPrimary className="ml-1.5" onClick={() => onSubmitData()}>
            Add contact
          </BtnPrimary>
        </div>
      </form>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Contact added
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={onAddDialogClose}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default AddContactForm;
