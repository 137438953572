
import axios from "axios";
import * as actionType from "../../constant/actionType";
import { fetchData, fetchDataSuccess, fetchDataError } from "../../redux/classes/AdminContractor/GetDocumentHistoryByIdSlice";
export const GetAllSiteList = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetAllSitesOfClient`,
      params: getAllData
    });
    return response.data

  } catch (error) {
  }
};

export const AssignCompChecklistToWorker = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'post',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/AssignCompChecklistToWorker`,
    });
    return response.data
  } catch (error) {
  }
}

export const GetDocumentHistoryById = (apiData) => async (dispatch) => {
  try {
    // Start fetching data, dispatch the action for loading state
    dispatch(fetchData());

    // Make the API call
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetDocumentHistoryById`,
      params: apiData,
    });

    // Dispatch success if API call succeeds
    dispatch(fetchDataSuccess(response.data));

    // Return the response data to handle it in the component
    return { success: true, data: response.data };
  } catch (error) {
    // Dispatch failure if API call fails
    dispatch(fetchDataError(error));

    // Return error for error handling in the component
    return { success: false, error };
  }
};

export const UpdateProviderDocStatus = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'put',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/UpdateProviderDocStatus`,
    });
    return response.data
  } catch (error) {
    return error.message
  }
}
export const DeleteSite = (siteId, siteName, ipAddress) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'delete',
      data: { site_id: siteId, site_name: siteName },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        'X-Client-IP': ipAddress, // Add the IP address here
      },
      url: `${actionType.environment_url}/api/client/DeleteSite`,
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting site:', error);
    return error.message;
  }
};

export const DeleteSiteInduction = (apiData, ipAddress) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: apiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        'X-Client-IP': ipAddress, // Add IP address here
      },
      url: `${actionType.environment_url}/api/client/DeleteSiteInduction`,
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting site induction:', error);
    return { success: false, message: error.message }; // Optional: structured error handling
  }
};

export const DeleteInducteeSiteDetails = (apiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: apiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/client/DeleteInducteeSiteDetails`,
    });
    return response.data;
  } catch (error) { }
};
export const UpdateProviderDoc = (ApiData) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in ApiData) {
      if (key === 'column_names') {
        formdata.append(key, JSON.stringify(ApiData[key]));
      }
      else {
        // For other properties, append the value directly
        formdata.append(key, ApiData[key]);
      }
    }
    const response = await axios({
      method: 'put',
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/UpdateProviderDoc`,
    });
    return response.data
  } catch (error) {
    return error.message
  }
}
export const DeleteProviderDoc = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'delete',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/DeleteProviderDoc`,
    });
    return response.data
  } catch (error) {
    return error.message
  }
}

export const GetSubmissionDocsOrChecklist = (ApiData, cancelToken) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetSubmissionDocsOrChecklist`,
      params: ApiData,
      cancelToken: cancelToken,
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error fetching provider docs:', error);
    return { success: false, error: error.message || 'Unknown error' };
  }
};

export const GetAllComplianceOfClientList = (ApiData) => {
  return (dispatch) => {
    const config = {
      method: 'get',
      url: `${actionType.environment_url}/api/client/GetAllComplianceOfClientList`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: ApiData
    };

    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  };
};
export const GetProviderDocsAgainstClient = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetProviderDocsAgainstClient`,
      params: ApiData,
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error fetching provider docs:', error);
    return { success: false, error: error.message || 'Unknown error' };
  }
};

export const GetAllAssignedChecklistOfProvider = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetAllAssignedChecklistOfProvider`,
      params: ApiData,
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error fetching provider docs:', error);
    return { success: false, error: error.message || 'Unknown error' };
  }
};

export const UpdateProviderChecklistAssign = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'put',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/UpdateProviderChecklistAssign`,
    });
    return response.data
  } catch (error) {
    return console.log(error.message);
  }
}

export const GetClientContactsForSiteList = (userData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetClientContactsForSiteList`,
      params: userData
    });
    return response.data

  } catch (error) {
  }
};

export const GetClientSiteById = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetClientSiteById`,
      params: getAllData
    });
    return response.data

  } catch (error) {
  }
};


export const CreateSite = (form_data) => async (dispatch) => {
  // Final CSV Data
  try {
    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "evacuation_diagram") {
        if (form_data[key]) {
          form_data[key].forEach((file, i) => {
            formdata.append(`evacuation_diagram`, file)
          })
        }
      } else {
        formdata.append(key, form_data[key]);
      }
    }

    const response = await axios({
      method: 'post',
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/createSite`,
    });
    return response.data
  } catch (error) {
  }
}


export const DeleteSiteEvacuationDiagram = (form_data) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "evacuation_diagram") {
        if (form_data[key].length !== 0) {
          formdata.append(`evacuation_diagram`, JSON.stringify(form_data[key]))
        }
        else if (form_data[key].length === 0) {
          formdata.append(`evacuation_diagram`, JSON.stringify(form_data[key]))
        }
      } else {
        formdata.append(key, form_data[key]);
      }
    }
    const response = await axios({
      method: 'delete',
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/DeleteSiteEvacuationDiagramById`,
    });
    return response.data
  } catch (error) {
  }
}



export const UpdateClientSite = (form_data, ipAddress) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "evacuation_diagram") {
        if (form_data[key]) {
          form_data[key].files.forEach((file) => {
            formdata.append("evacuation_diagram", file);
          });
        }
      } else if (key === "evacuation_diagramUrl") {
        formdata.append("evacuation_diagram", JSON.stringify(form_data[key]));
      } else {
        formdata.append(key, form_data[key]);
      }
    }

    const response = await axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        'X-Client-IP': ipAddress, // Added IP address header
      },
      url: `${actionType.environment_url}/api/client/UpdateClientSite`,
    });

    return response.data;
  } catch (error) {
    console.error("Error updating client site:", error);
    return error.message;
  }
};



//Site Induction Apis

export const CreateSiteInductionForClient = (APIData, ipAddress) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'post',
      data: APIData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        'X-Client-IP': ipAddress, // Add IP address here
      },
      url: `${actionType.environment_url}/api/client/CreateSiteInduction`,
    });
    return response.data;
  } catch (error) {
    console.error('Error creating site induction for client:', error);
    return { success: false, message: error.message }; // Structured error response
  }
};



export const GetAllInductionsOfSpecificSiteForClient = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetAllInductionsOfSpecificSite`,
      params: getAllData
    });
    return response.data

  } catch (error) {
  }
};
//Overview data show in client-portal in provider
export const GetProviderOverviewForClient = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetProviderOverviewForClient`,
      params: getAllData
    });
    return response.data

  } catch (error) {
  }
};

//Overview data for Worker
export const GetAllWorkersOfProviderByClient = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetAllWorkersOfProviderByClient`,
      params: getAllData
    });
    return response.data

  } catch (error) {
  }
};
//overview site log GetWorkerSiteLogsByClient
export const GetWorkerSiteLogsByClient = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetWorkerSiteLogsByClient`,
      params: getAllData
    });
    return response.data

  } catch (error) {
  }
};
//overview site log GetIndStatusOfWorkerByClient
export const GetIndStatusOfWorkerByClient = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetIndStatusOfWorkerByClient`,
      params: getAllData
    });
    return response.data

  } catch (error) {
  }
};

//overview worker induction GetWorkerTrainingInduction
export const GetWorkerTrainingInduction = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetWorkerTrainingInduction`,
      params: getAllData
    });
    return response.data

  } catch (error) {
  }
};
export const UpdateSiteInductionStatusForClient = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'put',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/UpdateSiteInductionStatus`,
    });
    // dispatch(fetchDataSuccess(response.data));
    return response.data
  } catch (error) {
    return error.message
    // dispatch(fetchDataError(error.message));
  }
}

export const CreateSiteInductionModuleForClient = (ApiData) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in ApiData) {
      if (key === 'site_induction_file ') {
        formdata.append('site_induction_file ', ApiData.site_induction_file);
      }
      else {
        if (key === 'module_ques_data' || key === "pdf_page_range") {
          formdata.append(key, JSON.stringify(ApiData[key]));
        }
        else {
          if (key === "content_info_type") {
            if (ApiData.content_info_type !== "") {
              formdata.append(key, ApiData[key]);
            }
          } else {
            formdata.append(key, ApiData[key]);
          }
        }
      }
    }

    const response = await axios({
      method: 'post',
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/CreateSiteInductionModule`,
    });
    return response.data
  } catch (error) {
    return error.message
  }
}

export const UpdateSiteInductionModuleForClient = (ApiData) => async (dispatch) => {
  try {
    // dispatch(fetchData());
    let formdata = new FormData();
    for (let key in ApiData) {
      if (key === 'site_induction_file') {
        formdata.append('site_induction_file', ApiData.site_induction_file);
      } else {
        if (key === 'module_ques_data' || key === "pdf_page_range") {
          // Assuming you want to stringify the array as JSON
          formdata.append(key, JSON.stringify(ApiData[key]));
        }
        else {
          // For other properties, append the value directly
          if (key === "content_info_type") {
            if (ApiData.content_info_type !== "" && ApiData.content_info_type !== null) {
              formdata.append(key, ApiData[key]);
            }
          } else {
            formdata.append(key, ApiData[key]);
          }
        }
      }
    }

    const response = await axios({
      method: 'put',
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/UpdateSiteInductionModule`,
    });
    // dispatch(fetchDataSuccess(response.data));
    return response.data
  } catch (error) {
    return error.message
    // dispatch(fetchDataError(error.message));
  }
}

export const GetIndividualSiteIndDataForClient = (getData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    // dispatch(fetchData());
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetSiteInductionModuleById`,
      params: { module_id: getData.module_id }
    });
    // dispatch(fetchDataSuccess(response.data));
    return response.data

  } catch (error) {
    return error.message
    // dispatch(fetchDataError(error.message));
  }
};


export const UpdateSiteInductionDataForClient = (ApiData, ipAddress) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in ApiData) {
      if (
        key === 'SiteIndCompDocIds' ||
        key === 'SiteIndIndustryIds' ||
        key === 'doctType_id_data' ||
        key === 'master_setting_id_data'
      ) {
        // For array or object properties, append the value as JSON string
        formdata.append(key, JSON.stringify(ApiData[key]));
      } else {
        // Append other properties directly
        formdata.append(key, ApiData[key]);
      }
    }

    const response = await axios({
      method: 'put',
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        'X-Client-IP': ipAddress, // Add IP address here
        'Content-Type': 'multipart/form-data', // Ensure correct content type
      },
      url: `${actionType.environment_url}/api/client/UpdateSiteInduction`,
    });

    return response.data;
  } catch (error) {
    console.error('Error updating site induction for client:', error);
    return { success: false, message: error.message }; // Structured error handling
  }
};




export const GetSiteInductionAndModulesbyIdForClient = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetSiteInductionAndModulesbyId`,
      params: { site_induction_id: getAllData }
    });
    return response.data

  } catch (error) {
    return error.message
  }
};


export const ExportInductionModulePdfRange = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'put',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/ExportInductionModulePdfRange`,
    });
    return response.data
  } catch (error) {
    return error.message
  }
}



export const DeleteCompanyIndModuleFileById = (data) => async (dispatch) => {

  // Final CSV Data
  try {
    let formdata = new FormData();
    for (let key in data) {
      formdata.append(key, data[key]);
    }
    const response = await axios({
      method: "delete",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/client/DeleteCompanyIndModuleFileById`,
    });
    return response.data;
  } catch (error) { }
};

export const DeleteCompanyIndModuleQuesAnsByid = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: { module_question_id: ApiData },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/client/DeleteCompanyIndModuleQuesAnsByid`,
    });
    return response.data;
  } catch (error) { }
};

export const DeleteCompanyIndModuleAnsByid = (ApiData) => async (dispatch) => {
  // Final CSV Data
  try {
    const response = await axios({
      method: "delete",
      data: { module_answer_id: ApiData },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/client/DeleteCompanyIndModuleAnsByid`,
    });
    return response.data;
  } catch (error) { }
};



export const GetCompDocsListOfWorkerType = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetCompDocsListOfWorkerType`,
      params: getAllData
    });
    return response.data

  } catch (error) {
  }
};

export const DeleteSiteInductionModuleById = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/client/DeleteSiteInductionModuleById`,
    });
    return response.data;
  } catch (error) { }
};



export const UpdateModuleSortingOrder = (data) => async (dispatch) => {
  const dataa = { moduleOrderContents: data }
  try {
    let formdata = new FormData();
    for (let key in dataa) {
      formdata.append(key, JSON.stringify(dataa[key]));
    }
    const response = await axios({
      method: "patch",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/client/UpdateModuleSortingOrder`,
    });
    return response.data;
  } catch (error) { }
};

export const UpdateModuleQuesSortingOrder = (data) => async (dispatch) => {
  const dataa = { moduleQuesOrderContents: data }
  try {
    let formdata = new FormData();
    for (let key in dataa) {
      formdata.append(key, JSON.stringify(dataa[key]));
    }
    const response = await axios({
      method: "patch",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/client/UpdateModuleQuesSortingOrder`,
    });
    return response.data;
  } catch (error) { }
};

export const UpdateModuleAnsSortingOrder = (data) => async (dispatch) => {
  const dataa = { moduleAnsOrderContents: data }
  try {
    let formdata = new FormData();
    for (let key in dataa) {
      formdata.append(key, JSON.stringify(dataa[key]));
    }
    const response = await axios({
      method: "patch",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/client/UpdateModuleAnsSortingOrder`,
    });
    return response.data;
  } catch (error) { }
};
