
import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Heading from "../../../../../common/element/Heading";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import AddNote from "../Dialog/AddNote";
// import ClientName from "./ClientName";
import WorkerName from "./WorkerName";
import { debounce } from "../../../../../common/snippet/Debouncing";
import * as userActions from "../../../../../actions/index";
import moment from "moment/moment";
import searchIcon from "../../../../../assets/icons-svg/search-lg.svg";
import ReactPaginate from "react-paginate";
import { useLocation, useParams } from "react-router-dom";
import SettingIcon from "../../../../../assets/icons-svg/settings02.svg";
import PlusIcon from "../../../../../assets/icons-svg/plus.svg";
import Avatar from "../../../../../common/element/Avatar";
import EditIcon from "../../../../../assets/icons-svg/edit.svg";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import Table from "../../../../../common/element/Table";
import { Document, Page } from 'react-pdf';
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../common/pagination";
import { GetSpecificWorkerNotes } from "../../../../../actions/admin-worker/GetSpecificWorkerNotes";
const getLoginUserdta = JSON.parse(localStorage.getItem("LoginUserData"));
const { Tr, Th, Td, THead, TBody } = Table;
const Notes = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const { workerRedirectSpecificData } = useSelector((state) => state)
  const [countData, setCountData] = useState(0);
  const {SpecificWorkerData} = useSelector((state)=>state)
  const [totalPages, setTotalPages] = useState(1);
  const [filterData, setFilterData] = useState({
    page: 1,
    limit: 10,
    sort: "created_date",
    order: "desc",
    search: "",
    individual_id: workerRedirectSpecificData?.individual_id ? workerRedirectSpecificData?.individual_id : ""
  })
    
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogNoteOpen, setNoteOpen] = useState(false);
  const [notesData, setNotesData] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [noteSpecific, setNoteSpecific] = useState("");
  const [attachedFiles, setAttachedFiles] = useState("");
  // Extracting function assignment ID from Redux store
  const functionAssignmentId = useSelector(
    (state) => state?.functionAssignmentId?.functionAssignmentId
  );

  // Set breadcrumbs when client data is available
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "View All Workers", url: "/admin/worker/view-all-worker", home: "/admin/worker/dashboard" },
        {
          label: "Annette Souter",
          url: `/admin/worker/view-all-worker/specific-worker/${encodeURIComponent(id)}`,
        },
        {
          label: "Notes",
          url: `/admin/worker/view-all-clients/specific-worker/${encodeURIComponent(id)}/notes`,
        },
      ])
    );
  }, []);
  // Open the dialog for adding a new note
  const openDialog = () => {
    setIsOpen(true);
  };
  // Close the dialog and refresh notes data
  const onDialogClose = (e) => {
    if (!buttonDisabled) {
      setIsOpen(false);
    }
  };

  // Open the dialog of note
  const openNoteDialog = (item) => {
    let files = []
    item.attachment && item.attachment.length > 0 &&
      item.attachment.forEach((data) => {
        const file = data.note_attachment;
        const file_id = data.note_attachment_id;
        // fetch(data.note_attachment)
        // .then((response) => response.blob())
        // .then((blob) => {
        // const sizeKB = Math.round(blob.size / 1024);
        const parts = data.note_attachment.split("-");
        let FileName;
        if (parts.length >= 3) {
          FileName = parts[parts.length - 1];
          FileName = FileName.replace(/%/g, ' ');
        }
        files.push({
          // size: sizeKB,
          name: FileName,
          file: file,
          id: file_id
        })
        setAttachedFiles(files)
      })

    setNoteSpecific(item);
    setNoteOpen(true);
  };

  // Close the dialog of notes data
  const onNoteDialogClose = (e) => {
    setNoteOpen(false);
    setNoteSpecific("");
  };

  const handleSeachChange = (e) => {
    // setsearchTerm(e.target.value);
    // setfilterpage(1);
    setFilterData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);

  const handlePageChange = ({ selected }) => {
    setFilterData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
  };

  const handleSort = (column) => {
    setFilterData((prevalue) => {
      return {
        ...prevalue,
        ["order"]: filterData.order === "asc" ? "desc" : "asc",
      };
    });
  };

  const GetSpecificOrgNotes = useCallback(async () => {
    dispatch(GetSpecificWorkerNotes(filterData)).then((response) => {
      if (response.success === true) {
        const arrayData = response.data.rows;
        setCountData(response.data.count)
        let notes_data = [];
        for (let key in arrayData) {
          let createdDate = moment(arrayData[key].created_date).format(
            "HH:mm DD-MM-YYYY"
          );
          notes_data.push({
            note_id: arrayData[key].note_id,
            note_heading: arrayData[key].note_heading,
            note: arrayData[key].note,
            created_date: createdDate,
            first_name: arrayData[key].first_name,
            last_name: arrayData[key].last_name,
            avatar: arrayData[key].avatar,
            attachment: arrayData[key].note_attachs
          });
        }
        setNotesData(notes_data);
        setTotalPages(Math.ceil(response.data.count / filterData.limit));
      } else if (response.status === 401) {
        handleUnauthorizedAccess()
      } else {
      }
    });
    // eslint-disable-next-line
  }, [
    dispatch,
    functionAssignmentId,
    filterData
  ]);

  useEffect(() => {
    GetSpecificOrgNotes();
  }, [functionAssignmentId, filterData.limit, filterData.order, filterData.page, filterData.search, filterData.sort]);

  return (
    <React.Fragment>
      <WorkerName />
      <div className="bg-gray-50 px-6 nmx-6 border-t border-gray-200  nmb-6">
        <div className="lg:flex items-start py-6 justify-between mb-3 gap-3">
          <Heading>Notes</Heading>
          <div className="flex">
            <div className="w-80 h-10">
              <div className="input-wrapper">
                <div className="input-suffix-start left-3.5">
                  <img src={searchIcon} alt="" className="h-5" />
                </div>
                <input
                  type="text"
                  name={"search"}
                  className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Search"
                  onChange={(e) => inputchangeSearch(e)}
                />

              </div>
            </div>
            <BtnPrimary
              onClick={() => openDialog()}
              className="ml-4 w-29 text-xs flex items-center justify-center"
              variant="solid"
            >
              Add note{" "}
              <span className="ml-3">
                <img src={PlusIcon} alt="" className="h-5" />
              </span>
            </BtnPrimary>
          </div>
        </div>

        <div className="pb-6">
          <div className="w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg border-gray-200">
              <Table className="min-w-full divide-y divide-gray-200 notes-table">
                <THead className="bg-gray-100">
                  <Tr>
                    <Th
                      scope="col"
                      className="bg-gray-100 px-4 py-3  font-medium text-left text-gray-600"
                    >
                      <span
                        className="flex items-center gap-1"
                        onClick={() => handleSort("created_date")}
                      >
                        {" "}
                        Created date
                        <svg
                          className={`transform ${filterData.sort === "created_date" &&
                            filterData.order === "asc"
                            ? "rotate-180"
                            : ""
                            }`}
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                            stroke="#475467"
                            stroke-width="1.333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </Th>
                    <Th className="bg-gray-100 px-4 py-3  font-medium text-left text-gray-600">
                      Created by
                    </Th>
                    <Th className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600  w-2/5">
                      Note
                    </Th>
                    <Th></Th>
                  </Tr>
                </THead>
                <TBody className="divide-y divide-gray-200">
                  {notesData &&
                    notesData.map((item) => (
                      <Tr key={item.note_id}>
                        <Td className=" px-4 py-3.5 font-medium text-gray-900">
                          {item.created_date}
                        </Td>
                        <Td className=" px-4 py-3.5  font-medium text-gray-900">
                          <div className="flex gap-3 items-center">
                            <Avatar
                              size={40}
                              shape="circle"
                              src={item?.avatar}
                              children={item.first_name.slice(0, 1)}
                            />
                            <span className="text-gray-800 font-medium">
                              {item.first_name + " " + item.last_name}
                            </span>
                          </div>
                        </Td>
                        <Td className="px-4 py-3.5  font-medium text-gray-900">
                          <span
                            // onClick={() => openNoteDialog(item)}
                            className="text-gray-900 font-bold"
                          >
                            {item.note_heading}
                          </span>
                          <p className="text-gray-600 mt-1">{item.note}</p>
                        </Td>
                        <Td>
                          <span onClick={() => openNoteDialog(item)}>
                            {item.attachment && item.attachment.length !== 0 ?
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M9.99998 11.668V7.08464C9.99998 6.39428 10.5596 5.83464 11.25 5.83464C11.9403 5.83464 12.5 6.39428 12.5 7.08464V11.668C12.5 13.0487 11.3807 14.168 9.99998 14.168C8.61927 14.168 7.49998 13.0487 7.49998 11.668V8.33464M7.33331 18.3346H12.6666C14.0668 18.3346 14.7668 18.3346 15.3016 18.0622C15.772 17.8225 16.1545 17.44 16.3942 16.9696C16.6666 16.4348 16.6666 15.7348 16.6666 14.3346V5.66797C16.6666 4.26784 16.6666 3.56777 16.3942 3.03299C16.1545 2.56259 15.772 2.18014 15.3016 1.94045C14.7668 1.66797 14.0668 1.66797 12.6666 1.66797H7.33331C5.93318 1.66797 5.23312 1.66797 4.69834 1.94045C4.22793 2.18014 3.84548 2.56259 3.6058 3.03299C3.33331 3.56777 3.33331 4.26784 3.33331 5.66797V14.3346C3.33331 15.7348 3.33331 16.4348 3.6058 16.9696C3.84548 17.44 4.22793 17.8225 4.69834 18.0622C5.23312 18.3346 5.93318 18.3346 7.33331 18.3346Z"
                                  stroke="#475467"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              : null}
                          </span>
                        </Td>
                      </Tr>
                    ))}
                </TBody>
              </Table>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-3">
          <Pagination
            // totalCount={countData}
            // pageNumber={filterpage}
            // displayPage={notesData?.length}
            // UpdatePageLimit={setfilterlimit}
            // UpdatePage={setfilterpage}
            totalCount={countData}
            pageNumber={filterData.page}
            displayPage={notesData?.length}
            UpdatePageLimit={setFilterData}
            allfilters={filterData}
          />
          {countData > 0 && <ReactPaginate
            className="flex justify-content-end p-2 align-items-center pagination-wrap"
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLinkClassName={"prev-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"prev-link"}
            previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
            nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
            pageLinkClassName={"px-4 rounded-md"}
            activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
            disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
            renderOnZeroPageCount={null}
            forcePage={filterData.page - 1}
          />}
        </div>
      </div>

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="addContact-dialog"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M11 4.67992H6.8C5.11984 4.67992 4.27976 4.67992 3.63803 5.0069C3.07354 5.29452 2.6146 5.75346 2.32698 6.31794C2 6.95968 2 7.79976 2 9.47992V17.8799C2 19.5601 2 20.4002 2.32698 21.0419C2.6146 21.6064 3.07354 22.0653 3.63803 22.3529C4.27976 22.6799 5.11984 22.6799 6.8 22.6799H15.2C16.8802 22.6799 17.7202 22.6799 18.362 22.3529C18.9265 22.0653 19.3854 21.6064 19.673 21.0419C20 20.4002 20 19.5601 20 17.8799V13.6799M7.99997 16.6799H9.67452C10.1637 16.6799 10.4083 16.6799 10.6385 16.6247C10.8425 16.5757 11.0376 16.4949 11.2166 16.3852C11.4184 16.2615 11.5914 16.0886 11.9373 15.7427L21.5 6.17992C22.3284 5.35149 22.3284 4.00834 21.5 3.17991C20.6716 2.35149 19.3284 2.35149 18.5 3.17991L8.93723 12.7427C8.59133 13.0886 8.41838 13.2615 8.29469 13.4633C8.18504 13.6423 8.10423 13.8374 8.05523 14.0415C7.99997 14.2716 7.99997 14.5162 7.99997 15.0054V16.6799Z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Add a note
            </h5>
            <p className="text-gray-600 ">
              Create a new note for Annette Souter
            </p>
          </div>
        </div>
        <AddNote GetSpecificOrgNotes={GetSpecificOrgNotes} onDialogClose={onDialogClose}
          buttonDisabled={buttonDisabled} setButtonDisabled={setButtonDisabled} />
      </Dialog>

      <Dialog
        isOpen={dialogNoteOpen}
        onClose={onNoteDialogClose}
        onRequestClose={onNoteDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="addContact-dialog"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M11 4.67992H6.8C5.11984 4.67992 4.27976 4.67992 3.63803 5.0069C3.07354 5.29452 2.6146 5.75346 2.32698 6.31794C2 6.95968 2 7.79976 2 9.47992V17.8799C2 19.5601 2 20.4002 2.32698 21.0419C2.6146 21.6064 3.07354 22.0653 3.63803 22.3529C4.27976 22.6799 5.11984 22.6799 6.8 22.6799H15.2C16.8802 22.6799 17.7202 22.6799 18.362 22.3529C18.9265 22.0653 19.3854 21.6064 19.673 21.0419C20 20.4002 20 19.5601 20 17.8799V13.6799M7.99997 16.6799H9.67452C10.1637 16.6799 10.4083 16.6799 10.6385 16.6247C10.8425 16.5757 11.0376 16.4949 11.2166 16.3852C11.4184 16.2615 11.5914 16.0886 11.9373 15.7427L21.5 6.17992C22.3284 5.35149 22.3284 4.00834 21.5 3.17991C20.6716 2.35149 19.3284 2.35149 18.5 3.17991L8.93723 12.7427C8.59133 13.0886 8.41838 13.2615 8.29469 13.4633C8.18504 13.6423 8.10423 13.8374 8.05523 14.0415C7.99997 14.2716 7.99997 14.5162 7.99997 15.0054V16.6799Z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              {noteSpecific?.note_heading ? noteSpecific?.note_heading : "View a note"}
            </h5>
            <p className="text-gray-600 ">
              A note for Annette Souter
            </p>
          </div>
        </div>
        <form>
          <div className="mt-6 add-client-form px-6 ">
            <div className="flex justify-between mb-5">
              <div className="lavel-wd">
                <label className="text-gray-700 font-medium">
                  Note heading*
                </label>
              </div>
              <div className="columns-input">
                <input
                  type="text"
                  placeholder=""
                  className=" input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={noteSpecific?.note_heading}
                />
              </div>
            </div>

            <div className="flex justify-between mb-5">
              <div className="lavel-wd">
                <label className="text-gray-700 font-medium">Note*</label>
              </div>
              <div className="columns-input">
                <textarea
                  placeholder="Enter a note..."
                  className="min-h06  resize-none input-shadow text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={noteSpecific?.note}
                ></textarea>
              </div>
            </div>

            <div className="flex justify-between mb-5">
              <div className="lavel-wd">
                <label className="text-gray-700 font-medium">
                  Attachment (optional)
                </label>
              </div>
              {attachedFiles && attachedFiles.length > 0 &&
                attachedFiles.map((item, index) => (
                  <div className="columns-input">
                    <div className="upload-file-list">
                      <div className="upload-file" key={index}>
                        <div className="flex">
                          <div className="upload-file-thumbnail">
                            <span className="w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_7200_9671)">
                                  <path
                                    d="M9.33341 2.19136V4.94505C9.33341 5.31842 9.33341 5.5051 9.40608 5.64771C9.46999 5.77315 9.57198 5.87514 9.69742 5.93906C9.84003 6.01172 10.0267 6.01172 10.4001 6.01172H13.1538M13.3334 7.3372V12.1451C13.3334 13.2652 13.3334 13.8252 13.1154 14.253C12.9237 14.6294 12.6177 14.9353 12.2414 15.1271C11.8136 15.3451 11.2535 15.3451 10.1334 15.3451H5.86675C4.74664 15.3451 4.18659 15.3451 3.75877 15.1271C3.38244 14.9353 3.07648 14.6294 2.88473 14.253C2.66675 13.8252 2.66675 13.2652 2.66675 12.1451V5.21172C2.66675 4.09161 2.66675 3.53156 2.88473 3.10374C3.07648 2.72741 3.38244 2.42145 3.75877 2.22971C4.18659 2.01172 4.74664 2.01172 5.86675 2.01172H8.00793C8.49711 2.01172 8.7417 2.01172 8.97188 2.06698C9.17595 2.11597 9.37104 2.19678 9.54998 2.30644C9.75182 2.43012 9.92477 2.60307 10.2707 2.94898L12.3962 5.07446C12.7421 5.42036 12.915 5.59332 13.0387 5.79515C13.1484 5.97409 13.2292 6.16918 13.2782 6.37326C13.3334 6.60343 13.3334 6.84802 13.3334 7.3372Z"
                                    stroke="#5786CC"
                                    stroke-width="1.33333"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_7200_9671">
                                    <rect
                                      width="16"
                                      height="16"
                                      fill="white"
                                      transform="translate(0 0.679688)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </div>
                          <div className="upload-file-info">
                            <a
                              href={item.file}
                              // onClick={() => window.open(item.file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="upload-file-name font-medium text-xs text-gray-700">
                                {/* bg-icon-box1.jpg */}
                              </h6>
                              <span className="upload-file-size text-xs text-gray-600">
                                {/* 31 KB- 100% uploaded */}
                                {item.name}
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>))}
            </div>
          </div>
          <div className="flex dailog-footer justify-end">
            <BtnPrimary className="w-full" onClick={onNoteDialogClose}>
              Close
            </BtnPrimary>
          </div>
        </form>
      </Dialog>
    </React.Fragment>
  );
};
export default Notes;
