import React, { useEffect, useRef, useState } from "react";
import Table from "../../../../../common/element/Table";
import Tag from "../../../../../common/element/Tag";
import Button from "../../../../../common/element/Buttons";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import Tabs from "../../../../../common/element/Tabs";
import { useDispatch, useSelector } from "react-redux";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import ThreeDotLoader from "../../../../../common/element/ButtonLoader/ThreeDotLoader";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import rightIcon from "../../../../../assets/icons-svg/chevron-right.svg";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Dropdown from "../../../../../common/element/Dropdown";
import { RemoveWorkerOfProvider } from "../../../../../actions/provider-portal/worker/RemoveWorkerOfProvider";
import { useParams } from "react-router-dom";
import InducteesInfo from "../InducteesInfo";
import { GetWorkerProfileById } from "../../../../../actions/provider-portal/worker/GetWorkerProfileById";
import Checkbox from "../../../../../common/element/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MultiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import { GetAllClientsOfProviderList } from "../../../../../actions/provider-portal/GetAllClientsOfContractorList";
import { CreateAssignWorkersToClient } from "../../../../../actions/provider-portal/worker/CreateAssignWorkersToClients";
import { RemoveBulkWorkerOfProvider } from "../../../../../actions/provider-portal/worker/RemoveBulkWorkerOfProvider";
import Label from "../../../../../common/element/Label";
import Scrollbar from "../../../../../common/element/ScrollBar";
import { RemoveWorkerAssignedClients } from "../../../../../actions/provider-portal/worker/RemoveWorkerAssignedClients";
import { AddspecificWorkerData } from "../../../../../redux/classes/provider-portal/specificWorker";
import { GetAllComplianceListOfClient } from "../../../../../actions/admin-provider/worker/GetAllComplianceListOfClient";
const { Tr, Th, Td, THead, TBody } = Table;
const { TabNav, TabList, TabContent } = Tabs;
const ViewAll = (props) => {
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  const { id } = useParams();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const GettAllFunctions = useSelector((state) => state);
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  });
  const dispatch = useDispatch();
  const [getData, SetGetData] = useState([]);
  const [updateData, SetupdateData] = useState([]);
  const [IndividualData, SetgetIndividual] = useState();
  const [specificData, SetspecificData] = useState([]);
  const [open, setOpen] = useState(0);
  const [specificDataUpdate, SetspecificDataUpdate] = useState([]);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogIsOpenRemove, setDialogIsOpenRemove] = useState(false);
  const [checkEdit, SetCheckEdit] = useState(false);
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [dialogReinviteIsOpen, setDialogReinviteIsOpen] = useState(false);
  const [checkAssign, setCheckAssign] = useState(false);
  const [inducteesDialogIsOpen, setInducteesIsOpen] = useState(false);
  const [insuraceDialogIsOpen, setIsInsuraceOpen] = useState(false);
  const [invite_id, setInvite_id] = useState();
  const [formDialogIsOpen, setIsFormOpen] = useState(false);
  const [individual_id, setIndividual_id] = useState("");
  const [deleteData, setDeleteData] = useState();
  const [deleteDiolog, setDeleteDiolog] = useState(false);
  const [mainCheck, setMainCheck] = useState(false);
  const [firstCheck, setFirstCheck] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedClientItems, setCheckedClientItems] = useState({});
  const [ClientError, setClientError] = useState("");
  const [reinviteData, setReinviteData] = useState("");
  const [clientData, SetclientData] = useState([""]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [clientId, setClientId] = useState("");
  const [DetailData, setDetailData] = useState("");
  const [personName, setPersonName] = React.useState([]);
  const [checkListCheck, setCheckListCheck] = useState([]);
  const [documentFormData, SetDocumentFormData] = useState();
  const [iconSvg, setIconSvg] = useState(false);
  const [assignClientData, SetAssignClientData] = useState({
    provider_org_id: loginUserData.organisation_id,
    client_org_id: "",
    workerIds: [],
  });

  const [toggleState, setToggleState] = useState(
    Array(getData?.length).fill(false)
  );
  const [expandedRows, setExpandedRows] = useState([]);
  // Get Data From Redux-store

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    const filteredData =
      GettAllFunctions.SpecificContractorWorkerInvites?.data?.data?.rows.filter(
        (item) => {
          return item;
        }
      );
    // Now, filteredData contains the items where singleSegmentValue is "Invited"
    SetGetData(filteredData);
  }, [GettAllFunctions, props.singleSegmentValue]);

  const loading = useSelector(
    (state) => state.GetAdminSpecificWorkerData.loading
  );

  useEffect(() => {}, [setCheckedItems, checkedItems, mainCheck, setMainCheck]);

  const onCheckboxChange = (e, item) => {
    if (item === "mainCheck") {
      const isChecked = e;
      setMainCheck(isChecked);
      setFirstCheck(isChecked);
      if (isChecked) {
        setCheckedItems(getData);
      } else {
        setCheckedItems([]);
      }
    } else {
      // setMainCheck(false);
      const isChecked = e;
      const existingIndex = checkedItems?.findIndex(
        (ele) => ele.worker_id === item.worker_id
      );
      if (isChecked && existingIndex === -1) {
        setCheckedItems((prevItems) => [...prevItems, item]);
      } else if (!isChecked && existingIndex !== -1) {
        setCheckedItems((prevItems) => {
          const updatedItems = [...prevItems];
          updatedItems.splice(existingIndex, 1);
          return updatedItems;
        });
      }
    }
  };
  const onDialogCloseRemove = () => {
    if (!buttonDisabled) {
      setDialogIsOpenRemove(false);
      setCheckedClientItems("");
      setCheckListCheck([]);
    }
  };
  const removeWorkerdone = () => {
    // other remove api call then false dialog
    if (checkAssign) {
      let payload = {
        ...assignClientData,
        client_org_id: checkedClientItems?.contractorClientOrgIds,
        workerIds: JSON.stringify(checkedItems?.map((item) => item?.worker_id)),
        provider_org_id: loginUserData.organisation_id,
        checklistIds: JSON.stringify(checkListCheck),
      };
      setButtonDisabled(true);
      dispatch(CreateAssignWorkersToClient(payload))
        .then((response) => {
          if (response.success) {
            setCheckListCheck([]);
            setButtonDisabled(false);
            setCheckedClientItems("");
            setCheckedItems([]);
            setDialogIsOpenRemove(false);
            props.setrefetch(!props.refetch);
          }
        })
        .catch((error) => {
          console.error("Error:", error); // Log the actual error object
        })
        .finally(() => {
          setButtonDisabled(false); // Ensure the button is re-enabled, whether success or failure
        });
    } else {
      let payload = {
        ...assignClientData,
        client_org_id: checkedClientItems?.contractorClientOrgIds,
        workerIds: JSON.stringify(checkedItems?.map((item) => item?.worker_id)),
        individual_ids: JSON.stringify(
          checkedItems?.map((item) => item?.worker_individual?.individual_id)
        ),
        client_fa_id: checkedClientItems?.function_assignment_id,
        provider_org_id: loginUserData.organisation_id,
      };
      setButtonDisabled(true);
      dispatch(RemoveWorkerAssignedClients(payload))
        .then((response) => {
          if (response.success) {
            setCheckListCheck([]);
            setButtonDisabled(false);
            setCheckedClientItems("");
            setCheckedItems([]);
            props.setrefetch(!props.refetch);
            setDialogIsOpenRemove(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error); // Log the actual error object
        })
        .finally(() => {
          setButtonDisabled(false); // Ensure the button is re-enabled, whether success or failure
        });
    }
  };
  // const openDialog = (e) => {
  //   // dispatch(reSetData())
  //   setIsOpen(true);
  //   dispatch(GetAllDocumentsOfSpecificWorkerData(e));
  //   SetgetIndividual(e);
  // };

  // Get Data After Click Individual Id
  useEffect(() => {
    let data = GettAllFunctions?.GetAdminSpecificWorkerData?.data?.data;

    SetspecificDataUpdate({
      doc_type: data?.is_swms_doc ? "swms" : data?.document_type?.document_type,
      document_id: data?.document_id,
      column_names: [""],
    });

    if (!GettAllFunctions?.GetAdminSpecificWorkerData.loading) {
      SetspecificData({
        document_id: data?.document_id,
        document_type: data?.document_type?.document_type,
        doc_type_name: data?.is_swms_doc
          ? data?.swm_doc_type?.swm_type?.swm_type_name
          : data?.document_type?.doc_type_name,
        document_desc: data?.document_desc,
        expiry_date: data?.expiry_date,
        // number_of_workers: data?.doc_insurance?.number_of_workers,
        insured_amount: data?.doc_insurance?.insured_amount,
        policy_no: data?.doc_insurance?.policy_no,
        trading_name: data?.swm_doc_type?.swm_clients_org,
        issuing_authority: data?.issuing_authority,
        created_date: data?.created_date,
        issue_date: data?.issue_date,
        is_swms_doc: data?.is_swms_doc,
        doc_file: data?.doc_file,
      });
    }
  }, [IndividualData, GettAllFunctions?.GetAdminSpecificWorkerData?.loading]);

  const inducteesOpenDialog = (individual_id, clientData) => {
    dispatch(AddspecificWorkerData(individual_id));
    setClientId(clientData);
    setIndividual_id(individual_id);
    dispatch(
      GetWorkerProfileById({
        individual_id: individual_id?.worker_individual?.individual_id,
      })
    ).then((response) => {
      if (response.success) {
        setDetailData(response?.data);
      }
    });

    setInducteesIsOpen(true);
  };

  // Close View Document Screen
  const onDialogClose = (e) => {
    setCheckedClientItems("");
    setIsOpen(false);
    SetspecificData([]);
    SetCheckEdit(false);
    // dispatch(reSetData())
  };

  // Update data in a state
  useEffect(() => {
    const columnData = Object.keys(specificDataUpdate).filter(
      (key) => key !== "document_id" && key !== "column_names"
    );
    SetspecificDataUpdate((prevData) => ({
      ...prevData,
      column_names: columnData,
    }));
  }, [updateData]);

  // Function to toggle the main rows
  const toggleClick = (index) => {
    const newToggleStates = [...toggleState];
    newToggleStates[index] = !newToggleStates[index];
    setToggleState(newToggleStates);
    // Close all child rows when closing a parent row
    // setExpandedChildRows({});

    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = [...prevExpandedRows];
      newExpandedRows[index] = !newExpandedRows[index];
      return newExpandedRows;
    });
  };

  // Import CSV Function
  const Toggle = (
    <Button className="flex gap-1 justify-center items-center text-xs w-auto px-3 h-9 font-semibold hover:bg-gray-50 hover:border-gray-300 focus:bg-white focus:border-gray-300">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="16"
        viewBox="0 0 4 16"
        fill="none"
      >
        <path
          d="M2.0013 8.83301C2.46154 8.83301 2.83463 8.45991 2.83463 7.99967C2.83463 7.53944 2.46154 7.16634 2.0013 7.16634C1.54106 7.16634 1.16797 7.53944 1.16797 7.99967C1.16797 8.45991 1.54106 8.83301 2.0013 8.83301Z"
          stroke="#98A2B3"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.0013 2.99967C2.46154 2.99967 2.83463 2.62658 2.83463 2.16634C2.83463 1.7061 2.46154 1.33301 2.0013 1.33301C1.54106 1.33301 1.16797 1.7061 1.16797 2.16634C1.16797 2.62658 1.54106 2.99967 2.0013 2.99967Z"
          stroke="#98A2B3"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.0013 14.6663C2.46154 14.6663 2.83463 14.2932 2.83463 13.833C2.83463 13.3728 2.46154 12.9997 2.0013 12.9997C1.54106 12.9997 1.16797 13.3728 1.16797 13.833C1.16797 14.2932 1.54106 14.6663 2.0013 14.6663Z"
          stroke="#98A2B3"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Button>
  );
  const toggleDropdown = () => {
    setCheckListCheck([]);
    setCheckedClientItems("");
    setClientError("");
  };
  const ToggleAssignClient = (name) => (
    <Button className="flex gap-2" onClick={toggleDropdown}>
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5456 16.5L18.0456 14M18.0456 14L15.5456 11.5M18.0456 14H13.0456M9.71224 11.9167H5.96224C4.79927 11.9167 4.21779 11.9167 3.74462 12.0602C2.67929 12.3834 1.84561 13.217 1.52244 14.2824C1.37891 14.7555 1.37891 15.337 1.37891 16.5M11.7956 5.25C11.7956 7.32107 10.1166 9 8.04557 9C5.97451 9 4.29557 7.32107 4.29557 5.25C4.29557 3.17893 5.97451 1.5 8.04557 1.5C10.1166 1.5 11.7956 3.17893 11.7956 5.25Z"
          stroke="#1D2939"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {name}
    </Button>
  );
  const deleteWorkerInvite = (items) => {
    setInvite_id(items);
    setIsOpen(true);
  };

  const removeWorker = () => {
    // provider_org_id, worker_id, trading_name, first_name, email
    let payload = {
      provider_org_id: loginUserData.organisation_id,
      worker_id: invite_id?.worker_id,
      trading_name: "contractor trading(static created)",
      individual_id: invite_id?.worker_id,
      provider_fa_id: loginUserData?.function_assignment_id,
      first_name: invite_id?.worker_individual?.first_name,
      email: invite_id?.worker_individual?.email,
    };
           
    dispatch(RemoveWorkerOfProvider(payload)).then((response) => {
      if (response.success) {
        props.setrefetch(!props.refetch);
        onDialogClose();
        setCheckedClientItems("");
      }
    });
  };

  const removeBulkWorker = () => {
    // provider_org_id, worker_id, trading_name, first_name, email
    let payload = {
      provider_org_id: loginUserData.organisation_id,
      worker_id: invite_id?.worker_id,
      trading_name: "contractor trading(static created)",
      first_name: invite_id?.worker_individual?.first_name,
      email: invite_id?.worker_individual?.email,
    };

    dispatch(RemoveBulkWorkerOfProvider(payload)).then((response) => {
      if (response.success) {
        props.setrefetch(!props.refetch);
        onDialogClose();
      }
    });
  };

  const onInducteesDialogClose = () => {
    setInducteesIsOpen(false);
  };
  // const onCheckboxChange = (e, item) => {
  //   if (item == "mainCheck") {
  //     setMainCheck(e);
  //   }
  // };
  useEffect(() => {
    dispatch(
      GetAllClientsOfProviderList({
        provider_fa_id: loginUserData?.function_assignment_id,
        f_a_relation_type: "client_provider",
      })
    ).then((response) => {
      if (response.success) {
        const documentNames = response?.data?.map((item) => {
          return {
            contractorClientOrgIds: item.organisation_id,
            name: item.trading_name,
            function_assignment_id: item?.function_assignment_id,
            is_provider_assignby_invite: item?.is_provider_assignby_invite,
          };
        });
        SetclientData(documentNames);
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      }
    });
  }, []);

  // const handleChange = (event) => {
  //   const EventValue = event.target.value;
  //   const filterdata = EventValue.map((item, index) => {
  //     return item;
  //   });

  //   setPersonName(filterdata.flat());
  //   SetDocumentFormData((prevalue) => {
  //     return {
  //       ...prevalue,
  //       contractorClientOrgIds: JSON.stringify(
  //         filterdata.map((item) => item.contractorClientOrgIds)
  //       ),
  //     };
  //   });
  // };
  const AssignClient = (item) => {
    if (
      checkedClientItems?.contractorClientOrgIds == undefined ||
      checkedClientItems?.contractorClientOrgIds == null
    ) {
      setClientError("This field is required!");
    } else {
      setDialogIsOpenRemove(true);
      setCheckAssign(true);

      let payload = {
        client_org_id: checkedClientItems?.contractorClientOrgIds,
        recipient_type: "worker",
        is_provider_assignby_invite:
          checkedClientItems?.is_provider_assignby_invite,
        provider_org_id: loginUserData.organisation_id,
      };
      dispatch(GetAllComplianceListOfClient(payload)).then((response) => {
        if (response.success) {
          response?.data?.map((item) => {
            if (item?.invite_provider_comp_id) {
              setCheckListCheck((prevValue) => {
                // If the item already exists, return the previous state (avoid duplicates)
                if (prevValue.includes(item.checklist_id)) {
                  return prevValue;
                }
                // Otherwise, add the new item to the array
                return [...prevValue, item.checklist_id];
              });
            }
          });
        }
      });
    }

    // let payload = {
    //   ...assignClientData,
    //   clientOrgIds: JSON.stringify([item.contractorClientOrgIds]),
    //   workerIds: JSON.stringify(checkedItems.map((item) => item?.worker_id)),
    // };
    // dispatch(CreateAssignWorkersToClient(payload)).then((response) => {
    //   if (response.success) {
    //     setCheckedItems([]);
    //     props.setrefetch(!props.refetch);
    //   }
    // });
  };

  const UnAssignClient = (item) => {
    if (
      checkedClientItems?.contractorClientOrgIds == undefined ||
      checkedClientItems?.contractorClientOrgIds == null
    ) {
      setClientError("This field is required!");
    } else {
      setCheckAssign(false);
      setDialogIsOpenRemove(true);
    }

    // let payload = {
    //   ...assignClientData,
    //   clientOrgIds: JSON.stringify([item.contractorClientOrgIds]),
    //   workerIds: JSON.stringify(checkedItems.map((item) => item?.worker_id)),
    // };
    // dispatch(CreateAssignWorkersToClient(payload)).then((response) => {
    //   if (response.success) {
    //     setCheckedItems([]);
    //     props.setrefetch(!props.refetch);
    //   }
    // });
  };

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
    setIconSvg((prevState) => !prevState);
  };

  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      iconSvg
    ) {
      setIconSvg(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  // const HnadleSelect=(item)=>{
  // }
  const HnadleSelect = (e, item, unsign) => {
    setClientError("");
    const isChecked = e.target.checked;
    if (isChecked) {
      setCheckedClientItems(item);
    } else {
      setCheckedClientItems("");
    }
    const findWorkerId = (workers, clientAssignId) => {
      return workers.filter((worker) =>
        worker.client_assigns.some(
          (item) => item?.organisation_id === clientAssignId
        )
      );
    };
    const workerIdFilter = findWorkerId(
      checkedItems,
      item?.contractorClientOrgIds
    );
    const workerIds = workerIdFilter.map((item) => item?.worker_id);
    const workerId = workerIds;
    if (workerId && unsign !== "unassign") {
      let filterdata = checkedItems.filter(
        (iteminner) => !workerId.includes(iteminner.worker_id)
      );
      if (workerIds.length == checkedItems.length) {
        setCheckedClientItems("");
        setClientError("Client already assigned!");
      } else {
        setCheckedItems(filterdata);
      }
    }

    if (workerId && unsign == "unassign") {
      let filterdata = checkedItems.filter((iteminner) =>
        workerId.includes(iteminner.worker_id)
      );
      if (filterdata.length == 0) {
        setCheckedClientItems("");
        setClientError("No client assigned!");
      } else {
        setCheckedItems(filterdata);
      }
    }

    // if (isChecked && existingIndex === -1) {
    //   setCheckedClientItems((prevItems) => [...prevItems, item]);
    // } else if (!isChecked && existingIndex !== -1) {
    //   setCheckedClientItems((prevItems) => {
    //     const updatedItems = [...prevItems];
    //     updatedItems.splice(existingIndex, 1);
    //     return updatedItems;
    //   });
    // }
  };

  return (
    <React.Fragment>
      <div className="border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>
                <Checkbox
                  checked={
                    checkedItems?.length == getData?.length ? mainCheck : false
                  }
                  onChange={(e) => onCheckboxChange(e, "mainCheck")}
                />
              </Th>
              <Th>Worker name</Th>
              <Th>Job title</Th>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => props.handleSort("trading_name")}
                >
                  Clients assigned
                  <svg
                    className={`transform ${
                      props.getAllinvitedData.sort !== "" &&
                      props.getAllinvitedData.sort === "trading_name" &&
                      props.getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th>Contact no.</Th>
              <Th>Status</Th>
              <Th className="w-12"> </Th>
            </Tr>
          </THead>
          <TBody>
            {getData?.map((items, index) => {
              let checkStatus = "";
              let statusCss;
              if (items?.client_assigns?.length) {
                if (
                  -items?.client_assigns?.every(
                    (worker) => worker.status === "compliant"
                  )
                ) {
                  // non-compliant
                  checkStatus = "Compliant";
                  statusCss = "text-success-700 bg-success-50";
                } else {
                  checkStatus = "Partial";
                  statusCss = "text-warning-700 bg-warning-50";
                }
              } else {
                statusCss = "";
                checkStatus = "-";
              }
              // checkedItems[items.worker_id]

              return (
                <React.Fragment>
                  <Tr key={index}>
                    <Td>
                      <Checkbox
                        checked={checkedItems?.some(
                          (item) => item.worker_id == items.worker_id
                        )}
                        onChange={(e) => {
                          onCheckboxChange(
                            !checkedItems?.some(
                              (item) => item.worker_id == items.worker_id
                            ),
                            items
                          );
                        }}
                      />
                    </Td>
                    <Td>
                      <div className="flex items-center">
                        {items?.client_assigns?.length > 1 && (
                          <Button
                            onClick={() => toggleClick(index)}
                            className={`bg-gray-50 rounded-full h-6 w-6 p-0.5 mr-2 ${
                              toggleState[index] ? "rotate-90" : ""
                            }`}
                          >
                            <img src={rightIcon} alt="" />
                          </Button>
                        )}
                        <Button
                          onClick={() =>
                            items?.client_assigns?.length >= 1 &&
                            inducteesOpenDialog(items)
                          }
                        >
                          {items?.worker_individual?.first_name +
                            " " +
                            items?.worker_individual?.last_name}
                        </Button>
                      </div>
                    </Td>

                    <Td
                      onClick={() =>
                        items?.client_assigns?.length >= 1 &&
                        inducteesOpenDialog(items)
                      }
                    >
                      {items?.worker_job_title}
                    </Td>
                    {/* <Td>
                      {items?.client_assigns?.length<=1
                        ? items?.client_assigns?.[0]?.trading_name
                        : "Multiple"}
                    </Td> */}
                    <Td
                      onClick={() =>
                        items?.client_assigns?.length >= 1 &&
                        inducteesOpenDialog(items)
                      }
                    >
                      {items?.client_assigns?.length > 1
                        ? "Multiple"
                        : items?.client_assigns?.[0]?.trading_name}
                    </Td>
                    <Td
                      onClick={() =>
                        items?.client_assigns?.length >= 1 &&
                        inducteesOpenDialog(items)
                      }
                    >
                      {items?.worker_individual?.phone
                        ? items?.worker_individual?.phone
                        : ""}
                    </Td>
                    <Td
                      onClick={() =>
                        items?.client_assigns?.length >= 1 &&
                        inducteesOpenDialog(items)
                      }
                    >
                      <Tag
                        className={
                          items?.client_assigns?.length != 1
                            ? statusCss
                            : items?.client_assigns?.[0]?.status ==
                              "non-compliant"
                            ? "text-error-700 bg-error-50"
                            : "text-success-700 bg-success-50"
                        }
                      >
                        {items?.client_assigns?.length != 1
                          ? checkStatus
                          : items?.client_assigns?.[0]?.status ==
                            "non-compliant"
                          ? "Non-compliant"
                          : "Compliant"}
                      </Tag>
                    </Td>
                    <Td>
                      <Button
                        onClick={() => deleteWorkerInvite(items)}
                        className="flex gap-1 justify-center items-center text-xs w-auto px-3 h-9 font-semibold hover:bg-gray-50 hover:border-gray-300 focus:bg-white focus:border-gray-300"
                      >
                        <svg
                          width="19"
                          height="20"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                            stroke="#667085"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </Button>
                      {/* <div className="flex gap-2 reset-dropdown">
                        <Dropdown renderTitle={Toggle} isOpen={true}>
                          <Dropdown.Item
                            eventKey="a"
                            onClick={() => deleteWorkerInvite(items)}
                          >
                            <svg
                              width="19"
                              height="20"
                              viewBox="0 0 24 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                stroke="#667085"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                            {/* Import file */}
                      {/* <span>Delete</span>
                          </Dropdown.Item> */}
                      {/* <Dropdown.Item
                            eventKey="b"
                            onClick={() => Reinvite(items)}
                          >
                            <button>
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                                  stroke="#475467"
                                  stroke-width="1.667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>Dropdown
                            </button>
                            <span>Reinvite</span>
                          </Dropdown.Item> */}
                      {/* </Dropdown> */}
                      {/* </div> */}
                    </Td>
                  </Tr>
                  {expandedRows[index] &&
                    items?.client_assigns.map((innerItem, index) => {
                      let checkNestedStatus = "-";
                      let statusNestedCss;
                      if (innerItem.status == "compliant") {
                        checkNestedStatus = "Compliant";
                        statusNestedCss = "text-success-700 bg-success-50";
                      } else {
                        checkNestedStatus = "Non-compliant";
                        statusNestedCss = "text-error-700 bg-error-50";
                      }

                      // if (innerItem.approval_status == "approved") {
                      //   checkNestedStatus = "Approved";
                      //   statusNestedCss = "text-success-700 bg-success-50";
                      // } else if (innerItem.approval_status == "pending") {
                      //   checkNestedStatus = "Awaiting review";
                      //   statusNestedCss = "text-blue-700 bg-blue-50";
                      // } else {
                      //   checkStatus = "Action required";
                      //   statusCss = "text-error-700 bg-error-50";
                      // }
                      return (
                        <Tr key={index}>
                          <Td></Td>
                          <Td>
                            <div className="flex items-center">
                              <span class="h-6 w-6 mr-2 gray-line-set relative"></span>
                              <Button
                                onClick={() =>
                                  inducteesOpenDialog(items, innerItem)
                                }
                              >
                                {items?.worker_individual?.first_name +
                                  items?.worker_individual?.last_name}
                              </Button>
                            </div>
                          </Td>
                          <Td
                            onClick={() =>
                              inducteesOpenDialog(items, innerItem)
                            }
                          ></Td>
                          <Td
                            onClick={() =>
                              inducteesOpenDialog(items, innerItem)
                            }
                          >
                            {innerItem.trading_name}
                          </Td>
                          <Td
                            onClick={() =>
                              inducteesOpenDialog(items, innerItem)
                            }
                          ></Td>
                          <Td
                            onClick={() =>
                              inducteesOpenDialog(items, innerItem)
                            }
                          >
                            <Tag className={statusNestedCss}>
                              {checkNestedStatus}
                            </Tag>
                          </Td>
                          <Td
                            onClick={() =>
                              inducteesOpenDialog(items, innerItem)
                            }
                          ></Td>
                        </Tr>
                      );
                    })}
                </React.Fragment>
              );
            })}
          </TBody>
        </Table>
      </div>
      {checkedItems?.length > 0 && (
        <>
          <div className="font-semibold my-5">
            {/* <MultiSelect
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={personName}
              onChange={handleChange}
              onOpen={() => {
                openMultiSelect();
              }}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) =>
                selected.map((item) => item.name).join(", ")
              } // Display only names
              MenuProps={MenuProps}
            >
              {clientData.map((item, index) => (
                <MenuItem key={index} value={item}>
                  <Checkbox
                    checked={
                      personName.findIndex(
                        (selectedItem) => selectedItem.name === item.name
                      ) > -1
                    }
                  />
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </MultiSelect> */}
            <ul
              className="flex items-center justify-center w-[425px] h-[56px] rounded-[110px] p-2 mx-auto my-auto"
              style={{ boxShadow: "0px 0px 24px 0px rgb(214, 221, 237)" }}
            >
              <li
                className="p-2"
                style={{ color: "#5786CC", position: "relative" }}
              >
                <Button>{`${checkedItems?.length} selected`}</Button>
                <div
                  className="absolute right-0 top-1/2 transform -translate-y-1/2"
                  style={{
                    width: "2px",
                    height: "36px",
                    backgroundColor: "#F2F4F7",
                  }}
                />
              </li>
              <li className="p-2 relative">
                {/* <Button className="flex gap-2">
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5456 16.5L18.0456 14M18.0456 14L15.5456 11.5M18.0456 14H13.0456M9.71224 11.9167H5.96224C4.79927 11.9167 4.21779 11.9167 3.74462 12.0602C2.67929 12.3834 1.84561 13.217 1.52244 14.2824C1.37891 14.7555 1.37891 15.337 1.37891 16.5M11.7956 5.25C11.7956 7.32107 10.1166 9 8.04557 9C5.97451 9 4.29557 7.32107 4.29557 5.25C4.29557 3.17893 5.97451 1.5 8.04557 1.5C10.1166 1.5 11.7956 3.17893 11.7956 5.25Z"
                      stroke="#1D2939"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Assign to Client
                </Button> */}
                <div
                  className="absolute right-0 top-1/2 transform -translate-y-1/2"
                  style={{
                    width: "2px",
                    height: "36px",
                    backgroundColor: "#F2F4F7",
                  }}
                />
                <div
                  className={`filter-block ${iconSvg === true ? "active" : ""}`}
                  ref={dropdownRef}
                >
                  <Dropdown
                    renderTitle={ToggleAssignClient("Assign to Client(s)")}
                  >
                    <div className="p-4">
                      {/* <div className="p-2">
                  <Label className="font-semibold mb-3 block">Filter name (search)</Label>
                  <div className="input-wrapper">
                    <div className="input-suffix-start left-3.5">
                      <img
                        src="/assets/icons-svg/search-lg.svg"
                        alt=""
                        className="h-5"
                      />
                    </div>
                    <input
                      type="text"
                      name="hs-table-search"
                      className="input pl-11 w-full input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Type keyword"
                      ref={inputRef}
                      onChange={(e) => inputchangeSearch(e)}
                    />
                  </div>
                </div> */}
                      <div className=" p-2">
                        <Label className="font-bold mb-2 block  text-sm	">
                          Assign Client(s) to worker
                        </Label>
                        <div className="h-28">
                          <Scrollbar>
                            <ul>
                              {clientData.map((item, index) => {
                                return (
                                  <li className=" h-10 px-1.5 py-0.5 flex  items-center rounded-md bg-blue-50">
                                    <Checkbox
                                      onClick={(e) => HnadleSelect(e, item)}
                                      checked={
                                        checkedClientItems?.contractorClientOrgIds ==
                                        item?.contractorClientOrgIds
                                          ? true
                                          : false
                                      }
                                    />{" "}
                                    {item.name}
                                  </li>
                                );
                              })}
                            </ul>
                            {ClientError && (
                              <p className="text-xs text-red-600 mt-2">
                                {ClientError}
                              </p>
                            )}
                          </Scrollbar>
                        </div>
                      </div>
                      <div className="flex gap-2 border-t border-gray-200 pt-6 pb-3 mt-4">
                        <BtnLight>Cancel</BtnLight>
                        <BtnPrimary onClick={() => AssignClient()}>
                          Next
                        </BtnPrimary>
                      </div>
                    </div>
                  </Dropdown>
                </div>
                {/* <Dropdown renderTitle={ToggleAssignClient} isOpen={true}>
                  {clientData.map((item, index) => {
                    return (
                      <Dropdown.Item
                        eventKey="a"
                        onClick={() => AssignClient(item)}
                      >
                        <span>{item.name}</span>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown> */}
              </li>
              <li className="p-2">
                <div
                  className="absolute right-0 top-1/2 transform -translate-y-1/2"
                  style={{
                    width: "2px",
                    height: "36px",
                    backgroundColor: "#F2F4F7",
                  }}
                />
                <div
                  className={`filter-block ${iconSvg === true ? "active" : ""}`}
                  ref={dropdownRef}
                >
                  <Dropdown
                    renderTitle={ToggleAssignClient("Unassign Client(s)")}
                  >
                    <div className="p-4">
                      {/* <div className="p-2">
                  <Label className="font-semibold mb-3 block">Filter name (search)</Label>
                  <div className="input-wrapper">
                    <div className="input-suffix-start left-3.5">
                      <img
                        src="/assets/icons-svg/search-lg.svg"
                        alt=""
                        className="h-5"
                      />
                    </div>
                    <input
                      type="text"
                      name="hs-table-search"
                      className="input pl-11 w-full input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Type keyword"
                      ref={inputRef}
                      onChange={(e) => inputchangeSearch(e)}
                    />
                  </div>
                </div> */}
                      <div className=" p-2">
                        <Label className="font-bold mb-2 block  text-sm	">
                          Unassign Client(s) to worker
                        </Label>
                        <div className="h-28">
                          <Scrollbar>
                            <ul>
                              {clientData.map((item, index) => {
                                return (
                                  <li className=" h-10 px-1.5 py-0.5 flex  items-center rounded-md bg-blue-50">
                                    <Checkbox
                                      onClick={(e) =>
                                        HnadleSelect(e, item, "unassign")
                                      }
                                      checked={
                                        checkedClientItems?.contractorClientOrgIds ==
                                        item?.contractorClientOrgIds
                                          ? true
                                          : false
                                      }
                                    />{" "}
                                    {item.name}
                                  </li>
                                  // <Dropdown.Item
                                  //   eventKey="a"
                                  // >
                                  //   <Checkbox onClick={() => AssignClient(item)}></Checkbox>
                                  //   <span>{item.name}</span>
                                  // </Dropdown.Item>
                                );
                              })}
                            </ul>
                            {ClientError && (
                              <p className="text-xs text-red-600 mt-2">
                                {ClientError}
                              </p>
                            )}
                          </Scrollbar>
                        </div>
                      </div>
                      <div className="flex gap-2 border-t border-gray-200 pt-6 pb-3 mt-4">
                        <BtnLight>Cancel</BtnLight>
                        <BtnPrimary onClick={() => UnAssignClient()}>
                          Unassign
                        </BtnPrimary>
                      </div>
                    </div>
                  </Dropdown>
                </div>
                {/* <Button
                  className="flex gap-2"
                  onClick={() => setDialogIsOpenRemove(true)}
                >
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                      stroke="#667085"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Unassign Client(s)
                </Button> */}
              </li>
            </ul>
          </div>
        </>
      )}

      <Dialog
        isOpen={dialogIsOpenRemove}
        onClose={onDialogCloseRemove}
        onRequestClose={onDialogCloseRemove}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            {checkAssign ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="49"
                viewBox="0 0 48 49"
                fill="none"
              >
                <rect
                  x="0.5"
                  y="1"
                  width="47"
                  height="47"
                  rx="23.5"
                  fill="#D2EBFF"
                />
                <rect
                  x="0.5"
                  y="1"
                  width="47"
                  height="47"
                  rx="23.5"
                  stroke="#ECFDF3"
                />
                <path
                  d="M24 28H19.5C18.1044 28 17.4067 28 16.8389 28.1722C15.5605 28.56 14.56 29.5605 14.1722 30.8389C14 31.4067 14 32.1044 14 33.5M31 33.5V27.5M28 30.5H34M26.5 20C26.5 22.4853 24.4853 24.5 22 24.5C19.5147 24.5 17.5 22.4853 17.5 20C17.5 17.5147 19.5147 15.5 22 15.5C24.4853 15.5 26.5 17.5147 26.5 20Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                  stroke="#000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            {checkAssign
              ? "Confirming that you are assigning workers to this Client"
              : "Are you sure you want to unassign Workers to this client"}
          </p>
          <ul className="w-28 mx-auto">
            {
              // checkedClientItems?.map((item, index) => {
              //   return (
              //     <li className="list-disc" key={index}>
              //       {item.name}
              //     </li>
              //   );
              // })
              <li className="list-disc">{checkedClientItems?.name}</li>
            }
          </ul>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 gap-2">
          <BtnLight disabled={buttonDisabled} onClick={onDialogCloseRemove}>
            Cancel
          </BtnLight>
          <BtnPrimary disabled={buttonDisabled} onClick={removeWorkerdone}>
            {buttonDisabled ? <ThreeDotLoader /> : "Confirm"}
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={inducteesDialogIsOpen}
        onClose={onInducteesDialogClose}
        onRequestClose={onInducteesDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/cons-worker.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              {DetailData?.first_name + " " + DetailData?.last_name}
            </h5>
            <p className="text-gray-600 ">Worker Details</p>
          </div>
        </div>

        <InducteesInfo
          DetailData={DetailData}
          individual_id={individual_id}
          clientId={clientId}
          onInducteesDialogClose={onInducteesDialogClose}
        />
      </Dialog>

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Are you sure you want to remove this Worker?
          </p>
          <p className=" text-gray-600 text-center mt-1">
            This action cannot be undone. The user will lose all access to the
            app.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 gap-2">
          <BtnLight onClick={onDialogClose}>Close</BtnLight>
          <BtnPrimary onClick={removeWorker}>Confirm</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default ViewAll;
