import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/AdminContractor/GetAdminSpecificContractorSlice";
export const GetAllProviderList = (search, function_id, contact_type_id) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    //   dispatch(fetchData())
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/worker/GetAllProviderList`,
      params: { search: search, function_id: function_id, contact_type_id: contact_type_id }
    });
    return response.data
    //   dispatch(fetchDataSuccess(response.data))
  } catch (error) {
    return error.message
    //   dispatch(fetchDataError(error.message))  
  }
};
export const GetAllClientsOfProviderList = (provider_fa_id) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    //   dispatch(fetchData())
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/worker/GetAllClientsOfProviderList`,
      params: { provider_fa_id:provider_fa_id }
    });
    return response.data
    //   dispatch(fetchDataSuccess(response.data))
  } catch (error) {
    return error.message
    //   dispatch(fetchDataError(error.message))  
  }
};