// invoiceSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedInvoice: null,
};

const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        setInvoiceData: (state, action) => {
            state.selectedInvoice = action.payload;
        },
        clearInvoiceData: (state) => {
            state.selectedInvoice = null;
        }
    }
});

export const { setInvoiceData, clearInvoiceData } = invoiceSlice.actions;
export default invoiceSlice.reducer;
