import React, { useEffect, useState } from "react";
import Steps from "../../../../common/element/Steps";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Button from "../../../../common/element/Buttons";
import BtnPrimaryLight from "../../../../common/element/Buttons/primaryLightBtn";
import { useDispatch } from "react-redux";
import { GetInvitedProviderChecklist } from "../../../../actions/provider-portal/registration/GetInvitedProviderChecklist";
import { GetProviderChecklistByClient } from "../../../../actions/provider-portal/registration/GetProviderChecklistByClient";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Label from "../../../../common/element/Label";
import Input from "../../../../common/element/Input";
import Upload from "../../../../common/element/Upload";
import ClientInput from "../../../../common/element/Input/clientInput";
import DatePicker from "../../../../common/element/DatePicker";
import dayjs from "dayjs";
import { AddProviderDocsAndSubmit } from "../../../../actions/provider-portal/registration/AddProviderDocsAndSubmit";
import Card from "../../../../common/element/Card";
import Segment from "../../../../common/element/Segment";
import { stripeCheckoutForProviderRegis } from "../../../../actions/provider-portal/registration/stripeCheckoutForProviderRegis";
import { paypalCheckoutForProviderRegis } from "../../../../actions/provider-portal/registration/paypalCheckoutForProviderRegis";
import { GetInvoicePdfById } from "../../../../actions/provider-portal/GetInvoicePdfById";
import { SignupProviderForClient } from "../../../../actions/public/SignupProviderForClient";
import { ResetProviderRegisterFinalData } from "../../../../redux/classes/common/ProviderRegisterFinalData";
import { ResetProviderSignUpData } from "../../../../redux/classes/provider-portal/SignUp";
import { GetProviderRegistPaymentStatus } from "../../../../actions/provider-portal/GetProviderRegistPaymentStatus";
import { RegisterExistingProviderForClient } from "../../../../actions/provider-portal/registration/RegisterExistingProviderForClient";

const ConnectClient = (props) => {
  const navigate = useNavigate();
  const { SignUpData } = useSelector((state) => state);
  const { ProviderContactData } = useSelector((state) => state);
  const { ProviderRegisterFinalData } = useSelector((state) => state);
  const [invoiceData, setInvoiceData] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const session_id = params.get("session_id");
  const paymentId = params.get("subscription_id");
  // const inviteProviderid = searchParams.get("invite_provider_id");
  const preventDefault = (e) => e.preventDefault();
  const dateGap = 0;
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [docAdd, setDocAdd] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [firstStep, secondStep] = useState(false);
  const [docData, SetDocData] = useState([]);
  const [workerDocData, SetworkerDocData] = useState([]);
  const [open, setOpen] = useState(0);
  const [checkPdf, SetcheckPdf] = useState("");
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [complianceData, setComplianceData] = useState({
    client_org_id: props?.GetProviderChecklistByClient?.client_org_id,
    provider_org_id: props?.GetProviderChecklistByClient?.provider_org_id,
    docsData: [],
  });

  const [isInvoiceSuccess, setIsInvoiceSuccess] = useState(false);
  const [sessionData, setSessionData] = useState({});

  const uploadDocHandler = (e) => {
    setDocAdd(true);
  };
  const hideBlock = (e) => {
    setDocAdd(false);
  };
  const onChange = (nextStep) => {
    if (nextStep < 0) {
      setStep(0);
    } else {
      setStep(nextStep);
    }
  };

  const onPrevious = () => onChange(step - 1);

  const activateLasers = (e) => {
    e.preventDefault();
  };

  // useEffect(() => {
  //   dispatch(
  //     GetInvitedProviderChecklist({
  //       invite_provider_id: ProviderContactData?.invite_provider_id,
  //     })
  //   ).then((response) => {
  //     if (response.success) {
  //       SetDocData(response?.data?.provChecklist);
  //     }
  //   });
  // }, []);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  function Icon({ id, open }) {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.68296 1.97217V18.7722M1.28296 10.3722H18.083"
          stroke="#5786CC"
          stroke-width="2.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }

  const openDialog = () => {
    setIsOpen(true);
  };

  // useEffect(() => {

  // }, []);

  const checkListData = (client_org_id, provider_org_id) => {
    setComplianceData((prevValue) => {
      return {
        ...prevValue,
        docsData: [],
      };
    });
    dispatch(
      GetProviderChecklistByClient({
        client_org_id: client_org_id,
        provider_org_id: provider_org_id,
      })
    ).then((response) => {
      if (response.success) {
        SetDocData(
          response?.data.filter(
            (item) => item.check_comp_assigned_to == 1 && item
          )
        );
        SetworkerDocData(
          response?.data.filter(
            (item) => item.check_comp_assigned_to == 0 && item
          )
        );
        response?.data.flatMap(
          (doc, index) =>
            doc?.check_comp_assigned_to == 1 &&
            doc?.inviteProviComp?.map((item, innerIndex) => {
              const newObject = {};
              newObject["checklist_doc_id"] = item.checklist_doc_id;
              newObject["is_other_doc"] = item.is_other_doc;
              newObject["doc_name"] = "";
              for (const [key, value] of Object.entries(item?.document_type)) {
                if (key == "document_type_id") {
                  newObject["document_type_id"] = value;
                }
                if (key == "custom_fields" && !item.is_other_doc) {
                  newObject["doctype_fields_data"] = value?.map((key) => ({
                    [key]: "",
                  }));
                }
                if (value === true) {
                  newObject[key] = "";
                }
              }
              setComplianceData((prevValue) => {
                return {
                  ...prevValue,
                  [`doc${index + innerIndex}`]: "",
                  // [`doc${index + innerIndex}`]: "",
                  docsData: [...prevValue.docsData, newObject],
                };
              });
            })
        );
      }
    });
  };

  const handleFileChange = (e) => {
    const file = e[0];
    SetcheckPdf(file);
    // dispatch(UpdateInductionStatus(true));
    // if (e[e.length - 1].size <= 25 * 1024 * 1024) {
    //   SetcheckPdf(e[e.length - 1]);
    //   const file = e[0];
    //   Setpdfdata({
    //     ...Pdfdata,
    //     content_info_type: "file",
    //     company_ind_file: file,
    //   });
    //   SetsizeError("");
    // } else {
    //   SetsizeError("File size exceeds the maximum limit of 25MB.");
    // }
  };
  // const handleDeletePDF = (e) => {
  //   e.preventDefault()
  //   SetcheckPdf("");
  // };

  const handleDeletePDF = (event, field, index) => {
    let newValue;
    if (field == "doc") {
      newValue = event[0];
      setComplianceData((prevalue) => {
        return {
          ...prevalue,
          ["doc" + index]: "",
        };
      });
    }
  };

  // Handle form
  const handleForm = (event, field, index, custom_field, customIndex) => {
    let newValue;
    if (field == "allow_expiry") {
      newValue = event;
    } else if (field == "doc") {
      newValue = event[0];
      setComplianceData((prevalue) => {
        return {
          ...prevalue,
          ["doc" + index]: newValue,
        };
      });
      return;
    } else {
      newValue = event.target.value;
    }
    setComplianceData((prevValue) => {
      // Create a new array with the updated field
      const updatedDocsData = prevValue.docsData.map((item, idx) => {
        if (idx === index) {
          // Return a new object with updated field
          if (field == "doctype_fields_data") {
            return {
              ...item,
              doctype_fields_data: item.doctype_fields_data.map(
                (fieldItem, fieldIdx) => {
                  if (fieldIdx === customIndex) {
                    // Update the specific field within doctype_fields_data
                    return {
                      ...fieldItem,
                      [custom_field]: newValue,
                    };
                  }
                  // Return unchanged fieldItem for all other indices
                  return fieldItem;
                }
              ),
            };
          } else {
            return {
              ...item,
              [field]: newValue,
            };
          }
        }
        // Return unchanged item for all other indices
        return item;
      });

      // Return the updated state
      return {
        ...prevValue,
        docsData: updatedDocsData,
      };
    });
  };

  // Min Date
  const minDate = dayjs(new Date())
    .subtract(dateGap, "day")
    .startOf("day")
    .toDate();

  const combinedProvCheckDoc = docData?.flatMap((item) => item.inviteProviComp);
  const combinedWorkerCheckDoc = workerDocData?.flatMap(
    (item) => item.inviteProviComp
  );

  const onNext = (step) => {
    // const nonEmptyFields = complianceData.docsData.map((doc) => {
    //   const nonEmptyFields = {};
    //   Object.keys(doc).forEach((key) => {
    //     if (typeof doc[key] === "string" && doc[key] !== "") {
    //       nonEmptyFields[key] = doc[key];
    //     } else if (Array.isArray(doc[key])) {
    //       doc[key].forEach((item, index) => {
    //         Object.keys(item).forEach((innerKey) => {
    //           if (item[innerKey] !== "") {
    //             if (!nonEmptyFields[key]) {
    //               nonEmptyFields[key] = [];
    //             }
    //             nonEmptyFields[key][index] = { [innerKey]: item[innerKey] };
    //           }
    //         });
    //       });
    //     }
    //   });
    //   return nonEmptyFields;
    // });
    if (ProviderContactData?.inviteProType?.client_pay_default) {
      if (step == 0) {
        props.onDialogClose();
        return;
      }

      const allFieldsNonEmpty = complianceData.docsData.map((doc) => {
        let allNonEmpty = true;

        Object.keys(doc).forEach((key) => {
          if (typeof doc[key] === "string" && doc[key] === "") {
            allNonEmpty = false;
          } else if (Array.isArray(doc[key])) {
            doc[key].forEach((item) => {
              Object.keys(item).forEach((innerKey) => {
                if (item[innerKey] === "") {
                  allNonEmpty = false;
                }
              });
            });
          }
        });

        return allNonEmpty;
      });
      const hasNonEmptyValues = (data) => {
        return Object.keys(data).some(
          (key) =>
            key !== "client_org_id" &&
            key !== "provider_org_id" &&
            key !== "docsData" &&
            data[key] !== ""
        );
      };

      const result = hasNonEmptyValues(complianceData);
      const checkValue = allFieldsNonEmpty.some((value) => value === false)
        ? false
        : true;
      if (combinedProvCheckDoc.length == 0) {
        onChange(step + 1);
      } else {
        if (checkValue && result) {
          dispatch(
            AddProviderDocsAndSubmit(
              complianceData,
              props?.GetProviderChecklistByClient?.token
            )
          ).then((response) => {
            if (response.success) {
              onChange(step + 1);
            }
          });
          setCheckError(false);
        } else {
          setCheckError(true);
        }
      }
    } else {
      if (step == 0) {
        setStep(1);
      }
      if (step == 2) {
        props.onDialogClose();
        return;
      }
      if (step == 1) {
        const allFieldsNonEmpty = complianceData.docsData.map((doc) => {
          let allNonEmpty = true;

          Object.keys(doc).forEach((key) => {
            if (typeof doc[key] === "string" && doc[key] === "") {
              allNonEmpty = false;
            } else if (Array.isArray(doc[key])) {
              doc[key].forEach((item) => {
                Object.keys(item).forEach((innerKey) => {
                  if (item[innerKey] === "") {
                    allNonEmpty = false;
                  }
                });
              });
            }
          });

          return allNonEmpty;
        });
        const hasNonEmptyValues = (data) => {
          return Object.keys(data).some(
            (key) =>
              key !== "client_org_id" &&
              key !== "provider_org_id" &&
              key !== "docsData" &&
              data[key] !== ""
          );
        };

        const result = hasNonEmptyValues(complianceData);
        const checkValue = allFieldsNonEmpty.some((value) => value === false)
          ? false
          : true;
        if (combinedProvCheckDoc.length == 0) {
          onChange(step + 1);
        } else {
          if (checkValue && result) {
            dispatch(
              AddProviderDocsAndSubmit(
                complianceData,
                props?.GetProviderChecklistByClient?.token
              )
            ).then((response) => {
              if (response.success) {
                onChange(step + 1);
              }
            });
            setCheckError(false);
          } else {
            setCheckError(true);
          }
        }
      }
    }
  };
  // let payload = {
  //   provider_data:ProviderRegisterFinalData,
  //   individual_id: ProviderContactData?.individual_id,
  //   provider_type_id: ProviderContactData?.provider_type_id,
  //   invite_provider_id: ProviderContactData?.invite_provider_id,
  //   provider_org_id: "",

  //   trading_name:
  //     ProviderRegisterFinalData?.provider_regist_data.trading_name,
  //   email:
  //     ProviderRegisterFinalData?.provider_regist_data.individual_data[0]
  //       ?.email,
  //   address_type: "business",
  //   address_one:
  //     ProviderRegisterFinalData?.provider_regist_data?.address_data[0]
  //       ?.address_one,
  //   address_two:
  //     ProviderRegisterFinalData?.provider_regist_data?.address_data[0]
  //       ?.address_two,
  //   state_name:
  //     ProviderRegisterFinalData?.provider_regist_data?.address_data[0]
  //       ?.state_name,
  //   zipcode:
  //     ProviderRegisterFinalData?.provider_regist_data?.address_data[0]
  //       ?.zipcode,
  //   isProviderExist: false,
  //   registrationType:
  //     SignUpData?.registrationType == "forClient"
  //       ? "forClient"
  //       : "inviteByClient",
  // };

  const { tempEngagementDoc, tempMsaDoc, ...updatedData } =
    ProviderRegisterFinalData;

  // delete ProviderRegisterFinalData.tempEngagementDoc;
  // delete ProviderRegisterFinalData.tempMsaDoc;

  const handleSubmit = async (checkpayment) => {
    // event.preventDefault();
    // registrationType, provider_type_id, invite_id, provider_org_id
    const plainObject = { ...ProviderRegisterFinalData };
    delete plainObject.tempEngagementDoc;
    delete plainObject.tempMsaDoc;
    let payload;
    if (props?.existingUser) {
      payload = {
        ...props?.ProviderExistingUser,
        isProviderExist: true,
      };
    } else {
      payload = {
        ...plainObject,
        isProviderExist: false,
      };
    }
    if (checkpayment == "stripe") {
      dispatch(stripeCheckoutForProviderRegis(payload)).then((response) => {
        if (response.success) {
          localStorage.setItem(
            "checkout_custom_id",
            JSON.stringify({
              checkout_custom_id: response?.data?.data?.checkout_custom_id,
            })
          );
          window.location.href = response?.data?.data?.sessionUrl;
        }
      });
    } else {
      dispatch(paypalCheckoutForProviderRegis(payload)).then((response) => {
        if (response.success) {
          localStorage.setItem(
            "checkout_custom_id",
            JSON.stringify({
              checkout_custom_id: response?.data?.data?.checkout_custom_id,
            })
          );
          window.location.href = response?.data?.data?.approvalLink;
        }
      });
    }
  };

  let checkPayment = true;
  if (session_id || paymentId) {
    checkPayment = false;
  } else {
    checkPayment = true;
  }

  // useEffect(() => {
  //   debugger
  //   const params = new URLSearchParams(window.location.search);
  //   const session_id = params.get("session_id");
  //   const paymentId = params.get("subscription_id");

  //   if (session_id || paymentId) {
  //     const checkout_custom_id = JSON.parse(
  //       localStorage.getItem("checkout_custom_id")
  //     );
  //     const inviteData = localStorage.getItem("workerInviteData")
  //       ? JSON.parse(localStorage.getItem("workerInviteData"))
  //       : "";

  //     dispatch(
  //       GetProviderRegistPaymentStatus({
  //         checkout_custom_id: checkout_custom_id?.checkout_custom_id,
  //       })
  //     ).then((response) => {
  //       if (response?.data?.is_invoice_success) {
  //         setIsInvoiceSuccess(true);
  //         setSessionData({ session_id, paymentId });
  //       }
  //     });
  //   }
  // }, [dispatch]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const session_id = params.get("session_id");
    const paymentId = params.get("subscription_id");

    if (session_id || paymentId) {
      const checkout_custom_id = JSON.parse(
        localStorage.getItem("checkout_custom_id")
      );

      const interval = setInterval(() => {
        dispatch(
          GetProviderRegistPaymentStatus({
            checkout_custom_id: checkout_custom_id?.checkout_custom_id,
          })
        ).then((response) => {
          if (response?.data?.is_invoice_success) {
            let data = response?.data;
            setIsInvoiceSuccess(true);
            setSessionData({
              payment_checkout_id: data?.payment_checkout_id,
              provider_fee_type: data?.provider_fee_type,
              payment_gateway: data?.payment_gateway,
              account_payment_id:data?.account_payment_id
            });
            setInvoiceData(data?.xero_invoice_pdf);
            clearInterval(interval); // Stop polling when condition is met
          }
        });
      }, 2000); // Call API every second

      // Cleanup interval on component unmount
      return () => clearInterval(interval);
    }
  }, [dispatch]);

  // useEffect(() => {
  //   if (isInvoiceSuccess) {
  //     const { session_id, paymentId } = sessionData;
  //     const ProviderRegisterFinalData = {}; // Replace with actual data
  //     const SignUpData = {}; // Replace with actual data

  //     dispatch(
  //       GetInvoicePdfById({ payment_session_id: session_id || paymentId })
  //     ).then((response) => {
  //       if (response.success) {
  //         // Add your logic to handle invoice data
  //         dispatch(SignupProviderForClient(ProviderRegisterFinalData))
  //           .then((signupResponse) => {
  //             if (signupResponse.success) {
  //               if (SignUpData?.registrationType === "forClient") {
  //                 navigate("/login");
  //                 dispatch(ResetProviderSignUpData());
  //                 dispatch(ResetProviderRegisterFinalData());
  //               } else {
  //                 // Add your logic for checklist data
  //                 dispatch(ResetProviderRegisterFinalData());
  //               }
  //             } else {
  //               console.error(
  //                 "Operation failed:",
  //                 signupResponse.errorMessage || "Unknown error"
  //               );
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("An error occurred during signup:", error);
  //           });
  //       }
  //     });
  //   }
  // }, [isInvoiceSuccess, sessionData, dispatch, navigate]);

  useEffect(() => {
    if (isInvoiceSuccess) {
      const params = new URLSearchParams(window.location.search);
      const session_id = params.get("session_id");
      const paymentId = params.get("subscription_id");
      if (session_id || paymentId) {
        if (props?.existingUser) {
          let payload = { ...sessionData, ...props?.ProviderExistingUser };
          if (props?.ProviderExistingUser) {
            dispatch(RegisterExistingProviderForClient(payload))
              .then((response) => {
                if (response.success) {
                  checkListData(
                    props?.GetProviderChecklistByClient?.client_org_id,
                    props?.GetProviderChecklistByClient?.provider_org_id,
                    props?.GetProviderChecklistByClient?.token
                  );
                  localStorage.setItem(
                    "conserve_authtoken",
                    props?.GetProviderChecklistByClient?.token
                  );
                } else {
                  console.error(
                    "Operation failed:",
                    response.errorMessage || "Unknown error"
                  );
                }
              })
              .catch((error) => {
                console.error("An error occurred during signup:", error);
              });
            // setDialogInvoice(true)
            // props?.openInvoice(true);
            // setIsOpen(true);
            // setPersonName(inviteData?.clientData);
            // delete inviteData?.clientData;
            // setAddWorkerData(inviteData);
          }
        } else {
          let payload = { ...sessionData, ...ProviderRegisterFinalData };
          if (ProviderRegisterFinalData) {
            dispatch(SignupProviderForClient(payload))
              .then((response) => {
                if (response.success) {
                  if (SignUpData?.registrationType === "forClient") {
                    navigate("/login");
                    // dispatch(ResetProviderSignUpData());
                    // dispatch(ResetProviderRegisterFinalData());
                  } else {
                    checkListData(
                      response?.data?.client_org_id,
                      response?.data?.provider_org_id,
                      response?.data?.token
                    );
                    let ids = {
                      client_org_id: response?.data?.client_org_id,
                      provider_org_id: response?.data?.provider_org_id,
                    };
                    setComplianceData((prevValue) => {
                      return {
                        ...prevValue, // Retain the existing properties of complianceData
                        ...ids,       // Merge or overwrite with properties from ids
                      };
                    });
                    localStorage.setItem(
                      "conserve_authtoken",
                      response?.data?.token
                    );
                    // dispatch(ResetProviderRegisterFinalData());
                  }
                } else {
                  console.error(
                    "Operation failed:",
                    response.errorMessage || "Unknown error"
                  );
                }
              })
              .catch((error) => {
                console.error("An error occurred during signup:", error);
              });
            // setDialogInvoice(true)
            // props?.openInvoice(true);
            // setIsOpen(true);
            // setPersonName(inviteData?.clientData);
            // delete inviteData?.clientData;
            // setAddWorkerData(inviteData);
          }
        }
      }
    }
  }, [isInvoiceSuccess, sessionData, dispatch, navigate]);

  const downloadInvoice = () => {
    // invoiceData.xero_invoice_pdf
    if (invoiceData && invoiceData.xero_invoice_pdf) {
      const link = document.createElement("a");
      link.href = invoiceData.xero_invoice_pdf;
      link.download = "invoice.pdf"; // Set the file name
      link.target = "_blank"; // Open in new tab for security if on another domain
      link.click();
    } else {
      console.error("No PDF URL found in invoice data");
    }
  };

  useEffect(() => {
    if (ProviderContactData?.inviteProType?.client_pay_default) {
      if (props?.existingUser) {
        let payload = {
          ...props?.ProviderExistingUser,
          isProviderExist: true,
        };
        dispatch(RegisterExistingProviderForClient(payload))
          .then((response) => {
            if (response.success) {
              checkListData(
                response?.data?.client_org_id,
                response?.data?.provider_org_id,
                response?.data?.token
              );
              localStorage.setItem("conserve_authtoken", response?.data?.token);
              let ids = {
                client_org_id: response?.data?.client_org_id,
                provider_org_id: response?.data?.provider_org_id,
              };
              setComplianceData((prevValue) => {
                return {
                  ...prevValue, // Retain the existing properties of complianceData
                  ...ids,       // Merge or overwrite with properties from ids
                };
              });
            } else {
              console.error(
                "Operation failed:",
                response.errorMessage || "Unknown error"
              );
            }
          })
          .catch((error) => {
            console.error("An error occurred during signup:", error);
          });
      } else {
        checkListData(
          props?.GetProviderChecklistByClient?.client_org_id,
          props?.GetProviderChecklistByClient?.provider_org_id
        );
      }
    }
  }, []);

  console.log(
    "GetProviderChecklistByClient::",
    props?.GetProviderChecklistByClient
  );
  return (
    <React.Fragment>
      <div className="px-6">
        <Steps current={step}>
          {ProviderContactData?.inviteProType?.client_pay_default == false && (
            <Steps.Item title="Payment" />
          )}
          <Steps.Item title="Compliance checklist" />
          <Steps.Item title="Workers assignment" />
        </Steps>
      </div>
      <form>
        <div className="mt-6 add-client-form px-6">
          {ProviderContactData?.inviteProType?.client_pay_default == false &&
          step === 0 ? (
            <React.Fragment>
              {checkPayment && (
                <div className="pl-6 pr-6">
                  <h5 className="text-blue-800 font-semibold text-2xl mb-2">
                    Payment options
                  </h5>
                  <p className="text-gray-700 mb-3">
                    This annual payment covers the cost of inviting additional
                    Workers to connect with Clients.
                  </p>
                  <div>
                    <div className="w-full">
                      <form>
                        <Card className="p-7 border border-gray-200 rounded-xl">
                          <h4 className="font-bold text-gray-700 text-base mb-1">
                            Seats
                          </h4>
                          <p className="text-gray-700 mb-7">
                            {1} additional Worker(s)
                          </p>
                          <h4 className="font-bold text-gray-700 text-base mb-1">
                            Annual Fee
                          </h4>
                          <p className="text-gray-700">$ {50}</p>
                        </Card>
                        <Card className="p-7 border border-gray-200 rounded-xl mt-7">
                          <h4 className="font-bold text-gray-700 text-base mb-5">
                            Select a payment method
                          </h4>
                          <div className="inline-flex rounded-md mb-7 validation-filter">
                            <Segment
                              className="payment-btn"
                              onClick={preventDefault}
                            >
                              <Segment.Item value="Stripe">
                                <span
                                  onClick={() => {
                                    handleSubmit("stripe");
                                    // setDocAdd(true);
                                    // setPaypalMethod(false);
                                  }}
                                >
                                  <img
                                    src="/assets/icons-svg/stripe.svg"
                                    alt=""
                                  />{" "}
                                  Stripe
                                </span>
                              </Segment.Item>
                              <Segment.Item value="Paypal">
                                <span
                                  onClick={() => {
                                    handleSubmit();
                                    // setDocAdd(true);
                                    // setPaypalMethod(false);
                                  }}
                                >
                                  <img
                                    src="/assets/icons-svg/paypal.svg"
                                    alt=""
                                  />{" "}
                                  Paypal
                                </span>
                              </Segment.Item>
                            </Segment>
                          </div>
                        </Card>
                      </form>
                    </div>
                    {/* <Dialog isOpen={dialogIsOpen} onClose={() => setIsOpen(false)}>
                  <div className="flex border-b mb-5 pb-5 px-6 pt-6">
                    <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
                      <img src="assets/icons-svg/building.svg" alt="" />
                    </span>
                    <div>
                      <h5 className="mb-1 text-gray-900 text-base font-semibold">
                        Connect with [Client name]
                      </h5>
                      <p className="text-gray-600">
                        Ensure a successful connection with the client by
                        completing these steps
                      </p>
                    </div>
                  </div>
                </Dialog> */}
                  </div>
                </div>
              )}

              {!checkPayment && (
                <React.Fragment>
                  <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
                    <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_12450_78578)">
                          <path
                            d="M19.1658 20.8745C19.9709 20.8745 20.6214 20.2225 20.6214 19.4191V15.5467C20.6214 14.7434 19.9709 14.0914 19.1658 14.0914H13.3557C12.5506 14.0914 11.9 14.7434 11.9 15.5467V19.4191C11.9 20.2225 12.5506 20.8745 13.3557 20.8745H19.1658ZM19.1658 19.5128H13.3557C13.304 19.5128 13.262 19.4707 13.262 19.4191V15.5467C13.262 15.4951 13.304 15.4531 13.3557 15.4531H19.1658C19.2174 15.4531 19.2594 15.4951 19.2594 15.5467V19.4191C19.2594 19.4707 19.2174 19.5128 19.1658 19.5128Z"
                            fill="#0E4E9A"
                            stroke="#0E4E9A"
                            stroke-width="0.2"
                          />
                          <path
                            d="M19.2593 15.5458C19.2593 16.0251 18.8703 16.4141 18.391 16.4141H14.1303C13.651 16.4141 13.262 16.0251 13.262 15.5458C13.262 15.1699 12.9569 14.8648 12.581 14.8648C12.2051 14.8648 11.9 15.1699 11.9 15.5458C11.9 16.7769 12.8992 17.7761 14.1303 17.7761H18.391C19.6221 17.7761 20.6213 16.7769 20.6213 15.5458C20.6213 15.1699 20.3162 14.8648 19.9403 14.8648C19.5644 14.8648 19.2593 15.1699 19.2593 15.5458Z"
                            fill="#0E4E9A"
                            stroke="#0E4E9A"
                            stroke-width="0.2"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M17.81 14.889C18.1307 14.889 18.391 14.6287 18.391 14.308V13.5333C18.391 12.785 17.7852 12.1777 17.0354 12.1777H15.486C14.7362 12.1777 14.1304 12.785 14.1304 13.5333V14.308C14.1304 14.6287 14.3907 14.889 14.7114 14.889H17.81ZM17.229 13.8727H15.2924V13.5333C15.2924 13.4265 15.3792 13.3397 15.486 13.3397H17.0354C17.1422 13.3397 17.229 13.4265 17.229 13.5333V13.8727Z"
                            fill="#0E4E9A"
                            stroke="#0E4E9A"
                            stroke-width="0.2"
                          />
                          <path
                            d="M10.78 14.0664C8.90316 14.098 5.33487 14.7346 3.56036 18.5573C3.34841 19.0142 3.38479 19.737 4.73027 19.776C6.07575 19.815 8.606 19.7923 10.166 19.776"
                            stroke="#0E4E9A"
                            stroke-width="1.95"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <circle
                            cx="11.5698"
                            cy="7.90674"
                            r="3.75986"
                            stroke="#0E4E9A"
                            stroke-width="1.95"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_12450_78578">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <div>
                      <h5 className="mb-0 text-gray-900 text-base font-semibold">
                        Connect with [Client name]
                      </h5>
                      <p>
                        Ensure a successful connection with the client by
                        completing these steps
                      </p>
                    </div>
                  </div>
                  <div className="pl-4 pr-4">
                    <div className="mb-5 bg-gray-50 rounded-xl border border-gray-200 p-5 rounded-xl ">
                      <h3 className="mb-1 text-gray-700 font-bold text-base">
                        Payment successful and invites sent to Workers!
                      </h3>
                      <p className="mb-4 text-gray-700">
                        Thank you for your payment. A confirmation of this
                        transaction has been sent to your email.
                      </p>
                      <h3 className="mb-1 text-gray-700 font-bold text-base">
                        Invoice number{" "}
                      </h3>
                      <p className="mb-4 text-gray-700">
                        <p>{invoiceData?.xero_invoice_number}</p>
                      </p>
                      <Button
                        className="flex gap-1  text-blue-700 hover:text-blue-900 focus:text-blue-700"
                        onClick={downloadInvoice}
                      >
                        Download invoice{" "}
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.583 13.2866V14.2866C17.583 15.6868 17.583 16.3868 17.3105 16.9216C17.0708 17.392 16.6884 17.7745 16.218 18.0141C15.6832 18.2866 14.9831 18.2866 13.583 18.2866H6.58301C5.18288 18.2866 4.48281 18.2866 3.94803 18.0141C3.47763 17.7745 3.09517 17.392 2.85549 16.9216C2.58301 16.3868 2.58301 15.6868 2.58301 14.2866V13.2866M14.2497 9.11995L10.083 13.2866M10.083 13.2866L5.91634 9.11995M10.083 13.2866V3.28662"
                            stroke="#5786CC"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
              ProviderContactData?.inviteProType?.client_pay_default
                ? step == 0
                : step == 1
            ) ? (
            <div id={firstStep}>
              <div className="mb-5">
                <h4 className="text-blue-800 text-base font-bold">
                  Compliance checklist
                </h4>
                <p className="text-gray-700">
                  The Compliance checklist ensures that you meet all the
                  necessary requirements set by the client, allowing you to
                  demonstrate your compliance with their specified guidelines
                  and regulations.
                </p>
              </div>
              <div className="flex flex-col gap-5">
                {combinedProvCheckDoc?.map((item, index) => {
                  return (
                    <React.Fragment>
                      <div
                        className="flex justify-between bg-gray-50 p-5 rounded-xl border border-gray-200"
                        key={index}
                      >
                        <Accordion
                          open={open === item?.checklist_doc_id}
                          icon={
                            <Icon id={item?.checklist_doc_id} open={open} />
                          }
                        >
                          <div className="flex justify-between">
                            <h4 className="text-gray-700 font-bold ">
                              {index + 1}. {item?.document_type?.doc_type_name}
                            </h4>
                            <div className="flex ml-auto gap-3 items-center">
                              <Button
                                className="flex  text-xs items-center text-blue-700 font-semibold gap-2"
                                onClick={() =>
                                  handleOpen(item?.checklist_doc_id)
                                }
                              >
                                Upload{" "}
                                <svg
                                  width="21"
                                  height="21"
                                  viewBox="0 0 21 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.583 3H2.58301M15.083 11.3333L10.083 6.33333M10.083 6.33333L5.08301 11.3333M10.083 6.33333V18"
                                    stroke="#5786CC"
                                    stroke-width="1.66667"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </Button>
                            </div>
                          </div>

                          <AccordionBody className="grid-cols-2 grid gap-2">
                            <div className="p-4 rounded-xl border border-gray-300 flex items-center justify-between w-full col-span-full mb-2">
                              {complianceData["doc" + index] == "" && (
                                <Upload
                                  value={""}
                                  accept={".jpg, .jpeg, .png, .gif"}
                                  showList={false}
                                  onChange={(e) => {
                                    handleForm(e, "doc", index);
                                  }}
                                  className="w-full"
                                >
                                  <div className="text-center">
                                    <div className="flex items-center justify-center flex-col">
                                      {" "}
                                      <span className="flex w-9 h-9 items-center justify-center rounded-full bg-gray-200 outline outline-8 outline-gray-50 outline-offset-0">
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                                            GetProviderChecklistByClientstroke="#475467"
                                            strokeWidth="1.667"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                        </svg>
                                      </span>
                                      <span className="pt-4">
                                        <span className="text-blue-700 font-semibold mr-1">
                                          Click to upload
                                        </span>{" "}
                                        <span className="text-xs text-gray-600">
                                          {" "}
                                          or drag and drop ( max. 25mb)
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  {/* {sizeError && (
                                      <div className="text-red-500 text-sm mt-1">
                                        {sizeError}
                                      </div>
                                    )} */}
                                </Upload>
                              )}
                              {complianceData["doc" + index] != "" && (
                                <React.Fragment>
                                  <div className="flex  gap-4">
                                    <span className="w-9 h-9 rounded-full border-4 border-blue-50 bg-blue-200 flex justify-center items-center">
                                      <img
                                        src="/assets/icons-svg/upload-pdf.svg"
                                        alt=""
                                      />
                                    </span>
                                    <div className="flex flex-col justify-center">
                                      <span className="text-gray-700 text-xs font-medium">
                                        {complianceData["doc" + index]?.name}
                                      </span>
                                    </div>
                                  </div>

                                  <button
                                    onClick={(e) => {
                                      handleDeletePDF(e, "doc", index);
                                    }}
                                  >
                                    <svg
                                      width="20"
                                      height="19"
                                      viewBox="0 0 24 25"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                        stroke="#344054"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                </React.Fragment>
                              )}
                            </div>
                            <div className="mb-5">
                              <label className="font-medium text-gray-700">
                                Document name
                              </label>
                              <div className="flex gap-2.5 mt-1">
                                <ClientInput
                                  name={"doc_name"}
                                  inputchangehandler={(e) =>
                                    handleForm(e, "doc_name", index)
                                  }
                                  className="w-full h-10 select-options"
                                />
                              </div>
                            </div>
                            {item?.document_type?.req_issuer && (
                              <div className="mb-4">
                                <label className="font-medium text-gray-700">
                                  Issuing authority
                                </label>
                                <div className="flex gap-2.5 mt-1">
                                  <ClientInput
                                    name={"req_issuer"}
                                    inputchangehandler={(e) =>
                                      handleForm(e, "req_issuer", index)
                                    }
                                    className="w-full h-10 select-options"
                                  />
                                </div>
                              </div>
                            )}

                            {item?.document_type?.req_policy_no && (
                              <div className="mb-4">
                                <label className="font-medium text-gray-700">
                                  Policy number
                                </label>
                                <div className="flex gap-2.5 mt-1">
                                  <ClientInput
                                    name={"req_policy_no"}
                                    inputchangehandler={(e) =>
                                      handleForm(e, "req_policy_no", index)
                                    }
                                    className="w-full h-10 select-options"
                                  />
                                </div>
                              </div>
                            )}
                            {item?.document_type?.req_amount_insured && (
                              <div className="mb-4">
                                <label className="font-medium text-gray-700">
                                  Amount insured
                                </label>

                                <div className="flex">
                                  <div className="w-full relative">
                                    <span className="adon_icon absolute left-2 top-10px">
                                      <svg
                                        width="16"
                                        height="17"
                                        viewBox="0 0 20 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M5 13.789a3.333 3.333 0 0 0 3.333 3.333h3.334a3.333 3.333 0 0 0 0-6.667H8.333a3.333 3.333 0 1 1 0-6.666h3.334A3.333 3.333 0 0 1 15 7.122m-5-5V18.79"
                                          stroke="#667085"
                                          strokeWidth="1.667"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    <div className="w-full">
                                      <ClientInput
                                        type={"number"}
                                        placeholder="Enter value here"
                                        name={"req_amount_insured"}
                                        inputchangehandler={(e) =>
                                          handleForm(
                                            e,
                                            "req_amount_insured",
                                            index
                                          )
                                        }
                                        className="w-full h-10 select-options pl-8"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {item?.document_type?.allow_expiry && (
                              <div className="mb-4">
                                <label className="font-medium text-gray-700">
                                  Expiry date
                                </label>
                                <div className="mt-1 w-full">
                                  <DatePicker
                                    onChange={(e) =>
                                      handleForm(e, "allow_expiry", index)
                                    }
                                    placeholder="Select expire date"
                                    minDate={minDate}
                                  />
                                </div>
                              </div>
                            )}
                            {item?.document_type?.req_expiry_date_notes && (
                              <div className="mb-4">
                                <label className="font-medium text-gray-700">
                                  Additional info
                                </label>
                                <div className="flex gap-2.5 mt-1">
                                  <textarea
                                    name={"req_expiry_date_notes"}
                                    onChange={(e) =>
                                      handleForm(
                                        e,
                                        "req_expiry_date_notes",
                                        index
                                      )
                                    }
                                    rows={4}
                                    cols={40}
                                    placeholder="Provide a brief description to help Inductees..."
                                    className="moduleDescTextarea input-shadow text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline h-10"
                                  />
                                </div>
                              </div>
                            )}
                            {item?.document_type?.custom_fields &&
                              Object.values(
                                item?.document_type?.custom_fields
                              )?.map((item, customIndex) => {
                                return (
                                  <div className="mb-4" key={customIndex}>
                                    <label className="font-medium text-gray-700">
                                      {item}
                                    </label>
                                    <div className="flex gap-2.5 mt-1">
                                      {/* <input
                                          type="text"
                                          className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                                        /> */}
                                      <ClientInput
                                        name={item}
                                        inputchangehandler={(e) =>
                                          handleForm(
                                            e,
                                            "doctype_fields_data",
                                            index,
                                            item,
                                            customIndex
                                          )
                                        }
                                        className="w-full h-10 select-options"
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </AccordionBody>
                        </Accordion>
                      </div>
                    </React.Fragment>
                  );
                })}
                {combinedProvCheckDoc.length == 0 && (
                  <div className="h-full w-full text-center mt-2 mb-2">
                    No Data Available!
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div id={secondStep}>
              <div className="mb-5">
                <h4 className="text-blue-800 text-base font-bold">
                  Assign workers
                </h4>
                <p className="text-gray-700">
                  You will need to customise your workforce by choosing the
                  right workers to be assigned to the client. Keep in mind that
                  workers must complete the client's induction modules and
                  provide the requested documents as specified by the client.
                </p>
              </div>
              <Accordion open={open === 1}>
                <AccordionHeader
                  onClick={() => handleOpen(1)}
                  className="reset-accordian-btn"
                >
                  <div>
                    <div>
                      <h4 className="text-gray-700 font-semibold">
                        View only: Required documents
                      </h4>
                      <p>
                        This is the list of documents needed by the client for
                        worker approval. Please ensure that workers complete the
                        document submission process accurately and promptly to
                        facilitate the approval process and enable seamless
                        collaboration with the client.
                      </p>
                    </div>
                    <Button className="flex text-blue-700 font-semibold gap-2"></Button>
                  </div>
                </AccordionHeader>
                <AccordionBody className="grid-cols-2 grid gap-2">
                  <ul>
                    {combinedWorkerCheckDoc.map((item, index) => {
                      return (
                        <li key={index}>
                          <h4 className="text-gray-700 font-bold ">
                            {index + 1}. {item?.document_type?.doc_type_name}
                          </h4>
                        </li>
                      );
                    })}
                  </ul>
                </AccordionBody>
              </Accordion>
              <div className="text-gray-700 mt-5">
                Please invite your workers in the Workers section of your
                Dashboard,
              </div>
            </div>
          )}
        </div>
        {checkError && (
          <div className="flex flex-column">
            <p className="text-xs text-red-600 mt-2 ml-5">
              {"All field's are required!"}
            </p>
          </div>
        )}
        <div className="flex dailog-footer gap-2 justify-end">
          <BtnLight
            className="w-auto px-4"
            onClick={onPrevious}
            disabled={step === 0}
          >
            Cancel
          </BtnLight>
          {/* <BtnPrimaryLight className="w-auto px-4">
            Save and continue another time
          </BtnPrimaryLight> */}

          <BtnPrimary
            className="w-auto px-4"
            onClick={() => onNext(step)}
            // disabled={step === 0}
            variant="solid"
          >
            {step === 0 ? (
              <span className="flex justify-center">
                Continue{" "}
                <img
                  src="assets/icons-svg/arrow-right.svg"
                  alt=""
                  className="ml-2"
                />{" "}
              </span>
            ) : step === 1 ? (
              <span className="flex justify-center">
                Continue{" "}
                <img
                  src="assets/icons-svg/arrow-right.svg"
                  alt=""
                  className="ml-2"
                />{" "}
              </span>
            ) : (
              <span>Complete</span>
            )}
          </BtnPrimary>
        </div>
      </form>
    </React.Fragment>
  );
};

export default ConnectClient;

// import React, { useState } from "react";
// import Steps from "../../../../common/element/Steps";
// import BtnLight from "../../../../common/element/Buttons/btnLight";
// import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
// import Button from "../../../../common/element/Buttons";
// import BtnPrimaryLight from "../../../../common/element/Buttons/primaryLightBtn";
// import Radio from "../../../../common/element/Radio";
// import Segment from "../../../../common/element/Segment";
// import Dialog from "../../../../common/element/Dialog/Dialog";
// import Table from "../../../../common/element/Table";
// import Scrollbar from "../../../../common/element/ScrollBar";
// import {
//   Accordion,
//   AccordionHeader,
//   AccordionBody,
// } from "@material-tailwind/react";

// const { Tr, Th, Td, THead, TBody } = Table;

// const ConnectClient = () => {
//   const [step, setStep] = useState(0);

//   const [stripe, setStripe] = useState(false);
//   const [open, setOpen] = useState(true);

//   const [dialogIsOpen, setIsOpen] = useState(false);

//   const openDialog = () => {
//     setIsOpen(true);
//   };

//   const onDialogClose = (e) => {
//     setIsOpen(false);
//   };

//   const onDialogOk = (e) => {
//     setIsOpen(false);
//   };

//   const handleOpen = (value) => setOpen(open === value ? 0 : value);
//   function Icon({ id, open }) {
//     return (
//       <svg
//         width="20"
//         height="20"
//         viewBox="0 0 20 20"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="M9.68296 1.97217V18.7722M1.28296 10.3722H18.083"
//           stroke="#5786CC"
//           stroke-width="2.4"
//           stroke-linecap="round"
//           stroke-linejoin="round"
//         />
//       </svg>
//     );
//   }
//   const onChange = (nextStep) => {
//     if (nextStep < 0) {
//       setStep(0);
//     } else {
//       setStep(nextStep);
//     }
//   };
//   const onNext = () => onChange(step + 1);

//   const onPrevious = () => onChange(step - 1);

//   const activateLasers = (e) => {
//     e.preventDefault();
//   };

//   const preventDefault = (e) => {
//     e.preventDefault();
//   };
//   const showStripe = (e) => {
//     e.preventDefault();
//     setStripe(true);
//   };
//   const hideStripe = (e) => {
//     setStripe(false);
//     e.preventDefault();
//   };

//   return (
//     <React.Fragment>
//       <div className="px-6">
//         <Steps current={step}>
//           <Steps.Item title="Payment" />
//           <Steps.Item title="Compliance checklist" />
//           <Steps.Item title="Workers assignment" />
//         </Steps>
//       </div>
//       <form>
//         <div className="mt-6 add-client-form px-6">
//           {step === 0 && (
//             <div>
//               <div className="mb-5 bg-gray-50 rounded-xl border border-gray-200 p-5 rounded-xl">
//                 <h3 className="mb-1 text-gray-700 font-bold text-base">
//                   Payment details
//                 </h3>
//                 <p className="mb-2 text-gray-700">
//                   This is a one-time payment to connect to client lorem ipsum
//                   etc etc to inform the use on what they’re paying for
//                 </p>
//                 <h2 className="text-gray-700 font-bold text-xl">$00.00</h2>
//               </div>

//               <div className="mb-5 bg-gray-50 rounded-xl border border-gray-200 p-5 rounded-xl">
//                 <h4 className="font-bold text-gray-700 text-base mb-5">
//                   Select a payment method
//                 </h4>
//                 <div className="inline-flex rounded-md  mb-7">
//                   <Segment className="payment-btn" onClick={preventDefault}>
//                     <Segment.Item value="Stripe">
//                       <span onClick={showStripe}>
//                         <img src="assets/icons-svg/stripe.svg" alt="" />
//                         Stripe
//                       </span>
//                     </Segment.Item>
//                     <Segment.Item value="Paypal">
//                       <span onClick={hideStripe}>
//                         <img src="assets/icons-svg/paypal.svg" alt="" /> Paypal
//                       </span>
//                     </Segment.Item>
//                   </Segment>
//                 </div>
//                 {stripe && (
//                   <React.Fragment>
//                     <h4 className="font-bold text-gray-700 text-base mb-5">
//                       Card information
//                     </h4>
//                     <div className="flex gap-4">
//                       <div className="w-4/5 mb-4">
//                         <label
//                           class="block text-gray-700  mb-2 text-xs font-medium"
//                           for="Trading Name"
//                         >
//                           Name on card
//                         </label>
//                         <span className="input-wrapper">
//                           <input
//                             class="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight"
//                             type="text"
//                             name="ccNumber"
//                             autocomplete="off"
//                             placeholder="Name on card"
//                             value=""
//                           />
//                         </span>
//                       </div>
//                       <div className="form-item vertical w-1/5 mb-4">
//                         <label className="form-label  block text-gray-700  mb-2 text-xs font-medium">
//                           Expiry
//                         </label>
//                         <span className="input-wrapper">
//                           <input
//                             className="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight"
//                             type="text"
//                             name="cardExpiry"
//                             autocomplete="off"
//                             placeholder="XX / XX"
//                             value="XX / XX"
//                             inputmode="numeric"
//                           />
//                         </span>
//                       </div>
//                     </div>
//                     <div className="flex gap-4">
//                       <div className="w-4/5 mb-4">
//                         <label
//                           class="block text-gray-700  mb-2 text-xs font-medium"
//                           for="Trading Name"
//                         >
//                           Card number
//                         </label>
//                         <span className="input-wrapper">
//                           <input
//                             class="pl-12 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight"
//                             type="text"
//                             name="ccNumber"
//                             autocomplete="off"
//                             placeholder="•••• •••• •••• ••••"
//                             value=""
//                             inputmode="numeric"
//                           />
//                           <div class="input-suffix-end">
//                             <svg
//                               width="18"
//                               height="19"
//                               viewBox="0 0 18 19"
//                               fill="none"
//                               xmlns="http://www.w3.org/2000/svg"
//                             >
//                               <path
//                                 d="M16.5 8.33h-15m0-1.35v5.7c0 .84 0 1.26.163 1.58a1.5 1.5 0 0 0 .656.656c.32.163.74.163 1.581.163h10.2c.84 0 1.26 0 1.581-.163a1.5 1.5 0 0 0 .656-.656c.163-.32.163-.74.163-1.58v-5.7c0-.841 0-1.261-.163-1.582a1.5 1.5 0 0 0-.656-.655c-.32-.164-.74-.164-1.581-.164H3.9c-.84 0-1.26 0-1.581.164a1.5 1.5 0 0 0-.656.655C1.5 5.72 1.5 6.14 1.5 6.98z"
//                                 stroke="#000"
//                                 stroke-width="1.5"
//                                 stroke-linecap="round"
//                                 stroke-linejoin="round"
//                               />
//                             </svg>
//                           </div>
//                         </span>
//                       </div>
//                       <div className="form-item vertical w-1/5 mb-4">
//                         <label className="form-label  block text-gray-700  mb-2 text-xs font-medium">
//                           CVV
//                         </label>
//                         <span className="input-wrapper">
//                           <input
//                             className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight"
//                             type="text"
//                             name="cardExpiry"
//                             autocomplete="off"
//                             placeholder="•••"
//                             value="•••"
//                             inputmode="numeric"
//                           />
//                         </span>
//                       </div>
//                     </div>
//                     <Radio className="mt-3 flex gap-2">
//                       I understand and accept that this licensing fee is valid
//                       only for one year and is non-refundable during that
//                       period.
//                     </Radio>
//                   </React.Fragment>
//                 )}
//               </div>

//               <div className="mb-5 bg-gray-50 rounded-xl border border-gray-200 p-5 rounded-xl">
//                 <h3 className="mb-1 text-gray-700 font-bold text-base">
//                   Payment successful!{" "}
//                 </h3>
//                 <p className="mb-4 text-gray-700">
//                   Thank you for your payment. A confirmation of this transaction
//                   has been sent to your email.
//                 </p>
//                 <h3 className="mb-1 text-gray-700 font-bold text-base">
//                   Invoice number{" "}
//                 </h3>
//                 <p className="mb-4 text-gray-700">XXXXXXXXXXX</p>

//                 <Button className="flex gap-1  text-blue-700 hover:text-blue-900 focus:text-blue-700">
//                   Download invoice{" "}
//                   <svg
//                     width="21"
//                     height="21"
//                     viewBox="0 0 21 21"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       d="M17.583 13.2866V14.2866C17.583 15.6868 17.583 16.3868 17.3105 16.9216C17.0708 17.392 16.6884 17.7745 16.218 18.0141C15.6832 18.2866 14.9831 18.2866 13.583 18.2866H6.58301C5.18288 18.2866 4.48281 18.2866 3.94803 18.0141C3.47763 17.7745 3.09517 17.392 2.85549 16.9216C2.58301 16.3868 2.58301 15.6868 2.58301 14.2866V13.2866M14.2497 9.11995L10.083 13.2866M10.083 13.2866L5.91634 9.11995M10.083 13.2866V3.28662"
//                       stroke="#5786CC"
//                       stroke-width="1.66667"
//                       stroke-linecap="round"
//                       stroke-linejoin="round"
//                     />
//                   </svg>
//                 </Button>
//               </div>
//             </div>
//           )}
//           {step === 1 && (
//             <div className="flex flex-col gap-5">
//               <div className="flex justify-between bg-gray-50  p-5 rounded-xl border border-gray-200">
//                 <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
//                   <div className="flex justify-between">
//                     <h4 className="text-gray-700 font-bold ">
//                       1. [Document type]
//                     </h4>
//                     <div className="flex ml-auto gap-3 items-center">
//                       <Button
//                         className="font-semibold align-baseline text-xs text-blue-700 hover:text-blue-900 focus:text-blue-700 "
//                         onClick={() => openDialog()}
//                       >
//                         Select from your documents
//                       </Button>
//                       <span className="font-semibold align-baseline text-xs">
//                         or
//                       </span>
//                       <Button
//                         className="flex  text-xs items-center text-blue-700 font-semibold gap-2"
//                         onClick={() => handleOpen(1)}
//                       >
//                         Upload{" "}
//                         <svg
//                           width="21"
//                           height="21"
//                           viewBox="0 0 21 21"
//                           fill="none"
//                           xmlns="http://www.w3.org/2000/svg"
//                         >
//                           <path
//                             d="M17.583 3H2.58301M15.083 11.3333L10.083 6.33333M10.083 6.33333L5.08301 11.3333M10.083 6.33333V18"
//                             stroke="#5786CC"
//                             stroke-width="1.66667"
//                             stroke-linecap="round"
//                             stroke-linejoin="round"
//                           />
//                         </svg>
//                       </Button>
//                     </div>
//                   </div>

//                   <AccordionBody>
//                     <ul className="flex flex-col gap-5">
//                       <li className="font-semibold text-xs text-gray-700">
//                         1 [Document name]
//                       </li>
//                       <li className="font-semibold text-xs text-gray-700">
//                         2 [Document name]
//                       </li>
//                       <li className="font-semibold text-xs text-gray-700">
//                         3 [Document name]
//                       </li>
//                       <li className="font-semibold text-xs text-gray-700">
//                         4 [Document name]
//                       </li>
//                     </ul>
//                   </AccordionBody>
//                 </Accordion>
//               </div>
//               <div className="flex justify-between bg-gray-50 h-16 p-5 rounded-xl border border-gray-200">
//                 <h4 className="text-gray-700 font-bold ">1. [Document type]</h4>
//                 <div className="flex ml-auto gap-3 items-center">
//                   <Button className="font-semibold align-baseline text-xs text-blue-700 hover:text-blue-900 focus:text-blue-700 ">
//                     Select from your documents
//                   </Button>
//                   <span className="font-semibold align-baseline text-xs">
//                     or
//                   </span>
//                   <Button className="flex  text-xs items-center text-blue-700 font-semibold gap-2">
//                     Upload{" "}
//                     <svg
//                       width="21"
//                       height="21"
//                       viewBox="0 0 21 21"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="M17.583 3H2.58301M15.083 11.3333L10.083 6.33333M10.083 6.33333L5.08301 11.3333M10.083 6.33333V18"
//                         stroke="#5786CC"
//                         stroke-width="1.66667"
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                       />
//                     </svg>
//                   </Button>
//                 </div>
//               </div>
//               <div className="flex justify-between bg-gray-50 h-16 p-5 rounded-xl border border-gray-200">
//                 <h4 className="text-gray-700 font-bold ">1. [Document type]</h4>
//                 <div className="flex ml-auto gap-3 items-center">
//                   <Button className="font-semibold align-baseline text-xs text-blue-700 hover:text-blue-900 focus:text-blue-700 ">
//                     Select from your documents
//                   </Button>
//                   <span className="font-semibold align-baseline text-xs">
//                     or
//                   </span>
//                   <Button className="flex  text-xs items-center text-blue-700 font-semibold gap-2">
//                     Upload{" "}
//                     <svg
//                       width="21"
//                       height="21"
//                       viewBox="0 0 21 21"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="M17.583 3H2.58301M15.083 11.3333L10.083 6.33333M10.083 6.33333L5.08301 11.3333M10.083 6.33333V18"
//                         stroke="#5786CC"
//                         stroke-width="1.66667"
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                       />
//                     </svg>
//                   </Button>
//                 </div>
//               </div>
//               <div className="flex justify-between bg-gray-50 h-16 p-5 rounded-xl border border-gray-200">
//                 <h4 className="text-gray-700 font-bold ">1. [Document type]</h4>
//                 <div className="flex ml-auto gap-3 items-center">
//                   <Button className="font-semibold align-baseline text-xs text-blue-700 hover:text-blue-900 focus:text-blue-700 ">
//                     Select from your documents
//                   </Button>
//                   <span className="font-semibold align-baseline text-xs">
//                     or
//                   </span>
//                   <Button className="flex  text-xs items-center text-blue-700 font-semibold gap-2">
//                     Upload{" "}
//                     <svg
//                       width="21"
//                       height="21"
//                       viewBox="0 0 21 21"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="M17.583 3H2.58301M15.083 11.3333L10.083 6.33333M10.083 6.33333L5.08301 11.3333M10.083 6.33333V18"
//                         stroke="#5786CC"
//                         stroke-width="1.66667"
//                         stroke-linecap="round"
//                         stroke-linejoin="round"
//                       />
//                     </svg>
//                   </Button>
//                 </div>
//               </div>
//             </div>
//           )}
//           {step === 2 && (
//             <>
//               <div className="mb-5">
//                 <h4 className="text-blue-800 text-base font-bold">
//                   Assign workers
//                 </h4>
//                 <p className="text-gray-700">
//                   You will need to customise your workforce by choosing the
//                   right workers to be assigned to the client. Keep in mind that
//                   workers must complete the client's induction modules and
//                   provide the requested documents as specified by the client.
//                 </p>
//               </div>
//               <div className="flex border border-gray-200 rounded-xl p-4">
//                 <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
//                   <AccordionHeader
//                     onClick={() => handleOpen(1)}
//                     className="border-0 text-base"
//                   >
//                     <div className="text-left">
//                       <h4 className="text-gray-700 font-semibold">
//                         View only: Required documents
//                       </h4>
//                       <p className="text-gray-600 text-sm font-normal">
//                         This is the list of documents needed by the client for
//                         worker approval. Please ensure that workers complete the
//                         document submission process accurately and promptly to
//                         facilitate the approval process and enable seamless
//                         collaboration with the client.
//                       </p>
//                     </div>
//                   </AccordionHeader>
//                   <AccordionBody className="border-t border-gray-200">
//                     <ul className="flex flex-col gap-5">
//                       <li className="font-semibold text-xs text-gray-700">
//                         1 [Document name]
//                       </li>
//                       <li className="font-semibold text-xs text-gray-700">
//                         2 [Document name]
//                       </li>
//                       <li className="font-semibold text-xs text-gray-700">
//                         3 [Document name]
//                       </li>
//                       <li className="font-semibold text-xs text-gray-700">
//                         4 [Document name]
//                       </li>
//                     </ul>
//                   </AccordionBody>
//                 </Accordion>
//               </div>
//               <div className="text-gray-700 mt-5">
//                 Please invite your workers in the Workers section of your
//                 Dashboard,
//               </div>
//             </>
//           )}
//         </div>
//         <div className="flex dailog-footer gap-2 justify-end">
//           <BtnLight
//             className="w-auto px-4"
//             onClick={onPrevious}
//             disabled={step === 0}
//           >
//             Cancel
//           </BtnLight>
//           <BtnPrimaryLight className="w-auto px-4">
//             Save and continue another time
//           </BtnPrimaryLight>

//           <BtnPrimary
//             className="w-auto px-4"
//             onClick={onNext}
//             disabled={step === 0}
//             variant="solid"
//           >
//             {step === 0 ? (
//               <span className="flex justify-center">
//                 Continue{" "}
//                 <img
//                   src="assets/icons-svg/arrow-right.svg"
//                   alt=""
//                   className="ml-2"
//                 />{" "}
//               </span>
//             ) : (
//               <span>Complete</span>
//             )}
//           </BtnPrimary>
//         </div>
//       </form>

//       <Dialog
//         isOpen={dialogIsOpen}
//         width={800}
//         contentClassName="pb-0 px-0"
//         onClose={onDialogClose}
//         onRequestClose={onDialogClose}
//       >
//         <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
//           <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
//             <svg
//               width="24"
//               height="25"
//               viewBox="0 0 24 25"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 d="M14 2.91211V7.04265C14 7.6027 14 7.88273 14.109 8.09664C14.2049 8.2848 14.3578 8.43778 14.546 8.53366C14.7599 8.64265 15.0399 8.64265 15.6 8.64265H19.7305M14 17.6426H8M16 13.6426H8M20 10.6308V17.8426C20 19.5227 20 20.3628 19.673 21.0045C19.3854 21.569 18.9265 22.028 18.362 22.3156C17.7202 22.6426 16.8802 22.6426 15.2 22.6426H8.8C7.11984 22.6426 6.27976 22.6426 5.63803 22.3156C5.07354 22.028 4.6146 21.569 4.32698 21.0045C4 20.3628 4 19.5227 4 17.8426V7.44258C4 5.76242 4 4.92234 4.32698 4.28061C4.6146 3.71612 5.07354 3.25718 5.63803 2.96956C6.27976 2.64258 7.11984 2.64258 8.8 2.64258H12.0118C12.7455 2.64258 13.1124 2.64258 13.4577 2.72547C13.7638 2.79896 14.0564 2.92017 14.3249 3.08466C14.6276 3.27018 14.887 3.52961 15.4059 4.04847L18.5941 7.23669C19.113 7.75555 19.3724 8.01497 19.5579 8.31772C19.7224 8.58614 19.8436 8.87877 19.9171 9.18488C20 9.53015 20 9.89703 20 10.6308Z"
//                 stroke="black"
//                 stroke-width="2"
//                 stroke-linecap="round"
//                 stroke-linejoin="round"
//               />
//             </svg>
//           </span>
//           <div>
//             <h5 className="text-gray-900 text-base font-semibold">
//               Select Document
//             </h5>
//           </div>
//         </div>

//         <div className="h-72">
//           <Scrollbar>
//             <div className="px-6 table-responsive">
//               <div className=" border rounded-lg border-gray-200">
//                 <Table>
//                   <THead>
//                     <Tr>
//                       <Th>
//                         <div className="flex gap-2 items-center">
//                           Document name{" "}
//                           <span>
//                             <svg
//                               width="16"
//                               height="17"
//                               viewBox="0 0 16 17"
//                               fill="none"
//                               xmlns="http://www.w3.org/2000/svg"
//                             >
//                               <path
//                                 d="M7.99992 3.97607V13.3094M7.99992 13.3094L12.6666 8.64274M7.99992 13.3094L3.33325 8.64274"
//                                 stroke="#475467"
//                                 stroke-width="1.33333"
//                                 stroke-linecap="round"
//                                 stroke-linejoin="round"
//                               />
//                             </svg>
//                           </span>
//                         </div>
//                       </Th>
//                       <Th>
//                         <div className="flex gap-2 items-center">
//                           Document type{" "}
//                           <span>
//                             <svg
//                               width="16"
//                               height="17"
//                               viewBox="0 0 16 17"
//                               fill="none"
//                               xmlns="http://www.w3.org/2000/svg"
//                             >
//                               <path
//                                 d="M7.99992 3.97607V13.3094M7.99992 13.3094L12.6666 8.64274M7.99992 13.3094L3.33325 8.64274"
//                                 stroke="#475467"
//                                 stroke-width="1.33333"
//                                 stroke-linecap="round"
//                                 stroke-linejoin="round"
//                               />
//                             </svg>
//                           </span>
//                         </div>
//                       </Th>
//                       <Th>
//                         <div className="flex gap-2 items-center">
//                           {" "}
//                           Expiry date{" "}
//                           <span>
//                             <svg
//                               width="16"
//                               height="17"
//                               viewBox="0 0 16 17"
//                               fill="none"
//                               xmlns="http://www.w3.org/2000/svg"
//                             >
//                               <path
//                                 d="M7.99992 3.97607V13.3094M7.99992 13.3094L12.6666 8.64274M7.99992 13.3094L3.33325 8.64274"
//                                 stroke="#475467"
//                                 stroke-width="1.33333"
//                                 stroke-linecap="round"
//                                 stroke-linejoin="round"
//                               />
//                             </svg>
//                           </span>
//                         </div>
//                       </Th>
//                       <Th></Th>
//                     </Tr>
//                   </THead>
//                   <TBody>
//                     <Tr>
//                       <Td>[Document name]</Td>
//                       <Td>[Document type]</Td>
//                       <Td>DD/MM/YYYY</Td>
//                       <Td>
//                         <Button className="inline-block font-semibold align-baseline  text-blue-700 hover:text-blue-900 focus:text-blue-700 ">
//                           Select
//                         </Button>
//                       </Td>
//                     </Tr>
//                     <Tr>
//                       <Td>[Document name]</Td>
//                       <Td>[Document type]</Td>
//                       <Td>DD/MM/YYYY</Td>
//                       <Td>
//                         <Button className="inline-block font-semibold align-baseline  text-blue-700 hover:text-blue-900 focus:text-blue-700 ">
//                           Select
//                         </Button>
//                       </Td>
//                     </Tr>
//                     <Tr>
//                       <Td>[Document name]</Td>
//                       <Td>[Document type]</Td>
//                       <Td>DD/MM/YYYY</Td>
//                       <Td>
//                         <Button className="inline-block font-semibold align-baseline  text-blue-700 hover:text-blue-900 focus:text-blue-700 ">
//                           Select
//                         </Button>
//                       </Td>
//                     </Tr>
//                     <Tr>
//                       <Td>[Document name]</Td>
//                       <Td>[Document type]</Td>
//                       <Td>DD/MM/YYYY</Td>
//                       <Td>
//                         <Button className="inline-block font-semibold align-baseline  text-blue-700 hover:text-blue-900 focus:text-blue-700 ">
//                           Select
//                         </Button>
//                       </Td>
//                     </Tr>
//                     <Tr>
//                       <Td>[Document name]</Td>
//                       <Td>[Document type]</Td>
//                       <Td>DD/MM/YYYY</Td>
//                       <Td>
//                         <Button className="inline-block font-semibold align-baseline  text-blue-700 hover:text-blue-900 focus:text-blue-700 ">
//                           Select
//                         </Button>
//                       </Td>
//                     </Tr>
//                   </TBody>
//                 </Table>
//               </div>
//             </div>
//           </Scrollbar>
//         </div>
//       </Dialog>
//     </React.Fragment>
//   );
// };

// export default ConnectClient;
