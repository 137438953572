import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Card from "../../../../../../common/element/Card";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import Table from "../../../../../../common/element/Table";
import Scrollbar from "../../../../../../common/element/ScrollBar";
import * as userActions from "../../../../../../actions/index";
import moment from "moment";
import { GetComplianceChecklist } from "../../../../../../actions/Admin-Client/Admin-Client";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { decryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";

const ComplianceChecklist = (props) => {
  const { Tr, Th, Td, THead, TBody } = Table;
  const { id } = useParams();
  const orgId=decryptId(id)

  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [DocData, setDocData] = useState("");
  // const [selectSWMS, setSelectSWMS] = useState();
  // const [reviewDate, setReviewDate] = useState("");
  const [remainingDays, setRemainingDays] = useState(null);
  const functionAssignmentId = useSelector(
    (state) => state.functionAssignmentId.functionAssignmentId
  );

  // const GetAllComplianceDocsOfClient = useCallback(async () => {
  //   dispatch(
  //     userActions.GetAllComplianceDocsOfClient(

  //       // selectSWMS,
  //     )
  //   ).then((response) => {
  //     if (response.success === true) {
  //       const arrayData = response.data.rows;
  //       let document_data = [];
  //       for (let key in arrayData) {
  //         document_data.push({
  //           doc_type_name: arrayData[key]?.compliance_doc_name
  //             ? arrayData[key]?.compliance_doc_name
  //             : "Swms",
  //           document_type:
  //             arrayData[key]?.document_type?.document_type === "insurance"
  //               ? "Insurance"
  //               : arrayData[key]?.document_type?.document_type === "other"
  //               ? "Other"
  //               : "SWMS",
  //         });
  //       }

  //       setDocData(document_data);
  //       // setTotalPages(Math.ceil(response.data.count / filterlimit));
  //     } else if (response.status === 401) {
  //       handleUnauthorizedAccess();
  //     } else {
  //     }
  //   });
  //   // eslint-disable-next-line
  // }, [
  //   dispatch,
  //   // selectSWMS,
  // ]);

  // useEffect(() => {
  //   GetAllComplianceDocsOfClient();
  // }, [
  //   // selectSWMS
  // ]);
  const GetAllComplianceChecklistOfClient = useCallback(async () => {
    try {
      const response = await dispatch(GetComplianceChecklist(
        orgId,"created_date","desc","","","",
        1,
        "active"
      ));

      const { success, data, status } = response;

      if (success) {
        const { rows } = data;
        // setCountData(response?.data?.count);
        setDocData(rows);
        // setTotalPages(Math.ceil(response?.data?.count / filterlimit));
      } else if (status === 401) {
        handleUnauthorizedAccess();
      } else {
        console.error('Failed to fetch compliance checklist:', response);
        // Handle other types of errors or unsuccessful responses
      }
    } catch (error) {
      console.error('Failed to fetch compliance checklist:', error);
      // Handle unexpected errors
    }
  }, [
    dispatch,
    orgId,
  ]);

  useEffect(() => {
    GetAllComplianceChecklistOfClient();
  }, [
    GetAllComplianceChecklistOfClient,
    orgId,
  ]);
  // const GetClientOtherDetailsById = useCallback(async () => {

  //   dispatch(userActions.GetClientOtherDetailsById(id)).then((response) => {
  //     if (response.success === true) {
  //       const arrayData = response?.data;
  //       const currentDate = moment();
  //       const revDate = moment(arrayData?.review_comp_checklist)?.format(
  //         "DD/MM/YYYY"
  //       );
  //       setReviewDate(revDate);
  //       const daysDifference = moment(revDate).diff(currentDate, "days");
  //       setRemainingDays(daysDifference);
  //     } else if (response.status === 401) {
  //       handleUnauthorizedAccess();
  //     } else {
  //     }
  //   });
  //   // eslint-disable-next-line
  // }, [dispatch]);

  // useEffect(() => {
  //   GetClientOtherDetailsById();
  // }, []);

  return (
    <Card className="py-5 border border-gray-200 rounded-xl dark-shadow">
      <div className="px-5">
        <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-3">
          <div className="flex items-center">
            <span className="mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M9 16L11 18L15.5 13.5M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <p></p>
            <div className="flex flex-col">
              <span className="text-gray-900 text-base font-semibold">
                Compliance checklist{" "}
              </span>
              <p className="text-gray-600">
                {/* Due for renewal in the next {remainingDays} days{" "} */}
                Due for renewal in the next 30 days
              </p>
            </div>
          </div>
          {/* <Link to=></Link> */}
          <BtnLight className="w-20 h-9" onClick={() => props?.setDefaultTab('tab3')}>View All</BtnLight>
        </div>
      </div>

      <div className="h-64">
        <Scrollbar>
          <div className="px-5">
            <div className="overflow-hidden border rounded-lg border-gray-200">
              <Table>
                <TBody>
                  {DocData &&
                    DocData.map((item) => (
                      <Tr key={item?.compliance_doc_id}>
                        <Td>
                          {item?.checklist_name}
                          <span className="block text-gray-600">
                            {item?.document_type}
                          </span>
                        </Td>
                        <Td>{moment(item?.checklist_renewal_date).format("DD/MM/YYYY")}</Td>
                      </Tr>
                    ))}
                </TBody>
              </Table>
            </div>
          </div>
        </Scrollbar>
      </div>
    </Card>
  );
};
export default ComplianceChecklist;
