import React from "react";
import Card from "../../../../../../common/element/Card";
import Table from "../../../../../../common/element/Table";
import Tag from "../../../../../../common/element/Tag";
import Scrollbar from "../../../../../../common/element/ScrollBar";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import { GetAllOverviewSubmissionDocByProvider } from "../../../../../../actions/admin-worker/GetAllOverviewSubmissionDocByProvider";
import Badge from "../../../../../../common/element/Badge";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { decryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";
const Documents = () => {
  const { id } = useParams();
  const { Tr, Th, Td, THead, TBody } = Table;
  const dispatch = useDispatch();
  const { SpecificWorkerData } = useSelector((state) => state);
  const [clientData, SetclientData] = useState([]);
  useEffect(() => {
    const orgId = decryptId(id);
    dispatch(
      GetAllOverviewSubmissionDocByProvider({
        provider_org_id: SpecificWorkerData?.workerProvider?.organisation_id,
        worker_id: SpecificWorkerData?.worker_id||orgId,
      })
    ).then((response) => {
      if (response.success) {
        SetclientData(response.data);
      }
    });
  }, []);
  return (
    <Card className="py-6 border border-gray-200 rounded-xl dark-shadow">
      <div className="px-6">
        <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-3">
          <div className="flex items-center">
            <span className="mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M9 16L11 18L15.5 13.5M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <p></p>
            <div className="flex flex-col">
              <span className="text-gray-900 text-base font-semibold">
                Documents
              </span>
              <p className="text-gray-600">Action required </p>
            </div>
          </div>
          {/* <BtnLight className="w-20 h-9">View All</BtnLight> */}
        </div>
      </div>

      <div className="h-64">
        <Scrollbar>
          <div className="px-6">
            <div className="overflow-hidden border rounded-lg border-gray-200">
              <Table>
                <TBody>
                  {clientData &&
                    clientData?.length > 0 &&
                    clientData?.map((dataItem, index) => (
                      <React.Fragment key={index}>
                        {dataItem?.workerCheckDocs &&
                          dataItem?.workerCheckDocs?.map((doc, docIndex) => (
                            <Tr>
                              <Td>
                                {doc?.workerDocAppr?.workerApprDoc?.doc_name
                                  ? doc?.workerDocAppr?.workerApprDoc?.doc_name
                                  : "-"}
                                <span className="block text-gray-600">
                                  {doc?.document_type?.doc_type_name
                                    ? doc?.document_type?.doc_type_name
                                    : "-"}
                                </span>
                              </Td>
                              {/* <Td className="w-28">
                                <Tag className="bg-error-100 text-error-700">
                                  Action required
                                </Tag>
                              </Td> */}
                              <Td>
                                {doc?.workerDocAppr?.approval_status ===
                                  "approved" && (
                                  <Badge
                                    className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                    content={"Approved"}
                                    innerClass="bg-success-100 text-success-700"
                                  />
                                )}
                                {doc?.workerDocAppr?.approval_status ===
                                  "client_approved_action" && (
                                  <div className="flex gap-3">
                                    <Badge
                                      className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                      content={"Approved"}
                                      innerClass="bg-success-100 text-success-700"
                                    />
                                    <svg
                                      width="16"
                                      height="17"
                                      viewBox="0 0 16 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M2.66675 14.5L2.66675 3.16667M2.66675 9.16667H7.60008C7.97345 9.16667 8.16013 9.16667 8.30274 9.094C8.42818 9.03009 8.53017 8.9281 8.59409 8.80266C8.66675 8.66005 8.66675 8.47337 8.66675 8.1V3.56667C8.66675 3.1933 8.66675 3.00661 8.59409 2.86401C8.53017 2.73856 8.42818 2.63658 8.30274 2.57266C8.16013 2.5 7.97345 2.5 7.60008 2.5H3.73341C3.36005 2.5 3.17336 2.5 3.03075 2.57266C2.90531 2.63658 2.80333 2.73856 2.73941 2.86401C2.66675 3.00661 2.66675 3.1933 2.66675 3.56667V9.16667ZM8.66675 3.83333H12.9334C13.3068 3.83333 13.4935 3.83333 13.6361 3.906C13.7615 3.96991 13.8635 4.0719 13.9274 4.19734C14.0001 4.33995 14.0001 4.52663 14.0001 4.9V9.43333C14.0001 9.8067 14.0001 9.99339 13.9274 10.136C13.8635 10.2614 13.7615 10.3634 13.6361 10.4273C13.4935 10.5 13.3068 10.5 12.9334 10.5H9.73341C9.36005 10.5 9.17336 10.5 9.03075 10.4273C8.90531 10.3634 8.80333 10.2614 8.73941 10.136C8.66675 9.99339 8.66675 9.8067 8.66675 9.43333V3.83333Z"
                                        stroke="#039855"
                                        stroke-width="1.33333"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </div>
                                )}
                                {doc?.workerDocAppr?.approval_status ===
                                  "client_approval_req" && (
                                  <Badge
                                    className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                    content={"Action required"}
                                    innerClass="bg-blue-100 text-blue-700"
                                  />
                                )}
                                {doc?.workerDocAppr?.approval_status ===
                                  "client_reject" && (
                                  <Badge
                                    className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                    content={"Reject"}
                                    innerClass="bg-error-100 text-error-700"
                                  />
                                )}
                                {doc?.workerDocAppr?.approval_status ===
                                  "admin_reject" && (
                                  <Badge
                                    className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                    content={"Reject"}
                                    innerClass="bg-error-100 text-error-700"
                                  />
                                )}
                                {doc?.workerDocAppr?.approval_status ===
                                  "pending" && (
                                  <Badge
                                    className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                    content={"Awaiting review"}
                                    innerClass="bg-blue-100 text-blue-700"
                                  />
                                )}
                                {doc?.workerDocAppr?.approval_status ===
                                  (null || undefined) && (
                                  <Badge
                                    className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                    content={"Pending Upload"}
                                    innerClass="bg-success-100 text-success-700"
                                  />
                                )}
                              </Td>
                            </Tr>
                          ))}
                      </React.Fragment>
                    ))}
                </TBody>
              </Table>
              {clientData?.length == 0 && (
                <div className="h-full w-full text-center mt-2">
                  No Data Available!
                </div>
              )}
            </div>
          </div>
        </Scrollbar>
      </div>
    </Card>
  );
};
export default Documents;
