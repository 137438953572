import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

Chart.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ labels, type, chartData }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Client Distribution",
        data: chartData, // Example data
        backgroundColor: ["#86E5B2", "#FDA29B", "#D0D5DD"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Disable default legend
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            `${context.label}: ${context.raw} (${(
              (context.raw / data.datasets[0].data.reduce((a, b) => a + b, 0)) *
              100
            ).toFixed(2)}%)`,
        },
      },
    },
    cutout: "70%", // Adjust for the inner circle
  };

  const totalClients = data.datasets[0].data.reduce(
    (a, b) => Number(a) + Number(b),
    0
  );

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
      <div style={{ position: "relative", width: "200px", height: "200px" }}>
        <Doughnut data={data} options={options} />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <h3>{totalClients}</h3>
          <p>{type}</p>
        </div>
      </div>
      <div>
        <ul style={{ listStyle: "none", padding: 0 }}>
          {data.labels.map((label, index) => (
            <li
              key={index}
              style={{
                display: "flex",
                alignItems: "start",
                marginBottom: "10px",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "15px",
                  height: "15px",
                  borderRadius: "100%",
                  backgroundColor: data.datasets[0].backgroundColor[index],
                  marginRight: "10px",
                }}
              ></span>
              <div className="grid">
                <span>{label}</span> <span>{data.datasets[0].data[index]}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DoughnutChart;
