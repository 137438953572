import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import Tabs from "../../../../../common/element/Tabs";
import { useParams } from "react-router-dom";
// import Layout from "../../../../adminDashboard/components/layout";
import SiteName from "./SiteName";
import Visitorlog from "./SpecificSiteTab/Visitorlog";
import Inductees from "./SpecificSiteTab/Inductees";
import SiteSettings from "./SpecificSiteTab/SiteSettings";
import AuditLog from "./SpecificSiteTab/AuditLog";
import SiteInduction from "./SpecificSiteTab/SiteInductions";
import * as userActions from "../../../../../actions/index";
import Overview from "./SpecificSiteTab/Overview/Overview";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import SiteAccess from "./SpecificSiteTab/SiteAccessLog";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import { decryptId } from "../../../../../common/encryptDecrypt/encryptdecrypt";
const { TabNav, TabList, TabContent } = Tabs;

const SpecificSite = () => {
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const { id, siteId } = useParams();
  const orgId = decryptId(id);
  const siteOrgId = decryptId(siteId);
  const dispatch = useDispatch();
  const [siteData, setSiteData] = useState();
  const [currentTab, setCurrrentTab] = useState("tab1");

  // useEffect(() => {
  //   dispatch(
  //     setBreadcrumbs([
  //       { label: "View All Clients", url: "/admin/client/view-all-clients", home: "/admin/client/dashboard" },
  //       {
  //         label: " props?.clientData?.trading_name",
  //         url: `/admin/client/view-all-clients/specific-client/${id}`,
  //       },
  //       {
  //         label: "Sites",
  //         url: `/admin/client/view-all-clients/specific-client/${id}`,
  //       },
  //       {
  //         label: "Specific Site",
  //         url: `/admin/client/view-all-clients/specific-client/${siteId}`,
  //       },
  //     ])
  //   );
  // }, []);

  const GetClientSiteById = useCallback(async () => {
    dispatch(userActions.GetClientSiteById(siteOrgId, orgId)).then(
      (response) => {
        if (response.success === true) {
          const arrayData = response.data;
          setSiteData(arrayData);
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        } else {
        }
      }
    );
    // eslint-disable-next-line
  }, [dispatch, siteId]);

  useEffect(() => {
    GetClientSiteById();
  }, [GetClientSiteById]);

  return (
    // <Layout>
    <React.Fragment>
      <SiteName siteName={siteData?.site_name} />
      <Tabs value={currentTab} variant="pill">
        <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5 ">
          <TabList>
            <TabNav value="tab1" onClick={() => setCurrrentTab("tab1")}>
              Overview
            </TabNav>
            <TabNav value="tab2" onClick={() => setCurrrentTab("tab2")}>
              Site Inductions
            </TabNav>
            <TabNav value="tab3" onClick={() => setCurrrentTab("tab3")}>
              Inductees
            </TabNav>
            <TabNav value="tab4" onClick={() => setCurrrentTab("tab4")}>
              Site Access Log
            </TabNav>
            <TabNav value="tab5" onClick={() => setCurrrentTab("tab5")}>
              Visitor log
            </TabNav>
            <TabNav value="tab6" onClick={() => setCurrrentTab("tab6")}>
              Site Settings
            </TabNav>
            <TabNav value="tab7" onClick={() => setCurrrentTab("tab7")}>
              Audit Log
            </TabNav>
          </TabList>
        </div>
        <div className="">
          <TabContent value="tab1">
            <Overview
              GetClientSiteById={GetClientSiteById}
              setCurrrentTab={setCurrrentTab}
              siteData={siteData}
            />
          </TabContent>
          <TabContent value="tab2">
            <SiteInduction siteData={siteData} />
          </TabContent>
          <TabContent value="tab3">
            <Inductees siteData={siteData} />
          </TabContent>
          <TabContent value="tab4">
            <SiteAccess siteData={siteData} />
          </TabContent>
          <TabContent value="tab5">
            <Visitorlog siteData={siteData} />
          </TabContent>
          <TabContent value="tab6">
            <SiteSettings
              siteData={siteData}
              GetClientSiteById={GetClientSiteById}
            />
          </TabContent>
          <TabContent value="tab7">
            <AuditLog siteData={siteData} />
          </TabContent>
        </div>
      </Tabs>
    </React.Fragment>
    //  </Layout>
  );
};
export default SpecificSite;
