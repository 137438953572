import React, { useState, useEffect, useRef, useCallback } from "react";
import Table from "../../../../../common/element/Table";
import Button from "../../../../../common/element/Buttons";
import Tag from "../../../../../common/element/Tag";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Dropdown from "../../../../../common/element/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import ViewInvoice from "./InvoiceView";
import { setInvoiceData } from "../../../../../redux/classes/Admin-Section/invoiceSlice";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "../../../../../common/element/Checkbox";
import moment from "moment";
import { GetIndividualByEmail } from "../../../../../actions";
import { debounce } from "../../../../../common/snippet/Debouncing";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import Label from "../../../../../common/element/Label";
import Select from "../../../../../common/element/Select";
import { GetAllIndividualForEmail } from "../../../../../actions/Admin/GetAllIndividualForEmail";
import Validations from "../../../../../common/404/Validations";
import { ResendInvoices } from "../../../../../actions/Admin-Client/invoices/ResendInvoices";
const { Tr, Th, Td, THead, TBody } = Table;
const InvoiceList = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const navigate = useNavigate();
  const [mainCheck, setMainCheck] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [dialogResendInvoice, setDialogResendInvoice] = useState(false);
  const [formError, setFormError] = useState({ emailCc: "", emailTo: "" });
  const [emailTo, setEmailTo] = useState([]);
  const [selectedCopy, setSelectedCopy] = useState(false);
  const [emailCc, setEmailCc] = useState([]);
  const [successdialogOpen, setSuccessDialogOpen] = useState(false);
  const [emailOptions, setEmailOptions] = useState([]);
  // const [dialogResendMultiInvoice, setDialogResendMultiInvoice] =
  //   useState(false);
  const [pdfDownloadSuccessDialog, setPdfDownloadSuccessDialog] =
    useState(false);
  const [invoicesData, setInvoicesData] = useState("");
  const [pdfViewDialog, setPdfViewDialog] = useState(false);
  const previousViewTypeRef = useRef();

  useEffect(() => {
    if (props?.viewType !== previousViewTypeRef.current) {
      setCheckedItems([]);
    }
    previousViewTypeRef.current = props?.viewType;
  }, [props?.viewType]);

  const ClosedialogResendInvoice = () => {
    setFormError({ emailCc: "", emailTo: "" });
    setEmailOptions([]);
    setDialogResendInvoice(false);
    // ClosedialogResendMultiInvoice();
  };
  // const ClosedialogResendMultiInvoice = () => {
  //   setDialogResendMultiInvoice(false);
  // };
  // const ResendMultiInvoiceConfirm = () => {
  //   setSuccessDialogOpen(true);
  //   setCheckedItems([]);
  // };
  console.log("emailTo::", emailTo);
  const ResendInvoiceConfirm = (item) => {
    const emailCcValidationa = Validations({
      value: emailCc.length != 0 ? true : "",
      Validations: { required: true },
    });

    const emailToValidationa = Validations({
      value: emailTo.length != 0 ? true : "",
      Validations: { required: true },
    });
    if (!emailCcValidationa.valid) {
      // setFormError((prevData) => {
      //   return { ...prevData, ["emailCc"]: emailCcValidationa.error_msg };
      // });
      setFormError((prevData) => {
        return { ...prevData, emailCc: emailCcValidationa.error_msg };
      });
    }
    if (!emailToValidationa.valid) {
      // setFormError((prevData) => {
      //   return { ...prevData, ["emailTo"]: emailCcValidationa.error_msg };
      // });
      setFormError((prevData) => {
        return { ...prevData, emailTo: emailCcValidationa.error_msg };
      });
    }
    if (emailToValidationa.valid && emailCcValidationa.valid) {
      const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
      let emails = emailCc;
      if (selectedCopy) {
        emails.push(loginUserData?.userName?.email);
      }

      let payload = checkedItems.map((item) => {
        return {
          account_xero_invoice_id: item?.account_xero_invoice_id,
          xero_invoice_pdf: item?.xero_invoice_pdf,
          manual_invoice_note: item?.manual_invoice_note,
          email_to: JSON.stringify(emailTo),
          email_cc: JSON.stringify(emails),
        };
      });
      dispatch(ResendInvoices(payload))
        .then((response) => {
          if (response.success) {
            setSelectedCopy(false);
            setSuccessDialogOpen(true);
            setEmailCc([]);
            setEmailTo([]);
          }
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });
    }

    // let payload = [
    //   {
    //     account_xero_invoice_id: 5,
    //     xero_invoice_pdf:
    //       "https://conservedev.s3.ap-south-1.amazonaws.com/invoices/0288ff11-23bf-4b6e-be8e-2762ae3cc20e.pdf",
    //     manual_invoice_note: "conssrve manual notes of invoice",
    //     email_to: ["clientuser2@mail.in"],
    //     email_cc: [],
    //   },
    // ];
    // setSuccessDialogOpen(true);
  };
  const closeSuccessDialog = () => {
    setSuccessDialogOpen(false);
    ClosedialogResendInvoice();
  };
  const closeDownloadSuccessPdf = () => {
    setPdfDownloadSuccessDialog(false);
  };
  const Toggle = (
    <Button className="flex gap-1 justify-center items-center text-xs w-auto px-3 h-9 font-semibold hover:bg-gray-50 hover:border-gray-300 focus:bg-white focus:border-gray-300">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="16"
        viewBox="0 0 4 16"
        fill="none"
      >
        <path
          d="M2.0013 8.83301C2.46154 8.83301 2.83463 8.45991 2.83463 7.99967C2.83463 7.53944 2.46154 7.16634 2.0013 7.16634C1.54106 7.16634 1.16797 7.53944 1.16797 7.99967C1.16797 8.45991 1.54106 8.83301 2.0013 8.83301Z"
          stroke="#98A2B3"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.0013 2.99967C2.46154 2.99967 2.83463 2.62658 2.83463 2.16634C2.83463 1.7061 2.46154 1.33301 2.0013 1.33301C1.54106 1.33301 1.16797 1.7061 1.16797 2.16634C1.16797 2.62658 1.54106 2.99967 2.0013 2.99967Z"
          stroke="#98A2B3"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.0013 14.6663C2.46154 14.6663 2.83463 14.2932 2.83463 13.833C2.83463 13.3728 2.46154 12.9997 2.0013 12.9997C1.54106 12.9997 1.16797 13.3728 1.16797 13.833C1.16797 14.2932 1.54106 14.6663 2.0013 14.6663Z"
          stroke="#98A2B3"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Button>
  );

  const onCheckboxChange = (e, item) => {
    if (item === "mainCheck") {
      const isChecked = e;
      setMainCheck(isChecked);

      if (isChecked) {
        setCheckedItems(props?.allInvoicesData);
      } else {
        setCheckedItems([]);
      }
    } else {
      const isChecked = e;
      const existingIndex = checkedItems.findIndex(
        (ele) => ele.account_xero_invoice_id === item.account_xero_invoice_id
      );

      if (isChecked && existingIndex === -1) {
        setCheckedItems((prevItems) => [...prevItems, item]);
      } else if (!isChecked && existingIndex !== -1) {
        setCheckedItems((prevItems) => {
          const updatedItems = [...prevItems];
          updatedItems.splice(existingIndex, 1);
          return updatedItems;
        });
      }
    }
  };

  // const downloadSingleInvoice = async (item) => {
  //     try {
  //         if (!item?.xero_invoice_pdf || !item?.xero_invoice_number) {
  //             throw new Error('Invalid invoice data: Missing URL or invoice number');
  //         }
  //         const response = await fetch(item.xero_invoice_pdf);
  //         if (!response.ok) {
  //             throw new Error('Failed to fetch the PDF');
  //         }
  //         const link = document.createElement('a');
  //         link.href = item.xero_invoice_pdf;
  //         link.download = item.xero_invoice_number + '.pdf';
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //     } catch (error) {
  //         console.error(`Error downloading invoice ${item?.xero_invoice_number || 'Unknown'}:`, error.message);
  //     }
  // };

  // const downloadSelectedInvoices = () => {
  //     checkedItems.forEach((item, index) => {
  //         setTimeout(() => {
  //             downloadSingleInvoice(item);
  //         }, index * 300);
  //     });
  // };

  // const downloadSingleInvoice = async (item) => {
  //   try {
  //     if (!item?.xero_invoice_pdf || !item?.xero_invoice_number) {
  //       throw new Error("Invalid invoice data: Missing URL or invoice number");
  //     }
  //     const response = await fetch(item.xero_invoice_pdf);

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch the PDF");
  //     }
  //     const link = document.createElement("a");
  //     link.href = item.xero_invoice_pdf;
  //     link.download = item.xero_invoice_number + ".pdf";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error(
  //       `Error downloading invoice ${item?.xero_invoice_number || "Unknown"}:`,
  //       error.message
  //     );
  //   }
  // };

  // const downloadSelectedInvoices = async () => {
  //   for (const [index, item] of checkedItems.entries()) {
  //     await new Promise((resolve) => {
  //       setTimeout(() => {
  //         downloadSingleInvoice(item);
  //         resolve();
  //       }, index * 300); // Introducing a delay of 300ms between downloads
  //     });
  //   }

  //   // After all downloads are attempted, show success dialog and reset checked items
  //   setPdfDownloadSuccessDialog(true);
  //   setCheckedItems([]);
  // };

  const downloadSingleInvoice = async (item) => {
    try {
      if (!item?.xero_invoice_pdf || !item?.xero_invoice_number) {
        throw new Error("Invalid invoice data: Missing URL or invoice number");
      }

      const response = await fetch(item.xero_invoice_pdf);
      if (!response.ok) {
        throw new Error("Failed to fetch the PDF");
      }

      const blob = await response.blob(); // Convert the response to a Blob
      const url = window.URL.createObjectURL(blob); // Create an object URL for the Blob

      const link = document.createElement("a");
      link.href = url;
      link.download = `${item.xero_invoice_number}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url); // Clean up the object URL
    } catch (error) {
      console.error(
        `Error downloading invoice ${item?.xero_invoice_number || "Unknown"}:`,
        error.message
      );
    }
  };
  const downloadSelectedInvoices = async () => {
    for (const [index, item] of checkedItems.entries()) {
      await new Promise((resolve) => {
        setTimeout(() => {
          downloadSingleInvoice(item);
          resolve();
        }, index * 300); // Introducing a delay of 300ms between downloads
      });
    }

    // After all downloads are attempted, show success dialog and reset checked items
    setPdfDownloadSuccessDialog(true);
    setCheckedItems([]);
  };

  const resendInvoice = (e) => {
    console.log("ee:::", e);
    setCheckedItems([e]);
    setDialogResendInvoice(true);
    // setDialogReinviteIsOpen(true);
    // setReinviteData(e);
  };
  const handleRowClick = (item) => {
    setInvoicesData(item);
    setPdfViewDialog(true);
  };
  const onDialogClose = () => {
    setPdfViewDialog(false);
  };
  const handleEditDataforRedux = (item) => {
    const editedData = { ...item, edited: true };
    dispatch(setInvoiceData(editedData));
    // navigate("raise-invoice");
  };

  const GetAllIndividualEmail = useCallback(
    async (email) => {
      dispatch(GetAllIndividualForEmail(email)).then((response) => {
        if (response.success === true) {
          const arrayData = response.data;
          const maildata = [];
          for (let key in arrayData) {
            maildata.push({
              label: arrayData[key].email,
              value: arrayData[key].email,
            });
          }
          setEmailOptions(maildata);
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        } else {
        }
      });
      // eslint-disable-next-line
    },
    [dispatch]
  );

  const handleSearchChange = debounce((newInputValue, identifier) => {
    if (newInputValue?.length >= 3) {
      GetAllIndividualEmail(newInputValue);
    }
  }, 400);

  const handleSelectEmailChange = (selectedOption, identifier) => {
    if (identifier == "to") {
      setEmailTo([selectedOption?.value]);
      setFormError((prevData) => {
        return { ...prevData, emailTo: "" };
      });
    } else {
      let filterData = selectedOption.map((item) => item.value);
      setEmailCc(filterData);
      setFormError((prevData) => {
        return { ...prevData, emailCc: "" };
      });
    }
  };

  const handleCheckbox = () => {
    setSelectedCopy((prevState) => !prevState);
  };

  console.log("emailCc::", checkedItems);
  return (
    <React.Fragment>
      <div className="border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>
                <Checkbox
                  className="cursor-pointer"
                  checked={
                    checkedItems.length === props?.allInvoicesData?.length
                      ? mainCheck
                      : false
                  }
                  onChange={(e) => onCheckboxChange(e, "mainCheck")}
                />
              </Th>
              <Th>
                <div className="flex gap-1">
                  <span
                    className="flex items-center gap-1"
                    onClick={() => props?.handleSort("xero_invoice_number")}
                  >
                    Invoice no.
                    <svg
                      className={`transform ${
                        props?.filtersort === "xero_invoice_number" &&
                        props?.filterorder === "asc"
                          ? "rotate-180"
                          : ""
                      }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        strokeWidth="1.333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Th>
              <Th>Recipient</Th>
              <Th>Billing date</Th>
              <Th>Amount</Th>
              <Th>Status</Th>
              <Th>Payment date</Th>
              <Th></Th>
            </Tr>
          </THead>
          <TBody>
            {props?.allInvoicesData.map((item) => {
              let status = "";

              if (item.xero_invoice_status === "AUTHORISED") {
                const today = new Date();
                const endDate = new Date(item.end_date); // Assuming `item.end_date` is in a valid date format like "YYYY-MM-DD".

                // Calculate the difference in days
                const differenceInTime = endDate.getTime() - today.getTime();
                const remainingDays = Math.ceil(
                  differenceInTime / (1000 * 3600 * 24)
                ); // Convert milliseconds to days

                if (remainingDays > 0) {
                  status = "Due";
                } else {
                  status = "Overdue";
                }
              }

              // let status;
              // if(item.xero_invoice_status=="AUTHORISED"){
              //   item.end_date
              //   if(){
              //     status="Upcoming"
              //   }
              //   else{
              //     status="Due"
              //   }

              // }
              console.log("status::", status);
              return (
                <Tr
                  className={`${
                    item?.xero_invoice_status !== "DRAFT"
                      ? "cursor-pointer"
                      : ""
                  }`}
                  key={item?.account_xero_invoice_id}
                  onClick={() =>
                    item?.xero_invoice_status != "DRAFT" && handleRowClick(item)
                  }
                >
                  <Td onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      className="cursor-pointer"
                      checked={checkedItems?.some(
                        (checkedItem) =>
                          checkedItem?.account_xero_invoice_id ===
                          item?.account_xero_invoice_id
                      )}
                      onChange={(e) => onCheckboxChange(e, item)}
                    />
                  </Td>
                  <Td>{item?.xero_invoice_number}</Td>
                  <Td>{item?.xeroInvClient?.trading_name}</Td>
                  <Td>
                    {item?.created_date
                      ? moment(item?.created_date).format("YYYY/MM/DD")
                      : "-"}
                  </Td>
                  <Td>{item?.amount}</Td>
                  <Td>
                    {item.xero_invoice_status === "Paid" && (
                      <Tag className="bg-success-50 text-success-700">
                        <span>
                          <svg
                            width="11"
                            height="8"
                            viewBox="0 0 11 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.39355 0.981445L3.89355 6.48145L1.39355 3.98145"
                              stroke="#3DB77E"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>{" "}
                        Paid
                      </Tag>
                    )}
                    {status === "Overdue" && (
                      <Tag className="bg-error-50 text-error-700">
                        <span>
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_7446_51239)">
                              <path
                                d="M6.39355 4.98145V6.98145M6.39355 8.98145H6.39855M1.39355 5.24282V8.72007C1.39355 8.84237 1.39355 8.90352 1.40737 8.96106C1.41962 9.01208 1.43982 9.06085 1.46723 9.10559C1.49816 9.15605 1.54139 9.19928 1.62787 9.28576L4.08924 11.7471C4.17572 11.8336 4.21895 11.8768 4.26941 11.9078C4.31415 11.9352 4.36292 11.9554 4.41394 11.9676C4.47148 11.9814 4.53263 11.9814 4.65493 11.9814H8.13218C8.25448 11.9814 8.31563 11.9814 8.37317 11.9676C8.42419 11.9554 8.47296 11.9352 8.5177 11.9078C8.56816 11.8768 8.61139 11.8336 8.69787 11.7471L11.1592 9.28576C11.2457 9.19928 11.289 9.15605 11.3199 9.10559C11.3473 9.06085 11.3675 9.01208 11.3797 8.96106C11.3936 8.90352 11.3936 8.84237 11.3936 8.72007V5.24282C11.3936 5.12052 11.3936 5.05937 11.3797 5.00183C11.3675 4.95081 11.3473 4.90204 11.3199 4.8573C11.289 4.80684 11.2457 4.76361 11.1592 4.67713L8.69787 2.21576C8.61139 2.12928 8.56816 2.08605 8.5177 2.05513C8.47296 2.02771 8.42419 2.00751 8.37317 1.99526C8.31563 1.98145 8.25448 1.98145 8.13218 1.98145H4.65493C4.53263 1.98145 4.47148 1.98145 4.41394 1.99526C4.36292 2.00751 4.31415 2.02771 4.26941 2.05513C4.21895 2.08605 4.17572 2.12928 4.08924 2.21576L1.62787 4.67713C1.54139 4.76361 1.49816 4.80684 1.46723 4.8573C1.43982 4.90204 1.41962 4.95081 1.40737 5.00183C1.39355 5.05937 1.39355 5.12052 1.39355 5.24282Z"
                                stroke="#F04438"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_7446_51239">
                                <rect
                                  width="12"
                                  height="12"
                                  fill="white"
                                  transform="translate(0.393555 0.981445)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>{" "}
                        Overdue
                      </Tag>
                    )}
                    {(status == "Upcoming" || status == "Due") && (
                      <Tag className="bg-warning-50 text-warning-700">
                        <span>
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_18999_70113)">
                              <path
                                d="M11.35 5.75L10.3503 6.75L9.34998 5.75M10.4725 6.5C10.4907 6.33583 10.5 6.169 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C3.51472 1.5 1.5 3.51472 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5C7.41363 10.5 8.67502 9.84817 9.5 8.82867M6 3.5V6L7.5 7"
                                stroke="#F79009"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_18999_70113">
                                <rect width="12" height="12" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>{" "}
                        {status}
                      </Tag>
                    )}
                    {item.xero_invoice_status === "DRAFT" && (
                      <Tag className="bg-blue-100 text-blue-700">
                        <span>
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_18999_37916)">
                              <path
                                d="M1.25 10.7502L4.02463 9.68299C4.2021 9.61473 4.29084 9.5806 4.37386 9.53603C4.4476 9.49645 4.5179 9.45077 4.58402 9.39946C4.65846 9.34169 4.72569 9.27447 4.86014 9.14001L10.5 3.50015C11.0523 2.94787 11.0523 2.05244 10.5 1.50015C9.94773 0.947867 9.0523 0.947866 8.50001 1.50015L2.86014 7.14001C2.72569 7.27446 2.65846 7.34169 2.6007 7.41613C2.54939 7.48225 2.5037 7.55255 2.46412 7.62629C2.41955 7.70931 2.38542 7.79805 2.31717 7.97552L1.25 10.7502ZM1.25 10.7502L2.27906 8.07463C2.3527 7.88317 2.38952 7.78744 2.45267 7.74359C2.50786 7.70527 2.57615 7.69078 2.64215 7.70338C2.71767 7.7178 2.79019 7.79033 2.93524 7.93538L4.06479 9.06493C4.20984 9.20997 4.28236 9.2825 4.29678 9.35802C4.30939 9.42401 4.29489 9.49231 4.25657 9.5475C4.21272 9.61065 4.11699 9.64747 3.92553 9.72111L1.25 10.7502Z"
                                stroke="#0E4E9A"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_18999_37916">
                                <rect width="12" height="12" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        Draft
                      </Tag>
                    )}
                  </Td>
                  <Td>
                    {item?.payment_date
                      ? moment(item?.payment_date).format("YYYY/MM/DD")
                      : "-"}
                  </Td>
                  <Td onClick={(e) => e.stopPropagation()}>
                    {item.xero_invoice_status !== "DRAFT" ? (
                      <div className="flex gap-2 reset-dropdown">
                        <Dropdown renderTitle={Toggle} isOpen={true}>
                          <Dropdown.Item
                            eventKey="a"
                            onClick={() => downloadSingleInvoice(item)}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.5 11.4297V12.4297C16.5 13.8298 16.5 14.5299 16.2275 15.0647C15.9878 15.5351 15.6054 15.9175 15.135 16.1572C14.6002 16.4297 13.9001 16.4297 12.5 16.4297H5.5C4.09987 16.4297 3.3998 16.4297 2.86502 16.1572C2.39462 15.9175 2.01217 15.5351 1.77248 15.0647C1.5 14.5299 1.5 13.8298 1.5 12.4297V11.4297M13.1667 7.26302L9 11.4297M9 11.4297L4.83333 7.26302M9 11.4297V1.42969"
                                stroke="#344054"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span>Download</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="b"
                            onClick={() => resendInvoice(item)}
                          >
                            <button>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.74928 11.2501L17.4993 2.50014M8.85559 11.5235L11.0457 17.1552C11.2386 17.6513 11.3351 17.8994 11.4741 17.9718C11.5946 18.0346 11.7381 18.0347 11.8587 17.972C11.9978 17.8998 12.0946 17.6518 12.2881 17.1559L17.78 3.08281C17.9547 2.63516 18.0421 2.41133 17.9943 2.26831C17.9528 2.1441 17.8553 2.04663 17.7311 2.00514C17.5881 1.95736 17.3643 2.0447 16.9166 2.21939L2.84349 7.71134C2.34759 7.90486 2.09965 8.00163 2.02739 8.14071C1.96475 8.26129 1.96483 8.40483 2.02761 8.52533C2.10004 8.66433 2.3481 8.7608 2.84422 8.95373L8.47589 11.1438C8.5766 11.183 8.62695 11.2026 8.66935 11.2328C8.70693 11.2596 8.7398 11.2925 8.7666 11.3301C8.79685 11.3725 8.81643 11.4228 8.85559 11.5235Z"
                                  stroke="#344054"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                            <span>Resend</span>
                          </Dropdown.Item>
                        </Dropdown>
                      </div>
                    ) : (
                      <div className="flex gap-2 reset-dropdown">
                        <Dropdown renderTitle={Toggle} isOpen={true}>
                          <Dropdown.Item
                            eventKey="a"
                            onClick={() => handleEditDataforRedux(item)}
                          >
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.39668 15.4059C2.43497 15.0613 2.45411 14.889 2.50624 14.728C2.55249 14.5851 2.61784 14.4491 2.70051 14.3238C2.79369 14.1825 2.91627 14.0599 3.16142 13.8147L14.1667 2.80949C15.0871 1.88902 16.5795 1.88902 17.5 2.8095C18.4205 3.72997 18.4205 5.22236 17.5 6.14283L6.49475 17.1481C6.2496 17.3932 6.12702 17.5158 5.98572 17.609C5.86035 17.6916 5.72439 17.757 5.58152 17.8032C5.42048 17.8554 5.24819 17.8745 4.90362 17.9128L2.08331 18.2262L2.39668 15.4059Z"
                                stroke="#475467"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span>Edit</span>
                          </Dropdown.Item>
                        </Dropdown>
                      </div>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </TBody>
        </Table>
      </div>

      {checkedItems?.length > 0 && (
        <>
          <div className="font-semibold my-5">
            <ul
              className="flex items-center justify-center w-[425px] h-[56px] rounded-[110px] p-2 mx-auto my-auto"
              style={{ boxShadow: "0px 0px 24px 0px rgb(214, 221, 237)" }}
            >
              <li
                className="p-2"
                style={{ color: "#5786CC", position: "relative" }}
              >
                <Button>{`${checkedItems?.length} selected`}</Button>
                <div
                  className="absolute right-0 top-1/2 transform -translate-y-1/2"
                  style={{
                    width: "2px",
                    height: "36px",
                    backgroundColor: "#F2F4F7",
                  }}
                />
              </li>
              <li
                onClick={() => {
                  if (
                    checkedItems?.length === 1 &&
                    checkedItems[0]?.xero_invoice_status === "DRAFT"
                  ) {
                  } else {
                    downloadSelectedInvoices();
                  }
                }}
                className={`p-2 flex gap-1 cursor-pointer ${
                  checkedItems?.every(
                    (item) => item?.xero_invoice_status === "DRAFT"
                  )
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                <div
                  className="absolute right-0 top-1/2 transform -translate-y-1/2"
                  style={{
                    width: "2px",
                    height: "36px",
                    backgroundColor: "#F2F4F7",
                  }}
                />
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5 11.4297V12.4297C16.5 13.8298 16.5 14.5299 16.2275 15.0647C15.9878 15.5351 15.6054 15.9175 15.135 16.1572C14.6002 16.4297 13.9001 16.4297 12.5 16.4297H5.5C4.09987 16.4297 3.3998 16.4297 2.86502 16.1572C2.39462 15.9175 2.01217 15.5351 1.77248 15.0647C1.5 14.5299 1.5 13.8298 1.5 12.4297V11.4297M13.1667 7.26302L9 11.4297M9 11.4297L4.83333 7.26302M9 11.4297V1.42969"
                    stroke="#344054"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Download</span>
              </li>

              <li
                onClick={() => {
                  // setDialogResendMultiInvoice(true);
                  setDialogResendInvoice(true);
                }}
                className="p-2 relative flex gap-1 cursor-pointer"
              >
                <div
                  className="absolute right-0 top-1/2 transform -translate-y-1/2"
                  style={{
                    width: "2px",
                    height: "36px",
                    backgroundColor: "#F2F4F7",
                  }}
                />
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.74928 11.2501L17.4993 2.50014M8.85559 11.5235L11.0457 17.1552C11.2386 17.6513 11.3351 17.8994 11.4741 17.9718C11.5946 18.0346 11.7381 18.0347 11.8587 17.972C11.9978 17.8998 12.0946 17.6518 12.2881 17.1559L17.78 3.08281C17.9547 2.63516 18.0421 2.41133 17.9943 2.26831C17.9528 2.1441 17.8553 2.04663 17.7311 2.00514C17.5881 1.95736 17.3643 2.0447 16.9166 2.21939L2.84349 7.71134C2.34759 7.90486 2.09965 8.00163 2.02739 8.14071C1.96475 8.26129 1.96483 8.40483 2.02761 8.52533C2.10004 8.66433 2.3481 8.7608 2.84422 8.95373L8.47589 11.1438C8.5766 11.183 8.62695 11.2026 8.66935 11.2328C8.70693 11.2596 8.7398 11.2925 8.7666 11.3301C8.79685 11.3725 8.81643 11.4228 8.85559 11.5235Z"
                    stroke="#344054"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>Resend invoices</span>
              </li>
            </ul>
          </div>
        </>
      )}
      <Dialog
        isOpen={dialogResendInvoice}
        onClose={ClosedialogResendInvoice}
        onRequestClose={ClosedialogResendInvoice}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="48"
              height="49"
              viewBox="0 0 48 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="1.47656"
                width="47"
                height="47"
                rx="23.5"
                fill="#D2EBFF"
              />
              <rect
                x="0.5"
                y="1.47656"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M22.4995 26.4767L32.9995 15.9767M22.6271 26.8048L25.2552 33.5628C25.4867 34.1581 25.6025 34.4558 25.7693 34.5427C25.9139 34.6181 26.0862 34.6182 26.2308 34.543C26.3977 34.4563 26.5139 34.1588 26.7461 33.5637L33.3364 16.6759C33.5461 16.1388 33.6509 15.8702 33.5935 15.6985C33.5437 15.5495 33.4268 15.4325 33.2777 15.3827C33.1061 15.3254 32.8375 15.4302 32.3003 15.6398L15.4126 22.2302C14.8175 22.4624 14.52 22.5785 14.4333 22.7454C14.3581 22.8901 14.3582 23.0624 14.4335 23.207C14.5204 23.3738 14.8181 23.4895 15.4135 23.721L22.1715 26.3492C22.2923 26.3962 22.3527 26.4196 22.4036 26.4559C22.4487 26.4881 22.4881 26.5275 22.5203 26.5726C22.5566 26.6235 22.5801 26.684 22.6271 26.8048Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Confirming that you’re resending invoices{" "}
          </p>
          <div className="flex-1 mt-3">
            <Label className="font-medium">To*</Label>
            <Select
              placeholder="Enter email address"
              className="w-full h-auto select-options"
              options={emailOptions}
              // readOnly={invoiceData?.recipient ? false : true}
              // value={selectedOptionRecipient}
              onChange={(e) => handleSelectEmailChange(e, "to")}
              handleSearchChange={(e) => handleSearchChange(e)}
              // handleSearchChange={(e) => handleSearchChange(e, "recipientTo")}
            />
            {/* {invoiceErrors?.to && (
              <div className="text-red-500 text-sm mt-1">
                {invoiceErrors?.to}
              </div>
            )} */}
            {formError?.emailTo && (
              <p className="text-red-500 text-sm mt-1">{formError?.emailTo}</p>
            )}
          </div>
          <div className="flex-1 mt-3">
            <Label className="font-medium">CC*</Label>
            {/* <Input
              type="text"
              className="h-34 w-full"
              placeholder="Select a recipient from the list"
              value={invoiceData.recipient}
              inputchangehandler={(e) => inputchangehandler("recipient", e)}
            /> */}
            {/* <Select
              className="w-full h-9 select-options"
              placeholder="Search & Select a email from the list"
              options={emailOptions}
              // value={selectedClint}
              onChange={(e) => handleSelectEmailChange(e)}
              handleSearchChange={(e) => handleSearchChange(e)}
              isMulti={true}
            ></Select> */}
            <Select
              type="text"
              placeholder="Add more emails"
              className="w-full h-auto select-options"
              isClearable={true}
              isSearchable={true}
              options={emailOptions}
              // value={selectedEmails}
              onChange={(e) => handleSelectEmailChange(e)}
              handleSearchChange={(e) => handleSearchChange(e)}
              isMulti={true}
            />
            {formError?.emailCc && (
              <p className="text-red-500 text-sm mt-1">{formError?.emailCc}</p>
            )}
            {/* {invoiceErrors?.recipient && (
              <div className="text-red-500 text-sm mt-1">
                {invoiceErrors?.recipient}
              </div>
            )} */}
          </div>
          <div className="p-0 mt-3">
            <Checkbox
              checked={selectedCopy}
              onClick={() => handleCheckbox()}
              className="font-medium text-sm leading-5"
            >
              {" "}
              Send me a copy
            </Checkbox>
          </div>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 gap-2">
          <BtnLight onClick={ClosedialogResendInvoice}>Cancel</BtnLight>
          <BtnPrimary onClick={ResendInvoiceConfirm}>Confirm</BtnPrimary>
        </div>
      </Dialog>

      {/* <Dialog
        isOpen={dialogResendMultiInvoice}
        onClose={ClosedialogResendMultiInvoice}
        onRequestClose={ClosedialogResendMultiInvoice}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="48"
              height="49"
              viewBox="0 0 48 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="1.47656"
                width="47"
                height="47"
                rx="23.5"
                fill="#D2EBFF"
              />
              <rect
                x="0.5"
                y="1.47656"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M22.4995 26.4767L32.9995 15.9767M22.6271 26.8048L25.2552 33.5628C25.4867 34.1581 25.6025 34.4558 25.7693 34.5427C25.9139 34.6181 26.0862 34.6182 26.2308 34.543C26.3977 34.4563 26.5139 34.1588 26.7461 33.5637L33.3364 16.6759C33.5461 16.1388 33.6509 15.8702 33.5935 15.6985C33.5437 15.5495 33.4268 15.4325 33.2777 15.3827C33.1061 15.3254 32.8375 15.4302 32.3003 15.6398L15.4126 22.2302C14.8175 22.4624 14.52 22.5785 14.4333 22.7454C14.3581 22.8901 14.3582 23.0624 14.4335 23.207C14.5204 23.3738 14.8181 23.4895 15.4135 23.721L22.1715 26.3492C22.2923 26.3962 22.3527 26.4196 22.4036 26.4559C22.4487 26.4881 22.4881 26.5275 22.5203 26.5726C22.5566 26.6235 22.5801 26.684 22.6271 26.8048Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="mb-2.5 text-base text-gray-900 font-semibold text-center mt-4">
            Confirming that you’re resending invoices to:
          </p>
          <ul className="list-disc list-inside text-center">
            {checkedItems && checkedItems.length > 0 ? (
              // Filter out items with "DRAFT" xero_invoice_status before mapping
              checkedItems.filter(
                (item) => item?.xero_invoice_status !== "DRAFT"
              ).length > 0 ? (
                checkedItems
                  .filter((item) => item?.xero_invoice_status !== "DRAFT")
                  .map((item, index) => (
                    <li key={index} className="text-gray-900 font-bold">
                      {item?.recipient}
                    </li>
                  ))
              ) : (
                <li className="text-gray-500 font-bold text-center">
                  DRAFT Invoices Not Sent
                </li>
              )
            ) : (
              <li className="text-gray-500 font-bold text-center">
                No Selected Invoices
              </li>
            )}
          </ul>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 gap-2">
          <BtnLight onClick={ClosedialogResendMultiInvoice}>Cancel</BtnLight>
          <BtnPrimary
            disabled={
              checkedItems?.length == 1 &&
              checkedItems?.[0]?.xero_invoice_status == "DRAFT"
            }
            onClick={ResendMultiInvoiceConfirm}
          >
            Confirm
          </BtnPrimary>
        </div>
      </Dialog> */}

      <Dialog
        isOpen={successdialogOpen}
        onClose={closeSuccessDialog}
        onRequestClose={closeSuccessDialog}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.894531"
                y="0.585938"
                width="48"
                height="48"
                rx="24"
                fill="#D1FADF"
              />
              <path
                d="M26.8945 14.8555V18.986C26.8945 19.5461 26.8945 19.8261 27.0035 20.04C27.0994 20.2282 27.2524 20.3811 27.4405 20.477C27.6545 20.586 27.9345 20.586 28.4945 20.586H32.6251M28.8945 25.5859H20.8945M28.8945 29.5859H20.8945M22.8945 21.5859H20.8945M26.8945 14.5859H21.6945C20.0144 14.5859 19.1743 14.5859 18.5326 14.9129C17.9681 15.2005 17.5091 15.6595 17.2215 16.224C16.8945 16.8657 16.8945 17.7058 16.8945 19.3859V29.7859C16.8945 31.4661 16.8945 32.3062 17.2215 32.9479C17.5091 33.5124 17.9681 33.9713 18.5326 34.259C19.1743 34.5859 20.0144 34.5859 21.6945 34.5859H28.0945C29.7747 34.5859 30.6148 34.5859 31.2565 34.259C31.821 33.9713 32.2799 33.5124 32.5676 32.9479C32.8945 32.3062 32.8945 31.4661 32.8945 29.7859V20.5859L26.8945 14.5859Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Invoice re-sent successfully
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => closeSuccessDialog()}>Close</BtnLight>
        </div>
      </Dialog>
      <Dialog
        isOpen={pdfDownloadSuccessDialog}
        onClose={closeDownloadSuccessPdf}
        onRequestClose={closeDownloadSuccessPdf}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.894531"
                y="0.585938"
                width="48"
                height="48"
                rx="24"
                fill="#D1FADF"
              />
              <path
                d="M26.8945 14.8555V18.986C26.8945 19.5461 26.8945 19.8261 27.0035 20.04C27.0994 20.2282 27.2524 20.3811 27.4405 20.477C27.6545 20.586 27.9345 20.586 28.4945 20.586H32.6251M28.8945 25.5859H20.8945M28.8945 29.5859H20.8945M22.8945 21.5859H20.8945M26.8945 14.5859H21.6945C20.0144 14.5859 19.1743 14.5859 18.5326 14.9129C17.9681 15.2005 17.5091 15.6595 17.2215 16.224C16.8945 16.8657 16.8945 17.7058 16.8945 19.3859V29.7859C16.8945 31.4661 16.8945 32.3062 17.2215 32.9479C17.5091 33.5124 17.9681 33.9713 18.5326 34.259C19.1743 34.5859 20.0144 34.5859 21.6945 34.5859H28.0945C29.7747 34.5859 30.6148 34.5859 31.2565 34.259C31.821 33.9713 32.2799 33.5124 32.5676 32.9479C32.8945 32.3062 32.8945 31.4661 32.8945 29.7859V20.5859L26.8945 14.5859Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Selected PDFs downloaded successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => closeDownloadSuccessPdf()}>Close</BtnLight>
        </div>
      </Dialog>

      <Dialog
        isOpen={pdfViewDialog}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="documentDialog"
      >
        <ViewInvoice
          invoicesData={invoicesData}
          onDialogClose={onDialogClose}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default InvoiceList;
