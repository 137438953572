import React, { useEffect, useState } from "react";
import Card from "../../../../common/element/Card";
import Heading from "../../../../common/element/Heading";
import { Link } from "react-router-dom";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Segment from "../../../../common/element/Segment";
import Radio from "../../../../common/element/Radio";
import Dialog from "../../../../common/element/Dialog";
import ConnectClient from "../../../providerPortal/pages/Registration/ConnectClient";
import Button from "../../../../common/element/Buttons";
import { useDispatch } from "react-redux";
import { ResetProviderRegisterFinalData } from "../../../../redux/classes/common/ProviderRegisterFinalData";
import { ResetProviderSignUpData } from "../../../../redux/classes/provider-portal/SignUp";
import { SignupProviderForClient } from "../../../../actions/public/SignupProviderForClient";
import { useSelector } from "react-redux";
import { stripeCheckoutForProviderRegis } from "../../../../actions/provider-portal/registration/stripeCheckoutForProviderRegis";
import { paypalCheckoutForProviderRegis } from "../../../../actions/provider-portal/registration/paypalCheckoutForProviderRegis";
import { GetInvoicePdfById } from "../../../../actions/provider-portal/GetInvoicePdfById";
import { GetProviderRegistPaymentStatus } from "../../../../actions/provider-portal/GetProviderRegistPaymentStatus";
import { useNavigate, useSearchParams } from "react-router-dom";
const PaymentMethod = ({ Setstep }) => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const session_id = params.get("session_id");
  const paymentId = params.get("subscription_id");
  const dispatch = useDispatch();
  const [docAdd, setDocAdd] = useState(false);
  const [isInvoiceSuccess, setIsInvoiceSuccess] = useState(false);
  const [sessionData, setSessionData] = useState({});
  const { ProviderRegisterFinalData } = useSelector((state) => state);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const preventDefault = (e) => {
    e.preventDefault();
  };
  const uploadDocHandler = (e) => {
    e.preventDefault();
    setDocAdd(true);
  };
  const hideBlock = (e) => {
    setDocAdd(false);
    e.preventDefault();
  };

  const openDialog = () => {
    setIsOpen(true);
  };

  const onDialogClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (checkpayment) => {
    // event.preventDefault();
    // registrationType, provider_type_id, invite_id, provider_org_id

    let payload = {
      ...ProviderRegisterFinalData,
      isProviderExist: false,
    };

    if (checkpayment == "stripe") {
      dispatch(stripeCheckoutForProviderRegis(payload)).then((response) => {
        if (response.success) {
          localStorage.setItem(
            "checkout_custom_id",
            JSON.stringify({
              checkout_custom_id: response?.data?.data?.checkout_custom_id,
            })
          );
          window.location.href = response?.data?.data?.sessionUrl;
        }
      });
    } else {
      dispatch(paypalCheckoutForProviderRegis(payload)).then((response) => {
        if (response.success) {
          localStorage.setItem(
            "checkout_custom_id",
            JSON.stringify({
              checkout_custom_id: response?.data?.data?.checkout_custom_id,
            })
          );
          window.location.href = response?.data?.data?.approvalLink;
        }
      });
    }
  };
  let checkPayment = true;

  if (session_id || paymentId) {
    checkPayment = false;
  } else {
    checkPayment = true;
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const session_id = params.get("session_id");
    const paymentId = params.get("subscription_id");

    if (session_id || paymentId) {
      const checkout_custom_id = JSON.parse(
        localStorage.getItem("checkout_custom_id")
      );

      const interval = setInterval(() => {
        dispatch(
          GetProviderRegistPaymentStatus({
            checkout_custom_id: checkout_custom_id?.checkout_custom_id,
          })
        ).then((response) => {
          if (response?.data?.is_invoice_success) {
            let data = response?.data;
            setIsInvoiceSuccess(true);
            setSessionData({
              payment_checkout_id: data?.payment_checkout_id,
              provider_fee_type: data?.provider_fee_type,
              payment_gateway: data?.payment_gateway,
              account_payment_id: data?.account_payment_id,
            });
            clearInterval(interval); // Stop polling when condition is met
          }
        });
      }, 2000); // Call API every second

      // Cleanup interval on component unmount
      return () => clearInterval(interval);
    }
  }, [dispatch]);

  useEffect(() => {
    if (isInvoiceSuccess) {
      const params = new URLSearchParams(window.location.search);
      const session_id = params.get("session_id");
      const paymentId = params.get("subscription_id");
      if (session_id || paymentId) {
        let inviteData = "";
        if (localStorage.getItem("workerInviteData") != "") {
          inviteData = JSON.parse(localStorage.getItem("workerInviteData"));
        }
        let payload = { ...sessionData, ...ProviderRegisterFinalData };
        if (ProviderRegisterFinalData) {
          // setInvoiceData(response?.data);
          dispatch(SignupProviderForClient(payload))
            .then((response) => {
              if (response.success) {
                // navigate("/login");
                dispatch(ResetProviderSignUpData());
                dispatch(ResetProviderRegisterFinalData());
              } else {
                console.error(
                  "Operation failed:",
                  response.errorMessage || "Unknown error"
                );
              }
            })
            .catch((error) => {
              console.error("An error occurred during signup:", error);
            });
          // setDialogInvoice(true)
          // props?.openInvoice(true);
          // setIsOpen(true);
          // setPersonName(inviteData?.clientData);
          // delete inviteData?.clientData;
          // setAddWorkerData(inviteData);
        }
      }
    }
  }, [isInvoiceSuccess, sessionData, dispatch, navigate]);

  // const downloadInvoice = () => {
  //   // invoiceData.xero_invoice_pdf
  //   if (invoiceData && invoiceData.xero_invoice_pdf) {
  //     const link = document.createElement("a");
  //     link.href = invoiceData.xero_invoice_pdf;
  //     link.download = "invoice.pdf"; // Set the file name
  //     link.target = "_blank"; // Open in new tab for security if on another domain
  //     link.click();
  //   } else {
  //     console.error("No PDF URL found in invoice data");
  //   }
  // };

  return (
    <div
      style={{ backgroundColor: `#eff8ff` }}
      className="flex justify-between pr-6 pl-6"
    >
      <div className="w-full  justify-center flex flex-wrap">
        <form className="pb-20   pt-20">
          <Card className="registerBlock p-7 border border-gray-200 rounded-xl">
            <h5 className="text-blue-800 font-semibold text-2xl mb-7">
              Payment
            </h5>
            <h4 className="font-bold text-gray-700 text-base mb-1">
              Annual Licensing Fee
            </h4>
            <p className="text-gray-700 mb-7">$308.00</p>
            <h4 className="font-bold text-gray-700 text-base mb-1">
              License period
            </h4>
            <p className="text-gray-700">dd/mm/yyy - dd/mm/yyy</p>
          </Card>
          <div>
            <div className="w-full">
              <form>
                <Card className="p-7 border border-gray-200 rounded-xl mt-7">
                  <h5 className="text-blue-800 font-semibold text-2xl mb-2">
                    Payment options
                  </h5>
                  <h4 className="font-bold text-gray-700 text-base mb-5">
                    Select a payment method
                  </h4>
                  <div className="inline-flex rounded-md mb-7 validation-filter">
                    <Segment className="payment-btn" onClick={preventDefault}>
                      <Segment.Item value="Stripe">
                        <span
                          onClick={() => {
                            handleSubmit("stripe");
                            // setDocAdd(true);
                            // setPaypalMethod(false);
                          }}
                        >
                          <img src="/assets/icons-svg/stripe.svg" alt="" />{" "}
                          Stripe
                        </span>
                      </Segment.Item>
                      <Segment.Item value="Paypal">
                        <span
                          onClick={() => {
                            handleSubmit();
                            // setDocAdd(true);
                            // setPaypalMethod(false);
                          }}
                        >
                          <img src="/assets/icons-svg/paypal.svg" alt="" />{" "}
                          Paypal
                        </span>
                      </Segment.Item>
                    </Segment>
                  </div>
                </Card>
              </form>
            </div>
            {/* <Dialog isOpen={dialogIsOpen} onClose={() => setIsOpen(false)}>
                  <div className="flex border-b mb-5 pb-5 px-6 pt-6">
                    <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
                      <img src="assets/icons-svg/building.svg" alt="" />
                    </span>
                    <div>
                      <h5 className="mb-1 text-gray-900 text-base font-semibold">
                        Connect with [Client name]
                      </h5>
                      <p className="text-gray-600">
                        Ensure a successful connection with the client by
                        completing these steps
                      </p>
                    </div>
                  </div>
                </Dialog> */}
          </div>
          {/* <Card className="registerBlock p-7 border border-gray-200 rounded-xl mt-7">
            <h5 className="text-blue-800 font-semibold text-2xl mb-7">
              Payment options
            </h5>
            <h4 className="font-bold text-gray-700 text-base mb-5">
              Select a payment method
            </h4>
            <div className="inline-flex rounded-md  mb-7 validation-filter">
              <Segment className="payment-btn" onClick={preventDefault}>
                <Segment.Item value="Stripe">
                  <span onClick={uploadDocHandler}>
                    <img src="assets/icons-svg/stripe.svg" alt="" />
                    Stripe
                  </span>
                </Segment.Item>
                <Segment.Item value="Paypal">
                  <span onClick={hideBlock}>
                    <img src="assets/icons-svg/paypal.svg" alt="" /> Paypal
                  </span>
                </Segment.Item>
              </Segment>
            </div>
            {docAdd && (
              <React.Fragment>
                <h4 className="font-bold text-gray-700 text-base mb-5">
                  Card information
                </h4>
                <div className="flex gap-4">
                  <div className="w-4/5 mb-4">
                    <label
                      class="block text-gray-700  mb-2 text-xs font-medium"
                      for="Trading Name"
                    >
                      Name on card
                    </label>
                    <span className="input-wrapper">
                      <input
                        class="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight"
                        type="text"
                        name="ccNumber"
                        autocomplete="off"
                        placeholder="Name on card"
                        value=""
                      />
                    </span>
                  </div>
                  <div className="form-item vertical w-1/5 mb-4">
                    <label className="form-label  block text-gray-700  mb-2 text-xs font-medium">
                      Expiry
                    </label>
                    <span className="input-wrapper">
                      <input
                        className="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight"
                        type="text"
                        name="cardExpiry"
                        autocomplete="off"
                        placeholder="XX / XX"
                        value="XX / XX"
                        inputmode="numeric"
                      />
                    </span>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="w-4/5 mb-4">
                    <label
                      class="block text-gray-700  mb-2 text-xs font-medium"
                      for="Trading Name"
                    >
                      Card number
                    </label>
                    <span className="input-wrapper">
                      <input
                        class="pl-12 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight"
                        type="text"
                        name="ccNumber"
                        autocomplete="off"
                        placeholder="•••• •••• •••• ••••"
                        value=""
                        inputmode="numeric"
                      />
                      <div class="input-suffix-end">
                        <svg
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 8.33h-15m0-1.35v5.7c0 .84 0 1.26.163 1.58a1.5 1.5 0 0 0 .656.656c.32.163.74.163 1.581.163h10.2c.84 0 1.26 0 1.581-.163a1.5 1.5 0 0 0 .656-.656c.163-.32.163-.74.163-1.58v-5.7c0-.841 0-1.261-.163-1.582a1.5 1.5 0 0 0-.656-.655c-.32-.164-.74-.164-1.581-.164H3.9c-.84 0-1.26 0-1.581.164a1.5 1.5 0 0 0-.656.655C1.5 5.72 1.5 6.14 1.5 6.98z"
                            stroke="#000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </span>
                  </div>
                  <div className="form-item vertical w-1/5 mb-4">
                    <label className="form-label  block text-gray-700  mb-2 text-xs font-medium">
                      CVV
                    </label>
                    <span className="input-wrapper">
                      <input
                        className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight"
                        type="text"
                        name="cardExpiry"
                        autocomplete="off"
                        placeholder="•••"
                        value="•••"
                        inputmode="numeric"
                      />
                    </span>
                  </div>
                </div>
                <Radio className="mt-3">
                  I understand and accept that this licensing fee is valid only
                  for one year and is non-refundable during that period.
                </Radio>
              </React.Fragment>
            )}
          </Card> */}
          <div className="flex mt-7 step2-btn-block form-footer justify-between">
            <BtnLight className="w-auto px-6 mr-2 flex justify-center items-center pre-step">
              <Button
                className=" px-6  flex justify-center items-center next-step"
                to="register"
                onClick={() => Setstep(false)}
              >
                <img
                  src="assets/icons-svg/arrow-left.svg"
                  alt=""
                  className="mr-2"
                />{" "}
                Previous page
              </Button>
            </BtnLight>
            <BtnPrimary className="w-auto px-6" onClick={openDialog}>
              Confirm and pay
            </BtnPrimary>
            {/* <BtnPrimary className="w-auto">
              <Link
                className=" px-6  flex justify-center items-center next-step"
                to="payment-success"
              >
                <img src="assets/icons-svg/lock.svg" alt="#" className="mr-2" />{" "}
                Confirm and pay
              </Link>
            </BtnPrimary> */}
          </div>
          <div className="flex justify-center mt-6 show-on-mobile">
            <Link
              to="/login"
              className="text-xs text-gray-600 font-semibold hover:text-gray-900 focus:text-gray-600 flex  items-center"
            >
              Back to log in
            </Link>
          </div>
        </form>
      </div>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="assets/icons-svg/building.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Connect with [Client name]
            </h5>
            <p className="text-gray-600 ">
              Ensure a successful connection with the client by completing these
              steps
            </p>
          </div>
        </div>
        <ConnectClient />
      </Dialog>
    </div>
  );
};
export default PaymentMethod;
