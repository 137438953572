import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateSiteInductionModuleData } from "../../../../../../../../redux/classes/Admin-Client/SiteInductionModuleSlice";
import { UpdateInductionStatusSite } from "../../../../../../../../redux/classes/Admin-Client/updatInductionStatusSite";
import { DeletedocumentDataSite } from "../../../../../../../../redux/classes/Admin-Client/deleteDocumentSite";
import { closeDocumentfuncSite } from "../../../../../../../../redux/classes/Admin-Client/closeDocumentSite";

import Upload from "../../../../../../..//../common/element/Upload";
import { DeleteCompanyIndModuleFileById} from "../../../../../../../../actions/Admin-Client/AllCompanyInduction";
import { Tooltip } from "@material-ui/core";
import { GetSignedUrlByKey } from "../../../../../../../../actions/public/GetSignedUrlByKey";
const ImgFile = (props) => {
  const { SiteInductionModuleData } = useSelector((state) => state);
  const [checkPdf, SetcheckPdf] = useState("");
  const [sizeError, SetsizeError] = useState("");
  const [Pdfdata, Setpdfdata] = useState(
    SiteInductionModuleData[props.indexData]
  );

  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    dispatch(UpdateInductionStatusSite(true));
    if (e[e.length - 1].size <= 25 * 1024 * 1024) {
      SetcheckPdf(e[e.length - 1]);
      const file = e[0];
      Setpdfdata({
        ...Pdfdata,
        content_info_type: "file",
        site_induction_file: file,
      });
      SetsizeError("");
    } else {
      SetsizeError("File size exceeds the maximum limit of 25MB.");
    }
  };

  useEffect(() => {
    dispatch(
      UpdateSiteInductionModuleData({
        indexData: props.indexData,
        moduleData: Pdfdata,
      })
    );
  }, [Pdfdata]);

  useEffect(() => {
    let fileName;
    if (Pdfdata?.site_induction_file?.name !== undefined) {
      fileName = Pdfdata.site_induction_file.name;
      SetcheckPdf(Pdfdata.site_induction_file);
    } else {
      fileName = Pdfdata.content_info;
      if (Pdfdata.content_info) {
        dispatch(
          GetSignedUrlByKey(
            { url: Pdfdata.content_info },
            "admin/client"
          )
        ).then((response) => {
          if (response.success) {
            // setPdfFile(response?.data);
            SetcheckPdf(response?.data);
          }
        });
      }
    }
  }, []);

  const handleDeletePDF = () => {
    dispatch(closeDocumentfuncSite(true))
    dispatch(UpdateInductionStatusSite(true));
    SetcheckPdf("");
    Setpdfdata({ ...Pdfdata, content_info_type: "", site_induction_file: "",content_info:""  });
    if(((Pdfdata?.module_id)&&(Pdfdata?.content_info_type=="file") && (Pdfdata?.content_info))){
      dispatch(DeleteCompanyIndModuleFileById({module_id:Pdfdata.module_id,content_info:Pdfdata.content_info}))
    }
  };
  return (
    <React.Fragment>
      <div className="flex w-full gap-5 items-center relative">
      <Tooltip placement={"right-start"} title={"Remove module content"}>
          <button
            onClick={() => handleDeletePDF()}
            className="modue_creation_close_icon absolute right-0 bottom-full"
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m18 6.5-12 12m0-12 12 12"
                stroke="#667085"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </Tooltip>
        <div className="p-4 rounded-xl border border-gray-300 flex items-center justify-between w-2/4">
          {checkPdf?.length == 0 && (
            <Upload
              value={""}
              accept={".jpg, .jpeg, .png, .gif"}
              showList={false}
              onChange={handleFileChange}
              draggable
              className="w-full"
            >
              <div className="text-center">
                <div className="flex items-center justify-center flex-col">
                  {" "}
                  <span className="flex w-9 h-9 items-center justify-center rounded-full bg-gray-200 outline outline-8 outline-gray-50 outline-offset-0">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                        stroke="#475467"
                        strokeWidth="1.667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                  <span className="pt-4">
                    <span className="text-blue-700 font-semibold mr-1">
                      Click to upload
                    </span>{" "}
                    <span className="text-xs text-gray-600">
                      {" "}
                      or drag and drop ( max. 25mb)
                    </span>
                  </span>
                </div>
              </div>
              {sizeError && (
                <div className="text-red-500 text-sm mt-1">{sizeError}</div>
              )}
            </Upload>
          )}
          {checkPdf?.length != 0 && (
            <React.Fragment>
              <div className="flex  gap-4">
                <span className="w-9 h-9 rounded-full border-4 border-blue-50 bg-blue-200 flex justify-center items-center">
                  <img src="/assets/icons-svg/upload-pdf.svg" alt="" />
                </span>
                <div className="flex flex-col justify-center">
                  <span className="text-gray-700 text-xs font-medium">
                  {checkPdf?.name ? checkPdf?.name : props?.size?.name}
                  </span>
                  {/* <span className="text-gray-600 text-xs">
                  {checkPdf?.size ? checkPdf?.size :props?.size?.size} KB
                    {checkPdf?.size}KB
                  </span> */}
                </div>
              </div>
              <button onClick={handleDeletePDF}>
                <svg
                  width="20"
                  height="19"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                    stroke="#344054"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ImgFile;
