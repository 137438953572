import React, { useState, useCallback, useEffect, useRef } from "react";
import { GetSubmissionDocsOrChecklist } from "../../../../../../../../../../actions/client-portal/AllSiteApi";
import DocumentListShow from "./DocumentListShow";
import { debounce } from "../../../../../../../../../../common/snippet/Debouncing";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import fileCheck from "../../../../../../../../../../assets/icons-svg/file-check.svg";
import Scrollbar from "../../../../../../../../../../common/element/ScrollBar";
import BtnPrimary from "../../../../../../../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../../../../../../../common/element/Dialog";
import Checkbox from "../../../../../../../../../../common/element/Checkbox";
import Table from "../../../../../../../../../../common/element/Table";
import BtnLight from "../../../../../../../../../../common/element/Buttons/btnLight";
import { GetAllAssignedChecklistOfProvider } from "../../../../../../../../../../actions/client-portal/AllSiteApi";
import axios from 'axios';
const Documents = (props) => {
  const { Tr, Th, Td, THead, TBody } = Table;
  const dispatch = useDispatch();
  const [workerD, setworkerD] = useState([])
  const [searchTerm, setsearchTerm] = useState("");
  const [allChecklistData, setAllChecklistData] = useState([])
  const [allDocData, setAllDocData] = useState([])
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openAssignChecklist, setOpenAssignChecklist] = useState(false)
  const [workerCompCheckList, setWorkerCompCheckList] = useState([]);
  const [checklistIds, setChecklistIds] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [removeChecklistIds, setRemoveChecklistIds] = useState([]);
  const [filterpage, setfilterpage] = useState(1);
  const [filterlimit, setfilterlimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [filterorder, setFilterOrder] = useState("desc");
  const [countData, setCountData] = useState(0);
  const [filtersort, setFiltersort] = useState("doc_name");
  const cancelTokenSourceRef = useRef(null);
  let organisation_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    // user_id = storedData?.userName?.user_id;
    // function_assignment_id = storedData.function_assignment_id;
    // trading_name = storedData.trading_name;
    organisation_id = storedData?.organisation_id

  }
  const OnGetSubmissionDocsOrChecklist = useCallback(async () => {
    // if (organisation_id && props?.organisation_id) {
    const payload = {
      client_org_id: organisation_id,
      worker_id: props?.workerId ? props?.workerId : ""
    }

    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel('Operation canceled by the user.');
    }
    const source = axios.CancelToken.source();
    cancelTokenSourceRef.current = source;
    try {
      const result = await dispatch(GetSubmissionDocsOrChecklist(payload, source.token));
      if (result?.success) {
        // const arrayData = result?.data?.rows;
        const arrayData = result?.data
        const checklistData = arrayData?.data?.checklistData;
        const workerData = arrayData?.data?.workerDocs
        const filterData = checklistData?.map((item) => ({
          value: item?.checklist_id,
          label: item?.checklist_name,
        }));
        setAllChecklistData(filterData)
        // setCountData(result?.data?.data?.count);
        // setTotalPages(Math.ceil(result?.data?.data?.count / filterlimit));
        setAllDocData(workerData)
        // setworkerD(result?.data?.data?.rows)

      } else {
        console.error('Error:', result);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error('Dispatch error:', error);
      }
    }
    // }
  }, [dispatch]);


  useEffect(() => {
    OnGetSubmissionDocsOrChecklist()
    return () => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Component unmounted');
      }
    };
  }, [])
  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
    OnGetSubmissionDocsOrChecklist()
  };
  const handleSeachChange = (e) => {
    setsearchTerm(e.target.value);
    setfilterpage(1);
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);

  const handleSort = (column) => {
    // Handle sorting of the service list
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };
  //   const OnGetAllAssignedChecklistOfWorker = useCallback(async () => {
  //     if (openAssignChecklist) {
  //        try {
  //           const payload = { provider_org_uuid: organisationUuid, client_org_uuid: organisation_uuid };
  //           const response = await dispatch(GetAllAssignedChecklistOfProvider(payload));
  //           const { success, data, status } = response;
  //           if (success) {
  //              const arrayData = data?.data;
  //              setWorkerCompCheckList(arrayData);
  //              const alreadyAssignedChecklist = arrayData.filter((ele) => ele?.compAssigns && Object.keys(ele?.compAssigns)?.length > 0);
  //              setCheckedItems(alreadyAssignedChecklist);
  //           } else if (status === 401) {

  //           } else {
  //              console.error('Failed to fetch compliance checklist:', response);

  //           }
  //        } catch (error) {
  //           console.error('Failed to fetch compliance checklist:', error);

  //        }
  //     }
  //  }, [dispatch, organisationUuid, organisation_uuid, openAssignChecklist]);

  //  useEffect(() => {
  //     OnGetAllAssignedChecklistOfWorker();
  //  }, [organisationUuid, organisation_uuid, openAssignChecklist]);



  const onCheckboxChange = (isChecked, item) => {
    const existingIndex = checkedItems.findIndex(
      (ele) => ele.checklist_uuid === item.checklist_uuid
    );

    if (isChecked && existingIndex === -1) {
      // Item is checked (added)
      setCheckedItems((prevItems) => [...prevItems, item]);
      setChecklistIds((prevIds) => [...prevIds, item.checklist_uuid]);
      setRemoveChecklistIds((prevIds) =>
        prevIds.filter((id) => id !== item.checklist_uuid)
      );
    } else if (!isChecked && existingIndex !== -1) {
      // Item is unchecked (removed)
      setCheckedItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(existingIndex, 1);
        return updatedItems;
      });
      setRemoveChecklistIds((prevIds) => [...prevIds, item.checklist_uuid]);
      setChecklistIds((prevIds) =>
        prevIds.filter((id) => id !== item.checklist_uuid)
      );
    }
  };

  const onCloseConfirmDialog = () => {
    setOpenConfirmDialog(false)
  }
  const onCloseAssignChecklist = () => {
    setOpenAssignChecklist(false)
    setCheckedItems([])
  }
  const onConfirm = () => {
    // const payload = {
    //    assignChecklistIds: JSON.stringify(checklistIds),
    //    removeChecklistIds: JSON.stringify(removeChecklistIds),
    //    provider_org_uuid: organisationUuid,
    //    client_org_uuid: organisation_uuid
    // }

    // dispatch(UpdateProviderChecklistAssign(payload))
    //    .then((res) => {
    //       if (res?.success === true) {
    //          OnGetProviderDocsAgainstClient()
    //          setOpenConfirmDialog(false)
    //          setOpenAssignChecklist(false)
    //          setRemoveChecklistIds([])
    //          setCheckedItems([])
    //          setChecklistIds([])
    //       }
    //    }).catch((error) => {
    //       console.log(error);
    //    })

  }
  return (
    <React.Fragment>
      {allDocData?.length > 0 && (allDocData?.[0].workerCheckDocs.length || allDocData?.[1]?.workerCheckDocs?.length) && (<>
        <div className="p-2 flex justify-between">
          <div className="flex gap-2">
            <div className="input-wrapper w-80 p-2">
              <div className="input-suffix-start left-3.5"></div>
              <label className="block text-sm font-medium text-gray-700">Compliance Checklists</label>
            </div>
          </div>

          <BtnLight
            onClick={() => setOpenAssignChecklist(true)} // Uncommented the onClick handler
            className="flex justify-between items-center gap-2 w-auto px-4"
          >
            <span className="flex-1 text-center">Edit Assigned Checklist</span>
            <span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 15L12.5 10L7.5 5" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </span>
          </BtnLight>
        </div>

      </>)}
      <DocumentListShow
        OnGetSubmissionDocsOrChecklist={OnGetSubmissionDocsOrChecklist}
        allDocData={allDocData}
        handleSort={handleSort}
        filtersort={filtersort}
        filterorder={filterorder}
        client_org_id={organisation_id}
        worker_id={props?.workerId ? props?.workerId : ""}
        trading_name={props?.trading_name}
        allChecklistData={allChecklistData} />


      <Dialog
        isOpen={openAssignChecklist}
        onClose={onCloseAssignChecklist}
        onRequestClose={onCloseAssignChecklist}
        bodyOpenClassName="overflow-hidden"
        width={660}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={fileCheck} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Assigned Checklists to {props?.individual_uuid?.first_name && props?.individual_uuid?.last_name
                ? `${props.individual_uuid.first_name} ${props.individual_uuid.last_name}`
                : ""}
            </h5>

            <p className="text-gray-600 font-normal text-sm">
              Changing assignments will initiate a review process</p>
          </div>
        </div>
        <div className="px-6">
          <div className="flex justify-between mb-5 mt-5">
            <h5 className="text-xl text-gray-900 font-semibold">Worker Checklist(s)</h5>
          </div>
          <div className="border rounded-lg border-gray-200">
            <div className="h-64 overflow-auto">
              <Scrollbar>
                <Table>
                  <THead>
                    <Tr>
                      <Th className="flex justify-between gap-1">
                        <span className="flex items-center gap-1">
                          Checklist
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                              stroke="#475467"
                              strokeWidth="1.333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </span>


                      </Th>
                      <Th>
                        <span className="flex items-center gap-1 justify-end">
                          Expiry date
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                              stroke="#475467"
                              strokeWidth="1.333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </span>
                      </Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {workerCompCheckList?.length > 0 &&
                      workerCompCheckList.map((item) => (
                        <Tr key={item.checklist_uuid}>
                          <Td className="flex gap-2">
                            <Checkbox
                              className="cursor-pointer"
                              checked={checkedItems.some(checkedItem => checkedItem.checklist_uuid === item.checklist_uuid)}
                              onChange={(e) => onCheckboxChange(e, item)}
                            />
                            {item?.checklist_name ? item?.checklist_name : "-"}
                          </Td>
                          <Td className="text-right">{item.checklist_renewal_date ? moment(item?.checklist_renewal_date).format("DD/MM/YYYY") : "-"}</Td>
                        </Tr>
                      ))}
                  </TBody>
                </Table>
              </Scrollbar>
            </div>
          </div>

        </div>
        <div className="flex dailog-footer ">
          <BtnLight className="mr-1.5" variant="plain"
            onClick={onCloseAssignChecklist}>
            Cancel
          </BtnLight>
          <BtnPrimary
            type="submit"
            onClick={() => { setOpenConfirmDialog(true) }}
            className="ml-1.5"
          // disabled={isSaving}
          >Save updates
            {/* {isSaving ? "Saving..." : "Save updates"} */}
          </BtnPrimary>
        </div>

      </Dialog>
      <Dialog
        isOpen={openConfirmDialog}
        onClose={onCloseConfirmDialog}
        onRequestClose={onCloseConfirmDialog}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"

      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1.01331" y="0.5" width="47" height="47" rx="23.5" fill="#D2EBFF" />
              <rect x="1.01331" y="0.5" width="47" height="47" rx="23.5" stroke="#ECFDF3" />
              <path d="M26.5133 14.2695V18.4001C26.5133 18.9601 26.5133 19.2401 26.6223 19.4541C26.7182 19.6422 26.8712 19.7952 27.0593 19.8911C27.2732 20.0001 27.5533 20.0001 28.1133 20.0001H32.2438M22.3633 24.0022C22.5395 23.5014 22.8873 23.079 23.345 22.81C23.8028 22.5409 24.341 22.4426 24.8643 22.5324C25.3876 22.6221 25.8623 22.8942 26.2042 23.3004C26.5462 23.7066 26.7333 24.2207 26.7325 24.7517C26.7325 26.2506 24.4842 27 24.4842 27M24.5133 30H24.5233M26.5133 14H21.3133C19.6331 14 18.7931 14 18.1513 14.327C17.5868 14.6146 17.1279 15.0735 16.8403 15.638C16.5133 16.2798 16.5133 17.1198 16.5133 18.8V29.2C16.5133 30.8802 16.5133 31.7202 16.8403 32.362C17.1279 32.9265 17.5868 33.3854 18.1513 33.673C18.7931 34 19.6331 34 21.3133 34H27.7133C29.3935 34 30.2335 34 30.8753 33.673C31.4398 33.3854 31.8987 32.9265 32.1863 32.362C32.5133 31.7202 32.5133 30.8802 32.5133 29.2V20L26.5133 14Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Are you sure you want to make these updates?
          </p>
          <p className="text-gray-600 text-center">
            If additional checklists are assigned, Workers will need their documents reviewed again.
          </p>
        </div>
        <div className="mt-8 gap-2 flex justify-between pb-6 px-6">
          <BtnLight onClick={onCloseConfirmDialog}>Close</BtnLight>
          <BtnPrimary
            onClick={() => onConfirm()}
          >Confirm</BtnPrimary>
        </div>
      </Dialog>
      {/* <div className="flex justify-between mt-3">
        <Pagination totalCount={countData} pageNumber={filterpage} displayPage={workerD?.length} UpdatePageLimit={setfilterlimit} UpdatePage={setfilterpage} />

        {countData > 0 && <ReactPaginate
          className="flex justify-content-end p-2 align-items-center pagination-wrap"
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousLinkClassName={"prev-link"}
          nextLinkClassName={"page-link"}
          breakLinkClassName={"prev-link"}
          previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
          nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
          pageLinkClassName={"px-4 rounded-md"}
          activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
          disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
          renderOnZeroPageCount={null}
          forcePage={filterpage - 1}
        />}
      </div> */}
    </React.Fragment>
  );
};

export default Documents;
