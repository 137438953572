// features/ipAddressSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Async thunk to fetch IP address
export const fetchIPAddress = createAsyncThunk('ipAddress/fetch', async () => {
  try {
    const response = await fetch('https://api64.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    throw error;
  }
});

const ipAddressSlice = createSlice({
  name: 'ipAddress',
  initialState: { ip: '', loading: false, error: null },
  reducers: {
    resetIP(state) {
      state.ip = '';
    }
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(fetchIPAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIPAddress.fulfilled, (state, action) => {
        state.ip = action.payload;
        state.loading = false;
      })
      .addCase(fetchIPAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { resetIP } = ipAddressSlice.actions;
export default ipAddressSlice.reducer;
