import React, { useEffect, useState } from "react";
import Heading from "../../../../common/element/Heading";
import Card from "../../../../common/element/Card";
import { Link, useNavigate } from "react-router-dom";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Label from "../../../../common/element/Label";
import Tabs from "../../../../common/element/Tabs";
import { SignupProviderEmailVerification } from "../../../../actions/Auth";
import { useDispatch } from "react-redux";
import Validations from "../../../../common/404/Validations";
import { GetProviderSignUpData } from "../../../../redux/classes/provider-portal/SignUp";
import { useSelector } from "react-redux";
const SignUp = () => {
  const { SignUpData } = useSelector((state) => state);
  const navigate = useNavigate();
  const { TabNav, TabList, TabContent } = Tabs;
  const dispatch = useDispatch();
  const [loader, SetLoader] = useState(false);
  const [type, Settype] = useState("forClient");
  const [formData, SetFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
  });

  const [formErrorData, SetFormErrorData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
  });

  const handleChange = (e, name) => {
    SetFormErrorData((prevalue) => {
      return {
        ...prevalue,
        [name]: "",
      };
    });
    SetFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: e.target.value,
      };
    });
  };

  const SubmitData = () => {
    //Validate Email
    SetLoader(true);
    const emailValidation = Validations({
      value: formData.email,
      Validations: { required: true, email: true },
    });
    if (!emailValidation.valid) {
      SetFormErrorData((prevalue) => {
        return {
          ...prevalue,
          ["email"]: emailValidation.error_msg,
        };
      });
    }

    const firstnameValidation = Validations({
      value: formData.first_name,
      Validations: { required: true },
    });

    const lastnameValidation = Validations({
      value: formData.last_name,
      Validations: { required: true },
    });
    if (!firstnameValidation.valid) {
      SetFormErrorData((prevalue) => {
        return {
          ...prevalue,
          ["first_name"]: firstnameValidation.error_msg,
        };
      });
    }
    if (!lastnameValidation.valid) {
      SetFormErrorData((prevalue) => {
        return {
          ...prevalue,
          ["last_name"]: lastnameValidation.error_msg,
        };
      });
    }

    const phoneValidation = Validations({
      value:
        formData?.phone?.length == "" || formData?.phone?.length == undefined
          ? ""
          : formData?.phone?.length >= 7
          ? formData?.phone
          : "",
      Validations: { required: true },
    });
    if (!phoneValidation.valid) {
      SetFormErrorData((prevError) => ({
        ...prevError,
        phone:
          formData?.phone?.length == "" || formData?.phone?.length == undefined
            ? "This field is required!"
            : "Phone number must be at least 7 digits!",
      }));
    }
    if (
      emailValidation.valid === true &&
      firstnameValidation.valid === true &&
      lastnameValidation.valid === true &&
      phoneValidation.valid === true
    ) {
      let payload = { ...formData, registrationType: type };
      dispatch(SignupProviderEmailVerification(payload)).then((response) => {
        if (response.success) {
          dispatch(GetProviderSignUpData({ ...SignUpData, ...payload }));
          navigate("/sign-up/inbox-verification");
          SetLoader(false);
        } else {
          SetLoader(false);
        }
        if (response.status == 409) {
          SetFormErrorData((prevalue) => {
            return {
              ...prevalue,
              ["email"]: response.message,
            };
          });
        }
      });
    } else {
      SetLoader(false);
    }
  };

  useEffect(() => {
    dispatch(GetProviderSignUpData({ registrationType: "forClient" }));
  }, []);
  let signUpData = (
    <div className="w-full">
      <form>
        <div className="flex gap-2">
          <div className="flex-1 mb-5">
            <Label className="mb-2 text-xs" htmlFor="firstname">
              First name*
            </Label>
            <input
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="firstname"
              type="text"
              placeholder=""
              onChange={(e) => {
                handleChange(e, "first_name");
              }}
            />
            {formErrorData.first_name && (
              <p className="text-red-500 text-sm mt-1">
                {formErrorData.first_name}
              </p>
            )}
          </div>
          <div className="flex-1 mb-5">
            <Label className="mb-2 text-xs" htmlFor="lastName">
              Last name*
            </Label>
            <input
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="lastName"
              type="text"
              placeholder=""
              onChange={(e) => {
                handleChange(e, "last_name");
              }}
            />
            {formErrorData.last_name && (
              <p className="text-red-500 text-sm mt-1">
                {formErrorData.last_name}
              </p>
            )}
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex-1 mb-5">
            <Label className="mb-2 text-xs" htmlFor="Phonenumber">
              Phone number*
            </Label>
            <input
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="Phonenumber"
              type="number"
              placeholder="Enter phone number"
              onChange={(e) => {
                let value = e.target.value;
                if (value.length > 15) {
                  value = value.slice(0, 15); // Truncate to 15 characters
                }
                e.target.value = value;
                handleChange(e, "phone"); // Call your existing handler with modified value
              }}
              onKeyDown={(e) => {
                if (["e", "E", "+", "-"].includes(e.key)) {
                  e.preventDefault(); // Prevent entry of invalid characters
                }
              }}
            />

            {formErrorData.phone && (
              <p className="text-red-500 text-sm mt-1">{formErrorData.phone}</p>
            )}
          </div>
          <div className="mb-5 flex-1">
            <Label className="mb-2 text-xs" htmlFor="email">
              {" "}
              Email address*
            </Label>
            <input
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder=""
              onChange={(e) => {
                handleChange(e, "email");
              }}
            />
            {formErrorData.email && (
              <p className="text-red-500 text-sm mt-1">{formErrorData.email}</p>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between w-100">
          <BtnPrimary onClick={SubmitData} disabled={loader}>
            <div className="flex justify-center">
              <span className="pr-2">Continue </span>
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.167 10.487h11.666m0 0L10 4.654m5.833 5.833L10 16.321"
                  stroke="#fff"
                  strokeWidth="1.667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </BtnPrimary>
        </div>
        <p className="text-xs text-gray-600 mt-6 text-center font-semibold">
          By signing up, you agree to our{" "}
          <Link
            to={"/terms-of-use"}
            className="text-blue-700 hover:text-gray-900 focus:text-gray-600"
          >
            Terms of Use
          </Link>{" "}
          and{" "}
          <Link
            to={"/privacy-policy"}
            className="text-blue-700 hover:text-gray-900 focus:text-gray-600"
          >
            Privacy Policy.
          </Link>
        </p>
      </form>
    </div>
  );

  const hnadleTab = (e) => {
    if (e == "tab2") {
      dispatch(GetProviderSignUpData({ registrationType: "yourself" }));
      Settype("yourself");
    } else {
      dispatch(GetProviderSignUpData({ registrationType: "forClient" }));
      Settype("forClient");
    }
  };

  return (
    <div
      style={{ backgroundColor: `#eff8ff` }}
      className="flex justify-center min-h-screen"
    >
      <div className="min-h-screen justify-center flex  py-12 px-6">
        <div className="flex-col items-center justify-center flex">
          <div className="flex items-center mb-6">
            <img className="brand-img" src="/assets/logo.svg" alt="conserve" />
          </div>
          <Heading className="text-center mb-7">Let’s get started</Heading>
          <Card className="w-688 rounded-xl   block-shadow w-100 mt-2 px-10 py-8">
            <h4 className="font-semibold text-gray-600 text-xl text-center mb-4">
              Who are you registering for?
            </h4>
            <Tabs defaultValue="tab1" variant="pill" onChange={hnadleTab}>
              <TabList>
                <TabNav value="tab1" className="sign-up-tab">
                  <div className="flex-1 px-3 py-6 text-center  rounded-lg">
                    <h5 className="text-center text-blue-700 text-base font-semibold mb-2">
                      For a Client
                    </h5>
                    <p className="text-gray-700 whitespace-normal">
                      Fill out the required details for the Client you have been
                      awarded or expect to be awarded work for.
                    </p>
                  </div>
                </TabNav>
                <TabNav value="tab2" className="sign-up-tab">
                  <div className="flex-1 px-3 py-6 text-center rounded-lg">
                    <h5 className="text-center text-blue-700 text-base font-semibold mb-2">
                      Without a Client
                    </h5>
                    <p className="text-gray-700 whitespace-normal">
                      Register for faster onboarding when you’re awarded work.
                    </p>
                  </div>
                </TabNav>
              </TabList>

              <hr className="my-8 border-gray-200" />
              <TabContent value="tab1">{signUpData}</TabContent>
              <TabContent value="tab2">{signUpData}</TabContent>
            </Tabs>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default SignUp;
