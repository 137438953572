import React, { useCallback, useEffect, useState } from "react";
import { notify } from "react-notify-toast";
// import WorkersList from "./WorkersList";
// import { GetAllIndividualListForWorkerInvites } from "../../../../../../../actions/admin-provider/GetAllIndividualListForWorkerInvites";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "../../../../../../common/snippet/Debouncing";
// import { InviteWorker } from "../../../../../../../actions/admin-worker/InviteWorker";
import ClientInput from "../../../../../../common/element/Input/clientInput";
// import { GetProviderWorkersInvite } from "../../../../../../../actions/admin-provider/GetSpecificContractorWorkerInvites";
import ReactPaginate from "react-paginate";  
import Segment from "../../../../../../common/element/Segment";
// import Validations from "../../../../../../../common/404/Validations";
// import { reSetData } from "../../../../../../../redux/classes/AdminContractor/inviteContractorWorkerCsvViewSlice";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import { useParams } from "react-router-dom";
// import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../../common/pagination";
// import { GetAllWorkersOfProvider } from "../../../../../../../actions/admin-provider/clients/GetAllWorkersOfProvider";
import CompanyInduction from "./CompanyInduction";
import SiteInduction from "./SiteInduction";
import { GetIndWorkerByAssignedClients } from "../../../../../../actions/admin-worker/induction/GetIndWorkerByAssignedClients";
import CompanyInductionView from "./CompanyInductionView";
import InductionView from "./SiteInductionView";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import Select from "../../../../../../common/element/Select";
import { GetAllProviderOfWorkerList } from "../../../../../../actions/worker-portal/GetAllProviderOfWorkerList";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import { decryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Clients = (props) => {
  const { id } = useParams();
  const orgId = decryptId(id);
  const { SpecificWorkerData } = useSelector((state) => state);
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const dispatch = useDispatch();
  const { workerRedirectSpecificData } = useSelector((state) => state);
  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Worker",
          url: "/admin/worker/view-all-worker",
          home: "/admin/worker/dashboard",
        },
        {
          label: `${workerRedirectSpecificData?.first_name || ""} ${
            workerRedirectSpecificData?.last_name || ""
          }`,
          url: `/admin/worker/view-all-worker/specific-worker/${encodeURIComponent(
            id
          )}`,
        },
        {
          label: "Inductions",
          url: `/admin/worker/view-all-worker/specific-worker/${encodeURIComponent(
            id
          )}`,
        },
      ])
    );
  }, []);

  const [singleSegmentValue, setSingleSegmentValue] =
    useState("companyInduction");
  const [search, setSearch] = useState("");
  const [inviteData, setinviteData] = useState([]);
  const [refetch, setrefetch] = useState(false);
  const [inductionData, setInductionData] = useState(null);
  const [selectedProvType, setSelectedProvType] = useState(null);
  const [inDialogIsOpen, setInDialogIsOpen] = useState(false);
  const [clientOrdId, SetClientOrdId] = useState("");
  const [inductionOpen, setInductionOpen] = useState(false);
  const [searchProvider, setSearchProvider] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);

  // FilTER DATA SHOW
  const [totalPages, setTotalPages] = useState(1);

  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    worker_id: SpecificWorkerData?.worker_id||orgId,
    page: 1,
    limit: 10,
    // sort: "created_date",
    order: "desc",
    search: "",
    inductionType: "company",
  });

  // All ReduxFunction
  useEffect(() => {
    if (SpecificWorkerData?.worker_id||orgId) {
      let payload = {
        ...getAllinvitedData,
        worker_id: SpecificWorkerData?.worker_id||orgId,
      };
      dispatch(GetIndWorkerByAssignedClients(payload));
    }
  }, [singleSegmentValue, refetch, SpecificWorkerData, getAllinvitedData]);
  const GetFunction = useSelector((state) => state);
  const handleSort = (column) => {
    if (column === getAllinvitedData.sort) {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    } else {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    }
  };

  const handlePageChange = ({ selected }) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });

    setTotalPages(
      Math.ceil(
        GetFunction.GetSpecificWorkerIndData?.data?.data?.count ||
          0 / getAllinvitedData.limit
      )
    );
  };

  // Search Data
  const handleSearch = (e) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value.trim(),
      };
    });
  };
  const inputchangehandlerSearch = useCallback(debounce(handleSearch), []);
  // page, limit, sort, order, search, function_assignment_id  (client function assignment id)

  const getPageNumber = () => {
    setTotalPages(
      Math.ceil(
        GetFunction.GetSpecificWorkerIndData?.data?.data?.count /
          getAllinvitedData.limit
      )
    );
  };
  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);

  // Check Tab
  // Update Main Tabs Function
  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setSingleSegmentValue(val[0]);
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          // ["sort"]: "created_date",
          inductionType: val[0] == "companyInduction" ? "company" : "site",
        };
      });
    },
    [setSingleSegmentValue]
  );

  const handleView = (item, type, client_org_id) => {
    SetClientOrdId(client_org_id);
    let payload;
    if (type == "site") {
      payload = {
        site_induction_id: item?.site_induction_id,
        worker_id: SpecificWorkerData?.worker_id||orgId,
        inductionType: type,
      };
    } else {
      payload = {
        company_induction_id: item?.company_induction_id,
        worker_id: SpecificWorkerData?.worker_id||orgId,
        inductionType: type,
      };
    }
    setInductionData(payload);
    setInDialogIsOpen(true);
  };

  const onInDialogClose = () => {
    setInDialogIsOpen(false);
    setrefetch(!refetch);
  };
  const inductionCptClose = () => {
    setInductionOpen(false);
    onInDialogClose();
  };

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5">
        <div className="flex gap-2">
          <Segment
            value={singleSegmentValue}
            onChange={(val) => onSingleSelectionSegmentChange(val)}
          >
            <Segment.Item value="companyInduction" className="h-9 p-2">
              <span>Company Induction </span>
            </Segment.Item>
            <Segment.Item value="siteInduction" className="h-9 p-2">
              <span>Site Induction</span>
            </Segment.Item>
          </Segment>
          {/* <div className="mb-5 w-80 h-10">
            <div className="mb-5">
              <div className="w-full hide-select-elt">
                <Select
                  className="w-full h-9 select-options"
                  placeholder="Select a provider"
                  options={searchOptions}
                  value={selectedProvType}
                  onChange={(e) => handleSelectChange(e, "provider")}
                ></Select>
              </div>
            </div>
          </div> */}
          <ClientInput
            type="text"
            className="input  w-72 input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Search by client name"
            inputchangehandler={inputchangehandlerSearch}
            name={"search"}
          />
        </div>
      </div>
      {singleSegmentValue == "companyInduction" && (
        <React.Fragment>
          <CompanyInduction
            handleSort={handleSort}
            getAllinvitedData={getAllinvitedData}
            singleSegmentValue={singleSegmentValue}
            setrefetch={setrefetch}
            refetch={refetch}
            handleView={handleView}
          />
          <div className="flex justify-between mt-3">
            <Pagination
              totalCount={
                GetFunction.GetSpecificWorkerIndData?.data?.data?.count || 0
              }
              pageNumber={getAllinvitedData.page}
              displayPage={
                GetFunction.GetSpecificWorkerIndData?.data?.data?.rows
                  ?.length || 0
              }
              UpdatePageLimit={SetgetAllinvitedData}
              allfilters={getAllinvitedData}
            />
            {GetFunction.GetSpecificWorkerIndData?.data?.data?.count > 0 && (
              <ReactPaginate
                className="flex justify-content-end p-2 align-items-center pagination-wrap"
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                // pageLinkClassName={'page-link'}
                previousLinkClassName={"prev-link"}
                nextLinkClassName={"page-link"}
                breakLinkClassName={"prev-link"}
                previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
                nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
                pageLinkClassName={"px-4 rounded-md"}
                activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
                disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              />
            )}
          </div>
        </React.Fragment>
      )}

      {singleSegmentValue == "siteInduction" && (
        <React.Fragment>
          <SiteInduction
            handleSort={handleSort}
            getAllinvitedData={getAllinvitedData}
            singleSegmentValue={singleSegmentValue}
            setrefetch={setrefetch}
            refetch={refetch}
            handleView={handleView}
          />
          <div className="flex justify-between mt-3">
            <Pagination
              totalCount={
                GetFunction.GetSpecificWorkerIndData?.data?.data?.count || 0
              }
              pageNumber={ getAllinvitedData.limit}
              displayPage={GetFunction.GetSpecificWorkerIndData?.data?.data?.rows?.length ||0}
              UpdatePageLimit={SetgetAllinvitedData}
              allfilters={getAllinvitedData}
            />
            {GetFunction.GetSpecificWorkerIndData?.data?.data?.count > 0 && (
              <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={getAllinvitedData?.page - 1}
              />
            )}
          </div>
        </React.Fragment>
      )}

      <Dialog
        isOpen={inDialogIsOpen}
        onClose={onInDialogClose}
        onRequestClose={onInDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="lg-dialog"
      >
        <div className="flex border-b pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/file-check.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              {inductionData?.company_ind_name}
            </h5>
          </div>
        </div>
        {singleSegmentValue == "siteInduction" && (
          <InductionView
            inductionView={inductionData}
            onInDialogClose={onInDialogClose}
            // inductionName={inductionName()}
            setInductionOpen={setInductionOpen}
            clientOrdId={clientOrdId}
            setrefetch={setrefetch}
            refetch={refetch}
          />
        )}
        {singleSegmentValue == "companyInduction" && (
          <CompanyInductionView
            inductionView={inductionData}
            setInductionOpen={setInductionOpen}
            selectedProvType={selectedProvType}
            // inductionName={inductionName()}
            onInDialogClose={onInDialogClose}
            clientOrdId={clientOrdId}
            setrefetch={setrefetch}
            refetch={refetch}
          />
        )}
      </Dialog>
      <Dialog
        isOpen={inductionOpen}
        onClose={inductionCptClose}
        onRequestClose={inductionCptClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m12.895 21.586-.1-.15c-.695-1.042-1.043-1.563-1.501-1.94a4 4 0 0 0-1.378-.737c-.568-.173-1.194-.173-2.447-.173H6.095c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874c-.218-.428-.218-.988-.218-2.108v-8.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.427-.218.987-.218 2.108-.218h.4c2.24 0 3.36 0 4.215.436A4 4 0 0 1 12.46 5.77c.436.856.436 1.976.436 4.216m0 11.6v-11.6m0 11.6.1-.15c.694-1.042 1.042-1.563 1.5-1.94a4.001 4.001 0 0 1 1.378-.737c.568-.173 1.194-.173 2.447-.173h1.375c1.12 0 1.68 0 2.107-.218a2 2 0 0 0 .875-.874c.218-.428.218-.988.218-2.108v-8.6c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.875-.874c-.427-.218-.987-.218-2.107-.218h-.4c-2.24 0-3.36 0-4.216.436A4 4 0 0 0 13.33 5.77c-.436.856-.436 1.976-.436 4.216"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Congrats! Induction completed successfully.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={inductionCptClose}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default Clients;
