import React, { useEffect, useState, useCallback } from "react";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../common/element/Select/Select";
import Dialog from "../../../../common/element/Dialog/Dialog";
import SiteList from "./SiteList";
import Input from "../../../../common/element/Input";
import { GetWorkerSiteLogs } from "../../../../actions/worker-portal/GetWorkerSiteLogs";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { debounce } from "../../../../common/snippet/Debouncing";
import useCallbackRef from "../../../../common/element/hooks/useCallbackRef";
import ClientInput from "../../../../common/element/Input/clientInput";
import { Pagination } from "../../../../common/pagination";
import ReactPaginate from "react-paginate";
import Heading from "../../../../common/element/Heading";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
const WorkerSiteLog = (props) => {
  const GetFunction = useSelector((state) => state);
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  const [totalPages, setTotalPages] = useState(1);
  const [apiCalls, setApiCalls] = useState(true);
  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    individual_id: loginUserData?.userName?.individual_id,
    page: 1,
    limit: 10,
    sort: "trading_name",
    order: "desc",
    search: "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(setBreadcrumbs([{ label: "Site Log", url: "/worker/siteLog" }]));
  }, []);
  const onDialogClose = (e) => {
    props.getOnDialogClose(onDialogClose);
  };

  // Filter code

  // All ReduxFunction
  const fetchWorkerSiteLogs = useCallback(() => {
    dispatch(GetWorkerSiteLogs(getAllinvitedData));
  }, [dispatch, getAllinvitedData]);

  useEffect(() => {
    fetchWorkerSiteLogs();
  }, [getAllinvitedData]);

  const handleSort = (column) => {
    if (column === getAllinvitedData.sort) {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    } else {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
    }
  };
  const handlePageChange = ({ selected }) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(
      Math.ceil(
        GetFunction.GetWorkerportalSiteLogsData?.data?.data?.count /
        getAllinvitedData.limit
      )
    );
  };

  // Search Data
  const handleSearch = (e) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value.trim(),
      };
    });
  };

  const inputchangehandler = useCallbackRef(debounce(handleSearch), []);
  const getPageNumber = () => {
    setTotalPages(
      Math.ceil(
        GetFunction?.GetWorkerportalSiteLogsData?.data?.data?.count /
        getAllinvitedData.limit
      )
    );
  };
  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);
  const updatePageLimit = (newLimit) => {
    if (apiCalls == true) {
      setApiCalls(false)
    }
    else {

      SetgetAllinvitedData(prevState => ({
        ...prevState,
        limit: newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10,
        page: 1
      }));
      setTotalPages(Math.ceil(GetFunction.AdminSpecificWorkerSiteLogsData?.data?.data?.count / newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10));
    }
  };

  const updatePage = (newPage) => {
    if (apiCalls == true) {
      setApiCalls(false)
    }
    else {

      SetgetAllinvitedData(prevState => ({
        ...prevState,
        page: newPage?.page ? newPage?.page : newPage ? newPage : 1,
      }));
    }

  };
  return (
    <React.Fragment>
      <Heading className="mb-1">Site Log</Heading>
      <p className="text-gray-600 font-medium mb-7">
        Lorem ipsum dolor sit amet consectetur. Sem arcu mi in gravida tincidunt
        eget sit. Dui felis aliquam metus morbi morbi.
      </p>
      <div className="flex justify-between mb-5">
        <div className="w-80 h-10">
          <div className="input-wrapper">
            <div className="input-suffix-start left-3.5">
              <img
                src="/assets/icons-svg/search-lg.svg"
                alt=""
                className="h-5"
              />
            </div>
            <ClientInput
              type="text"
              className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Search"
              inputchangehandler={inputchangehandler}
              name={"search"}
            />
          </div>
        </div>
      </div>
      <SiteList handleSort={handleSort} getAllinvitedData={getAllinvitedData} />
      <div
        className="flex justify-between mt-3"
        style={{
          alignItems: "Right",
          listStyle: "none",
          padding: 0,
        }}
      >
        <Pagination
          totalCount={
            GetFunction?.GetWorkerportalSiteLogsData?.data?.data?.count || 0
          }
          pageNumber={getAllinvitedData.page}
          displayPage={
            GetFunction?.GetWorkerportalSiteLogsData?.data?.data?.rows
              ?.length || 0
          }
          UpdatePageLimit={updatePageLimit}
          UpdatePage={updatePage}
        // UpdatePageLimit={SetgetAllinvitedData}
        // allfilters={getAllinvitedData}
        />
        {GetFunction?.GetWorkerportalSiteLogsData?.data?.data?.count > 0 && (
          <ReactPaginate
            className="flex justify-content-end p-2 align-items-center pagination-wrap"
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLinkClassName={"prev-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"prev-link"}
            previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
            nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
            pageLinkClassName={"px-4 rounded-md"}
            activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
            disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
            renderOnZeroPageCount={null}
            forcePage={getAllinvitedData?.page - 1} />
        )}
      </div>
    </React.Fragment>
  );
};
export default WorkerSiteLog;
