import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Table from "../../../../../../common/element/Table";
import Dialog from "../../../../../../common/element/Dialog";
import Label from "../../../../../../common/element/Label";
import * as userActions from "../../../../../../actions/index";
import Button from "../../../../../../common/element/Buttons";
import moment from "moment";
import { EmailToIndividual } from "../../../../../../actions/index";
import ThreeDotLoader from "../../../../../../common/element/ButtonLoader/ThreeDotLoader";
import Validations from "../../../../../../common/404/Validations";
import { GetAllIndividualForEmail } from "../../../../../../actions/Admin-Client/Admin-Client";
import RichTextEditor from "../../../../../../common/snippet/RichTextEditor";
import EmailImg from "../../../../../../assets/icons-svg/email.svg";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Checkbox from "../../../../../../common/element/Checkbox/Checkbox";
import Select from "../../../../../../common/element/Select";
import { debounce } from "../../../../../../common/snippet/Debouncing";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import Input from "../../../../../../common/element/Input";
import { email } from "../../../../../../actions/Auth";
import { ForwardEmail } from "../../../../../../actions/Admin";
const { Tr, Th, Td, THead, TBody } = Table;
const EmailLogList = (props) => {
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  let individualId;
  if (loginUserData !== null) {
    individualId = loginUserData?.userName?.individual_id;
  }
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedCopy, setSelectedCopy] = useState(false);
  const [emailForOpen, setEmailForOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [emailId, SetEmailId] = useState("");

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [copyEmailSend, setCopyEmailSend] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState("");
  const [formError, setFormError] = useState("");
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  let superAdminEmail;
  if (loginData) {
    superAdminEmail = loginData?.userName?.email
      ? loginData?.userName?.email
      : "";
  }
  const [logData, setLogData] = useState({
    sender: "",
    message: "",
    recipient: "",
    subject: "",
    cc: "",
    bcc: "",
    timeStamp: "",
  });
  const openDialog = (email) => {
    SetEmailId(email?.emailId);
    setIsOpen(true);
    GetEmailLogById(email?.emailId);
    // setSender(email?.sender);
    setLogData({
      ...logData,
      sender: email?.sender,
    });
  };

  useEffect(() => {
    setCopyEmailSend(selectedCopy == true ? superAdminEmail : "");
  }, [selectedCopy]);

  const handleCheckbox = () => {
    setSelectedCopy((prevState) => !prevState);
  };
  const forwardEmail = () => {
    setEmailForOpen(true);
  };
  const onDialogCloseEmail = () => {
    if (!buttonDisabled) {
      setSelectedEmails([]);
      setSelectedCopy(false);
      setEmailForOpen(false);
      setCopyEmailSend("");
      setSelectedOption(null);
    }
  };

  // const handleSelectChange = (selectedOption) => {
  //   let filterData = selectedOption.map((item) => item.value)
  //   setSelectedOption(selectedOption);
  //   setCopyEmailSend((prevState) => ({
  //     ...prevState,
  //     emails: filterData
  //   }))
  // };
  const onDialogClose = () => {
    setIsOpen(false);
    setLogData({
      sender: "",
      message: "",
      recipient: "",
      subject: "",
      cc: "",
      bcc: "",
      timeStamp: "",
    });
    setUploadedFiles("");
    setCopyEmailSend("");
    setSelectedCopy(false);
    setSelectedOption(null);
  };

  const GetEmailLogById = useCallback(
    async (id) => {
      dispatch(userActions.GetEmailLogById(id)).then((response) => {
        if (response.success === true) {
          const arrayData = response.data;
          const emailDate = moment(arrayData?.created_date).format(
            "HH:mm DD/MM/YYYY"
          );
          const fileData = [];
          arrayData.email_attach_docs.forEach((data) => {
            const file = data;
            const parts = data.split("-");
            let FileName;
            const partAfterThirdHyphen = parts.slice(4).join("-");
            const decodedData = decodeURIComponent(partAfterThirdHyphen);
            if (parts.length >= 3) {
              FileName = parts[parts.length - 1];
              // FileName = FileName.replace(/%/g, ' ');
              FileName = decodedData;
            }
            fileData.push({
              // size: sizeKB,
              name: FileName,
              file: file,
            });
          });
          setUploadedFiles(fileData);
          setLogData((prevFormData) => ({
            ...prevFormData,
            cc: arrayData?.email_cc,
            bcc: arrayData?.email_bcc,
            recipient: arrayData?.email_to,
            subject: arrayData?.email_subject,
            message: arrayData?.email_content,
            timeStamp: emailDate,
          }));
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        } else {
        }
      });
      // eslint-disable-next-line
    },
    [dispatch]
  );

  function isEmail(value) {
    // Regular expression for checking email format
    if (value !== undefined && value !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    }
  }

  const handleSearchChange = debounce((newInputValue) => {
    setSearch(newInputValue);
    let dataForApi = {
      search: newInputValue,
    };
    dispatch(GetAllIndividualForEmail(newInputValue))
      .then((response) => {
        if (response?.success) {
          const arrayData = response?.data;
          const maildata = [];
          for (let key in arrayData) {
            maildata.push({
              individual_id: arrayData[key]?.individual_id,
              label: arrayData[key]?.email,
              value: arrayData[key]?.email,
            });
          }
          setSearchOptions(maildata);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });

    setFormError("");
  }, 500);
  // Handle select change
  const handleSelectChange = (selectedOption, identifier) => {
    let selectedEmail = [];
    selectedOption.map((item) => {
      selectedEmail.push(item?.label);
    });
    setSelectedEmails(selectedOption);
    // if (isEmail(selectedOption?.label)) {
    // }
    // else{
    //   setFormError("Please enter valid email")
    // }
  };

  const customOption = { value: search, label: search };
  const updatedOptions = searchOptions.find((option) => option.value === search)
    ? searchOptions
    : [...searchOptions, customOption];

  const onSendEmail = () => {
    // email valid check
    const emailValidation = Validations({
      value: selectedEmails.length,
      Validations: { required: true },
    });

    if (!emailValidation.valid) {
      setFormError(emailValidation.error_msg);
    }

    if (emailValidation.valid === true) {
      setButtonDisabled(true);
      const payload = {
        email_id: emailId,
        email_to: JSON.stringify(selectedEmails.map((item) => item.value)),
        sendMeCopy: selectedCopy,
        individual_id: individualId,
      };

      dispatch(ForwardEmail(payload))
        .then((response) => {
          if (response?.success) {
            setSelectedEmails([]);
            setSelectedCopy(false);
            setEmailForOpen(false);
            onDialogClose();
            setButtonDisabled(false);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {})
        .finally(() => {
          setButtonDisabled(false);
        });
    }
  };
  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("created_date")}
                >
                  Timestamp
                  <svg
                    className={`transform ${
                      props?.filtersort === "created_date" &&
                      props?.filterorder === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th>Sender</Th>
              <Th>Recipient</Th>
              <Th>Template</Th>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("email_subject")}
                >
                  Subject
                  <svg
                    className={`transform ${
                      props?.filtersort === "email_subject" &&
                      props?.filterorder === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th></Th>
            </Tr>
          </THead>
          <TBody>
            {props?.emailData &&
              props?.emailData.map((item) => (
                <Tr key={item?.emailId}>
                  <Td>{item?.createdDate ? item?.createdDate : "-"}</Td>
                  <Td>{item?.sender ? item?.sender : "-"}</Td>
                  <Td>
                    {item?.recipient?.map((mail, index) => (
                      <React.Fragment key={index}>
                        {mail ? mail : "-"}
                        {index !== item?.recipient?.length - 1 && ", "}
                      </React.Fragment>
                    ))}
                  </Td>
                  <Td>{item?.templateName ? item?.templateName : "-"}</Td>
                  <Td>
                    <span className="email-subject-wrap">
                      {item?.emailSubject ? item?.emailSubject : "-"}
                    </span>
                  </Td>
                  <Td>
                    <span className="flex justify-end">
                      <Button onClick={() => openDialog(item)}>
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </span>
                  </Td>
                </Tr>
              ))}
          </TBody>
        </Table>
      </div>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        width={900}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={EmailImg} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Email sent
            </h5>
          </div>
        </div>
        <div className="p-6 pt-1">
          <div className="flex flex-row">
            <div className="flex-1 mb-4">
              <Label className="block text-12 font-medium mb-1">Sender</Label>
              <span className="font-semibold text-black">
                {logData?.sender}
              </span>
            </div>
            <div className="flex-1 mb-4">
              <Label className="block text-12 font-medium mb-1">
                Recipient*
              </Label>
              <span className="font-semibold text-black">
                {logData?.recipient &&
                  logData?.recipient?.map((item, index) => (
                    <React.Fragment key={index}>
                      {item}
                      {index !== logData?.recipient?.length - 1 && ", "}
                    </React.Fragment>
                  ))}
              </span>
            </div>
          </div>
          <div className="flex flex-row">
            {logData?.cc && logData?.cc?.length != 0 && (
              <div className="flex-1 mb-4">
                <Label className="block text-12 font-medium mb-1">CC</Label>
                <span className="font-semibold text-black">
                  {logData?.cc?.map((item, index) => (
                    <React.Fragment key={index}>
                      {item}
                      {index !== logData?.cc.length - 1 && ", "}
                    </React.Fragment>
                  ))}
                </span>
              </div>
            )}
            {logData?.bcc && logData?.bcc?.length != 0 && (
              <div className="flex-1 mb-4">
                <Label className="block text-12 font-medium mb-1">BCC</Label>
                <span className="font-semibold text-black">
                  {/* {viewData?.email_bcc} */}
                  {logData?.bcc?.map((item, index) => (
                    <React.Fragment key={index}>
                      {item}
                      {index !== logData?.bcc?.length - 1 && ", "}
                    </React.Fragment>
                  ))}
                </span>
              </div>
            )}
          </div>
          <div className="flex flex-row mb-4">
            <div className="flex-1">
              <Label className="block text-12 font-medium mb-1">Subject*</Label>
              <span className="font-semibold text-black">
                {logData?.subject}
              </span>
            </div>
            <div className="flex-1">
              <Label className="block text-12 font-medium mb-1">
                Timestamp
              </Label>
              <span className="font-semibold text-black">
                {logData?.timeStamp}
              </span>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex-1">
              <Label className="block text-12 font-medium mb-1">Message*</Label>
              <RichTextEditor
                value={logData?.message}
                // modules={{
                //   toolbar: false,
                // }}
                readOnly={true}
              />
            </div>
          </div>
          {uploadedFiles &&
            uploadedFiles.length > 0 &&
            uploadedFiles.map((item, index) => (
              <div className="columns-input">
                <div className="upload-file-list">
                  <div className="upload-file" key={index}>
                    <div className="flex">
                      <div className="upload-file-thumbnail">
                        <span className="w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_7200_9671)">
                              <path
                                d="M9.33341 2.19136V4.94505C9.33341 5.31842 9.33341 5.5051 9.40608 5.64771C9.46999 5.77315 9.57198 5.87514 9.69742 5.93906C9.84003 6.01172 10.0267 6.01172 10.4001 6.01172H13.1538M13.3334 7.3372V12.1451C13.3334 13.2652 13.3334 13.8252 13.1154 14.253C12.9237 14.6294 12.6177 14.9353 12.2414 15.1271C11.8136 15.3451 11.2535 15.3451 10.1334 15.3451H5.86675C4.74664 15.3451 4.18659 15.3451 3.75877 15.1271C3.38244 14.9353 3.07648 14.6294 2.88473 14.253C2.66675 13.8252 2.66675 13.2652 2.66675 12.1451V5.21172C2.66675 4.09161 2.66675 3.53156 2.88473 3.10374C3.07648 2.72741 3.38244 2.42145 3.75877 2.22971C4.18659 2.01172 4.74664 2.01172 5.86675 2.01172H8.00793C8.49711 2.01172 8.7417 2.01172 8.97188 2.06698C9.17595 2.11597 9.37104 2.19678 9.54998 2.30644C9.75182 2.43012 9.92477 2.60307 10.2707 2.94898L12.3962 5.07446C12.7421 5.42036 12.915 5.59332 13.0387 5.79515C13.1484 5.97409 13.2292 6.16918 13.2782 6.37326C13.3334 6.60343 13.3334 6.84802 13.3334 7.3372Z"
                                stroke="#5786CC"
                                stroke-width="1.33333"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_7200_9671">
                                <rect
                                  width="16"
                                  height="16"
                                  fill="white"
                                  transform="translate(0 0.679688)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </div>
                      <div className="upload-file-info">
                        <a
                          href={item?.file}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h6 className="upload-file-name font-medium text-xs text-gray-700">
                            {/* bg-icon-box1.jpg */}
                          </h6>
                          <span className="upload-file-size text-xs text-gray-600">
                            {/* 31 KB- 100% uploaded */}
                            {item?.name}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div className="mt-8 flex justify-between pb-6 px-6">
            <BtnLight onClick={() => onDialogClose()} className="mr-1.5">
              Close
            </BtnLight>
            <BtnPrimary className="ml-1.5" onClick={() => forwardEmail()}>
              Forward email
            </BtnPrimary>
          </div>
        </div>
      </Dialog>

      <Dialog
        isOpen={emailForOpen}
        onClose={onDialogCloseEmail}
        onRequestClose={onDialogCloseEmail}
        width="650px"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={EmailImg} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Forward Email
            </h5>
          </div>
        </div>
        <div className="p-6 pt-1">
          <Label className="block font-medium text-xs leading-4 mb-2">
            Email address:
          </Label>
          <div className="w-full m-auto table">
            <Select
              type="text"
              placeholder=""
              className="w-full h-auto select-options"
              isClearable={true}
              isSearchable={true}
              options={updatedOptions ? updatedOptions : selectedEmails}
              value={selectedEmails}
              onChange={(e) => handleSelectChange(e)}
              handleSearchChange={(e) => handleSearchChange(e)}
              isMulti={true}
            />
            {formError && (
              <p className="text-red-500 text-sm mt-1">{formError}</p>
            )}
          </div>
        </div>
        <div className="p-6 pt-0">
          <Checkbox
            checked={selectedCopy}
            onClick={() => handleCheckbox()}
            className="font-medium text-sm leading-5"
          >
            {" "}
            Send me a copy
          </Checkbox>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight
            disabled={buttonDisabled}
            onClick={() => onDialogCloseEmail()}
            className="mr-1.5"
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            className="ml-1.5"
            disabled={buttonDisabled}
            onClick={() => onSendEmail()}
          >
            {buttonDisabled ? <ThreeDotLoader /> : "Send"}
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default EmailLogList;
