import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../../../../../../common/element/Heading/index.js";
import BtnLight from "../../../../../../common/element/Buttons/btnLight.js";
import Dropdown from "../../../../../../common/element/Dropdown/Dropdown.js";
import Label from "../../../../../../common/element/Label/index.js";
import Input from "../../../../../../common/element/Input/Input.js";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice.js";
import Select from "../../../../../../common/element/Select/Select.js";
import Button from "../../../../../../common/element/Buttons/index.js";
const ProvidersComplianceChecklist = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                { label: "Reports", url: "/admin/provider/reports", home: "/admin/provider/dashboard" },
                { label: "Providers Compliance Checklist ", url: "/admin/provider/reports/compliance-checklist" },
            ])
        );
    }, [dispatch]);
    return (
        <React.Fragment>

            <div className="flex justify-between mb-5 border-b border-gray-200 pb-5">
                <Heading>Providers Compliance Checklist</Heading>
            </div>
        </React.Fragment>
    )
}

export default ProvidersComplianceChecklist