import React, { useEffect, useRef, useState } from "react";
import logomark from "./Logomark.png";
// import pdfbg from "../../../../../assets/icons-svg/"
// import pdfbg from "./head-bg.png";
import pdfbg from "./pdfbg.svg";
import Logo from "./Logo.png";
import sign from "./sign.png";
import Button from "../../common/element/Buttons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Dialog from "../../common/element/Dialog/Dialog";
import { useReactToPrint } from "react-to-print";
import { useDispatch } from "react-redux";
import { GetProviderGeneratedCertificateById } from "../../actions/admin-provider/GetProviderGeneratedCertificateById";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
const PdfDataView = ({
  PdfData,
  type,
  userSettingHide,
  accreditationDate,
  provider_fa_id,
  provider_org_id,
}) => {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [startPosition, setStartPosition] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(100);
  const [showPdf, setShowPdf] = useState(true);
  const [dragging, setDragging] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [openPdf, SetOpenPdf] = useState(false);
  const printDocument = () => {
    // Create a link element
    const link = document.createElement("a");
    // Set the URL to the pdfUrl
    link.href = pdfUrl;
    // Set the download attribute with a default filename or extract from the URL
    link.download = "document.pdf"; // You can change the name if needed
    // Append the link to the body (necessary for some browsers)
    document.body.appendChild(link);
    // Trigger a click on the link to start the download
    link.click();
    // Remove the link from the document
    document.body.removeChild(link);
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  });
  const handleZoomOut = () => {
    setZoom(zoom + 10);
  };

  const handleZoomIn = () => {
    if (zoom > 1) {
      setZoom(zoom - 10);
    }
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setDragging(false);
    setStartPosition(null);
  };

  const handleMouseMove = (e) => {
    if (dragging && startPosition) {
      const deltaX = e.clientX - startPosition.x;
      const deltaY = e.clientY - startPosition.y;
      setPosition({
        x: position.x + deltaX,
        y: position.y + deltaY,
      });
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const onDialogAddDocClose = () => {
    SetOpenPdf(false);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getClientData = () => {
    dispatch(
      GetProviderGeneratedCertificateById({
        provider_org_id: provider_org_id,
        type: type,
      })
    ).then((response) => {
      if (response.status) {
        setPdfUrl(response?.data?.certi_url);
      }
    });
  };
  
  useEffect(() => {
    getClientData();
  }, []);
  return (
    <React.Fragment>
      {pdfUrl && (
        <>
          {!openPdf && (
            <React.Fragment>
              {showPdf && (
                <div className="border border-gray-300 rounded-lg pt-8	relative h-80 w-96">
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={pdfUrl}
                      // plugins={[defaultLayoutPluginInstance]}
                      theme="light"
                    />
                  </Worker>
                  {/* <div>
                    <div
                      style={{
                        background: "rgb(245, 250, 255)",
                        margin: 0,
                        padding: 0,
                        borderCollapse: "collapse",
                        border: "none",
                        width: "200px",
                        height:"100%"
                      }}
                    >
                    
                    </div>
                  </div> */}
                  <div className="flex border-t border-gray-100 pdf-btn-block">
                    <Button
                      className="border-r h-10 flex gap-1 flex-1 text-sm font-semibold text-gray-700 items-center justify-center"
                      onClick={() => SetOpenPdf(true)}
                    >
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.375 7.64258L18.375 2.64258M18.375 2.64258H13.375M18.375 2.64258L11.7083 9.30924M9.20833 4.30924H7.375C5.97487 4.30924 5.2748 4.30924 4.74002 4.58173C4.26962 4.82141 3.88717 5.20386 3.64748 5.67427C3.375 6.20905 3.375 6.90911 3.375 8.30924V13.6426C3.375 15.0427 3.375 15.7428 3.64748 16.2776C3.88717 16.748 4.26962 17.1304 4.74002 17.3701C5.2748 17.6426 5.97487 17.6426 7.375 17.6426H12.7083C14.1085 17.6426 14.8085 17.6426 15.3433 17.3701C15.8137 17.1304 16.1962 16.748 16.4358 16.2776C16.7083 15.7428 16.7083 15.0427 16.7083 13.6426V11.8092"
                          stroke="#344054"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Open
                    </Button>
                    <Button
                      className="border-l h-10 flex gap-1 flex-1 text-sm font-semibold text-gray-700 items-center justify-center"
                      onClick={printDocument}
                    >
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.875 12.6426V13.6426C17.875 15.0427 17.875 15.7428 17.6025 16.2776C17.3628 16.748 16.9804 17.1304 16.51 17.3701C15.9752 17.6426 15.2751 17.6426 13.875 17.6426H6.875C5.47487 17.6426 4.7748 17.6426 4.24002 17.3701C3.76962 17.1304 3.38717 16.748 3.14748 16.2776C2.875 15.7428 2.875 15.0427 2.875 13.6426V12.6426M14.5417 8.47591L10.375 12.6426M10.375 12.6426L6.20833 8.47591M10.375 12.6426V2.64258"
                          stroke="#344054"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Download
                    </Button>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
          {openPdf && (
            <Dialog
              isOpen={true}
              bodyOpenClassName="overflow-hidden"
              className="documentDialog"
              onClose={onDialogAddDocClose}
              onRequestClose={onDialogAddDocClose}
            >
              <React.Fragment>
                <div
                  className="image-container view_pdf"
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onMouseMove={handleMouseMove}
                  id="divToPrint"
                  ref={componentRef}
                >
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={pdfUrl}
                      plugins={[defaultLayoutPluginInstance]}
                      theme="dark"
                    />
                  </Worker>
                </div>
              </React.Fragment>
            </Dialog>
          )}
        </>
      )}
      {(pdfUrl == "" || pdfUrl == null || pdfUrl == undefined) && (
        <div className="h-full w-full text-center my-2">No Data Available!</div>
      )}
    </React.Fragment>
  );
};

export default PdfDataView;
