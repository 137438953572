import React, { useEffect, useState } from "react";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../../common/element/Select/Select";
import ClientInput from "../../../../../common/element/Input/clientInput";
import { useDispatch, useSelector } from "react-redux";
import { UpdateProviderClientContact } from "../../../../../actions/admin-provider/UpdateContractorClientContact";

// import { GetAllContactsOfProvider } from "../../../../../actions/admin-provider/GetAllContactsOfProvider";
import { GetAllContactsOfProvider } from "../../../../../actions/admin-provider/GetAllContactsOfContractor";

import { notify } from "react-notify-toast";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import Validations from "../../../../../common/404/Validations";
import Dialog from "../../../../../common/element/Dialog";
const EditContactForm = (props) => {
  const dispatch = useDispatch();
  const [contactType, setContactType] = useState("");
  const allFunction = useSelector((state) => state);
  const filteredContacts = allFunction.AllContactTypes.filter(
    (contact) => contact.contact_type === "provider contact"
  );
  const [dialogAddOpen, SetDialogAddOpen] = useState(false);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const ipAddress = useSelector((state) => state?.ipAddress?.ip);
  const [OrgId, SetOrgId] = useState("");
  const [addContactData, SetaddContactData] = useState({
    individual_id: "",
    organisation_id: "",
    job_title: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
  });

  const [errorvalidation, seterrorvalidation] = useState({
    first_name: "",
    last_name: "",
    phone: "",
  });

  useEffect(() => {
    setContactType(filteredContacts?.[0]?.contact_type);
  }, [filteredContacts?.[0]]);
  useEffect(() => {
    if (props.indivisualData) {
      if (props.indivisualData.hasOwnProperty("provider_client_contact_id")) {
        SetOrgId(props.indivisualData.provider_org_id);
        SetaddContactData({
          provider_client_contact_id:
            props.indivisualData.provider_client_contact_id,
          job_title: props.indivisualData.contact_title,
          email: props.indivisualData.contact_email,
          first_name: props.indivisualData.contact_first_name,
          last_name: props.indivisualData.contact_last_name,
          phone: props.indivisualData.contact_phone,
        });
      } else {
        SetaddContactData((prevState) => ({
          ...prevState,
          ...props.indivisualData,
        }));
      }
    }
  }, [props.indivisualData]);

  // Update Data Function
  const onUpdateData = () => {
    let validationCheck = true;
    const first_name = Validations({
      value: addContactData.first_name,
      Validations: { required: true },
    });
    const last_name = Validations({
      value: addContactData.last_name,
      Validations: { required: true },
    });

    const phone = Validations({
      value: addContactData.phone,
      Validations: {
        required: addContactData.phone == "" ? false : true,
        min_length: 7,
        max_length: 15,
      },
    });

    if (!first_name.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["first_name"]: first_name.error_msg,
        };
      });
    }
    if (!last_name.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["last_name"]: last_name.error_msg,
        };
      });
    }

    if (!phone.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["phone"]: phone.error_msg,
        };
      });
    }

    if (first_name.valid && last_name.valid && phone.valid) {
      validationCheck = true;
    } else {
      validationCheck = false;
    }
    if (validationCheck) {
      if (props.indivisualData.hasOwnProperty("provider_client_contact_id")) {
        let filterdata = {
          provider_client_contact_id: addContactData.provider_client_contact_id,
          contact_title: addContactData.job_title,
          contact_first_name: addContactData.first_name,
          contact_last_name: addContactData.last_name,
          contact_phone: addContactData.phone,
          contact_type: contactType,
        };

        dispatch(UpdateProviderClientContact(filterdata, "client", ipAddress))
          .then((response) => {
            SetDialogAddOpen(true);
            if (response.success) {
              SetDialogAddOpen(true);
              dispatch(
                GetAllContactsOfProvider(
                  {
                    organisation_id: OrgId,
                    page: 1,
                    type: "clientContact",
                    limit: 10,
                    sort: "created_date",
                    order: "desc",
                    search: "",
                  },
                  ["client"]
                )
              );
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            }
          })
          .catch((error) => {
            notify.show(error, "error");
          });
      } else {
        dispatch(UpdateProviderClientContact(addContactData))
          .then((response) => {
            if (response.success) {
              SetDialogAddOpen(true);
              if (props.type == "internal") {
                dispatch(
                  GetAllContactsOfProvider(
                    {
                      organisation_id: addContactData.organisation_id,
                      page: 1,
                      limit: 10,
                      type: "internal",
                      sort: "created_date",
                      order: "desc",
                      search: "",
                    },
                    ["all"]
                  )
                );
              } else {
                dispatch(
                  GetAllContactsOfProvider(
                    {
                      organisation_id: addContactData.organisation_id,
                      page: 1,
                      limit: 10,
                      sort: "created_date",
                      order: "desc",
                      search: "",
                    },
                    ["all"]
                  )
                );
              }
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            }
          })
          .catch((error) => {
            notify.show(error, "error");
          });
      }
    }
  };
  // Form Update input
  const formHandle = (e) => {
    if (
      e.target.name == "first_name" ||
      e.target.name == "last_name" ||
      e.target.name == "phone"
    ) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          [e.target.name]: "",
        };
      });
    }
    
    SetaddContactData((prevalue) => {
      if (props.indivisualData.hasOwnProperty("provider_client_contact_id")) {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      } else {
        if (e.target.name == "job_title") {
          return {
            ...prevalue,
            [e.target.name]: e.target.value,
          };
        } else {
          return {
            ...prevalue,
          };
        }
      }
    });
  };

  const onAddDialogClose = () => {
    SetDialogAddOpen(false);
    props.CloseEditForm();
  };

  return (
    <React.Fragment>
      <form>
        <div className="mt-6 add-client-form px-6">
          <div className="flex justify-between flex-col">
            <div className="flex">
              <div className="flex-1 pl-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Email address*
                </label>
                <ClientInput
                  value={addContactData.email}
                  type="email"
                  name="email"
                  readOnly={true}
                  inputchangehandler={formHandle}
                  placeholder="Enter Contact's email address"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
            <div className="flex">
              <div className="flex-1 pr-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  First name*
                </label>
                <ClientInput
                  type="text"
                  name="first_name"
                  inputchangehandler={formHandle}
                  value={addContactData.first_name}
                  placeholder="Enter Contact's first name"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errorvalidation.first_name && (
                  <div className="text-red-500 text-sm mt-1 ">
                    {errorvalidation.first_name}
                  </div>
                )}
              </div>
              <div className="flex-1 pl-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Last name*
                </label>
                <ClientInput
                  value={addContactData.last_name}
                  type="text"
                  name="last_name"
                  inputchangehandler={formHandle}
                  placeholder="Enter Contact's last name"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errorvalidation.last_name && (
                  <div className="text-red-500 text-sm mt-1 ">
                    {errorvalidation.last_name}
                  </div>
                )}
              </div>
            </div>
            <div className="flex">
              <div className="flex-1 pr-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Job title
                </label>
                <ClientInput
                  type="text"
                  value={addContactData.job_title}
                  name="job_title"
                  inputchangehandler={formHandle}
                  placeholder="Enter Contact's job role"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex-1 pl-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Phone number
                </label>
                <ClientInput
                  type="number"
                  name="phone"
                  inputchangehandler={formHandle}
                  value={addContactData.phone}
                  placeholder="Enter Contact's phone number"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errorvalidation.phone && (
                  <div className="text-red-500 text-sm mt-1 ">
                    {errorvalidation.phone}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight onClick={props?.onDialogClose}>Cancel</BtnLight>
          <BtnPrimary className="ml-1.5" onClick={() => onUpdateData()}>
            Update contact
          </BtnPrimary>
        </div>
      </form>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Contact updated
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={onAddDialogClose}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default EditContactForm;
