import React, { useState, useEffect } from "react";
import Card from "../../../../../../../common/element/Card";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import Table from "../../../../../../../common/element/Table";
import Scrollbar from "../../../../../../../common/element/ScrollBar";
import Tag from "../../../../../../../common/element/Tag";
import Upload from "../../../../../../../common/element/Upload";
import SiteName from "../../SiteName";
import {
  DeleteSiteEvacuationDiagram,
  GetClientSiteById,
} from "../../../../../../../actions/client-portal/AllSiteApi";
import PdfFile from "../../../../../../../assets/icons-svg/upload-pdf.svg";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import trashIcon from "../../../../../../../assets/icons-svg/trash.svg";
import { connect, useDispatch, useSelector } from "react-redux";
import { setBreadcrumbs } from "../../../../../../../redux/classes/breadcrumbsSlice";
import { useParams } from "react-router-dom";
const Overview = (props) => {
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const dispatch = useDispatch();
  const { id, siteId } = useParams();
  const { Tr, Th, Td, THead, TBody } = Table;
  const files = props?.siteData?.evacuation_diagram;
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Sites", url: "/client/sites", home: "/client/dashboard" },
        {
          label: props?.siteData?.site_name ? props?.siteData?.site_name : "",
          url: `/client/sites/specific-site/${encodeURIComponent(siteId)}`,
        },
        {
          label: "Overview",
          url: `/client/sites/specific-site/${encodeURIComponent(siteId)}`,
        },
      ])
    );
  }, [props?.siteData]);
  const handleRemoveFile = (fileUrl) => {
    let fileArray = files?.filter((item) => item !== fileUrl);
    const payload = {
      site_id: props?.siteData?.site_id,
      evacuation_diagram_url: fileUrl,
      evacuation_diagram: fileArray,
    };

    dispatch(DeleteSiteEvacuationDiagram(payload))
      .then((response) => {
        if (response?.success) {
          props?.handleGetClientSiteById();
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {
        console.error("Error removing file:", error);
      });
  };

  const handleTabChange = (tabNo) => {
    props?.setCurrrentTab(tabNo);
  };
  return (
    <React.Fragment>
      <div className="flex gap-2 w-full  items-start">
        <div className="w-3/6">
          <Card className=" mb-5 p-5 border border-gray-200 rounded-xl dark-shadow">
            <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-5">
              <span className="text-gray-900 text-base font-semibold flex items-center">
                Site information
              </span>
              <BtnLight
                onClick={() => handleTabChange("tab6")}
                className="h-9 w-20 flex items-center justify-center gap-2"
                // onClick={props.openAddDialog}
                disabled={props?.sitePermission}
              >
                Edit
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M14.9999 8.33326L11.6666 4.99993M2.08325 17.9166L4.90356 17.6032C5.24813 17.5649 5.42042 17.5458 5.58146 17.4937C5.72433 17.4474 5.86029 17.3821 5.98566 17.2994C6.12696 17.2062 6.24954 17.0836 6.49469 16.8385L17.4999 5.83326C18.4204 4.91279 18.4204 3.4204 17.4999 2.49993C16.5795 1.57945 15.0871 1.57945 14.1666 2.49992L3.16136 13.5052C2.91621 13.7503 2.79363 13.8729 2.70045 14.0142C2.61778 14.1396 2.55243 14.2755 2.50618 14.4184C2.45405 14.5794 2.43491 14.7517 2.39662 15.0963L2.08325 17.9166Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </BtnLight>
            </div>

            <div className="flex mb-4">
              <div className="flex-1">
                <span className="text-gray-400 font-semibold block text-sm mb-1">
                  Site name
                </span>
                <span className="text-gray-900 font-medium block text-sm">
                  {props?.siteData?.site_name
                    ? props?.siteData?.site_name
                    : "-"}
                </span>
              </div>
            </div>

            <div className="flex mb-4">
              <div className="flex-1">
                <span className="text-gray-400 font-semibold block text-sm mb-1">
                  Site Address
                </span>
                <span className="text-gray-900 font-medium block text-sm">
                  {`${
                    props?.siteData?.site_address
                      ? props.siteData.site_address
                      : "-"
                  }, ${
                    props?.siteData?.site_state?.state_name
                      ? props.siteData.site_state.state_name
                      : ""
                  }, ${
                    props?.siteData?.site_country?.name
                      ? props.siteData.site_country.name
                      : ""
                  }, ${
                    props?.siteData?.site_zipcode
                      ? props.siteData.site_zipcode
                      : ""
                  }`}
                </span>
              </div>
            </div>

            <div className="flex mb-4">
              <div className="flex-1">
                <span className="text-gray-400 font-semibold block text-sm mb-1">
                  Country
                </span>
                <span className="text-gray-900 font-medium flex gap-1 text-sm">
                  {props?.siteData?.site_country?.name
                    ? props?.siteData?.site_country?.name
                    : "-"}
                </span>
              </div>
              <div className="flex-1">
                <span className="text-gray-400 font-semibold block text-sm mb-1">
                  State
                </span>
                <span className="text-gray-900 font-medium block text-sm">
                  {props?.siteData?.site_state?.state_name
                    ? props.siteData.site_state.state_name
                    : ""}{" "}
                </span>
              </div>
            </div>

            <div className="flex mb-4">
              <div className="flex-1">
                <span className="text-gray-400 font-semibold block text-sm mb-1">
                  Valid days
                </span>
                <span className="text-gray-900 font-medium block text-sm">
                  {props?.siteData?.induction_valid_days
                    ? props?.siteData?.induction_valid_days
                    : "-"}
                </span>
              </div>
            </div>
          </Card>
          <Card className=" p-5 border border-gray-200 rounded-xl dark-shadow">
            <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-5">
              <span className="text-gray-900 text-base font-semibold flex items-center">
                Site contacts
              </span>
              <BtnLight
                onClick={() => handleTabChange("tab6")}
                className="h-9 w-20 flex items-center justify-center gap-2"
                // onClick={props.openAddDialog}
                disabled={props?.sitePermission}
              >
                Edit
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M14.9999 8.33326L11.6666 4.99993M2.08325 17.9166L4.90356 17.6032C5.24813 17.5649 5.42042 17.5458 5.58146 17.4937C5.72433 17.4474 5.86029 17.3821 5.98566 17.2994C6.12696 17.2062 6.24954 17.0836 6.49469 16.8385L17.4999 5.83326C18.4204 4.91279 18.4204 3.4204 17.4999 2.49993C16.5795 1.57945 15.0871 1.57945 14.1666 2.49992L3.16136 13.5052C2.91621 13.7503 2.79363 13.8729 2.70045 14.0142C2.61778 14.1396 2.55243 14.2755 2.50618 14.4184C2.45405 14.5794 2.43491 14.7517 2.39662 15.0963L2.08325 17.9166Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </BtnLight>
            </div>
            <div
              className={`h-64 ${
                props?.siteData?.siteContacts?.length == 0
                  ? "flex justify-center items-center"
                  : ""
              }`}
            >
              {props?.siteData?.siteContacts?.length == 0 ? (
                <div className=" w-full text-center mt-2">
                  No Data Available!
                </div>
              ) : (
                <Scrollbar>
                  <div className="overflow-hidden border rounded-lg border-gray-200">
                    <>
                      {props?.siteData?.siteContacts?.length == 0 ? (
                        <div className=" w-full text-center mt-2">
                          No Data Available!
                        </div>
                      ) : (
                        <Table className="v-align-top">
                          <TBody>
                            {props?.siteData?.siteContacts &&
                              props?.siteData?.siteContacts.map(
                                (item, index) => (
                                  <Tr key={index}>
                                    <Td>
                                      <div className="flex mb-4">
                                        <div className="flex-1">
                                          <span className="text-gray-900 font-medium block text-sm">
                                            {`${
                                              item?.siteContIndi?.first_name
                                                ? item?.siteContIndi?.first_name
                                                : "-"
                                            } ${
                                              item?.siteContIndi?.last_name
                                                ? item?.siteContIndi?.last_name
                                                : "-"
                                            }`}
                                          </span>
                                          <span className="text-gray-400 font-semibold block text-sm mb-1">
                                            {item?.siteContIndi?.org_ind
                                              ?.job_title
                                              ? item?.siteContIndi?.org_ind
                                                  ?.job_title
                                              : "-"}
                                          </span>
                                        </div>
                                      </div>
                                    </Td>
                                    <Td>
                                      {item?.siteContIndi?.email
                                        ? item?.siteContIndi?.email
                                        : "-"}
                                    </Td>
                                    <Td>
                                      {item?.siteContIndi?.phone
                                        ? item?.siteContIndi?.phone
                                        : "-"}
                                    </Td>
                                  </Tr>
                                )
                              )}
                          </TBody>
                        </Table>
                      )}
                    </>
                  </div>
                </Scrollbar>
              )}
            </div>
          </Card>
        </div>
        <Card className="w-2/5 p-5 border border-gray-200 rounded-xl dark-shadow">
          <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-5">
            <span className="text-gray-900 text-base font-semibold flex items-center">
              Evacuation Diagrams
            </span>
            <BtnLight
              onClick={() => handleTabChange("tab6")}
              className="h-9 w-20 flex items-center justify-center gap-2"
              // onClick={props.openAddDialog}
              disabled={props?.sitePermission}
            >
              Edit
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M14.9999 8.33326L11.6666 4.99993M2.08325 17.9166L4.90356 17.6032C5.24813 17.5649 5.42042 17.5458 5.58146 17.4937C5.72433 17.4474 5.86029 17.3821 5.98566 17.2994C6.12696 17.2062 6.24954 17.0836 6.49469 16.8385L17.4999 5.83326C18.4204 4.91279 18.4204 3.4204 17.4999 2.49993C16.5795 1.57945 15.0871 1.57945 14.1666 2.49992L3.16136 13.5052C2.91621 13.7503 2.79363 13.8729 2.70045 14.0142C2.61778 14.1396 2.55243 14.2755 2.50618 14.4184C2.45405 14.5794 2.43491 14.7517 2.39662 15.0963L2.08325 17.9166Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </BtnLight>
          </div>

          {props?.siteData?.evacuation_diagram &&
            props?.siteData?.evacuation_diagram.map((item, index) =>
              item ? (
                <div
                  key={index}
                  className="upload-file flex items-center justify-between mt-2"
                >
                  <div className="upload-file-left flex items-center">
                    <div className="upload-file-thumbnail">
                      <span className="w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3">
                        <img src={PdfFile} alt="PDF File" />
                      </span>
                    </div>
                    <a href={item} target="_blank" rel="noopener noreferrer">
                      <span className="upload-file-name font-medium text-xs text-gray-700">
                        {item.split("/").pop().split("?X-Amz-Algorithm")[0]}
                        {/* Extracting the file name from URL */}
                      </span>
                    </a>
                  </div>
                  <span
                    className="cursor-pointer upload-file-remove w-5"
                    onClick={() =>
                      props?.sitePermission === false && handleRemoveFile(item)
                    }
                  >
                    <img src={trashIcon} alt="Remove" />
                  </span>
                </div>
              ) : null
            )}

          <div
            onClick={() =>
              props?.sitePermission === false && handleTabChange("tab6")
            }
            className="cursor-pointer mt-5 text-blue-700 flex font-semibold text-xs items-center justify-start"
          >
            Upload addditional{" "}
            <span className="pl-2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 4.167v11.666M4.167 10h11.666"
                  stroke="#5786CC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Overview;
