import React, { useEffect, useRef, useState } from "react";
import Table from "../../../../../../common/element/Table";
import Tooltip from "../../../../../../common/element/Tooltip";
import Tag from "../../../../../../common/element/Tag";
import Button from "../../../../../../common/element/Buttons";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import Tabs from "../../../../../../common/element/Tabs";
import { notify } from "react-notify-toast";
import { GetAllDocumentsOfSpecificContractorData } from "../../../../../../actions/admin-provider/GetContractorDocById";
import { useDispatch, useSelector } from "react-redux";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import Scrollbar from "../../../../../../common/element/ScrollBar";

import { UpdateProviderDoc } from "../../../../../../actions/admin-provider/GetUpdateContractorDoc";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import moment from "moment";
import ClientInput from "../../../../../../common/element/Input/clientInput";
import { GetDocumentHistoryById } from "../../../../../../actions/admin-provider/GetDocumentHistoryById";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import rightIcon from "../../../../../../assets/icons-svg/chevron-right.svg";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import { DeleteProviderDoc } from "../../../../../../actions/admin-provider/DeleteContractorDoc";
import Upload from "../../../../../../common/element/Upload";
import { GetSignedUrlByKey } from "../../../../../../actions/public/GetSignedUrlByKey";
const { Tr, Th, Td, THead, TBody } = Table;
const { TabNav, TabList, TabContent } = Tabs;
const DocumentsList = (props) => {
  let fileExtension;
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const GettAllFunctions = useSelector((state) => state);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  });
  const dispatch = useDispatch();
  const [getData, SetGetData] = useState([]);
  const [updateData, SetupdateData] = useState([]);
  const [IndividualData, SetgetIndividual] = useState();
  const [specificData, SetspecificData] = useState([]);
  const [specificDataUpdate, SetspecificDataUpdate] = useState([]);
  const [clientOrgData, SetClientOrgData] = useState([]);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [checkEdit, SetCheckEdit] = useState(false);
  const [insuraceDialogIsOpen, setIsInsuraceOpen] = useState(false);
  const [formDialogIsOpen, setIsFormOpen] = useState(false);
  const [deleteDiolog, setDeleteDiolog] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [pdfFile, setPdfFile] = useState("");
  const [checkAllData, setcheckAllData] = useState();
  const [toggleState, setToggleState] = useState(
    Array(getData?.length).fill(false)
  );
  const [expandedRows, setExpandedRows] = useState([]);
  const [UrlPdf, setUrlPdf] = useState("");

  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState(null);

  // Get Data From Redux-store
  useEffect(() => {
    SetGetData(GettAllFunctions?.AdminSpecificContractorData?.data?.data?.rows);
  }, [GettAllFunctions]);
  const loading = useSelector(
    (state) => state.GetAdminSpecificContractorData.loading
  );
  const openDialog = (e) => {
    // dispatch(reSetData())
    let getIndivisualData = {
      document_id: e.document_id,
      document_type_id: e.document_type_id,
      function_assignment_id: props.function_assignment_id,
    };
    setIsOpen(true);
    dispatch(GetAllDocumentsOfSpecificContractorData(getIndivisualData));
    SetgetIndividual(e);
  };

  // Get Data After Click Individual Id
  useEffect(() => {
    let data = GettAllFunctions?.GetAdminSpecificContractorData?.data?.data;
    setcheckAllData(data?.docData?.document_type);

    SetspecificDataUpdate({
      document_id: data?.docData?.document_id,
      column_names: [""],
    });

    if (!GettAllFunctions?.GetAdminSpecificContractorData.loading && data) {
      SetspecificData({
        document_id: data?.docData?.document_id,
        document_type: data?.docData?.document_type?.document_type,
        doc_type_name: data?.docData?.document_type?.doc_type_name,
        doc_name: data?.docData?.doc_name,
        document_desc: data?.docData?.expiry_date_notes,
        expiry_date: data?.docData?.expiry_date,
        customField: data?.docData?.doctype_fields_data,
        // number_of_workers: data?.doc_insurance?.number_of_workers,
        insured_amount: data?.docData?.amount_insured,
        policy_no: data?.docData?.policy_no,
        // trading_name: data?.swm_doc_type?.swm_clients_org,
        issuing_authority: data?.docData?.issuer,
        created_date: data?.docData?.created_date,
        // issue_date: data?.docData?.issue_date,
        // is_swms_doc: data?.is_swms_doc,
        doc_file: data?.docData?.doc_file,
      });
      SetClientOrgData(data?.clientsData);
    }
  }, [
    IndividualData,
    GettAllFunctions?.GetAdminSpecificContractorData?.loading,
  ]);
  // Close View Document Screen
  const onDialogClose = (e) => {
    setIsOpen(false);
    SetspecificData([]);
    SetCheckEdit(false);
    // dispatch(reSetData())
  };

  const insuraceDialogClose = (e) => {
    setIsInsuraceOpen(false);
  };

  const formDialogClose = (e) => {
    setIsFormOpen(false);
  };

  const handleTabClick = (event) => {
    if (event.target.textContent === "History") {
      dispatch(
        GetDocumentHistoryById({ document_id: specificData?.document_id })
      );
    }
  };

  // Documentb Edit function
  const handleEditForm = (e, customField) => {
    if (customField == "emptyDoc_file") {
      SetupdateData(e[0]);
    } else {
      SetupdateData(e.target.value);
    }
    SetspecificDataUpdate((prevalue) => {
      if (customField == "customField") {
        const doctype_fields_data = {
          ...prevalue.doctype_fields_data, // If it already exists in prevalue
          [e.target.name]: e.target.value, // Assuming 'name' is the key where you want to store the value
          // If you have different keys, replace 'name' with your desired key
        };
        return {
          ...prevalue,
          doctype_fields_data: doctype_fields_data,
        };
      } else if (customField == "doc_file") {
        return {
          ...prevalue,
          ["doc_file"]: e.target.files[0],
        };
      } else if (customField == "emptyDoc_file") {
        return {
          ...prevalue,
          ["doc_file"]: e[0],
        };
      } else {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      }
    });
    SetspecificData((prevalue) => {
      if (customField == "customField") {
        return {
          ...prevalue,
          customField: {
            ...prevalue.customField,
            [e.target.name]: e.target.value,
          },
        };
      } else if (customField == "doc_file") {
        return {
          ...prevalue,
          ["doc_file"]: e.target.files[0],
        };
      } else if (customField == "emptyDoc_file") {
        return {
          ...prevalue,
          ["doc_file"]: e[0],
        };
      } else {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      }
    });
  };
  // Update data in a state
  useEffect(() => {
    let customColumnName = "";
    if (specificDataUpdate?.document_id) {
      if (specificDataUpdate?.doctype_fields_data) {
        customColumnName = Object.keys(specificDataUpdate?.doctype_fields_data);
      }

      let columnName;
      if (customColumnName) {
        columnName = {
          ...specificDataUpdate,
          [customColumnName]: customColumnName,
        };
      } else {
        columnName = specificDataUpdate;
      }
      const columnData = Object.keys(columnName).filter(
        (key) =>
          key !== "document_id" &&
          key !== "column_names" &&
          key !== "doctype_fields_data"
      );
      SetspecificDataUpdate((prevData) => ({
        ...prevData,
        column_names: columnData,
      }));
    }
  }, [updateData]);
  // Document updatData function
  let UpdateDocData;
  if (specificDataUpdate?.doctype_fields_data) {
    UpdateDocData = {
      ...specificDataUpdate,
      ["doctype_fields_data"]: JSON.stringify(
        specificDataUpdate.doctype_fields_data
      ),
    };
  } else {
    UpdateDocData = specificDataUpdate;
  }

  const updatData = () => {
    dispatch(UpdateProviderDoc(UpdateDocData))
      .then((response) => {
        if (response.success) {
          SetCheckEdit(false);
          SetspecificDataUpdate({
            document_id: specificData?.document_id,
            column_names: [""],
          });
          props.refetch(true);
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {
        notify.show(error, "error");
      });
  };

  // Function to toggle the main rows
  // const toggleClick = (index) => {
  //   const newToggleStates = [...toggleState];
  //   newToggleStates[index] = !newToggleStates[index];
  //   setToggleState(newToggleStates);
  //   // Close all child rows when closing a parent row
  //   // setExpandedChildRows({});

  //   setExpandedRows((prevExpandedRows) => {
  //     const newExpandedRows = [...prevExpandedRows];
  //     newExpandedRows[index] = !newExpandedRows[index];
  //     return newExpandedRows;
  //   });
  // };

  const onDeleteDialogClose = () => {
    setDeleteDiolog(false);
  };

  // const openDeleteHandle = (item, worker_doc_approval_id, innerTab) => {
  //   let singleItem = item.contr_doc;
  //   if (innerTab) {
  //     setDeleteData({ worker_doc_approval_id: worker_doc_approval_id });
  //   } else {
  //     if (singleItem.is_swms_doc) {
  //       setDeleteData({
  //         doc_type: "swms",
  //         document_id: singleItem.document_id,
  //         document_swm_type_id:
  //           singleItem.swm_doc_type.document_swm_type_id,
  //         swm_type_id: singleItem?.swm_doc_type?.swm_type?.swm_type_id,
  //         worker_doc_approval_id: "",
  //       });
  //     } else {
  //       setDeleteData({
  //         doc_type: singleItem.document_type.document_type,
  //         document_id: singleItem.document_id,
  //         worker_doc_approval_id: "",
  //       });
  //     }
  //   }

  //   setDeleteDiolog(true);
  // };

  const deleteModuleConfirmation = () => {
    dispatch(DeleteProviderDoc(deleteData)).then((response) => {
      if (response.success) {
        setDeleteDiolog(false);
        props.refetch(true);
      }
    });
  };
  let docName;
  if (specificData?.doc_file) {
    if (specificData?.doc_file.name) {
      docName = specificData?.doc_file.name;
    } else {
      docName = specificData?.doc_file.split("-");
      const partAfterThirdHyphen = docName.slice(4).join("-");
      // Decode the URL component to get the actual data
      docName = decodeURIComponent(partAfterThirdHyphen);
    }
  } else {
    docName = "";
  }

  const fileInputRef = useRef(null);

  const UploadDoc = () => {
    // Trigger click event on file input
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (specificData?.doc_file?.name) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setPdfFile(base64String);
      };
      reader.readAsDataURL(specificData?.doc_file);
    } else {
      if (specificData?.doc_file) {
        dispatch(
          GetSignedUrlByKey({ url: specificData?.doc_file }, "admin/provider")
        ).then((response) => {
          if (response.success) {
            setPdfFile(response?.data);
          }
        });
      }
    }
  }, [specificData?.doc_file]);

  const deleteDoc = (item) => {
    setDeleteData({ document_id: item?.document_id });
    setDeleteDiolog(true);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = UrlPdf;
    link.download = "image.jpg";
    link.click();
  };
  const handlePrint = () => {
    const printWindow = window.open("", "", "width=600,height=600");
    printWindow.document.open();
    printWindow.document.write(`<img src="${UrlPdf}" alt="Image" />`);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };
  const [zoom, setZoom] = useState(100);

  const handleZoomOut = () => {
    setZoom(zoom + 10);
  };

  const handleZoomIn = () => {
    if (zoom > 1) {
      setZoom(zoom - 10);
    }
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setDragging(false);
    setStartPosition(null);
  };
  const handleMouseMove = (e) => {
    if (dragging && startPosition) {
      const deltaX = e.clientX - startPosition.x;
      const deltaY = e.clientY - startPosition.y;
      setPosition({
        x: position.x + deltaX,
        y: position.y + deltaY,
      });
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  fileExtension = pdfFile?.split(".").pop().toLowerCase();

  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className={"w-8/5"}>
                <span
                  className="flex items-center gap-1 "
                  onClick={() => props.handleSort("doc_name")}
                >
                  Document name
                  <svg
                    className={`transform ${
                      props.getAllinvitedData.sort !== "" &&
                      props.getAllinvitedData.sort === "doc_name" &&
                      props.getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th>Document Type</Th>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => props.handleSort("expiry_date")}
                >
                  Expiry date
                  <svg
                    className={`transform ${
                      props.getAllinvitedData.sort !== "" &&
                      props.getAllinvitedData.sort === "expiry_date" &&
                      props.getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>

              <Th className="w-12"> </Th>
            </Tr>
          </THead>

          <TBody>
            {getData?.map((items, index) => {
              return (
                <React.Fragment>
                  <Tr key={index}>
                    <Td>
                      <Button
                        onClick={() => openDialog(items)}
                        className={"bg-gray-50 rounded-full h-6 p-0.5 mr-2"}
                      >
                        {items?.doc_name}
                      </Button>
                    </Td>
                    <Td>{items?.doc_type_name}</Td>

                    <Td>
                      {items.expiry_date !== null
                        ? moment(items.expiry_date).format("DD/MM/YYYY")
                        : "-"}
                    </Td>
                    <Td>
                      <Button onClick={() => deleteDoc(items)}>
                        <svg
                          width="19"
                          height="20"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                            stroke="#667085"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </Button>
                    </Td>
                  </Tr>
                </React.Fragment>
              );
            })}
          </TBody>
        </Table>
      </div>

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="documentDialog"
      >
        <div className="flex h-full">
          <div className="left-block-wrap h-full w-1/4">
            <Tabs defaultValue="tab1" variant="pill">
              <TabList
                className="bg-gray-500 rounded-lg p-1"
                onClick={(val) => handleTabClick(val)}
              >
                <TabNav value="tab1">Details</TabNav>
                <TabNav value="tab2">History</TabNav>
              </TabList>
              <div className="h-cal-full ">
                <Scrollbar>
                  <div className="">
                    <TabContent value="tab1">
                      <div className="mb-4">
                        <div className="font-bold text-white text-xl mb-1 flex justify-between">
                          <span></span>
                          {/* {specificData?.doc_name} */}
                          <div className="mb-4">
                            {checkEdit ? (
                              <ClientInput
                                name={"doc_name"}
                                inputchangehandler={handleEditForm}
                                value={specificData?.doc_name}
                                className="mt-2 w-full h-10 select-options"
                              />
                            ) : (
                              <p className="text-white font-semibold">
                                {specificData?.doc_name}
                              </p>
                            )}
                          </div>
                          <Button
                            onClick={() => SetCheckEdit(true)}
                            className="ml-auto"
                          >
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                                stroke="#fff"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Button>
                        </div>
                        {specificData && (
                          <label className="text-gray-100">
                            {moment(specificData?.created_date).format(
                              "DD/MM/YYYY"
                            )}{" "}
                            at{" "}
                            {moment(specificData?.created_date).format("HH:mm")}
                          </label>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="text-gray-100 text-12 font-medium">
                          Document type
                        </label>
                        <p className="text-white font-semibold">
                          {specificData?.doc_type_name}
                        </p>
                      </div>

                      {checkAllData?.req_issuer && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Issuing authority
                          </label>
                          {checkEdit ? (
                            <ClientInput
                              name={"issuing_authority"}
                              inputchangehandler={handleEditForm}
                              value={specificData?.issuing_authority}
                              className="mt-2 w-full h-10 select-options"
                            />
                          ) : (
                            <p className="text-white font-semibold">
                              {specificData?.issuing_authority}
                            </p>
                          )}
                        </div>
                      )}

                      {/* <div className="mb-4">
                        <label className="text-gray-100 text-12 font-medium">
                          Issue date
                        </label>
                        {checkEdit ? (
                          <ClientInput
                            type="date"
                            name={"expiry_date"}
                            inputchangehandler={handleEditForm}
                            value={moment(specificData?.issue_date).format(
                              "DD/MM/YYYY"
                            )}
                            className="mt-2 w-full h-10 select-options"
                          />
                        ) : (
                          <p className="text-white font-semibold">
                            {moment(specificData?.expiry_date).format(
                              "DD/MM/YYYY"
                            )}
                          </p>
                        )}
                      </div> */}

                      <div className="mb-4">
                        <label className="text-gray-100 text-12 font-medium">
                          Clients
                        </label>
                        <p className="text-white font-semibold">
                          {clientOrgData
                            ?.map((innerData) => innerData?.trading_name)
                            .join(", ")}
                        </p>
                      </div>
                      {checkAllData?.req_policy_no && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Policy number
                          </label>
                          {checkEdit ? (
                            <ClientInput
                              name={"policy_no"}
                              inputchangehandler={handleEditForm}
                              value={specificData?.policy_no}
                              className="mt-2 w-full h-10 select-options"
                            />
                          ) : (
                            <p className="text-white font-semibold">
                              {specificData?.policy_no}
                            </p>
                          )}
                        </div>
                      )}
                      {checkAllData?.req_amount_insured && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Amount insured
                          </label>
                          {checkEdit ? (
                            <ClientInput
                              type="number"
                              name={"insured_amount"}
                              inputchangehandler={handleEditForm}
                              value={specificData?.insured_amount}
                              className="mt-2 w-full h-10 select-options"
                            />
                          ) : (
                            <p className="text-white font-semibold">
                              ${specificData?.insured_amount}
                            </p>
                          )}
                        </div>
                      )}
                      {checkAllData?.allow_expiry && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Expiry date
                          </label>
                          {checkEdit ? (
                            <ClientInput
                              name={"expiry_date"}
                              type="date"
                              inputchangehandler={handleEditForm}
                              value={moment(specificData?.expiry_date).format(
                                "YYYY-MM-DD"
                              )}
                              // value={moment(specificData?.expiry_date).format("YYYY-MM-DD")}
                              className="mt-2 w-full h-10 select-options"
                            />
                          ) : (
                            <p className="text-white font-semibold">
                              {moment(specificData?.expiry_date).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          )}
                        </div>
                      )}
                      {checkAllData?.req_expiry_date_notes && (
                        <div className="mb-4">
                          <label className="text-gray-100 text-12 font-medium">
                            Additional info
                          </label>
                          {checkEdit ? (
                            <ClientInput
                              name={"document_desc"}
                              inputchangehandler={handleEditForm}
                              value={specificData?.document_desc}
                              className="mt-2 w-full h-10 select-options"
                            />
                          ) : (
                            <p className="text-white font-semibold">
                              {specificData?.document_desc}
                            </p>
                          )}
                        </div>
                      )}
                      {specificData?.customField &&
                        Object.keys(specificData?.customField)?.map(
                          (item, index) => {
                            return (
                              <div className="mb-4" key={index}>
                                <label className="text-gray-100 text-12 font-medium">
                                  {item}
                                </label>
                                {checkEdit ? (
                                  <ClientInput
                                    name={item} // Use 'item' as the name, not a hardcoded string
                                    inputchangehandler={(e) => {
                                      handleEditForm(e, "customField");
                                    }}
                                    value={specificData?.customField[item]} // Use square brackets to access value
                                    className="mt-2 w-full h-10 select-options"
                                  />
                                ) : (
                                  <p className="text-white font-semibold">
                                    {specificData?.customField[item]}
                                  </p>
                                )}
                              </div>
                            );
                          }
                        )}
                      {/* <hr className="border-t border-gray-400 my-5" /> */}
                      {/* <div className="font-bold text-white text-xl mb-4 ">
                        Validation
                      </div>
                      <div className="mb-4">
                        <label className="text-gray-100 text-12 font-medium mb-2 block">
                          Message
                        </label>
                        <textarea
                          id=""
                          name=""
                          className="w-full"
                          placeholder="Enter a message..."
                        ></textarea>
                        <p className="text-gray-100 text-12">
                          Provide a message about the document, if necessary.
                        </p>
                      </div> */}

                      {checkEdit && (
                        <React.Fragment>
                          <div className="mb-5">
                            <label className="font-medium text-white">
                              Document
                            </label>
                            <div>
                              {/* {sizeError && (
                            <div className="text-red-500 text-sm mt-1">
                              {sizeError}
                            </div>
                          )}
                          {checkReqiuredField &&
                            documentFormData.doc_file == "" && (
                              <div className="text-red-500 text-sm mt-1 ">
                                {error}
                              </div>
                            )} */}
                              <div className="flex items-center justify-between">
                                {!specificData?.doc_file ? (
                                  <Upload
                                    uploadLimit={1}
                                    onChange={(e) =>
                                      handleEditForm(e, "emptyDoc_file")
                                    }
                                    accept={".jpg, .jpeg, .png, .pdf,"}
                                    draggable
                                    className="w-full bg-white mt-2"
                                    // onFileRemove={handleForm}
                                  >
                                    <div className="text-center">
                                      <div className="flex items-center justify-center flex-col">
                                        <span className="flex w-9 h-9 items-center justify-center rounded-full bg-gray-200 outline outline-8 outline-gray-50 outline-offset-0">
                                          <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                                              stroke="#475467"
                                              strokeWidth="1.667"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                          </svg>
                                        </span>
                                        <span className="pt-4">
                                          <span className="text-blue-700 font-semibold mr-1">
                                            Click to upload
                                          </span>{" "}
                                          <span className="text-xs text-gray-600">
                                            {" "}
                                            or drag and drop (Max 2MB. Only PDF
                                            accepted)
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </Upload>
                                ) : (
                                  <React.Fragment>
                                    <div className="p-4 rounded-xl border border-gray-300 bg-white w-full flex items-center justify-between">
                                      <div className="flex  gap-4 items-center">
                                        <span className="w-9 h-9 rounded-full border-4 border-blue-50 bg-blue-200 flex justify-center items-center">
                                          <img
                                            src="/assets/icons-svg/upload-pdf.svg"
                                            alt=""
                                          />
                                        </span>
                                        <div className="flex flex-col items-center">
                                          <span className="text-gray-700 text-xs font-medium">
                                            {docName}
                                          </span>
                                        </div>
                                      </div>
                                      {/* <button>
                                        <svg
                                          width="20"
                                          height="19"
                                          viewBox="0 0 24 25"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                            stroke="#344054"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button> */}
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                          {checkEdit && (
                            <div className="flex justify-end gap-2">
                              {/* <Button
                                onClick={() => {
                                  SetCheckEdit(false);
                                }}
                                className="text-error-700 font-semibold bg-white h-10 w-20 rounded-lg"
                              >
                                Cancel
                              </Button> */}
                              {/* <Button
                                onClick={() => {
                                  updatData();
                                }}
                                className="text-success-700 font-semibold bg-success-50 h-10 w-20 rounded-lg"
                              >
                                Update
                              </Button> */}
                            </div>
                          )}
                          <div className="flex justify-end gap-2">
                            <Button
                              onClick={() => {
                                SetCheckEdit(false);
                              }}
                              className="text-error-700 font-semibold bg-white h-10 w-20 rounded-lg"
                            >
                              Cancel
                            </Button>
                            <input
                              type="file"
                              accept={".pdf"}
                              ref={fileInputRef}
                              className="hidden"
                              onChange={(e) => handleEditForm(e, "doc_file")}
                            />
                            <Button
                              className="text-blue-700 font-semibold bg-blue-100 h-10 w-40 rounded-lg"
                              onClick={UploadDoc}
                            >
                              Reupload
                            </Button>
                            <Button
                              className="text-white font-semibold bg-blue-700 h-10 w-40 rounded-lg"
                              onClick={() => {
                                updatData();
                              }}
                            >
                              Save Document
                            </Button>
                          </div>
                        </React.Fragment>
                      )}
                    </TabContent>
                    <TabContent value="tab2">
                      {GettAllFunctions?.DocumentHistoryData?.data?.data?.map(
                        (item, index) =>
                          item.desc_html.map((innerItem, index) => {
                            let documetnName;
                            if (item?.new_value.doc_file) {
                              documetnName =
                                item?.new_value.doc_file.split("-");
                              const partAfterThirdHyphen = documetnName
                                .slice(4)
                                .join("-");
                              // Decode the URL component to get the actual data
                              documetnName =
                                decodeURIComponent(partAfterThirdHyphen);
                            } else {
                              documetnName = "";
                            }
                            let parser = new DOMParser();
                            let doc = parser.parseFromString(
                              innerItem,
                              "text/html"
                            );
                            return (
                              <React.Fragment>
                                {item?.column_names?.includes("doc_file") ? (
                                  <div className="mb-4  pb-4">
                                    <label className="text-gray-100">
                                      {moment(item?.updated_date).format(
                                        "DD MMMM YYYY [at] HH:mm"
                                      )}
                                    </label>
                                    <p className="font-semibold text-white mt-2">
                                      {doc.body.textContent}
                                    </p>
                                    <div className=" p-4 rounded-lg bg-white font-medium mt-2 flex">
                                      <span className="w-9 h-9 rounded-full bg-blue-50 border-4 border-blue-100 flex justify-center items-center">
                                        <svg
                                          width="16"
                                          height="17"
                                          viewBox="0 0 16 17"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M10.8 14.692H4.62c-.403 0-.605 0-.698-.08a.333.333 0 0 1-.116-.28c.01-.122.152-.264.438-.55l5.668-5.669c.264-.264.396-.396.549-.445a.667.667 0 0 1 .412 0c.152.05.284.181.548.445L14 10.693v.8m-3.2 3.2c1.12 0 1.68 0 2.108-.219a2 2 0 0 0 .874-.874c.218-.428.218-.987.218-2.108m-3.2 3.2H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 13.172 2 12.613 2 11.492v-5.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v5.6M7 6.36a1.333 1.333 0 1 1-2.667 0A1.333 1.333 0 0 1 7 6.36z"
                                            stroke="#5786CC"
                                            strokeWidth="1.333"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </span>
                                      <div className="ml-3">
                                        <span className="font-medium text-gray-700 text-xs">
                                          {documetnName}
                                        </span>
                                        <p className="text-gray-600 text-xs">
                                          {(
                                            item?.new_value?.file_size / 1024
                                          ).toFixed(2)}{" "}
                                          KB
                                        </p>
                                      </div>
                                      <Button className="ml-auto">
                                        <a
                                          href={item?.new_value.doc_file}
                                          download
                                        >
                                          <svg
                                            width="20"
                                            height="21"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M17.5 8.458v-5m0 0h-5m5 0-6.667 6.667m-2.5-5H6.5c-1.4 0-2.1 0-2.635.272A2.5 2.5 0 0 0 2.772 6.49C2.5 7.024 2.5 7.725 2.5 9.125v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h5.333c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.093-1.093c.272-.535.272-1.235.272-2.635v-1.833"
                                              stroke="#475467"
                                              strokeWidth="1.667"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </a>
                                      </Button>
                                    </div>
                                  </div>
                                ) : item?.column_names?.includes("message") ? (
                                  <div className="mb-4  pb-4">
                                    <label className="text-gray-100">
                                      {moment(item?.updated_date).format(
                                        "DD MMMM YYYY [at] HH:mm"
                                      )}
                                    </label>
                                    <p className="font-semibold text-white mt-2">
                                      {doc.body.textContent}
                                    </p>
                                    {item.new_value.message && (
                                      <div className=" p-4 rounded-lg bg-white font-medium mt-2 flex">
                                        {/* <span className="w-9 h-9 rounded-full bg-blue-50 border-4 border-blue-100 flex justify-center items-center">
                                      </span> */}
                                        <div className="ml-3">
                                          <span className="font-medium text-gray-700 text-xs">
                                            {item.new_value.message}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    key={index}
                                    className="mb-4 border-b border-gray-500 pb-4"
                                  >
                                    <label className="text-gray-100">
                                      {moment(item?.updated_date).format(
                                        "DD MMMM YYYY [at] HH:mm"
                                      )}
                                    </label>
                                    <p className="font-semibold text-white mt-2">
                                      {doc.body.textContent}
                                    </p>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })
                      )}
                      {/* <div className="mb-4 border-b border-gray-500 pb-4">
                        <label className="text-gray-100">
                          26 April 2023 at 15:23
                        </label>
                        <p className="font-semibold text-white mt-2">
                          [Worker Name] has edited Amount insured
                        </p>
                        <div className=" p-4 rounded-lg bg-white font-medium mt-2">
                          Hi. The document was rejected because the insurance
                          amount does not meet Client’s requirements. Please
                          re-upload.
                        </div>
                      </div>
                      <div className="mb-4  pb-4">
                        <label className="text-gray-100">
                          26 April 2023 at 15:23
                        </label>
                        <p className="font-semibold text-white mt-2">
                          [Worker Name] has edited Amount insured
                        </p>
                        <div className=" p-4 rounded-lg bg-white font-medium mt-2 flex">
                          <span className="w-9 h-9 rounded-full bg-blue-50 border-4 border-blue-100 flex justify-center items-center">
                            <svg
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.8 14.692H4.62c-.403 0-.605 0-.698-.08a.333.333 0 0 1-.116-.28c.01-.122.152-.264.438-.55l5.668-5.669c.264-.264.396-.396.549-.445a.667.667 0 0 1 .412 0c.152.05.284.181.548.445L14 10.693v.8m-3.2 3.2c1.12 0 1.68 0 2.108-.219a2 2 0 0 0 .874-.874c.218-.428.218-.987.218-2.108m-3.2 3.2H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 13.172 2 12.613 2 11.492v-5.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v5.6M7 6.36a1.333 1.333 0 1 1-2.667 0A1.333 1.333 0 0 1 7 6.36z"
                                stroke="#5786CC"
                                strokeWidth="1.333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <div className="ml-3">
                            <span className="font-medium text-gray-700 text-xs">
                              document name.[format]
                            </span>
                            <p className="text-gray-600 text-xs">200 KB</p>
                          </div>
                          <Button className="ml-auto">
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.5 8.458v-5m0 0h-5m5 0-6.667 6.667m-2.5-5H6.5c-1.4 0-2.1 0-2.635.272A2.5 2.5 0 0 0 2.772 6.49C2.5 7.024 2.5 7.725 2.5 9.125v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h5.333c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.093-1.093c.272-.535.272-1.235.272-2.635v-1.833"
                                stroke="#475467"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Button>
                        </div>
                      </div> */}
                    </TabContent>
                  </div>
                </Scrollbar>
              </div>
            </Tabs>
          </div>
          <div className="right-block-wrap h-full w-full">
            {/* <div className="doc-header flex items-center justify-center">
              <div className="flex justify-content-center">
                <Button className="bg-white h-10 w-11 text-xl bl-rds">-</Button>
                <span className="font-semibold text-12 text-gray-700 bg-white w-16 flex items-center justify-center text-center h-10 border-r border-l">
                  100%
                </span>
                <Button className="bg-white h-10 w-11 text-xl br-rds">+</Button>
              </div>
              <div className="flex gap-6 rightSidebtn">
                <Button className="flex text-white gap-2">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 13v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C15.6 18 14.9 18 13.5 18h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 16.1 2.5 15.4 2.5 14v-1m11.667-4.167L10 13m0 0L5.833 8.833M10 13V3"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Download
                </Button>
                <Button>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 6.333v-1.5c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 3.433 5 3.899 5 4.833v1.5m0 9.166c-.775 0-1.163 0-1.48-.085a2.5 2.5 0 0 1-1.768-1.768c-.085-.318-.085-.705-.085-1.48v-1.833c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.534-.272 1.234-.272 2.635-.272h8.666c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.093 1.093c.272.534.272 1.235.272 2.635v1.833c0 .775 0 1.162-.085 1.48a2.5 2.5 0 0 1-1.768 1.768c-.318.085-.705.085-1.48.085m-2.5-6.25H15m-7.333 9.584h4.666c.934 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.824.182-1.757v-1.333c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 13.433 5 13.899 5 14.833v1.333c0 .933 0 1.4.181 1.757.16.313.415.568.729.728.356.182.823.182 1.756.182z"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
                <Button>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#zvmr0yey3a)">
                      <path
                        d="m10.835 18.333-2.5-2.5m0 0 2.5-2.5m-2.5 2.5H12.5a5.833 5.833 0 0 0 2.5-11.105m-10 10.544a5.834 5.834 0 0 1 2.5-11.105h4.167m0 0-2.5-2.5m2.5 2.5-2.5 2.5"
                        stroke="#fff"
                        strokeWidth="1.667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="zvmr0yey3a">
                        <path fill="#fff" d="M0 0h20v20H0z" />
                      </clipPath>
                    </defs>
                  </svg>
                </Button>

                <Button>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.588 15.303 9.41 16.482a4.167 4.167 0 1 1-5.893-5.893l1.179-1.178m10.606 1.178 1.179-1.178a4.167 4.167 0 1 0-5.893-5.893L9.41 4.697m-2.328 8.22 5.834-5.834"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </div>
            </div> */}
            <div className="right-block-wrap h-full w-full">
              <div className="doc-view pdf_view_scroll">
                {pdfFile?.split(".").pop().toLowerCase() == "pdf" ? (
                  specificData &&
                  specificData?.doc_file &&
                  pdfFile && (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={pdfFile}
                        plugins={[defaultLayoutPluginInstance]}
                        theme="dark"
                      />
                    </Worker>
                  )
                ) : (
                  <React.Fragment>
                    <div className="iFrame-Header image_viewer">
                      <div className="text-white text-xs">
                        {/* {moduleData?.[indexData]?.company_ind_file?.name} */}
                      </div>
                      <div className="btn-group">
                        <button
                          onClick={() => handleZoomIn()}
                          className="bg-white"
                        >
                          -
                        </button>
                        <button
                          onClick={() => handleZoomOut()}
                          className="bg-white"
                        >
                          +
                        </button>
                      </div>
                      <div className="flex gap-3">
                        <button onClick={() => handleDownload()}>
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.5 13v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C15.6 18 14.9 18 13.5 18h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 16.1 2.5 15.4 2.5 14v-1m11.667-4.167L10 13m0 0L5.833 8.833M10 13V3"
                              stroke="#fff"
                              strokeWidth="1.667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <button onClick={() => handlePrint()}>
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 6.333v-1.5c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 3.433 5 3.899 5 4.833v1.5m0 9.166c-.775 0-1.163 0-1.48-.085a2.5 2.5 0 0 1-1.768-1.768c-.085-.318-.085-.705-.085-1.48v-1.833c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.534-.272 1.234-.272 2.635-.272h8.666c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.093 1.093c.272.534.272 1.235.272 2.635v1.833c0 .775 0 1.162-.085 1.48a2.5 2.5 0 0 1-1.768 1.768c-.318.085-.705.085-1.48.085m-2.5-6.25H15m-7.333 9.584h4.666c.934 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.824.182-1.757v-1.333c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 13.433 5 13.899 5 14.833v1.333c0 .933 0 1.4.181 1.757.16.313.415.568.729.728.356.182.823.182 1.756.182z"
                              stroke="#fff"
                              strokeWidth="1.667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div
                      className="image-container"
                      onMouseDown={handleMouseDown}
                      onMouseUp={handleMouseUp}
                      onMouseMove={handleMouseMove}
                    >
                      <img
                        style={{
                          scale: `${zoom}% `,
                          transform: `translate(${position.x}px, ${position.y}px)`,
                        }}
                        src={pdfFile}
                        alt="Image"
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
            {/* <div className="doc-view pdf_view_scroll">
              {specificData && specificData?.doc_file && pdfFile && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={pdfFile}
                    plugins={[defaultLayoutPluginInstance]}
                    theme="dark"
                  />
                </Worker>
              )}
            </div> */}
          </div>
        </div>
      </Dialog>

      <Dialog
        isOpen={deleteDiolog}
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            Are you sure you want to delete document?
          </p>
        </div>

        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight className="mr-1.5" onClick={() => setDeleteDiolog(false)}>
            Cancel
          </BtnLight>
          <BtnPrimary
            className="ml-1.5"
            onClick={() => deleteModuleConfirmation()}
          >
            Delete
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default DocumentsList;
