import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import { notify } from "react-notify-toast";
import Dialog from "../../../../../../common/element/Dialog";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Label from "../../../../../../common/element/Label";
import RichTextEditor from "../../../../../../common/snippet/RichTextEditor";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import * as userActions from "../../../../../../actions/index";
import emailIcon from "../../../../../../assets/icons-svg/email.svg";
import Validations from "../../../../../../common/404/Validations";
import Upload from "../../../../../../common/element/Upload";
import trashIcon from "../../../../../../assets/icons-svg/trash.svg";
import PdfFile from "../../../../../../assets/icons-svg/upload-pdf.svg";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";

const EditClientContractorEmail = (props) => {
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [dialogIsOpen, setIsOpen] = useState(true);
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [content, setContent] = useState("");
  const [template_type, setTemplate_type] = useState("");

  const [attachment, setAttachment] = useState({
    files: [], // Initialize 'files' as an empty array
  });
  const [uploadedFiles, setUploadedFiles] = useState({ files: [] });
  const [nameError, setNameError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = (value) => {
    setContent(value);
    setMessageError("")
  }

  const dismissError = () => {
    setMessageError("")
    setSubjectError("")
    setNameError("")
  }

  const onDialogClose = () => {
    if (!isSaving) {
      setIsOpen(false);
      dismissError()
      props?.setOpenPopup(!dialogIsOpen);
    }
  };
  const onDialogOk = () => {
    setIsOpen(false);
  };
  const GetEmailTemplateById = () => {
    props?.GetEmailTemplateById(props?.email_id).then((response) => {
      if (response?.success === true) {
        const res_data = response?.data;
        const fileData = [];

        res_data?.email_temp_attach.forEach((data) => {
          const file = data?.email_temp_doc;
          const attachment_id = data?.email_temp_attachment_id;
          const segments = file.split("/");

          // Get the last segment, which should be the file name
          const fileNameWithQueryParams = segments[segments.length - 1];

          // Decode the percent-encoded characters
          const decodedFileName = decodeURIComponent(fileNameWithQueryParams);

          fileData.push({
            email_temp_attachment_id: attachment_id,
            file: file,
            fileName: decodedFileName,
          });
        });
        setTemplateName(res_data?.template_name);
        setTemplateSubject(res_data?.template_subject);
        setContent(res_data?.template_content);
        setUploadedFiles((prevAttachment) => ({
          ...prevAttachment,
          files: fileData,
        }));
        setAttachment((prevAttachment) => ({
          ...prevAttachment,
          files: fileData,
        }));
        setTemplate_type(res_data?.template_type);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
      } else {
      }
    });
    // eslint-disable-next-line
  };
  useEffect(() => {
    GetEmailTemplateById();
  }, []);

  const inputChangeHandler = (e, identifier) => {
    if (identifier === "templateName") {
      setTemplateName(e.target.value);

    }
    if (identifier === "templateSubject") {
      setTemplateSubject(e.target.value);
    }
  };
  // function to remove attached file
  const removeFile = (item) => {
    if (item?.email_temp_attachment_id) {
      const payload = {
        email_temp_attachment_id: item?.email_temp_attachment_id,
        email_temp_doc: item?.file,
      };

      props?.onDeleteEmailTempAttachmentById(payload).then((response) => {
        if (response?.success === true) {
          GetEmailTemplateById();
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        } else {
        }
      });
    } else {
      setAttachment((prevAttachment) => ({
        ...prevAttachment,
        files: prevAttachment.files.filter((files) => files.file !== item?.file),
      }));
    }
  };

  // to calculate the size of attached files
  const calculateTotalAttachmentSize = () => {
    let totalSize = 0;
    attachment?.files?.forEach((item) => {
      totalSize += item?.size;
    });
    return totalSize;
  };

  const submithandler = (e) => {
    e.preventDefault();
    const tempNameValidation = Validations({
      value: templateName,
      Validations: { required: true },
    });
    const tempSubValidation = Validations({
      value: templateSubject,
      Validations: { required: true },
    });
    const tempContentValidation = Validations({
      value: content == "<p><br></p>" || "" ? "" : content,
      Validations: { required: true },
    });
    if (!tempNameValidation.valid) {
      setNameError(tempNameValidation.error_msg);
    }
    if (!tempSubValidation.valid) {
      setSubjectError(tempSubValidation.error_msg);
    }
    if (!tempContentValidation.valid) {
      setMessageError(tempContentValidation.error_msg);
    }

    const totalAttachmentSize = calculateTotalAttachmentSize();
    if (totalAttachmentSize > 25 * 1024 * 1024) {

      return;
    }

    const files = attachment.files;
    if (
      tempNameValidation.valid === true &&
      tempSubValidation.valid === true &&
      tempContentValidation.valid === true
    ) {
      const payload = {
        email_template_id: props?.email_id,
        template_name: templateName,
        template_subject: templateSubject,
        template_content: content,
        template_type: template_type,
        email_temp_doc: files, // Array to hold file objects
      };
      setTemplateName("");
      setIsSaving(true);
      props?.onUpdateEmailTemplate(payload).then((response) => {
        if (response?.success === true) {
          props?.GetAllEmailTemplatesByType()
          onDialogOk();
          setNameError("");
          setTimeout(() => {
            setIsSaving(false);
          }, 100);
          setSubjectError("");
          setMessageError("");
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        } else {
          setIsSaving(false);
        }
      })
        .catch((error) => {
          setIsSaving(false);
        });
    }
  };

  const handleFileChange = (files) => {
    setAttachment({ files });
  };

  const handleFileRemove = (files) => {
    // Handle the file remove logic here
    setAttachment({ files });
  };

  return (
    <React.Fragment>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
      >
        <div className="flex border-b pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={emailIcon} alt=""></img>
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Edit email template
            </h5>
            <p className="text-gray-600">
              Compose and send an email directly to user
            </p>
          </div>
        </div>
        <form>
          <div className="p-6">
            <div className="mb-4 flex flex-col">
              <Label className="font-medium text-xs mb-2">Template name*</Label>
              <input
                className="h-9 input px-3 input-shadow text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                // placeholder="Enter document name here"
                value={templateName ? templateName : ""}
                onChange={(e) => { inputChangeHandler(e, "templateName"); setNameError("") }}
              />
              {nameError && (
                <div className="text-red-500 text-sm mt-1">{nameError}</div>
              )}
            </div>
            <div className="mb-4 flex flex-col">
              <Label className="font-medium text-xs mb-1.5">Subject*</Label>
              <input
                className="h-9 input px-3 input-shadow text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                // placeholder="Enter document name here"
                value={templateSubject}
                onChange={(e) => { inputChangeHandler(e, "templateSubject"); setSubjectError("") }}
              />
              {subjectError && (
                <div className="text-red-500 text-sm mt-1">{subjectError}</div>
              )}
            </div>
            <div className="flex flex-col">
              <div>
                <Label className="font-medium text-xs mb-1.5">Message*</Label>
                <RichTextEditor
                  onChange={(e) => handleChange(e)}
                  value={content}
                />
                {messageError && (
                  <div className="text-red-500 text-sm mt-1">
                    {messageError}
                  </div>
                )}
              </div>
              <div className="mb-5">
                <Label className="text-xs font-medium block mb-1 mt-3">
                  Attachment (optional)
                </Label>
                <Upload
                  className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                  onChange={handleFileChange}
                  onFileRemove={handleFileRemove}
                  multiple={true} // Allow multiple file uploads
                  maxFileSize={25 * 1024 * 1024} // Max file size in bytes (25MB)
                >
                  <span className="text-blue-700 font-semibold">
                    Click to upload
                  </span>{" "}
                  {/* <span className="font-medium text-gray-600">
                          or drag and drop
                        </span> */}
                  <p className="text-gray-600">(max.25mb)</p>
                </Upload>
              </div>

              {uploadedFiles.files &&
                uploadedFiles.files.map((item, index) => (
                  <div
                    key={index}
                    className="upload-file flex items-center justify-between mt-2"
                  >
                    <div className="upload-file-left flex items-center">
                      <div className="upload-file-thumbnail">
                        <span className="w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3">
                          <img src={PdfFile} alt="" />
                        </span>
                      </div>
                      <a
                        href={item?.file}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="upload-file-name font-medium text-xs text-gray-700">
                          {item?.fileName}
                        </span>
                      </a>
                    </div>
                    <span
                      className="upload-file-remove w-5"
                      onClick={() => removeFile(item)}
                    >
                      <span className="">
                        <img src={trashIcon} alt="" />
                      </span>
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </form>
        <div className="flex dailog-footer mt-0 justify-end gap-4">
          <BtnLight
            className="w-auto px-3"
            variant="plain"
            onClick={onDialogClose}
            width={900}
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            className="w-auto px-3"
            variant="solid"
            onClick={submithandler}
            // onClick={onDialogOk}
            disabled={isSaving}
          >
            {isSaving ? "Saving..." : "Save template"}
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    loader: userActions.CreateEmailTemplate.loader,
  };
}
const mapDispatchToProps = {
  onUpdateEmailTemplate: userActions.UpdateEmailTemplate,
  GetEmailTemplateById: userActions.GetEmailTemplateById,
  onDeleteEmailTempAttachmentById: userActions.DeleteEmailTempAttachmentById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditClientContractorEmail);
