import React from 'react'
const Input = (props) => {
    const  classes = "input px-3 input-shadow text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline' " + props.className;
    const handleKeyDown = (e) => {
        if (props.type === 'number' && ["e", "E", "+", "-"].includes(e.key)) {
            e.preventDefault();
        }
    };
    return (
        <input  className={classes} placeholder = {props.placeholder || " "} 
        onChange={(e) => props?.inputchangehandler(e?.target?.value)}
        value={props?.value}
        readOnly={props?.readOnly}
        disabled={props?.disabled}
        onBlur={props?.onBlur}
        maxLength={props?.maxLength}
        type={props?.type}
        onKeyDown={handleKeyDown}
        />
    )
}
export default Input