import Card from "../../../../../../common/element/Card";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import Table from "../../../../../../common/element/Table";
import DoughnutChart from "../../../../../../common/chart/Doughnut";
import { GetAllClientsOfProvider } from "../../../../../../actions/admin-provider/clients/GetAllClientsOfProvider";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { decryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const TotalClient = ({updateTab}) => {
  const dispatch = useDispatch();
  const { Tr, Th, Td, THead, TBody } = Table;
  const { SpecificProviderData } = useSelector((state) => state);
  let function_assignment_id = SpecificProviderData?.provider_fa_id;
  const { id } = useParams();
  const orgId = decryptId(id);

  // FilTER DATA SHOW
  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    provider_fa_id: function_assignment_id,
    provider_org_id: orgId,
    // page: 1,
    // limit: 10,
    // sort: "created_date",
    // order: "desc",
    // search: "",
  });

  // All ReduxFunction
  useEffect(() => {
    dispatch(GetAllClientsOfProvider(getAllinvitedData));
  }, [getAllinvitedData]);

  let { AllClientsOfAdminProvider } = useSelector((state) => state);

  const statusCounts = AllClientsOfAdminProvider?.data?.data?.rows?.reduce((acc, client) => {
    // Check the `docs_status` and increment the corresponding count
    if (client.docs_status === "Non-compliant") {
      acc.nonCompliant += 1;
    } else if (client.docs_status === "Compliant") {
      acc.compliant += 1;
    } else if (client.docs_status === "invited") {
      acc.invited += 1;
    }
    return acc;
  }, { nonCompliant: 0, compliant: 0, invited: 0 });
  
  // Log the counts
  const updatTab=()=>{
    updateTab("tab3")
  }
  return (
    <Card className="py-6 border border-gray-200 rounded-xl dark-shadow">
      <div className="px-6">
        <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-3">
          <div className="flex items-center">
            <span className="mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M18.818 4.84647L18.818 4.84648C19.2151 4.91279 19.5063 5.2563 19.5063 5.65874V18.6823C19.5063 19.1371 19.1378 19.5058 18.6828 19.5058H12.8945C12.4399 19.5058 12.0711 19.1367 12.0711 18.6823C12.0711 18.2278 12.4399 17.8587 12.8945 17.8587H17.8595L17.8594 6.35648L10.8239 5.18397V18.6823C10.8239 19.1367 10.4552 19.5058 10.0004 19.5058C9.54578 19.5058 9.17711 19.1367 9.17711 18.6823V4.21176C9.17711 3.96961 9.28347 3.73994 9.46818 3.58353C9.65279 3.42699 9.89717 3.3597 10.1357 3.39948C10.1357 3.39948 10.1357 3.39948 10.1357 3.39948L18.818 4.84647Z"
                  fill="#101828"
                  stroke="#101828"
                  stroke-width="0.2"
                />
                <path
                  d="M12.0706 15.0645C12.0706 14.6095 12.4391 14.2411 12.894 14.2411H15.7882C16.2432 14.2411 16.6117 14.6095 16.6117 15.0645V18.6821C16.6117 19.1371 16.2432 19.5057 15.7882 19.5057H12.894C12.4391 19.5057 12.0706 19.1371 12.0706 18.6821V15.0645ZM13.7176 17.8586H14.9647V15.8881H13.7176V17.8586Z"
                  fill="#101828"
                  stroke="#101828"
                  stroke-width="0.2"
                />
                <path
                  d="M3.39091 15.0645C3.39091 14.6095 3.75953 14.2411 4.21446 14.2411H7.1086C7.56355 14.2411 7.93203 14.6095 7.93203 15.0645V18.6821C7.93203 19.1371 7.56357 19.5057 7.1086 19.5057H4.21446C3.75951 19.5057 3.39091 19.1371 3.39091 18.6821V15.0645ZM5.03805 17.8586H6.28504V15.8881H5.03805V17.8586Z"
                  fill="#101828"
                  stroke="#101828"
                  stroke-width="0.2"
                />
                <path
                  d="M1.45215 0.505087L1.45226 0.505104L10.1345 1.95234C10.5315 2.01852 10.8227 2.36194 10.8227 2.76461V18.6821C10.8227 19.1369 10.4543 19.5056 9.99922 19.5056H4.21095C3.75633 19.5056 3.38764 19.1364 3.38764 18.6821C3.38764 18.2278 3.75633 17.8587 4.21095 17.8587H9.17588L9.17595 3.46234L2.14038 2.28959V18.6822C2.14038 19.1365 1.7717 19.5056 1.31707 19.5056C0.862333 19.5056 0.493638 19.1365 0.493638 18.6822V1.31738C0.493638 1.07524 0.600203 0.845855 0.784713 0.689396C0.969401 0.532545 1.21377 0.465607 1.45215 0.505087Z"
                  fill="#101828"
                  stroke="#101828"
                  stroke-width="0.2"
                />
                <path
                  d="M14.9647 11.0852C14.9647 10.8302 15.1715 10.6234 15.4264 10.6234H16.1499C16.4049 10.6234 16.6117 10.8302 16.6117 11.0852V11.8087C16.6117 12.0636 16.4049 12.2705 16.1499 12.2705H15.4264C15.1715 12.2705 14.9647 12.0636 14.9647 11.8087V11.0852Z"
                  fill="#101828"
                  stroke="#101828"
                  stroke-width="0.2"
                />
                <path
                  d="M12.0702 11.0852C12.0702 10.8302 12.277 10.6234 12.532 10.6234H13.2554C13.5104 10.6234 13.7172 10.8302 13.7172 11.0852V11.8087C13.7172 12.0636 13.5104 12.2705 13.2554 12.2705H12.532C12.277 12.2705 12.0702 12.0636 12.0702 11.8087V11.0852Z"
                  fill="#101828"
                  stroke="#101828"
                  stroke-width="0.2"
                />
                <path
                  d="M12.0702 8.1909C12.0702 7.93607 12.277 7.7291 12.532 7.7291H13.2554C13.5104 7.7291 13.7172 7.93606 13.7172 8.1909V8.91445C13.7172 9.16928 13.5104 9.37625 13.2554 9.37625H12.532C12.277 9.37625 12.0702 9.16929 12.0702 8.91445V8.1909Z"
                  fill="#101828"
                  stroke="#101828"
                  stroke-width="0.2"
                />
                <path
                  d="M14.9647 8.1909C14.9647 7.93609 15.1715 7.7291 15.4264 7.7291H16.1499C16.4049 7.7291 16.6117 7.93606 16.6117 8.1909V8.91445C16.6117 9.16928 16.4049 9.37625 16.1499 9.37625H15.4264C15.1715 9.37625 14.9647 9.16927 14.9647 8.91445V8.1909Z"
                  fill="#101828"
                  stroke="#101828"
                  stroke-width="0.2"
                />
                <path
                  d="M6.28501 11.0852C6.28501 10.8302 6.49182 10.6234 6.7468 10.6234H7.47036C7.72523 10.6234 7.93203 10.8302 7.93203 11.0852V11.8087C7.93203 12.0635 7.72526 12.2705 7.47036 12.2705H6.7468C6.49182 12.2705 6.28501 12.0636 6.28501 11.8087V11.0852Z"
                  fill="#101828"
                  stroke="#101828"
                  stroke-width="0.2"
                />
                <path
                  d="M3.39036 11.0852C3.39036 10.8302 3.59717 10.6234 3.85215 10.6234H4.57571C4.83053 10.6234 5.0375 10.8302 5.0375 11.0852V11.8087C5.0375 12.0636 4.83057 12.2705 4.57571 12.2705H3.85215C3.59716 12.2705 3.39036 12.0636 3.39036 11.8087V11.0852Z"
                  fill="#101828"
                  stroke="#101828"
                  stroke-width="0.2"
                />
                <path
                  d="M3.39036 8.1909C3.39036 7.93607 3.59715 7.7291 3.85215 7.7291H4.57571C4.83055 7.7291 5.0375 7.93603 5.0375 8.1909V8.91445C5.0375 9.1693 4.83057 9.37625 4.57571 9.37625H3.85215C3.59716 9.37625 3.39036 9.16929 3.39036 8.91445V8.1909Z"
                  fill="#101828"
                  stroke="#101828"
                  stroke-width="0.2"
                />
                <path
                  d="M6.28501 8.1909C6.28501 7.93607 6.4918 7.7291 6.7468 7.7291H7.47036C7.72525 7.7291 7.93203 7.93608 7.93203 8.1909V8.91445C7.93203 9.16925 7.72526 9.37625 7.47036 9.37625H6.7468C6.49182 9.37625 6.28501 9.16929 6.28501 8.91445V8.1909Z"
                  fill="#101828"
                  stroke="#101828"
                  stroke-width="0.2"
                />
                <path
                  d="M6.28501 5.29685C6.28501 5.04203 6.4918 4.83506 6.7468 4.83506H7.47036C7.72525 4.83506 7.93203 5.04203 7.93203 5.29685V6.02041C7.93203 6.27535 7.72524 6.4822 7.47036 6.4822H6.7468C6.49184 6.4822 6.28501 6.27539 6.28501 6.02041V5.29685Z"
                  fill="#101828"
                  stroke="#101828"
                  stroke-width="0.2"
                />
                <path
                  d="M3.39036 5.29685C3.39036 5.04203 3.59715 4.83506 3.85215 4.83506H4.57571C4.83055 4.83506 5.0375 5.04199 5.0375 5.29685V6.02041C5.0375 6.2754 4.83055 6.4822 4.57571 6.4822H3.85215C3.59718 6.4822 3.39036 6.27539 3.39036 6.02041V5.29685Z"
                  fill="#101828"
                  stroke="#101828"
                  stroke-width="0.2"
                />
              </svg>
            </span>
            <p></p>
            <div className="flex flex-col">
              <span className="text-gray-900 text-base font-semibold">
                Total clients
              </span>
            </div>
          </div>
          <BtnLight className="w-20 h-9" onClick={updatTab}>View All</BtnLight>
        </div>
      </div>
      <div className="px-6">
        <DoughnutChart
          labels={["Compliant", "Non-Compliant", "Invited"]}
          type={"Clients"}
          chartData={[statusCounts?.compliant||0, statusCounts?.nonCompliant||0, statusCounts?.invited||0]}
        />
      </div>
    </Card>
  );
};
export default TotalClient;
