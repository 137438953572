import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../../../../../actions/index";
import { debounce } from "../../../../../common/snippet/Debouncing";
import ReactPaginate from "react-paginate";
import ContactsList from "./ContactsList";
import AddContactForm from "./AddContact";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import plusImg from "../../../../../assets/icons-svg/plus.svg";
import searchIcon from "../../../../../assets/icons-svg/search-lg.svg";
import UserPlusImg from "../../../../../assets/icons-svg/user-plus-01.svg";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import { useParams, useLocation, useNavigate } from "react-router-dom"
import Heading from "../../../../../common/element/Heading";
import { GetAllClientContacts } from "../../../../../actions/client-portal/AllContactsApi";
import { GetClientUserPermByIdForClient } from '../../../../../actions/client-portal/AllUsersApi';
import Email from "../Email/Email";
import EmailIcon from "../../../../../assets/icons-svg/email.svg";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../common/pagination";

const Contacts = (props) => {
  const { id } = useParams();
  const navigate = useNavigate()
  const location = useLocation();
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const permissionDataUpdate = useSelector((state) => state?.permissions?.permissionData);
  const [forceRender, setForceRender] = useState(false);
  let organisation_id;
  let function_assignment_id;
  let user_id;
  const [countData, setCountData] = useState(0);
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  useEffect(() => {
    if (permissionDataUpdate !== null) {
      localStorage.setItem("permission_data", JSON.stringify(permissionDataUpdate));
      setForceRender(prevForceRender => !prevForceRender);
      if (permissionDataUpdate?.contacts_view == false) {
        // navigate('/client/dashboard');
        navigate('/client/noaccess')
      }
    }
  }, [permissionDataUpdate]);
  let permission_data;
  const permissionData = JSON.parse(localStorage?.getItem("permission_data"));

  if (permissionData !== null) {
    permission_data = permissionData;
  }

  const contactsPermission = permission_data?.contacts_write == true ? false : true

  if (storedData !== null) {
    organisation_id = storedData.organisation_id;
    user_id = storedData?.userName?.user_id;
    function_assignment_id = storedData.function_assignment_id;
  }
  const [apiCalls, setApiCalls] = useState(true)
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [contactsData, setContactsData] = useState("");
  const [filterData, setFilterData] = useState({
    sort: 'first_name',
    order: 'desc',
    page: 1,
    limit: 10,
    search: '',
    organisation_id: organisation_id
  })
  // state to open/ close email dialog
  const [dialogEmailOpen, setEmailOpen] = useState(false);
  // to open email dialog
  const openEmailDialog = () => {
    setEmailOpen(true);
  };
  // to close email dialog
  const onEmailClose = (e) => {
    if(!buttonDisabled){
      setEmailOpen(false);
    }
  };
  // api call for permissions
  // remove this permission api call
  // useEffect(() => {
  //   const apiData = {
  //     user_id: user_id,
  //     function_assignment_id: function_assignment_id
  //   }
  //   dispatch(GetClientUserPermByIdForClient(apiData)).then(
  //     (response) => {
  //       if (response.success === true) {
  //         // const arrayData = response.data;  
  //         localStorage.setItem("permission_data", JSON.stringify(response.data))
  //       } else if (response.status === 401) {
  //         handleUnauthorizedAccess()
  //       }
  //     }
  //   );
  // }, [])

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Contacts", url: "/client/contacts", home: "/client/dashboard" },
      ])
    );
  }, []);


  const openDialog = () => {
    setIsOpen(true);
  };

  const onDialogClose = (e) => {
    if (!buttonDisabled) {
      setIsOpen(false);
    }
  };


  const handlePageChange = ({ selected }) => {
    setFilterData({
      ...filterData,
      page: selected + 1,
    });
    // GetAllClientContacts(selected + 1, filterlimit);
  };
  const handleSeachChange = (e) => {
    setFilterData({
      ...filterData,
      search: e.target.value.trim(),
      page: 1
    });
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);


  // useEffect(() => {
  //   if (filterData.organisation_id) {
  //     dispatch(GetAllClientContacts(filterData)).then(
  //       (response) => {
  //         if (response.success) {
  //           const arrayData = response.data.rows;
  //           setCountData(response.data.count)
  //           setContactsData(arrayData);
  //           setTotalPages(Math.ceil(response.data.count / filterData.limit));
  //         } else if (response.status === 401) {
  //           handleUnauthorizedAccess()
  //         }
  //       }
  //     );
  //   }
  // }, [filterData]);

  const fetchClientContacts = useCallback(() => {
    if (filterData.organisation_id) {
      dispatch(GetAllClientContacts(filterData)).then((response) => {
        if (response.success) {
          const arrayData = response.data.rows;
          setCountData(response.data.count);
          setContactsData(arrayData);
          setTotalPages(Math.ceil(response.data.count / filterData.limit));
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      });
    }
  }, [dispatch, filterData]);

  useEffect(() => {
    fetchClientContacts();
  }, [filterData]);

  const updatePageLimit = (newLimit) => {
    if (apiCalls == true) {
      setApiCalls(false)
    }
    else {
      setFilterData(prevState => ({
        ...prevState,
        limit: newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10,
      }));
    }
  };
  const handleSort = (column) => {
    if (column === filterData.sort) {
      // Toggle the sorting order if the same column is clicked
      setFilterData({
        ...filterData,
        order: filterData.order === "asc" ? "desc" : "asc",
      });
    } else {
      setFilterData({
        ...filterData,
        sort: column,
        order: "asc", // Set the default order when changing the sorting column
        page: 1, // Reset page when changing the sorting column
      });
    }
  };
  return (
    <React.Fragment>
      <div className="flex justify-between mb-5">
        <div>
          <Heading>Contacts</Heading>
          <p className="text-gray-600">
            {/* Add, manage and tailor work sites to fit the unique needs of each site */}
          </p>
        </div>
        <BtnLight disabled={contactsPermission}
          onClick={() => openEmailDialog()}
          className="w-24 text-xs text-gray-700 font-semibold flex items-center justify-center"
        >
          Email <img src={EmailIcon} alt="" className="ml-2" />
        </BtnLight>
      </div>
      <div className="flex justify-between mb-5">
        <div className="flex gap-2">
          <div className="w-80 input-wrapper">
            <div className="input-suffix-start left-3.5">
              <img src={searchIcon} alt="" className="h-5" />
            </div>
            <input
              type="text"
              name="hs-table-search"
              className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Search"
              onChange={(e) => inputchangeSearch(e)}
            />
          </div>
        </div>

        <BtnPrimary
          disabled={contactsPermission}
          variant="solid"
          onClick={() => openDialog()}
          className="w-auto h-9 flex items-center justify-center text-12 px-3.5"
          type="button"
        >
          Add Contacts{" "}
          <span class="ml-3">
            <img src={plusImg} alt="" class="h-5" />
          </span>
        </BtnPrimary>
      </div>
      <ContactsList
        fetchClientContacts={fetchClientContacts}
        contactsPermission={contactsPermission}
        contactsData={contactsData}
        setContactsData={setContactsData}
        handleSort={handleSort}
        filterData={filterData}
      />
      <div
        className="flex justify-between mt-3"
        style={{
          alignItems: "Right",
          listStyle: "none",
          padding: 0,
        }}
      >
        <Pagination
          totalCount={countData}
          pageNumber={filterData.page}
          displayPage={contactsData?.length}
          UpdatePageLimit={updatePageLimit}
          allfilters={filterData}
        />
        {countData > 0 && <ReactPaginate
          className="flex justify-content-end p-2 align-items-center pagination-wrap"
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousLinkClassName={"prev-link"}
          nextLinkClassName={"page-link"}
          breakLinkClassName={"prev-link"}
          previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
          nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
          pageLinkClassName={"px-4 rounded-md"}
          activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
          disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
          renderOnZeroPageCount={null}
          forcePage={filterData.page - 1}
        />}
      </div>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="addContact-dialog"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={UserPlusImg} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Add a Contact
            </h5>
            <p className="text-gray-600 ">
              Create a new Client into the Conserve system
            </p>
          </div>
        </div>
        <AddContactForm
          fetchClientContacts={fetchClientContacts}
          buttonDisabled={buttonDisabled}
          setButtonDisabled={setButtonDisabled}
          allContactTypes={props.allContactTypes}
          onDialogClose={onDialogClose}
          GetAllClientContacts={GetAllClientContacts}
          contactsData={contactsData}
          setContactsData={setContactsData}
          filterData={filterData}
        />
      </Dialog>
      <Dialog
        isOpen={dialogEmailOpen}
        onClose={onEmailClose}
        onRequestClose={onEmailClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={EmailIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Send an email{" "}
            </h5>
            <p className="text-gray-600 ">
              Compose and send an email directly to user
            </p>
          </div>
        </div>
        <Email onEmailClose={onEmailClose} buttonDisabled={buttonDisabled}
         setButtonDisabled={setButtonDisabled} />
      </Dialog>
    </React.Fragment>
  );
};

export default Contacts;
