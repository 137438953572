import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/client-portal/clientCsvSlice";
export const UpdateCompanyInductionStatus = (ApiData, ipAddress) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'put',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        'X-Client-IP': ipAddress, // Add the IP address here
      },
      url: `${actionType.environment_url}/api/client/UpdateCompanyInductionStatus`,
    });

    return response.data; // Return the response data
  } catch (error) {
    return error.message; // Return the error message
  }
};
