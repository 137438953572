import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../../../../common/element/Heading";
import Segment from "../../../../common/element/Segment";
import Table from "../../../../common/element/Table";
import ClientInput from "../../../../common/element/Input/clientInput";
import Badge from "../../../../common/element/Badge";
import moment from "moment";
import Tag from "../../../../common/element/Tag";
import useCallbackRef from "../../../../common/element/hooks/useCallbackRef";
import Scrollbar from "../../../../common/element/ScrollBar";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import SearchImg from "../../../../assets/icons-svg/search-lg.svg";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
import { Link } from "react-router-dom";
import Tooltip from "../../../../common/element/Tooltip";
import Inductions from "./DashboardAllClient/Indunction";
import { debounce } from "../../../../common/snippet/Debouncing";
import TotalClients from "./DashboardAllClient/TotalClient";
import {
  GetAllDeshboardTasks,
  UpdateTaskArchiveStatus,
  MarkTaskAsRead,
  GetNotificationTasksForAdminPortal,
} from "../../../../actions/Admin";
import { updateRoutefunc } from "../../../../redux/classes/common/updateRoute";
import { DashboardAllClientsCompanyInd } from "../../../../actions/Admin-Client/AllSiteApi";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";
import { useParams } from "react-router-dom";
import { decryptId } from "../../../../common/encryptDecrypt/encryptdecrypt";
const Dashboard = () => {
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  const user_id = loginData?.userName?.user_id || loginData?.data?.user_id;
  const userRole = loginData?.data?.role?.role_name;
  const { id } = useParams();
  // const orgId=decryptId(id)
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [clientDataName, setClientDataName] = useState([]);
  const [totalTastCount, setTotalTaskCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const scrollContainerRef = useRef(null);
  const [scrollThreshold, setScrollThreshold] = useState(23);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [notificationList, setNotificationList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [singleSegmentValue, setSingleSegmentValue] = useState(["Inbox"]);
  const { Tr, Th, Td, THead, TBody } = Table;
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Dashboard",
          url: "/admin/client/dashboard",
          home: "/admin/client/dashboard",
        },
      ])
    );
  }, []);

  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "day").startOf("day");
  const startOfWeek = moment().startOf("week");
  const startOfMonth = moment().startOf("month");
  const startOfYear = moment().startOf("year");

  const groupedNotifications = notificationList?.reduce(
    (acc, notification) => {
      const createdDate = moment(notification?.created_date);

      if (createdDate.isSame(today, "d")) {
        acc.today.push(notification);
      } else if (createdDate.isSame(yesterday, "d")) {
        acc.yesterday.push(notification);
      } else if (createdDate.isSameOrAfter(startOfWeek)) {
        acc.thisWeek.push(notification);
      } else if (createdDate.isSameOrAfter(startOfMonth)) {
        acc.thisMonth.push(notification);
      } else if (createdDate.isSameOrAfter(startOfYear)) {
        acc.thisYear.push(notification);
      } else {
        acc.earlier.push(notification);
      }
      return acc;
    },
    {
      today: [],
      yesterday: [],
      thisWeek: [],
      thisMonth: [],
      thisYear: [],
      earlier: [],
    }
  );

  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setSingleSegmentValue(val);
    },
    [setSingleSegmentValue]
  );
  const UpdateRoute = () => {
    dispatch(updateRoutefunc(true));
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;

      if (
        scrollContainer &&
        scrollContainer.scrollTop >= scrollThreshold &&
        !isFetching &&
        notificationList?.length < totalCount
      ) {
        setLimit((prevLimit) => prevLimit + 10);
        setScrollThreshold((prevThreshold) => prevThreshold + 150);
      }
    };
    const scrollContainer = scrollContainerRef.current;
    scrollContainer?.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer?.removeEventListener("scroll", handleScroll);
    };
  }, [isFetching, scrollThreshold, totalCount, notificationList]);
  const OnGetNotificationTasksForAdminPortal = useCallback(() => {
    // setIsFetching(true);
    const payload = {
      dashboardType: "client",
      limit: limit,
      role_name: userRole,
      user_id: user_id,
      is_archived: singleSegmentValue[0] == "Inbox" ? false : true,
      organisation_id: "",
      search: searchTerm,
    };
    dispatch(GetNotificationTasksForAdminPortal(payload)).then((res) => {
      // setIsFetching(false);
      if (res?.success === true) {
        const arrayData = res?.data.rows;
        setNotificationList(arrayData);
        setTotalTaskCount(res?.data?.unreadCount);
        setTotalCount(res?.data?.count);
      }
    });
  }, [dispatch, singleSegmentValue, searchTerm, limit]);
  useEffect(() => {
    OnGetNotificationTasksForAdminPortal();
  }, [singleSegmentValue, searchTerm, limit]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.trim());
  };
  const inputchangehandler = useCallbackRef(debounce(handleSearch), []);

  const archiveTask = (notify) => {
    const payload = {
      role_name: userRole,
      notification_id: notify.notification_id,
      is_archived: true,
      user_id: user_id,
      dashboardType: "client",
    };
    dispatch(UpdateTaskArchiveStatus(payload)).then((res) => {
      if (res?.success === true) {
        OnGetNotificationTasksForAdminPortal();
      } else if (res?.status === 401) {
        handleUnauthorizedAccess();
      } else {
        // Handle other errors
      }
    });
  };

  const onMarkAsRead = (notifyId) => {
    const payload = {
      role_name: userRole,
      notification_id: notifyId,
      user_id: user_id,
      task_read: true,
      dashboardType: "client",
    };
    dispatch(MarkTaskAsRead(payload)).then((res) => {
      if (res?.success === true) {
        setTotalTaskCount(totalTastCount - 1); // Fixed typo: 'totalTastCount' -> 'totalTaskCount'
        setNotificationList((prevList) => {
          return prevList.map((item) => {
            if (item?.notification_id === notifyId) {
              return { ...item, task_read: true };
            }
            return item;
          });
        });
      } else if (res?.status === 401) {
        handleUnauthorizedAccess();
      } else {
        // Handle other errors
      }
    });
  };

  const fetchDashboardAllClientsCompanyInd = useCallback(async () => {
    try {
      const response = await dispatch(DashboardAllClientsCompanyInd());
      if (response.success) {
        const arrayData = response?.data;
        setClientDataName(arrayData);
        // setContactFunAssId(arrayData.function_assignment_id);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess();
      } else {
        // Handle other errors
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchDashboardAllClientsCompanyInd();
  }, [fetchDashboardAllClientsCompanyInd]);
  return (
    <React.Fragment>
      <Heading className="mb-4">Dashboard</Heading>
      <div className="grid grid-container gap-8">
        <div className="flex gap-8 flex-col wrap-dashboard-table">
          <div className="bg-white py-5 rounded-xl dark-shadow">
            <div className="card-header flex justify-between border-b border-gray-200 pb-5 px-5 mb-3">
              <span className="text-gray-900 text-base font-semibold flex items-center">
                <span className="mr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6 15L8 17L12.5 12.5M8 8V5.2C8 4.0799 8 3.51984 8.21799 3.09202C8.40973 2.71569 8.71569 2.40973 9.09202 2.21799C9.51984 2 10.0799 2 11.2 2H18.8C19.9201 2 20.4802 2 20.908 2.21799C21.2843 2.40973 21.5903 2.71569 21.782 3.09202C22 3.51984 22 4.0799 22 5.2V12.8C22 13.9201 22 14.4802 21.782 14.908C21.5903 15.2843 21.2843 15.5903 20.908 15.782C20.4802 16 19.9201 16 18.8 16H16M5.2 22H12.8C13.9201 22 14.4802 22 14.908 21.782C15.2843 21.5903 15.5903 21.2843 15.782 20.908C16 20.4802 16 19.9201 16 18.8V11.2C16 10.0799 16 9.51984 15.782 9.09202C15.5903 8.71569 15.2843 8.40973 14.908 8.21799C14.4802 8 13.9201 8 12.8 8H5.2C4.0799 8 3.51984 8 3.09202 8.21799C2.71569 8.40973 2.40973 8.71569 2.21799 9.09202C2 9.51984 2 10.0799 2 11.2V18.8C2 19.9201 2 20.4802 2.21799 20.908C2.40973 21.2843 2.71569 21.5903 3.09202 21.782C3.51984 22 4.07989 22 5.2 22Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Task{" "}
                <Tag className="text-blue-800 bg-blue-100 ml-2">
                  {totalTastCount}
                </Tag>
              </span>
            </div>
            <div className="flex justify-between mb-3 px-4">
              <Segment
                className="docType-btn"
                value={singleSegmentValue[0] == "Inbox" ? "Inbox" : "Archive"}
                onChange={(val) => onSingleSelectionSegmentChange(val)}
              >
                <Segment.Item value="Inbox">Inbox</Segment.Item>
                <Segment.Item value="Archive">Archive</Segment.Item>
              </Segment>
              <div className="w-80 h-10">
                <div className="input-wrapper">
                  <div className="input-suffix-start left-3.5">
                    <img src={SearchImg} alt="" className="h-5" />
                  </div>
                  <ClientInput
                    type="text"
                    className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Search"
                    inputchangehandler={inputchangehandler}
                    name={"search"}
                  />
                </div>
              </div>
            </div>
            {/* <div className="overflow-hidden"> */}
            <div
              ref={scrollContainerRef}
              className="notification-list-container overflow-y-auto h-[400px]"
            >
              <Table className="min-w-full">
                <THead>
                  <Tr>
                    <Th className="pl-6 w-1/2	">Notification</Th>
                    <Th>Timestamp</Th>
                    <Th className="w-84">Company</Th>
                    {singleSegmentValue[0] == "Inbox" && (
                      <Th className="">Action</Th>
                    )}
                  </Tr>
                </THead>
                <tbody className={`overflow-y-auto notification-list `}>
                  {loading ? (
                    <tr>
                      <td colSpan="4" className="text-center p-4">
                        Loading...
                      </td>
                    </tr>
                  ) : notificationList.length === 0 ? (
                    <tr>
                      <td colSpan="4" className="text-center p-4">
                        No Data Available!
                      </td>
                    </tr>
                  ) : (
                    <>
                      {groupedNotifications.today.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              Today
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.today.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}

                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {" "}
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.yesterday.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              Yesterday
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.yesterday.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.thisWeek.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              This week
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.thisWeek.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.thisMonth.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              This month
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.thisMonth.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.thisYear.length > 0 && (
                        <>
                          <tr>
                            <td
                              colSpan="4"
                              className="text-blue-700 font-bold px-6 py-2 mt-2"
                            >
                              This year
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.thisYear.map((notif) => (
                                  <li
                                    key={notif.notification_id}
                                    className="flex justify-between py-1.5 px-6 hover:bg-blue-50"
                                  >
                                    <Link
                                      className={`flex gap-2 ${
                                        notif?.task_read ? "" : "font-bold"
                                      }`}
                                      to="#"
                                      onClick={() =>
                                        !notif?.task_read &&
                                        onMarkAsRead(notif.notification_id)
                                      }
                                    >
                                      {notif?.task_read === true ? (
                                        <div
                                          className=""
                                          width="10"
                                          height="10"
                                        ></div>
                                      ) : (
                                        <svg
                                          className="self-center"
                                          width="10"
                                          height="10"
                                          viewBox="0 0 10 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="5"
                                            cy="5"
                                            r="5"
                                            fill="#5786CC"
                                          />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">
                                          {notif.message}
                                        </span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format(
                                        "HH:mm DD/MM/YY"
                                      )}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name
                                          ? notif.trading_name
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" && (
                                      <span
                                        className={`text-blue-700 font-semibold cursor-pointer`}
                                        onClick={() => {
                                          archiveTask(notif);
                                        }}
                                      >
                                        {notif?.action
                                          ? notif.action
                                          : "Archive"}
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                </tbody>
                {isFetching && (
                  <div className="flex justify-center py-4">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-blue-600"></div>
                  </div>
                )}
              </Table>
            </div>
          </div>
          <div className="bg-white py-5 border border-gray-200 rounded-xl dark-shadow">
            <div className="card-header flex justify-between border-b border-gray-200 pb-5 px-5 mb-3">
              <div className="flex items-center">
                <span className="mr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M9.35156 14.6667C9.35156 15.9553 10.3962 17 11.6849 17H13.8516C15.2323 17 16.3516 15.8807 16.3516 14.5C16.3516 13.1193 15.2323 12 13.8516 12H11.8516C10.4709 12 9.35156 10.8807 9.35156 9.5C9.35156 8.11929 10.4709 7 11.8516 7H14.0182C15.3069 7 16.3516 8.04467 16.3516 9.33333M12.8516 5.5V7M12.8516 17V18.5M22.8516 12C22.8516 17.5228 18.3744 22 12.8516 22C7.32871 22 2.85156 17.5228 2.85156 12C2.85156 6.47715 7.32871 2 12.8516 2C18.3744 2 22.8516 6.47715 22.8516 12Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <p></p>
                <div className="flex flex-col">
                  <span className="text-gray-900 text-base font-semibold">
                    {" "}
                    Invoices
                  </span>
                  <p className="text-gray-600">Due in the next 30 days </p>
                </div>
              </div>
              <BtnLight className="w-20">View All</BtnLight>
            </div>
            <div className="px-4">
              <div className="h-64">
                <Scrollbar>
                  <div className="overflow-hidden border rounded-lg border-gray-200">
                    <Table>
                      <TBody>
                        <Tr>
                          <Td>[Name of Recipient]</Td>
                          <Td>DD/MM/YYYY</Td>
                          <Td>
                            <Tag className="bg-warning-100 text-warning-700">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="12"
                                  viewBox="0 0 13 12"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_5799_40401)">
                                    <path
                                      d="M12.2015 5.75L11.2018 6.75L10.2015 5.75M11.3241 6.5C11.3422 6.33583 11.3516 6.169 11.3516 6C11.3516 3.51472 9.33684 1.5 6.85156 1.5C4.36628 1.5 2.35156 3.51472 2.35156 6C2.35156 8.48528 4.36628 10.5 6.85156 10.5C8.2652 10.5 9.52658 9.84817 10.3516 8.82867M6.85156 3.5V6L8.35156 7"
                                      stroke="#F79009"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5799_40401">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                        transform="translate(0.851562)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              Upcoming
                            </Tag>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>[Name of Recipient]</Td>
                          <Td>DD/MM/YYYY</Td>
                          <Td>
                            <Tag className="bg-warning-100 text-warning-700">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="12"
                                  viewBox="0 0 13 12"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_5799_40401)">
                                    <path
                                      d="M12.2015 5.75L11.2018 6.75L10.2015 5.75M11.3241 6.5C11.3422 6.33583 11.3516 6.169 11.3516 6C11.3516 3.51472 9.33684 1.5 6.85156 1.5C4.36628 1.5 2.35156 3.51472 2.35156 6C2.35156 8.48528 4.36628 10.5 6.85156 10.5C8.2652 10.5 9.52658 9.84817 10.3516 8.82867M6.85156 3.5V6L8.35156 7"
                                      stroke="#F79009"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5799_40401">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                        transform="translate(0.851562)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              Upcoming
                            </Tag>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>[Name of Recipient]</Td>

                          <Td>DD/MM/YYYY</Td>
                          <Td>
                            <Tag className="bg-warning-100 text-warning-700">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="12"
                                  viewBox="0 0 13 12"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_5799_40401)">
                                    <path
                                      d="M12.2015 5.75L11.2018 6.75L10.2015 5.75M11.3241 6.5C11.3422 6.33583 11.3516 6.169 11.3516 6C11.3516 3.51472 9.33684 1.5 6.85156 1.5C4.36628 1.5 2.35156 3.51472 2.35156 6C2.35156 8.48528 4.36628 10.5 6.85156 10.5C8.2652 10.5 9.52658 9.84817 10.3516 8.82867M6.85156 3.5V6L8.35156 7"
                                      stroke="#F79009"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5799_40401">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                        transform="translate(0.851562)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              Upcoming
                            </Tag>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>[Name of Recipient]</Td>

                          <Td>DD/MM/YYYY</Td>
                          <Td>
                            <Tag className="bg-error-100 text-error-700">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="12"
                                  viewBox="0 0 13 12"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_5799_40406)">
                                    <path
                                      d="M6.85156 4V6M6.85156 8H6.85656M1.85156 4.26137V7.73863C1.85156 7.86092 1.85156 7.92207 1.86538 7.97962C1.87763 8.03063 1.89783 8.07941 1.92524 8.12414C1.95616 8.1746 1.9994 8.21784 2.08588 8.30431L4.54725 10.7657C4.63372 10.8522 4.67696 10.8954 4.72742 10.9263C4.77216 10.9537 4.82093 10.9739 4.87195 10.9862C4.92949 11 4.99064 11 5.11293 11H8.59019C8.71249 11 8.77363 11 8.83118 10.9862C8.8822 10.9739 8.93097 10.9537 8.9757 10.9263C9.02616 10.8954 9.0694 10.8522 9.15588 10.7657L11.6172 8.30431C11.7037 8.21784 11.747 8.1746 11.7779 8.12414C11.8053 8.07941 11.8255 8.03063 11.8377 7.97962C11.8516 7.92207 11.8516 7.86092 11.8516 7.73863V4.26137C11.8516 4.13908 11.8516 4.07793 11.8377 4.02038C11.8255 3.96937 11.8053 3.92059 11.7779 3.87586C11.747 3.8254 11.7037 3.78216 11.6172 3.69569L9.15588 1.23431C9.0694 1.14784 9.02616 1.1046 8.9757 1.07368C8.93097 1.04627 8.8822 1.02606 8.83118 1.01382C8.77363 1 8.71249 1 8.59019 1H5.11293C4.99064 1 4.92949 1 4.87195 1.01382C4.82093 1.02606 4.77216 1.04627 4.72742 1.07368C4.67696 1.1046 4.63372 1.14784 4.54725 1.23431L2.08588 3.69569C1.9994 3.78216 1.95616 3.8254 1.92524 3.87586C1.89783 3.92059 1.87763 3.96937 1.86538 4.02038C1.85156 4.07793 1.85156 4.13908 1.85156 4.26137Z"
                                      stroke="#F04438"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5799_40406">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                        transform="translate(0.851562)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              Overdue
                            </Tag>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>[Name of Recipient]</Td>

                          <Td>DD/MM/YYYY</Td>
                          <Td>
                            <Tag className="bg-error-100 text-error-700">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="12"
                                  viewBox="0 0 13 12"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_5799_40406)">
                                    <path
                                      d="M6.85156 4V6M6.85156 8H6.85656M1.85156 4.26137V7.73863C1.85156 7.86092 1.85156 7.92207 1.86538 7.97962C1.87763 8.03063 1.89783 8.07941 1.92524 8.12414C1.95616 8.1746 1.9994 8.21784 2.08588 8.30431L4.54725 10.7657C4.63372 10.8522 4.67696 10.8954 4.72742 10.9263C4.77216 10.9537 4.82093 10.9739 4.87195 10.9862C4.92949 11 4.99064 11 5.11293 11H8.59019C8.71249 11 8.77363 11 8.83118 10.9862C8.8822 10.9739 8.93097 10.9537 8.9757 10.9263C9.02616 10.8954 9.0694 10.8522 9.15588 10.7657L11.6172 8.30431C11.7037 8.21784 11.747 8.1746 11.7779 8.12414C11.8053 8.07941 11.8255 8.03063 11.8377 7.97962C11.8516 7.92207 11.8516 7.86092 11.8516 7.73863V4.26137C11.8516 4.13908 11.8516 4.07793 11.8377 4.02038C11.8255 3.96937 11.8053 3.92059 11.7779 3.87586C11.747 3.8254 11.7037 3.78216 11.6172 3.69569L9.15588 1.23431C9.0694 1.14784 9.02616 1.1046 8.9757 1.07368C8.93097 1.04627 8.8822 1.02606 8.83118 1.01382C8.77363 1 8.71249 1 8.59019 1H5.11293C4.99064 1 4.92949 1 4.87195 1.01382C4.82093 1.02606 4.77216 1.04627 4.72742 1.07368C4.67696 1.1046 4.63372 1.14784 4.54725 1.23431L2.08588 3.69569C1.9994 3.78216 1.95616 3.8254 1.92524 3.87586C1.89783 3.92059 1.87763 3.96937 1.86538 4.02038C1.85156 4.07793 1.85156 4.13908 1.85156 4.26137Z"
                                      stroke="#F04438"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5799_40406">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                        transform="translate(0.851562)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              Overdue
                            </Tag>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>[Name of Recipient]</Td>
                          <Td>DD/MM/YYYY</Td>
                          <Td>
                            <Tag className="bg-error-100 text-error-700">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="12"
                                  viewBox="0 0 13 12"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_5799_40406)">
                                    <path
                                      d="M6.85156 4V6M6.85156 8H6.85656M1.85156 4.26137V7.73863C1.85156 7.86092 1.85156 7.92207 1.86538 7.97962C1.87763 8.03063 1.89783 8.07941 1.92524 8.12414C1.95616 8.1746 1.9994 8.21784 2.08588 8.30431L4.54725 10.7657C4.63372 10.8522 4.67696 10.8954 4.72742 10.9263C4.77216 10.9537 4.82093 10.9739 4.87195 10.9862C4.92949 11 4.99064 11 5.11293 11H8.59019C8.71249 11 8.77363 11 8.83118 10.9862C8.8822 10.9739 8.93097 10.9537 8.9757 10.9263C9.02616 10.8954 9.0694 10.8522 9.15588 10.7657L11.6172 8.30431C11.7037 8.21784 11.747 8.1746 11.7779 8.12414C11.8053 8.07941 11.8255 8.03063 11.8377 7.97962C11.8516 7.92207 11.8516 7.86092 11.8516 7.73863V4.26137C11.8516 4.13908 11.8516 4.07793 11.8377 4.02038C11.8255 3.96937 11.8053 3.92059 11.7779 3.87586C11.747 3.8254 11.7037 3.78216 11.6172 3.69569L9.15588 1.23431C9.0694 1.14784 9.02616 1.1046 8.9757 1.07368C8.93097 1.04627 8.8822 1.02606 8.83118 1.01382C8.77363 1 8.71249 1 8.59019 1H5.11293C4.99064 1 4.92949 1 4.87195 1.01382C4.82093 1.02606 4.77216 1.04627 4.72742 1.07368C4.67696 1.1046 4.63372 1.14784 4.54725 1.23431L2.08588 3.69569C1.9994 3.78216 1.95616 3.8254 1.92524 3.87586C1.89783 3.92059 1.87763 3.96937 1.86538 4.02038C1.85156 4.07793 1.85156 4.13908 1.85156 4.26137Z"
                                      stroke="#F04438"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5799_40406">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                        transform="translate(0.851562)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              Overdue
                            </Tag>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>[Name of Recipient]</Td>

                          <Td>DD/MM/YYYY</Td>
                          <Td>
                            <Tag className="bg-error-100 text-error-700">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="12"
                                  viewBox="0 0 13 12"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_5799_40406)">
                                    <path
                                      d="M6.85156 4V6M6.85156 8H6.85656M1.85156 4.26137V7.73863C1.85156 7.86092 1.85156 7.92207 1.86538 7.97962C1.87763 8.03063 1.89783 8.07941 1.92524 8.12414C1.95616 8.1746 1.9994 8.21784 2.08588 8.30431L4.54725 10.7657C4.63372 10.8522 4.67696 10.8954 4.72742 10.9263C4.77216 10.9537 4.82093 10.9739 4.87195 10.9862C4.92949 11 4.99064 11 5.11293 11H8.59019C8.71249 11 8.77363 11 8.83118 10.9862C8.8822 10.9739 8.93097 10.9537 8.9757 10.9263C9.02616 10.8954 9.0694 10.8522 9.15588 10.7657L11.6172 8.30431C11.7037 8.21784 11.747 8.1746 11.7779 8.12414C11.8053 8.07941 11.8255 8.03063 11.8377 7.97962C11.8516 7.92207 11.8516 7.86092 11.8516 7.73863V4.26137C11.8516 4.13908 11.8516 4.07793 11.8377 4.02038C11.8255 3.96937 11.8053 3.92059 11.7779 3.87586C11.747 3.8254 11.7037 3.78216 11.6172 3.69569L9.15588 1.23431C9.0694 1.14784 9.02616 1.1046 8.9757 1.07368C8.93097 1.04627 8.8822 1.02606 8.83118 1.01382C8.77363 1 8.71249 1 8.59019 1H5.11293C4.99064 1 4.92949 1 4.87195 1.01382C4.82093 1.02606 4.77216 1.04627 4.72742 1.07368C4.67696 1.1046 4.63372 1.14784 4.54725 1.23431L2.08588 3.69569C1.9994 3.78216 1.95616 3.8254 1.92524 3.87586C1.89783 3.92059 1.87763 3.96937 1.86538 4.02038C1.85156 4.07793 1.85156 4.13908 1.85156 4.26137Z"
                                      stroke="#F04438"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5799_40406">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                        transform="translate(0.851562)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              Overdue
                            </Tag>
                          </Td>
                        </Tr>
                      </TBody>
                    </Table>
                  </div>
                </Scrollbar>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-8 flex-col">
          <TotalClients />
          <Inductions clientDataName={clientDataName} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
