import React from "react";
import Table from "../../../../../../common/element/Table";
const { Tr, Th, Td, THead, TBody } = Table
const ClientOverviewList = () => {
    return (
        <React.Fragment>
            <div className="overflow-hidden border rounded-lg border-gray-200">
                <Table>
                    <THead>
                        <Tr>
                            <Th>
                                <span className='flex items-center gap-1'>
                                    Client name

                                </span>
                            </Th>
                            <Th>ABN/NZBN</Th>
                            <Th>
                                Lifetime value
                            </Th>
                            <Th>Provider fees value</Th>
                            <Th>Status</Th>
                            <Th>
                                <span className='flex items-center gap-1'>
                                    Date joined
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8" stroke="#475467" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span>
                            </Th>
                            <Th>
                                <span className='flex items-center gap-1'>
                                    Renewal Date
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8" stroke="#475467" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span>
                            </Th>
                        </Tr>
                    </THead>
                    <TBody>
                        <Tr>
                            <Td>Lead text</Td>
                            <Td>ABN/NZBN</Td>
                            <Td>$000.000</Td>
                            <Td>$000.000</Td>
                            <Td>[Status]</Td>
                            <Td>DD/MM/YYYY</Td>
                            <Td>28/01/2023</Td>
                        </Tr>
                        <Tr>
                            <Td>Lead text</Td>
                            <Td>ABN/NZBN</Td>
                            <Td>$000.000</Td>
                            <Td>$000.000</Td>
                            <Td>[Status]</Td>
                            <Td>DD/MM/YYYY</Td>
                            <Td>28/01/2023</Td>
                        </Tr>
                        <Tr>
                            <Td>Lead text</Td>
                            <Td>ABN/NZBN</Td>
                            <Td>$000.000</Td>
                            <Td>$000.000</Td>
                            <Td>[Status]</Td>
                            <Td>DD/MM/YYYY</Td>
                            <Td>-</Td>
                        </Tr>
                        <Tr>
                            <Td>Lead text</Td>
                            <Td>ABN/NZBN</Td>
                            <Td>$000.000</Td>
                            <Td>$000.000</Td>
                            <Td>[Status]</Td>
                            <Td>DD/MM/YYYY</Td>
                            <Td>28/01/2023</Td>
                        </Tr>
                        <Tr>
                            <Td>Lead text</Td>
                            <Td>ABN/NZBN</Td>
                            <Td>$000.000</Td>
                            <Td>$000.000</Td>
                            <Td>[Status]</Td>
                            <Td>DD/MM/YYYY</Td>
                            <Td>-</Td>
                        </Tr>
                    </TBody>
                </Table>
            </div>
        </React.Fragment>
    )
}
export default ClientOverviewList