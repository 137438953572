import "./Loader.css";

const ThreeDotBouncing = () => (
  // <div className="flex justify-center items-center space-x-1">
  //   <div className="w-2 h-2 bg-[rgb(87,134,204)] rounded-full animate-dot-bounce delay-0"></div>
  //   <div className="w-2 h-2 bg-[rgb(87,134,204)] rounded-full animate-dot-bounce delay-200"></div>
  //   <div className="w-2 h-2 bg-[rgb(87,134,204)] rounded-full animate-dot-bounce delay-400"></div>
  // </div>
  <div className="loader">
    <div className="spinner"></div>
  </div>
);

export default ThreeDotBouncing;
