// // Import necessary libraries
// import React from 'react';
// import { Bar } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   BarElement,
//   CategoryScale,
//   LinearScale,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// // Register required Chart.js components
// ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

// const BarChart = () => {
//   // Data for the bar chart
//   const data = {
//     labels: [
//       'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
//       'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
//     ],
//     datasets: [
//       {
//         label: 'Paid',
//         data: [500, 600, 400, 500, 700, 800, 600, 700, 600, 900, 800, 700],
//         backgroundColor: '#3DB77E',
//         borderRadius: 8,  // Rounded corners for bars
//         barThickness: 30, // Fixed thickness for bars
//       },
//       {
//         label: 'Overdue',
//         data: [200, 300, 150, 250, 200, 300, 150, 200, 200, 300, 150, 250],
//         backgroundColor: '#F97066',
//         borderRadius: 8,  // Rounded corners for bars
//         barThickness: 30, // Fixed thickness for bars
//       }
//     ]
//   };

//   // Configuration options for the chart
//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,  // Allows full control over height and width
//     plugins: {
//       legend: {
//         position: 'top',
//         labels: {
//           boxWidth: 20,
//           padding: 20,
//         },
//       },
//       tooltip: {
//         callbacks: {
//           label: (tooltipItem) => `$${tooltipItem.raw}`, // Custom tooltip format
//         },
//       },
//     },
//     scales: {
//       x: {
//         stacked: true,
//         grid: {
//           display: false,  // Hide gridlines on the X-axis
//         },
//         ticks: {
//           autoSkip: false,  // Ensures all labels are shown
//           maxRotation: 45,  // Rotate labels slightly to prevent overlap
//           minRotation: 0,   // Prevent full vertical rotation
//         },
//         categoryPercentage: 0.7,  // Space between bars within a category
//         barPercentage: 0.6,  // Adjust bar width within each category
//       },
//       y: {
//         stacked: true,
//         beginAtZero: true,  // Y-axis starts from 0
//         ticks: {
//           callback: (value) => `$${value}`,  // Custom tick format
//         },
//       },
//     },
//   };

//   // Render the chart with a custom container
//   return (
//     <div style={{ width: '100%', height: '400px', margin: '0 auto 40px' }}>
//       <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
//         Total Revenue
//       </h2>
//       <Bar data={data} options={options} />
//     </div>
//   );
// };

// export default BarChart;
// Import necessary libraries
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';

// Register required Chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const BarChart = () => {
  // Data for the bar chart
  const data = {
    labels: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ],
    datasets: [
      {
        label: 'Paid',
        data: [500, 600, 400, 500, 700, 800, 600, 700, 600, 900, 800, 700],
        backgroundColor: '#3DB77E',
        borderRadius: 8,  // Rounded corners for bars
        barThickness: 30, // Fixed thickness for bars
      },
      {
        label: 'Overdue',
        data: [200, 300, 150, 250, 200, 300, 150, 200, 200, 300, 150, 250],
        backgroundColor: '#F97066',
        borderRadius: 8,  // Rounded corners for bars
        barThickness: 30, // Fixed thickness for bars
      },
    ],
  };

  // Configuration options for the chart
  const options = {
    responsive: true,
    maintainAspectRatio: false,  // Allows full control over height and width
    plugins: {
      legend: {
        display: false,  // Disable the default legend
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `$${tooltipItem.raw}`, // Custom tooltip format
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,  // Hide gridlines on the X-axis
        },
        ticks: {
          autoSkip: false,  // Ensure all labels are shown
          maxRotation: 45,
          minRotation: 0,
        },
        categoryPercentage: 0.7,
        barPercentage: 0.6,
      },
      y: {
        stacked: true,
        beginAtZero: true,  // Y-axis starts from 0
        ticks: {
          callback: (value) => `$${value}`,  // Custom tick format
        },
      },
    },
  };

  // Render the chart with a custom container
  return (
    <div style={{ width: '100%', height: '400px', margin: '0 auto', paddingTop:'60px', position: 'relative' }}>
      {/* Title on the left side */}
      <div style={{ 
        position: 'absolute', 
        top: '0', 
        left: '0', 

      }}>
        <span className='text-gray-600 font-medium text-xs'>Total Revenue</span> 
        <div className='text-gray-600 font-semibold text-sm'> $00000</div> 
      </div>

      {/* Custom Legend on the right side */}
      <div style={{ 
        position: 'absolute', 
        top: '0', 
        right: '0', 
        display: 'flex', 
        gap: '30px',
        alignItems: 'center',
      }}>
        <div className='flex gap-3'>
          <div className='mt-2' style={{ width: '8px', height: '8px', backgroundColor: '#3DB77E',borderRadius:'50%' }}></div>
          <div>
          <span className='text-gray-600 font-medium text-xs'>Paid</span>
          <div className='text-gray-600 font-semibold text-sm'> $00000</div> 
          </div>
        </div>
        <div className='flex gap-3'>
          <div className='mt-2' style={{ width: '8px', height: '8px', backgroundColor: '#F97066',borderRadius:'50%'  }}></div>
          <div>
          <span className='text-gray-600 font-medium text-xs'>Overdue</span>
          <div className='text-gray-600 font-semibold text-sm'> $00000</div> 
          </div>
        </div>
      </div>

      {/* Chart */}
      
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
