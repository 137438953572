import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useParams } from "react-router";
import Tabs from "../../../../../../../../common/element/Tabs";
import Table from "../../../../../../../../common/element/Table";
import Tooltip from "../../../../../../../../common/element/Tooltip";
import Tag from "../../../../../../../../common/element/Tag";
import Dialog from "../../../../../../../../common/element/Dialog";
import CompanyInductionView from "./CompanyInductionView";
import { GetIndStatusOfSpecificWorkerByClient } from "../../../../../../../../actions/provider-portal/worker/GetIndStatusOfSpecificWorkerByClient";
import { useSelector } from "react-redux";
const { TabNav, TabList, TabContent } = Tabs;
const CompanyInductions = ({ clientOrgId }) => {
  const { specificWorker } = useSelector((state) => state);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [siteLogDataCP, setSiteLogDataCP] = useState([]);
  const [indName, setIndName] = useState("");
  const [inductionData, setinductionData] = useState([]);
  const [tabValue, setTabValue] = useState("tab1");
  const [dataIndSpec, setDataIndSpec] = useState(null);

  const [siteIndFilterSite, setSiteIndFilterSite] = useState({
    client_org_id: clientOrgId ? clientOrgId : "",
    worker_id: specificWorker?.worker_id,
    sort: "company_ind_name",
    order: "desc",
    inductionType: "company",
  });
  const [inDialogIsOpen, setInIsOpen] = useState(false);
  const { Tr, Th, Td, THead, TBody } = Table;

  const openInDialog = async (item, type) => {
    let payload = {
      worker_id: specificWorker?.worker_id,
    };
    payload = {
      ...payload,
      site_induction_id: item?.site_induction_id || "",
      inductionType: "company",
    };
    setIndName(item?.company);
    setInIsOpen(true);

    setDataIndSpec(payload);
  };

  const onInDialogClose = (e) => {
    setInIsOpen(false);
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await dispatch(
        GetIndStatusOfSpecificWorkerByClient(siteIndFilterSite)
      );
      if (response.success) {
        const arrayData = response.data;
        setinductionData(arrayData);
      } else if (response.status === 401) {
        // handle unauthorized access
        console.warn("Unauthorized access");
      } else {
        // handle other errors
        console.error(
          "Failed to fetch site logs:",
          response.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [dispatch, siteIndFilterSite, siteIndFilterSite.sort]);

  useEffect(() => {
    fetchData();
  }, [siteIndFilterSite.sort, siteIndFilterSite]);

  // Site Filter
  const handleSortIndSite = (column) => {
    setSiteIndFilterSite((prevalue) => {
      return {
        ...prevalue,
        ["order"]: siteIndFilterSite.order === "asc" ? "desc" : "asc",
      };
    });
    setSiteIndFilterSite((prevalue) => {
      return {
        ...prevalue,
        ["sort"]: column,
      };
    });
  };
  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        {/* <div className="h-64 overflow-auto">
                    <Scrollbar> */}
        <Table>
          <THead>
            <Tr>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSortIndSite("company_ind_name")}
                >
                  Company name
                  <svg
                    className={`transform ${
                      siteIndFilterSite?.sort === "company_ind_name" &&
                      siteIndFilterSite?.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </Th>

              {/* <Th>Induction Completed</Th> */}
              <Th className="w-44">
                <span className="flex items-center">
                  Status
                  <Tooltip title="Top start" placement="top-start">
                    <span className="pl-1">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#bcclap1iaa)">
                          <path
                            d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0z"
                            stroke="#98A2B3"
                            strokeWidth="1.333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="bcclap1iaa">
                            <path fill="#fff" d="M0 0h16v16H0z" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </Tooltip>
                </span>
              </Th>
            </Tr>
          </THead>
          <TBody>
            {inductionData?.clientCompInd?.map((item, innerIndex) => {
              let status;
              if (item?.workerCompInd?.is_comp_ind_completed) {
                status = "Completed";
              } else if (item?.workerCompInd?.is_comp_ind_completed == false) {
                status = "Pending";
              } else {
                status = "Awaiting action";
              }
              return (
                <Tr key={innerIndex}>
                  <Td>
                    <span
                      className="cursor-pointer"
                      onClick={() => openInDialog(item, "company")}
                    >
                      {item?.company_ind_name}
                    </span>
                  </Td>
                  <Td>
                    {status == "Awaiting action" && (
                      <Tag className="bg-gray-100 text-gray-700">
                        <svg
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        {status}
                      </Tag>
                    )}
                    {status == "Completed" && (
                      <Tag className="bg-success-100 text-success-700">
                        <svg
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="m10 3.691-5.5 5.5-2.5-2.5"
                            stroke="#039855"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Completed
                      </Tag>
                    )}
                    {status == "Pending" && (
                      <Tag className="bg-blue-100 text-blue-800">
                        <svg
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_1592_20677)">
                            <path
                              d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                              stroke="#5786CC"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1592_20677">
                              <rect
                                width="12"
                                height="12"
                                fill="white"
                                transform="translate(0 0.691406)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        Pending
                      </Tag>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </TBody>
        </Table>
        {/* </Scrollbar>
                </div> */}
                
      </div>

      <Dialog
        isOpen={inDialogIsOpen}
        onClose={onInDialogClose}
        onRequestClose={onInDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="lg-dialog"
      >
        <div className="flex border-b pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m12.895 21.586-.1-.15c-.695-1.042-1.043-1.563-1.501-1.94a4 4 0 0 0-1.378-.737c-.568-.173-1.194-.173-2.447-.173H6.095c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874c-.218-.428-.218-.988-.218-2.108v-8.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.427-.218.987-.218 2.108-.218h.4c2.24 0 3.36 0 4.215.436A4 4 0 0 1 12.46 5.77c.436.856.436 1.976.436 4.216m0 11.6v-11.6m0 11.6.1-.15c.694-1.042 1.042-1.563 1.5-1.94a4.001 4.001 0 0 1 1.378-.737c.568-.173 1.194-.173 2.447-.173h1.375c1.12 0 1.68 0 2.107-.218a2 2 0 0 0 .875-.874c.218-.428.218-.988.218-2.108v-8.6c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.875-.874c-.427-.218-.987-.218-2.107-.218h-.4c-2.24 0-3.36 0-4.216.436A4 4 0 0 0 13.33 5.77c-.436.856-.436 1.976-.436 4.216"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div>
            <h5 className="text-gray-900 text-base font-semibold">
              {indName ? indName : ""}
            </h5>
          </div>
        </div>

        {dataIndSpec?.inductionType == "company" && (
          <CompanyInductionView dataIndSpec={dataIndSpec} />
        )}
      </Dialog>
    </React.Fragment>
  );
};
export default CompanyInductions;
