import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { TailSpin } from "react-loader-spinner";
import { ExportInductionModulePdfRange } from "../../../../../../../../actions/client-portal/ExportInductionModulePdfRange";
import ScrollBar from "../../../../../../../../common/element/ScrollBar/ScrollBar";
import Tabs from "../../../../../../../../common/element/Tabs";
import Checkbox from "../../../../../../../../common/element/Checkbox";
import Upload from "../../../../../../../../common/element/Upload";
import RichTextEditor from "../../../../../../../../common/snippet/RichTextEditor";
import useUnauthorizedHandler from "../../../../../../../../common/snippet/useUnauthorizedHandler";
import { GetWorkerTrainingInduction } from "../../../../../../../../actions/client-portal/AllSiteApi";
import { GetSignedUrlByKey } from "../../../../../../../../actions/public/GetSignedUrlByKey";

let checkindexNumber = 0;
const SiteInductionView = ({ dataIndSpec }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  });

  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const { userName } = JSON.parse(localStorage.getItem("LoginUserData"));
  const GettAllFunctions = useSelector((state) => state);
  const { TabNav, TabList, TabContent } = Tabs;
  const [checkData, setcheckData] = useState("");
  const [UrlPdf, setUrlPdf] = useState("");
  const [defaultTab, SetdefaultTab] = useState("tab1");
  const [pdfFile, SetPdfFile] = useState("");
  const [spiner, Setspiner] = useState(false);
  const [moduleData, SetmoduleData] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [checkIsLastmodule, setcheckIsLastmodule] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState(null);
  const [indexData, SetindexData] = useState(0);
  let induction_permissions;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  const permissionData = JSON.parse(localStorage.getItem("permission_data"));
  if (permissionData !== null) {
    induction_permissions = permissionData.induction_write;
  }

  useEffect(() => {
    checkindexNumber = 0;
  }, [GettAllFunctions]);

  const updateUrl = (file, image) => {
    if (file) {
      dispatch(GetSignedUrlByKey({ url: file }, "client")).then((response) => {
        if (response.success) {
          if (image == "image") {
            setUrlPdf(response?.data);
          } else {
            SetPdfFile(response?.data);
          }
        }
      });
    }
  };

  const ViewModule = (e) => {
    if (e.index == 0) {
      checkindexNumber = 1;
    }
    if (indexData != e.index || checkindexNumber == 1) {
      if (e.index !== 0) {
        checkindexNumber = 0;
      }
      SetindexData(e.index);
      SetPdfFile("");
      Setspiner(true);
      const parsedObject = moduleData[e.index]?.module_data?.pdf_page_range;
      const fileExtension = moduleData[e.index]?.module_data?.content_info
        ?.split(".")
        ?.pop()
        ?.toLowerCase();
      if (
        parsedObject &&
        Object.keys(parsedObject).length !== 0 &&
        parsedObject.constructor === Object
      ) {
        let stringifyData = moduleData[e.index]?.module_data?.pdf_page_range;
        let PdfData = {
          content_info: moduleData[e.index]?.module_data?.content_info,
          startPage: stringifyData?.startPage,
          endPage: stringifyData?.endPage,
        };
        if (
          moduleData[e.index]?.module_data?.content_info &&
          fileExtension == "pdf" &&
          stringifyData?.startPage &&
          stringifyData?.endPage
        ) {
          dispatch(ExportInductionModulePdfRange(PdfData))
            .then((response) => {
              if (response.success) {
                Setspiner(false);
                updateUrl(response.data.file);
              } else if (response.status === 401) {
                handleUnauthorizedAccess();
              } else {
                Setspiner(false);
              }
            })
            .catch((error) => {});
        } else {
          if (fileExtension == "pdf") {
            updateUrl(moduleData[e.index]?.module_data?.content_info);
          } else {
            SetPdfFile(moduleData[e.index]?.module_data?.content_info);
          }

          Setspiner(false);
        }
      } else {
        if (fileExtension == "pdf") {
          updateUrl(moduleData[e.index]?.module_data?.content_info);
        } else {
          SetPdfFile(moduleData[e.index]?.module_data?.content_info);
        }
        Setspiner(false);
      }

      if (moduleData.length - 1 == e.index) {
        setcheckIsLastmodule(true);
      } else {
        setcheckIsLastmodule(false);
      }
      SetdefaultTab(e.module_name);

      if (moduleData[e?.index]?.module_data?.content_info_type == "file") {
        let fileName;
        if (
          moduleData[e?.index]?.module_data?.company_ind_file?.name !==
          undefined
        ) {
          fileName = moduleData[e?.index]?.module_data?.company_ind_file?.name;
        } else {
          fileName = moduleData[e?.index]?.module_data?.content_info;
        }
        // Get the file extension
        let fileExtension;
        if (fileName) {
          fileExtension = fileName?.split(".").pop().toLowerCase();
        }
        // Check if it's a PDF
        if (fileExtension === "pdf") {
          //   if (moduleData[e.index].company_ind_file?.name !== undefined) {
          //     let render = new FileReader();
          //     render.onload = (event) => {
          //       // Use '=' instead of '()' here
          //       setUrlPdf(event.target.result);
          //     };
          //     render.readAsDataURL(moduleData[e.index].company_ind_file);
          //   } else {
          //     setUrlPdf(moduleData[e.index].content_info);
          //   }
          setcheckData("pdf");
        }
        // Check if it's an image (you can add more image extensions if needed)
        if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
          let render = new FileReader();
          render.onload = (event) => {
            // Use '=' instead of '()' here
            setUrlPdf(event.target.result);
          };
          if (
            moduleData[e?.index]?.module_data?.company_ind_file?.name !==
            undefined
          ) {
            render?.readAsDataURL(
              moduleData[e?.index]?.module_data?.company_ind_file
            );
          } else {
            updateUrl(moduleData[e.index]?.module_data?.content_info, "image");
          }
          setcheckData("image");
        }
      }
      if (moduleData[e?.index]?.module_data?.content_info_type == "text") {
        setcheckData("text");
      }
      if (
        moduleData[e?.index]?.module_data?.content_info_type == "video_url" ||
        moduleData[e?.index]?.module_data?.content_info_type == "file_video"
      ) {
        updateUrl(moduleData[e.index]?.module_data?.content_info);
        setcheckData("video_url");
      }
      if (moduleData[e?.index]?.module_data?.content_info_type == "url") {
        setcheckData("url");
      }
      if (
        moduleData[e?.index]?.module_data?.content_info_type == null ||
        moduleData[e?.index]?.module_data?.content_info_type == ""
      ) {
        setcheckData("");
      }
    }
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setDragging(false);
    setStartPosition(null);
  };
  const handleMouseMove = (e) => {
    if (dragging && startPosition) {
      const deltaX = e.clientX - startPosition.x;
      const deltaY = e.clientY - startPosition.y;
      setPosition({
        x: position.x + deltaX,
        y: position.y + deltaY,
      });
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = UrlPdf;
    link.download = "image.jpg";
    link.click();
  };
  const handlePrint = () => {
    const printWindow = window.open("", "", "width=600,height=600");
    printWindow.document.open();
    printWindow.document.write(`<img src="${UrlPdf}" alt="Image" />`);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };
  const [zoom, setZoom] = useState(100);

  const handleZoomOut = () => {
    setZoom(zoom + 10);
  };

  const handleZoomIn = () => {
    if (zoom > 1) {
      setZoom(zoom - 10);
    }
  };

  useEffect(() => {
    if (dataIndSpec.inductionType == "company") {
      delete dataIndSpec.site_induction_id;
    } else {
      delete dataIndSpec.company_induction_id;
    }
    dispatch(GetWorkerTrainingInduction(dataIndSpec)).then((response) => {
      SetmoduleData(response?.data?.site_ind_modules);
    });
  }, []);

  return (
    <React.Fragment>
      <Tabs value={defaultTab} variant="pill">
        <div className="flex flex-auto w-full">
          <div className="flex flex-col  bg-blue-50 border-r border-gray-300 w-1/4">
            <div className="overflow-y-auto h-595">
              <ScrollBar>
                <div className=" py-6 px-3">
                  <TabList className="flex-col left-side-block">
                    <TabNav
                      onClick={() =>
                        ViewModule({
                          index: 0,
                          module_name: "tab1",
                        })
                      }
                      value="tab1"
                    >
                      Induction Details
                    </TabNav>
                    <hr className="border-t border-gray-300 mt-4 mb-3 w-full" />
                    {moduleData?.map((items, index) => {
                      return (
                        <TabNav
                          value={items?.module_data?.module_name + index}
                          onClick={() =>
                            ViewModule({
                              index: index,
                              module_name:
                                items?.module_data?.module_name + index,
                            })
                          }
                          key={index}
                        >
                          {index + 1}.{items?.module_data?.module_name}
                          {items?.module_data?.ModuleAttempt == null && (
                            <span className="flex w-5 h-5 justify-center items-center rounded-full border-2 border-gray-600">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                                  stroke="#475467"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          )}
                          {items?.module_data?.ModuleAttempt?.is_module_pass ==
                            false && (
                            <span className="flex w-5 h-5 justify-center items-center rounded-full border-2 border-blue-700">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_1592_20677)">
                                  <path
                                    d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                    stroke="#5786CC"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1592_20677">
                                    <rect
                                      width="12"
                                      height="12"
                                      fill="white"
                                      transform="translate(0 0.691406)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          )}
                          {items?.module_data?.ModuleAttempt
                            ?.is_module_pass && (
                            <span className="flex w-5 h-5 justify-center items-center rounded-full border-2 border-success-600">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m10 3.691-5.5 5.5-2.5-2.5"
                                  stroke="#039855"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          )}
                        </TabNav>
                      );
                    })}
                  </TabList>
                </div>
              </ScrollBar>
            </div>
          </div>
          <div className="w-3/4">
            <TabContent value="tab1" className="pr-1">
              <div className="overflow-y-auto h-595">
                <ScrollBar>
                  <div className="mb-6 px-5 pt-5">
                    <h4 className="text-black font-semibold text-xl mb-3">
                      {GettAllFunctions.inductionDetailData.company_ind_name}{" "}
                      for {userName.first_name + " " + userName.last_name}
                    </h4>
                    <p className="text-back font-medium">
                      {GettAllFunctions.inductionDetailData.company_ind_desc}
                    </p>
                  </div>
                  <div className="mb-6 px-5">
                    <h4 className="text-black font-semibold text-xl mb-3">
                      There are {moduleData?.length} modules in this Induction
                    </h4>
                    <p className="text-back font-medium">
                      To get started, simply click on a module you wish to
                      complete. Each module includes important information and
                      instructions to ensure a safe working environment. As you
                      complete each module, your progress will be tracked and
                      saved. If you need to take a break or log out, don't worry
                      - you can pick up where you left off at any time. Thank
                      you for participating in this induction.
                    </p>
                  </div>
                  <div className="mb-6 px-5">
                    <h4 className="text-black font-semibold text-xl mb-3">
                      The Modules progress are tracked in different states:
                    </h4>
                    <ul className="flex flex-col gap-2 mb-0">
                      <li className="flex gap-1">
                        <span className="flex w-5 h-5 justify-center items-center rounded-full border-2 border-gray-600">
                          <svg
                            width="12"
                            height="13"
                            viewBox="0 0 12 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span>=</span> The module is awaiting action.
                      </li>
                      <li className="flex gap-1">
                        <span className="flex w-5 h-5 justify-center items-center rounded-full border-2 border-blue-700">
                          <svg
                            width="12"
                            height="13"
                            viewBox="0 0 12 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1592_20677)">
                              <path
                                d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                stroke="#5786CC"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1592_20677">
                                <rect
                                  width="12"
                                  height="12"
                                  fill="white"
                                  transform="translate(0 0.691406)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span>=</span> You have started your module, but there
                        is more to do.
                      </li>
                      <li className="flex gap-1">
                        <span className="flex w-5 h-5 justify-center items-center rounded-full border-2 border-success-600">
                          <svg
                            width="12"
                            height="13"
                            viewBox="0 0 12 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m10 3.691-5.5 5.5-2.5-2.5"
                              stroke="#039855"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span>=</span>You have successfully finished the module.
                        Well done!
                      </li>
                    </ul>
                  </div>
                </ScrollBar>
              </div>
            </TabContent>
            <TabContent
              value={defaultTab == "tab1" ? "tab2" : defaultTab}
              className="pr-1"
            >
              <div className="overflow-y-auto h-595">
                <ScrollBar>
                  <div className="border-b border-gray-300 pb-3 px-5 pt-5">
                    <h4 className="text-black font-semibold text-xl mb-3">
                      {moduleData?.[indexData]?.module_data?.module_name}
                    </h4>
                    <p className="text-back font-medium">
                      {moduleData?.[indexData]?.module_data?.module_desc}
                    </p>
                  </div>
                  <div className="flex">
                    <div className="w-7/12 rich_text_view">
                      {checkData == "text" && (
                        <RichTextEditor
                          readOnly={true}
                          value={
                            moduleData?.[indexData]?.module_data?.content_info
                          }
                          toolbarHidden={true}
                        />
                      )}
                      {checkData == "url" && (
                        <React.Fragment>
                          <div className="Image h-80 rounded-t-lg bg-gray-200">
                            <img src="/assets/png-icons/pic.png" alt="" />
                          </div>
                          <div className="link-set bg-gray-100 p-5 rounded-b-lg">
                            <div className="flex ">
                              <Link
                                // to="https://www.google.com"
                                to={`${moduleData?.[indexData]?.module_data?.content_info}`}
                                // href={moduleData?.[indexData]?.content_info}
                                className="flex font-semibold text-black gap-2 "
                                target="_blank"
                              >
                                {
                                  moduleData?.[indexData]?.module_data
                                    ?.content_link_title
                                }
                                <span>
                                  <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M17.5 8.125v-5m0 0h-5m5 0-6.667 6.667m-2.5-5H6.5c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093C2.5 6.69 2.5 7.392 2.5 8.792v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.092c.535.273 1.235.273 2.635.273h5.333c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 0 0 1.093-1.092c.272-.535.272-1.235.272-2.635v-1.833"
                                      stroke="#667085"
                                      strokeWidth="1.667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                              </Link>
                            </div>
                            <p className="mt-2 text-black break-words">
                              {
                                moduleData?.[indexData]?.module_data
                                  ?.content_info
                              }
                            </p>
                          </div>
                        </React.Fragment>
                      )}
                      {checkData == "image" && (
                        <>
                          <div className="iFrame-Header ">
                            <div className="text-white text-xs">
                              {
                                moduleData?.[indexData]?.module_data
                                  ?.company_ind_file?.name
                              }
                            </div>
                            <div className="btn-group">
                              <button
                                onClick={() => handleZoomIn()}
                                className="bg-white"
                              >
                                -
                              </button>
                              <button
                                onClick={() => handleZoomOut()}
                                className="bg-white"
                              >
                                +
                              </button>
                            </div>
                            <div className="flex gap-3">
                              <button onClick={() => handleDownload()}>
                                <svg
                                  width="20"
                                  height="21"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.5 13v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C15.6 18 14.9 18 13.5 18h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 16.1 2.5 15.4 2.5 14v-1m11.667-4.167L10 13m0 0L5.833 8.833M10 13V3"
                                    stroke="#fff"
                                    strokeWidth="1.667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                              <button onClick={() => handlePrint()}>
                                <svg
                                  width="20"
                                  height="21"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15 6.333v-1.5c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 3.433 5 3.899 5 4.833v1.5m0 9.166c-.775 0-1.163 0-1.48-.085a2.5 2.5 0 0 1-1.768-1.768c-.085-.318-.085-.705-.085-1.48v-1.833c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.534-.272 1.234-.272 2.635-.272h8.666c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.093 1.093c.272.534.272 1.235.272 2.635v1.833c0 .775 0 1.162-.085 1.48a2.5 2.5 0 0 1-1.768 1.768c-.318.085-.705.085-1.48.085m-2.5-6.25H15m-7.333 9.584h4.666c.934 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.824.182-1.757v-1.333c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 13.433 5 13.899 5 14.833v1.333c0 .933 0 1.4.181 1.757.16.313.415.568.729.728.356.182.823.182 1.756.182z"
                                    stroke="#fff"
                                    strokeWidth="1.667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div
                            className="image-container"
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                            onMouseMove={handleMouseMove}
                          >
                            <img
                              style={{
                                scale: `${zoom}% `,
                                transform: `translate(${position.x}px, ${position.y}px)`,
                              }}
                              src={UrlPdf}
                              alt="Image"
                            />
                          </div>
                        </>
                      )}
                      {checkData == "video_url" && (
                        <ReactPlayer
                          url={pdfFile}
                          className="video-player"
                          playing={true}
                          controls={true}
                        />
                      )}
                      {spiner && (
                        <div>
                          <div className="blur">
                            <div className="flex">
                              <label className="font-medium text-gray-700 w-1/5">
                                Site Address*
                              </label>
                              <div className="w-4/5 pl-2">
                                <input
                                  type="text"
                                  placeholder="Street Address*"
                                  className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                <input
                                  type="text"
                                  placeholder="Street Address*"
                                  className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-center mb-5 mt-5 pt-5">
                            <TailSpin color="blue" radius={"8px"} />
                          </div>
                          <div className="blur">
                            <div className="flex mb-5 border-t mt-5 pt-5">
                              <label className="font-medium text-gray-700 w-1/5">
                                Evacuation Diagram
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {checkData == "pdf" && pdfFile && (
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                          <Viewer
                            fileUrl={pdfFile}
                            plugins={[defaultLayoutPluginInstance]}
                          />
                        </Worker>
                      )}
                    </div>
                    <div className="w-5/12 border-l border-gray-300">
                      {moduleData?.[indexData]?.module_data
                        ?.module_ques_data?.[0].question &&
                        moduleData[
                          indexData
                        ]?.module_data?.module_ques_data?.map(
                          (items, index) => {
                            return (
                              <React.Fragment key={index}>
                                <h4 className="text-black font-semibold mb-3 pl-4 pr-5 pt-5">
                                  Q{index + 1}. {items.question}
                                </h4>
                                <div
                                  className={`flex flex-col gap-2  pr-5 pl-4 pb-4 ${
                                    moduleData?.[indexData]?.module_data
                                      ?.module_ques_data.length -
                                      1 !==
                                    index
                                      ? "border-b border-gray-300"
                                      : ""
                                  }`}
                                >
                                  {items?.ques_ans_data?.map(
                                    (checkbox, number) => {
                                      return (
                                        <div
                                          className={`${
                                            checkbox.is_correct_answer
                                              ? "success-checkbox"
                                              : "error-checkbox"
                                          }`}
                                          key={number}
                                        >
                                          <Checkbox
                                            checked={checkbox.is_correct_answer}
                                          >
                                            {checkbox.answer}
                                          </Checkbox>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </React.Fragment>
                            );
                          }
                        )}
                    </div>
                  </div>
                </ScrollBar>
              </div>
            </TabContent>
          </div>
        </div>
      </Tabs>
    </React.Fragment>
  );
};
export default SiteInductionView;
