import React, { useState } from "react";
import Card from "../../../../../../common/element/Card";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Dialog from "../../../../../../common/element/Dialog";
import ClientSettings from "../../Dialog/Clients/ClientSettings";

const ClientInformation = (props) => {
  const { SpecificProviderData } = useSelector((state) => state);

  const [isEditMode, setEditMode] = useState(false);
  const [userSetting, setUserSetting] = useState(false);
  let address =
    SpecificProviderData &&
    SpecificProviderData?.address_one +
      " " +
      SpecificProviderData?.address_two +
      " " +
      SpecificProviderData?.suburb +
      " " +
      SpecificProviderData?.zipcode;
  const handleEditClick = () => {
    setUserSetting(true);
  };
  const userSettingHide = () => {
    setUserSetting(false);
  };
  return (
    <React.Fragment>
      <Card className="p-5 border border-gray-200 rounded-xl dark-shadow">
        <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-5">
          <span className="text-gray-900 text-base font-semibold flex items-center">
            Provider Information
          </span>
          <BtnLight
            className="h-9 w-20 flex items-center justify-center gap-2"
            onClick={handleEditClick}
          >
            {/* <BtnLight className="h-9 w-20 flex items-center justify-center gap-2" > */}
            Edit
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M14.9999 8.33326L11.6666 4.99993M2.08325 17.9166L4.90356 17.6032C5.24813 17.5649 5.42042 17.5458 5.58146 17.4937C5.72433 17.4474 5.86029 17.3821 5.98566 17.2994C6.12696 17.2062 6.24954 17.0836 6.49469 16.8385L17.4999 5.83326C18.4204 4.91279 18.4204 3.4204 17.4999 2.49993C16.5795 1.57945 15.0871 1.57945 14.1666 2.49992L3.16136 13.5052C2.91621 13.7503 2.79363 13.8729 2.70045 14.0142C2.61778 14.1396 2.55243 14.2755 2.50618 14.4184C2.45405 14.5794 2.43491 14.7517 2.39662 15.0963L2.08325 17.9166Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </BtnLight>
        </div>
        <div className="flex mb-4">
          <div className="flex-1">
            <span className="text-gray-400 font-semibold block text-sm mb-1">
              Business name
            </span>
            <span className="text-gray-900 font-medium block text-sm">
              {SpecificProviderData?.trading_name}
            </span>
          </div>
        </div>
        <div className="flex mb-4">
          <div className="flex-1">
            <span className="text-gray-400 font-semibold block text-sm mb-1">
              ABN/AZBN
            </span>
            <span className="text-gray-900 font-medium block text-sm">
              {SpecificProviderData?.abn_nzbn}
            </span>
          </div>
          <div className="flex-1">
            <span className="text-gray-400 font-semibold block text-sm mb-1">
              ACN
            </span>
            <span className="text-gray-900 font-medium block text-sm">
              {SpecificProviderData?.acn}
            </span>
          </div>
        </div>
        <div className="flex mb-4">
          <div className="flex-1">
            <span className="text-gray-400 font-semibold block text-sm mb-1">
              Website
            </span>
            <span className="text-gray-900 font-medium flex gap-1 text-sm">
              {SpecificProviderData?.website_url != "null" &&
              SpecificProviderData?.website_url != "" ? (
                <>
                  {SpecificProviderData?.website_url}{" "}
                  <Link
                    to="#"
                    className="text-gray-700 font-semibold border border-gray-100 bg-white w-10  flex h-10 justify-center items-center rounded-lg gap-1 rounded-tl-none rounded-bl-none hover:text-gray-900 focus:text-gray-600"
                    onClick={() => {
                      if (SpecificProviderData?.website_url.trim() !== "") {
                        window.open(
                          `https://${SpecificProviderData?.website_url}`,
                          "_blank"
                        );
                      }
                    }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 13 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </Link>
                </>
              ) : (
                "-"
              )}
            </span>
          </div>
        </div>
        <div className="flex mb-4">
          <div className="flex-1">
            <span className="text-gray-400 font-semibold block text-sm mb-1">
              Business address
            </span>
            <span className="text-gray-900 font-medium flex gap-1 text-sm">
              {address}
            </span>
          </div>
        </div>
        <div className="flex mb-0">
          <div className="flex-1">
            <span className="text-gray-400 font-semibold block text-sm mb-1">
              Country
            </span>
            <span className="text-gray-900 font-medium block text-sm">
              {SpecificProviderData?.country_name}
            </span>
          </div>
          <div className="flex-1">
            <span className="text-gray-400 font-semibold block text-sm mb-1">
              State
            </span>
            <span className="text-gray-900 font-medium block text-sm">
              {SpecificProviderData?.state_name}
            </span>
          </div>
        </div>
      </Card>

      <Dialog
        isOpen={userSetting}
        onClose={userSettingHide}
        onRequestClose={userSettingHide}
        bodyOpenClassName="overflow-hidden provider_setting"
      >
        <div className="flex border-b  pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                clip-path="url(#tlbg3la43a)"
                stroke="#344054"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                <path d="M15.606 12.273a1.25 1.25 0 0 0 .25 1.379l.046.045a1.514 1.514 0 0 1-1.072 2.588 1.514 1.514 0 0 1-1.072-.444l-.046-.046a1.25 1.25 0 0 0-1.379-.25 1.25 1.25 0 0 0-.757 1.144v.13a1.515 1.515 0 0 1-3.03 0v-.069a1.25 1.25 0 0 0-.819-1.144 1.25 1.25 0 0 0-1.378.25l-.046.046a1.517 1.517 0 1 1-2.144-2.144l.046-.046a1.25 1.25 0 0 0 .25-1.379 1.25 1.25 0 0 0-1.144-.757h-.13a1.515 1.515 0 1 1 0-3.03h.069a1.25 1.25 0 0 0 1.144-.819 1.25 1.25 0 0 0-.25-1.379l-.045-.045a1.515 1.515 0 1 1 2.143-2.144l.046.046a1.25 1.25 0 0 0 1.379.25h.06a1.25 1.25 0 0 0 .758-1.144v-.13a1.515 1.515 0 1 1 3.03 0v.069a1.25 1.25 0 0 0 .758 1.144 1.25 1.25 0 0 0 1.379-.25l.045-.046a1.515 1.515 0 1 1 2.144 2.144l-.045.046a1.25 1.25 0 0 0-.25 1.379v.06a1.25 1.25 0 0 0 1.143.758h.13a1.515 1.515 0 0 1 0 3.03h-.069a1.25 1.25 0 0 0-1.144.758z" />
              </g>
              <defs>
                <clipPath id="tlbg3la43a">
                  <path fill="#fff" d="M0 0h20v20H0z" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Settings
            </h5>
          </div>
        </div>
        <ClientSettings userSettingHide={userSettingHide}  type={"overView"}/>
        {/* <div className="flex p-6 border-t justify-end border-gray-200">
      <BtnLight
        onClick={() => openDialogUnSave()}
        className="w-auto px-5 mr-3"
        variant="plain"
      >
        Close
      </BtnLight>
      <BtnPrimary
        onClick={() => userInactiveOpen()}
        className="w-auto px-5"
      >
        Save changes
      </BtnPrimary>
    </div> */}
        <div className="flex dailog-footer justify-end gap-2 p-0"></div>
      </Dialog>
    </React.Fragment>
  );
};
export default ClientInformation;
