import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import ScrollBar from "../../../../../common/element/ScrollBar";
import Label from "../../../../../common/element/Label";
import { useParams } from "react-router-dom";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import SmallButton from "../../../../../common/element/Buttons/btnPrimary";
import { notify } from "react-notify-toast";
import * as userActions from "../../../../../actions/index";

// import { GetClientUserPermByIdForClient } from "../../../../../actions/client-portal/AllUsersApi";
import {
  GetClientOtherDetailsById,
  UpdateComplianceChecklistReviewDate,
} from "../../../../../actions/client-portal/AllSettingApis";
import moment from "moment";
import Dialog from "../../../../../common/element/Dialog";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";

const ComplianceChecklist = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [clientDetailId, setClientDetailId] = useState("");
  const [reviewDate, setReviewDate] = useState("");
  const [dialogAddOpen, setdialogAddOpen] = useState(false);
  let getApiData;

  let user_id;
  let function_assignment_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData) {
    getApiData = {
      organisation_id: storedData.organisation_id,
    };
    user_id = storedData?.userName?.user_id;
    function_assignment_id = storedData.function_assignment_id;
  }

  // api call for permissions


  const openDialogUnSave = () => {
    props.userSettingHide();
  };

  // const GetClientOtherDetailsById = useCallback(async () => {
  //   dispatch(userActions.GetClientOtherDetailsById(id)).then((response) => {
  //     if (response.success === true) {
  //       const arrayData = response.data;
  //       const currentTime = moment();
  //       const revDate = moment(arrayData?.review_comp_checklist_date).format(
  //         "YYYY-MM-DD"
  //       );
  //       setClientDetailId(arrayData?.client_detail_id);
  //       setReviewDate(revDate);
  //     } else {
  //     }
  //   });
  //   // eslint-disable-next-line
  // }, [dispatch]);

  useEffect(() => {
    dispatch(GetClientOtherDetailsById(getApiData)).then((response) => {
      if (response.success) {
        const arrayData = response?.data;
        const currentTime = moment();
        const revDate = moment(arrayData?.review_comp_checklist).format("YYYY-MM-DD");
        setClientDetailId(arrayData?.client_detail_id);
        setReviewDate(revDate);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
      }
    }).catch((error) => {
      console.log("error", error);
    })
  }, []);

  const submitDate = (e) => {
    const payload = {
      client_detail_id: clientDetailId,
      // review_comp_checklist_date: reviewDate,
      review_comp_checklist: reviewDate,
    };
    dispatch(UpdateComplianceChecklistReviewDate(payload))
      .then((response) => {
        if (response.success) {
          setdialogAddOpen(true)
          props.userSettingHide();
        } else if (response.status === 401) {
          handleUnauthorizedAccess()
        }
      })
      .catch((error) => {
      });
  };
  
  const onAddDialogClose=()=>{
    setdialogAddOpen(false)
  }


  return (
    <React.Fragment>
      <div className="flex justify-between mb-5 border-b border-gray-200 pb-5">
        <div>
          <h5 className="text-base text-gray-900 font-semibold mb-1">
            Compliance Date
          </h5>
          {/* <p className="text-gray-600">Customise your notification preferences to stay informed and in control of your alerts and updates.</p> */}
        </div>
       
          <div className="flex justify-end items-center">
            <BtnLight disabled={props.settingsPermission} className="w-auto px-4">Cancel</BtnLight>
            <SmallButton disabled={props.settingsPermission}
              className="w-auto px-4 ml-3"
              onClick={() => submitDate()}
            >
              Save
            </SmallButton>
          </div>
   
      </div>
      <div className="h-400 pr-1">
        <ScrollBar>
          <div className="pr-4 mt-7">
            <div className="flex flex-wrap">
              <div className="w-1/3">
                <Label>
                  How often do you want to be reminded to review your Compliance
                  Checklist?
                </Label>
              </div>
              <div className="w-2/3">
                <input
                  className="pl-4 input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="date"
                  placeholder="Select date"
                  disabled={props.settingsPermission}
                  value={reviewDate}
                  onChange={(e) => setReviewDate(e.target.value)}
                />
              </div>
            </div>
          </div>
        </ScrollBar>
      </div>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                fill="#D1FADF"
              />
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M32.083 18.8721L21.083 29.8721L16.083 24.8721"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Your password has been updated
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => onAddDialogClose()}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    loader: userActions.UpdateComplianceChecklistReviewDate.loader,
  };
}
const mapDispatchToProps = {
  onUpdateComplianceChecklistReviewDate:
    userActions.UpdateComplianceChecklistReviewDate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplianceChecklist);
