import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

const LineChart = () => {
  // Define data for chart
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Compliant',
        data: [40, 50, 60, 65, 70, 80, 85, 90, 92, 93, 95, 100],
        borderColor: '#86E5B2',
        fill: true,
        tension: 0.4,

      },
      {
        label: 'Non-Compliant',
        data: [30, 40, 42, 45, 50, 52, 55, 58, 60, 62, 65, 68],
        borderColor: '#F04438',
        fill: true,
        tension: 0.4
      },
      {
        label: 'Invited',
        data: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75],
        borderColor: '#98A2B3',
        fill: true,
        tension: 0.4
      }
    ]
  };

  // Define chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true
      }
    }
  };
  
  return (

      <Line data={data} options={options} />
  );
};
export default LineChart;
