


// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const ProviderExistingUserData = createSlice({
  name:"ProviderExistingUserData",
  initialState:[],
  reducers:{
    GetProviderExistingData(state,action){
      return (action.payload)
    },
    ResetProviderExistingData(){
      return []
    },
  }
}) 
export const {GetProviderExistingData,ResetProviderExistingData}=ProviderExistingUserData.actions;
export default ProviderExistingUserData.reducer;