import React from 'react';
import ThreeDotLoader from './ThreeDotLoader';

const FullscreenLoader = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="text-center text-white">
        <ThreeDotLoader />
        <div>Please wait!</div>
      </div>
    </div>
  );
  
  export default FullscreenLoader;
