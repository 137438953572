import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../../../../common/element/Table";
import Button from "../../../../../common/element/Buttons";
import Tooltip from "../../../../../common/element/Tooltip";
import ScrollBar from "../../../../../common/element/ScrollBar/ScrollBar";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import SmallButton from "../../../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
// import { notify } from "react-notify-toast";

// import {ReinviteClientUser} from '../../../../../../actions/client-portal/ReInviteUser';
// import {ReinviteOrgUser, RemoveOrgUserInvitation, GetAllSpecificOrgInvites} from '../../../../../../actions/client-portal/AllUsersApi';
import { GetAllSpecificOrgInvites, RemoveOrgUserInvitation, ReinviteOrgUser } from "../../../../../actions/provider-portal/AllUserAPIs";
import moment from "moment";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
const { Tr, Th, Td, THead, TBody } = Table;
const InvitedUser = (props) => {
   const dispatch = useDispatch()
   const handleUnauthorizedAccess = useUnauthorizedHandler()
   const [dialogDeleteOpen, setDeleteIsOpen] = useState(false)
   const [dialogIsOpen, setIsOpen] = useState(false)
   const [contactTypeId, setContactTypeId] = useState("");
   const [inviteId, setInviteId] = useState("");

   let function_assignment_id;
   let trading_name;
   const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
   if (storedData !== null) {
      trading_name = storedData.trading_name;
      function_assignment_id = storedData.function_assignment_id;
   }

   const AllContactType = useSelector((state) => state?.AllContactTypes);
   useEffect(() => {
      if (AllContactType) {
         const contactTypeId = AllContactType.find(
            (item) => item.contact_type === "client contact"
         )?.contact_type_id;

         setContactTypeId(contactTypeId);
      }
   }, []);

   const openDialog = () => {
      setIsOpen(true)
   }
   const onDialogClose = () => {
      setIsOpen(false)
   }
   const openDeleteDialog = (e) => {
      setDeleteIsOpen(true)
      setInviteId(e.invite_id)
   }
   const closeInvitation = () => {
      setDeleteIsOpen(false)
   }

   const ReInviteUser = (e) => {
      const payload = {
         invite_id: e.invite_id,
         trading_name: trading_name,
         first_name: e.invite_individual.first_name,
         last_name: e.invite_individual.last_name,
         email: e.invite_individual.email,
         contact_type_id: contactTypeId,
         invited_user_type: "client_user"
      };

      dispatch(ReinviteOrgUser(payload))
         .then((response) => {
            if (response.success) {
               openDialog();
            } else if (response.status === 401) {
               handleUnauthorizedAccess()
            }
         })
         .catch((error) => {
         });
   };


   // to remove Invited user
   const RemoveUserInvite = () => {
      const payload = {
         invite_id: inviteId,
         function_assignment_id: function_assignment_id
      };
      dispatch(RemoveOrgUserInvitation(payload))
         .then((response) => {
            if (response.success) {
               closeInvitation()
               setInviteId('')
               dispatch(GetAllSpecificOrgInvites({ function_assignment_id: function_assignment_id, invited_user_type: "provider_user" })).then(
                  (response) => {
                     if (response.success) {
                        const arrayData = response.data;
                        props.onInvitedUser(arrayData);
                     } else if (response.status === 401) {
                        handleUnauthorizedAccess()
                     }
                  }
               );
            } else if (response.status === 401) {
               handleUnauthorizedAccess()
            }
         })
         .catch((error) => {
         });
   };


   return (
      <React.Fragment>
         <div className="flex justify-between mb-5 mt-5">
            <h5 className="text-xl text-gray-900 font-semibold">Invited</h5>
         </div>
         <div className="overflow-hidden border rounded-lg border-gray-200 custom-scrollbar">
            <Table>
               <THead>
                  <Tr>
                     <Th className="w-1/3">User name</Th>
                     <Th className="w-1/3">Email</Th>
                     <Th>
                        <div className="flex items-center">
                           Sent
                           <Tooltip title="Account Billing contact is responsible for billing and payment on the account." placement="top-start">
                              <div className="text-center cursor-pointer">
                                 <img src="/assets/icons-svg/help-circle.svg" alt="" className="ml-2" />
                              </div>
                           </Tooltip>
                        </div>
                     </Th>
                     <Th className="w-48">
                     </Th>
                  </Tr>
               </THead>
               <TBody>
                  {props.invitedUser && props?.invitedUser?.map((item) => {
                     // Add days to the validSDays
                     const currentTime = moment();
                     const active = moment.duration(currentTime.diff(item.invite_date))
                     const activeDate = item?.invite_date
                        ? (active.asSeconds() < 60
                           ? "Just now"
                           : active.asMinutes() < 60
                              ? `${Math.floor(active.asMinutes())} ${active.asMinutes() === 1 ? "minute" : "minutes"
                              } ago`
                              : active.asHours() < 24
                                 ? `${Math.floor(active.asHours())} ${active.asHours() === 1 ? "hour" : "hours"
                                 } ago`
                                 : active.asDays() < 2
                                    ? "Yesterday"
                                    : `${Math.floor(active.asDays())} ${active.asDays() === 1 ? "day" : "days"
                                    } ago`)
                        : "";
                     return (
                        <Tr>
                           <Td>{item?.invite_individual?.first_name + ' ' + item?.invite_individual?.last_name}</Td>
                           <Td>{item?.invite_individual?.email}</Td>
                           <Td>{activeDate}</Td>
                           <Td>
                              <span className="flex gap-4 justify-center">
                                 {/*permission for Read and Write */}
                                 <Button disabled={props?.permission_data?.users_write == true ? false : true}
                                    onClick={() =>
                                       openDeleteDialog(item)} >
                                    <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5" stroke="#475467" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                 </Button>
                                 <Button
                                    disabled={props?.permission_data?.users_write == true ? false : true}
                                    onClick={() =>
                                       ReInviteUser(item)}>
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z" stroke="#475467" strokeWidth="1.667" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                 </Button>
                              </span>
                           </Td>
                        </Tr>)
                  })}

               </TBody>
            </Table>
         </div>
         <Dialog
            isOpen={dialogDeleteOpen}
            onClose={closeInvitation}
            onRequestClose={closeInvitation}
            bodyOpenClassName="overflow-hidden"
            className="customAlert"
         >
            <div className="px-6 pt-6">
               <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
               </span>
               <h4 className="text-base text-gray-900 font-semibold text-center mt-4">Remove Invitation?</h4>
               <p className=" text-gray-600 text-center mt-1">
                  Removing this invitation will cancel their access to the app.
               </p>
            </div>
            <div className='mt-8 flex justify-between pb-6 px-6'>
               <BtnLight className='mr-1.5' >Cancel</BtnLight>
               <SmallButton onClick={() => RemoveUserInvite()} className='ml-1.5'>Confirm</SmallButton>
            </div>
         </Dialog>

         <Dialog
            isOpen={dialogIsOpen}
            onClose={onDialogClose}
            onRequestClose={onDialogClose}
            bodyOpenClassName="overflow-hidden"
            className="customAlert"
         >
            <div className="px-6 pt-6">
               <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z" stroke="#101828" strokeWidth="1.667" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
               </span>
               <h4 className="text-base text-gray-900 font-semibold text-center mt-4">Invite sent successfully!</h4>
            </div>
            <div className='mt-8 flex justify-between pb-6 px-6'>
               <SmallButton onClick={() => onDialogClose()} >Confirm</SmallButton>
            </div>
         </Dialog>


      </React.Fragment>
   )
}

export default InvitedUser