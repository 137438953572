import React, { useCallback, useEffect, useState } from "react";
import ContactsList from "./ContractorsList";
import Select from "../../../../../../common/element/Select/Select";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { debounce } from "../../../../../../common/snippet/Debouncing";
import ClientInput from "../../../../../../common/element/Input/clientInput";
import { Pagination } from "../../../../../../common/pagination";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import { GetAllProvidersOfWorker } from "../../../../../../actions/admin-worker/GetAllContractorsOfSpecificWorker";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import ThreeDotBouncing from "../../../../../../common/element/PageLoader";
const Providers = () => {
  const GetFunction = useSelector((state) => state);
  const { id } = useParams();

  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(1);
  const [countData, setCountData] = useState(0);
  const [apiCalls, setApiCalls] = useState(true);
  const [loadData, setLoadData] = useState(false);
  const AllContactType = useSelector((state) => state?.AllContactTypes);
  const [providerAllData, setProviderAllData] = useState([]);
  const { workerRedirectSpecificData } = useSelector((state) => state);
  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    contact_type_id: AllContactType?.filter(
      (item) => item?.contact_type == "provider primary"
    )?.[0]?.contact_type_id,
    individual_id: workerRedirectSpecificData?.individual_id
      ? workerRedirectSpecificData?.individual_id
      : "", //change this individual id this is static
    page: 1,
    limit: 10,
    sort: "trading_name",
    order: "desc",
    search: "",
  });

  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Worker",
          url: "/admin/worker/view-all-worker",
          home: "/admin/worker/dashboard",
        },
        {
          label: `${workerRedirectSpecificData?.first_name || ""} ${
            workerRedirectSpecificData?.last_name || ""
          }`,
          url: `/admin/worker/view-all-worker/specific-worker/${encodeURIComponent(
            id
          )}`,
        },
        {
          label: "Providers",
          url: `/admin/worker/view-all-worker/specific-worker/${encodeURIComponent(
            id
          )}`,
        },
      ])
    );
  }, []);
  const GetAllProvidersOfWorkerData = useCallback(() => {
    dispatch(GetAllProvidersOfWorker(getAllinvitedData))
      .then((response) => {
        if (response?.success) {
          const arrayData = response?.data;
          setCountData(response?.data?.count);
          setProviderAllData(arrayData?.rows);
          setTotalPages(Math.ceil(countData / getAllinvitedData.limit));
          setLoadData(true);
        } else {
          // Handle unsuccessful response or any other response status
          setLoadData(true);
          console.log("Failed to fetch data or handle response properly.");
        }
      })
      .catch((error) => {
        setLoadData(true);
        console.error("Error fetching providers data:", error);
      });
  }, [dispatch, getAllinvitedData]);

  useEffect(() => {
    GetAllProvidersOfWorkerData();
  }, [getAllinvitedData]);

  const handleSort = (column) => {
    if (column === getAllinvitedData.sort) {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    } else {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
    }
  };
  const updatePageLimit = (newLimit) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      SetgetAllinvitedData((prevState) => ({
        ...prevState,
        limit: newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10,
        page: 1,
      }));
      setTotalPages(
        Math.ceil(
          countData / newLimit?.limit
            ? newLimit?.limit
            : newLimit
            ? newLimit
            : 10
        )
      );
    }
  };

  const updatePage = (newPage) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      SetgetAllinvitedData((prevState) => ({
        ...prevState,
        page: newPage?.page ? newPage?.page : newPage ? newPage : 1,
      }));
    }
  };
  const handlePageChange = ({ selected }) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(Math.ceil(countData / getAllinvitedData.limit));
  };

  // Search Data
  const handleSearch = (e) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value.trim(),
      };
    });
  };

  const inputchangehandler = useCallback(debounce(handleSearch), []);
  const getPageNumber = () => {
    setTotalPages(Math.ceil(countData / getAllinvitedData.limit));
  };
  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5 px-2">
        <div className="flex gap-2">
          <div className="w-80 h-10">
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <img
                  src="/assets/icons-svg/search-lg.svg"
                  alt=""
                  className="h-5"
                />
              </div>
              <ClientInput
                type="text"
                className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                inputchangehandler={inputchangehandler}
                name={"search"}
              />
            </div>
          </div>
        </div>
      </div>
      <ContactsList
        providerAllData={providerAllData}
        handleSort={handleSort}
        getAllinvitedData={getAllinvitedData}
      />
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {loadData && (
        <div
          className="flex justify-between mt-3"
          style={{ alignItems: "Right", listStyle: "none", padding: 0 }}
        >
          <Pagination
            totalCount={countData}
            pageNumber={getAllinvitedData?.page}
            displayPage={providerAllData?.length}
            UpdatePageLimit={updatePageLimit}
            UpdatePage={updatePage}
            // UpdatePageLimit={SetgetAllinvitedData}
            // allfilters={getAllinvitedData}
          />
          {countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={getAllinvitedData?.page - 1}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Providers;
