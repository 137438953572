import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { GetClientOverviewProfileById } from "../../../../actions/provider-portal/GetClientOverviewProfileById";

const ClientDetails = ({ indivisualData }) => {
  const [getCLientData, SetGetCLientData] = useState("");
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      GetClientOverviewProfileById({
        organisation_id: indivisualData?.organisation_id,
        provider_org_id: loginUserData?.organisation_id,
      })
    ).then((response) => {
      if (response.success) {
        SetGetCLientData(response.data);
      }
    });
  }, []);

  return (
    <div className="flex w-full">
      <div className="border h-72 border-gray-300 rounded-lg w-56 flex items-center text-center justify-center">
        {/* <span className="text-xs text-gray-400 font-semibold"> */}
        {/* Client logo here, */}
        <img src={getCLientData?.logo} />
        {/* <br /> if available */}
        {/* </span> */}
      </div>
      <div className="flex w-4/6 pl-5">
        <div className="w-3/6">
          <div className="flex flex-col mb-5">
            <label className="text-xs font-medium">Client name</label>
            <div className="text-gray-900 font-semibold">
              {/* [Client name here] */}
              {getCLientData?.trading_name}
            </div>
          </div>
          <div className="flex flex-col mb-5">
            <label className="text-xs font-medium">Email address</label>
            <div className="text-gray-900 font-semibold">
              {getCLientData?.provClientCont?.contact_email
                ? getCLientData?.provClientCont?.contact_email
                : "-"}
            </div>
          </div>
          <div className="flex flex-col mb-5">
            <label className="text-xs font-medium">Website</label>
            <Link
              to={getCLientData?.website_url}
              className="text-xs text-blue-700 flex gap-1"
            >
              {getCLientData?.website_url}
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7"
                  stroke="#5786cc"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
          <div className="flex flex-col mb-5">
            <div class="flex mb-0">
              <div class="flex-1">
                <span class="text-gray-400 font-semibold block text-sm mb-1">
                  Address1
                </span>
                <span class="text-gray-900 font-medium block text-sm">
                  {getCLientData?.org_address_data?.[0]?.address_one}
                </span>
              </div>
              <div class="flex-1">
                <span class="text-gray-400 font-semibold block text-sm mb-1">
                  Address2
                </span>
                <span class="text-gray-900 font-medium block text-sm">
                  {getCLientData?.org_address_data?.[0]?.address_two}
                </span>
              </div>
            </div>
            <div class="flex mb-0">
              <div class="flex-1">
                <span class="text-gray-400 font-semibold block text-sm mb-1">
                  Country
                </span>
                <span class="text-gray-900 font-medium block text-sm">
                  {getCLientData?.org_address_data?.[0]?.country_name}
                </span>
              </div>
              <div class="flex-1">
                <span class="text-gray-400 font-semibold block text-sm mb-1">
                  State
                </span>
                <span class="text-gray-900 font-medium block text-sm">
                  {getCLientData?.org_address_data?.[0]?.state_name}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-3/6">
          <div className="flex flex-col mb-5">
            <label className="text-xs font-medium">ABN/NZBN</label>
            <div className="text-gray-900 font-semibold">
              {getCLientData?.abn_nzbn}
            </div>
          </div>
          <div className="flex flex-col mb-5">
            <label className="text-xs font-medium">Contact number</label>
            <div className="text-gray-900 font-semibold">
              {getCLientData?.provClientCont?.contact_phone
                ? getCLientData?.provClientCont?.contact_phone
                : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDetails;
