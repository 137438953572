import React from "react";
import Menu from "../../../../../common/element/Menu";
import { NavLink } from "react-router-dom";
const ClientMenu = () => {
  const handleToggle = (expanded, e) => {
    
  };

  return (
    <div className="" style={{ maxWidth: 176 }}>
      <Menu>
        <Menu.MenuItem eventKey="item-1"><NavLink to="client/dashboard">Dashboard</NavLink></Menu.MenuItem>
        <Menu.MenuItem eventKey="item-2"><NavLink to="client/view-all-clients">View All</NavLink> </Menu.MenuItem>
        <Menu.MenuItem eventKey="item-3"><NavLink to="client/invoices">Invoices</NavLink> </Menu.MenuItem>
        <Menu.MenuItem eventKey="item-4"><NavLink to="client/reports">Reports</NavLink> </Menu.MenuItem>

        {/* <Menu.MenuCollapse
          eventKey="item-3"
          label="Reports"
          onToggle={handleToggle}
        >
          <Menu.MenuItem eventKey="item-4"><NavLink to="item-one">Item 3.1</NavLink> </Menu.MenuItem>
          <Menu.MenuItem eventKey="item-5"><NavLink to="item-two">Item 3.2</NavLink> </Menu.MenuItem>
        </Menu.MenuCollapse> */}
      </Menu>
    </div>
  );
};

export default ClientMenu;
