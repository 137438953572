import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { debounce } from "../../../../common/snippet/Debouncing";
import * as userActions from "../../../../actions/index";
import { setFunctionAssignmentUuid } from "../../../../actions/index";
import {
  setClientSpecificData,
  resetclientSpecificData,
} from "../../../../redux/classes/Admin-Client/specificClientDatashow";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
import moment from "moment";
import Heading from "../../../../common/element/Heading";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../common/element/Dialog/Dialog";
import AddClientForm from "./Dialog/AddClient";
import Table from "../../../../common/element/Table";

import searchIcon from "../../../../assets/icons-svg/search-lg.svg";
import plusIcon from "../../../../assets/icons-svg/plus.svg";
import rightIcon from "../../../../assets/icons-svg/chevron-right.svg";
import buildingIcon from "../../../../assets/icons-svg/building.svg";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../common/pagination";
import { encryptId } from "../../../../common/encryptDecrypt/encryptdecrypt";
import ThreeDotBouncing from "../../../../common/element/PageLoader";
// import { toggleButtonClasses } from "@mui/material";

const ViewAllClients = (props) => {
  // let location = useLocation();
  const dispatch = useDispatch();
  // const { id } = useParams();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  let role_assignment_id;
  if (loginData) {
    role_assignment_id = loginData?.data?.role_assignment_id;
  }
  const [filtersort, setFiltersort] = useState("trading_name");
  const [loadData, setLoadData] = useState(false);
  const [filterorder, setFilterOrder] = useState("asc");
  const [filterpage, setfilterpage] = useState(1);
  const [filterlimit, setfilterlimit] = useState(10);
  const [searchTerm, setsearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [countData, setCountData] = useState(0);
  const [clientData, setClientData] = useState([]);
  const [checkSort, setCheckSort] = useState({
    createdDate: false,
    renewalDate: false,
  });
  const [toggleState, setToggleState] = useState(
    Array(clientData.length).fill(false)
  );
  const [toggleStateChild, setToggleStateChild] = useState([]);
  // const [toggleStateChild, setToggleStateChild] = useState(Array(clientData.length).fill(null).map(() => []));
  const [dialogIsOpen, setIsOpen] = useState(false);

  const [functionId, setfunctionId] = useState("");

  // const [contactTypeId, setContactTypeId] = useState("");
  //state to show & hide child
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedChildRows, setExpandedChildRows] = useState([]);
  // Redux selector to get contact types
  const AllContactType = useSelector((state) => state?.AllContactTypes);
  // Redux selector to get all functions
  const AllFunctions = useSelector((state) => state.allFunctions);
  useEffect(() => {
    // Set breadcrumbs for the page
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Clients",
          url: "/admin/client/view-all-clients",
          home: "/admin/client/dashboard",
        },
      ])
    );
    dispatch(resetclientSpecificData());
  }, []);
  // useEffect(() => {
  //   // Find the contact type ui for "client primary"
  //   if (AllContactType) {
  //     const contactTypeid = AllContactType.find(
  //       (item) => item?.contact_type === "client primary"
  //     )?.contact_type_id;
  //     setContactTypeId(contactTypeid);
  //   }
  // }, []);

  const openDialog = () => {
    setIsOpen(true);
  };

  const onDialogClose = (e) => {
    if (!buttonDisabled) {
      setIsOpen(false);
      AdminGetAllClients(functionId);
    }
  };
  // Function to toggle the main rows
  const toggleClick = (index) => {
    const newToggleStates = [...toggleState];
    newToggleStates[index] = !newToggleStates[index];
    setToggleState(newToggleStates);
    // Close all child rows when closing a parent row
    setExpandedChildRows({});

    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = [...prevExpandedRows];
      newExpandedRows[index] = !newExpandedRows[index];
      return newExpandedRows;
    });
  };

  // const toggleClick = (index) => {
  //   // Toggle the main row
  //   const newToggleStates = [...toggleState];
  //   newToggleStates[index] = !newToggleStates[index];
  //   setToggleState(newToggleStates);

  //   // Close all child rows when closing a parent row
  //   setExpandedChildRows({});

  //   // Toggle the parent row
  //   setExpandedRows((prevExpandedRows) => {
  //     const newExpandedRows = { ...prevExpandedRows };

  //     // Close all other open parent items
  //     Object.keys(newExpandedRows).forEach((rowKey) => {
  //       if (rowKey !== index.toString()) {
  //         delete newExpandedRows[rowKey];
  //       }
  //     });

  //     // Open or close the current parent item
  //     if (newExpandedRows[index]) {
  //       delete newExpandedRows[index];
  //     } else {
  //       newExpandedRows[index] = true;
  //     }

  //     return newExpandedRows;
  //   });
  // };

  // Function to toggle the child rows
  const toggleClickChild = (parentIndex, childIndex) => {
    const newToggleChildStates = [...toggleStateChild];
    newToggleChildStates[parentIndex][childIndex] =
      !newToggleChildStates[parentIndex][childIndex];
    setToggleStateChild(newToggleChildStates);

    const key = `${parentIndex}-${childIndex}`;
    setExpandedChildRows((prevExpandedChildRows) => {
      const newExpandedChildRows = { ...prevExpandedChildRows };

      // Close all other open childL1 items
      Object.keys(newExpandedChildRows).forEach((rowKey) => {
        if (rowKey.startsWith(`${parentIndex}-`) && rowKey !== key) {
          delete newExpandedChildRows[rowKey];
        }
      });

      // Open or close the current childL1 item
      if (newExpandedChildRows[key]) {
        delete newExpandedChildRows[key];
      } else {
        newExpandedChildRows[key] = true;
      }

      return newExpandedChildRows;
    });
  };

  useEffect(() => {
    if (clientData.length > 0) {
      // Initialize toggleStateChild based on clientData length
      setToggleStateChild(
        Array(clientData.length)
          .fill(null)
          .map(() => [])
      );
    }
  }, [clientData]);

  const handleSeachChange = (e) => {
    setsearchTerm(e.target.value.trim());
    // setFilterState((prevForm) => ({
    //   ...prevForm,
    //   search: e.target.value,
    //   page: 1
    // }));
    setfilterpage(1);
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);

  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
    // setFilterState((prevForm) => ({
    //   ...prevForm,
    //   page: selected + 1
    // }));
    // AdminGetAllClients(selected + 1, filterlimit);
  };

  const handleSort = (column) => {
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };

  const handleSortRenewal = (column) => {
    if (column === "renewal_date") {
      setCheckSort((preState) => ({
        ...preState,
        renewalDate: true,
        createdDate: false,
      }));
    } else {
      setCheckSort((preState) => ({
        ...preState,
        createdDate: true,
        renewalDate: false,
      }));
    }
    setFiltersort("created_date");
    setFilterOrder(filterorder === "asc" ? "desc" : "asc");
  };
  // Handle clicking on a specific client
  const handleSpecificClient = (data) => {
    dispatch(
      setClientSpecificData({
        abn_nzbn: data?.abn_nzbn,
        logo: data?.logo,
        trading_name: data?.trading_name,
        is_org_active: data?.is_org_active,
      })
    );
    dispatch(
      setFunctionAssignmentUuid(
        data?.function_assignments_id ? data?.function_assignments_id : data
      )
    );
  };

  useEffect(() => {
    if (AllFunctions?.allFunction) {
      let functionType = AllFunctions?.allFunction.filter(
        (item) => item?.function_name === "client"
      );
      setfunctionId(functionType[0]?.function_id);
    }
  }, []);

  function addOneYear(dateString) {
    const date = moment(dateString);
    if (!date.isValid()) {
      throw new Error("Invalid date");
    }
    const newDate = date?.add(1, "year");
    return newDate?.toISOString();
  }

  const AdminGetAllClients = useCallback(
    async (functionId) => {
      if (functionId) {
        dispatch(
          userActions.AdminGetAllClients(
            // contactTypeId,
            functionId,
            searchTerm,
            filterpage,
            filterlimit,
            filtersort,
            filterorder
          )
        ).then((response) => {
          if (response?.success === true) {
            setCountData(response?.data.count);
            const arrayData = response?.data.rows;
            let client_data = [];
            for (let key in arrayData) {
              client_data.push({
                // function_assignments_id:
                //   arrayData[key]?.function_assignments[0]?.function_assignment_id,
                function_assignments_id:
                  arrayData[key]?.org_fun_assign?.function_assignment_id,
                trading_name: arrayData[key]?.trading_name,
                abn_nzbn: arrayData[key]?.abn_nzbn,
                logo: arrayData[key]?.logo,
                organisation_id: arrayData[key]?.organisation_id,
                is_child: arrayData[key]?.org_fun_assign?.fa_relation_data[0]
                  ? true
                  : false,
                is_child_child: arrayData[key]?.org_fun_assign
                  ?.fa_relation_data[0]?.fa_child_data?.fa_relation_data[0]
                  ? true
                  : false,
                child_data: arrayData[key]?.org_fun_assign?.fa_relation_data,
                is_org_active: arrayData[key]?.is_org_active,
                created_date: arrayData[key]?.created_date,
                renewal_date: addOneYear(arrayData[key]?.created_date),
              });
            }
            setClientData(client_data);
            setLoadData(true);
            setTotalPages(Math.ceil(response?.data?.count / filterlimit));
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
            setLoadData(true);
          } else {
            setLoadData(true);
          }
        });
      }
      // eslint-disable-next-line
    },
    [
      dispatch,
      // contactTypeId,
      searchTerm,
      filterpage,
      filterlimit,
      filtersort,
      filterorder,
    ]
  );

  useEffect(() => {
    AdminGetAllClients(functionId);
  }, [
    functionId,
    searchTerm,
    filterpage,
    filterlimit,
    filtersort,
    filterorder,
  ]);

  //call permission api
  // const GetSpecificUserRole = useCallback(async () => {
  //   if (role_assignment_id) {
  //     dispatch(userActions.GetSpecificUserRole(role_assignment_id)).then(
  //       (response) => {
  //         if (response?.success === true) { }
  //         else if (response?.status === 401) {
  //           handleUnauthorizedAccess()
  //         }
  //       }
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [dispatch]);
  // useEffect(() => {
  //   GetSpecificUserRole()
  // }, [])

  const { Tr, Th, Td, THead, TBody } = Table;
  return (
    <React.Fragment>
      <div className="flex items-center justify-between mb-7 gap-3 flex-wrap">
        <Heading>View All Clients</Heading>
        <div className="flex flex-wrap gap-3">
          <div className="w-80 h-10">
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <img src={searchIcon} alt="" className="h-5" />
              </div>
              <input
                type="text"
                name="hs-table-search"
                className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                onChange={(e) => inputchangeSearch(e)}
              />
            </div>
          </div>
          <BtnPrimary
            className="w-29 flex items-center justify-center text-xs"
            variant="solid"
            onClick={() => openDialog()}
          >
            Add client
            <span className="ml-3">
              <img src={plusIcon} alt="" className="h-5" />
            </span>
          </BtnPrimary>
        </div>
      </div>
      <div className="w-full inline-block align-middle">
        <div className="border rounded-lg border-gray-200">
          <Table className="min-w-full divide-y divide-gray-200">
            <THead className="bg-gray-100">
              <Tr>
                <Th scope="col" className="w-2/5">
                  <span
                    className="flex items-center gap-1 "
                    onClick={() => handleSort("trading_name")}
                  >
                    Client name
                    <svg
                      className={`transform ${
                        filtersort === "trading_name" && filterorder === "desc"
                          ? "rotate-180"
                          : ""
                      }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      transform="rotate(180)"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        strokeWidth="1.333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Th>

                <Th scope="col">Status</Th>
                <Th scope="col">
                  <span
                    className="flex items-center gap-1"
                    onClick={() => handleSortRenewal("created_date")}
                  >
                    Date joined
                    <svg
                      className={`transform ${
                        filtersort === "created_date" &&
                        checkSort?.createdDate &&
                        filterorder === "desc"
                          ? "rotate-180"
                          : ""
                      }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        strokeWidth="1.333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Th>
                <Th scope="col">
                  <span
                    className="flex items-center gap-1"
                    onClick={() => handleSortRenewal("renewal_date")}
                  >
                    Renewal date
                    <svg
                      className={`transform ${
                        filtersort === "created_date" &&
                        checkSort?.renewalDate &&
                        filterorder === "desc"
                          ? "rotate-180"
                          : ""
                      }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        strokeWidth="1.333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Th>
              </Tr>
            </THead>
            <TBody className="divide-y divide-gray-200">
              {clientData &&
                clientData.map((item, index) => {
                  const encryptedId = encryptId(item?.organisation_id);
                  return (
                    <React.Fragment key={index}>
                      <Tr>
                        <Td>
                          <div className="flex items-center">
                            {item?.is_child && (
                              <button
                                onClick={() => toggleClick(index)}
                                className={`bg-gray-50 rounded-full h-6 w-6 p-0.5 mr-2 ${
                                  toggleState[index] ? "rotate-90" : ""
                                }`}
                              >
                                <img src={rightIcon} alt="" />
                              </button>
                            )}

                            <Link
                              to={`specific-client/${encodeURIComponent(
                                encryptedId
                              )}`}
                              onClick={() => handleSpecificClient(item)}
                            >
                              {item?.trading_name ? item?.trading_name : "-"}
                            </Link>
                          </div>
                        </Td>
                        <Td>
                          <Link
                            to={`specific-client/${encodeURIComponent(
                              encryptedId
                            )}`}
                            onClick={() => handleSpecificClient(item)}
                          >
                            <span
                              className={
                                item?.is_org_active
                                  ? "badge bg-success-50 text-success-700 py-0.5 px-2 rounded-lg font-medium"
                                  : " badge bg-error-100 text-error-700 py-0.5 px-2 rounded-lg font-medium"
                              }
                            >
                              {item?.is_org_active ? "Active" : "Inactive"}
                            </span>
                          </Link>
                        </Td>
                        <Td>
                          <Link
                            to={`specific-client/${encodeURIComponent(
                              encryptedId
                            )}`}
                            onClick={() => handleSpecificClient(item)}
                          >
                            <span>
                              {item?.created_date
                                ? moment(item?.created_date)?.format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </span>
                          </Link>
                        </Td>
                        <Td>
                          <Link
                            to={`specific-client/${encodeURIComponent(
                              encryptedId
                            )}`}
                            onClick={() => handleSpecificClient(item)}
                          >
                            <span>
                              {item?.renewal_date
                                ? moment(item?.renewal_date)?.format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </span>
                          </Link>
                        </Td>
                      </Tr>
                      {item?.is_child &&
                        expandedRows[index] &&
                        item?.child_data.map((childL1, indx) => {
                          const encryptedId = encryptId(
                            childL1?.fa_child_data?.organisation_id
                          );
                          return (
                            <React.Fragment key={indx}>
                              <Tr>
                                <Td>
                                  <div className="flex items-center">
                                    <span className="h-6 w-6 mr-2 gray-line-set relative"></span>
                                    {item?.is_child_child &&
                                      childL1?.fa_child_data
                                        ?.fa_relation_data[0] && (
                                        <button
                                          onClick={() =>
                                            toggleClickChild(index, indx)
                                          }
                                          className={`bg-gray-50 rounded-full h-6 w-6 p-0.5 mr-2 }`}
                                        >
                                          {expandedChildRows[
                                            `${index}-${indx}`
                                          ] ? (
                                            <img
                                              className={`rotate-90`}
                                              src={rightIcon}
                                              alt=""
                                            />
                                          ) : (
                                            <div>
                                              <img
                                                className={`rotate-270`}
                                                src={rightIcon}
                                                alt=""
                                              />
                                            </div>
                                          )}
                                        </button>
                                      )}
                                    <Link
                                      to={`specific-client/${encodeURIComponent(
                                        encryptedId
                                      )}`}
                                      onClick={() =>
                                        handleSpecificClient(
                                          childL1?.fa_child_data
                                            ?.function_assignment_id
                                        )
                                      }
                                    >
                                      {
                                        childL1?.fa_child_data?.org_data
                                          ?.trading_name
                                      }
                                    </Link>
                                  </div>
                                </Td>

                                <Td>
                                  <Link
                                    to={`specific-client/${encodeURIComponent(
                                      encryptedId
                                    )}`}
                                    onClick={() =>
                                      handleSpecificClient(
                                        childL1?.fa_child_data
                                          ?.function_assignment_id
                                      )
                                    }
                                  >
                                    <span
                                      className={
                                        childL1?.fa_child_data?.org_data
                                          ?.is_org_active
                                          ? "badge bg-success-50 text-success-700 py-0.5 px-2 rounded-lg font-medium"
                                          : " badge bg-error-100 text-error-700 py-0.5 px-2 rounded-lg font-medium"
                                      }
                                    >
                                      {childL1?.fa_child_data?.org_data
                                        ?.is_org_active
                                        ? "Active"
                                        : "Inactive"}
                                    </span>
                                  </Link>
                                </Td>
                                <Td>
                                  <Link
                                    to={`specific-client/${encodeURIComponent(
                                      encryptedId
                                    )}`}
                                    onClick={() =>
                                      handleSpecificClient(
                                        childL1?.fa_child_data
                                          ?.function_assignment_id
                                      )
                                    }
                                  >
                                    <span>
                                      {childL1?.fa_child_data?.org_data
                                        ?.created_date
                                        ? moment(
                                            childL1?.fa_child_data?.org_data
                                              ?.created_date
                                          )?.format("DD/MM/YYYY")
                                        : "-"}
                                    </span>
                                  </Link>
                                </Td>
                                <Td>
                                  <Link
                                    to={`specific-client/${encodeURIComponent(
                                      encryptedId
                                    )}`}
                                    onClick={() =>
                                      handleSpecificClient(
                                        childL1?.fa_child_data
                                          ?.function_assignment_id
                                      )
                                    }
                                  >
                                    <span>
                                      {childL1?.fa_child_data?.org_data
                                        ?.created_date
                                        ? moment(
                                            addOneYear(
                                              childL1?.fa_child_data?.org_data
                                                ?.created_date
                                            )
                                          )?.format("DD/MM/YYYY")
                                        : "-"}
                                    </span>
                                  </Link>
                                </Td>
                              </Tr>

                              {/*  */}
                              {item?.is_child_child &&
                                expandedChildRows[`${index}-${indx}`] &&
                                childL1?.fa_child_data?.fa_relation_data?.map(
                                  (childL2, idx) => {
                                    const encryptedId = encryptId(
                                      childL2?.fa_child_data?.organisation_id
                                    );
                                    return (
                                      <React.Fragment key={idx}>
                                        <Tr>
                                          <Td>
                                            <div className="flex items-center">
                                              <span className="h-6 w-6 mr-2 gray-line-set relative"></span>
                                              <button
                                                className={` rounded-full h-6 w-6 p-0.5 mr-2 `}
                                              ></button>
                                              <Link
                                                to={`specific-client/${encodeURIComponent(
                                                  encryptedId
                                                )}`}
                                                onClick={() =>
                                                  handleSpecificClient(
                                                    childL2?.fa_child_data
                                                      ?.function_assignment_id
                                                  )
                                                }
                                              >
                                                {
                                                  childL2?.fa_child_data
                                                    ?.org_data?.trading_name
                                                }
                                              </Link>
                                            </div>
                                          </Td>

                                          <Td>
                                            <Link
                                              to={`specific-client/${encodeURIComponent(
                                                encryptedId
                                              )}`}
                                              onClick={() =>
                                                handleSpecificClient(
                                                  childL2?.fa_child_data
                                                    ?.function_assignment_id
                                                )
                                              }
                                            >
                                              <span
                                                className={
                                                  childL2?.fa_child_data
                                                    ?.org_data?.is_org_active
                                                    ? "badge bg-success-50 text-success-700 py-0.5 px-2 rounded-lg font-medium"
                                                    : " badge bg-error-100 text-error-700 py-0.5 px-2 rounded-lg font-medium"
                                                }
                                              >
                                                {childL2?.fa_child_data
                                                  ?.org_data?.is_org_active
                                                  ? "Active"
                                                  : "Inactive"}
                                              </span>
                                            </Link>
                                          </Td>
                                          <Td>
                                            <Link
                                              to={`specific-client/${encodeURIComponent(
                                                encryptedId
                                              )}`}
                                              onClick={() =>
                                                handleSpecificClient(
                                                  childL2?.fa_child_data
                                                    ?.function_assignment_id
                                                )
                                              }
                                            >
                                              <span>
                                                {childL2?.fa_child_data
                                                  ?.org_data?.created_date
                                                  ? moment(
                                                      childL2?.fa_child_data
                                                        ?.org_data?.created_date
                                                    )?.format("DD/MM/YYYY")
                                                  : "-"}
                                              </span>
                                            </Link>
                                          </Td>
                                          <Td>
                                            <Link
                                              to={`specific-client/${encodeURIComponent(
                                                encryptedId
                                              )}`}
                                              onClick={() =>
                                                handleSpecificClient(
                                                  childL2?.fa_child_data
                                                    ?.function_assignment_id
                                                )
                                              }
                                            >
                                              <span>
                                                {childL2?.fa_child_data
                                                  ?.org_data?.created_date
                                                  ? moment(
                                                      addOneYear(
                                                        childL2?.fa_child_data
                                                          ?.org_data
                                                          ?.created_date
                                                      )
                                                    )?.format("DD/MM/YYYY")
                                                  : "-"}
                                              </span>
                                            </Link>
                                          </Td>
                                        </Tr>
                                      </React.Fragment>
                                    );
                                  }
                                )}
                            </React.Fragment>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
            </TBody>
          </Table>
        </div>
      </div>
      {!loadData && (
        <div>
          <ThreeDotBouncing />
        </div>
      )}
      {loadData && (
        <div className="flex justify-between mt-3">
          <Pagination
            totalCount={countData || 0}
            pageNumber={filterpage}
            displayPage={clientData?.length}
            UpdatePageLimit={setfilterlimit}
            UpdatePage={setfilterpage}
          />
          {countData > 0 && (
            <ReactPaginate
              className="flex justify-content-end p-2 align-items-center pagination-wrap"
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLinkClassName={"prev-link"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"prev-link"}
              previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
              nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
              pageLinkClassName={"px-4 rounded-md"}
              activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
              disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              renderOnZeroPageCount={null}
              forcePage={filterpage - 1}
            />
          )}
        </div>
      )}
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={buildingIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Add a Client
            </h5>
            <p className="text-gray-600 ">
              Create a new Client into the Conserve system
            </p>
          </div>
        </div>

        <AddClientForm
          onDialogClose={onDialogClose}
          buttonDisabled={buttonDisabled}
          setButtonDisabled={setButtonDisabled}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default ViewAllClients;
