import Card from "../../../../../../../common/element/Card";
import Table from "../../../../../../../common/element/Table";
import Tag from "../../../../../../../common/element/Tag";
import Scrollbar from "../../../../../../../common/element/ScrollBar";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Button from "../../../../../../../common/element/Buttons";
import { GetAllOverviewSubmissionDocByProvider } from "../../../../../../../actions/provider-portal/worker/GetAllOverviewSubmissionDocByProvider";
import Badge from "../../../../../../../common/element/Badge";
const Documents = () => {
  const dispatch = useDispatch();
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));

  const { specificWorker } = useSelector((state) => state);
  const [clientData, SetclientData] = useState([]);
  const { Tr, Th, Td, THead, TBody } = Table;
  useEffect(() => {
    dispatch(
      GetAllOverviewSubmissionDocByProvider({
        provider_org_id: loginUserData?.organisation_id,
        worker_id: specificWorker?.worker_id,
      })
    ).then((response) => {
      if (response.success) {
        SetclientData(response.data);
      }
    });
  }, []);
  return (
    <Card className="py-6 border border-gray-200 rounded-xl dark-shadow">
      <div className="px-6">
        <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-3">
          <div className="flex items-center">
            <span className="mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M9 16L11 18L15.5 13.5M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <p></p>
            <div className="flex flex-col">
              <span className="text-gray-900 text-base font-semibold">
                Documents
              </span>
              <p className="text-gray-600">Action required </p>
            </div>
          </div>
          <BtnLight className="w-20 h-9">View All</BtnLight>
        </div>
      </div>
      <div className="h-64">
        <Scrollbar>
          <div className="px-6">
            <div className="overflow-hidden border rounded-lg border-gray-200">
              <Table>
                <TBody>
                  {clientData &&
                    clientData?.length > 0 &&
                    clientData?.map((dataItem, index) => (
                      <React.Fragment key={index}>
                        {dataItem?.workerCheckDocs &&
                          dataItem?.workerCheckDocs?.map((doc, docIndex) => (
                            <Tr>
                              <Td>
                                {doc?.workerDocAppr?.workerApprDoc?.doc_name
                                  ? doc?.workerDocAppr?.workerApprDoc?.doc_name
                                  : "-"}
                                <span className="block text-gray-600">
                                  {doc?.document_type?.doc_type_name
                                    ? doc?.document_type?.doc_type_name
                                    : "-"}
                                </span>
                              </Td>
                              {/* <Td className="w-28">
                                <Tag className="bg-error-100 text-error-700">
                                  Action required
                                </Tag>
                              </Td> */}
                              <Td>
                                {doc?.workerDocAppr?.approval_status ===
                                  "approved" && (
                                  <Badge
                                    className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                    content={"Approved"}
                                    innerClass="bg-success-100 text-success-700"
                                  />
                                )}
                                {doc?.workerDocAppr?.approval_status ===
                                  "client_approved_action" && (
                                  <div className="flex gap-3">
                                    <Badge
                                      className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                      content={"Approved"}
                                      innerClass="bg-success-100 text-success-700"
                                    />
                                    <svg
                                      width="16"
                                      height="17"
                                      viewBox="0 0 16 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M2.66675 14.5L2.66675 3.16667M2.66675 9.16667H7.60008C7.97345 9.16667 8.16013 9.16667 8.30274 9.094C8.42818 9.03009 8.53017 8.9281 8.59409 8.80266C8.66675 8.66005 8.66675 8.47337 8.66675 8.1V3.56667C8.66675 3.1933 8.66675 3.00661 8.59409 2.86401C8.53017 2.73856 8.42818 2.63658 8.30274 2.57266C8.16013 2.5 7.97345 2.5 7.60008 2.5H3.73341C3.36005 2.5 3.17336 2.5 3.03075 2.57266C2.90531 2.63658 2.80333 2.73856 2.73941 2.86401C2.66675 3.00661 2.66675 3.1933 2.66675 3.56667V9.16667ZM8.66675 3.83333H12.9334C13.3068 3.83333 13.4935 3.83333 13.6361 3.906C13.7615 3.96991 13.8635 4.0719 13.9274 4.19734C14.0001 4.33995 14.0001 4.52663 14.0001 4.9V9.43333C14.0001 9.8067 14.0001 9.99339 13.9274 10.136C13.8635 10.2614 13.7615 10.3634 13.6361 10.4273C13.4935 10.5 13.3068 10.5 12.9334 10.5H9.73341C9.36005 10.5 9.17336 10.5 9.03075 10.4273C8.90531 10.3634 8.80333 10.2614 8.73941 10.136C8.66675 9.99339 8.66675 9.8067 8.66675 9.43333V3.83333Z"
                                        stroke="#039855"
                                        stroke-width="1.33333"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </div>
                                )}
                                {doc?.workerDocAppr?.approval_status ===
                                  "client_approval_req" && (
                                  <Badge
                                    className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                    content={"Action required"}
                                    innerClass="bg-blue-100 text-blue-700"
                                  />
                                )}
                                {doc?.workerDocAppr?.approval_status ===
                                  "client_reject" && (
                                  <Badge
                                    className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                    content={"Reject"}
                                    innerClass="bg-error-100 text-error-700"
                                  />
                                )}
                                {doc?.workerDocAppr?.approval_status ===
                                  "admin_reject" && (
                                  <Badge
                                    className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                    content={"Reject"}
                                    innerClass="bg-error-100 text-error-700"
                                  />
                                )}
                                {doc?.workerDocAppr?.approval_status ===
                                  "pending" && (
                                  <Badge
                                    className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                    content={"Awaiting review"}
                                    innerClass="bg-blue-100 text-blue-700"
                                  />
                                )}
                                {doc?.workerDocAppr?.approval_status ===
                                  (null || undefined) && (
                                  <Badge
                                    className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                                    content={"Pending Upload"}
                                    innerClass="bg-success-100 text-success-700"
                                  />
                                )}
                              </Td>
                            </Tr>
                            // <Tr key={docIndex}>
                            //   <Th>
                            //     {doc?.workerDocAppr?.workerApprDoc?.doc_name
                            //       ? doc?.workerDocAppr?.workerApprDoc?.doc_name
                            //       : "-"}
                            //   </Th>
                            //   <Td>
                            //     {doc?.document_type?.doc_type_name
                            //       ? doc?.document_type?.doc_type_name
                            //       : "-"}
                            //   </Td>
                            //   {/* <Td>
                            //     {doc?.document_type?.doc_type_name?.issuer
                            //       ? doc?.document_type?.doc_type_name?.issuer
                            //       : "-"}
                            //   </Td> */}
                            //   <Td>
                            //     {doc?.workerDocAppr?.approval_status ===
                            //       "approved" && (
                            //       <Badge
                            //         className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                            //         content={"Approved"}
                            //         innerClass="bg-success-100 text-success-700"
                            //       />
                            //     )}
                            //     {doc?.workerDocAppr?.approval_status ===
                            //       "client_approved_action" && (
                            //       <div className="flex gap-3">
                            //         <Badge
                            //           className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                            //           content={"Approved"}
                            //           innerClass="bg-success-100 text-success-700"
                            //         />
                            //         <svg
                            //           width="16"
                            //           height="17"
                            //           viewBox="0 0 16 17"
                            //           fill="none"
                            //           xmlns="http://www.w3.org/2000/svg"
                            //         >
                            //           <path
                            //             d="M2.66675 14.5L2.66675 3.16667M2.66675 9.16667H7.60008C7.97345 9.16667 8.16013 9.16667 8.30274 9.094C8.42818 9.03009 8.53017 8.9281 8.59409 8.80266C8.66675 8.66005 8.66675 8.47337 8.66675 8.1V3.56667C8.66675 3.1933 8.66675 3.00661 8.59409 2.86401C8.53017 2.73856 8.42818 2.63658 8.30274 2.57266C8.16013 2.5 7.97345 2.5 7.60008 2.5H3.73341C3.36005 2.5 3.17336 2.5 3.03075 2.57266C2.90531 2.63658 2.80333 2.73856 2.73941 2.86401C2.66675 3.00661 2.66675 3.1933 2.66675 3.56667V9.16667ZM8.66675 3.83333H12.9334C13.3068 3.83333 13.4935 3.83333 13.6361 3.906C13.7615 3.96991 13.8635 4.0719 13.9274 4.19734C14.0001 4.33995 14.0001 4.52663 14.0001 4.9V9.43333C14.0001 9.8067 14.0001 9.99339 13.9274 10.136C13.8635 10.2614 13.7615 10.3634 13.6361 10.4273C13.4935 10.5 13.3068 10.5 12.9334 10.5H9.73341C9.36005 10.5 9.17336 10.5 9.03075 10.4273C8.90531 10.3634 8.80333 10.2614 8.73941 10.136C8.66675 9.99339 8.66675 9.8067 8.66675 9.43333V3.83333Z"
                            //             stroke="#039855"
                            //             stroke-width="1.33333"
                            //             stroke-linecap="round"
                            //             stroke-linejoin="round"
                            //           />
                            //         </svg>
                            //       </div>
                            //     )}
                            //     {doc?.workerDocAppr?.approval_status ===
                            //       "client_approval_req" && (
                            //       <Badge
                            //         className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                            //         content={"Action required"}
                            //         innerClass="bg-blue-100 text-blue-700"
                            //       />
                            //     )}
                            //     {doc?.workerDocAppr?.approval_status ===
                            //       "client_reject" && (
                            //       <Badge
                            //         className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                            //         content={"Reject"}
                            //         innerClass="bg-error-100 text-error-700"
                            //       />
                            //     )}
                            //     {doc?.workerDocAppr?.approval_status ===
                            //       "admin_reject" && (
                            //       <Badge
                            //         className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                            //         content={"Reject"}
                            //         innerClass="bg-error-100 text-error-700"
                            //       />
                            //     )}
                            //     {doc?.workerDocAppr?.approval_status ===
                            //       "pending" && (
                            //       <Badge
                            //         className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                            //         content={"Awaiting review"}
                            //         innerClass="bg-blue-100 text-blue-700"
                            //       />
                            //     )}
                            //     {doc?.workerDocAppr?.approval_status ===
                            //       (null || undefined) && (
                            //       <Badge
                            //         className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                            //         content={"Pending Upload"}
                            //         innerClass="bg-success-100 text-success-700"
                            //       />
                            //     )}
                            //   </Td>
                            // </Tr>
                          ))}
                      </React.Fragment>
                    ))}
                </TBody>
                {/* <TBody>
                  {clientData?.map((item, index) => {
                    let status;
                    
                    const approvalStatusArray = item?.workerCheckDocs?.map(
                      (doc) =>
                        doc.workerDocAppr !== null
                          ? doc.workerDocAppr.approval_status
                          : "pending"
                    );

                    const assignedStatusArray = item?.orgChklists?.flatMap(
                      (item) =>
                        item?.compDocs
                          ?.filter((doc) => doc)
                          ?.map((doc) =>
                            doc.DocAppr !== null
                              ? doc.DocAppr.approval_status
                              : "null"
                          )
                    );

                    const reviewed_date = item?.orgChklists?.flatMap((item) =>
                      item?.compDocs
                        ?.filter((doc) =>
                          doc?.DocAppr?.reviewed_date
                            ? doc.DocAppr?.reviewed_date !== "null" &&
                              doc?.DocAppr?.reviewed_date !== null
                            : ""
                        )
                        ?.map(
                          (doc) =>
                            doc.DocAppr !== null &&
                            doc.DocAppr !== "null" &&
                            doc.DocAppr.reviewed_date
                        )
                    );

                    if (approvalStatusArray?.includes("client_approval_req")) {
                      status = "Action required";
                    } else if (
                      approvalStatusArray?.length == 2 &&
                      approvalStatusArray?.includes("approved") &&
                      approvalStatusArray?.includes("client_approved_action")
                    ) {
                      status = "Approved";
                    } else if (
                      approvalStatusArray?.every(
                        (status) => status === "admin_approved"
                      )
                    ) {
                      status = "Approved";
                    } else if (
                      approvalStatusArray?.every(
                        (status) => status === "client_approved"
                      )
                    ) {
                      status = "Approved";
                    } else if (
                      approvalStatusArray?.every(
                        (status) => status === "approved"
                      )
                    ) {
                      status = "Approved";
                    } else if (
                      approvalStatusArray?.every(
                        (status) => status === "client_approved_action"
                      )
                    ) {
                      status = "Approved";
                    } else if (approvalStatusArray?.includes("admin_reject")) {
                      status = "Action required";
                    } else if (approvalStatusArray?.includes("client_reject")) {
                      status = "Action required";
                    } else if (
                      approvalStatusArray?.every(
                        (status) => status === "pending"
                      )
                    ) {
                      status = "Awaiting review";
                    } else if (
                      approvalStatusArray?.every((status) => status === null)
                    ) {
                      status = "Awaiting review";
                    } else {
                      status = "Action required";
                    }

                    return (
                      <Tr key={index}>
                        <Td>{item.trading_name}</Td>
                        <Td>{item?.provClientCont?.contact_email}</Td>
                        <Td>
                          <span className="block w-full text-gray-900">
                            {" "}
                            {item?.provClientCont?.contact_phone}
                          </span>
                          <span className="block w-full text-gray-600">
                            {item?.provClientCont?.contact_first_name +
                              " " +
                              item?.provClientCont?.contact_last_name}
                          </span>
                        </Td>
                        <Td>
                          <Button>
                            <Tag
                              className={`${
                                status == "Action required"
                                  ? "badge-wrapper bg-error-100 text-error-700"
                                  : status ==
                                    "badge-wrapper bg-blue-100 text-blue-700"
                                  ? "badge-wrapper bg-blue-100 text-blue-700"
                                  : "text-success-700 bg-success-50"
                              }`}
                            >
                              {status}
                            </Tag>
                          </Button>
                        </Td>
                      </Tr>
                    )
                  })}
               
                </TBody> */}
              </Table>
            </div>
          </div>
        </Scrollbar>
      </div>
    </Card>
  );
};
export default Documents;
{
  /* <Tr>
                    <Td>
                      Document name{" "}
                      <span className="block text-gray-600">[Client name]</span>
                    </Td>
                    <Td className="w-28">
                      <Tag className="bg-error-100 text-error-700">
                        Action required
                      </Tag>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      Document name{" "}
                      <span className="block text-gray-600">[Client name]</span>
                    </Td>
                    <Td className="w-28">
                      <Tag className="bg-error-100 text-error-700">
                        Action required
                      </Tag>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      Document name{" "}
                      <span className="block text-gray-600">[Client name]</span>
                    </Td>
                    <Td className="w-28">
                      <Tag className="bg-blue-100 text-blue-700">
                        Awaiting review
                      </Tag>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      Document name{" "}
                      <span className="block text-gray-600">[Client name]</span>
                    </Td>
                    <Td className="w-28">
                      <Tag className="bg-error-100 text-error-700">
                        Action required
                      </Tag>
                    </Td>
                  </Tr> */
}
