import React, { useEffect, useState } from "react";
import Tooltip from "../../../../common/element/Tooltip";
import Dialog from "../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Table from "../../../../common/element/Table";
import InducteesInfo from "./InducteesInfo";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { ReinviteWorker } from "../../../../actions/provider-portal/worker/ReinviteContractorWorker";
import { notify } from "react-notify-toast";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";
import Dropdown from "../../../../common/element/Dropdown";
import Button from "../../../../common/element/Buttons";
import { DeleteWorkerInvite } from "../../../../actions/provider-portal/worker/DeleteWorkerInvite";
import { GetWorkerProfileById } from "../../../../actions/admin-provider/worker/GetWorkerProfileById";
const WorkersList = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [reinviteData, setReinviteData] = useState("");
  const [DetailData, setDetailData] = useState("");
  const [dialogReinviteIsOpen, setDialogReinviteIsOpen] = useState(false);

  const [reinvitedialogAddOpen, setReinvitedialogAddOpen] = useState(false);
  const [invite_id, setInvite_id] = useState();
  const [individual_id, setIndividual_id] = useState("");
  const [inducteesDialogIsOpen, setInducteesIsOpen] = useState(false);
  const { Tr, Th, Td, THead, TBody } = Table;
  let user_id;
  let organisation_id;
  let trading_name;
  let current_user;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    user_id = storedData?.userName?.user_id;
    organisation_id = storedData.organisation_id;
    trading_name = storedData?.trading_name;
    current_user = `${storedData?.userName?.first_name || ""} ${
      storedData?.userName?.last_name || ""
    }`;
  }

  const openDialog = () => {
    setIsOpen(true);
  };

  const onDialogClose = () => {
    setIsOpen(false);
  };

  const onInducteesDialogClose = () => {
    setInducteesIsOpen(false);
  };

  const [getData, SetGetData] = useState([]);
  const GettAllFunctions = useSelector((state) => state);
  useEffect(() => {
    const filteredData =
      GettAllFunctions.SpecificContractorWorkerInvites?.data?.data?.rows.filter(
        (item) => {
          return item.invite_status === props.singleSegmentValue;
        }
      );
    // Now, filteredData contains the items where singleSegmentValue is "Invited"
    SetGetData(filteredData);
  }, [GettAllFunctions, props.singleSegmentValue]);
  // Reinvite function
  const onDialogReinviteClose = () => {
    setDialogReinviteIsOpen(false);
  };

  const Reinvite = (e) => {
    setDialogReinviteIsOpen(true);
    setReinviteData(e);
  };

  const reInviteWorker = () => {
    dispatch(
      ReinviteWorker({
        invite_id: reinviteData.invite_id,
        first_name: reinviteData.invite_individual.first_name,
        last_name: reinviteData.invite_individual.last_name,
        email: reinviteData.invite_individual.email,
        provider_org_id: organisation_id,
        current_user: current_user,
        trading_name: trading_name,
      })
    )
      .then((response) => {
        if (response.success) {
          setReinvitedialogAddOpen(true);
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {
        notify.show(error, "error");
      });
  };

  const onAddDialogClose = () => {
    setReinvitedialogAddOpen(false);
    setDialogReinviteIsOpen(false);
  };

  // Import CSV Function
  const Toggle = (
    <Button className="flex gap-1 justify-center items-center text-xs w-auto px-3 h-9 font-semibold hover:bg-gray-50 hover:border-gray-300 focus:bg-white focus:border-gray-300">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="16"
        viewBox="0 0 4 16"
        fill="none"
      >
        <path
          d="M2.0013 8.83301C2.46154 8.83301 2.83463 8.45991 2.83463 7.99967C2.83463 7.53944 2.46154 7.16634 2.0013 7.16634C1.54106 7.16634 1.16797 7.53944 1.16797 7.99967C1.16797 8.45991 1.54106 8.83301 2.0013 8.83301Z"
          stroke="#98A2B3"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.0013 2.99967C2.46154 2.99967 2.83463 2.62658 2.83463 2.16634C2.83463 1.7061 2.46154 1.33301 2.0013 1.33301C1.54106 1.33301 1.16797 1.7061 1.16797 2.16634C1.16797 2.62658 1.54106 2.99967 2.0013 2.99967Z"
          stroke="#98A2B3"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.0013 14.6663C2.46154 14.6663 2.83463 14.2932 2.83463 13.833C2.83463 13.3728 2.46154 12.9997 2.0013 12.9997C1.54106 12.9997 1.16797 13.3728 1.16797 13.833C1.16797 14.2932 1.54106 14.6663 2.0013 14.6663Z"
          stroke="#98A2B3"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Button>
  );

  const deleteWorkerInvite = (items) => {
    setInvite_id(items?.invite_id);
    setIsOpen(true);
  };

  const removeWorker = () => {
    dispatch(DeleteWorkerInvite(invite_id)).then((response) => {
      if (response.success) {
        props.setrefetch(!props.refetch);
        onDialogClose();
      }
    });
  };
  // useEffect(()=>{
  //   if(inducteesDialogIsOpen){

  //   }
  // },[inducteesDialogIsOpen])
  return (
    <React.Fragment>
      <div className="border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className="w-1/4">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props.handleSort("first_name")}
                >
                  Worker name
                  <svg
                    className={`transform ${
                      props.getAllinvitedData.sort !== "" &&
                      props.getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-64">Clients assigned</Th>
              {/* <Th className="w-48">
                <span className="flex items-center gap-1">
                  Clients assigned
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th> */}
              <Th className="w-40">Contact no.</Th>

              <Th className="w-36">
                <span className="flex gap-1">
                  Status
                  <Tooltip
                    title="Invited: Registered: Active:"
                    placement="top-start"
                  >
                    <img src="/assets/icons-svg/help-circle.svg" alt="#" />
                  </Tooltip>
                </span>
              </Th>
              <Th className="w-24"> </Th>
            </Tr>
          </THead>
          <TBody>
            {getData?.map((items, index) => {
              return (
                <Tr key={index}>
                  <Td>
                    <Button>
                      {items?.invite_individual?.first_name +
                        " " +
                        items?.invite_individual?.last_name}
                    </Button>
                  </Td>
                  <Td>
                    {items?.client_assigns?.length > 1
                      ? "Multiple"
                      : items?.inviteWorkerClients?.[0]?.trading_name}
                  </Td>
                  <Td>
                    {items?.invite_individual?.phone
                      ? items?.invite_individual?.phone
                      : ""}
                  </Td>
                  <Td>{items?.invite_status}</Td>
                  {/* <Td>
                      <Badge
                        className="py-0.5 px-2 rounded-lg"
                        content={"Unapproved"}
                        innerClass="bg-error-50 text-error-700 "
                      />
                    </Td> */}
                  {props.singleSegmentValue == "Invited" && (
                    <Td>
                      <div className="flex gap-2 reset-dropdown">
                        <Dropdown renderTitle={Toggle} isOpen={true}>
                          <Dropdown.Item
                            eventKey="a"
                            onClick={() => deleteWorkerInvite(items)}
                          >
                            <svg
                              width="19"
                              height="20"
                              viewBox="0 0 24 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                stroke="#667085"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                            {/* Import file */}
                            <span>Delete</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="b"
                            onClick={() => Reinvite(items)}
                          >
                            <button>
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                                  stroke="#475467"
                                  stroke-width="1.667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </button>
                            <span>Reinvite</span>
                          </Dropdown.Item>
                        </Dropdown>
                      </div>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </TBody>
        </Table>
      </div>

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Are you sure you want to remove this Worker?
          </p>
          <p className=" text-gray-600 text-center mt-1">
            This action cannot be undone. The user will lose all access to the
            app.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 gap-2">
          <BtnLight onClick={onDialogClose}>Close</BtnLight>
          <BtnPrimary onClick={removeWorker}>Confirm</BtnPrimary>
        </div>
      </Dialog>

      <Dialog
        isOpen={dialogReinviteIsOpen}
        onClose={onDialogReinviteClose}
        onRequestClose={onDialogReinviteClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#101828"
                stroke-width="1.667"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Are you sure you want to re-invite this Worker?
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 gap-2">
          <BtnLight onClick={onDialogReinviteClose}>Close</BtnLight>
          <BtnPrimary onClick={reInviteWorker}>Confirm</BtnPrimary>
        </div>
      </Dialog>

      <Dialog
        isOpen={inducteesDialogIsOpen}
        onClose={onInducteesDialogClose}
        onRequestClose={onInducteesDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/cons-worker.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              {DetailData?.first_name + " " + DetailData?.last_name}
            </h5>
            <p className="text-gray-600 ">Worker Details</p>
          </div>
        </div>

        <InducteesInfo DetailData={DetailData} individual_id={individual_id} />
      </Dialog>
      <Dialog
        isOpen={reinvitedialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#101828"
                stroke-width="1.667"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Reinvited successfully
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => onAddDialogClose()}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default WorkersList;
