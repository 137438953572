import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ProgressCircle = () => {
  const percentage = 60; // Set your desired percentage here
  const text = "Average completion rate"; // Custom text you want to display

  return (
    <div className="card-body mt-3.5 flex" style={{ width: '30%', height: '400px', position: 'relative' }}>
      <CircularProgressbar
        value={percentage}
        styles={buildStyles({
          // textColor: "#4a90e2", // Change text color to match the image
          pathColor: "#4a90e2", // Blue color for the progress
          trailColor: "#d9d9d9", // Light grey for the remaining part
          textSize: '24px', // Set font size for the text
          pathTransitionDuration: 0.5, // Smooth animation
        })}
      />
      {/* Centering the text in the middle of the progress circle */}
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        fontSize: '16px',
        // color: '#4a90e2',
      }}>
        {text}<br />
        <span style={{ fontSize: '24px', fontWeight: 'bold' }}>{`${percentage}%`}</span>
      </div>
    </div>
  );
};

export default ProgressCircle;
