import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../../../common/element/Card"
import BtnLight from '../../../../../common/element/Buttons/btnLight'
import Table from "../../../../../common/element/Table"
import Tabs from "../../../../../common/element/Tabs";
import Tag from "../../../../../common/element/Tag"
import Scrollbar from "../../../../../common/element/ScrollBar";
import { DashboardClientCompanyInductions } from "../../../../../actions/Admin-Client/AllOverviewApi";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { useNavigate } from "react-router-dom";
import Dialog from "../../../../../common/element/Dialog";
import InductionView from "../Dialog/Clients/CompanyInduction/InductionView";
import { ResetEditInduction } from "../../../../../redux/classes/client-portal/EeditInduction";
import { ResetInductionModuleData } from "../../../../../redux/classes/client-portal/inductionModuleDataSlice";
import { GetCompanyInductionAndModulesbyId } from "../../../../../actions/client-portal/GetCompanyInductionAndModulesbyIdApi";
import { InductuionDetailForm } from "../../../../../redux/classes/client-portal/inductionDetailsForm";
import { AddInductionModuleData } from "../../../../../redux/classes/client-portal/inductionModuleDataSlice";
import { EditInduction } from "../../../../../redux/classes/client-portal/EeditInduction";

const Inductions = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { TabNav, TabList, TabContent } = Tabs;
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [checkEditdata, SetcheckEditdata] = useState(true);
  const [editBtnHide, setEditBtnHide] = useState(false);
  const [buttonDisable, setbuttonDisable] = useState(true);
  const Allfunction = useSelector((state) => state);
  const { Tr, Th, Td, THead, TBody } = Table
  let permission_data;
  const permissionData = JSON.parse(localStorage?.getItem("permission_data"));
  if (permissionData !== null) {
    permission_data = permissionData;
  }
  const inductionPermission = permission_data?.induction_write == true ? true : false
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [inductionData, setInductionData] = useState(props?.clientDataName)
  const functionAssignmentId = useSelector(
    (state) => state?.functionAssignmentId?.functionAssignmentId
  );

  useEffect(() => {
    setInductionData(props?.clientDataName);
  }, [props?.clientDataName])
  const openDialog = (company_induction_id) => {
    setEditBtnHide(true)
    SetcheckEditdata(true);
    dispatch(ResetEditInduction());
    dispatch(ResetInductionModuleData());
    dispatch(GetCompanyInductionAndModulesbyId(company_induction_id)).then(
      (response) => {
        if (response?.success) {
          dispatch(
            InductuionDetailForm({
              function_assignment_id: response?.data?.function_assignment_id,
              company_ind_name: response?.data?.company_ind_name,
              company_ind_desc: response?.data?.company_ind_desc,
              company_induction_id: response?.data?.company_induction_id,
              company_ind_status: response?.data?.company_ind_status,
              company_ind_valid_days: response?.data?.company_ind_valid_days,
              company_ind_publish_date: response?.data?.company_ind_publish_date,
            })
          );
          response?.data?.company_ind_modules.map((item) => {
            dispatch(AddInductionModuleData(item));
          });
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        }
      }
    );
    setIsOpen(true);
  };
  const onDialogClose = (e) => {
    setIsOpen(false);
    dispatch(ResetInductionModuleData());
    dispatch(ResetEditInduction());
    SetcheckEditdata(false)
  };
  const closeViewInduction = () => {
    dispatch(ResetEditInduction());
    SetcheckEditdata(false)
    setIsOpen(false);
  }

  const handleEditinduction = (company_induction_id, e) => {
    if (e === "EditModule") {
      // SetcheckEditdata(true);
      dispatch(ResetEditInduction());
      dispatch(ResetInductionModuleData());
      dispatch(GetCompanyInductionAndModulesbyId(company_induction_id)).then(
        (response) => {
          if (response?.success) {
            dispatch(
              InductuionDetailForm({
                function_assignment_id:
                  response?.data?.function_assignment_id,
                company_ind_name: response?.data?.company_ind_name,
                company_ind_desc: response?.data?.company_ind_desc,
                company_induction_id: response?.data?.company_induction_id,
                company_ind_status: response?.data?.company_ind_status,
                company_ind_valid_days: response?.data?.company_ind_valid_days,
                company_ind_publish_date: response?.data?.company_ind_publish_date,
              })
            );
            response?.data?.company_ind_modules.map((item) => {
              dispatch(AddInductionModuleData(item));
            });
          } else if (response?.status === 401) {
            handleUnauthorizedAccess()
          }
        }
      );
      // setIsOpen(true);
      // dispatch(EditInduction(true));
      setIsOpen(false);
    } else {
      // SetcheckEditdata(true);
      dispatch(ResetEditInduction());
      // dispatch(EditInduction(true));
      // SetcheckEditdata(false);
      setIsOpen(false);
    }
  };

  return (
    <Card className='py-5 border border-gray-200 rounded-xl dark-shadow'>
      <div className="px-5">
        <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-3">
          <div className="flex items-center">
            <span className="mr-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 21L11.8999 20.8499C11.2053 19.808 10.858 19.287 10.3991 18.9098C9.99286 18.5759 9.52476 18.3254 9.02161 18.1726C8.45325 18 7.82711 18 6.57482 18H5.2C4.07989 18 3.51984 18 3.09202 17.782C2.71569 17.5903 2.40973 17.2843 2.21799 16.908C2 16.4802 2 15.9201 2 14.8V6.2C2 5.07989 2 4.51984 2.21799 4.09202C2.40973 3.71569 2.71569 3.40973 3.09202 3.21799C3.51984 3 4.07989 3 5.2 3H5.6C7.84021 3 8.96031 3 9.81596 3.43597C10.5686 3.81947 11.1805 4.43139 11.564 5.18404C12 6.03968 12 7.15979 12 9.4M12 21V9.4M12 21L12.1001 20.8499C12.7947 19.808 13.142 19.287 13.6009 18.9098C14.0071 18.5759 14.4752 18.3254 14.9784 18.1726C15.5467 18 16.1729 18 17.4252 18H18.8C19.9201 18 20.4802 18 20.908 17.782C21.2843 17.5903 21.5903 17.2843 21.782 16.908C22 16.4802 22 15.9201 22 14.8V6.2C22 5.07989 22 4.51984 21.782 4.09202C21.5903 3.71569 21.2843 3.40973 20.908 3.21799C20.4802 3 19.9201 3 18.8 3H18.4C16.1598 3 15.0397 3 14.184 3.43597C13.4314 3.81947 12.8195 4.43139 12.436 5.18404C12 6.03968 12 7.15979 12 9.4" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg></span>
            <p></p>
            <div className="flex flex-col"><span className="text-gray-900 text-base font-semibold">Inductions</span><p className="text-gray-600"> Due for activation or renewal </p></div>
          </div>
          {/* <BtnLight className="w-20 h-9" onClick={() => props?.setDefaultTab('tab4')}>View All</BtnLight> */}
        </div>
      </div>

      <div className="h-64">
        <Scrollbar>
          <div className="px-5">
            <div className="overflow-hidden border rounded-lg border-gray-200">
              <Table>
                <TBody>
                  {inductionData && inductionData.map((item) => (
                    <Tr className="cursor-pointer" onClick={() => item?.company_induction_id && openDialog(item?.company_induction_id)} key={item?.company_induction_id}>
                      <Td>{item?.company_ind_name ? item?.company_ind_name : "-"}
                        <span className="block text-gray-600">{item?.CompIndOrg?.trading_name ? item?.CompIndOrg?.trading_name : "-"}</span>
                      </Td>
                      <Td>{item?.company_ind_publish_date ? item?.company_ind_publish_date : "-"}</Td>
                      <Td className="cursor-pointer" onClick={() => item?.company_induction_id && openDialog(item?.company_induction_id)}>
                        <Tag className="bg-blue-50 text-blue-800"> Draft</Tag>
                      </Td>
                    </Tr>))}
                </TBody>
              </Table>
            </div>
          </div>
        </Scrollbar>

      </div>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="lg-dialog"
      >
        <div className="flex border-b pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/file-check.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              {Allfunction?.inductionDetailData?.company_ind_name}
            </h5>
          </div>
        </div>
        <InductionView buttonDisable={buttonDisable} inductionPermission={inductionPermission} closeViewInductionModule={closeViewInduction} handleEditinductionModule={handleEditinduction} editBtnHide={editBtnHide} />
      </Dialog>
    </Card>
  )
}
export default Inductions 