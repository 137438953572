import axios from "axios";
import * as actionType from "../../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/client-portal/clientCsvSlice";
export const ResendInvoices = (APIData) => async (dispatch) => {
  // let formdata = new FormData();
  // for (let key in APIData) {
  //   formdata.append(key, APIData[key]);
  // }
  const response = await axios({
    method: "post",
    data: APIData,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
    },
    url: `${actionType.environment_url}/api/admin/client/ResendInvoices`,
  });
  return response.data;
  // dispatch(fetchDataSuccess());
};
