import CryptoJS from "crypto-js";

const secretKey = "ConserveId@Encryption"; // Store this securely

// Function to encrypt the ID
export const encryptId = (id) => {
  return CryptoJS.AES.encrypt(id.toString(), secretKey).toString();
};

// Function to decrypt the ID
export const decryptId = (encryptedId) => {
  const bytes = CryptoJS.AES.decrypt(encryptedId, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
