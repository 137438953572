import React, { useEffect, useState } from "react";
import Tabs from "../../../../../../common/element/Tabs";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import Table from "../../../../../../common/element/Table";
import Badge from "../../../../../../common/element/Badge";
import Tooltip from "../../../../../../common/element/Tooltip";
import Tag from "../../../../../../common/element/Tag";
import Scrollbar from "../../../../../../common/element/ScrollBar";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import Button from "../../../../../../common/element/Buttons";
// import ViewInduction from "./SiteInductions/ViewInduction";
import SiteInductionView from "./SiteInductionView";
import { useDispatch } from "react-redux";
import { GetWorkerSiteLogsByClient } from "../../../../../../actions/admin-provider/worker/GetWorkerSiteLogsByClient";
import moment from "moment";
import { GetClientsAssignedToWorkerByProvider } from "../../../../../../actions/admin-provider/worker/GetClientsAssignedToWorkerByProvider";
import { useParams } from "react-router";
import { GetIndStatusOfWorkerByClient } from "../../../../../../actions/admin-provider/worker/GetIndStatusOfWorkerByClient";
import { GetSubmissionDocsOfWorker } from "../../../../../../actions/admin-provider/worker/GetSubmissionDocsOfWorker";
import { GetWorkerDocHistoryById } from "../../../../../../actions/admin-provider/worker/GetWorkerDocHistoryById";
import { useSelector } from "react-redux";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { Link } from "react-router-dom";
import { updateRoutefunc } from "../../../../../../redux/classes/common/updateRoute";
import CompanyInductionView from "./CompanyInductionView";
import Documents from "./Documents";
import {
  decryptId,
  encryptId,
} from "../../../../../../common/encryptDecrypt/encryptdecrypt";
const { TabNav, TabList, TabContent } = Tabs;
let inductionNameData;
const InducteesInfo = ({
  DetailData,
  individual_id,
  clientId,
  onInducteesDialogClose,
}) => {
  const { SpecificProviderData } = useSelector((state) => state);
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  });

  const { id } = useParams();
  const orgId = decryptId(id);

  const dispatch = useDispatch();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [siteLogData, setSiteLogData] = useState([]);
  const [clientsAssigned, setClientsAssigned] = useState([]);
  const [inductionData, setinductionData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [documentHistoryData, setDocumentHistoryData] = useState([]);
  const [inductionView, setInductionView] = useState({
    worker_id: individual_id?.worker_id,
    site_induction_id: "",
    company_induction_id: "",
    inductionType: "",
  });
  // inductionType: site || company,


  const [tabValue, setTabValue] = useState("tab1");
  const [specificData, SetspecificData] = useState([]);
  // let clientOrgIds = individual_id?.client_assigns?.map((org) => org.organisation_id);
  const [clientAssignFilt, SetClientAssignFilt] = useState({
    worker_id: individual_id?.worker_id,
    provider_org_id: orgId,
    sort: "",
    order: "",
  });

  // client_org_id, worker_id,sort:'site_ind_name' || ' company_ind_name' ,order,inductionType
  const [inductionFilt, SetInductionFilt] = useState({
    worker_id: individual_id?.worker_id,
    client_org_id: clientId?.organisation_id
      ? clientId?.organisation_id
      : individual_id?.client_assigns?.[0]?.organisation_id,
    sort: "created_date",
    order: "",
    inductionType: "",
  });
  // checklistDocIds, worker_id
  const [docFilt, SetDocFilt] = useState({
    worker_id: individual_id?.worker_id,
    client_org_id: clientId?.organisation_id
      ? clientId?.organisation_id
      : individual_id?.client_assigns?.[0]?.organisation_id,
    sort: "",
    order: "",
  });

  const [logFilter, SetLogFilter] = useState({
    individual_id: individual_id?.worker_individual?.individual_id,
    client_org_id: clientId?.organisation_id
      ? clientId?.organisation_id
      : individual_id?.client_assigns?.[0]?.organisation_id,
    sort: "",
    order: "",
  });
  const [inDialogIsOpen, setInIsOpen] = useState(false);
  const { Tr, Th, Td, THead, TBody } = Table;

  const onDialogClose = (e) => {
    setIsOpen(false);
  };

  const openInDialog = (item, company) => {
    setInIsOpen(true);
    if (company == "company") {
      setInductionView((prevalue) => {
        return {
          ...prevalue,
          ["company_induction_id"]: item.company_induction_id,
          ["inductionType"]: "company",
        };
      });
    } else {
      setInductionView((prevalue) => {
        return {
          ...prevalue,
          ["site_induction_id"]: item.site_induction_id,
          ["inductionType"]: "site",
        };
      });
    }
  };

  const onInDialogClose = (e) => {
    setInIsOpen(false);
  };

  const HandleLog = (tab) => {
    setTabValue(tab);
    if (tab == "tab4") {
      dispatch(GetWorkerSiteLogsByClient(logFilter)).then((response) => {
        setSiteLogData(response.data);
      });
    }
    if (tab == "tab5") {
      //  SetClientAssignFilt
      dispatch(GetClientsAssignedToWorkerByProvider(clientAssignFilt)).then(
        (response) => {
          setClientsAssigned(response.data);
        }
      );
    }

    if (tab == "tab3") {
      SetInductionFilt((prevalue) => {
        return {
          ...prevalue,
          sort: "created_date",
          inductionType: "company",
        };
      });
    }

    if (tab == "tab6") {
      //  SetClientAssignFilt
      SetInductionFilt((prevalue) => {
        return {
          ...prevalue,
          sort: "created_date",
          inductionType: "site",
        };
      });
    }

    // if (tab == "tab2") {
    //   dispatch(GetSubmissionDocsOfWorker(docFilt)).then((response) => {
    //     setDocumentData(response?.data);
    //   });
    // }
  };

  const handleSort = (column) => {
    // setApiCalls(false);
    SetLogFilter((prevalue) => {
      return {
        ...prevalue,
        ["order"]: logFilter.order === "asc" ? "desc" : "asc",
      };
    });
    SetLogFilter((prevalue) => {
      return {
        ...prevalue,
        ["sort"]: column,
      };
    });
  };

  const handleClientAssSort = (column) => {
    // setApiCalls(false);
    SetClientAssignFilt((prevalue) => {
      return {
        ...prevalue,
        ["order"]: clientAssignFilt.order === "asc" ? "desc" : "asc",
      };
    });

    SetClientAssignFilt((prevalue) => {
      return {
        ...prevalue,
        ["sort"]: column,
      };
    });
  };

  const handleInductionSort = (column, name) => {
    if (name == "doc_name") {
      SetDocFilt((prevalue) => {
        return {
          ...prevalue,
          ["order"]: inductionFilt.order === "asc" ? "desc" : "asc",
        };
      });
      SetDocFilt((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
    } else {
      if (column == "company_ind_name") {
        SetInductionFilt((prevalue) => {
          return {
            ...prevalue,
            ["order"]: inductionFilt.order === "asc" ? "desc" : "asc",
            ["sort"]: column,
            ["inductionType"]: "company",
          };
        });
      } else {
        SetInductionFilt((prevalue) => {
          return {
            ...prevalue,
            ["order"]: inductionFilt.order === "asc" ? "desc" : "asc",
            ["sort"]: column,
            ["inductionType"]: "site",
          };
        });
      }
    }
  };
  useEffect(() => {
    if (clientAssignFilt.sort) {
      dispatch(GetClientsAssignedToWorkerByProvider(clientAssignFilt)).then(
        (response) => {
          setClientsAssigned(response.data);
        }
      );
    }
  }, [clientAssignFilt]);

  useEffect(() => {
    if (logFilter.sort) {
      dispatch(GetWorkerSiteLogsByClient(logFilter)).then((response) => {
        setSiteLogData(response.data);
      });
    }
  }, [logFilter]);

  useEffect(() => {
    if (inductionFilt.sort) {
      dispatch(GetIndStatusOfWorkerByClient(inductionFilt)).then((response) => {
        setinductionData(response.data);
      });
    }
  }, [inductionFilt]);

  useEffect(() => {
    if (docFilt.sort) {
      dispatch(GetSubmissionDocsOfWorker(docFilt)).then((response) => {
        setDocumentData(response.data.rows);
      });
    }
  }, [docFilt]);

  // View Document
  const OpenViewDoc = (data) => {
    setIsOpen(true);
    SetspecificData({
      document_id: data?.document_id,
      doc_type_name: data?.doc_type_name,
      doc_name: data?.doc_name,
      document_desc: data?.expiry_date_notes,
      expiry_date: data?.expiry_date,
      customField: data?.doctype_fields_data,
      insured_amount: data?.amount_insured,
      policy_no: data?.policy_no,
      issuing_authority: data?.issuer,
      created_date: data?.created_date,
      doc_file: data?.doc_file,
    });
  };

  const handleTabClick = (event) => {
    if (event.target.textContent === "History") {
      dispatch(
        GetWorkerDocHistoryById({
          document_id: specificData?.document_id,
        })
      ).then((response) => {
        setDocumentHistoryData(response?.data);
      });
    }
  };

  const inductionName = (item) => {
    inductionNameData = item;
  };
  
  const workerId = encryptId(individual_id?.worker_id);
  
  return (
    <React.Fragment>
      <Tabs value={tabValue} variant="pill">
        <div className="mx-2 bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5">
          <TabList>
            <TabNav
              className="w-1/4"
              value="tab1"
              onClick={() => HandleLog("tab1")}
            >
              Details
            </TabNav>
            <TabNav
              className="w-1/4"
              value="tab2"
              onClick={() => HandleLog("tab2")}
            >
              Documentation
            </TabNav>
            <TabNav
              className="w-1/4"
              value="tab3"
              onClick={() => HandleLog("tab3")}
            >
              Company Inductions
            </TabNav>
            <TabNav
              className="w-1/4"
              value="tab6"
              onClick={() => HandleLog("tab6")}
            >
              Site Inductions
            </TabNav>
            <TabNav
              className="w-1/4"
              value="tab4"
              onClick={() => HandleLog("tab4")}
            >
              Site access log
            </TabNav>
            <TabNav
              className="w-1/4"
              value="tab5"
              onClick={() => HandleLog("tab5")}
            >
              Clients assigned
            </TabNav>
          </TabList>
        </div>
        <div className="">
          <TabContent value="tab1">
            <div className="flex w-full p-5 details-modal">
              <div className="col-1">
                <div className="flex w-full upload-img h-full">
                  <label
                    htmlFor="dropzone-file"
                    className="flex flex-col items-center justify-center w-full border rounded-xl  border-gray-200   bg-white text-center mb-5 overflow-hidden"
                  >
                    <img
                      src={DetailData?.avatar}
                      alt=""
                      className="h-full w-full"
                    />
                  </label>
                </div>
              </div>
              <div className="pl-5 col-2">
                <form>
                  <div className="flex w-full">
                    <div className="flex-1 pr-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        First name*
                      </label>
                      <span className="font-semibold text-gray-900">
                        {DetailData?.first_name}
                      </span>
                    </div>
                    <div className="flex-1 pl-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        Last name*
                      </label>
                      <span className="font-semibold text-gray-900">
                        {DetailData?.last_name}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full">
                    <div className="flex-1 pr-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        Email address*
                      </label>
                      <span className="font-semibold text-gray-900">
                        {DetailData?.email}
                      </span>
                    </div>
                    <div className="flex-1 pl-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        Contact number
                      </label>
                      <span className="font-semibold text-gray-900">
                        {DetailData?.phone}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full">
                    <div className="flex-1 pr-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        Job title
                      </label>
                      <span className="font-semibold text-gray-900">
                        {DetailData?.occupation}
                      </span>
                    </div>
                    <div className="flex-1 pl-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        Company name
                      </label>
                      <span className="font-semibold text-gray-900">
                        {SpecificProviderData?.trading_name}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full">
                    <div className="flex-1 pr-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        Country
                      </label>
                      <span className="font-semibold text-gray-900">
                        {DetailData?.name}
                      </span>
                    </div>
                    <div className="flex-1 pl-1.5 mb-5">
                      <label className="text-xs font-medium text-gray-700 mb-1.5 block">
                        State
                      </label>
                      <span className="font-semibold text-gray-900">
                        {DetailData?.state_name}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </TabContent>
          <TabContent value="tab2">
            <Documents
              docFilt={docFilt}
              individual_id={individual_id}
              clientId={clientId}
            />
            {/* <div className="p-2 mb-6 mh-430">
              <div className="overflow-hidden border rounded-lg border-gray-200">
                <Table>
                  <THead>
                    <Tr>
                      <Th>
                        <Button
                          className="flex items-center gap-1"
                          onClick={() =>
                            handleInductionSort("doc_name", "doc_name")
                          }
                        >
                          Document
                          <svg
                            className={`transform ${
                              docFilt.sort !== "" &&
                              docFilt.sort === "doc_name" &&
                              docFilt.order === "asc"
                                ? "rotate-180"
                                : ""
                            }`}
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                              stroke="#475467"
                              strokeWidth="1.333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Button>
                      </Th>
                      <Th>Type</Th>
                      <Th>Expiry Date</Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {documentData &&
                      documentData?.map((item, index) => {
                        return (
                          <Tr key={index}>
                            <Td>
                              <Button onClick={() => OpenViewDoc(item)}>
                                {item?.doc_name}
                              </Button>{" "}
                            </Td>
                            <Td>{item.doc_type_name}</Td>
                            <Td>
                              {moment(item.expiry_date).format("YYYY-MM-DD")}
                            </Td>
                          </Tr>
                        );
                      })}
                  </TBody>
                </Table>
              </div>
            </div> */}
          </TabContent>
          <TabContent value="tab3">
            <div className="p-2 mb-6 mh-430">
              <div className="overflow-hidden border rounded-lg border-gray-200">
                <Table>
                  <THead>
                    <Tr>
                      <Th>
                        <Button
                          className="flex items-center gap-1"
                          onClick={() =>
                            handleInductionSort("company_ind_name")
                          }
                        >
                          Induction
                          <svg
                            className={`transform ${
                              inductionFilt.sort !== "" &&
                              inductionFilt.sort === "company_ind_name" &&
                              inductionFilt.order === "asc"
                                ? "rotate-180"
                                : ""
                            }`}
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                              stroke="#475467"
                              strokeWidth="1.333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Button>
                      </Th>
                      <Th>Client name</Th>
                      <Th>Induction Completed</Th>
                      <Th className="w-44">
                        <span className="flex items-center">
                          Status
                          <Tooltip title="Top start" placement="top-start">
                            <span className="pl-1">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#bcclap1iaa)">
                                  <path
                                    d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0z"
                                    stroke="#98A2B3"
                                    strokeWidth="1.333"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="bcclap1iaa">
                                    <path fill="#fff" d="M0 0h16v16H0z" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </Tooltip>
                        </span>
                      </Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {inductionData?.clientCompInd &&
                      inductionData?.clientCompInd?.map((item, index) => {
                        let status;
                        if (item?.workerCompInd?.is_comp_ind_completed) {
                          status = "Completed";
                        } else if (
                          item?.workerCompInd?.is_comp_ind_completed == false
                        ) {
                          status = "Pending";
                        } else {
                          status = "Awaiting action";
                        }
                        return (
                          <Tr key={index}>
                            <Td>
                              {" "}
                              <Button
                                onClick={() => {
                                  openInDialog(item, "company");
                                }}
                              >
                                {item?.company_ind_name}
                              </Button>
                            </Td>
                            <Td>
                              {clientId?.trading_name
                                ? clientId?.trading_name
                                : individual_id?.client_assigns[0]
                                    ?.trading_name}
                            </Td>
                            <Td>-</Td>
                            <Td>
                              <Td>
                                {status == "Awaiting action" && (
                                  <Tag className=" bg-gray-100 text-gray-700">
                                    <svg
                                      width="12"
                                      height="13"
                                      viewBox="0 0 12 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                                        stroke="#475467"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    {status}
                                  </Tag>
                                )}
                                {status == "Completed" && (
                                  <Tag className="bg-success-100 text-success-700">
                                    <svg
                                      width="12"
                                      height="13"
                                      viewBox="0 0 12 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="m10 3.691-5.5 5.5-2.5-2.5"
                                        stroke="#039855"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    Completed
                                  </Tag>
                                )}
                                {status == "Pending" && (
                                  <Tag className="bg-blue-100 text-blue-800">
                                    <svg
                                      width="12"
                                      height="13"
                                      viewBox="0 0 12 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clip-path="url(#clip0_1592_20677)">
                                        <path
                                          d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                          stroke="#5786CC"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1592_20677">
                                          <rect
                                            width="12"
                                            height="12"
                                            fill="white"
                                            transform="translate(0 0.691406)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    Pending
                                  </Tag>
                                )}
                              </Td>
                            </Td>
                          </Tr>
                        );
                      })}
                    {inductionData?.clientOrgSites?.map((siteInd, index) =>
                      siteInd?.siteInd?.map((item, innerIndex) => {
                        let status;
                        if (item?.siteIndWorker?.is_induction_completed) {
                          status = "Completed";
                        } else if (
                          item?.siteIndWorker?.is_induction_completed == false
                        ) {
                          status = "Pending";
                        } else {
                          status = "Awaiting action";
                        }
                        return (
                          <Tr key={index + innerIndex}>
                            <Td>
                              <Button
                                onClick={() => {
                                  openInDialog(item);
                                }}
                              >
                                {item?.site_ind_name}
                              </Button>{" "}
                            </Td>
                            <Td>
                              {clientId?.trading_name
                                ? clientId?.trading_name
                                : individual_id?.client_assigns[0]
                                    ?.trading_name}
                            </Td>
                            <Td>{siteInd?.site_name}</Td>
                            <Td>
                              {item?.siteIndWorker
                                ? item?.siteIndWorker?.updated_date == "null"
                                  ? moment(
                                      item?.siteIndWorker?.updated_date
                                    ).format("YYYY-MM-DD")
                                  : "-"
                                : "-"}
                            </Td>
                            <Td>
                              {status == "Awaiting action" && (
                                <Tag className=" bg-gray-100 text-gray-700">
                                  <svg
                                    width="12"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                                      stroke="#475467"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  {status}
                                </Tag>
                              )}
                              {status == "Completed" && (
                                <Tag className="bg-success-100 text-success-700">
                                  <svg
                                    width="12"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="m10 3.691-5.5 5.5-2.5-2.5"
                                      stroke="#039855"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  Completed
                                </Tag>
                              )}
                              {status == "Pending" && (
                                <Tag className="bg-blue-100 text-blue-800">
                                  <svg
                                    width="12"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_1592_20677)">
                                      <path
                                        d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                        stroke="#5786CC"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1592_20677">
                                        <rect
                                          width="12"
                                          height="12"
                                          fill="white"
                                          transform="translate(0 0.691406)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  Pending
                                </Tag>
                              )}
                            </Td>
                          </Tr>
                        );
                      })
                    )}

                    {/* <Tr>
                      <Td>[Induction name]</Td>
                      <Td>[Client name here]</Td>
                      <Td>[Site name here]</Td>
                      <Td>-</Td>
                      <Td>
                        <Tag className="bg-blue-100 text-blue-800">
                          <svg
                            width="12"
                            height="13"
                            viewBox="0 0 12 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1592_20677)">
                              <path
                                d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                stroke="#5786CC"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1592_20677">
                                <rect
                                  width="12"
                                  height="12"
                                  fill="white"
                                  transform="translate(0 0.691406)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          Pending
                        </Tag>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>[Induction name]</Td>
                      <Td>[Client name here]</Td>
                      <Td>[Site name here]</Td>
                      <Td>DD/MM/YYYY</Td>
                      <Td>
                        <span onClick={() => openInDialog()}>
                          <Tag className="bg-success-100 text-success-700">
                            <svg
                              width="12"
                              height="13"
                              viewBox="0 0 12 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m10 3.691-5.5 5.5-2.5-2.5"
                                stroke="#039855"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Completed
                          </Tag>
                        </span>
                      </Td>
                    </Tr> */}
                  </TBody>
                </Table>
              </div>
            </div>
          </TabContent>
          <TabContent value="tab6">
            <div className="p-2 mb-6 mh-430">
              <div className="overflow-hidden border rounded-lg border-gray-200">
                <Table>
                  <THead>
                    <Tr>
                      <Th>
                        <Button
                          className="flex items-center gap-1"
                          onClick={() => handleInductionSort("site_ind_name")}
                        >
                          Induction
                          <svg
                            className={`transform ${
                              inductionFilt.sort !== "" &&
                              inductionFilt.sort === "site_ind_name" &&
                              inductionFilt.order === "asc"
                                ? "rotate-180"
                                : ""
                            }`}
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                              stroke="#475467"
                              strokeWidth="1.333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Button>
                      </Th>
                      <Th>Client name</Th>
                      <Th>Site name</Th>
                      <Th>Induction Completed</Th>
                      <Th className="w-44">
                        <span className="flex items-center">
                          Status
                          <Tooltip title="Top start" placement="top-start">
                            <span className="pl-1">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#bcclap1iaa)">
                                  <path
                                    d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0z"
                                    stroke="#98A2B3"
                                    strokeWidth="1.333"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="bcclap1iaa">
                                    <path fill="#fff" d="M0 0h16v16H0z" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </Tooltip>
                        </span>
                      </Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {inductionData?.clientCompInd &&
                      inductionData?.clientCompInd?.map((item, index) => {
                        let status;
                        if (item?.workerCompInd?.is_comp_ind_completed) {
                          status = "Completed";
                        } else if (
                          item?.workerCompInd?.is_comp_ind_completed == false
                        ) {
                          status = "Pending";
                        } else {
                          status = "Awaiting action";
                        }

                        return (
                          <Tr key={index}>
                            <Td>
                              {" "}
                              <Button
                                onClick={() => {
                                  openInDialog(item, "company");
                                }}
                              >
                                {item?.company_ind_name}
                              </Button>
                            </Td>
                            <Td>
                              {clientId?.trading_name
                                ? clientId?.trading_name
                                : individual_id?.client_assigns[0]
                                    ?.trading_name}
                            </Td>
                            <Td>-</Td>
                            <Td>-</Td>
                            <Td>
                              <Td>
                                {status == "Awaiting action" && (
                                  <Tag className=" bg-gray-100 text-gray-700">
                                    <svg
                                      width="12"
                                      height="13"
                                      viewBox="0 0 12 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                                        stroke="#475467"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    {status}
                                  </Tag>
                                )}
                                {status == "Completed" && (
                                  <Tag className="bg-success-100 text-success-700">
                                    <svg
                                      width="12"
                                      height="13"
                                      viewBox="0 0 12 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="m10 3.691-5.5 5.5-2.5-2.5"
                                        stroke="#039855"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    Completed
                                  </Tag>
                                )}
                                {status == "Pending" && (
                                  <Tag className="bg-blue-100 text-blue-800">
                                    <svg
                                      width="12"
                                      height="13"
                                      viewBox="0 0 12 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clip-path="url(#clip0_1592_20677)">
                                        <path
                                          d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                          stroke="#5786CC"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1592_20677">
                                          <rect
                                            width="12"
                                            height="12"
                                            fill="white"
                                            transform="translate(0 0.691406)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    Pending
                                  </Tag>
                                )}
                              </Td>
                            </Td>
                          </Tr>
                        );
                      })}
                    {inductionData?.clientOrgSites?.map((siteInd, index) =>
                      siteInd?.siteInd?.map((item, innerIndex) => {
                        let status;
                        if (item?.siteIndWorker?.is_induction_completed) {
                          status = "Completed";
                        } else if (
                          item?.siteIndWorker?.is_induction_completed == false
                        ) {
                          status = "Pending";
                        } else {
                          status = "Awaiting action";
                        }
                        return (
                          <Tr key={index + innerIndex}>
                            <Td>
                              <Button
                                onClick={() => {
                                  openInDialog(item);
                                }}
                              >
                                {item?.site_ind_name}
                              </Button>{" "}
                            </Td>
                            <Td>
                              {clientId?.trading_name
                                ? clientId?.trading_name
                                : individual_id?.client_assigns[0]
                                    ?.trading_name}
                            </Td>
                            <Td>{siteInd?.site_name}</Td>
                            <Td>
                              {item?.siteIndWorker
                                ? item?.siteIndWorker?.updated_date == "null"
                                  ? moment(
                                      item?.siteIndWorker?.updated_date
                                    ).format("YYYY-MM-DD")
                                  : "-"
                                : "-"}
                            </Td>
                            <Td>
                              {status == "Awaiting action" && (
                                <Tag className=" bg-gray-100 text-gray-700">
                                  <svg
                                    width="12"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                                      stroke="#475467"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  {status}
                                </Tag>
                              )}
                              {status == "Completed" && (
                                <Tag className="bg-success-100 text-success-700">
                                  <svg
                                    width="12"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="m10 3.691-5.5 5.5-2.5-2.5"
                                      stroke="#039855"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  Completed
                                </Tag>
                              )}
                              {status == "Pending" && (
                                <Tag className="bg-blue-100 text-blue-800">
                                  <svg
                                    width="12"
                                    height="13"
                                    viewBox="0 0 12 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_1592_20677)">
                                      <path
                                        d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                        stroke="#5786CC"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_1592_20677">
                                        <rect
                                          width="12"
                                          height="12"
                                          fill="white"
                                          transform="translate(0 0.691406)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  Pending
                                </Tag>
                              )}
                            </Td>
                          </Tr>
                        );
                      })
                    )}

                    {/* <Tr>
                      <Td>[Induction name]</Td>
                      <Td>[Client name here]</Td>
                      <Td>[Site name here]</Td>
                      <Td>-</Td>
                      <Td>
                        <Tag className="bg-blue-100 text-blue-800">
                          <svg
                            width="12"
                            height="13"
                            viewBox="0 0 12 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1592_20677)">
                              <path
                                d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                stroke="#5786CC"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1592_20677">
                                <rect
                                  width="12"
                                  height="12"
                                  fill="white"
                                  transform="translate(0 0.691406)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          Pending
                        </Tag>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>[Induction name]</Td>
                      <Td>[Client name here]</Td>
                      <Td>[Site name here]</Td>
                      <Td>DD/MM/YYYY</Td>
                      <Td>
                        <span onClick={() => openInDialog()}>
                          <Tag className="bg-success-100 text-success-700">
                            <svg
                              width="12"
                              height="13"
                              viewBox="0 0 12 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m10 3.691-5.5 5.5-2.5-2.5"
                                stroke="#039855"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Completed
                          </Tag>
                        </span>
                      </Td>
                    </Tr> */}
                  </TBody>
                </Table>
              </div>
            </div>
          </TabContent>
          <TabContent value="tab4">
            <div className="p-2 mb-6 h-430 pr-1">
              <Scrollbar>
                <div className="pr-2">
                  <div className="overflow-hidden border rounded-lg border-gray-200">
                    <Table>
                      <THead>
                        <Tr>
                          <Th>
                            <Button
                              className="flex items-center gap-1"
                              onClick={() => handleSort("trading_name")}
                            >
                              Client
                              <svg
                                className={`transform ${
                                  logFilter.sort !== "" &&
                                  logFilter.sort === "trading_name" &&
                                  logFilter.order === "asc"
                                    ? "rotate-180"
                                    : ""
                                }`}
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                  stroke="#475467"
                                  strokeWidth="1.333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Button>
                          </Th>
                          <Th>
                            <Button
                              className="flex items-center gap-1"
                              onClick={() => handleSort("site_name")}
                            >
                              Site
                              <svg
                                className={`transform ${
                                  logFilter.sort !== "" &&
                                  logFilter.sort === "site_name" &&
                                  logFilter.order === "asc"
                                    ? "rotate-180"
                                    : ""
                                }`}
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                  stroke="#475467"
                                  strokeWidth="1.333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Button>
                          </Th>
                          <Th>Sign-In</Th>
                          <Th>Sign-Out</Th>
                        </Tr>
                      </THead>
                      <TBody>
                        {siteLogData?.map((item, index) => {
                          return (
                            <Tr key={index}>
                              <Td>{item?.WorkerSiteClient?.trading_name}</Td>
                              <Td>{item?.WorkerSite?.site_name}</Td>
                              <Td>
                                <span className="text-gray-900 font-medium block">
                                  {/* DD/MM/YY */}
                                  {moment(item.sign_in_date).format(
                                    "YYYY-MM-DD"
                                  )}
                                </span>
                                <span className="text-gray-600">
                                  {moment(item.sign_in_date).format("HH:mm:ss")}
                                </span>
                              </Td>
                              <Td>
                                {/* <span className=" text-success-500">
                                  On Site
                                </span> */}
                                {item.clock_in_out_status ? (
                                  <span className="text-success-500">
                                    On Site
                                  </span>
                                ) : (
                                  <>
                                    <span className="text-gray-900 block w-full font-medium">
                                      {item?.sign_out_date !== null
                                        ? moment(item.sign_out_date).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "-"}
                                    </span>
                                    <span className="text-gray-600">
                                      {item?.sign_in_date !== null
                                        ? moment(item.sign_out_date).format(
                                            "HH:mm"
                                          )
                                        : "-"}
                                    </span>
                                  </>
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                      </TBody>
                    </Table>
                  </div>
                </div>
              </Scrollbar>
            </div>
          </TabContent>
          <TabContent value="tab5">
            <div className="p-2 mb-6 h-430 pr-1">
              <Scrollbar>
                <div className="pr-2">
                  <div className="overflow-hidden border rounded-lg border-gray-200">
                    <Table>
                      <THead>
                        <Tr>
                          <Th>
                            <Button
                              className="flex items-center gap-1"
                              onClick={() =>
                                handleClientAssSort("trading_name")
                              }
                            >
                              Client name
                              <svg
                                className={`transform ${
                                  clientAssignFilt.sort !== "" &&
                                  clientAssignFilt.sort === "trading_name" &&
                                  clientAssignFilt.order === "asc"
                                    ? "rotate-180"
                                    : ""
                                }`}
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                  stroke="#475467"
                                  strokeWidth="1.333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Button>
                          </Th>
                          <Th>Phone number</Th>
                          <Th>
                            <Button
                              className="flex items-center gap-1"
                              onClick={() =>
                                handleClientAssSort("created_date")
                              }
                            >
                              Assigned
                              <svg
                                className={`transform ${
                                  clientAssignFilt.sort !== "" &&
                                  clientAssignFilt.sort === "created_date" &&
                                  clientAssignFilt.order === "asc"
                                    ? "rotate-180"
                                    : ""
                                }`}
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                  stroke="#475467"
                                  strokeWidth="1.333"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Button>{" "}
                          </Th>
                          {/* <Th>Status</Th> */}
                        </Tr>
                      </THead>
                      <TBody>
                        {clientsAssigned?.map((item, index) => {
                          return (
                            <Tr key={index}>
                              <Td>{item?.trading_name}</Td>
                              <Td>{item?.org_phone}</Td>
                              <Td>
                                <span className="text-gray-900 font-medium block">
                                  {item.created_date
                                    ? moment(item.created_date).format(
                                        "YYYY-MM-DD"
                                      )
                                    : ""}
                                </span>
                              </Td>
                              {/* <Td>
                                <span className="text-success-500">
                                  On Site
                                </span>
                              </Td> */}
                            </Tr>
                          );
                        })}
                      </TBody>
                    </Table>
                  </div>
                </div>
              </Scrollbar>
            </div>
          </TabContent>
          <div className="flex dailog-footer mt-0">
            <BtnLight className="mr-1.5" onClick={onInducteesDialogClose}>
              Close
            </BtnLight>
            <BtnPrimary
              className="ml-1.5"
              onClick={() => {
                dispatch(updateRoutefunc(true));
              }}
            >
              <Link
                to={`/admin/worker/view-all-worker/specific-worker/${encodeURIComponent(
                  workerId
                )}`}
              >
                View Worker’s full profile
              </Link>
            </BtnPrimary>
          </div>
        </div>
      </Tabs>

      <Dialog
        isOpen={inDialogIsOpen}
        onClose={onInDialogClose}
        onRequestClose={onInDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="lg-dialog"
      >
        <div className="flex border-b pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/file-check.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              {inductionNameData}
            </h5>
          </div>
        </div>
        {inductionView?.inductionType == "site" && (
          <SiteInductionView
            inductionView={inductionView}
            inductionName={inductionName()}
          />
        )}
        {inductionView?.inductionType == "company" && (
          <CompanyInductionView
            inductionView={inductionView}
            inductionName={inductionName()}
          />
        )}
      </Dialog>
    </React.Fragment>
  );
};
export default InducteesInfo;
