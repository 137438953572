import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import Segment from "../../../../common/element/Segment";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import SmallButton from "../../../../common/element/Buttons/btnPrimary";
import * as userActions from "../../../../actions/index";
import imagePlus from "../../../../assets/icons-svg/image-plus.svg";
import Validations from "../../../../common/404/Validations";
import Select from "../../../../common/element/Select/Select";
import { GetClientUserPermByIdForClient } from "../../../../actions/client-portal/AllUsersApi";
import { GetAlreadyExistingOrgCheck } from "../../../../actions/client-portal/AllSettingApis";
import Dialog from "../../../../common/element/Dialog";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";

// import { UpdateProviderOrgDetails } from "../../../../actions/provider-portal/UpdateContractorOrgDetails";
import { GetAllOrgIndustryTypeList } from "../../../../actions/index";
import Label from "../../../../common/element/Label";
import Input from "../../../../common/element/Input";
import { Link } from "react-router-dom";
import Radio from "../../../../common/element/Radio";
import Tooltip from "../../../../common/element/Tooltip";
import { useSelector } from "react-redux";
import { RegistrationValidationCheck } from "../../../../redux/classes/common/RegistrationValidation";
import { GetIndividualByEmail } from "../../../../actions/public/GetIndividualByEmail";
import preventInvalidChars from "../../../../utils/preventInvalidChars";
const YourBusiness = ({ UpdateData }) => {
  // Get Register Data
  const { SignUpData } = useSelector((state) => state);
  const { ProviderRegisterData } = useSelector((state) => state);
  const { RegistrationValidation } = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [logo, setLogo] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [urlImage, setUrlImage] = useState("");
  const [dialogAddOpen, setdialogAddOpen] = useState(false);
  const [StateOptions2, setStateOptions2] = useState();
  const [mailingStateID, setMailingStateID] = useState("");
  const [countryOptions, setCountryOptions] = useState();
  const [checkEmail, setCheckEmail] = useState(false);
  const [visibleHide, setVisibleHide] = useState(false);
  const [mailingCountryID, setMailingCountryID] = useState("");
  const [formData, setFormData] = useState({
    trading_name: "",
    // provider_org_detail_id: "",
    abn_nzbn: "",
    // acn: "",
    no_of_emp: "",
    ind_master_setting_id: "",
    businessAddres1: "",
    businessAddres2: "",
    businessAddresCity: "",
    businessAddresPostCode: "",
    businessAddresState: "",
    businessAddresCountry: "",
    businessId: "",

    mailingAddres1: "",
    mailingAddres2: "",
    mailingAddresCity: "",
    mailingAddresPostCode: "",
    mailingAddresState: "",
    mailingAddresCountry: "",
    mailingId: "",
  });
  const [formErrors, setFormErrors] = useState({
    abn_nzbn: "",
    // acn: "",
    no_of_emp: "",
    ind_master_setting_id: "",
    trading_name: "",
    businessAddres1: "",
    businessAddres2: "",
    businessAddresCity: "",
    businessAddresPostCode: "",
    businessAddresState: "",
    businessAddresCountry: "",

    mailingAddres1: "",
    mailingAddres2: "",
    mailingAddresCity: "",
    mailingAddresPostCode: "",
    mailingAddresState: "",
    mailingAddresCountry: "",
    org_type: "",
    existClient: "",
  });

  const [organizationId, setOrganizationId] = useState("");
  const [parentLevel, setParentLevel] = useState("");
  const [countries, setCountries] = useState();
  const [statesOne, setStatesOne] = useState();
  const [statesTwo, setStatesTwo] = useState();
  const [sameAddress, setSameAddress] = useState();
  const [businessAddresCountry, setBusinessAddresCountry] = useState("");
  const [documentError, setdocumentError] = useState("");
  const [industoryOptions, setIndustoryOptions] = useState([]);
  const [noOfEmp, setNoOfEmp] = useState([
    { label: "0-50", value: "0-50" },
    { label: "50-100", value: "50-100" },
    { label: "100-200", value: "100-200" },
    { label: "200-300", value: "200-300" },
    { label: "300-400", value: "300-400" },
    { label: "400-500", value: "400-500" },
  ]);
  const [businessAddresState, setBusinessAddresState] = useState("");
  const [mailingAddresCountry, setMailingAddresCountry] = useState("");
  const [mailingAddresState, setMailingAddresState] = useState("");
  const [value, setValue] = useState("Primary");
  const { ProviderContactData } = useSelector((state) => state);

  const [contact, SetContact] = useState([
    {
      providerContactType: "primary",
      title: "mr.",
      first_name: "",
      last_name: "",
      phone: "",
      phone_optional: "",
      email: "",
      individual_id: "",
      pasword: "",
    },
  ]);

  const [hasChanges, setHasChanges] = useState(false);
  const TitleOptions = [
    { value: "mr.", label: "Mr." },
    { value: "miss", label: "Miss" },
    { value: "mrs", label: "Mrs." },
  ];

  // state to set abn without having space
  const [abn, setAbn] = useState();
  const [callApi, setcallApi] = useState(false);

  let getApiData;
  let settings_permissions;
  let function_assignment_id;
  let user_id;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  const permissionData = JSON.parse(localStorage.getItem("permission_data"));
  if (permissionData !== null) {
    settings_permissions = permissionData.settings_write;
  }
  if (storedData) {
    getApiData = {
      organisation_id: storedData.organisation_id,
    };
    user_id = storedData?.userName?.user_id;
    function_assignment_id = storedData.function_assignment_id;
  }

  const GetAllStatesbyCountryId = useCallback(
    async (formData) => {
      if (formData.businessAddresCountry) {
        dispatch(
          userActions.GetAllStatesbyCountryId(formData.businessAddresCountry)
        ).then((response) => {
          if (response.success === true) {
            const arrayData = response.data;
            let state_data = [];
            for (let key in arrayData) {
              state_data.push({
                label: arrayData[key].state_name,
                value: arrayData[key].state_id,
              });
            }
            setStatesOne(state_data);
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          } else {
          }
        });
      }
      if (formData.mailingAddresCountry) {
        dispatch(
          userActions.GetAllStatesbyCountryId(formData.mailingAddresCountry)
        ).then((response) => {
          if (response.success === true) {
            const arrayData = response.data;
            let state_data = [];
            for (let key in arrayData) {
              state_data.push({
                label: arrayData[key].state_name,
                value: arrayData[key].state_id,
              });
            }
            setStatesTwo(state_data);
            setStateOptions2(state_data);
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          } else {
          }
        });
      }
      // eslint-disable-next-line
    },
    [dispatch, formData.businessAddresCountry, formData.mailingAddresCountry]
  );

  const GetAllCountries = useCallback(async () => {
    dispatch(userActions.GetAllCountries()).then((response) => {
      if (response.success === true) {
        const arrayData = response.data;
        let country_data = [];
        for (let key in arrayData) {
          country_data.push({
            label: arrayData[key].name,
            value: arrayData[key].country_id,
            country_id: arrayData[key].country_id,
          });
        }

        setCountryOptions(country_data);
        setCountries(country_data);
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      } else {
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetAllStatesbyCountryId(formData);
  }, [formData.businessAddresCountry, formData.mailingAddresCountry]);

  useEffect(() => {
    GetAllCountries();
  }, []);

  const inputchangehandler = (e, field) => {
    setFormErrors((prevalue) => {
      return {
        ...prevalue,
        [field]: "",
      };
    });
    let value;
    if (field == "ind_master_setting_id" || field == "no_of_emp") {
      if (e?.target?.value !== undefined) {
        value = e.target.value;
      } else {
        value = e.value;
      }
    } else if (field === "abn_nzbn") {
      if (e?.target?.value !== undefined) {
        value = e.target.value;
      } else {
        value = e.value;
      }
      value = value.replace(/\D/g, "");
    } else {
      if (e?.target?.value !== undefined) {
        value = e.target.value;
      } else {
        value = e.value;
      }
    }
    

    // if(field=="businessAddres1" ||field=="businessAddres2" || field=="mailingAddres1" ||field=="mailingAddres2"  ){
    if (value && value.length >= 255) {
      setFormErrors((prevValue) => ({
        ...prevValue,
        [field]: "Your text limit reached 255 characters",
      }));
    } else {
      // Clear the error if the value length is less than 255
      setFormErrors((prevValue) => ({
        ...prevValue,
        [field]: "",
      }));
      setFormData({ ...formData, [field]: value });
    }

    // setFormErrors((prevalue)=>{
    //   return{
    //     ...prevalue,[field]:"Your text limit reached 255"
    //   }
    // })
    // }
    // else{
    //   setFormData({ ...formData, [field]: value });
    // }

    setHasChanges(true);
  };

  const handleFocusWebsite = () => {
    // Check if the current value doesn't already start with "www"
    // if (!formData.website_url.startsWith("www")) {
    //   setFormData({ ...formData, website_url: `www. ${formData.website_url}` });
    // }
  };

  // function to auto space in abn number
  const handleABN = () => {
    let newValue = formData.abn_nzbn.replace(/\D/g, ""); // Remove non-digit characters
    const length = newValue.length;
    if (length <= 11) {
      // newValue = `${newValue.slice(0, 2)} ${newValue.slice(2)}`;
      newValue = `${newValue.slice(0, 2)} ${newValue.slice(
        2,
        5
      )} ${newValue.slice(5, 8)} ${newValue.slice(8, 11)}`;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      abn_nzbn: newValue,
    }));
    setAbn(newValue);
    let clientData = {
      trading_name: formData?.trading_name,
      abn_nzbn: formData?.abn_nzbn,
      organisation_id: organizationId,
    };

    // dispatch(GetAlreadyExistingOrgCheck(clientData)).then((response) => {
    //   if (response.success) {
    //     const arrayData = response.data;
    //     setFormErrors((prevFormErrors) => ({
    //       ...prevFormErrors,
    //       existClient: "",
    //     }));

    //     if (
    //       arrayData &&
    //       arrayData?.abn_nzbn == formData.abn_nzbn &&
    //       arrayData?.trading_name == formData.trading_name
    //     ) {
    //       // setClientExist(true)
    //       setFormErrors((prevFormErrors) => ({
    //         ...prevFormErrors,
    //         existClient:
    //           "The Name and ABN/NZBN you have provided are already registered on our system. In order to register as a new organization, please choose a different name or ABN/NZBN",
    //       }));
    //     } else {
    //       // setClientExist(false)
    //     }
    //   } else if (response.status === 401) {
    //     handleUnauthorizedAccess();
    //   }
    // });
  };

  // function to handle business Type
  const onBusinessType = useCallback(
    (val) => {
      setBusinessType(val);
      setHasChanges(true);
    },
    [setBusinessType]
  );

  const handleCountryChange = (selectedOption, identifier) => {
    if (identifier === "businessAddress") {
      //  setbusinessCountryID(selectedOption.value)
      setFormData((prevFormData) => ({
        ...prevFormData,
        businessAddresCountry: selectedOption.value,
        businessAddresState: "",
      }));
      setBusinessAddresCountry(selectedOption);
    }
    if (identifier === "mailingAddress") {
      setMailingCountryID(selectedOption.value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        mailingAddresCountry: selectedOption.value,
        mailingAddresState: "",
      }));
      setMailingStateID("");
      setMailingAddresCountry(selectedOption);
    }
    setHasChanges(true);
  };

  const handleStateChange = (selectedOption, identifier) => {
    if (identifier === "businessAddress") {
      //  setBusinessStateID(selectedOption.value)
      setFormData((prevFormData) => ({
        ...prevFormData,
        businessAddresState: selectedOption.value,
      }));
      setBusinessAddresState(selectedOption);
    }
    if (identifier === "mailingAddress") {
      setMailingStateID(selectedOption.value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        mailingAddresState: selectedOption.value,
      }));
      setMailingAddresState(selectedOption);
    }
    setHasChanges(true);
  };

  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  const submitClient = (e) => {
    dispatch(RegistrationValidationCheck(false));
    if (!formErrors.existClient) {
      if (!sameAddress) {
        // clearFormErrors();

        const abnValidation = Validations({
          value: formData.abn_nzbn,
          Validations: { required: true, ValidateABN: true },
        });
        const typeValidation = Validations({
          value: businessType,
          Validations: { required: true },
        });
        const nameValidation = Validations({
          value: formData.trading_name,
          Validations: { required: true },
        });

        const ind_master_setting_id = Validations({
          value: formData.ind_master_setting_id,
          Validations: { required: true },
        });
        const no_of_emp = Validations({
          value: formData.no_of_emp,
          Validations: { required: true },
        });

        if (!typeValidation.valid) {
          setFormError("org_type", typeValidation.error_msg);
        }
        if (!nameValidation.valid) {
          setFormError("trading_name", nameValidation.error_msg);
        }
        if (!ind_master_setting_id.valid) {
          setFormError(
            "ind_master_setting_id",
            ind_master_setting_id.error_msg
          );
        }
        if (!no_of_emp.valid) {
          setFormError("no_of_emp", no_of_emp.error_msg);
        }
        if (!abnValidation.valid) {
          setFormError("abn_nzbn", abnValidation.error_msg);
        }

        const add1BusinessValidation = Validations({
          value: formData.businessAddres1,
          Validations: { required: true },
        });
        const cityBusinessValidation = Validations({
          value: formData.businessAddresCity,
          Validations: { required: true },
        });
        const postCodeBusinessValidation = Validations({
          value: formData.businessAddresPostCode,
          Validations: { required: true },
        });
        const countryBusinessValidation = Validations({
          value: formData.businessAddresCountry,
          Validations: { required: true },
        });
        const stateBusinessValidation = Validations({
          value: formData.businessAddresState,
          Validations: { required: true },
        });

        const add1MailingValidation = Validations({
          value: formData.mailingAddres1,
          Validations: { required: true },
        });
        const cityMailingValidation = Validations({
          value: formData.mailingAddresCity,
          Validations: { required: true },
        });
        const postCodeMailingValidation = Validations({
          value: formData.mailingAddresPostCode,
          Validations: { required: true },
        });
        const countryMailingValidation = Validations({
          value: formData.mailingAddresCountry,
          Validations: { required: true },
        });
        const stateMailingValidation = Validations({
          value: formData.mailingAddresState,
          Validations: { required: true },
        });

        if (!add1BusinessValidation.valid) {
          setFormError("businessAddres1", add1BusinessValidation.error_msg);
        }
        if (!cityBusinessValidation.valid) {
          setFormError("businessAddresCity", cityBusinessValidation.error_msg);
        }
        if (!postCodeBusinessValidation.valid) {
          setFormError(
            "businessAddresPostCode",
            postCodeBusinessValidation.error_msg
          );
        }
        if (!countryBusinessValidation.valid) {
          setFormError(
            "businessAddresState",
            countryBusinessValidation.error_msg
          );
        }
        if (!stateBusinessValidation.valid) {
          setFormError(
            "businessAddresCountry",
            stateBusinessValidation.error_msg
          );
        }

        if (!add1MailingValidation.valid) {
          setFormError("mailingAddres1", add1MailingValidation.error_msg);
        }
        if (!cityMailingValidation.valid) {
          setFormError("mailingAddresCity", cityMailingValidation.error_msg);
        }
        if (!postCodeMailingValidation.valid) {
          setFormError(
            "mailingAddresPostCode",
            postCodeMailingValidation.error_msg
          );
        }
        if (!countryMailingValidation.valid) {
          setFormError(
            "mailingAddresState",
            countryMailingValidation.error_msg
          );
        }
        if (!stateMailingValidation.valid) {
          setFormError(
            "mailingAddresCountry",
            stateMailingValidation.error_msg
          );
        }
      }
    }
  };

  const onAddDialogClose = () => {
    setdialogAddOpen(false);
  };

  useEffect(() => {
    dispatch(GetAllOrgIndustryTypeList()).then((response) => {
      let filterData = response?.data?.map((item) => {
        return { label: item.meta_value_one, value: item.master_setting_id };
      });
      setIndustoryOptions(filterData);
      // industoryOptions,
    });
  }, []);

  const showHandle = (e) => {
    if (e.target.value == "Primary") {
      setVisibleHide(false);
    } else {
      setVisibleHide(true);
    }
  };
  const AccBillingcontact = (val) => {
    setValue(val);
  };

  // const handleTitleChange = (e, name,billing) => {
  //   SetContact((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: name == "title" ? e.value : e.target.value,
  //   }));
  // };
  function isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }
  const handleTitleChange = (e, name, billing) => {
    if (isValidEmail(e.target.value)) {
      dispatch(GetIndividualByEmail(e.target.value)).then((response) => {
        if (response.success && response.data) {
          if (response.data.is_conserve_team) {
            return;
          } else {
            setCheckEmail(true);
            SetContact((prevFormData) => {
              // Create a copy of the previous state array
              const newContact = [...prevFormData];
              // Update the specific object in the array
              newContact[1] = {
                ...newContact[1],
                ...response.data,
              };
              return newContact;
            });
            return;
          }
        } else {
          setCheckEmail(false);
        }
      });
    }
    const indexToUpdate = billing === "billing" ? 1 : 0; // Determine the index based on the billing value
    SetContact((prevFormData) => {
      // Create a copy of the previous state array
      const newContact = [...prevFormData];
      // Update the specific object in the array
      newContact[indexToUpdate] = {
        ...newContact[indexToUpdate],
        [name]: name === "title" ? e.value : e.target.value,
      };

      return newContact;
    });
  };

  useEffect(() => {
    const payload = {
      detailsData: {
        org_type:
          businessType === "pty"
            ? "pty_traders"
            : businessType === "sole"
            ? "sole_traders"
            : "other", //Assuming businessType is one of "pty", "sole", or "other"
        trading_name: formData.trading_name,
        abn_nzbn: formData.abn_nzbn,
        no_of_emp: formData?.no_of_emp,
        ind_master_setting_id: formData?.ind_master_setting_id,
        // provider_org_detail_id: formData?.provider_org_detail_id,
      },
      provider_address_data: [
        {
          address_type: "business",
          address_one: formData.businessAddres1,
          address_two: formData.businessAddres2,
          suburb: formData.businessAddresCity,
          zipcode: formData.businessAddresPostCode,
          state_id: formData.businessAddresState,
          country_id: formData.businessAddresCountry,
          state_name: businessAddresState.label,
          country_name: businessAddresCountry.label,
        },
        {
          address_type: "mailing",
          address_one: sameAddress
            ? formData.businessAddres1
            : formData.mailingAddres1,
          address_two: sameAddress
            ? formData.businessAddres2
            : formData.mailingAddres2,
          suburb: sameAddress
            ? formData.businessAddresCity
            : formData.mailingAddresCity,
          zipcode: sameAddress
            ? formData.businessAddresPostCode
            : formData.mailingAddresPostCode,
          state_id: sameAddress
            ? formData.businessAddresState
            : formData.mailingAddresState,
          country_id: sameAddress
            ? formData.businessAddresCountry
            : formData.mailingAddresCountry,
          state_name: sameAddress
            ? businessAddresState.label
            : mailingAddresState.label,
          country_name: sameAddress
            ? businessAddresCountry.label
            : mailingAddresCountry.label,
        },
      ],
    };
    UpdateData(payload, contact);
  }, [
    businessType,
    contact,
    formData,
    mailingAddresState,
    mailingAddresCountry,
    businessAddresState,
    businessAddresCountry,
  ]);

  // useEffect(() => {
  //   if (visibleHide) {
  //     SetContact((prevValue) => {
  //       const newContact = [...prevValue];
  //       newContact[0] = {
  //         ...newContact[0],
  //         is_acc_billing_contact: true,
  //       },
  //       newContact[1]={
  //         providerContactType: "billing",
  //         is_acc_billing_contact: visibleHide,
  //         contact_title: "mr.",
  //         contact_first_name: "",
  //         contact_last_name: "",
  //         contact_phone: "",
  //         contact_phone_optional: "",
  //         contact_email: "",
  //       };
  //       return newContact;
  //     });
  //   }
  // }, [visibleHide]);
  useEffect(() => {
    SetContact((prevValue) => {
      const newContact = [...prevValue];
      if (visibleHide) {
        // Update the first contact object
        newContact[0] = {
          ...newContact[0],
        };
        // Add a new contact object
        newContact.push({
          providerContactType: "billing",
          title: "mr.",
          first_name: "",
          last_name: "",
          phone: "",
          phone_optional: "",
          email: "",
          individual_id: "",
        });
      } else {
        // Remove the second object if it exists
        if (newContact.length > 1) {
          newContact.splice(1, 1);
        }
      }
      if (newContact.length == 3) {
        newContact.splice(1, 1);
      }
      return newContact;
    });
  }, [visibleHide]);

  useEffect(() => {
    if (ProviderRegisterData?.data) {
      let address_data = ProviderRegisterData?.data?.provider_regist_data;

      SetContact(
        ProviderRegisterData?.data?.provider_regist_data?.individual_data
      );

      setBusinessType(address_data?.org_type);
      setFormData({
        trading_name: address_data?.trading_name,
        // provider_org_detail_id: "",
        abn_nzbn: address_data?.abn_nzbn,
        // acn: "",
        no_of_emp: address_data?.no_of_emp,
        ind_master_setting_id: address_data?.ind_master_setting_id,
        businessAddres1: address_data?.address_data[0]?.address_one,
        businessAddres2: address_data?.address_data[0]?.address_two,
        businessAddresCity: address_data?.address_data[0]?.suburb,
        businessAddresPostCode: address_data?.address_data[0]?.zipcode,
        businessAddresState: address_data?.address_data[0]?.state_id,
        businessAddresCountry: address_data?.address_data[0]?.country_id,
        businessId: "",

        mailingAddres1: address_data?.address_data[1]?.address_one,
        mailingAddres2: address_data?.address_data[1]?.address_two,
        mailingAddresCity: address_data?.address_data[1]?.suburb,
        mailingAddresPostCode: address_data?.address_data[1]?.zipcode,
        mailingAddresState: address_data?.address_data[1]?.state_id,
        mailingAddresCountry: address_data?.address_data[1]?.country_id,
        mailingId: "",
      });
      if (
        ProviderRegisterData?.data?.provider_regist_data?.individual_data
          .length >= 2
      ) {
        setVisibleHide(true);
        AccBillingcontact("Different");
        setValue("Different");
      }
    }
  }, [ProviderRegisterData]);

  useEffect(() => {
    if (RegistrationValidation) {
      submitClient();
    }
  }, [RegistrationValidation]);
  useEffect(() => {
    if (ProviderContactData?.invitedProvider) {
      SetContact([
        {
          providerContactType: "primary",
          title: "mr.",
          first_name: ProviderContactData?.invitedProvider.first_name,
          last_name: ProviderContactData?.invitedProvider.last_name,
          phone: "",
          phone_optional: "",
          email: ProviderContactData?.invitedProvider.email,
          password: ProviderContactData?.repeatPassword,
          individual_id: ProviderContactData?.individual_id,
          user_id: ProviderContactData?.invitedProvider?.user_id,
        },
      ]);
    }
    if (SignUpData.registrationType) {
      SetContact([
        {
          providerContactType: "primary",
          title: "mr.",
          first_name: SignUpData.first_name,
          last_name: SignUpData.last_name,
          phone: "",
          phone_optional: "",
          email: SignUpData.email,
          password: SignUpData.password,
          individual_id: SignUpData?.individual_id,
          user_id: SignUpData?.invitedProvider?.user_id,
        },
      ]);
    }
  }, [ProviderContactData]);
  return (
    <React.Fragment>
      <div className="flex justify-between mb-5">
        <h5 className="text-blue-800 font-semibold text-2xl">Your business</h5>
        {/* {settings_permissions && ( */}
        {/*permission for Read and Write */}
        {/* )} */}
      </div>
      <div className="items-start  mb-5">
        <p className="text-gray-700 font-bold text-base mb-4">
          Type of business
        </p>
        <div className="flex items-center">
          <Segment
            className="docType-btn"
            value={businessType}
            onChange={(val) => onBusinessType(val)}
          >
            <Segment.Item value="pty">
              <span>Pty Ltd</span>
            </Segment.Item>
            <Segment.Item value="sole">
              <span>Sole Trader</span>
            </Segment.Item>
            <Segment.Item value="other">
              <span>Other</span>
            </Segment.Item>
          </Segment>
        </div>
      </div>
      {/* <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">Website</label>
        <div className="flex items-center w-8/12">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-r-0 rounded-tr-none rounded-br-none"
            id="email"
            type="text"
            value={formData.website_url}
            onChange={(e) => inputchangehandler(e, "website_url")}
            onFocus={handleFocusWebsite}
          />
        </div>
      </div> */}
      <div className="items-start mb-5">
        <h4 className="font-bold text-gray-700 text-base mb-5">
          Business Address
        </h4>
        <div className="flex items-center w-full flex-wrap">
          <div className="mb-5 w-full">
            <input
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="text"
              placeholder="Street Address*"
              value={formData.businessAddres1}
              onChange={(e) => inputchangehandler(e, "businessAddres1")}
            />
            {formErrors.businessAddres1 && (
              <p className="text-xs text-red-600 ">
                {formErrors.businessAddres1}
              </p>
            )}
          </div>
          <div className="flex w-full gap-2">
            <div className="w-1/3 mb-5">
              <input
                className="input-shadow w-full h-10 text-sm border-gray-300 rounded-lg  appearance-none border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                id="email"
                type="text"
                placeholder="Apartment, suite, etc"
                value={formData.businessAddres2}
                onChange={(e) => inputchangehandler(e, "businessAddres2")}
              />
              {formErrors.businessAddres2 && (
                <p className="text-xs text-red-600 ">
                  {formErrors.businessAddres2}
                </p>
              )}
            </div>
            <div className="w-1/3 mb-5">
              <input
                className="input-shadow w-full h-10 text-sm border-gray-300 rounded-lg  appearance-none border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder="Suburb*"
                value={formData.businessAddresCity}
                onChange={(e) => inputchangehandler(e, "businessAddresCity")}
              />
              {formErrors.businessAddresCity && (
                <p className="text-xs text-red-600 ">
                  {formErrors.businessAddresCity}
                </p>
              )}
            </div>
            <div className="w-1/3 mb-5">
              <input
                type="text"
                placeholder="Postcode*"
                className="input-shadow h-10 w-full text-sm border-gray-300 rounded-lg  appearance-none border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={formData.businessAddresPostCode}
                onChange={(e) =>
                  inputchangehandler(e, "businessAddresPostCode")
                }
                maxLength={12}
              />
              {formErrors.businessAddresPostCode && (
                <p className="text-xs text-red-600 ">
                  {formErrors.businessAddresPostCode}
                </p>
              )}
            </div>
          </div>

          <div className="flex w-full gap-2">
            <div className="flex-1">
              <Select
                isSearchable={false}
                placeholder="Country"
                options={countries}
                className="select-option w-full h-10"
                value={countries?.filter(function (option) {
                  return option.value === formData.businessAddresCountry;
                })}
                onChange={(e) => handleCountryChange(e, "businessAddress")}
              />
              {formErrors.businessAddresCountry && (
                <p className="text-xs text-red-600">
                  {formErrors.businessAddresCountry}
                </p>
              )}
            </div>
            <div className="flex-1">
              <Select
                isSearchable={false}
                placeholder="State"
                options={statesOne}
                className="select-option w-full h-10  mx-1"
                value={statesOne?.filter(function (option) {
                  return option.value === formData.businessAddresState;
                })}
                onChange={(e) => handleStateChange(e, "businessAddress")}
              />

              {formErrors.businessAddresState && (
                <p className="text-xs text-red-600 ">
                  {formErrors.businessAddresState}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="items-start pb-5 mb-5">
        <h4 className="font-bold text-gray-700 text-base mb-5">
          Mailing Address
        </h4>
        <div className="flex items-center flex-1 flex-wrap">
          <div className="w-full mb-5">
            <input
              type="text"
              name="address"
              placeholder="Street Address*"
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
              value={
                sameAddress
                  ? formData.businessAddres1
                  : formData?.mailingAddres1
              }
              onChange={(e) => inputchangehandler(e, "mailingAddres1")}
              readOnly={sameAddress ? true : false}
            />
            {formErrors.mailingAddres1 && (
              <p className="text-xs text-red-600 ">
                {formErrors.mailingAddres1}
              </p>
            )}
          </div>
          <div className="flex w-full gap-2">
            <div className="flex-1">
              <input
                type="text"
                name="Apartment"
                placeholder="Apartment, suite, etc (optional)"
                className="mb-2 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={
                  sameAddress
                    ? formData.businessAddres2
                    : formData?.mailingAddres2
                }
                onChange={(e) => inputchangehandler(e, "mailingAddres2")}
                readOnly={sameAddress ? true : false}
              />
              {formErrors.mailingAddres2 && (
                <p className="text-xs text-red-600 ">
                  {formErrors.mailingAddres2}
                </p>
              )}{" "}
            </div>
            <div className="flex-1 mb-5">
              <input
                type="text"
                name="Suburb"
                placeholder="Suburb*"
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={
                  sameAddress
                    ? formData.businessAddresCity
                    : formData?.mailingAddresCity
                }
                onChange={(e) => inputchangehandler(e, "mailingAddresCity")}
                readOnly={sameAddress ? true : false}
              />
              {formErrors.mailingAddresCity && (
                <p className="text-xs text-red-600 ">
                  {formErrors.mailingAddresCity}
                </p>
              )}
            </div>
          </div>
          <div className="flex w-full gap-2">
            <div className=" flex-1 h-9">
              <Select
                isSearchable={false}
                placeholder="Country"
                options={countryOptions}
                className="select-option flex-1 h-9"
                value={
                  sameAddress
                    ? countries?.filter(function (option) {
                        return option.value === formData.businessAddresCountry;
                      })
                    : countryOptions?.filter(function (option) {
                        return option.value === formData?.mailingAddresCountry;
                      })
                }
                onChange={(e) => handleCountryChange(e, "mailingAddress")}
              />
              {formErrors.mailingAddresCountry && (
                <p className="text-xs text-red-600 ">
                  {formErrors.mailingAddresCountry}
                </p>
              )}
            </div>
            <div className="flex-1 mb-5">
              <input
                type="text"
                name="Postcode"
                placeholder="Postcode*"
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={
                  sameAddress
                    ? formData.businessAddresPostCode
                    : formData?.mailingAddresPostCode
                }
                onChange={(e) => inputchangehandler(e, "mailingAddresPostCode")}
                readOnly={sameAddress ? true : false}
                maxLength={12}
              />
              {formErrors.mailingAddresPostCode && (
                <p className="text-xs text-red-600 ">
                  {formErrors.mailingAddresPostCode}
                </p>
              )}
            </div>
          </div>
          <div className="w-full h-9">
            <Select
              isSearchable={false}
              placeholder="State*"
              options={StateOptions2}
              className="select-option h-10 text-sm border-gray-300 rounded-lg  appearance-none w-full text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
              value={
                sameAddress
                  ? statesOne?.filter(function (option) {
                      return option.value === formData.businessAddresState;
                    })
                  : StateOptions2?.filter(function (option) {
                      return option.value === formData.mailingAddresState;
                    })
              }
              onChange={(e) => handleStateChange(e, "mailingAddress")}
            />

            {formErrors.mailingAddresState && (
              <p className="text-xs text-red-600 ">
                {formErrors.mailingAddresState}
              </p>
            )}
          </div>
        </div>
      </div>
      {/* <div className="flex items-start mb-5">
        <Label className="font-semibold w-52">Website</Label>
        <div className="flex items-center flex-1">
          <Input
            type="text"
            name="Postcode"
            placeholder="www.sitename.com"
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-r-0 rounded-tr-none rounded-br-none"
            value={formData?.website_url}
            inputchangehandler={(e) => inputchangehandler(e, "website_url")}
          />
          <Link
            to="#"
            className="text-gray-700 font-semibold border border-gray-100 bg-white w-24  flex h-10 justify-center items-center rounded-lg gap-1 rounded-tl-none rounded-bl-none hover:text-gray-900 focus:text-gray-600"
            onClick={() => {
              if (formData?.website_url.trim() !== "") {
                window.open(`${formData?.website_url}`, "_blank");
              }
            }}
          >
            <svg
              width="16"
              height="15"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Open
          </Link>
        </div>
      </div> */}

      <div className="my-4">
        <input
          className="checkbox rounded"
          type="checkbox"
          value={sameAddress ? sameAddress : false}
          checked={sameAddress ? sameAddress : false}
          onChange={() => setSameAddress(!sameAddress)}
        />
        <label className="text-gray-700 font-medium py-3">
          Mailing address same as business address
        </label>{" "}
      </div>
      {formErrors.existClient && (
        <p className="text-xs text-red-600  pt-8">{formErrors.existClient}</p>
      )}
      <h4 className="font-bold text-gray-700 text-base mb-5">Details</h4>
      <div className="items-start  mb-5">
        <label
          className="block text-gray-700  mb-2 text-xs font-medium"
          for="password"
        >
          Trading Name*
        </label>
        <div className=" items-center w-full">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            placeholder="Enter business name here"
            value={formData.trading_name}
            // readOnly
            onChange={(e) => inputchangehandler(e, "trading_name")}
          />
          {formErrors.trading_name && (
            <p className="text-xs text-red-600 ">{formErrors.trading_name}</p>
          )}
        </div>
      </div>
      <div className="items-start mb-5">
        <label className="block text-gray-700  mb-2 text-xs font-medium">
          ABN/NZBN*
        </label>
        <div className=" items-center w-full">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            // id="abn_nzbn"
            type="number"
            placeholder="Enter ABN/NZBN here"
            value={formData.abn_nzbn}
            onChange={(e) => inputchangehandler(e, "abn_nzbn")}
            // onBlur={handleABN}
            maxLength="14"
            minLength="11"
            onKeyDown={preventInvalidChars}
          />
          {formErrors.abn_nzbn && (
            <p className="text-xs text-red-600 ">{formErrors.abn_nzbn}</p>
          )}
        </div>
      </div>
      <div className="items-start mb-5">
        <label className="block text-gray-700  mb-2 text-xs font-medium">
          Industry*
        </label>
        <div className="flex items-center w-full flex-wrap">
          <Select
            className="w-full h-10 select-options"
            placeholder="Industry name here"
            value={
              industoryOptions.filter(
                (item) => item.value == formData.ind_master_setting_id
              )[0]
            }
            options={industoryOptions}
            onChange={(e) => inputchangehandler(e, "ind_master_setting_id")}
          ></Select>
          {formErrors.ind_master_setting_id && (
            <p className="text-xs text-red-600 ">
              {formErrors.ind_master_setting_id}
            </p>
          )}
        </div>
      </div>
      <div className="items-start mb-5">
        <label className="block text-gray-700  mb-2 text-xs font-medium">
          Number of Employees*
        </label>
        <Select
          className="w-full h-10 select-options"
          placeholder="Enter number of employees here"
          value={noOfEmp.filter((item) => item.value == formData.no_of_emp)[0]}
          options={noOfEmp}
          onChange={(e) => inputchangehandler(e, "no_of_emp")}
        ></Select>
        {formErrors.no_of_emp && (
          <p className="text-xs text-red-600 ">{formErrors.no_of_emp}</p>
        )}
      </div>

      <h4 className="font-bold text-gray-700 text-base mb-5 mt-7">
        Primary contact*
      </h4>
      <div className="items-start  mb-5">
        {/* <Label className="font-semibold w-72 font-sm">WHS Contact</Label> */}
        <div className="Pflex items-center  flex-1 flex-wrap">
          <div className="flex w-full gap-2">
            <Select
              isSearchable={false}
              placeholder=""
              readOnly={true}
              options={TitleOptions}
              className="select-option w-2/12 mb-5"
              value={
                TitleOptions &&
                TitleOptions.filter(
                  (options) => options.value === contact[0]?.title
                )
              }
              onChange={(e) => handleTitleChange(e, "title")}
            />
            <input
              type="text"
              name="First Name"
              disabled={true}
              placeholder="First Name*"
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-5/12 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
              value={contact[0]?.first_name}
              onChange={(e) => handleTitleChange(e, "first_name")}
            />
            <input
              type="text"
              name="Last Name"
              disabled={true}
              placeholder="Last Name*"
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-5/12 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
              value={contact[0]?.last_name}
              onChange={(e) => handleTitleChange(e, "last_name")}
            />
          </div>
          <div className="flex w-full gap-2">
            <input
              type="number"
              name="Work"
              placeholder="Mobile*"
              // disabled={true}
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
              value={contact[0]?.phone}
              onChange={(e) => handleTitleChange(e, "phone")}
              onKeyDown={preventInvalidChars}
            />

            <input
              type="number"
              // disabled={true}
              placeholder="Phone(Optional)"
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
              value={contact[0]?.phone_optional}
              onChange={(e) => handleTitleChange(e, "phone_optional")}
              onKeyDown={preventInvalidChars}
            />
          </div>
          <div className="flex w-full h-9">
            <input
              type="text"
              name="Email"
              placeholder="Email"
              disabled={true}
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
              value={contact[0]?.email}
              onChange={(e) => handleTitleChange(e, "email")}
            />
          </div>
        </div>
      </div>
      <h4 className="font-bold text-gray-700 text-base mb-2 mt-7 flex items-center gap-2">
        Account Billing contact
        <Tooltip
          title="Account Billing contact is responsible for billing and payment on the account."
          placement="top-start"
        >
          <div className="text-center cursor-pointer">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#bcclap1iaa)">
                <path
                  d="M6.06 6a2 2 0 0 1 3.887.667c0 1.333-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0z"
                  stroke="#98A2B3"
                  stroke-width="1.333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="bcclap1iaa">
                  <path fill="#fff" d="M0 0h16v16H0z" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </Tooltip>
      </h4>
      <div className="">
        <Radio.Group vertical value={value} onChange={AccBillingcontact}>
          <Radio onClick={showHandle} value={"Primary"} className="flex gap-2">
            Same as primary contact
          </Radio>
          <Radio
            onClick={showHandle}
            value={"Different"}
            className="flex gap-2"
          >
            Different contact
          </Radio>
        </Radio.Group>
      </div>

      {visibleHide && (
        <React.Fragment>
          <div className="Pflex items-center  flex-1 flex-wrap">
            <h4 className="font-bold text-gray-700 text-base mb-4 mt-5">
              Billing contact*
            </h4>
            <div className="flex w-full gap-2">
              <Select
                isSearchable={false}
                placeholder=""
                options={TitleOptions}
                className="select-option w-2/12 mb-5"
                value={
                  TitleOptions &&
                  TitleOptions.filter(
                    (options) => options.value === contact[1]?.title
                  )
                }
                onChange={(e) => handleTitleChange(e, "title", "billing")}
              />
              <input
                type="text"
                name="First Name"
                disabled={checkEmail}
                placeholder="First Name*"
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-5/12 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
                value={contact[1]?.first_name}
                onChange={(e) => handleTitleChange(e, "first_name", "billing")}
              />
              <input
                type="text"
                name="Last Name"
                disabled={checkEmail}
                placeholder="Last Name*"
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-5/12 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
                value={contact[1]?.last_name}
                onChange={(e) => handleTitleChange(e, "last_name", "billing")}
              />
            </div>
            <div className="flex w-full gap-2">
              <input
                type="number"
                name="Work"
                disabled={checkEmail}
                placeholder="Work*"
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
                value={contact[1]?.phone}
                onChange={(e) => handleTitleChange(e, "phone", "billing")}
                onKeyDown={preventInvalidChars}
              />

              <input
                type="number"
                disabled={checkEmail}
                placeholder="Mobile(Optional)"
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
                value={contact[1]?.phone_optional}
                onChange={(e) =>
                  handleTitleChange(e, "phone_optional", "billing")
                }
                onKeyDown={preventInvalidChars}
              />
            </div>
            <div className="flex w-full h-9">
              <input
                type="text"
                name="Email"
                placeholder="Email"
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5"
                value={contact[1]?.email}
                onChange={(e) => handleTitleChange(e, "email", "billing")}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                fill="#D1FADF"
              />
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M32.083 18.8721L21.083 29.8721L16.083 24.8721"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Company profile has been saved successfully
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => onAddDialogClose()}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default YourBusiness;
