import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../../../../../../common/element/Heading/index.js";
import BtnLight from "../../../../../../common/element/Buttons/btnLight.js";
import Dropdown from "../../../../../../common/element/Dropdown/Dropdown.js";
import Label from "../../../../../../common/element/Label/index.js";
import Input from "../../../../../../common/element/Input/Input.js";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice.js";
import Select from "../../../../../../common/element/Select/Select.js";
import Button from "../../../../../../common/element/Buttons/index.js";
import ClientOverviewList from "../ClientsOverview/ClientOverviewList.js";
import BarChart from "./BarChart.js";
const ClientsInvoices = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                { label: "Reports", url: "/admin/client/reports", home: "/admin/client/dashboard" },
                { label: "Clients Invoices", url: "/admin/client/reports/clients-invoices" },
            ])
        );
    }, [dispatch]);
    return (
        <React.Fragment>

            <div className="flex justify-between mb-5 border-b border-gray-200 pb-5">
                <Heading>Clients Invoices</Heading>
                <div className="flex gap-3">
                    <BtnLight className="h-10 w-10 flex items-center justify-center gap-2 text-12">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.40156 2.8778C9.59363 2.48869 9.68967 2.29413 9.82004 2.23197C9.93347 2.17789 10.0653 2.17789 10.1787 2.23197C10.3091 2.29413 10.4051 2.48869 10.5972 2.8778L12.4194 6.56944C12.4761 6.68432 12.5045 6.74176 12.5459 6.78635C12.5826 6.82584 12.6266 6.85783 12.6754 6.88056C12.7306 6.90623 12.794 6.91549 12.9208 6.93402L16.9968 7.5298C17.4261 7.59253 17.6407 7.6239 17.74 7.72874C17.8264 7.81995 17.8671 7.94529 17.8506 8.06985C17.8317 8.21302 17.6763 8.36436 17.3656 8.66702L14.4172 11.5387C14.3253 11.6282 14.2794 11.673 14.2497 11.7263C14.2235 11.7734 14.2066 11.8252 14.2001 11.8788C14.1928 11.9393 14.2036 12.0025 14.2253 12.129L14.921 16.1851C14.9944 16.6129 15.031 16.8269 14.9621 16.9538C14.9021 17.0642 14.7955 17.1417 14.6719 17.1646C14.5299 17.1909 14.3378 17.0899 13.9536 16.8879L10.3097 14.9716C10.1961 14.9119 10.1394 14.882 10.0796 14.8703C10.0266 14.8599 9.97213 14.8599 9.91918 14.8703C9.85937 14.882 9.8026 14.9119 9.68906 14.9716L6.04512 16.8879C5.66095 17.0899 5.46886 17.1909 5.32683 17.1646C5.20325 17.1417 5.09662 17.0642 5.03663 16.9538C4.96768 16.8269 5.00437 16.6129 5.07774 16.1851L5.77342 12.129C5.79511 12.0025 5.80595 11.9393 5.79862 11.8788C5.79212 11.8252 5.77528 11.7734 5.74902 11.7263C5.71937 11.673 5.67341 11.6282 5.5815 11.5387L2.63315 8.66702C2.3224 8.36436 2.16703 8.21302 2.14812 8.06985C2.13167 7.94529 2.17231 7.81995 2.25872 7.72874C2.35804 7.6239 2.57266 7.59253 3.00189 7.5298L7.07794 6.93402C7.2047 6.91549 7.26808 6.90623 7.32328 6.88056C7.37215 6.85783 7.41615 6.82584 7.45284 6.78635C7.49427 6.74176 7.52262 6.68432 7.57933 6.56944L9.40156 2.8778Z" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </BtnLight>
                    <BtnLight className="h-10 w-40 flex items-center justify-center gap-2 text-12">
                        Save as custom report
                    </BtnLight>

                    <div className="export-dropdow">
                        <Dropdown title="Export" className="dropdown-btn">
                            <Dropdown.Item eventKey="a" className="text-gray-900 font font-medium">CSV</Dropdown.Item>
                            <Dropdown.Item eventKey="b" className="text-gray-900 font font-medium">Excel</Dropdown.Item>
                            <Dropdown.Item eventKey="c" className="text-gray-900 font font-medium">PDF</Dropdown.Item>

                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="flex justify-between bg-gray-50 rounded-xl py-3 px-4">
                <div className="flex gap-5">
                    <div className="w-64"><Label className="mb-1 block">Date Range</Label>
                        <Input className="h-9 w-full" />
                    </div>
                    <div className="w-64"><Label className="mb-1 block">Client</Label>
                        <Select className="select-options" placeholder="All" />
                    </div>
                    <div className="w-64"><Label className="mb-1 block">Status</Label>
                        <Select className="select-options hide-seacrh-icon" placeholder="All" />
                    </div>
                </div>
                <div className="flex gap-5">
                    <Button className="text-gray-600 text-xs font-semibold hover:text-gray-900">Clear All</Button>
                    <Button className="text-blue-700 text-xs font-semibold hover:text-blue-900">Update</Button>
                </div>
            </div>

            <div className="flex border border-gray-100 rounded-xl p-6 mt-5 gap-6">
                <BarChart />
            </div>
            <ClientOverviewList />
        </React.Fragment>
    )
}


export default ClientsInvoices
