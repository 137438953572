import axios from "axios";
import * as actionType from "../../constant/actionType";

export const CreateOrUpdateProviderRegisTempData =
  (ApiData,SignUpData) => async (dispatch) => {
    try {
      let Data=ApiData;
      delete ApiData.created_by;
      delete ApiData.updated_by;
      delete ApiData.deleted_by;
      delete ApiData.deleted_date;
      if(SignUpData=="forClient"){
        delete ApiData.invite_provider_id;
      }
      let formdata = new FormData();
      for (let key in Data) {
        if(key=="provider_regist_data"){
          formdata.append(key,JSON.stringify(Data[key]));
        }
        else{
          if(key=="user_id"||key=="invite_provider_id"){
            formdata.append(key, Data[key]==null?"":Data[key]);
          }
          else{
            formdata.append(key, Data[key]);
          }
          
        }
      }
      const response = await axios({
        method: "post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/CreateProviderRegisTempData`,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };
  