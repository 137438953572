import React, { useState, useEffect, useRef, useCallback } from "react";
import Table from "../../../../../../common/element/Table";
import Button from "../../../../../../common/element/Buttons";
import Tag from "../../../../../../common/element/Tag";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Dropdown from "../../../../../../common/element/Dropdown";
import { useSelector, useDispatch } from "react-redux";
// import ViewInvoice from "./InvoiceView";
import { setInvoiceData } from "../../../../../../redux/classes/Admin-Section/invoiceSlice";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "../../../../../../common/element/Checkbox";
import moment from "moment";  
const { Tr, Th, Td, THead, TBody } = Table;
const InvoiceList = (props) => {  
  const dispatch = useDispatch(); 
  const navigate = useNavigate(); 
  const [mainCheck, setMainCheck] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [dialogResendInvoice, setDialogResendInvoice] = useState(false);
  const [successdialogOpen, setSuccessDialogOpen] = useState(false);
  const [pdfDownloadSuccessDialog, setPdfDownloadSuccessDialog] =
    useState(false);
  const [invoicesData, setInvoicesData] = useState("");
  const [pdfViewDialog, setPdfViewDialog] = useState(false);
  const previousViewTypeRef = useRef();

  useEffect(() => {
    if (props?.viewType !== previousViewTypeRef.current) {
      setCheckedItems([]);
    }
    previousViewTypeRef.current = props?.viewType;
  }, [props?.viewType]);

  const closeSuccessDialog = () => {
    setSuccessDialogOpen(false);
  };
  const closeDownloadSuccessPdf = () => {
    setPdfDownloadSuccessDialog(false);
  };

  const onCheckboxChange = (e, item) => {
    if (item === "mainCheck") {
      const isChecked = e;
      setMainCheck(isChecked);

      if (isChecked) {
        setCheckedItems(props?.allInvoicesData);
      } else {
        setCheckedItems([]);
      }
    } else {
      const isChecked = e;
      const existingIndex = checkedItems.findIndex(
        (ele) => ele.account_payment_id === item.account_payment_id
      );

      if (isChecked && existingIndex === -1) {
        setCheckedItems((prevItems) => [...prevItems, item]);
      } else if (!isChecked && existingIndex !== -1) {
        setCheckedItems((prevItems) => {
          const updatedItems = [...prevItems];
          updatedItems.splice(existingIndex, 1);
          return updatedItems;
        });
      }
    }
  };
  // const downloadSingleInvoice = async (item) => {
  //     try {
  //         if (!item?.invoiceUrl || !item?.invoiceNumber) {
  //             throw new Error('Invalid invoice data: Missing URL or invoice number');
  //         }
  //         const response = await fetch(item.invoiceUrl);
  //         if (!response.ok) {
  //             throw new Error('Failed to fetch the PDF');
  //         }
  //         const link = document.createElement('a');
  //         link.href = item.invoiceUrl;
  //         link.download = item.invoiceNumber + '.pdf';
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //     } catch (error) {
  //         console.error(`Error downloading invoice ${item?.invoiceNumber || 'Unknown'}:`, error.message);
  //     }
  // };

  // const downloadSelectedInvoices = () => {
  //     checkedItems.forEach((item, index) => {
  //         setTimeout(() => {
  //             downloadSingleInvoice(item);
  //         }, index * 300);
  //     });
  // };

  // const downloadSingleInvoice = async (item) => {
  //   try {
  //     if (!item?.xero_invoice_pdf || !item?.xero_invoice_number) {
  //       throw new Error("Invalid invoice data: Missing URL or invoice number");
  //     }
  //     const response = await fetch(item.xero_invoice_pdf);

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch the PDF");
  //     }
  //     const link = document.createElement("a");
  //     link.href = item.xero_invoice_pdf;
  //     link.download = item.xero_invoice_number + ".pdf";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error(
  //       `Error downloading invoice ${item?.xero_invoice_number || "Unknown"}:`,
  //       error.message
  //     );
  //   }
  // };
  const downloadSingleInvoice = async (item) => {
    try {
      if (!item?.xero_invoice_pdf || !item?.xero_invoice_number) {
        throw new Error("Invalid invoice data: Missing URL or invoice number");
      }

      const response = await fetch(item.xero_invoice_pdf);
      if (!response.ok) {
        throw new Error("Failed to fetch the PDF");
      }

      const blob = await response.blob(); // Convert the response to a Blob
      const url = window.URL.createObjectURL(blob); // Create an object URL for the Blob

      const link = document.createElement("a");
      link.href = url;
      link.download = `${item.xero_invoice_number}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url); // Clean up the object URL
    } catch (error) {
      console.error(
        `Error downloading invoice ${item?.xero_invoice_number || "Unknown"}:`,
        error.message
      );
    }
  };

  const downloadSelectedInvoices = async () => {
    for (const [index, item] of checkedItems.entries()) {
      await new Promise((resolve) => {
        setTimeout(() => {
          downloadSingleInvoice(item);
          resolve();
        }, index * 300); // Introducing a delay of 300ms between downloads
      });
    }

    // After all downloads are attempted, show success dialog and reset checked items
    setPdfDownloadSuccessDialog(true);
    setCheckedItems([]);
  };
  const resendInvoice = (e) => {
    setDialogResendInvoice(true);
    // setDialogReinviteIsOpen(true);
    // setReinviteData(e);
  };
  const handleRowClick = (item) => {
    setInvoicesData(item);
    // setPdfViewDialog(true);
  };
  const onDialogClose = () => {
    setPdfViewDialog(false);
  };
  const handleEditDataforRedux = (item) => {
    const editedData = { ...item, edited: true };
    dispatch(setInvoiceData(editedData));
    // navigate("raise-invoice");
  };

  console.log("checkedItems::", checkedItems);
  return (
    <React.Fragment>
      <div className="border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>
                <Checkbox
                  className="cursor-pointer"
                  checked={
                    checkedItems.length === props?.allInvoicesData?.length
                      ? mainCheck
                      : false
                  }
                  onChange={(e) => onCheckboxChange(e, "mainCheck")}
                />
              </Th>
              <Th>
                <div className="flex gap-1">
                  <span
                    className="flex items-center gap-1"
                    onClick={() => props?.handleSort("xero_invoice_number")}
                  >
                    Invoice no.
                    <svg
                      className={`transform ${
                        props?.filtersort === "xero_invoice_number" &&
                        props?.filterorder === "asc"
                          ? "rotate-180"
                          : ""
                      }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        strokeWidth="1.333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Th>
              <Th>Amount</Th>
              <Th>Billing date</Th>
              <Th>Payment date</Th>
              <Th>Status</Th>
              <Th></Th>
            </Tr>
          </THead>
          <TBody>
            {props?.allInvoicesData?.map((item) => (
              <Tr
                // className={`${
                //   item?.status !== "Draft" ? "cursor-pointer" : ""
                // }`}
                key={item?.account_payment_id}
                onClick={() => item?.status != "Draft" && handleRowClick(item)}
              >
                <Td onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    className="cursor-pointer"
                    checked={checkedItems?.some(
                      (checkedItem) =>
                        checkedItem?.account_payment_id ===
                        item?.account_payment_id
                    )}
                    onChange={(e) => onCheckboxChange(e, item)}
                  />
                </Td>
                <Td>{item?.xero_invoice_number || "-"}</Td>
                <Td>{item?.amount}</Td>
                <Td>
                  {item?.created_date &&
                    moment(item?.created_date).format("DD/MM/YYYY")}
                </Td>
                <Td>{item?.paymentData}</Td>
                <Td>
                  {item.status === "complete" && (
                    <Tag className="bg-success-50 text-success-700">
                      <span>
                        <svg
                          width="11"
                          height="8"
                          viewBox="0 0 11 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.39355 0.981445L3.89355 6.48145L1.39355 3.98145"
                            stroke="#3DB77E"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>{" "}
                      Paid
                    </Tag>
                  )}
                  {item.status === "Overdue" && (
                    <Tag className="bg-error-50 text-error-700">
                      <span>
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_7446_51239)">
                            <path
                              d="M6.39355 4.98145V6.98145M6.39355 8.98145H6.39855M1.39355 5.24282V8.72007C1.39355 8.84237 1.39355 8.90352 1.40737 8.96106C1.41962 9.01208 1.43982 9.06085 1.46723 9.10559C1.49816 9.15605 1.54139 9.19928 1.62787 9.28576L4.08924 11.7471C4.17572 11.8336 4.21895 11.8768 4.26941 11.9078C4.31415 11.9352 4.36292 11.9554 4.41394 11.9676C4.47148 11.9814 4.53263 11.9814 4.65493 11.9814H8.13218C8.25448 11.9814 8.31563 11.9814 8.37317 11.9676C8.42419 11.9554 8.47296 11.9352 8.5177 11.9078C8.56816 11.8768 8.61139 11.8336 8.69787 11.7471L11.1592 9.28576C11.2457 9.19928 11.289 9.15605 11.3199 9.10559C11.3473 9.06085 11.3675 9.01208 11.3797 8.96106C11.3936 8.90352 11.3936 8.84237 11.3936 8.72007V5.24282C11.3936 5.12052 11.3936 5.05937 11.3797 5.00183C11.3675 4.95081 11.3473 4.90204 11.3199 4.8573C11.289 4.80684 11.2457 4.76361 11.1592 4.67713L8.69787 2.21576C8.61139 2.12928 8.56816 2.08605 8.5177 2.05513C8.47296 2.02771 8.42419 2.00751 8.37317 1.99526C8.31563 1.98145 8.25448 1.98145 8.13218 1.98145H4.65493C4.53263 1.98145 4.47148 1.98145 4.41394 1.99526C4.36292 2.00751 4.31415 2.02771 4.26941 2.05513C4.21895 2.08605 4.17572 2.12928 4.08924 2.21576L1.62787 4.67713C1.54139 4.76361 1.49816 4.80684 1.46723 4.8573C1.43982 4.90204 1.41962 4.95081 1.40737 5.00183C1.39355 5.05937 1.39355 5.12052 1.39355 5.24282Z"
                              stroke="#F04438"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_7446_51239">
                              <rect
                                width="12"
                                height="12"
                                fill="white"
                                transform="translate(0.393555 0.981445)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>{" "}
                      Overdue
                    </Tag>
                  )}
                  {item.status === "Upcoming" && (
                    <Tag className="bg-warning-50 text-warning-700">
                      <span>
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_18999_70113)">
                            <path
                              d="M11.35 5.75L10.3503 6.75L9.34998 5.75M10.4725 6.5C10.4907 6.33583 10.5 6.169 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C3.51472 1.5 1.5 3.51472 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5C7.41363 10.5 8.67502 9.84817 9.5 8.82867M6 3.5V6L7.5 7"
                              stroke="#F79009"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_18999_70113">
                              <rect width="12" height="12" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>{" "}
                      Upcoming
                    </Tag>
                  )}

                  {/* {item.status === "Draft" && (
                    <Tag className="bg-blue-100 text-blue-700">
                      <span>
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_18999_37916)">
                            <path
                              d="M1.25 10.7502L4.02463 9.68299C4.2021 9.61473 4.29084 9.5806 4.37386 9.53603C4.4476 9.49645 4.5179 9.45077 4.58402 9.39946C4.65846 9.34169 4.72569 9.27447 4.86014 9.14001L10.5 3.50015C11.0523 2.94787 11.0523 2.05244 10.5 1.50015C9.94773 0.947867 9.0523 0.947866 8.50001 1.50015L2.86014 7.14001C2.72569 7.27446 2.65846 7.34169 2.6007 7.41613C2.54939 7.48225 2.5037 7.55255 2.46412 7.62629C2.41955 7.70931 2.38542 7.79805 2.31717 7.97552L1.25 10.7502ZM1.25 10.7502L2.27906 8.07463C2.3527 7.88317 2.38952 7.78744 2.45267 7.74359C2.50786 7.70527 2.57615 7.69078 2.64215 7.70338C2.71767 7.7178 2.79019 7.79033 2.93524 7.93538L4.06479 9.06493C4.20984 9.20997 4.28236 9.2825 4.29678 9.35802C4.30939 9.42401 4.29489 9.49231 4.25657 9.5475C4.21272 9.61065 4.11699 9.64747 3.92553 9.72111L1.25 10.7502Z"
                              stroke="#0E4E9A"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_18999_37916">
                              <rect width="12" height="12" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Draft
                    </Tag>
                  )} */}
                </Td>
                <Td onClick={(e) => e.stopPropagation()}>
                  <div className="flex gap-2 reset-dropdown">
                    <Button onClick={() => downloadSingleInvoice(item)}>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 11.4297V12.4297C16.5 13.8298 16.5 14.5299 16.2275 15.0647C15.9878 15.5351 15.6054 15.9175 15.135 16.1572C14.6002 16.4297 13.9001 16.4297 12.5 16.4297H5.5C4.09987 16.4297 3.3998 16.4297 2.86502 16.1572C2.39462 15.9175 2.01217 15.5351 1.77248 15.0647C1.5 14.5299 1.5 13.8298 1.5 12.4297V11.4297M13.1667 7.26302L9 11.4297M9 11.4297L4.83333 7.26302M9 11.4297V1.42969"
                          stroke="#344054"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </div>
                </Td>
              </Tr>
            ))}
          </TBody>
        </Table>
      </div>

      {checkedItems?.length > 0 && (
        <>
          <div className="font-semibold my-5">
            <ul
              className="flex items-center justify-center w-[425px] h-[56px] rounded-[110px] p-2 mx-auto my-auto"
              style={{ boxShadow: "0px 0px 24px 0px rgb(214, 221, 237)" }}
            >
              <li
                className="p-2"
                style={{ color: "#5786CC", position: "relative" }}
              >
                <Button>{`${checkedItems?.length} selected`}</Button>
                <div
                  className="absolute right-0 top-1/2 transform -translate-y-1/2"
                  style={{
                    width: "2px",
                    height: "36px",
                    backgroundColor: "#F2F4F7",
                  }}
                />
              </li>
              <li
                onClick={() => {
                  if (
                    checkedItems?.length === 1 &&
                    checkedItems[0]?.status === "Draft"
                  ) {
                  } else {
                    downloadSelectedInvoices();
                  }
                }}
                className={`p-2 flex gap-1 cursor-pointer ${
                  checkedItems?.every((item) => item?.status === "Draft")
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                <div
                  className="absolute right-0 top-1/2 transform -translate-y-1/2"
                  style={{
                    width: "2px",
                    height: "36px",
                    backgroundColor: "#F2F4F7",
                  }}
                />
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5 11.4297V12.4297C16.5 13.8298 16.5 14.5299 16.2275 15.0647C15.9878 15.5351 15.6054 15.9175 15.135 16.1572C14.6002 16.4297 13.9001 16.4297 12.5 16.4297H5.5C4.09987 16.4297 3.3998 16.4297 2.86502 16.1572C2.39462 15.9175 2.01217 15.5351 1.77248 15.0647C1.5 14.5299 1.5 13.8298 1.5 12.4297V11.4297M13.1667 7.26302L9 11.4297M9 11.4297L4.83333 7.26302M9 11.4297V1.42969"
                    stroke="#344054"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>Download</span>
              </li>
            </ul>
          </div>
        </>
      )}

      <Dialog
        isOpen={pdfDownloadSuccessDialog}
        onClose={closeDownloadSuccessPdf}
        onRequestClose={closeDownloadSuccessPdf}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.894531"
                y="0.585938"
                width="48"
                height="48"
                rx="24"
                fill="#D1FADF"
              />
              <path
                d="M26.8945 14.8555V18.986C26.8945 19.5461 26.8945 19.8261 27.0035 20.04C27.0994 20.2282 27.2524 20.3811 27.4405 20.477C27.6545 20.586 27.9345 20.586 28.4945 20.586H32.6251M28.8945 25.5859H20.8945M28.8945 29.5859H20.8945M22.8945 21.5859H20.8945M26.8945 14.5859H21.6945C20.0144 14.5859 19.1743 14.5859 18.5326 14.9129C17.9681 15.2005 17.5091 15.6595 17.2215 16.224C16.8945 16.8657 16.8945 17.7058 16.8945 19.3859V29.7859C16.8945 31.4661 16.8945 32.3062 17.2215 32.9479C17.5091 33.5124 17.9681 33.9713 18.5326 34.259C19.1743 34.5859 20.0144 34.5859 21.6945 34.5859H28.0945C29.7747 34.5859 30.6148 34.5859 31.2565 34.259C31.821 33.9713 32.2799 33.5124 32.5676 32.9479C32.8945 32.3062 32.8945 31.4661 32.8945 29.7859V20.5859L26.8945 14.5859Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Selected PDFs downloaded successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => closeDownloadSuccessPdf()}>Close</BtnLight>
        </div>
      </Dialog>
      <Dialog
        isOpen={pdfViewDialog}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="documentDialog"
      >
        {/* <ViewInvoice
          invoicesData={invoicesData}
          onDialogClose={onDialogClose}
        /> */}
      </Dialog>
    </React.Fragment>
  );
};

export default InvoiceList;
