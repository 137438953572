import React, { useCallback, useEffect, useState } from "react";
import Tabs from "../../../../../../common/element/Tabs";
import Table from "../../../../../../common/element/Table";
import Badge from "../../../../../../common/element/Badge";
import { useNavigate, Link } from "react-router-dom";
import { Pagination } from "../../../../../../common/pagination";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";
// import { GetAllGlobalProvidersForClient } from "../../../../../../actions/client-portal/AllContractorApi";
import { GetAllProvidersOfClientAndDetails } from "../../../../../../actions/client-portal/AllContractorApi";
import { encryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";
import Tag from "../../../../../../common/element/Tag";
const { TabNav, TabList, TabContent } = Tabs;
const { Tr, Th, Td, THead, TBody } = Table;
const ContractorList = (props) => {
  const navigate = useNavigate();
  let function_assignment_id;
  let contractor_permissions;
  let user_id;
  let trading_name;
  let function_id;
  let organisation_id;
  const dispatch = useDispatch();
  const AllContactType = useSelector((state) => state?.AllContactTypes);
  const [contactTypeId, setContactTypeId] = useState("");
  const [allContractorData, setAllContractorData] = useState([]);
  const [countData, setCountData] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [apiCalls, setApiCalls] = useState(true);
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  const permissionData = JSON.parse(localStorage.getItem("permission_data"));
  if (permissionData !== null) {
    contractor_permissions = permissionData.provider_write;
  }
  if (storedData !== null) {
    user_id = storedData?.userName?.user_id;
    function_assignment_id = storedData.function_assignment_id;
    function_id = storedData.functionId;
    trading_name = storedData.trading_name;
    organisation_id = storedData.organisation_id;
  }
  const [getAllinvitedData, setGetAllinvitedData] = useState({
    contact_type_id: contactTypeId || "",
    page: 1,
    limit: 10,
    sort: "",
    order: "desc",
    search: props?.getAllinvitedData?.search
      ? props?.getAllinvitedData?.search
      : "",
    client_fa_id: function_assignment_id,
    client_org_id: organisation_id,
  });
  useEffect(() => {
    if (AllContactType) {
      const contactTypeId = AllContactType.find(
        (item) => item.contact_type === "provider primary"
      )?.contact_type_id;
      setContactTypeId(contactTypeId);
    }
  }, []);

  useEffect(() => {
    setGetAllinvitedData((prevData) => ({
      ...prevData,
      page: 1,
      contact_type_id: contactTypeId,
      search: props?.getAllinvitedData?.search
        ? props?.getAllinvitedData?.search
        : "",
    }));
  }, [contactTypeId, props?.getAllinvitedData?.search]);

  const handleRowClick = (organisation_id) => {
    if (organisation_id) {
      // Usage example
      const encryptedId = encryptId(organisation_id);
      // Navigate to the specific provider page without refresh
      navigate(`specific-provider/${encodeURIComponent(encryptedId)}`);
    }
  };

  const GetAllProviderDataofclient = useCallback(() => {
    if (
      contactTypeId &&
      getAllinvitedData?.contact_type_id &&
      (props?.getAllinvitedData?.search == "" ||
        props?.getAllinvitedData?.search)
    ) {
      dispatch(GetAllProvidersOfClientAndDetails(getAllinvitedData)).then(
        (response) => {
          if (response.success) {
            const arrayData = response?.data?.rows;
            setCountData(response?.data?.count);
            setAllContractorData(arrayData);
            setTotalPages(
              Math.ceil(response?.data?.count / getAllinvitedData?.limit)
            );
          } else if (response.status === 401) {
            // handleUnauthorizedAccess()
          }
        }
      );
    }
  }, [dispatch, getAllinvitedData, contactTypeId]);

  useEffect(() => {
    GetAllProviderDataofclient();
  }, [GetAllProviderDataofclient]);

  const handleSort = (column) => {
    if (column === getAllinvitedData.sort) {
      setGetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    } else {
      setGetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
      setGetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    }
  };
  const updatePageLimit = (newLimit) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      setGetAllinvitedData((prevState) => ({
        ...prevState,
        limit: newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10,
        page: 1,
      }));
      setTotalPages(
        Math.ceil(
          countData / newLimit?.limit
            ? newLimit?.limit
            : newLimit
            ? newLimit
            : 10
        )
      );
    }
  };

  const updatePage = (newPage) => {
    if (apiCalls == true) {
      setApiCalls(false);
    } else {
      setGetAllinvitedData((prevState) => ({
        ...prevState,
        page: newPage?.page ? newPage?.page : newPage ? newPage : 1,
      }));
    }
  };
  const handlePageChange = ({ selected }) => {
    setGetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(Math.ceil(countData / getAllinvitedData.limit));
  };
  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className="w-60" onClick={() => handleSort("trading_name")}>
                <span className="flex items-center gap-1">
                  Provider
                  <svg
                    className={`transform ${
                      getAllinvitedData.sort === "trading_name" &&
                      getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th>

              <Th className="w-48">
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("provider_type_name")}
                >
                  Provider type
                  <svg
                    className={`transform ${
                      getAllinvitedData.sort === "provider_type_name" &&
                      getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      stroke-width="1.333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-48">Services</Th>
              <Th className="w-48">ABN/NZBN</Th>
              <Th className="w-48">Suburb</Th>
              <Th className="w-48">State</Th>
              <Th className="w-48">Country</Th>

              <Th className="w-48">
                {/* <span onClick={() => handleSort("phone")} className="flex items-center gap-1"> */}
                Contact no.
                {/* <svg
                              className={`transform ${getAllinvitedData.sort === "trading_name" &&
                                 getAllinvitedData.order === "asc"
                                 ? "rotate-180"
                                 : ""
                                 }`}
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                 stroke="#475467"
                                 stroke-width="1.333"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                              />
                           </svg>
                        </span> */}
              </Th>
              <Th className="w-48">Status</Th>
            </Tr>
          </THead>
          <TBody>
            {allContractorData &&
              allContractorData.map((item) => (
                <Tr
                  key={item?.provider_org_id}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRowClick(item?.provider_org_id)}
                >
                  <Td>{item?.trading_name ? item?.trading_name : "-"}</Td>
                  <Td>
                    {item?.provider_type_name ? item?.provider_type_name : "-"}
                  </Td>
                  <Td>{item?.meta_value_one ? item?.meta_value_one : "-"}</Td>
                  <Td>{item?.abn_nzbn ? item?.abn_nzbn : "-"}</Td>
                  <Td>{item?.suburb ? item?.suburb : "-"}</Td>
                  <Td>{item?.state_name ? item?.state_name : "-"}</Td>
                  <Td>{item?.country_name ? item?.country_name : "-"}</Td>
                  <Td>{item?.phone ? item?.phone : "-"}</Td>
                  <Td>
                    {item?.docs_status == "Non-compliant" ? (
                      <Tag className="bg-error-50 text-error-700">
                        Non-compliant
                      </Tag>
                    ) : (
                      <Tag className="bg-success-50 text-success-700">
                        Compliant
                      </Tag>
                    )}
                  </Td>
                </Tr>
              ))}
          </TBody>
        </Table>
      </div>

      <div className="flex justify-between mt-3">
        <Pagination
          totalCount={countData}
          pageNumber={getAllinvitedData.page}
          displayPage={allContractorData?.length}
          UpdatePageLimit={setGetAllinvitedData}
          allfilters={getAllinvitedData}
          // UpdatePageLimit={updatePageLimit}
          // UpdatePage={updatePage}
        />

        {countData > 0 && (
          <ReactPaginate
            className="flex justify-content-end p-2 align-items-center pagination-wrap"
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLinkClassName={"prev-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"prev-link"}
            previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
            nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
            pageLinkClassName={"px-4 rounded-md"}
            activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
            disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
            renderOnZeroPageCount={null}
            forcePage={getAllinvitedData.page - 1}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ContractorList;
