import Card from "../../../common/element/Card";
import Heading from "../../../common/element/Heading";
import Table from "../../../common/element/Table";
import TBody from "../../../common/element/Table/TBody";
import Tr from "../../../common/element/Table/Tr";
import THead from "../../../common/element/Table/THead";
import Th from "../../../common/element/Table/Th";
import Td from "../../../common/element/Table/Td";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { GetInvitedProviderChecklist } from "../../../actions/provider-portal/registration/GetInvitedProviderChecklist";
import BtnPrimary from "../../../common/element/Buttons/btnPrimary";
import {
  GetProviderContactData,
  ResetProviderContactData,
} from "../../../redux/classes/provider-portal/ProviderContactData";
import Login from "../../auth/Login/Login";
import { UpdateCheckExistUser } from "../../../redux/classes/provider-portal/checkExistUser";
import { GetProviderPrimaryUserCheck } from "../../../actions/provider-portal/registration/GetProviderPrimaryUserCheck";
import { RegisterExistingProviderForClient } from "../../../actions/provider-portal/registration/RegisterExistingProviderForClient";
import { GetProviderSignUpData } from "../../../redux/classes/provider-portal/SignUp";
import { decryptId } from "../../../common/encryptDecrypt/encryptdecrypt";
import { GetProviderExistingData } from "../../../redux/classes/provider-portal/ProviderExistingUserData";
// import { GetProviderInvitationById } from "../../../actions/public/GetContractorInvitationById";
const WelcomeToConserveDoc = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inviteProviderId = decryptId(
    searchParams.get("invite_provider_id").replace(/\s/g, "+")
  );
  const [docData, SetDocData] = useState([]);
  const [email, SetEmail] = useState("");
  const [isUser, SetIsUser] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ResetProviderContactData());
    dispatch(
      GetInvitedProviderChecklist({ invite_provider_id: inviteProviderId })
    ).then((response) => {
      if (response.success) {
        let inviteData = response?.data?.inviteData;
        let is_invited_by_admin =
          response?.data?.inviteData?.is_invited_by_admin;
        if (
          response?.data?.inviteData?.invite_status == "Active" ||
          response?.data?.inviteData?.is_regis_attempt
        ) {
          navigate("/expire-link");
        }
        
        if (
          response?.data?.userValidate?.is_worker ||
          response?.data?.userValidate?.fa_user_permission_id
        ) {
          dispatch(
            GetProviderPrimaryUserCheck({
              // user_id: response?.data?.userValidate?.user_id,
              individual_id: inviteData?.individual_id,
            })
          ).then((response) => {
            if (response.success) {
              if (response?.data?.provider_org_id) {
                let UpdateData = {
                  ...inviteData,
                  ...{
                    provider_org_id: response?.data?.provider_org_id,
                    is_invited_by_admin: is_invited_by_admin,
                  },
                };

                let checkListData = {
                  client_org_id:
                    inviteData?.provInviteClient?.org_data?.organisation_id,
                  client_fa_id: inviteData?.client_fa_id,
                  invite_provider_id: inviteData?.invite_provider_id,
                  provider_org_id: response?.data?.provider_org_id,
                  client_contact_data: inviteData?.ProvInvitedBy,
                  provider_type_id: inviteData?.provider_type_id,
                };
                dispatch(GetProviderExistingData(checkListData));
                // dispatch(RegisterExistingProviderForClient(checkListData));
                dispatch(GetProviderContactData(UpdateData));
                SetIsUser(true);
                dispatch(
                  UpdateCheckExistUser({
                    checkUser: false,
                    path: "",
                    login: false,
                    checkAcceptUser: true,
                  })
                );
              } else {
                SetIsUser(false);
              }
            }
          });
        } else {
          SetIsUser(false);
        }
        let UpdateData = {
          ...inviteData,
          is_invited_by_admin: is_invited_by_admin,
        };
        SetEmail(response?.data?.inviteData?.email);
        SetDocData(response?.data?.provChecklist);
        dispatch(GetProviderContactData(UpdateData));
      } else {
        navigate("/expire-link");
      }
    });

    // dispatch(
    //   GetProviderInvitationById({ invite_provider_id: inviteProviderId })
    // ).then((response) => {
    //   if (response.success) {
    //     SetDocData(response.data);
    //   }else{
    //     navigate("/expire-link")
    //   }
    // });
    dispatch(GetProviderSignUpData({ registrationType: "" }));
  }, []);

  if (isUser) {
    return <Login email={email} reject={true} />;
  } else {
    return (
      <div
        style={{ backgroundColor: "#eff8ff" }}
        className="flex justify-center min-h-screen"
      >
        <div className="min-h-screen justify-center flex  py-12 px-6">
          <div className="flex-col items-center justify-center flex">
            <div className="flex items-center mb-6">
              <img
                className="brand-img"
                src="/assets/logo.svg"
                alt="conserve"
              />
            </div>
            <Heading className="text-center mb-3">Welcome to Conserve</Heading>
            <p className="text-base text-gray-600 text-center mb-6 font-medium">
              Prior to continuing, kindly note that the following documents must
              be uploaded.
            </p>
            <Card className="w-500 rounded-xl block-shadow w-6/12	mt-2 px-10 py-8">
              <div className="w-full">
                <div className="mb-3">
                  <h4 className="text-blue-800 font-semibold text-base mb-1">
                    Compliance checklist
                  </h4>
                  <p className="text-gray-700">
                    The Compliance checklist ensures that you meet all the
                    necessary requirements set by the client, allowing you to
                    demonstrate your compliance with their specified guidelines
                    and regulations.
                  </p>
                </div>
                <div className="overflow-hidden border rounded-lg border-gray-200">
                  <Table>
                    <THead>
                      <Tr>
                        <Th>Document</Th>
                      </Tr>
                    </THead>
                    <TBody>
                      {docData?.flatMap?.((doc, indexData) =>
                        doc?.inviteProviComp?.map((item) => {
                          return (
                            <Tr key={indexData}>
                              <Td>
                                {item?.is_other_doc
                                  ? item?.other_doc_name
                                  : item?.document_type?.doc_type_name}
                              </Td>
                            </Tr>
                          );
                        })
                      )}
                    </TBody>
                  </Table>
                  {docData?.length == 0 && (
                    <div className="h-full w-full text-center mt-2 mb-2">
                      No Data Available!
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-between w-100 mt-5">
                <BtnPrimary>
                  <Link to={`${inviteProviderId}/provider-password-creation`}>
                    Understood, I'm ready to proceed
                  </Link>
                </BtnPrimary>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
};
export default WelcomeToConserveDoc;
