import React, { useEffect, useState } from "react";
import Card from "../../../../common/element/Card";
import Heading from "../../../../common/element/Heading";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Button from "../../../../common/element/Buttons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Validations from "../../../../common/404/Validations";
import { SignupProviderEmailVerification } from "../../../../actions/Auth";
import { VerifyEmail } from "../../../../actions/public/VerifyEmail";
import Loader from "../../../../common/loader";
import { useSelector } from "react-redux";
import { GetProviderSignUpData } from "../../../../redux/classes/provider-portal/SignUp";
import { ResetProviderContactData } from "../../../../redux/classes/provider-portal/ProviderContactData";

const PasswordCreation = () => {
  const url = window.location.pathname; // Get the full path
  const forClient = url.split("/")[3]; // Extract 'forClient'
  const { SignUpData } = useSelector((state) => state);
  const { email, token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, SetLoader] = useState(false);
  const [checkPage, SetCheckPage] = useState(false);
  const [hiddenpassword, sethiddenpassword] = useState({
    Password: true,
    ConfirmPassword: true,
  });
  const [formData, SetFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [formErrorData, SetFormErrorData] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e, name) => {
    SetFormErrorData((prevalue) => {
      return {
        ...prevalue,
        [name]: "",
      };
    });
    SetFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: e.target.value,
      };
    });
  };

  const SubmitData = () => {
    dispatch(ResetProviderContactData());
    //Validate Email
    SetLoader(true);
    const password = Validations({
      value: formData.password,
      Validations: { required: true, password: true },
    });
    if (!password.valid) {
      SetFormErrorData((prevalue) => {
        return {
          ...prevalue,
          ["password"]: password.error_msg,
        };
      });
    }
    const checkBothpaswd = Validations({
      value:
        formData.password == formData.confirmPassword
          ? formData.confirmPassword
          : "",
      Validations: { required: true, min_lenght: 8, password: true },
    });

    const confirmPassword = Validations({
      type: "password",
      valid: true,
      error_msg: "",
      value: formData.confirmPassword,
      Validations: { required: true, min_lenght: 8, password: true },
    });

    if (!confirmPassword.valid || !checkBothpaswd.valid) {
      let error;
      if (!confirmPassword.valid) {
        error = confirmPassword.error_msg;
      } else {
        error = "Passwords do NOT match!";
      }
      SetFormErrorData((prevalue) => {
        return {
          ...prevalue,
          ["confirmPassword"]: error,
        };
      });
    }

    if (
      confirmPassword.valid === true &&
      password.valid === true &&
      checkBothpaswd.valid === true
    ) {
      let password = { password: formData?.password };

      // Merge password with SignUpData and add registrationType

      if (forClient == "yourself") {
        let data = {
          ...password,
          ...SignUpData,
          registrationType: "yourself",
        };
        dispatch(GetProviderSignUpData(data));
        navigate("/provider-registration-yourself");
      } else {
        let data = {
          ...password,
          ...SignUpData,
          registrationType: "forClient",
        };
        dispatch(GetProviderSignUpData(data));
        navigate("/provider-registration");
      }

      // dispatch(SignupProviderEmailVerification(data)).then((response) => {
      //   if (response.success) {
      //     navigate("/sign-up/inbox-verification");
      //     SetLoader(false);
      //   } else {
      //     SetLoader(false);
      //   }
      // });
    } else {
      SetLoader(false);
    }
  };
  useEffect(() => {
    dispatch(VerifyEmail({ email: email, token: token })).then((response) => {
      if (response.status == 400) {
        navigate("/email-verification/expired");
      }
      if (response.success) {
        if (response.data.user_id == null) {
          let individual_data = {
            user_id: response?.data?.user_id,
            individual_id: response?.data?.individual_id,
          };
          let data = {
            ...SignUpData,
            ...individual_data,
          };
          dispatch(GetProviderSignUpData(data));
          SetCheckPage(true);
        } else {
          if (SignUpData.registrationType == "yourself") {
            navigate("/provider-registration-yourself");
          } else {
            navigate("/provider-registration");
          }
        }
      }
    });
  }, []);

  const togglePassword = (field) => {
    sethiddenpassword((prevalue) => {
      return {
        ...prevalue,
        [field]:
          field == "Password"
            ? !hiddenpassword.Password
            : !hiddenpassword.ConfirmPassword,
      };
    });
  };
  if (checkPage) {
    return (
      <div
        style={{ backgroundColor: `#eff8ff` }}
        className="flex justify-center min-h-screen pr-6 pl-6"
      >
        <div className="min-h-screen justify-center flex  py-12 px-4">
          <div className="flex-col items-center justify-center flex">
            <div className="flex items-center mb-6">
              <img
                className="brand-img"
                src="/assets/logo.svg"
                alt="conserve"
              />
            </div>
            <Heading className="text-center mb-3">
              Email confirmed!
              <br />
              Create your password
            </Heading>
            <Card className="w-500 rounded-xl   block-shadow w-100 mt-7 px-10 py-8">
              <div className="w-full">
                <form>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700  mb-2 text-xs font-medium"
                      htmlFor="Email"
                    >
                      Password*
                    </label>
                    <div className="relative flex">
                      <input
                        className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 pr-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type={hiddenpassword.Password ? "password" : "text"}
                        placeholder=""
                        onChange={(e) => {
                          handleChange(e, "password");
                        }}
                      />
                      <div className="input-suffix-start right-set">
                        <span
                          className="gray-svg cursor-pointer"
                          onClick={() => togglePassword("Password")}
                        >
                          {hiddenpassword.Password ? (
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              height="21"
                              width="20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                              ></path>
                            </svg>
                          ) : (
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                    </div>
                    {formErrorData.password && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrorData.password}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700  mb-2 text-xs font-medium"
                      htmlFor="password"
                    >
                      Confirm Password*
                    </label>
                    <div className="relative flex">
                      <input
                        className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type={
                          hiddenpassword.ConfirmPassword ? "password" : "text"
                        }
                        placeholder=""
                        onChange={(e) => {
                          handleChange(e, "confirmPassword");
                        }}
                      />
                      <div className="input-suffix-start right-set">
                        <span
                          className="gray-svg cursor-pointer"
                          onClick={() => togglePassword("ConfirmPassword")}
                        >
                          {hiddenpassword.ConfirmPassword ? (
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              height="21"
                              width="20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                              ></path>
                            </svg>
                          ) : (
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                    </div>
                    {formErrorData.confirmPassword && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrorData.confirmPassword}
                      </p>
                    )}
                  </div>

                  <div className="flex items-center justify-between w-full">
                    <BtnPrimary onClick={SubmitData} disabled={loader}>
                      <div className="flex justify-center">
                        <span className="pr-2">Continue </span>
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.167 10.487h11.666m0 0L10 4.654m5.833 5.833L10 16.321"
                            stroke="#fff"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </BtnPrimary>
                  </div>
                </form>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  } else {
    return <Loader />;
  }
};

export default PasswordCreation;
