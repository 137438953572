import React from "react";
import Menu from "../../../../../common/element/Menu";
import { NavLink } from "react-router-dom";
const Worker = () => {
  const handleToggle = (expanded, e) => {
  };
  return (
    <div className="" style={{ maxWidth: 176 }}>
      <Menu>
        <Menu.MenuItem eventKey="item-1"><NavLink to="worker/dashboard">Dashboard</NavLink></Menu.MenuItem>
        <Menu.MenuItem eventKey="item-2"><NavLink to="worker/view-all-worker">View All</NavLink> </Menu.MenuItem>
        <Menu.MenuItem eventKey="item-3"><NavLink to="worker/reports">Reports</NavLink> </Menu.MenuItem>
      </Menu>
    </div>
  );
};

export default Worker;
