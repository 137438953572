import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Switcher from "../../../../../../../common/element/Switcher";
import ScrollBar from "../../../../../../../common/element/ScrollBar";
// import { notify } from "react-notify-toast";
import * as userActions from "../../../../../../../actions/index";
import { useParams } from "react-router-dom";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../../../../common/element/Dialog";
import ThreeDotLoader from "../../../../../../../common/element/ButtonLoader/ThreeDotLoader";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import { decryptId } from "../../../../../../../common/encryptDecrypt/encryptdecrypt";
const Accreditations = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const orgId = decryptId(id)

  const dialogRef = useRef(null);
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [AllowContractorAC, setContractorAC] = useState();
  const [sendACRecievedInfo, setACRecievedInfo] = useState();
  const [dialogUserUpdate, setDialogUserUpdate] = useState(false);
  const [showWorkerICPhoto, setWorkerICPhoto] = useState();
  const [showClientLogoIC, setClientLogoIC] = useState();
  const [showWorkerICBack, setWorkerICBack] = useState();
  const [clientDetailId, setClientDetailId] = useState("");
  const [unSaveDialogIsOpen, unSaveSetIsOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const openDialogUnSave = () => {
    if (hasChanges) {
      unSaveSetIsOpen(true);
    }
  };
  const onDialogCloseUnSave = () => {
    unSaveSetIsOpen(false);
  };
  const onCloseNotUpdateValue = () => {
    props?.userSettingHide();
  }
  const onUpdateDialogClose = () => {
    setDialogUserUpdate(false);
    props?.userSettingHide();
  };

  // Effect to listen for clicks outside of the component to trigger unsaved changes
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dialogRef.current && !dialogRef.current.contains(e.target)) {
        openDialogUnSave();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hasChanges]);

  const onDialogExitUnSave = () => {
    unSaveSetIsOpen(false);
    props?.userSettingHide();
  };

  // function to toggle the state
  const onSwitcherToggle = (e, identifier) => {
    if (identifier === "AllowContractorAC") {
      setContractorAC(!AllowContractorAC);
    }
    if (identifier === "sendACRecievedInfo") {
      setACRecievedInfo(!sendACRecievedInfo);
    }
    if (identifier === "showWorkerICPhoto") {
      setWorkerICPhoto(!showWorkerICPhoto);
    }
    if (identifier === "showClientLogoIC") {
      setClientLogoIC(!showClientLogoIC);
    }
    if (identifier === "showWorkerICBack") {
      setWorkerICBack(!showWorkerICBack);
    }
    setHasChanges(true);
  };

  const GetClientOtherDetailsById = useCallback(async () => {

    dispatch(userActions.GetClientOtherDetailsById(orgId))
      .then((response) => {
        if (response?.success === true) {
          const arrayData = response?.data;
          setContractorAC(arrayData?.allow_receive_ac);
          setACRecievedInfo(arrayData?.send_client_confirm_ac);
          setWorkerICPhoto(arrayData?.show_worker_ic_photo);
          setClientLogoIC(arrayData?.show_worker_ic_client_logo);
          setWorkerICBack(arrayData?.show_worker_ic_back);
          setClientDetailId(arrayData?.client_detail_id);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        } else {
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {

      });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetClientOtherDetailsById();
  }, []);

  const submitAccreditation = (e) => {
    const payload = {
      client_detail_id: clientDetailId,
      allow_receive_ac: AllowContractorAC,
      send_client_confirm_ac: sendACRecievedInfo,
      show_worker_ic_photo: showWorkerICPhoto,
      show_worker_ic_client_logo: showClientLogoIC,
      show_worker_ic_back: showWorkerICBack,
    };
    setButtonDisabled(true);
    props.onUpdateClientAccreditations(payload).then((response) => {
      if (response?.success === true) {
        // props.userSettingHide(); 
        setDialogUserUpdate(true)
        setButtonDisabled(false);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
      } else {
      }
    }).catch((error) => {
      console.error('Error:', error);
    })
      .finally(() => {
        setButtonDisabled(false);
      });
  };

  return (
    <React.Fragment>
      <div className="h-500 pr-1">
        <ScrollBar>
          <div className="pr-4 mt-7">
            <h5 className="font-semibold text-xl text-gray-900 mb-6">
              Provider Accreditation Certificate
            </h5>
            <div className="flex flex-wrap items-center mb-6">
              <Switcher
                onChange={(e) => onSwitcherToggle(e, "AllowContractorAC")}
                checked={AllowContractorAC}
                value={AllowContractorAC}
              />
              <span className="text-xs text-gray-700 font-medium ml-2">
                Allow Providers to receive Accreditation Certificates
              </span>
            </div>
            <div className="flex flex-wrap items-center mb-6">
              <Switcher
                checked={sendACRecievedInfo}
                value={sendACRecievedInfo}
                onChange={(e) => onSwitcherToggle(e, "sendACRecievedInfo")}
              />
              <span className="text-xs text-gray-700 font-medium ml-2">
                Send Client confirmation of Provider receiving Accreditation
                Certificates
              </span>
            </div>
            <div className="border-t border-gray-200 pt-6 mb-6">
              <h5 className="font-semibold text-xl text-gray-900 mb-1">
                Worker Induction Card
              </h5>
              <p className="text-gray-600 font-medium  mb-6">
                Manage what will show on Workers Induction Cards
              </p>
              <div className="flex flex-wrap items-center mb-6">
                <Switcher
                  checked={showWorkerICPhoto}
                  value={showWorkerICPhoto}
                  onChange={(e) => onSwitcherToggle(e, "showWorkerICPhoto")}
                />
                <span className="text-xs text-gray-700 font-medium ml-2">
                  Show Photo on Induction Card
                </span>
              </div>
              <div className="flex flex-wrap items-center mb-6">
                <Switcher
                  checked={showClientLogoIC}
                  value={showClientLogoIC}
                  onChange={(e) => onSwitcherToggle(e, "showClientLogoIC")}
                />
                <span className="text-xs text-gray-700 font-medium ml-2">
                  Show Client Logo on Induction Card
                </span>
              </div>
              <div className="flex flex-wrap items-center mb-6">
                <Switcher
                  checked={showWorkerICBack}
                  value={showWorkerICBack}
                  onChange={(e) => onSwitcherToggle(e, "showWorkerICBack")}
                />
                <span className="text-xs text-gray-700 font-medium ml-2">
                  Show Induction Card Back
                </span>
              </div>
            </div>
          </div>
        </ScrollBar>
      </div>
      <div className="flex p-6 justify-end border-gray-200">
        <BtnLight
          onClick={() => { hasChanges ? openDialogUnSave() : onCloseNotUpdateValue() }}
          disabled={buttonDisabled}
          className="w-auto px-5 mr-3"
          variant="plain"
        >
          Close
        </BtnLight>
        <BtnPrimary
          onClick={() => submitAccreditation()}
          className="w-auto px-5"
          disabled={buttonDisabled}
        >
          {buttonDisabled ? <ThreeDotLoader /> : 'Save changes'}
        </BtnPrimary>
      </div>


      <Dialog
        isOpen={unSaveDialogIsOpen}
        onClose={onDialogCloseUnSave}
        onRequestClose={onDialogCloseUnSave}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >

        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-warning-100 flex items-center justify-center">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 3.5v3.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C8.26 8.5 8.54 8.5 9.1 8.5h6.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437c.109-.214.109-.494.109-1.054V4.5m0 17v-6.4c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437c-.214-.109-.494-.109-1.054-.109H9.1c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C7.5 14.26 7.5 14.54 7.5 15.1v6.4m14-11.675V16.7c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327H8.3c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3.5 19.22 3.5 18.38 3.5 16.7V8.3c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C5.78 3.5 6.62 3.5 8.3 3.5h6.874c.49 0 .734 0 .965.055.204.05.399.13.578.24.201.123.374.296.72.642l3.126 3.126c.346.346.519.519.642.72.11.18.19.374.24.579.055.23.055.474.055.963z"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
            You have unsaved changes
          </h4>
          <p className=" text-gray-600 text-center mt-1">
            Save your information before leaving?
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => onDialogExitUnSave()} className="mr-1.5">
            Exit
          </BtnLight>
          <BtnPrimary className="ml-1.5" onClick={() => submitAccreditation()}>
            Save
          </BtnPrimary>
        </div>
      </Dialog>

      <Dialog
        isOpen={dialogUserUpdate}
        bodyOpenClassName="overflow-hidden"
        onClose={onUpdateDialogClose}
        onRequestClose={onUpdateDialogClose}
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Accreditation updated successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onUpdateDialogClose}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
});

function mapStateToProps(state) {
  return {
    loader: userActions.UpdateClientAccreditations.loader,
  };
}
const mapDispatchToProps = {
  onUpdateClientAccreditations: userActions.UpdateClientAccreditations,
};

export default connect(mapStateToProps, mapDispatchToProps)(Accreditations);
