import React, { useCallback, useEffect, useState } from "react";
import ClientsList from "./ClientList";
import ClientInput from "../../../../common/element/Input/clientInput";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "../../../../common/snippet/Debouncing";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
import { Pagination } from "../../../../common/pagination";
import { GetAllClientsOfProvider } from "../../../../actions/provider-portal/GetAllClientsOfProvider";
import Segment from "../../../../common/element/Segment";
import { GetProviderAllClientInvites } from "../../../../actions/admin-provider/clients/GetProviderAllClientInvites";
import InvitedList from "./invited";
import { el } from "date-fns/locale";
import Heading from "../../../../common/element/Heading";
const ContractorClients = () => {
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));

  const { id } = useParams();
  const dispatch = useDispatch();
  const [invitedData, setInvitedData] = useState([]);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Client",
          url: "/provider/client",
          home: "/provider/dashboard",
        }
      ])
    );
  }, []);
  const [totalPages, setTotalPages] = useState(1);
  
  // FilTER DATA SHOW
  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    provider_fa_id: loginUserData?.function_assignment_id,
    provider_org_id: loginUserData?.organisation_id,
    page: 1,
    limit: 10,
    sort: "created_date",
    order: "desc",
    search: "",
  });

  // All ReduxFunction
  useEffect(() => {
    dispatch(GetAllClientsOfProvider(getAllinvitedData));
  }, [getAllinvitedData]);
  const GetFunction = useSelector((state) => state);

  const handleSort = (column) => {
    // if (column === getAllinvitedData.sort) {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["sort"]: column,
      };
    });
    
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
      };
    });
  };
  const handlePageChange = ({ selected }) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(
      Math.ceil(
        (GetFunction.AllClientsOfAdminProvider?.data?.data?.count || 0) /
          getAllinvitedData.limit
      )
    );
  };

  // Search Data
  const handleSearch = (e) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value.trim(),
      };
    });
  };
  const inputchangehandler = useCallback(debounce(handleSearch), []);
  const getPageNumber = () => {
    setTotalPages(
      Math.ceil(
        (GetFunction?.AllClientsOfAdminProvider?.data?.data?.count || 0) /
          getAllinvitedData.limit
      )
    );
  };
  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);

  // Update Main Tabs Function
  // const onSingleSelectionSegmentChange = useCallback(
  //   (val) => {
  //     if (val?.[0] === undefined ? true : val[0] === "all") {
  //       // page: 1,
  //       // limit: 10,
  //       // sort: "created_date",
  //       // order: "desc",
  //       // search: "",});
  //     } else {
  //       dispatch(GetProviderAllClientInvites(getAllinvitedData)).then(
  //         (response) => {
  //           if (response.success) {
  //             setInvitedData(response?.data?.rows);
  //           } else {
  //             setInvitedData([]);
  //           }
  //         }
  //       );
  //       // let type=val[0] === "Internal" ?"internal":"clientContact"
  //       // setType(type)
  //       // SetgetAllinvitedData((prevalue) => {
  //       //   return {
  //       //     ...prevalue,
  //       //     type: type,
  //       //     search: "",
  //       //     page: 1,
  //       //     limit: 10,
  //       //     sort: "created_date",
  //       //     order: "desc",
  //       //   };
  //       // });
  //     }
  //     setSingleSegmentValue(val[0]);
  //   },
  //   [setSingleSegmentValue]
  // );

  return (
    <React.Fragment>
      <Heading>Clients</Heading>
      <p className="text-gray-600 mb-5">
        Configure and manage criteria for contractors and workers to be
        fulfilled before initiating collaboration with your organisation
      </p>
      <div className="flex justify-between mb-5 mt-7">
        <div className="flex gap-2 validation-filter">
          {/* <Segment
            value={singleSegmentValue}
            onChange={(val) => onSingleSelectionSegmentChange(val)}
          >
            <Segment.Item value="all" className="h-9">
              <span>View all</span>
            </Segment.Item>
            <Segment.Item value="Invited" className="h-9">
              <span>Invited</span>
            </Segment.Item>
          </Segment> */}
          <ClientInput
            type="text"
            className="input  w-64 input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Search"
            inputchangehandler={inputchangehandler}
            name={"search"}
          />
        </div>
      </div>

      <ClientsList
        handleSort={handleSort}
        getAllinvitedData={getAllinvitedData}
      />
  
      <div className="flex justify-between mt-3">
        <Pagination
          totalCount={
            GetFunction.AllClientsOfAdminProvider?.data?.data?.count || 0
          }
          pageNumber={getAllinvitedData.page}
          displayPage={
            GetFunction.AllClientsOfAdminProvider?.data?.data?.rows?.length || 0
          }
          UpdatePageLimit={SetgetAllinvitedData}
          allfilters={getAllinvitedData}
        />
        {totalPages != 0 && (
          <ReactPaginate
            className="flex justify-content-end p-2 align-items-center pagination-wrap"
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            // pageLinkClassName={'page-link'}
            previousLinkClassName={"prev-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"prev-link"}
            previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
            nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
            pageLinkClassName={"px-4 rounded-md"}
            activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
            disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default ContractorClients;
