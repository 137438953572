// reducers/counterReducer.js
import { createSlice } from "@reduxjs/toolkit";
const InputLimit = createSlice({
  name: "InputLimit",
  initialState: {name:25,email:25},
  reducers: {
    GettInputData(state, action) {
      return action.payload;
    },
    ResetInputData() {
      return {};
    },
  },
});
export const { GettInputData, ResetInputData } = InputLimit.actions;
export default InputLimit.reducer;