import React, { useCallback, useEffect, useState } from "react";
import Heading from "../../../../../common/element/Heading";
import Button from "../../../../../common/element/Buttons";
import Dialog from "../../../../../common/element/Dialog";
import LiveChat from "./Dialog/LiveChat";
import EmailUs from "./Dialog/Email";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import { useDispatch,useSelector } from "react-redux";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import { GetAllClientArticles, GetSearchClientArticles } from "../../../../../actions/client-portal/AllSupportApi";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { NavLink } from "react-router-dom";
import { debounce } from "../../../../../common/snippet/Debouncing";

const ClientSupport = () => {
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [forceRender, setForceRender] = useState(false);
  const permissionDataUpdate = useSelector((state) => state?.permissions?.permissionData);
  useEffect(() => {
    dispatch(setBreadcrumbs([{ label: "support", url: "/client/support", home: "/client/dashboard" }]));
  }, []);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [emailDialogIsOpen, emailSetIsOpen] = useState(false);
  const [query, setQuery] = useState();
  const [clientData, setClientData] = useState();
  const openDialog = () => {
    setIsOpen(true);
  };
  const onDialogClose = (e) => {
    setIsOpen(false);
  };
  const onDialogOk = (e) => {
    setIsOpen(false);
  };
  const openEmailDialog = () => {
    emailSetIsOpen(true);
  };
  const onEmailDialogClose = (e) => {
    emailSetIsOpen(false);
  };
  const onEmailDialogOk = (e) => {
    emailSetIsOpen(false);
  };

  useEffect(() => {
    if (permissionDataUpdate !== null) {
      localStorage.setItem("permission_data", JSON.stringify(permissionDataUpdate));
      setForceRender(prevForceRender => !prevForceRender);
      if (permissionDataUpdate?.support_view == false) {
        //   navigate('/client/dashboard');
        navigate('/client/noaccess')

      }
    }
  }, [permissionDataUpdate]);
  let permission_data;
  const permissionData = JSON.parse(localStorage?.getItem("permission_data"));
  if (permissionData !== null) {
    permission_data = permissionData;
  }
  const supportPermission = permission_data?.support_write == true ? false : true


  const GetAllClientArticlesData = useCallback(() => {
    dispatch(GetAllClientArticles())
      .then((response) => {
        if (response) {
          const arrayData = response?.articles?.items
          setClientData(arrayData);
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [dispatch]);

  useEffect(() => {
    GetAllClientArticlesData();
  }, [GetAllClientArticlesData]);

  // const GetSearcheClientArticlesData = useCallback((query) => {
  //   dispatch(GetSearchClientArticles(query))
  //     .then((response) => {
  //       if (response) {
  //         const arrayData = response.articles.items
  //         setClientData(arrayData);
  //       }
  //     })
  //     .catch((err) => {
  //     });
  // }, [dispatch, query]);
  const GetSearcheClientArticlesData = useCallback(debounce(async (query) => {
    if (query) {
      try {
        const response = await dispatch(GetSearchClientArticles(query));
        if (response && response.articles) {
          setClientData(response.articles.items);
        }
      } catch (error) {
        console.error("Error searching worker articles:", error);
      }
    } else {
      GetAllClientArticlesData();
    }
  }, 500), [dispatch, GetAllClientArticlesData]);


  const handleSearchQuery = (e) => {
    setQuery(e.target.value);
    GetSearcheClientArticlesData(e.target.value);
  }
  // useEffect(() => {
  //   GetSearcheClientArticlesData(query);
  // }, [GetSearcheClientArticlesData, query]);

  // const inputchangeSearch = useCallback(debounce(handleSearchQuery), []);

  return (
    <React.Fragment>
      <Heading>Support</Heading>
      <p className="text-gray-600">
        Search for solutions or get in touch with our dedicated support.
      </p>
      <div className="flex gap-5 mt-5">
        <div className="flex-1 bg-gray-50 rounded-lg py-5 px-6 mb-12">
          <h4 className="font-semibold text-gray-900 mb-2 text-xl">
            Find answers
          </h4>
          <div className="input-wrapper mb-6">
            <div className="input-suffix-start left-3.5">
              <img src="/assets/icons-svg/search-lg.svg" alt="" class="h-5" />
            </div>
            <input
              type="text"
              name="hs-table-search"
              className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Search"
              // value={query}
              onChange={(e) => handleSearchQuery(e)}
            />
          </div>
          <hr />
          <h4 className="mb-5 font-semibold text-gray-900 text-base mt-6">
            Recommended articles:
          </h4>
          <ul className="articals-list">
            {
              clientData?.map((item) => {
                return (
                  item.status === "published" &&(
                    <NavLink key={item.id} to={item?.publicUrl || item?.url}>
                      <span className="text-blue-700 block font-semibold text-base mb-1">
                        {item.name}
                      </span>
                      {/* <span className="text-gray-800">
                        Fermentum metus bibendum felis porta adipiscing consequat. Enim.
                      </span> */}
                    </NavLink>
                  ))
              })

            }
          </ul>
        </div>
        <div className="flex-1 bg-blue-25 rounded-lg py-5 px-6 mb-12">
          <h4 className="font-semibold text-gray-900 mb-2 text-xl">
            Contact us
          </h4>
          <p className="text-gray-600 ">
            When you can't find the answers you need, count on the Conserve team
            for dedicated support, ready to assist you via phone or email.
          </p>
          <div className="my-5">
            <img src="/assets/img/img01.jpg" alt="" className="rounded-xl" />
          </div>
          <ul>
            <li
              variant="solid"
              // onClick={() => openEmailDialog()}
              // className="bg-white mb-3 gray-300-shadow rounded-xl border borer-gray-300 text-center p-3 cursor-pointer"
              onClick={() => {
                if (!supportPermission) {
                  openEmailDialog();
                }
              }}
              className={`bg-white mb-3 gray-300-shadow rounded-xl border border-gray-300 text-center p-3 cursor-pointer ${
                supportPermission ? "cursor-not-allowed opacity-50" : ""
              }`}
            >
              <span className="flex items-center gap-2 justify-center mb-1 font-semibold">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#z1ilr7or0a)">
                    <path
                      d="m2.663 5.832 6.804 4.763c.55.386.826.578 1.126.653.264.066.541.066.806 0 .3-.075.575-.267 1.126-.653l6.804-4.763M6.663 16.665h8.666c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.093-1.093c.272-.534.272-1.235.272-2.635V7.332c0-1.4 0-2.1-.272-2.635a2.5 2.5 0 0 0-1.093-1.092c-.534-.273-1.235-.273-2.635-.273H6.663c-1.4 0-2.1 0-2.635.273a2.5 2.5 0 0 0-1.093 1.092c-.272.535-.272 1.235-.272 2.635v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.534.272 1.234.272 2.635.272z"
                      stroke="#344054"
                      stroke-width="1.667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="z1ilr7or0a">
                      <path
                        fill="#fff"
                        transform="translate(.996)"
                        d="M0 0h20v20H0z"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Email us
              </span>
              <span>
                No time to wait around? We usually respond within a few hours.
              </span>
            </li>
            <li
              variant="solid"
              // onClick={() => openDialog()}
              // className="bg-white mb-3 gray-300-shadow rounded-xl border borer-gray-300 text-center p-3 cursor-pointer"
              onClick={() => {
                if (!supportPermission) {
                  openDialog();
                }
              }}
              className={`bg-white mb-3 gray-300-shadow rounded-xl border border-gray-300 text-center p-3 cursor-pointer ${
                supportPermission ? "cursor-not-allowed opacity-50" : ""
              }`}
            >
              <span className="flex items-center gap-2 justify-center mb-1 font-semibold">
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2001_102379)">
                    <path
                      d="M8.33317 13.3978L5.77046 15.9925C5.41299 16.3545 5.23426 16.5354 5.08063 16.5482C4.94735 16.5593 4.81685 16.5057 4.7298 16.4042C4.62947 16.2871 4.62947 16.0328 4.62947 15.5241V14.2241C4.62947 13.7678 4.25573 13.4375 3.80417 13.3714V13.3714C2.71129 13.2113 1.85298 12.353 1.6929 11.2601C1.6665 11.0799 1.6665 10.8649 1.6665 10.4348V6.56445C1.6665 5.16432 1.6665 4.46426 1.93899 3.92948C2.17867 3.45907 2.56112 3.07662 3.03153 2.83694C3.56631 2.56445 4.26637 2.56445 5.6665 2.56445H11.8332C13.2333 2.56445 13.9334 2.56445 14.4681 2.83694C14.9386 3.07662 15.321 3.45907 15.5607 3.92948C15.8332 4.46426 15.8332 5.16432 15.8332 6.56445V10.0645M15.8332 19.2311L14.0195 17.9702C13.7645 17.7929 13.6371 17.7043 13.4983 17.6415C13.3752 17.5857 13.2457 17.5451 13.1128 17.5206C12.963 17.493 12.8078 17.493 12.4973 17.493H10.9998C10.0664 17.493 9.59971 17.493 9.24319 17.3114C8.92958 17.1516 8.67462 16.8966 8.51483 16.583C8.33317 16.2265 8.33317 15.7598 8.33317 14.8264V12.7311C8.33317 11.7977 8.33317 11.331 8.51483 10.9745C8.67462 10.6609 8.92958 10.4059 9.24319 10.2461C9.59971 10.0645 10.0664 10.0645 10.9998 10.0645H15.6665C16.5999 10.0645 17.0666 10.0645 17.4232 10.2461C17.7368 10.4059 17.9917 10.6609 18.1515 10.9745C18.3332 11.331 18.3332 11.7977 18.3332 12.7311V14.993C18.3332 15.7696 18.3332 16.1579 18.2063 16.4642C18.0371 16.8725 17.7127 17.197 17.3043 17.3662C16.998 17.493 16.6097 17.493 15.8332 17.493V19.2311Z"
                      stroke="#344054"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2001_102379">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0 0.898438)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Live Chat
              </span>
              <span>We’re online right now, talk with our team real-time.</span>
            </li>
            <li className="bg-white mb-3 gray-300-shadow rounded-xl border borer-gray-300 text-center p-3">
              <span className="flex items-center gap-2 justify-center mb-1 font-semibold">
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.484 8.276a12.17 12.17 0 0 0 2.372 3.342 12.168 12.168 0 0 0 3.341 2.372c.104.05.156.075.222.094.233.068.52.019.718-.123.056-.04.103-.087.199-.183.29-.29.437-.437.583-.532a1.667 1.667 0 0 1 1.817 0c.146.095.292.241.584.532l.162.163c.443.443.664.664.785.902.239.473.239 1.031 0 1.505-.12.237-.342.459-.785.902l-.131.131c-.442.441-.662.662-.962.83-.333.188-.85.322-1.232.32-.344 0-.58-.067-1.05-.2a15.866 15.866 0 0 1-6.903-4.062 15.865 15.865 0 0 1-4.061-6.903c-.134-.47-.2-.705-.202-1.05a2.814 2.814 0 0 1 .32-1.231c.17-.3.39-.52.831-.962l.132-.132c.443-.443.664-.664.902-.784a1.667 1.667 0 0 1 1.504 0c.238.12.46.341.902.784l.163.163c.291.291.437.437.532.583.36.553.36 1.265 0 1.817-.095.147-.24.292-.532.584a1.682 1.682 0 0 0-.183.198.872.872 0 0 0-.122.719c.019.065.044.117.094.221z"
                    stroke="#344054"
                    stroke-width="1.667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Phone us
              </span>
              <span>+61 2 8883 1501</span>
            </li>
          </ul>
          <p className="text-gray-800 text-xs text-center mt-5">
            Our opening hours are Mon-Fri, 9am-5pm AEDT.
          </p>
        </div>
      </div>
      <hr />
      <Heading className="mt-12">Resources</Heading>
      <p className="text-gray-600">
        Explore our comprehensive blog resources for valuable insights into WHS
        and compliance, ensuring you stay current with the latest developments.
      </p>
      <div className="flex mt-5 flex-wrap resources-block mb-7">
        <div className="flex flex-wrap">
          <div className="img-wrap w-3/5">
            <img src="/assets/img/img02.jpg" alt="" className="rounded-xl" />
          </div>
          <div className="text-caption w-2/5">
            <span className="text-blue-700 block font-semibold text-base mb-1">
              Provider management: it's time to upgrade from Excel
            </span>
            <Button className="font-semibold text-gray-600 text-xs flex gap-1 hover:text-gray-900 focus:text-gray-600">
              Button CTA{" "}
              <svg
                width="16"
                height="15"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="img-wrap w-3/5">
            <img src="/assets/img/img03.jpg" alt="" className="rounded-xl" />
          </div>
          <div className="text-caption w-2/5">
            <span className="text-blue-700 block font-semibold text-base mb-1">
              Provider management: it's time to upgrade from Excel
            </span>
            <Button className="font-semibold text-gray-600 text-xs flex gap-1 hover:text-gray-900 focus:text-gray-600">
              Button CTA{" "}
              <svg
                width="16"
                height="15"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="img-wrap w-3/5">
            <img src="/assets/img/img03.jpg" alt="" className="rounded-xl" />
          </div>
          <div className="text-caption w-2/5">
            <span className="text-blue-700 block font-semibold text-base mb-1">
              Provider management: it's time to upgrade from Excel
            </span>
            <Button className="font-semibold text-gray-600 text-xs flex gap-1 hover:text-gray-900 focus:text-gray-600">
              Button CTA{" "}
              <svg
                width="16"
                height="15"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="img-wrap w-3/5">
            <img src="/assets/img/img03.jpg" alt="" className="rounded-xl" />
          </div>
          <div className="text-caption w-2/5">
            <span className="text-blue-700 block font-semibold text-base mb-1">
              Provider management: it's time to upgrade from Excel
            </span>
            <Button className="font-semibold text-gray-600 text-xs flex gap-1 hover:text-gray-900 focus:text-gray-600">
              Button CTA{" "}
              <svg
                width="16"
                height="15"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <LiveChat isOpen={dialogIsOpen}/>
      </Dialog>
      <Dialog
        isOpen={emailDialogIsOpen}
        onClose={onEmailDialogClose}
        onRequestClose={onEmailDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <EmailUs onEmailDialogClose={onEmailDialogClose}/>
      </Dialog>
    </React.Fragment>
  );
};

export default ClientSupport;
