import axios from "axios";
import * as actionType from "../../constant/actionType";
export const GetProviderGeneratedCertificateById =
  (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      let apiPath;
      if (apiData.type == "adminworker") {
        apiPath = "admin/worker";
      } else if (apiData.type == "worker") {
        apiPath = "worker";
      } else if (apiData.type == "provider") {
        apiPath = "provider";
      }
      else if (apiData.type == "providerworker") {
        apiPath = "provider/worker";
      }
       else {
        apiPath = "admin/provider";
      }

      
    
      // Payload data
      const payload = {
        provider_org_id: apiData?.provider_org_id,
      };
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/${apiPath}/GetProviderGeneratedCertificateById`,
        params: payload,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };
