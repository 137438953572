import React, { useState } from "react";
import Scrollbar from "../../../../../common/element/ScrollBar";
import Input from "../../../../../common/element/Input";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import RichTextEditor from "../../../../../common/snippet/RichTextEditor";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import ThreeDotLoader from "../../../../../common/element/ButtonLoader/ThreeDotLoader";
import Validations from "../../../../../common/404/Validations";
import Dialog from "../../../../../common/element/Dialog";

const EmailUs = ({ onEmailDialogClose }) => {
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  const fullName = `${loginUserData.userName.first_name} ${loginUserData.userName.last_name}`;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [successdialogOpen, setSuccessDialogOpen] = useState(false)
  const [emailData, setEmailData] = useState({
    fullname: fullName || "",
    email: loginUserData.userName.email || "",
    subject: '',
    help: ''
  })
  const [emailUsErrors, setEmailUsErrors] = useState({
    subject: '',
    help: ''
  })

  const handleChange = (key, value) => {
    setEmailData(prevState => ({
      ...prevState,
      [key]: value,
    }));
    setEmailUsErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
  };


  const onSendEmail = () => {
    const subjectValidation = Validations({ value: emailData.subject, Validations: { required: true } });
    if (!subjectValidation.valid) {
      setEmailUsErrors((prevErrors) => ({ ...prevErrors, subject: subjectValidation.error_msg }));
    }
    const helpValidation = Validations({ value: emailData.help, Validations: { required: true } });
    if (!helpValidation.valid) {
      setEmailUsErrors((prevErrors) => ({ ...prevErrors, help: helpValidation.error_msg }));
    }

    if (subjectValidation.valid && helpValidation.valid) {
      setButtonDisabled(true);
      //  selectedCopy==true then sendMeCopyEmail set this in payload other wiese ""
      // setSelectedEmails([]);
      setTimeout(() => {
        setButtonDisabled(false);
        setSuccessDialogOpen(true)
      }, 2000);
    }
  }

  // For success dialog
  const closeSuccessDialog = () => {
    setSuccessDialogOpen(false)
    onEmailDialogClose()
  }

  return (
    <React.Fragment>
      <div className="flex border-b  pb-5 px-6 pt-6">
        <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
          <img src="/assets/icons-svg/email.svg" alt="" />
        </span>
        <div>
          <h5 className="mb-1 text-gray-900 text-base font-semibold">
            Email us
          </h5>
          <p className="text-gray-600 ">
            Directly engage with a Conserve team member. We aim to reply within a few hours during 9am-5pm from Mon-Fri.
          </p>
        </div>
      </div>
      <div className="chat-block p-6 pr-2">
        {/* <Scrollbar> */}
        <div className="flex gap-3 pr-4">
          <div className="flex-1 mb-5">
            <label className="text-gray-700 font-medium text-xs block mb-1">Name</label>
            <Input
              name="fullname"
              className="h-9 w-full"
              placeholder="[Auto-populate user's name]"
              value={emailData?.fullname}
              disabled="true"
            />
          </div>
          <div className="flex-1 mb-5">
            <label className="text-gray-700 font-medium text-xs block mb-1">Email</label>
            <Input
              name="email"
              className="h-9 w-full"
              placeholder="[Auto-populate user's email]"
              value={emailData.email}
              disabled="true"
            />
          </div>
        </div>
        <div className="flex mb-5 flex-col pr-4">
          <label className="text-gray-700 font-medium text-xs block mb-1">Subject</label>
          <Input
            name="subject"
            className="h-9 w-full"
            value={emailData.subject}
            inputchangehandler={(e) => handleChange('subject', e)}
          />
          {emailUsErrors?.subject && (
            <div className="text-red-500 text-sm mt-1">{emailUsErrors?.subject}</div>
          )}
        </div>
        <div className="flex mb-5 flex-col email-editer pr-4">
          <label className="text-gray-700 font-medium text-xs block mb-1">How can we help?</label>
          <RichTextEditor
            value={emailData.help}
            onChange={(e) => handleChange('help', e)}
          />
          {emailUsErrors?.help && (
            <div className="text-red-500 text-sm mt-1">{emailUsErrors?.help}</div>
          )}
        </div>
        {/* </Scrollbar> */}
      </div>
      <div className="border-t border-gray-200 p-6 ">
        <div className="flex gap-3 justify-end">
          <BtnLight
            className="flex items-center gap-1 w-32 justify-center"
            onClick={onEmailDialogClose}
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            className="flex items-center gap-1 w-32 justify-center"
            disabled={buttonDisabled}
            onClick={() => onSendEmail()}
          >
            {buttonDisabled ? <ThreeDotLoader /> : "Send email"}
          </BtnPrimary>
        </div>
      </div>
      <Dialog
        isOpen={successdialogOpen}
        onClose={closeSuccessDialog}
        onRequestClose={closeSuccessDialog}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1.35156" y="0.5" width="47" height="47" rx="23.5" fill="#D2EBFF" />
              <rect x="1.35156" y="0.5" width="47" height="47" rx="23.5" stroke="#ECFDF3" />
              <path d="M23.3511 25.5001L33.8511 15.0001M23.4787 25.8281L26.1068 32.5861C26.3383 33.1815 26.4541 33.4791 26.6209 33.566C26.7655 33.6414 26.9377 33.6415 27.0824 33.5663C27.2493 33.4796 27.3654 33.1821 27.5976 32.587L34.188 15.6992C34.3976 15.1621 34.5024 14.8935 34.4451 14.7219C34.3953 14.5728 34.2783 14.4558 34.1293 14.406C33.9577 14.3487 33.6891 14.4535 33.1519 14.6632L16.2641 21.2535C15.6691 21.4857 15.3715 21.6018 15.2848 21.7687C15.2097 21.9134 15.2098 22.0857 15.2851 22.2303C15.372 22.3971 15.6697 22.5128 16.265 22.7444L23.023 25.3725C23.1439 25.4195 23.2043 25.443 23.2552 25.4793C23.3003 25.5114 23.3397 25.5509 23.3719 25.596C23.4082 25.6468 23.4317 25.7073 23.4787 25.8281Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Message sent! We’ll be in touch soon.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => closeSuccessDialog()}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default EmailUs;
