import React, { useCallback, useEffect, useState } from "react";
import { notify } from "react-notify-toast";
// import WorkersList from "./WorkersList";
import { GetAllIndividualListForWorkerInvites } from "../../../../../../../actions/admin-provider/GetAllIndividualListForWorkerInvites";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "../../../../../../../common/snippet/Debouncing";
import { InviteWorker } from "../../../../../../../actions/admin-worker/InviteWorker";
import ClientInput from "../../../../../../../common/element/Input/clientInput";
import { GetProviderWorkersInvite } from "../../../../../../../actions/admin-provider/GetSpecificContractorWorkerInvites";
import ReactPaginate from "react-paginate";
import Segment from "../../../../../../../common/element/Segment";
import Validations from "../../../../../../../common/404/Validations";
import { reSetData } from "../../../../../../../redux/classes/AdminContractor/inviteContractorWorkerCsvViewSlice";
import { setBreadcrumbs } from "../../../../../../../redux/classes/breadcrumbsSlice";
import { useParams } from "react-router-dom";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../../../common/pagination";
import { GetAllWorkersOfProvider } from "../../../../../../../actions/admin-provider/clients/GetAllWorkersOfProvider";
import CompanyInduction from "./CompanyInduction";
import SiteInduction from "./SiteInduction";
import { GetIndWorkerByAssignedClients } from "../../../../../../../actions/provider-portal/worker/AdminWorkerAPI";
import CompanyInductionView from "./CompanyInductionView";
import InductionView from "./SiteInductionView";
import Dialog from "../../../../../../../common/element/Dialog/Dialog";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Clients = (props) => {
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));

  const { specificWorker } = useSelector((state) => state);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "Workers",
          url: "/provider/workers",
          home: "/provider/dashboard",
        },
        {
          label: `${specificWorker?.worker_individual?.first_name || ""} ${
            specificWorker?.worker_individual?.last_name || ""
          }`,
          url: `/provider/worker/view-all-worker/specific-worker/${encodeURIComponent(
            id
          )}`,
        },

        {
          label: "Inductions",
          url: `/provider/worker/view-all-worker/specific-worker/${encodeURIComponent(
            id
          )}`,
        },
      ])
    );
  }, []);

  const [singleSegmentValue, setSingleSegmentValue] =
    useState("companyInduction");
  const [search, setSearch] = useState("");
  const [inviteData, setinviteData] = useState([]);
  const [refetch, setrefetch] = useState(false);
  const [searchOptions, setsearchOptions] = useState([]);
  const [inductionData, setInductionData] = useState(null);
  
  const [inDialogIsOpen, setInDialogIsOpen] = useState(false);

  const [addWorkerData, setAddWorkerData] = useState({
    trading_name: "provider_trading" /* provider trading name */,
    provider_fa_id: loginUserData?.function_assignment_id,
    workersInviteData: [
      {
        individual_id: "",
        email: "",
        first_name: "",
        last_name: "",
      },
    ],
    workerClientsIds: "",
  });
  const onDialogClose = (e) => {
    // setIsOpen(false);
    // setCheckError(false);
    // props.getOnDialogClose(onDialogClose);
    setAddWorkerData({
      trading_name: "provider_trading" /* provider trading name */,
      provider_fa_id: loginUserData?.function_assignment_id,
      workersInviteData: [
        {
          individual_id: "",
          email: "",
          first_name: "",
          last_name: "",
        },
      ],
      workerClientsIds: "",
    });
    // setPersonName([]);
  };

  // FilTER DATA SHOW
  const [totalPages, setTotalPages] = useState(1);

  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    worker_id: specificWorker.worker_id,
    page: 1,
    limit: 10,
    // sort: "created_date",
    order: "desc",
    search: "",
    inductionType: "company",
  });

  // All ReduxFunction
  useEffect(() => {
    dispatch(GetIndWorkerByAssignedClients(getAllinvitedData));
  }, [getAllinvitedData, refetch]);
  const GetFunction = useSelector((state) => state);

  const handleSort = (column) => {
    if (column === getAllinvitedData.sort) {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    } else {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    }
  };

  const handlePageChange = ({ selected }) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(
      Math.ceil(
        GetFunction.SpecificContractorWorkerInvites?.data?.data?.count ||
          0 / getAllinvitedData.limit
      )
    );
  };

  // Search Data
  const handleSearch = (e) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value.trim(),
      };
    });
  };
  const inputchangehandlerSearch = useCallback(debounce(handleSearch), []);
  // page, limit, sort, order, search, function_assignment_id  (client function assignment id)

  const getPageNumber = () => {
    setTotalPages(
      Math.ceil(
        GetFunction.SpecificContractorWorkerInvites?.data?.data?.count /
          getAllinvitedData.limit
      )
    );
  };
  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);

  // Check Tab
  // Update Main Tabs Function
  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setSingleSegmentValue(val[0]);
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          // ["sort"]: "created_date",
          inductionType: val[0] == "companyInduction" ? "company" : "site",
        };
      });
    },
    [setSingleSegmentValue]
  );

  const handleView = (item, type) => {
    let payload;
    if (type == "site") {
      payload = {
        site_induction_id: item?.site_induction_id,
        worker_id: specificWorker.worker_id,
        inductionType: type,
      };
    } else {
      payload = {
        company_induction_id: item?.company_induction_id,
        worker_id: specificWorker.worker_id,
        inductionType: type,
      };
    }
    setInductionData(payload);
    setInDialogIsOpen(true);
  };

  const onInDialogClose = () => {
    setInDialogIsOpen(false);
  };

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5">
        <div className="flex gap-2">
          <Segment
            value={singleSegmentValue}
            onChange={(val) => onSingleSelectionSegmentChange(val)}
          >
            <Segment.Item value="companyInduction" className="h-9 p-2">
              <span>Company Induction </span>
            </Segment.Item>
            <Segment.Item value="siteInduction" className="h-9 p-2">
              <span>Site Induction</span>
            </Segment.Item>
          </Segment>
          <ClientInput
            type="text"
            className="input  w-72 input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Search"
            inputchangehandler={inputchangehandlerSearch}
            name={"search"}
          />
        </div>
      </div>
      {singleSegmentValue == "companyInduction" && (
        <React.Fragment>
          <CompanyInduction
            handleSort={handleSort}
            getAllinvitedData={getAllinvitedData}
            singleSegmentValue={singleSegmentValue}
            setrefetch={setrefetch}
            refetch={refetch}
            handleView={handleView}
          />
          <div className="flex justify-between mt-3">
            <Pagination
              totalCount={
                GetFunction.GetSpecificWorkerIndData?.data?.data?.count || 0
              }
              pageNumber={getAllinvitedData.page}
              displayPage={
                GetFunction.GetSpecificWorkerIndData?.data?.data?.rows
                  ?.length || 0
              }
              UpdatePageLimit={SetgetAllinvitedData}
              allfilters={getAllinvitedData}
            />
            {GetFunction.GetSpecificWorkerIndData?.data?.data?.count > 0 && (
              <ReactPaginate
                className="flex justify-content-end p-2 align-items-center pagination-wrap"
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                // pageLinkClassName={'page-link'}
                previousLinkClassName={"prev-link"}
                nextLinkClassName={"page-link"}
                breakLinkClassName={"prev-link"}
                previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
                nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
                pageLinkClassName={"px-4 rounded-md"}
                activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
                disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              />
            )}
          </div>
        </React.Fragment>
      )}

      {singleSegmentValue == "siteInduction" && (
        <React.Fragment>
          <SiteInduction
            handleSort={handleSort}
            getAllinvitedData={getAllinvitedData}
            singleSegmentValue={singleSegmentValue}
            setrefetch={setrefetch}
            refetch={refetch}
            handleView={handleView}
          />
          <div className="flex justify-between mt-3">
            <Pagination
              totalCount={
                GetFunction.GetSpecificWorkerIndData?.data?.data?.count || 0
              }
              pageNumber={getAllinvitedData.page}
              displayPage={
                GetFunction.GetSpecificWorkerIndData?.data?.data?.rows
                  ?.length || 0
              }
              UpdatePageLimit={SetgetAllinvitedData}
              allfilters={getAllinvitedData}
            />
            {GetFunction.GetSpecificWorkerIndData?.data?.data?.count > 0 && (
              <ReactPaginate
                className="flex justify-content-end p-2 align-items-center pagination-wrap"
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                // pageLinkClassName={'page-link'}
                previousLinkClassName={"prev-link"}
                nextLinkClassName={"page-link"}
                breakLinkClassName={"prev-link"}
                previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
                nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
                pageLinkClassName={"px-4 rounded-md"}
                activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
                disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
              />
            )}
          </div>
        </React.Fragment>
      )}


      <Dialog
        isOpen={inDialogIsOpen}
        onClose={onInDialogClose}
        onRequestClose={onInDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="lg-dialog"
      >
        <div className="flex border-b pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/file-check.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              {inductionData?.company_ind_name}
            </h5>
          </div>
        </div>
        {singleSegmentValue == "siteInduction" && (
          <InductionView
            inductionView={inductionData}
            // inductionName={inductionName()}
          />
        )}
        {singleSegmentValue == "companyInduction" && (
          <CompanyInductionView
            inductionView={inductionData}
            // inductionName={inductionName()}
          />
        )}
      </Dialog>
    </React.Fragment>
  );
};
export default Clients;
