import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// import ContractorViewAll from "../pages/Provider/ViewAll";
import WorkerViewAll from "../pages/Worker/ViewAll";

// import SpecificContractor from "../pages/Provider/SpecificContractor/SpecificContractor";
import SpecificWorker from "../pages/Worker/SpecificWorker/SpecificContractor";

import Notes from "../pages/Worker/SpecificWorker/Notes";
import ClientSettings from "../pages/Worker/Dialog/Clients/ClientSettings";
import SpecificSite from "../pages/Client/SpecificSite/index";
import Dashboard from "../pages/Worker/Dashboard";
import Reports from "../pages/Worker/Reports";
import WorkersOverview from "../pages/Worker/Reports/WorkersOverview";
import WorkersInductions from "../pages/Worker/Reports/WorkersInductions";
import WorkersComplianceChecklist from "../pages/Worker/Reports/WorkersComplianceChecklist";
function useLocalStoragePath() {
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("currentPath", location.pathname);
  }, [location.pathname]);

  return location.pathname;
}

export const AdminPortalWorkerRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="view-all-worker" element={<WorkerViewAll />} />
        <Route
          path="view-all-worker/specific-worker/:id/*"
          element={<SpecificWorker />}
        />
        <Route
          path="view-all-worker/specific-worker/:id/notes"
          element={<Notes />}
        />
        <Route
          path="view-all-worker/specific-worker/:id/settings"
          element={<ClientSettings />}
        />
        <Route
          path="view-all-worker/specific-worker/:id/specific-site"
          element={<SpecificSite />}
        />
        <Route path="reports" element={< Reports />} />
        <Route path="reports/workers-overview" element={<WorkersOverview />} />
        <Route path="reports/workers-inductions" element={<WorkersInductions />} />
        <Route path="reports/compliance-checklist" element={<WorkersComplianceChecklist />} />
      </Routes>
    </React.Fragment>
  );
};
