import Card from "../../../../../../common/element/Card";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import { useDispatch } from "react-redux";
import { GetWorkerOverviewDetailsById } from "../../../../../../actions/admin-worker/GetWorkerOverviewDetailsById";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
const WorkerInformation = ({ openAddDialog, workerRedirectSpecificData }) => {
  const [data, SetData] = useState();
  const dispatch = useDispatch();
  const { id } = useParams();
  return (
    <Card className="p-6 border border-gray-200 rounded-xl dark-shadow">
      <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-5">
        <span className="text-gray-900 text-base font-semibold flex items-center">
          {/* <span className="mr-4">
            <img src="/assets/icons-svg/building-icon.svg" />
          </span> */}
          Worker Information
        </span>
        <BtnLight
          className="h-9 w-20 flex items-center justify-center gap-2"
          onClick={openAddDialog}
        >
          Edit <img src="/assets/icons-svg/edit-pen.svg" />
        </BtnLight>
      </div>
      <div className="flex mb-3">
        <div className="flex-1">
          <span className="text-gray-400 font-semibold block text-sm mb-1">
            First name
          </span>
          <span className="text-gray-900 font-medium block text-sm">
            {workerRedirectSpecificData?.first_name
              ? workerRedirectSpecificData?.first_name
              : "-"}
          </span>
        </div>
        <div className="flex-1">
          <span className="text-gray-400 font-semibold block text-sm mb-1">
            Last name
          </span>
          <span className="text-gray-900 font-medium block text-sm">
            {workerRedirectSpecificData?.last_name
              ? workerRedirectSpecificData?.last_name
              : "-"}
          </span>
        </div>
      </div>
      <div className="flex mb-3">
        <div className="flex-1">
          <span className="text-gray-400 font-semibold block text-sm mb-1">
            Email
          </span>
          <span className="text-gray-900 font-medium block text-sm">
            {workerRedirectSpecificData?.email
              ? workerRedirectSpecificData?.email
              : "-"}
          </span>
        </div>
        <div className="flex-1">
          <span className="text-gray-400 font-semibold block text-sm mb-1">
            Phone
          </span>
          <span className="text-gray-900 font-medium block text-sm">
            {workerRedirectSpecificData?.phone
              ? workerRedirectSpecificData?.phone
              : "-"}
          </span>
        </div>
      </div>
      <div className="flex mb-3">
        <div className="flex-1">
          <span className="text-gray-400 font-semibold block text-sm mb-1">
            Country
          </span>
          <span className="text-gray-900 font-medium flex gap-1 text-sm">
            {workerRedirectSpecificData?.name
              ? workerRedirectSpecificData?.name
              : "-"}
          </span>
        </div>
        <div className="flex-1">
          <span className="text-gray-400 font-semibold block text-sm mb-1">
            State
          </span>
          <span className="text-gray-900 font-medium flex gap-1 text-sm">
            {workerRedirectSpecificData?.state_name
              ? workerRedirectSpecificData?.state_name
              : "-"}
          </span>
        </div>
      </div>
      <div className="flex mb-3">
        <div className="flex-1">
          <span className="text-gray-400 font-semibold block text-sm mb-1">
            Occupation
          </span>
          <span className="text-gray-900 font-medium flex gap-1 text-sm">
            {workerRedirectSpecificData?.occupation
              ? workerRedirectSpecificData?.occupation
              : "-"}
          </span>
        </div>
      </div>
    </Card>
  );
};
export default WorkerInformation;
