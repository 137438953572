import React, { useState } from "react";
import Table from "../../../../../common/element/Table";
import Button from "../../../../../common/element/Buttons";
import Tooltip from "../../../../../common/element/Tooltip";
import ScrollBar from "../../../../../common/element/ScrollBar/ScrollBar";
import Dialog from "../../../../../common/element/Dialog";
import SmallButton from "../../../../../common/element/Buttons/btnPrimary"
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import EditUser from "../Dialog/EditUser";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { GetClientUserPermByIdForProvider, RemoveClientUser, GetAllSpecificOrgUsers } from "../../../../../actions/provider-portal/AllUserAPIs";
// import {GetClientUserPermByIdForClient, RemoveClientUser, GetAllSpecificOrgUsers} from '../../../../../../actions/client-portal/AllUsersApi';
import moment from "moment";
import { useDispatch } from "react-redux";
let trading_name = "provider trading corpo";
const { Tr, Th, Td, THead, TBody } = Table;
const ActiveUser = (props) => {
   const dispatch = useDispatch()
   const handleUnauthorizedAccess = useUnauthorizedHandler()
   const [userDelete, setDelete] = useState(false)
   const [userEdit, setUserEdit] = useState(false)
   const [nameUser, setNameUser] = useState('')
   const [permData, setPermData] = useState('')
   const [userIdSpecifice, setUserIdSpecifice] = useState()

   const [deleteUserData, setDeleteUserData] = useState("");
   let function_assignment_id;
   const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
   if (storedData !== null) {
      function_assignment_id = storedData.function_assignment_id;
   }


   const openUserEdit = (val) => {
      setUserEdit(true);
      let userId = {
         user_id: val?.org_individual?.user_data?.user_id,
         function_assignment_id: function_assignment_id
      }
      setUserIdSpecifice(userId)
      dispatch(GetClientUserPermByIdForProvider(userId)).then(
         (response) => {
            if (response.success === true) {
               const arrayData = response.data;
               setPermData(arrayData);
            } else if (response.status === 401) {
               handleUnauthorizedAccess()
            } else {
            }
         }
      );
      setNameUser(val?.org_individual?.user_data?.first_name + ' ' + val?.org_individual?.user_data?.last_name);
   }
   const closeUserEdit = () => {
      setUserEdit(false)
   }
   const deleteUserDialog = (e) => {
      setDelete(true)
      setDeleteUserData(e)
   }
   const closeDeleteUser = () => {
      setDelete(false)
   }


   // to remove Invited user
   const RemoveUser = () => {
      const payload = {
         individual_id: deleteUserData?.individualId,
         function_assignment_id: function_assignment_id,
         individual_org_id: deleteUserData?.individualOrgId,
         user_id: deleteUserData?.userId,
      };
      dispatch(RemoveClientUser(payload))
         .then((response) => {
            if (response.success) {
               closeDeleteUser()
               setDeleteUserData('')
               dispatch(GetAllSpecificOrgUsers(function_assignment_id)).then(
                  (response) => {
                     if (response.success) {
                        const arrayData = response.data;
                        props.onInvitedUser(arrayData);
                     } else if (response.status === 401) {
                        handleUnauthorizedAccess()
                     }
                  }
               );
            } else if (response.status === 401) {
               handleUnauthorizedAccess()
            }
         })
         .catch((error) => {
         });
   };



   return (
      <React.Fragment>

         <div className="flex justify-between mb-5 mt-5">
            <h5 className="text-xl text-gray-900 font-semibold">Active</h5>
         </div>

         <div className="overflow-hidden border rounded-lg border-gray-200 custom-scrollbar">

            <Table>
               <THead>
                  <Tr>
                     <Th className="w-1/3">User name</Th>
                     <Th className="w-1/3">Email</Th>
                     <Th>
                        <div className="flex items-center">
                           Last active
                           <Tooltip title="Account Billing contact is responsible for billing and payment on the account." placement="top-start">
                              <div className="text-center cursor-pointer">
                                 <img src="/assets/icons-svg/help-circle.svg" alt="" className="ml-2" />
                              </div>
                           </Tooltip>
                        </div>
                     </Th>
                     <Th className="w-48">
                     </Th>
                  </Tr>
               </THead>
               <TBody>
                  {props.allUser && props?.allUser?.map((item) => {
                     // Add days to the validSDays
                     const currentTime = moment();
                     const active = moment.duration(currentTime.diff(item.org_individual.user_data.last_login))
                     const activeDate = item?.org_individual?.user_data?.last_login
                        ? (active.asSeconds() < 60
                           ? "Just now"
                           : active.asMinutes() < 60
                              ? `${Math.floor(active.asMinutes())} ${active.asMinutes() === 1 ? "minute" : "minutes"
                              } ago`
                              : active.asHours() < 24
                                 ? `${Math.floor(active.asHours())} ${active.asHours() === 1 ? "hour" : "hours"
                                 } ago`
                                 : active.asDays() < 2
                                    ? "Yesterday"
                                    : `${Math.floor(active.asDays())} ${active.asDays() === 1 ? "day" : "days"
                                    } ago`)
                        : "";
                     return (
                        <Tr>
                           <Td>{item.org_individual.first_name + ' ' + item.org_individual.last_name}</Td>
                           <Td>{item.org_individual.email}</Td>
                           <Td>{activeDate}</Td>
                           {/*permission for Read and Write */}
                           <Td>
                              <span className="flex gap-4 justify-center">
                                 <Button
                                    disabled={props?.permission_data?.users_write == true ? false : true}
                                    onClick={() =>
                                       deleteUserDialog(item)}>
                                    <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5" stroke="#475467" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                 </Button>
                                 <Button
                                    disabled={props?.permission_data?.users_write == true ? false : true}
                                    onClick={() =>
                                       openUserEdit(item)}>
                                    <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z" stroke="#475467" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                 </Button>
                              </span>
                           </Td>
                        </Tr>)
                  })}

               </TBody>

            </Table>


         </div>
         <Dialog
            isOpen={userEdit}
            onClose={closeUserEdit}
            onRequestClose={closeUserEdit}
            bodyOpenClassName="overflow-hidden"
            className="addContact-dialog"
         >
            <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
               <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4"><svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5 15.328c-3.17 0-5.99 1.53-7.784 3.906-.386.511-.58.767-.573 1.112.005.267.172.604.382.769.272.213.649.213 1.402.213h13.146c.753 0 1.13 0 1.402-.213.21-.165.377-.502.382-.769.006-.345-.187-.6-.573-1.112-1.795-2.375-4.614-3.906-7.784-3.906zM12.5 12.328a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
               </svg>
               </span>
               <div>
                  <h5 className="mb-1 text-gray-900 text-base font-semibold">Edit user access for {trading_name}</h5>
                  <p className="text-gray-600">Modify user’s access.</p>
               </div>
            </div>
            <EditUser userIdSpecifice={userIdSpecifice} permData={permData} closeUserEdit={closeUserEdit} />
         </Dialog>
         <Dialog
            isOpen={userDelete}
            onClose={closeDeleteUser}
            onRequestClose={closeDeleteUser}
            bodyOpenClassName="overflow-hidden"
            className="customAlert"
         >
            <div className="px-6 pt-6">
               <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
               </span>
               <h4 className="text-base text-gray-900 font-semibold text-center mt-4">Are you sure you want to delete this user?</h4>
               <p className=" text-gray-600 text-center mt-1">
                  This action cannot be undone. The user will lose all access to the app.
               </p>
            </div>
            <div className='mt-8 flex justify-between pb-6 px-6'>
               <BtnLight className='mr-1.5' >Cancel</BtnLight>
               <SmallButton onClick={() => closeDeleteUser()} className='ml-1.5'>Confirm</SmallButton>
            </div>
         </Dialog>
      </React.Fragment>
   )
}
export default ActiveUser