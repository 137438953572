import ABN_Validator from "au-bn-validator";
import NZBN_Validator from "@fnzc/nz-ird-validator";

const Validation = (form_element) => {
  let value = form_element.value;
  let rule = form_element.Validations;

  if (!rule.required) {
    return {
      valid: true,
      error_msg: "",
    };
  }

  value = typeof value === "object" ? JSON.stringify(value) : value;
  value = typeof value === "boolean" ? JSON.stringify(value) : value;

  if (rule.required) {
    if (
      value == null ||
      (typeof value === "string" && value.trim() === "") ||
      (typeof value === "number" && value === "")
    ) {
      return {
        valid: false,
        error_msg: "This field is required!",
      };
    }
  }

  // If filed is name
  if (rule.name) {
    // let name_regexp = /^[a-zA-Z0-9]+$/;;
    let name_regexp = /^[a-zA-Z]+$/;
    if (name_regexp.test(value) === false) {
      return {
        valid: false,
        error_msg:
          "Please enter the correct format (letters and numbers only).",
      };
    }
  }

  // If filed is email
  if (rule.email) {
    // let email_regexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    let email_regexp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    // let email_regexp = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (email_regexp.test(value) === false) {
      return {
        valid: false,
        error_msg: "Email format is incorrect.",
      };
    }
  }

  // If field is password
  if (rule.password) {
    const isStrong = value.match(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
    );

    if (!isStrong) {
      return {
        valid: false,
        error_msg:
          "Your password must be at least 8 characters long, contain at least one special character and have a mixture of lowercase, uppercase and numbers.",
      };
    }
  }

  // Validate min length
  if (rule.min_length) {
    if (value.length < rule.min_length) {
      return {
        valid: false,
        error_msg: "Please enter the correct format",
      };
    }
  }

  // Validate Max length
  if (rule.max_length) {
    if (value.length > rule.max_length) {
      return {
        valid: false,
        error_msg: "Please enter the correct format",
      };
    }
  }

  // Validate Phone Number length
  if (rule.phone_length) {
    if (value.length < rule.phone_length) {
      return {
        valid: false,
        error_msg: "Invalid phone format. ex: " + form_element.placeholder,
      };
    }
  }

  // Field typeahead not empty
  if (rule.typeahead) {
    if (value === "[]") {
      return {
        valid: false,
        error_msg: "This field is required!",
      };
    }
  }

  // Field accept only number and space
  if (rule.NumberAndSpace) {
    if (value) {
      const reg = /^[0-9 ]+$/;
      if (!reg.test(value)) {
        return {
          valid: false,
          error_msg: "Please enter the correct format",
        };
      }
    }
  }

  // Check ABN Validate
  if (rule.ValidateABN && value) {
    let abn_validation_return = false;

    /*** Check Australian Business Number (ABN) validates ***/
    if (ABN_Validator.validateABN(value)) {
      abn_validation_return = true;
    }

    /*** Check Zealand Business Numbers (NZBNs) validates ***/
    if (NZBN_Validator.isValidIRDNumber(value)) {
      abn_validation_return = true;
    }

    if (!abn_validation_return) {
      return {
        valid: false,
        error_msg: "Please enter valid ABN/NZBN",
      };
    }
  }

  // Check ACN Validate
  if (rule.ValidateACN && value) {
    if (ABN_Validator.validateACN(value)) {
      return {
        valid: true,
        error_msg: "",
      };
    } else {
      return {
        valid: false,
        error_msg: "Please enter valid ACN",
      };
    }
  }

  // Check a CreditCardNumber Validate
  if (rule.CreditCardNumber) {
    let regexp =
      /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;
    if (regexp.test(value) === false) {
      return {
        valid: false,
        error_msg: "Invalid credit card number number",
      };
    }
  }

  // Check a CreditCardExpiry Validate
  // if (rule.CreditCardExpiry) {
  // }

  // Check a CreditCardCVV Validate
  if (rule.CreditCardCVV) {
    var cvvRegex = /^[0-9]{3,4}$/;
    if (cvvRegex.test(value) === false) {
      return {
        valid: false,
        error_msg: "Invalid cvv number",
      };
    }
  }

  if (!rule.required) {
    return {
      valid: true,
      error_msg: "",
    };
  }

  return {
    valid: true,
    error_msg: "",
  };
};

export default Validation;
