import Card from "../../../../../../common/element/Card";
import Table from "../../../../../../common/element/Table";
import Tag from "../../../../../../common/element/Tag";
import Scrollbar from "../../../../../../common/element/ScrollBar";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetOverviewIndOfWorkerByProvider } from "../../../../../../actions/admin-worker/GetOverviewIndOfWorkerByProvider";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { decryptId } from "../../../../../../common/encryptDecrypt/encryptdecrypt";
const Inductions = () => {
  const { id } = useParams();
  const { Tr, Th, Td, THead, TBody } = Table;
  const { SpecificWorkerData } = useSelector((state) => state);
  const [inductionData, SetInductionData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const orgId = decryptId(id);
    dispatch(
      GetOverviewIndOfWorkerByProvider({
        worker_id: SpecificWorkerData?.worker_id || orgId,
      })
    ).then((response) => {
      if (response?.success) {
        SetInductionData(response?.data);
      }
    });
  }, []);

  return (
    <Card className="py-6 border border-gray-200 rounded-xl dark-shadow">
      <div className="px-6">
        <div className="card-header flex border-b border-gray-200 pb-5 items-center mb-5">
          <span className="mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 21L11.8999 20.8499C11.2053 19.808 10.858 19.287 10.3991 18.9098C9.99286 18.5759 9.52476 18.3254 9.02161 18.1726C8.45325 18 7.82711 18 6.57482 18H5.2C4.07989 18 3.51984 18 3.09202 17.782C2.71569 17.5903 2.40973 17.2843 2.21799 16.908C2 16.4802 2 15.9201 2 14.8V6.2C2 5.07989 2 4.51984 2.21799 4.09202C2.40973 3.71569 2.71569 3.40973 3.09202 3.21799C3.51984 3 4.07989 3 5.2 3H5.6C7.84021 3 8.96031 3 9.81596 3.43597C10.5686 3.81947 11.1805 4.43139 11.564 5.18404C12 6.03968 12 7.15979 12 9.4M12 21V9.4M12 21L12.1001 20.8499C12.7947 19.808 13.142 19.287 13.6009 18.9098C14.0071 18.5759 14.4752 18.3254 14.9784 18.1726C15.5467 18 16.1729 18 17.4252 18H18.8C19.9201 18 20.4802 18 20.908 17.782C21.2843 17.5903 21.5903 17.2843 21.782 16.908C22 16.4802 22 15.9201 22 14.8V6.2C22 5.07989 22 4.51984 21.782 4.09202C21.5903 3.71569 21.2843 3.40973 20.908 3.21799C20.4802 3 19.9201 3 18.8 3H18.4C16.1598 3 15.0397 3 14.184 3.43597C13.4314 3.81947 12.8195 4.43139 12.436 5.18404C12 6.03968 12 7.15979 12 9.4"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <div className="flex flex-col">
            <span className="text-gray-900 text-base font-semibold">
              Inductions
            </span>
            <p className="text-gray-600">Pending completion</p>
          </div>
        </div>
      </div>
      <div className="h-64">
        <Scrollbar>
          <div className="px-6">
            <div className="overflow-hidden border rounded-lg border-gray-200">
              <Table>
                <TBody>
                  {inductionData?.length > 0 &&
                    inductionData.map((clientAssign) => {
                      return clientAssign?.clientAssign?.clientCompInd?.map(
                        (item, index) => {
                          let status;
                          if (item?.workerCompInd?.is_comp_ind_completed) {
                            status = "Completed";
                          } else if (
                            item?.workerCompInd?.is_comp_ind_completed == false
                          ) {
                            status = "Pending";
                          } else {
                            status = "Awaiting action";
                          }
                          return (
                            <Tr key={index}>
                              <Td>
                                {item?.company_ind_name}
                                <span className="block text-gray-600">
                                  {clientAssign?.trading_name}
                                </span>
                              </Td>
                              <Td className="w-28">
                                {status == "Awaiting action" && (
                                  <Tag className=" bg-gray-100 text-gray-700">
                                    <svg
                                      width="12"
                                      height="13"
                                      viewBox="0 0 12 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM9.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM2.5 7.191a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                                        stroke="#475467"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    {status}
                                  </Tag>
                                )}
                                {status == "Completed" && (
                                  <Tag className="bg-success-100 text-success-700">
                                    <svg
                                      width="12"
                                      height="13"
                                      viewBox="0 0 12 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="m10 3.691-5.5 5.5-2.5-2.5"
                                        stroke="#039855"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    Completed
                                  </Tag>
                                )}
                                {status == "Pending" && (
                                  <Tag className="bg-blue-100 text-blue-800">
                                    <svg
                                      width="12"
                                      height="13"
                                      viewBox="0 0 12 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clip-path="url(#clip0_1592_20677)">
                                        <path
                                          d="M6 1.81641V3.06641M6 9.69141V11.6914M2.875 6.69141H1.125M10.625 6.69141H9.875M9.22855 9.91996L8.875 9.56641M9.33211 3.3993L8.625 4.10641M2.46079 10.2306L3.875 8.81641M2.56434 3.29575L3.625 4.35641"
                                          stroke="#5786CC"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1592_20677">
                                          <rect
                                            width="12"
                                            height="12"
                                            fill="white"
                                            transform="translate(0 0.691406)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    Pending
                                  </Tag>
                                )}
                              </Td>
                            </Tr>
                          );
                        }
                      );
                    })}
                </TBody>
              </Table>
              {inductionData?.length == 0 && (
                <div className="h-full w-full text-center mt-2">
                  No Data Available!
                </div>
              )}
            </div>
          </div>
        </Scrollbar>
      </div>
    </Card>
  );
};
export default Inductions;
