import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const data = [
  { name: 'Active', value: 400, color: '#65c9ac' },
  { name: 'Draft', value: 300, color: '#72aeda' },
  { name: 'Archived', value: 300, color: '#bcbec4' }
];

const outerData = [
  { name: 'Active', value: 400, color: '#65c9ac' },
  { name: 'Draft', value: 300, color: '#72aeda' },
  { name: 'Archived', value: 300, color: '#bcbec4' }
];

const innerData = [
  { name: 'Active', value: 200, color: '#65c9ac' },
  { name: 'Draft', value: 0, color: '#72aeda' },
  { name: 'Archived', value: 0, color: '#bcbec4' }
];

const RADIAN = Math.PI / 180;

const renderLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const ActivityGauge = () => {
  return (
    <PieChart width={400} height={400}>
      {/* Outer ring */}
      <Pie
        data={outerData}
        dataKey="value"
        cx={200}
        cy={200}
        innerRadius={100}
        outerRadius={150}
        fill="#8884d8"
        label={renderLabel}
      >
        {outerData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      
      {/* Inner ring */}
      <Pie
        data={innerData}
        dataKey="value"
        cx={200}
        cy={200}
        innerRadius={60}
        outerRadius={100}
        fill="#8884d8"
        label={renderLabel}
      >
        {innerData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default ActivityGauge;