import { useState } from "react";
import BtnPrimary from "../../../common/element/Buttons/btnPrimary";
import Card from "../../../common/element/Card";
import Heading from "../../../common/element/Heading";
import Circle from "../../../common/element/Iconcircle";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as userActions from "../../../actions/index";
import { notify } from "react-notify-toast";
import Validations from "../../../common/404/Validations";
import { useNavigate } from "react-router-dom";
import ThreeDotLoader from "../../../common/element/ButtonLoader/ThreeDotLoader";
function ForgotPassword(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //Validate Email
    const emailValidation = Validations({
      value: email,
      Validations: { required: true, email: true },
    });
    if (!emailValidation.valid) {
      setEmailError(emailValidation.error_msg);
      return;
    }

    const payload = {
      email: email,
    };
    setButtonDisabled(true)
    props.onForgotPassword(payload).then((response) => {
      if (response.success === true) {
        notify.show(response?.data, "success");
        localStorage.setItem("login", JSON.stringify(email));
        setTimeout(() => {
          // Redirects to dashboard page,
          navigate("/check-email");
          setButtonDisabled(false)
        }, 500);
      } else {
        notify.show(response.response.data.message, "error");
      }
    }).catch((error) => {
      console.log("Error", error);
      setButtonDisabled(false)
    }).finally(() => {
      setTimeout(() => {
        setButtonDisabled(false)
      }, 500);
    })
  };
  
  return (
    <div
      style={{ backgroundColor: `#eff8ff` }}
      className="flex justify-center min-h-screen"
    >
      <div className="min-h-screen justify-center flex  py-12 px-6">
        <div className="flex-col items-center justify-center flex">
          <Card className="w-500 rounded-xl   block-shadow w-100 mt-2 px-10 py-8">
            <Circle className="bg-blue-100 border-blue-50 mb-6">
              <img src="/assets/icons-svg/key.svg" alt="conserve" />
            </Circle>
            <Heading className="text-center mb-3">Forgot password?</Heading>
            <p className="text-sm text-gray-600 text-center mb-6">
              No worries, we’ll send you reset instructions.
            </p>
            <div className="w-full">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mb-6">
                  <label className="block text-gray-700  mb-2 text-xs font-medium">
                    Email address
                  </label>
                  <input
                    className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    value={email}
                    placeholder="Enter your email"
                    onChange={(e) => handleEmailChange(e)}
                    required
                  />
                  {emailError && (
                    <div className="text-red-500 text-sm mt-1">
                      {emailError}
                    </div>
                  )}
                </div>

                <div className="flex items-center justify-between w-100">
                  <BtnPrimary disabled={buttonDisabled} type="submit">
                    {buttonDisabled ? <ThreeDotLoader /> : "Reset password"}
                  </BtnPrimary>
                </div>
              </form>
            </div>
          </Card>
          <div className=" mt-8 px-10 w-full text-center">
            <Link
              to="/login"
              className="text-xs text-gray-600 font-semibold hover:text-gray-900 focus:text-gray-600 flex justify-center items-center"
            >
              {" "}
              <img
                src="/assets/icons-svg/arrow-left.svg"
                className="w-5 mr-2"
                alt=""
              />{" "}
              Back to log in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = {
  onForgotPassword: userActions.ForgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
